export const monthlyDatesSorted = (allDates) => {
  // Given an array of date objects, return a sorted array of those date objects plus date objects for any missing months

  // create a sorted set of all months seen
  var allDatesSet = Array.from(
    new Set(allDates.map((dateObject) => dateObject.toDateString()))
  ).map((dateString) => new Date(dateString));
  var allDatesSorted = allDatesSet.sort((a, b) => b - a);
  // see if there are any missing months in the data time period
  allDatesSorted.forEach((date, i) => {
    if (date - allDatesSorted[i + 1] > 31.1 * 1000 * 60 * 60 * 24) {
      const newDate = new Date(date.getFullYear(), date.getMonth());
      newDate.setMonth(newDate.getMonth() - 1);
      // add any missing months to allDatesSorted array
      allDatesSorted.push(newDate);
      allDatesSorted.sort((a, b) => b - a);
    }
  });
  return allDatesSorted;
};
