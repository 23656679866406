import {
  SelectionCategories,
  FilterContainer,
  FilterMenu,
  FilterChips,
  useSingleOrMoreFilterState,
  useViewFilters,
  AddEntityButton,
  upsertFilter,
  deleteFilter,
  useViewFilterDefaults,
  useStoredFilterSet,
  FilterSets,
  SelectFilter,
  FilterList,
  ValidValueTypes,
  PrimaryFilterLimits,
  FilterMenuLimits,
  LocalSelectionCategories,
  useSelectionLists,
  requireAtLeastOneFilterValueOf,
  OtherFilterNames,
  usePrimaryFilter,
  SubFilterDisplayMode,
  useDefaultLastMonth,
  DefaultDates,
  FilterMenuItemOrConfig,
  createSelectableFiltersMap,
  useSyncFiltersToSearchParams,
  PrimaryDataView,
  getPrimaryDataView,
  SelectionListIdNames,
  PlotAdditionalQueryParams,
  Tab,
  FilterChipsContainer,
  DateRangeFormattedValues,
  FiltersUsedInTabs,
  FilterBase,
  doesFilterHaveState,
  useAdaptiveRoleTaxonomy,
  useTabMeta,
  FilterSetSaveMenu,
} from '@revelio/filtering';
import { Grid, GridItem, Flex, SystemStyleObject } from '@chakra-ui/react';
import {
  EndpointSegment,
  useManyPlotConfigProviders,
  provideBasePlotConfigDefaults,
  ViewTypes,
} from '@revelio/filtering';
import { D3ChartNames } from '@revelio/d3';
import { BehaviorSubject, distinctUntilChanged, filter, pipe, tap } from 'rxjs';
import { flatten, get } from 'lodash';
import { useMemo, useRef } from 'react';
import objectHash from 'object-hash';
import { DefaultCard } from '@revelio/composed';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  Views,
  useResponsivePageGridDefs,
} from '@revelio/core';
import DashboardPage from '../DashboardPage';
import { usePostingsTopDataFetch, usePostingsDataFetch } from './data-fetch';
import { useTrackPerformance } from '../../hooks/mixpanel/useTrackPerformance';
import { View } from '@revelio/data-access';

export const providerFilterId = LocalSelectionCategories.PROVIDER;

const TopPostingsHeaderLookup: { [viewType: string]: string } = {
  [ViewTypes.COMPANY]: 'Roles',
  [ViewTypes.GEO]: 'Companies',
  [ViewTypes.ROLE]: 'Companies',
  [ViewTypes.SKILLS]: 'Companies',
};

export interface PostingsProps {
  title: PageTitles[];
  primaryView: PrimaryView;
  viewType: ViewTypes | Tab;
  primaryFilter: PrimaryFilters;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  requiredParams?: SelectionListIdNames[];
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryFiltersLimit: PrimaryFilterLimits | number;
  nonActiveSelectableFilters?: (SelectionCategories | SelectionCategories[])[];
  selectableFilters: FilterMenuItemOrConfig[];
  filterMenuLimits: FilterMenuLimits | number;
  otherFilters: (
    | OtherFilterNames
    | SelectionCategories
    | LocalSelectionCategories
  )[];
  additionalNonActiveFilters: (
    | OtherFilterNames
    | SelectionCategories
    | LocalSelectionCategories
  )[];
  disableParentSelect?: boolean;
  viewFiltersForDefault?: (OtherFilterNames | SelectionCategories)[];
  onlyConsiderTheseFiltersToTriggerDefaults?: (
    | OtherFilterNames
    | SelectionCategories
  )[];
  trialNoResultsMessage?: JSX.Element;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  savedSetView: View;
}

export function Postings({
  title,
  primaryView,
  viewType,
  primaryFilter,
  primaryFiltersLimit,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  requiredParams,
  primaryViewFilters,
  selectableFilters,
  filterMenuLimits,
  otherFilters,
  additionalNonActiveFilters,
  nonActiveSelectableFilters = [
    providerFilterId as unknown as SelectionCategories,
  ],
  disableParentSelect = false,
  viewFiltersForDefault,
  onlyConsiderTheseFiltersToTriggerDefaults,
  trialNoResultsMessage,
  isGqlQuery = false,
  isGoRequest = false,
  savedSetView,
}: PostingsProps) {
  const brokenOutFilterIds = isGqlQuery
    ? [SelectionCategories.PRIMARY_FILTER]
    : [SelectionCategories.PRIMARY_FILTER, SelectionCategories.DATE_RANGE_FULL];

  const view = Views.POSTING;

  const {
    templateColumns,
    templateRows,
    bigPlotColSpan,
    bigPlotRowSpan,
    gridItemMinHeight,
    tallGridItemMinHeight,
  } = useResponsivePageGridDefs(Views.POSTING);

  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);
  useViewFilters([...primaryFilters, ...selectableFiltersMap]);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: viewType,
    primaryEntitiesSync: true,
    limit: primaryFiltersLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: PrimaryFilterLimits.POSTINGS_DEFAULT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters,
  });

  useSelectionLists([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    ...FiltersUsedInTabs,
    LocalSelectionCategories.PROVIDER,
  ]);

  const viewFilterDefaultArgs = {
    view,
    viewType: viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults:
      onlyConsiderTheseFiltersToTriggerDefaults ?? [
        LocalSelectionCategories.PRIMARY_ENTITIES,
      ],
    viewFilters: viewFiltersForDefault ?? [
      ...otherFilters,
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    deepLinkLimit: PrimaryFilterLimits.POSTINGS,
    limit: PrimaryFilterLimits.POSTINGS_DEFAULT,
    primaryFilters,
    supportPrimaryEntities: true,
  };
  useViewFilterDefaults(viewFilterDefaultArgs);
  useTabMeta({
    savedSetView,
    view,
    viewType,
    deepLinkLimit: PrimaryFilterLimits.POSTINGS,
    limit: PrimaryFilterLimits.POSTINGS_DEFAULT,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });
  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.LAST_START_DATE,
  });

  usePrimaryFilter(primaryFilter);

  const requireAtLeastOne = requireAtLeastOneFilterValueOf(primaryFilters);

  const additionalOperatorsBeforeQueryMainPlot = pipe(
    requireAtLeastOne,
    distinctUntilChanged(
      (prev: PlotAdditionalQueryParams, curr: PlotAdditionalQueryParams) =>
        objectHash([...prev.filters, ...prev.additionalFilters], {
          unorderedArrays: true,
        }) ===
        objectHash([...curr.filters, ...curr.additionalFilters], {
          unorderedArrays: true,
        })
    ),
    filter((source: PlotAdditionalQueryParams) => {
      // Require date to be set
      // do not fire a request until date range is set to avoid cancelled request (can happen before last month has resolved)
      return !!source.filters.find((filter) => {
        const dateValue = filter.value as DateRangeFormattedValues;
        return (
          filter.id === SelectionCategories.DATE_RANGE_FULL &&
          dateValue.startDate &&
          dateValue.endDate
        );
      });
    })
  );

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(viewType),
    [viewType]
  );
  const {
    mappers: [
      {
        name: nameMainPostings,
        subfilters: subfiltersMainPostings,
        endpointMapper: endpointMapperMainPostings,
        plotConfigMapper: plotConfigMapperMainPostings,
        downloadEndpointMapper: downloadEndpointMainPostings,
        updater: updaterMainPostings,
        additionalNonActiveFilters: additionalNonActiveFiltersMainPostings,
        brokenOutFilterIds: brokenOutFilterIdsMainPostings,
        dataProvider: dataProviderMainPostings,
        metaData: metaDataMainPostings,
        endpointSegment,
      },
    ],
  } = useManyPlotConfigProviders([
    {
      view: Views.POSTING,
      endpoint: EndpointSegment.POSTING,
      viewType: ViewTypes.OVERTIME,
      chartType: D3ChartNames.MainPostingsPlot,
      subfilters: {
        filterName: LocalSelectionCategories.POSTING_METRIC,
        selectionLists: [LocalSelectionCategories.POSTING_METRIC],
      },
      chartProps: {
        name: 'posting-company-main',
        chartStyle: '.postings-company-main-plot',
        metaValueCompany: 'shortName', //for coloring by company
        yAxisFormat: '~s',
        dateFormat: 'YMD', //YM or YMD
        ttType: 'single', //percent (%value & count in tooltip), custom (add ttCustomString after single value) or single (tooltip with single value)
        ttCustomString: 'days', //for ttType="custom", string to use in tooltip after value
        ttMainFormat: ',',
        ttSecondaryFormat: ',', //for editing the count in ttType='percent'
      },
      additionalNonActiveFilters: additionalNonActiveFilters,
      brokenOutFilterIds: brokenOutFilterIds,
      metaData: {
        isGoRequest,
        pageGroupName: 'postings',
        primaryDataView,
      },
    },
  ]);

  const {
    mappers: [
      {
        endpointMapper: endpointMapperTopPostings,
        plotConfigMapper: plotConfigMapperTopPostings,
        downloadEndpointMapper: downloadEndpointTopPostings,
        additionalNonActiveFilters: additionalNonActiveFiltersTopPostings,
        brokenOutFilterIds: brokenOutFilterIdsTopPostings,
        dataProvider: dataProviderTopPostings,
        endpointSegment: endpointSegmentTopPostings,
        metaData: metaDataTopPostings,
      },
    ],
  } = useManyPlotConfigProviders([
    {
      view: Views.POSTING,
      endpoint: EndpointSegment.TOP,
      viewType: ViewTypes.SHARES,
      chartType: D3ChartNames.GroupedBarChartHorizontal,
      chartProps: {
        name: 'grouped-bar-chart-top-postings',
        chartStyle: '.top-postings-page',
        ttMainFormat: ',',
        ttSecondaryFormat: '.1%',
        isCentered: false,
        isFullHeight: true,
        labelSize: 11.5,
        isSharesPlot: true,
        chartPadding: { top: 5, right: 0, bottom: 5, left: 0 },
        innerPadding: 0.25,
        isXScaleNice: false,
      },
      additionalNonActiveFilters:
        // TODO: This is a workaround to remove provider from the top plot on the ROLE page
        viewType === ViewTypes.ROLE ? [] : additionalNonActiveFilters,
      brokenOutFilterIds: brokenOutFilterIds,
      metaData: {
        isGoRequest,
        pageGroupName: 'postings',
        primaryDataView,
      },
    },
  ]);

  const { current: externalTriggerDisplayCtrl } = useRef(
    new BehaviorSubject(true)
  );

  useSingleOrMoreFilterState<SelectFilter<FilterList<ValidValueTypes>>[]>(
    [...primaryFilters, LocalSelectionCategories.METRIC_MODE],
    pipe(
      tap((filters) => {
        const primaryFilters = filters.filter(
          (filter: FilterBase) =>
            filter.id !== LocalSelectionCategories.METRIC_MODE
        );

        const metricModeFilter = filters.filter(
          (filter: FilterBase) =>
            filter.id === LocalSelectionCategories.METRIC_MODE
        )[0];

        const metricModeFilterId = metricModeFilter?.value?.id;

        const isExpectedHiresPerPostingMetric =
          metricModeFilterId === 'expected_hires_per_posting';

        const moreThanOne =
          (
            primaryFilters as SelectFilter<FilterList<ValidValueTypes>>[]
          ).reduce((allValues, f) => {
            const items = get(f, 'value', [] as FilterList<ValidValueTypes>);
            return [...allValues, ...items];
          }, [] as FilterList<ValidValueTypes>).length > 1;

        if (moreThanOne && !isExpectedHiresPerPostingMetric) {
          externalTriggerDisplayCtrl.next(false);
          if (!doesFilterHaveState(SelectionCategories.POSTING_METRIC)) {
            upsertFilter(SelectionCategories.POSTING_METRIC, {
              value: { id: 'active', label: 'Active' },
              isMulti: false,
            });
          }
        } else {
          externalTriggerDisplayCtrl.next(true);
          deleteFilter(SelectionCategories.POSTING_METRIC);
        }

        updaterMainPostings.next({
          endpoint: moreThanOne
            ? EndpointSegment.POSTING_MULTI
            : EndpointSegment.POSTING,
          chartType:
            moreThanOne || isExpectedHiresPerPostingMetric
              ? D3ChartNames.LineChart
              : D3ChartNames.MainPostingsPlot,
          chartProps: isExpectedHiresPerPostingMetric
            ? {
                name: 'posting-company-main',
                chartStyle: '.postings-company-main-plot',
                metaValueCompany: 'shortName', //for coloring by company
                yAxisFormat: '',
                dateFormat: 'YMD', //YM or YMD
                ttType: 'single', //percent (%value & count in tooltip), custom (add ttCustomString after single value) or single (tooltip with single value)
                ttCustomString: 'days', //for ttType="custom", string to use in tooltip after value
                ttMainFormat: ',',
                ttSecondaryFormat: ',', //for editing the count in ttType='percent'
              }
            : {
                name: 'posting-company-main',
                chartStyle: '.postings-company-main-plot',
                metaValueCompany: 'shortName', //for coloring by company
                yAxisFormat: '~s',
                dateFormat: 'YMD', //YM or YMD
                ttType: 'single', //percent (%value & count in tooltip), custom (add ttCustomString after single value) or single (tooltip with single value)
                ttCustomString: 'days', //for ttType="custom", string to use in tooltip after value
                ttMainFormat: ',',
                ttSecondaryFormat: ',', //for editing the count in ttType='percent'
              },
          additionalNonActiveFilters: subfiltersMainPostings?.filterName
            ? [subfiltersMainPostings.filterName, ...additionalNonActiveFilters]
            : additionalNonActiveFilters,
          brokenOutFilterIds: subfiltersMainPostings?.filterName
            ? [subfiltersMainPostings.filterName, ...brokenOutFilterIds]
            : brokenOutFilterIds,
          subfilters:
            !moreThanOne || isExpectedHiresPerPostingMetric
              ? undefined
              : {
                  filterName: LocalSelectionCategories.POSTING_METRIC,
                  selectionLists: [LocalSelectionCategories.POSTING_METRIC],
                },
        });
      })
    )
  );

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view: Views.POSTING,
    viewType: ViewTypes.OVERTIME,
    chartType: D3ChartNames.LineChart,
    chartProps: {
      yAxisFormat: '~s',
      metaValueCompany: 'shortName',
      chartSize: 'large',
      dateFormat: 'YMD',
      ttType: 'single',
      ttMainFormat: ',',
      ttSecondaryFormat: ',',
      ttCustomString: 'days',
    },
    metaData: {
      isGoRequest,
      pageGroupName: 'postings',
      primaryDataView,
    },
  });

  const { mappers: sidePlotsMapper } = useManyPlotConfigProviders([
    viewDefaultsForPlots({
      endpoint: EndpointSegment.SALARY,
      metaData: {
        additionalFilters: additionalNonActiveFilters,
      },
      chartProps: {
        name: EndpointSegment.SALARY,
        chartStyle: `.postings-company-page-${EndpointSegment.SALARY}`,
        yAxisFormat: '$~s',
        ttMainFormat: '$.4s',
      },
      brokenOutFilterIds: brokenOutFilterIds,
    }),
    viewDefaultsForPlots({
      endpoint: EndpointSegment.TIMETOFILL,
      metaData: {
        additionalFilters: additionalNonActiveFilters,
      },
      chartProps: {
        name: EndpointSegment.TIMETOFILL,
        chartStyle: `.postings-company-page-${EndpointSegment.TIMETOFILL}`,
        yAxisFormat: '~s',
        ttType: 'custom',
        ttMainFormat: 'd',
      },
      brokenOutFilterIds: brokenOutFilterIds,
    }),
  ]);

  const {
    salaryData,
    timeToFillData,
    mainPostingData,
    loading: postingsDataLoading,
    error: postingsDataError,
  } = usePostingsDataFetch({ view: primaryView, primaryFilters });
  const {
    topData,
    loading: topDataLoading,
    error: topDataError,
  } = usePostingsTopDataFetch({ view: primaryView, primaryFilters });

  useTrackPerformance({
    loading: postingsDataLoading,
    eventName: 'plot_page_performance',
  });

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={postingsDataLoading || topDataLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={true}
            isPrimaryChip={true}
            min={1}
            addButton={
              <AddEntityButton
                entities={primaryViewFilters}
                entityName={AddEntityButtonText[primaryFilter]}
                buttonText={AddEntityButtonText[primaryFilter]}
                disableParentSelect={disableParentSelect}
                isParentCheckboxHidden={disableParentSelect}
                activeLimit={primaryFiltersLimit}
                limit={primaryFiltersLimit}
                required={1}
                trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <FilterChips
          filterNames={[...selectableFilters, ...nonActiveSelectableFilters]}
          view={Views.POSTING}
          variant="filterChip"
          limit={filterMenuLimits}
          offsetParent={[providerFilterId]}
          endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
          propsView={Views.POSTING}
          viewType={viewType}
          showGranularity
          addButton={
            <>
              <FilterMenu
                title="Filter"
                view={Views.POSTING}
                filters={[
                  ...selectableFilters,
                  ...nonActiveSelectableFilters,
                  // SelectionCategories.SAVED_FILTER_SET,
                ]}
                endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
                selectMenuOpenDefault
                limit={filterMenuLimits}
                offsetParent={[providerFilterId]}
                viewIdForDefault={`${view}_${viewType}`}
              />
              <FilterSetSaveMenu view={savedSetView} />
            </>
          }
        />
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        {sidePlotsMapper.map(
          (
            {
              name,
              endpointMapper,
              plotConfigMapper,
              endpointSegment: sideEndpointSegment,
              downloadEndpointMapper,
              subfilters,
              metaData,
              brokenOutFilterIds: brokenOutFilterIdsConfig,
              dataProvider,
              ...config
            },
            i
          ) => (
            <GridItem
              className={`postings-company-page-${endpointSegment}`}
              key={i}
              rowSpan={1}
              colSpan={1}
              minH={gridItemMinHeight}
            >
              <DefaultCard
                cardConfig={{
                  header: name,
                  endpointSegment: sideEndpointSegment,
                  view: Views.POSTING,
                }}
                plotConfig={{
                  endpoint: endpointMapper,
                  chartTypeAndProps: plotConfigMapper,
                  requiredParams,
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeQueryMainPlot,
                  additionalNonActiveFilters: subfilters?.filterName
                    ? [
                        subfilters.filterName,
                        ...(metaData && metaData.additionalFilters
                          ? metaData.additionalFilters
                          : []),
                      ]
                    : metaData?.additionalFilters,
                  brokenOutFilterIds: brokenOutFilterIdsConfig,
                  dataProvider,
                  isGqlQuery,
                }}
                downloadConfig={{
                  endpoint: downloadEndpointMapper,
                  isGoRequest: metaData?.isGoRequest,
                }}
                subfilter={
                  endpointSegment === EndpointSegment.KEYWORD
                    ? {
                        defaultState: subfilters.default,
                        showGrouped: false,
                        filterName: subfilters.filterName,
                        selectionLists: subfilters.selectionLists,
                        selectionLimit: 1,
                        staticTreeChildrenSelect: true,
                        placementOverride: 'top',
                        hideTriggerDefault: false,
                      }
                    : undefined
                }
                {...(sideEndpointSegment === EndpointSegment.SALARY && {
                  data: salaryData,
                })}
                {...(sideEndpointSegment === EndpointSegment.TIMETOFILL && {
                  data: timeToFillData,
                })}
                loading={postingsDataLoading}
                error={postingsDataError}
              />
            </GridItem>
          )
        )}
        <GridItem
          className="postings-company-top-plot"
          rowSpan={3}
          colSpan={1}
          minH={tallGridItemMinHeight}
        >
          <DefaultCard
            cardConfig={{
              header: `Top ${TopPostingsHeaderLookup[viewType]}`,
              endpointSegment: endpointSegmentTopPostings,
              view: Views.POSTING,
              viewType: [viewType],
            }}
            plotConfig={{
              requiredParams,
              additionalOperatorsBeforeQuery:
                additionalOperatorsBeforeQueryMainPlot,
              additionalNonActiveFilters: additionalNonActiveFiltersTopPostings,
              brokenOutFilterIds: brokenOutFilterIdsTopPostings,
              endpoint: endpointMapperTopPostings,
              chartTypeAndProps: plotConfigMapperTopPostings,
              dataProvider: dataProviderTopPostings,
              isGqlQuery,
            }}
            downloadConfig={{
              endpoint: downloadEndpointTopPostings,
              isGoRequest: metaDataTopPostings?.isGoRequest,
            }}
            data={topData}
            loading={topDataLoading}
            error={topDataError}
          />
        </GridItem>
        <GridItem
          className="postings-company-main-plot"
          rowSpan={bigPlotRowSpan}
          colSpan={bigPlotColSpan}
          minH={tallGridItemMinHeight}
        >
          <DefaultCard
            cardConfig={{
              header: nameMainPostings,
              endpointSegment,
              view: Views.POSTING,
            }}
            plotConfig={{
              requiredParams,
              additionalOperatorsBeforeQuery:
                additionalOperatorsBeforeQueryMainPlot,
              additionalNonActiveFilters:
                additionalNonActiveFiltersMainPostings,
              brokenOutFilterIds: brokenOutFilterIdsMainPostings,
              endpoint: endpointMapperMainPostings,
              chartTypeAndProps: plotConfigMapperMainPostings,
              dataProvider: dataProviderMainPostings,
              isGqlQuery,
            }}
            downloadConfig={{
              endpoint: downloadEndpointMainPostings,
              isGoRequest: metaDataMainPostings?.isGoRequest,
            }}
            subfilter={{
              externalTriggerDisplayCtrl: externalTriggerDisplayCtrl,
              defaultState: subfiltersMainPostings?.default,
              selectionLimit: 1,
              showGrouped: false,
              filterName: subfiltersMainPostings?.filterName,
              selectionLists: subfiltersMainPostings?.selectionLists,
              displayMode: SubFilterDisplayMode.SIMPLE,
              simpleSubfilterStyles: {
                option: (provided: SystemStyleObject) => ({
                  ...provided,
                  fontSize: 'xs',
                }),
              },
            }}
            data={mainPostingData}
            loading={postingsDataLoading}
            error={postingsDataError}
          />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
}

export default Postings;
