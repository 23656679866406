import {
  Checkbox,
  Collapse,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { D3ChartNames, pngDownloader } from '@revelio/d3';
import { startCase, get } from 'lodash';
import { useEffect, useState } from 'react';
import { ChartConfigForPlot, getActiveSetId } from '@revelio/filtering';
import { plotColorLookup } from '@revelio/core';
import { BehaviorSubject, of } from 'rxjs';
import { useObservable } from '@ngneat/react-rxjs';
import { RevelioWatermark } from '../../../assets/rl-data-logo';
import { Select } from 'chakra-react-select';
import {
  dimensionLimits,
  DownloadSizeOptions,
  sizeOptions,
  defaultOption,
  DownloadSizeMap,
} from './d3-chart-dimensions.config';
import { useDownloadFormState } from '../../../hooks/download-modal/useDownloadFormState';
import {
  ActionModal,
  ActionModalControlPanel,
  FeatureTrackingEvents,
} from '@revelio/core';
import mixpanel from 'mixpanel-browser';

export interface DownloadModalProps {
  header: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  downloadDimension: { height: number; width: number };
  isFullscreenMode?: boolean;
  typeAndProps?: ChartConfigForPlot;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data$?: BehaviorSubject<any[] | Record<string, unknown>> | undefined;
}

export function DownloadModal({
  header,
  isOpen,
  onOpen,
  onClose,
  downloadDimension,
  isFullscreenMode,
  typeAndProps,
  data,
  data$,
  ...props
}: DownloadModalProps) {
  const {
    title,
    filename,
    dimensions,
    aspectRatio,
    transparent,
    selectedSize,
    handleReset,
    validators,
  } = useDownloadFormState();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [dataStreamInternal] = useObservable(data$ || of(data));
  const dataStream = data ? data : dataStreamInternal;

  useEffect(() => {
    handleReset(isFullscreenMode, downloadDimension, {
      header,
      resetSelection: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (
      isFullscreenMode &&
      selectedSize.value.value === DownloadSizeOptions.AUTO
    ) {
      const height = Math.max(downloadDimension.height, dimensionLimits.min);
      const width = Math.max(downloadDimension.width, dimensionLimits.min);
      dimensions.setWidth(width);
      dimensions.setHeight(height);
    }
  }, [dimensions, downloadDimension, isFullscreenMode, selectedSize.value]);

  return (
    <ActionModal
      header={`${startCase(header)} Plot Download`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody>
        <FormControl isRequired isInvalid={filename.value.length === 0}>
          <FormLabel htmlFor="filename" fontSize="sm">
            File Name
          </FormLabel>
          <Input
            id="filename"
            type="text"
            value={filename.value}
            onChange={filename.handleChangeFilename}
            variant="outline"
          />
        </FormControl>

        <FormControl mt={5}>
          <FormLabel htmlFor="title" fontSize="sm">
            Title
          </FormLabel>
          <Input
            id="title"
            type="text"
            value={title.value}
            onChange={title.handleChangeTitle}
            variant="outline"
          />
        </FormControl>

        <FormControl isRequired mt={5}>
          <FormLabel htmlFor="download-size-select" fontSize="sm">
            Size
          </FormLabel>
          <Select
            id="download-size-select"
            name="download-size-select"
            placeholder="Select a size"
            options={sizeOptions}
            onChange={(e) => {
              if (e) {
                selectedSize.setSelectedSize(e);
              }
            }}
            value={selectedSize.value}
            defaultValue={defaultOption}
            focusBorderColor="green.500"
            chakraStyles={{
              control: (prev) => ({
                ...prev,
                _focus: {
                  zIndex: 1,
                  borderColor: '#3DC679',
                  boxShadow: 'none',
                },
              }),
            }}
          />
        </FormControl>
        <Collapse
          in={selectedSize.value.value === DownloadSizeOptions.CUSTOM}
          onAnimationComplete={() => {
            handleReset(isFullscreenMode, downloadDimension);
          }}
          transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
          animateOpacity
          style={{
            marginLeft: '-5px',
            paddingLeft: '5px',
          }}
        >
          <Stack>
            <HStack mt={5} spacing={10}>
              <FormControl
                isRequired
                isInvalid={!validators.validateDimensions(dimensions.width)}
              >
                <FormLabel htmlFor="width" fontSize="sm">
                  Width
                </FormLabel>
                <InputGroup>
                  <Input
                    id="width"
                    type="number"
                    value={dimensions.width}
                    onBlur={dimensions.handleDimInputOnBlur}
                    onChange={(e) => {
                      dimensions.handleDimInputOnChange(e, 'width');
                    }}
                  />

                  <InputRightAddon children="px" />
                </InputGroup>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={!validators.validateDimensions(dimensions.height)}
              >
                <FormLabel htmlFor="height" fontSize="sm">
                  Height
                </FormLabel>
                <InputGroup>
                  <Input
                    id="height"
                    type="number"
                    value={dimensions.height}
                    onBlur={dimensions.handleDimInputOnBlur}
                    onChange={(e) => {
                      dimensions.handleDimInputOnChange(e, 'height');
                    }}
                  />
                  <InputRightAddon children="px" />
                </InputGroup>
              </FormControl>
            </HStack>

            {(validators.isDimInvalid.width ||
              validators.isDimInvalid.height) && (
              <Text fontSize="sm" color="red">
                {`Dimensions can't be less than ${dimensionLimits.min}px or greater than ${dimensionLimits.max}px`}
              </Text>
            )}
          </Stack>

          <FormControl mt={5}>
            <Checkbox
              id="transparent"
              name="transparent"
              value="transparent"
              colorScheme="green"
              isChecked={aspectRatio.isLocked}
              isDisabled={
                !validators.validateDimensions(dimensions.height) ||
                !validators.validateDimensions(dimensions.width)
              }
              onChange={() => {
                aspectRatio.setLockRatio((prev) => !prev);
                aspectRatio.setAspectRatio(
                  aspectRatio.handleAspectRatioChange()
                );
              }}
            >
              <Text fontSize="sm">Lock Aspect Ratio</Text>
            </Checkbox>
          </FormControl>
        </Collapse>
        <FormControl
          mt={selectedSize.value.value === DownloadSizeOptions.CUSTOM ? 2.5 : 5}
        >
          <Checkbox
            id="transparent"
            name="transparent"
            value="transparent"
            colorScheme="green"
            isChecked={transparent.value}
            onChange={() => {
              transparent.setTransparent((prev) => !prev);
            }}
          >
            <Text fontSize="sm">Transparent Background</Text>
          </Checkbox>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ActionModalControlPanel
          onClose={onClose}
          submitText="Download"
          onSubmit={() => {
            setIsDownloading(true);

            const scaleFactor = 1.5; // TODO: maybe change this into a state
            const predefinedValue = DownloadSizeMap[selectedSize.value.value];

            const svgHeight = predefinedValue || dimensions.height;
            const svgWidth = predefinedValue
              ? predefinedValue * scaleFactor
              : dimensions.width;

            const { chartType, chartProps = {} } = typeAndProps || {};

            let isDownloadSuccess = false;

            pngDownloader(
              chartType || D3ChartNames.D3NoMatch,
              {
                ...chartProps,
                colorLookup: plotColorLookup.value,
                data: dataStream,
              },
              {
                download: true,
                getSVGNode: true,
                svgHeight,
                svgWidth,
                filename: filename.value,
                transparent: transparent.value,
                padding: 10,
                title: title.value,
                watermark: {
                  svgString: RevelioWatermark,
                  width: 85,
                  height: 20,
                },
              }
            )
              .then(() => {
                isDownloadSuccess = true;
                onClose();
                setTimeout(() => {
                  setIsDownloading(false);
                }, 1000);
              })
              .catch((err) => {
                setIsDownloading(false);
                console.log(err);
                throw new Error(err);
              })
              .finally(() => {
                mixpanel.track(FeatureTrackingEvents.PNG_DOWNLOAD, {
                  plot_name: get(chartProps, 'name'),
                  chart_type: chartType,
                  success: isDownloadSuccess,
                  page: getActiveSetId(),
                });
              });
          }}
          submitIsDisabled={
            filename.value.length === 0 ||
            !validators.validateDimensions(dimensions.height) ||
            !validators.validateDimensions(dimensions.width)
          }
          submitIsLoading={isDownloading}
          onReset={() => {
            handleReset(isFullscreenMode, downloadDimension, {
              header,
              resetSelection: true,
            });
          }}
        />
      </ModalFooter>
    </ActionModal>
  );
}

export default DownloadModal;
