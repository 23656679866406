import { graphql } from '@revelio/data-access';

export const GetSavedSetsQuery = graphql(`
  query GetSavedSets {
    presets {
      id
      name
      view
      default
      shared
      filters
    }
  }
`);

export const CreateSavedSetMutation = graphql(`
  mutation createPreset($name: String!, $view: View!, $filters: String!) {
    createPreset(
      input: {
        name: $name
        view: $view
        default: false
        shared: false
        filters: $filters
      }
    ) {
      id
      name
      view
      default
      shared
      filters
    }
  }
`);

export const UpdateSavedSetMutation = graphql(`
  mutation updatePreset(
    $id: String!
    $name: String
    $view: View
    $filters: String
  ) {
    updatePreset(
      input: { id: $id, name: $name, view: $view, filters: $filters }
    ) {
      id
      name
      view
      default
      shared
      filters
    }
  }
`);

export const DeleteSavedSetMutation = graphql(`
  mutation deletePreset($id: String!) {
    deletePreset(id: $id)
  }
`);
