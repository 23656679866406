import { CardListSelectController } from '@revelio/layout';
import { useObservable } from '@ngneat/react-rxjs';
import {
  deliverablesStore,
  updateDraftDeliverable,
} from '../deliverables.repository';
import { selectEntity } from '@ngneat/elf-entities';
import { map } from 'rxjs';
import { xor } from 'lodash';
import { write } from '@revelio/core';
import { CompleteIndividualPipeline, Deliverable } from '../deliverables.model';
import { useMemo } from 'react';

const CURRENT_POSITIONS_ONLY_OPTION = 'current';
const FULL_HISTORY_OPTION = 'full_history';
type ConfigurationOptions =
  | typeof CURRENT_POSITIONS_ONLY_OPTION
  | typeof FULL_HISTORY_OPTION;

export const IndividualUserDatasetConfiguration = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const [individualUserConfiguration] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          (deliverable?.pipeline as CompleteIndividualPipeline)
            ?.individual_user_configuration
      )
    )
  );

  const configurationOptionValues = useMemo(() => {
    const options = [];
    if (individualUserConfiguration?.current) {
      options.push(CURRENT_POSITIONS_ONLY_OPTION);
    }
    if (individualUserConfiguration?.full_history) {
      options.push(FULL_HISTORY_OPTION);
    }
    return options;
  }, [individualUserConfiguration]);

  return (
    <CardListSelectController<ConfigurationOptions>
      variant="columns"
      items={[
        {
          heading: null,
          columns: [
            {
              id: CURRENT_POSITIONS_ONLY_OPTION,
              label: 'Current positions only',
              tooltipInfo:
                'Select this option to only include current positions at the company. To include past employees at the company as well, leave this option unchecked.',
            },
            {
              id: FULL_HISTORY_OPTION,
              label: 'Include full user history',
              tooltipInfo:
                'Select this option to include full work history of all selected users. To only keep positions held at chosen set of companies, leave this option unchecked.',
            },
          ],
        },
      ]}
      gridProps={{
        spacing: 6,
        columns: 2,
        gridAutoFlow: 'row',
      }}
      value={configurationOptionValues}
      onChange={(value: string[]) => {
        const configurationOptions = xor(
          value,
          configurationOptionValues
        )[0] as ConfigurationOptions;
        updateDraftDeliverable(
          entityId,
          write<Deliverable>((state) => {
            (
              state.pipeline as CompleteIndividualPipeline
            ).individual_user_configuration[configurationOptions] = !(
              state.pipeline as CompleteIndividualPipeline
            ).individual_user_configuration[configurationOptions];
          })
        );
      }}
    />
  );
};
