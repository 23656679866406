import {
  VStack,
  Checkbox,
  InputGroup,
  Input,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon } from '@chakra-ui/icons';
import { useState, useMemo } from 'react';
import { useScreenerFilter } from '../screener-filter-provider';
import {
  CompanyDetailCheckboxFilters,
  ScreenerFilterOption,
  isCheckboxFilterState,
} from '../types';
import { SelectFooter } from '../select-footer';

interface CheckboxOption {
  value: string;
  label: string;
}

interface CheckboxFilterProps {
  selectOptions: CheckboxOption[];
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

export const CheckboxFilter = ({
  selectOptions,
  closeMenu,
  selectedFilter,
}: CheckboxFilterProps) => {
  const { state, dispatch } = useScreenerFilter();

  const selectedCompanyDetailState = state.filters.company_detail_filters?.find(
    (filter) => filter.name === selectedFilter.value
  );

  const defaultSelectedOptions = isCheckboxFilterState(
    selectedCompanyDetailState
  )
    ? selectedCompanyDetailState.options.map((opt) => opt.value)
    : [];

  const [search, setSearch] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[] | null>(
    defaultSelectedOptions
  );

  const selectionLimit = selectedFilter.selectionLimit || 10;

  const handleToggle = (value: string) => {
    if (selectedOptions?.includes(value)) {
      if (selectedOptions.length === 1) {
        // If only one selected, clear all
        return setSelectedOptions(null);
      }

      return setSelectedOptions(selectedOptions.filter((v) => v !== value));
    }

    if (selectedOptions && selectedOptions.length < selectionLimit) {
      return setSelectedOptions([...selectedOptions, value]);
    }

    // If at limit, replace the first selected item with the new one
    return setSelectedOptions([...(selectedOptions || []).slice(1), value]);
  };

  const handleAddFilter = () => {
    const selectedCheckboxOptions = selectOptions
      .filter((opt) => selectedOptions?.includes(opt.value))
      .map((opt) => ({
        label: opt.label,
        value: opt.value,
      }));

    if (selectedCheckboxOptions.length > 0) {
      dispatch({
        type: 'ADD_COMPANY_DETAIL_CHECKBOX_FILTER',
        name: selectedFilter.value as CompanyDetailCheckboxFilters,
        options: selectedCheckboxOptions,
      });
    }
    closeMenu();
  };

  const handleClearSelections = () => {
    setSelectedOptions([]);
  };

  const filteredOptions = useMemo(() => {
    return selectOptions.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [selectOptions, search]);

  const icon =
    search === '' ? (
      <SearchIcon w={3.5} h={3.5} color="silver.600" />
    ) : (
      <CloseIcon
        w={2.5}
        h={2.5}
        color="silver.600"
        onClick={() => setSearch('')}
        cursor="pointer"
      />
    );

  return (
    <>
      <VStack align="start" spacing={2}>
        {filteredOptions.length > 2 && (
          <InputGroup size="sm">
            <Input
              autoFocus
              placeholder="Search..."
              value={search}
              backgroundColor="#ffffff"
              onChange={(e) => setSearch(e.target.value)}
              data-testid="checkbox-search-input"
            />
            <InputRightElement children={icon} />
          </InputGroup>
        )}
        {filteredOptions.map((option) => {
          const isChecked = selectedOptions?.includes(option.value);

          return (
            <Checkbox
              key={option.value}
              value={option.value}
              isChecked={isChecked}
              onChange={() => handleToggle(option.value)}
              colorScheme="green"
              sx={{
                '& .chakra-checkbox__control': {
                  boxShadow: 'none !important',
                },
              }}
              ml={1}
            >
              <Text fontSize={12}>{option.label}</Text>
            </Checkbox>
          );
        })}
      </VStack>
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={closeMenu}
        onAdd={handleAddFilter}
        addLabel={selectedCompanyDetailState ? 'Update' : 'Add'}
        isAddDisabled={!selectedOptions || selectedOptions.length === 0}
      />
    </>
  );
};
