export const companySelectionListMock = {
  list: [
    {
      id: 4508,
      label: 'Slack Technologies, Inc.',
      data: {
        shortName: 'Slack',
        ticker: null,
        parentId: 12,
      },
    },
    {
      id: 3421,
      label: 'Snowflake, Inc.',
      data: {
        shortName: 'Snowflake',
        ticker: 'SNOW',
        parentId: 12,
      },
    },
    {
      id: 6181,
      label: 'Palantir Technologies, Inc.',
      data: {
        shortName: 'Palantir',
        ticker: 'PLTR',
        parentId: 12,
      },
    },
    {
      id: 3931,
      label: 'Alphabet, Inc.',
      data: {
        shortName: 'Alphabet',
        parentId: 12,
        companyTicker: 'GOOG',
      },
    },
    {
      id: 13138,
      label: '1 Source Business Solutions LLC',
      data: {
        shortName: '1 Source Business Solutions',
        parentId: 10,
        companyTicker: null,
      },
    },
    {
      id: 4492,
      label: 'Société Générale SA',
      data: {
        shortName: 'Société Générale',
        parentId: 18,
        companyTicker: 'GLE.PAR.CHF',
      },
    },
    {
      id: 15087,
      label: 'Nationwide Building Society',
      data: {
        shortName: 'Nationwide Building Society',
        parentId: 18,
        companyTicker: 'NBS.LON.EUR',
      },
    },
  ],
  parent: 'industry',
};
export default companySelectionListMock;
