import {
  ScreenerReqSegments,
  ScreenerReqSegmentSelector,
} from '@revelio/data-access';
import { ScreenerSubFilterState } from '../screener-filter-reducer';

export const rateColumns = ['hiring', 'attrition', 'growth', 'growth_yoy'];

export const serialiseSubFilter = ({
  filter: filterValue,
}: ScreenerSubFilterState): ScreenerReqSegments => {
  const filterKey = filterValue.name;
  const isRateFilter = rateColumns.includes(filterKey);
  const filter = Object.keys(filterValue.filter || {}).length
    ? filterValue.filter
    : undefined;

  if (isRateFilter && filterValue) {
    const start_val = filterValue.start_val
      ? filterValue.start_val / 100
      : filterValue.start_val;
    const end_val = filterValue.end_val
      ? filterValue.end_val / 100
      : filterValue.end_val;

    const segmentSelector: ScreenerReqSegmentSelector = {
      filter,
      start_val,
      end_val,
    };

    return { [filterKey]: [segmentSelector] };
  }

  const segmentSelector: ScreenerReqSegmentSelector = {
    filter,
    start_val: filterValue.start_val,
    end_val: filterValue.end_val,
  };

  return {
    [filterKey]: filterValue ? [segmentSelector] : [],
  };
};
