import Select from 'react-select';

export const StyledPlotSelect: typeof Select = ({ ...props }) => {
  return (
    <Select
      isSearchable={false}
      styles={{
        container: (prev) => ({
          ...prev,
          height: '24px',
          fontSize: '12px',
          fontWeight: 400,
        }),
        control: (prev) => ({
          ...prev,
          minHeight: '24px',
          height: '100%',
          backgroundColor: '#F1F5F9',
          borderWidth: 0,
          borderColor: 'none',
          boxShadow: 'none',
        }),
        valueContainer: (prev) => ({
          ...prev,
          padding: '0 8px',
        }),
        indicatorsContainer: (prev) => ({
          ...prev,
          height: '100%',
        }),
        indicatorSeparator: (prev) => ({
          ...prev,
          display: 'none',
        }),
        dropdownIndicator: (prev) => ({
          ...prev,
          color: '#2D426A',
          ' svg': {
            width: '12px',
            height: '12px',
          },
        }),
        menu: (prev) => ({
          ...prev,
          borderRadius: 0,
        }),
        option: (prev, { isSelected, isFocused }) => {
          const backgroundColor = (() => {
            if (isSelected) return '#3182ce';
            if (isFocused) return '#EDF2F7';
            return 'transparent';
          })();
          return {
            ...prev,
            backgroundColor,
            color: isSelected ? '#fff' : '#2D426A',
            fontSize: '12px',
            fontWeight: 400,
            padding: '4px 8px',
            cursor: 'pointer',
          };
        },
      }}
      {...props}
    />
  );
};
