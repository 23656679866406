import { useRef } from 'react';
import { get } from 'lodash';
import { SearchIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { remapCompany } from './company-mapping.respository';
import { CompanySearchTree, TreeNode } from '@revelio/filtering';
import { RevelioMappedCompany } from './company-mapping.model';

export const RemapCompany = ({
  rcid,
  missingCompaniesIndexToMap,
}: {
  rcid?: number;
  missingCompaniesIndexToMap?: number;
}) => {
  const { onOpen, onClose: onCloseMappingSearch, isOpen } = useDisclosure();
  const searchPopoverRef = useRef(null);
  const popoverRef = useRef(null);
  useOutsideClick({
    ref: popoverRef,
    handler: onCloseMappingSearch,
  });
  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={searchPopoverRef}
      onOpen={onOpen}
      onClose={onCloseMappingSearch}
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Search Company Universe"
          icon={<SearchIcon />}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={popoverRef}>
          <PopoverArrow />
          <PopoverBody>
            <CompanySearchTree
              isSingleSelect={true}
              placeholderListNodes={[]}
              setSelections={(item: RevelioMappedCompany) => {
                remapCompany({
                  rcid,
                  newMapping: item,
                  missingCompaniesIndexToMap,
                });
                onCloseMappingSearch();
              }}
              sortFn={(a: TreeNode, b: TreeNode) => {
                const headcountA = get(a, 'item.raw_emp_count');
                const headcountB = get(b, 'item.raw_emp_count');

                return headcountB - headcountA;
              }}
              searchInputRef={searchPopoverRef}
              treeHeight={200}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
