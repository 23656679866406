import mixpanel from 'mixpanel-browser';
import { assign } from 'lodash';

export enum UserTrackingEvents {
  LOGIN = 'login',
  SESSION = 'session',
  PAGE_VISIT = 'page visit',
  PAGE_LEAVE = 'page_leave',
  OPEN_MENU = 'open menu',
  TAB_CHANGE = 'tab change',
  TOOLTIP_OPEN = 'tooltip open',
}

export enum InitiationTrackingEvents {
  FINISH_TOUR = 'finish tour',
  SKIP_TOUR = 'skip tour',
  ACCEPT_TERMS = 'accept terms',
  DECLINE_TERMS = 'decline terms',
}

export enum CTAInteractionTrackingEvents {
  START_TRIAL = 'start trial',
  SIGN_UP = 'sign up click',
  SIGN_UP_SUCCESS = 'sign up success',
  PROVIDE_ONBOARDING_INFO = 'provide onboarding info',
  CONTACT_SALES_CLICK = 'contact sales click',
  RESEND_VERIFICATION_EMAIL = 'resend verification email click',
}

export enum UserFilterTrackingEvents {
  PRIMARY_FILTER = 'applied primary filter',
  PAGE_FILTER = 'applied page filter',
  REMOVE_FILTER = 'remove filter',
}

export enum FeatureTrackingEvents {
  DATA_DOWNLOAD = 'data download',
  PNG_DOWNLOAD = 'png download',
  SAVE_FILTER_SET = 'save filter set',
  RECALL_SAVED_FILTER_SET = 'recall saved filter set',
}

export type MixpanelEvents =
  | UserTrackingEvents
  | InitiationTrackingEvents
  | CTAInteractionTrackingEvents
  | UserFilterTrackingEvents
  | FeatureTrackingEvents;

export const userTracking = assign(mixpanel, {
  // not typed to avoid circular dependancy by importing from libs/auth
  setUser: (user: {
    trial?: boolean;
    username?: string;
    id: string;
    client_name: string;
  }) => {
    mixpanel.identify(user.username);
    mixpanel.people.set(user);
  },
  login: () => mixpanel.track(UserTrackingEvents.LOGIN),
});
