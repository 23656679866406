import { ID3ChartProps } from '@revelio/d3';
import { EndpointSegment, ViewTypes } from '@revelio/filtering';

interface BaseTransitionsPlotsConfig {
  endpoint: EndpointSegment;
  chartProps: Partial<ID3ChartProps>;
}

export const CompanyTransitionsPlotsConfigs: BaseTransitionsPlotsConfig[] = [
  {
    endpoint: EndpointSegment.ROLE,
    chartProps: {
      name: 'transitions-role',
    },
  },
  {
    endpoint: EndpointSegment.GEO,
    chartProps: {
      name: 'transitions-region',
    },
  },
  {
    endpoint: EndpointSegment.SENIORITY,
    chartProps: {
      name: 'transitions-seniority',
    },
  },

  {
    endpoint: EndpointSegment.GENDER,
    chartProps: {
      name: 'transitions-gender',
    },
  },
  {
    endpoint: EndpointSegment.ETHNICITY,
    chartProps: {
      name: 'transitions-ethnicity',
    },
  },
  {
    endpoint: EndpointSegment.EDUCATION,
    chartProps: {
      name: 'transitions-education',
    },
  },
];

export const GeoTransitionsPlotsConfigs: BaseTransitionsPlotsConfig[] = [
  {
    endpoint: EndpointSegment.ROLE,
    chartProps: {
      name: 'transitions-role',
    },
  },
  {
    endpoint: EndpointSegment.INDUSTRY,
    chartProps: {
      name: 'transitions-industry',
    },
  },
  {
    endpoint: EndpointSegment.SENIORITY,
    chartProps: {
      name: 'transitions-seniority',
    },
  },

  {
    endpoint: EndpointSegment.GENDER,
    chartProps: {
      name: 'transitions-gender',
    },
  },
  {
    endpoint: EndpointSegment.ETHNICITY,
    chartProps: {
      name: 'transitions-ethnicity',
    },
  },
  {
    endpoint: EndpointSegment.EDUCATION,
    chartProps: {
      name: 'transitions-education',
    },
  },
];

export const RoleTransitionsPlotsConfigs: BaseTransitionsPlotsConfig[] = [
  {
    endpoint: EndpointSegment.INDUSTRY,
    chartProps: {
      name: 'transitions-industry',
    },
  },
  {
    endpoint: EndpointSegment.GEO,
    chartProps: {
      name: 'transitions-geography',
    },
  },
  {
    endpoint: EndpointSegment.SENIORITY,
    chartProps: {
      name: 'transitions-seniority',
    },
  },

  {
    endpoint: EndpointSegment.GENDER,
    chartProps: {
      name: 'transitions-gender',
    },
  },
  {
    endpoint: EndpointSegment.ETHNICITY,
    chartProps: {
      name: 'transitions-ethnicity',
    },
  },
  {
    endpoint: EndpointSegment.EDUCATION,
    chartProps: {
      name: 'transitions-education',
    },
  },
];

export const BaseTransitionsPlotConfigLookup: {
  [key: string]: BaseTransitionsPlotsConfig[];
} = {
  [ViewTypes.COMPANY]: CompanyTransitionsPlotsConfigs,
  [ViewTypes.GEO]: GeoTransitionsPlotsConfigs,
  [ViewTypes.ROLE]: RoleTransitionsPlotsConfigs,
};
