// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { BarChartVerticalGenerator } from '../plot-generators/BarChartVerticalGenerator';

const BarChartVertical = ({
  name,
  data,
  chartPosition,
  yAxisFormat,
  ttMainFormat,
  ttSecondaryFormat,
  chartStyle,
  colorIndex,
  mirror,
  height,
  width,
  targetRef,
  requestHash,
  customMargins,
}) => {
  useD3(
    BarChartVerticalGenerator,
    {
      name,
      data,
      chartPosition,
      yAxisFormat,
      ttMainFormat,
      ttSecondaryFormat,
      chartStyle,
      colorIndex,
      mirror,
      height,
      width,
      targetRef,
      requestHash,
      customMargins,
    },
    [height, width, targetRef.current, data, requestHash]
  );

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

BarChartVertical.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  chartPosition: PropTypes.string,
  yAxisFormat: PropTypes.string,
  ttMainFormat: PropTypes.string,
  ttSecondaryFormat: PropTypes.string,
  chartStyle: PropTypes.string,
  colorIndex: PropTypes.number,
  mirror: PropTypes.bool,
};

BarChartVertical.defaultProps = {
  name: 'default',
  data: [],
  chartPosition: '',
  yAxisFormat: '.0%',
  ttMainFormat: '.1%',
  ttSecondaryFormat: ',',
  chartStyle: '',
  colorIndex: 0,
  mirror: false,
};

export default withResizeDetector(BarChartVertical, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
