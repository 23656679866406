import {
  Box,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/layout';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react';
import { LoaderType, PagePaths, PageTitles } from '@revelio/core';
import DashboardPage from '../DashboardPage';
import { CircleIcon } from '@revelio/layout';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { ReactNode, useEffect, useMemo } from 'react';
import { getPipelineTypeTitleById } from './data-set.model';
import { selectEntities } from '@ngneat/elf-entities';
import {
  deliverablesStore,
  getClient,
  getDuplicateDatasetNaming,
  getName,
  hasCompanySelection,
  updateLag_data,
} from './deliverables.repository';
import { useObservable } from 'react-use';
import {
  areColumnsValid,
  isCompanyRequiredOnDeliverablePipeline,
} from './columns/validate-column-submission';
import { every, map, noop } from 'lodash';
import { Deliverable } from './deliverables.model';
import { getDuplicateDeliverables } from './delivery/delivery.model';
import { useUserTrialDetails } from '@revelio/auth';
import { PipelineType } from '@revelio/data-access';

interface IStep {
  id: string;
  title: string;
  path: DeliverablePaths;
  description?: string | ReactNode;
}

export enum DeliverablePaths {
  DATASET = 'dataset',
  COMPANY_SELECTION = 'company_selection',
  COLUMNS = 'columns',
  DELIVERY = 'delivery',
  CONFIRMATION = 'confirmation',
  STATUS = 'status',
}
export const steps: IStep[] = [
  {
    id: 'dataset',
    title: 'Dataset',
    path: DeliverablePaths.DATASET,
  },
  {
    id: 'company_mapping',
    title: 'Company Mapping',
    path: DeliverablePaths.COMPANY_SELECTION,
  },
  {
    id: 'columns_and_filters',
    title: 'Columns & Filters',
    path: DeliverablePaths.COLUMNS,
  },
  {
    id: 'delivery',
    title: 'Delivery',
    path: DeliverablePaths.DELIVERY,
  },
  {
    id: 'Confirmation',
    title: 'Confirmation',
    path: DeliverablePaths.CONFIRMATION,
  },
];

export const DeliverablesPage = ({ pageTitle }: { pageTitle: PageTitles }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isTrialUser } = useUserTrialDetails();

  useEffect(() => {
    if (isTrialUser) {
      deliverablesStore.update(updateLag_data(true));
    }
  }, [isTrialUser]);

  const deliverables = useObservable(deliverablesStore.pipe(selectEntities()));
  useEffect(() => {
    if (location.pathname.endsWith(PagePaths.DELIVERABLES)) {
      navigate(DeliverablePaths.DATASET);
    }

    const deliverablePath = location.pathname.split('/').pop() as string;
    if (
      Object.values(DeliverablePaths).includes(
        deliverablePath as DeliverablePaths
      ) &&
      deliverablePath !== DeliverablePaths.STATUS &&
      !isStepAccessible({
        step: steps.find((s) => s.path === deliverablePath) as IStep,
        deliverables,
      })
    ) {
      const currentStepIndex = steps.findIndex(
        (s) => s.path === deliverablePath
      );
      for (let i = currentStepIndex; i >= 0; i--) {
        if (isStepAccessible({ step: steps[i], deliverables })) {
          navigate(steps[i].path);
          return;
        }
      }
    }
  }, [navigate, location.pathname, deliverables]);

  const currentStepIndex = useMemo(
    () => steps.findIndex((step) => location.pathname.includes(step.path)),
    [location]
  );

  const datasetsLength = Object.keys(deliverables || {}).length;
  const stepsWithFilledInData = steps.map((step) =>
    step.path === DeliverablePaths.DATASET
      ? {
          ...step,
          description:
            datasetsLength < 11
              ? map(deliverables, (d) =>
                  getPipelineTypeTitleById(
                    d?.pipeline.pipeline_type as PipelineType
                  )
                ).map((pipelineTitle, i, datasetTitles) => (
                  <Text pl="3" key={i}>
                    {pipelineTitle}
                    {i !== datasetTitles.length - 1 ? ',' : ''}
                    <br />
                  </Text>
                ))
              : `${datasetsLength} selected`,
        }
      : step
  );
  return (
    <DashboardPage
      title={[PageTitles.DELIVERABLES, pageTitle]}
      loader={LoaderType.MANUAL}
    >
      <Box rounded="lg" h="calc(100vh - 100px)">
        <Stack
          h="100%"
          direction="row"
          divider={<StackDivider width="15px" backgroundColor="gray.100" />}
          spacing="0"
          css={{
            '> div:first-child, > div:last-child': {
              background: 'white',
              borderRadius: '0.5rem',
            },
          }}
        >
          <Flex
            width="240px"
            height="100%"
            padding="32px 24px 16px"
            flexDirection="column"
            justifyContent="space-between"
            rounded="lg"
          >
            <Stepper
              colorScheme="green"
              index={currentStepIndex}
              orientation="vertical"
              height="500px"
              maxH="500px"
              gap="0"
              size="md"
            >
              {stepsWithFilledInData.map((step, index) => (
                <Step key={`dlStep-${index}`}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<CircleIcon boxSize="12" color="gray.200" />}
                      active={
                        <CircleIcon
                          boxSize="7"
                          color="green.500"
                          data-testid={`deliverable_${step.id}_step_active_icon`}
                        />
                      }
                    />
                  </StepIndicator>

                  <Box>
                    <StepTitle as="span">
                      <Heading
                        as="h1"
                        pt="6px"
                        pl="6px"
                        color="text.primary"
                        fontWeight="600"
                        fontSize="md"
                      >
                        {step.title}
                      </Heading>
                    </StepTitle>
                    {step.description &&
                    typeof step.description === 'string' ? (
                      <Box pl="6px">
                        <StepDescription>{step.description}</StepDescription>
                      </Box>
                    ) : (
                      <Text as="span" fontSize="2xs" color="text.primary">
                        {step.description}
                      </Text>
                    )}
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Flex>
          <Outlet />
        </Stack>
      </Box>
    </DashboardPage>
  );
};

const isStepAccessible = ({
  step,
  deliverables,
}: {
  step: IStep;
  deliverables: Record<number, Deliverable> | undefined;
}) => {
  const stepIndex = steps.findIndex((s) => s.path === step.path);
  const isAfterFirstStep = stepIndex > 0;
  if (!isAfterFirstStep) {
    // first step is always accessible
    return true;
  }

  return (
    deliverables &&
    !!Object.keys(deliverables).length &&
    (doesDeliveryHaveDuplicateDatasetsWithUniqueNamesSet({
      step,
      deliverables: Object.values(deliverables || {}),
    }) ||
      step.path !== DeliverablePaths.CONFIRMATION) &&
    every(deliverables, (deliverable) =>
      isStepValid({
        stepPath: steps[stepIndex - 1].path,
        deliverable,
      })
    )
  );
};

const doesDeliveryHaveDuplicateDatasetsWithUniqueNamesSet = ({
  step,
  deliverables,
}: {
  step: IStep;
  deliverables: Deliverable[];
}) => {
  const duplicateDeliverables =
    getDuplicateDeliverables<Deliverable>(deliverables);
  const duplicateDatasetNames = deliverablesStore.query(
    getDuplicateDatasetNaming
  );
  return (
    step.path === DeliverablePaths.CONFIRMATION &&
    every(
      duplicateDeliverables,
      (d) => !d.isExcluded && duplicateDatasetNames[d.id]
    )
  );
};

const isStepValid = ({
  stepPath,
  deliverable,
}: {
  stepPath: DeliverablePaths;
  deliverable: Deliverable | undefined;
}) => {
  const validationStepMap: {
    [key in DeliverablePaths]: (
      deliverable: Deliverable | undefined
    ) => boolean;
  } = {
    [DeliverablePaths.STATUS]: () => false, // ignore this one, annoyingly can't get enum subset to exclude this and don't want to make type optional
    [DeliverablePaths.DATASET]: () => true,
    [DeliverablePaths.COMPANY_SELECTION]: isCompanySelectionValid,
    [DeliverablePaths.COLUMNS]: isColumnsStepValid,
    [DeliverablePaths.DELIVERY]: isDeliveryStepValid,
    [DeliverablePaths.CONFIRMATION]: isConfirmationStepValid,
  };

  return validationStepMap[stepPath](deliverable);
};
const isCompanySelectionValid = (deliverable: Deliverable | undefined) => {
  return (
    !!deliverable?.pipeline.pipeline_type &&
    (!isCompanyRequiredOnDeliverablePipeline(deliverable) ||
      hasCompanySelection({
        entityId: deliverable?.id as number,
      }))
  );
};

const isColumnsStepValid = (deliverable: Deliverable | undefined) => {
  return (
    isCompanySelectionValid(deliverable) &&
    areColumnsValid({
      entityId: deliverable?.id as number,
      toast: noop,
    })
  );
};

export const isDeliveryStepValid = (deliverable: Deliverable | undefined) => {
  return (
    isColumnsStepValid(deliverable) &&
    !!(deliverable?.s3_delivery || deliverable?.snowflake_delivery)
  );
};

const isConfirmationStepValid = (deliverable: Deliverable | undefined) => {
  const client = deliverablesStore.query(getClient);
  const deliverableName = deliverablesStore.query(getName);
  return isDeliveryStepValid(deliverable) && !!client && !!deliverableName;
};
