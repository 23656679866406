import styles from './tos-modal.module.scss';
import remarkGfm from 'remark-gfm';
import { ComponentPropsWithoutRef } from 'react-markdown/lib/ast-to-react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';

export enum AgreementTypes {
  TERMS_OF_USE = 'terms_of_use',
  TRIAL_AGREEMENT = 'trial_agreement',
  NONE = 'none',
}

export const remarkPlugins = [remarkGfm];

export const markdownComponents = {
  a: ({
    node,
    children,
    ...props
  }: ComponentPropsWithoutRef<'a'> & ReactMarkdownProps) => (
    <a className={styles.link} {...props} target="_blank">
      {children}
    </a>
  ),
};
