import { useState } from 'react';
import { Filter } from '../../engine/filters.model';

type KeywordChip = string[];
type KeywordFilterState = KeywordChip[];

export const useKeywordFilterApi = () => {
  const [keywordFilters, setKeywordFilters] = useState<KeywordFilterState>([]);

  const addKeywordFilterChip = (keywords: string[]) => {
    setKeywordFilters((prevKeywordFilters) => [
      ...prevKeywordFilters,
      keywords,
    ]);
  };

  const breakoutFilterChips = (items: Filter[]) => {
    return items.flatMap((item): any => {
      if (item?.id === 'keywords') {
        return keywordFilters.map((keywordFilterChip, i) => ({
          ...item,
          filterStateIndex: i,
          value: {
            keyword: keywordFilterChip.map((keyword) => ({
              label: keyword,
              value: keyword,
            })),
          },
        }));
      }
      return item;
    });
  };

  const removeKeywordFilterChip = (
    filterItem: Filter & { filterStateIndex: number }
  ) => {
    const filterStateIndex = filterItem.filterStateIndex;
    setKeywordFilters((prevKeywordFilters) =>
      prevKeywordFilters.filter((_, i) => i !== filterStateIndex)
    );
  };

  return {
    keywordFilters,
    addKeywordFilterChip,
    removeKeywordFilterChip,
    breakoutFilterChips,
  };
};

export type KeywordFilterApi = ReturnType<typeof useKeywordFilterApi>;
