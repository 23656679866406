import { NodeApi } from 'react-arborist';
import { NodeData } from '../tree-selection';
import { deburr } from 'lodash';

export const handleSearch = (node: NodeApi<NodeData>, search: string) =>
  [
    node.data.name,
    ...(node.data.secondaryLabel ? [node.data.secondaryLabel] : []),
  ].some((identifier) =>
    deburr(identifier.toLowerCase().trim()).includes(
      search.toLowerCase().trim()
    )
  );
