import { DASH_META_ROOT, getAuthDashMetaCsrfToken } from '@revelio/auth';
import {
  GetTalentDiscoveryTooltipPayload,
  TalentDiscoveryMutateResponse,
} from '@revelio/data-access';

export const fetchTooltipData = async (
  vars: GetTalentDiscoveryTooltipPayload
) => {
  const csrfToken = getAuthDashMetaCsrfToken();
  if (!csrfToken) throw new Error('Missing CSRF token');

  return await fetch(`${DASH_META_ROOT}/api/talent/map/tooltip`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'x-surf-token-dash-meta': csrfToken,
      'Content-Type': 'application/json',
      'request-id': crypto.randomUUID(),
    },
    body: JSON.stringify(vars),
  }).then((r) => r.json());
};

export const seedTooltipCache = async (
  vars: GetTalentDiscoveryTooltipPayload[]
): Promise<TalentDiscoveryMutateResponse> => {
  const csrfToken = getAuthDashMetaCsrfToken();
  if (!csrfToken) throw new Error('Missing CSRF token');

  return await fetch(`${DASH_META_ROOT}/api/talent/seed-tooltip-cache`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'x-surf-token-dash-meta': csrfToken,
      'Content-Type': 'application/json',
      'request-id': crypto.randomUUID(),
    },
    body: JSON.stringify(vars),
  }).then((r) => r.json());
};
