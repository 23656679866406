import { LocalSelectionCategories } from '../../../engine/filters.model';

export const toastId = 'filter-tree-error-toast';

export const categoriesToTrackUserSubmission = [
  LocalSelectionCategories.PROVIDER as string,
  LocalSelectionCategories.DATA_METRIC,
];

export const nestedSelections = [LocalSelectionCategories.PROVIDER];
