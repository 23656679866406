import { PageCardInterface } from './PageCard';
import CompanyIcon from './CompanyIcon';
import GeographyIcon from './GeographyIcon';
import RoleIcon from './RoleIcon';
import IndividualIcon from './IndividualIcon';
import { Tab } from '@revelio/data-access';

export const pageCardData: PageCardInterface[] = [
  {
    tab: Tab.Overview,
    heading: 'Companies & <br class="landing-break-line"/>Industries',
    description:
      'Track the headcounts, inflows, and outflows of any role at any company or industry to see real time changes in the workforce',
    bgColor: '#F6FAFF',
    hoverBgColor: '#E4F1FF',
    path: '/company/compositions',
    icon: <CompanyIcon />,
  },
  {
    tab: Tab.Overview,
    heading: 'Cities & <br class="landing-break-line"/>States',
    description:
      'See the workforce at any city and state to understand changes in compensation and enhance your talent intelligence capabilities',
    bgColor: '#F3FDF7',
    hoverBgColor: '#DDF8E8',
    path: '/geography/compositions',
    icon: <GeographyIcon />,
  },
  {
    tab: Tab.Overview,
    heading: 'Jobs & <br class="landing-break-line"/>Skills',
    description:
      'Analyze how technology is impacting the workforce, identify skill gaps, and how the occupational landscape is transforming',
    bgColor: '#F9F6FF',
    hoverBgColor: '#EDE5FF',
    path: '/role/compositions',
    icon: <RoleIcon />,
  },
  {
    tab: Tab.TalentDiscovery,
    heading: 'Talent & <br class="landing-break-line"/>Prospects',
    description:
      'Access the most powerful talent discovery tool, allowing anybody to find professionals, to either partner with or recruit',
    bgColor: '#FFF9F7',
    hoverBgColor: '#FFEDE7',
    path: '/talent-discovery',
    icon: <IndividualIcon />,
  },
];
