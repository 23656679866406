// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  select,
  selectAll,
  scaleOrdinal,
  scaleBand,
  scaleLinear,
  min,
  max,
  range,
} from 'd3';
import {
  //axisLeft,
  axisBottom,
} from 'd3-axis';
import { format as d3Format } from 'd3-format';
import { roundedRect } from '../../utilities/corner-radius';
import { colors } from '../../utilities/colors.js';
import { generateHTMLBoxplot } from '../../utilities/generate-html-boxplot.js';
// import { generateTextWidth } from '../../utilities/text-width.js';
// import { removeLoadingStatus } from '@revelio/core';
// import { endall } from '../../utilities/endall-transition.js';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import '../../d3-styles.scss';
import { getBoxPlotColor } from './helpers';

export const BoxPlotGenerator = ({
  name,
  data,
  otherTabData = [],
  ttMainFormat,
  ttSecondaryFormat,
  xAxisFormat,
  height,
  width,
  targetRef,
  requestHash,
  isRenderingOrLoading,
}) => {
  const dims = {};

  // upper and lower percentiles to use for boxes
  const percentileLower = 'percentile10';
  const percentileUpper = 'percentile90';

  if (data && targetRef.current && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();
    // setup margins and inner dims
    dims.margin = { top: 36, left: 70, bottom: 20, right: 25 };
    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);
    // setup svg node
    const node = targetRef.current;
    const svg = select(node).append('svg');
    svg
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    //=============================================================================

    // PROPS REQUIRED: ttMainFormat (always perc), ttSecondaryFormat (always count), yAxisFormat, xAxisFormat

    // --------------- BOX PLOT START HERE

    const currentTabXValues = data
      .map((d) => d.value.map((r) => Object.values(r.value)))
      .flat(2);

    const xValuesConcatenated = [...currentTabXValues, ...otherTabData];

    const xScaleLowerBound = min(xValuesConcatenated);
    const xScaleUpperBound = max(xValuesConcatenated);

    const multiplier = xScaleUpperBound < 200 ? 100 : 10000;
    const lowerBoundRatio = Math.trunc(xScaleLowerBound / multiplier);
    const upperBoundRatio = Math.trunc(xScaleUpperBound / multiplier);
    const lowerBoundRounded = lowerBoundRatio * 10000;
    const upperBoundRounded = (upperBoundRatio + 1) * multiplier;
    // range function generates [lower, upper)
    const tickRange = range(lowerBoundRounded, upperBoundRounded + 1, 1);

    const getTickValues = (tickRange, segments) => {
      const tickValues = [];

      for (let i = 0; i < segments - 1; i++) {
        const value =
          tickRange[Math.floor(tickRange.length * ((i + 1) / segments))];

        tickValues.push(value);
      }

      return tickValues;
    };

    const xAxisLabels = [
      lowerBoundRounded,
      ...getTickValues(tickRange, 4),
      upperBoundRounded,
    ];

    const xScale = scaleLinear()
      .domain([lowerBoundRounded, upperBoundRounded])
      .range([0, dims.innerWidth]);

    const groups = new Set(
      data.map((d) => d.value.map((r) => r.metadata.shortName)).flat()
    );
    const subgroups = data.map((d) => d.metadata.longName);

    const colorScale = scaleOrdinal().domain(subgroups).range(colors);

    //transform data to nest properly for group & subgroup
    const dataTransformed = [];
    const valueMapping = {};
    groups.forEach((d) => {
      // transform data
      const newData = {};
      newData.group_name = d;
      data.forEach((r) => {
        const dVal = r.value.filter((s) => s.metadata.shortName === d)[0];

        if (dVal) {
          newData[r.metadata.longName] = dVal.value;
          newData[r.metadata.longName].count = dVal.metadata.count;
          newData[r.metadata.longName].id = r.id;
        } else {
          newData[r.metadata.longName] = {};
          newData[r.metadata.longName].count = 0;
        }
        // also create a mapping of value shortName (to use in legend) to value longName (to use in tooltip)
        r.value.forEach(
          (val) =>
            (valueMapping[`${val.metadata.shortName}`] = val.metadata.longName)
        );
      });

      dataTransformed.push(newData);
    });

    dims.margin.left += 10;
    // re-transform chart & recalculate inner width:
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    // keep inner padding between bars constant and clamp space occupied by bar & inner padding to a max
    const innerPad = 0.2; //this is a percentage of step
    const maxStep = 60; //space from start of 1 bar to start of the next
    let yAxisRange = [0, dims.innerHeight];
    // readjust the y axis range to make the chart height (sum of all steps - height of bars + innerpaddings) dependent on # bars and vertically centered
    const chartHeight = groups.size * maxStep;
    if (chartHeight < dims.innerHeight) {
      yAxisRange = [
        dims.innerHeight / 2 - chartHeight / 2,
        dims.innerHeight / 2 + chartHeight / 2,
      ];
    }

    const yScale = scaleBand()
      .domain(groups)
      .range(yAxisRange) //previously yAxisRange - TODO: add in dynamic y axis range
      .paddingInner(innerPad);

    const ySubgroupScale = scaleBand()
      .domain(subgroups)
      .range([0, yScale.bandwidth()])
      .paddingInner(0.2); // no padding for subgroups

    const tooltipWidth = 240;

    const tooltip = select(`.react-node-${name}`)
      .append('div')
      .style('width', tooltipWidth + 'px')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      .attr('class', `tooltip-boxplot`)
      .classed(`tooltip-${name}`, true)
      .attr('data-testid', `box-plot-tooltip`);

    const mouseOver = (event, d) => {
      // TODO: get tooltip height dynamically
      const approxTooltipHeight = 139; //24 + 48 + 14 + 21 * 4 + 14;
      const halfTooltipHeight = approxTooltipHeight / 2;
      const arrowHeight = 10;
      let tooltipPosition = '';
      //show tooltip
      tooltip
        .style('opacity', 1)
        .style('display', null)
        .style('transform', () => {
          let translateX =
            dims.margin.left +
            xScale(d.value[percentileUpper]) -
            (xScale(d.value[percentileUpper]) -
              xScale(d.value[percentileLower])) /
              2 -
            tooltipWidth / 2;

          let translateY =
            dims.margin.top +
            yScale(d.group) +
            ySubgroupScale(d.key) -
            (approxTooltipHeight + arrowHeight);

          // check if tooltip exceeds right bound
          if (translateX + tooltipWidth > dims.innerWidth + dims.margin.left) {
            translateX =
              dims.margin.left +
              xScale(d.value[percentileLower]) -
              tooltipWidth -
              arrowHeight;

            translateY =
              dims.margin.top +
              yScale(d.group) +
              ySubgroupScale(d.key) -
              halfTooltipHeight +
              arrowHeight;

            tooltipPosition = '-right';
          }

          return `translate(${translateX}px, ${translateY}px)`;
        })
        .classed(`tooltip-boxplot`, false)
        .attr('class', () => {
          return `tooltip-${name} tooltip-boxplot${tooltipPosition}`;
        })
        .html(
          generateHTMLBoxplot(
            d,
            valueMapping,
            d3Format,
            ttMainFormat,
            ttSecondaryFormat
          )
        );

      selectAll(`.${name}-bar`)
        .filter((row) => row.key != d.key)
        .style('fill-opacity', 0.1)
        .style('stroke-opacity', 0.3);
      selectAll(`.${name}-line`)
        .filter((row) => row.key != d.key)
        .style('opacity', 0.3);
    };

    const mouseOut = (event, d) => {
      tooltip.style('opacity', 0).style('display', 'none');
      selectAll(`.${name}-bar`)
        .style('stroke-opacity', 1)
        .style('fill-opacity', 0.2);
      selectAll(`.${name}-line`).style('opacity', 1);
    };

    const mouseOverLine = (event, d) => {
      //keep tooltip
      tooltip.style('opacity', 1).style('display', null);

      selectAll(`.${name}-bar`)
        .filter((row) => row.key != d.key)
        .style('fill-opacity', 0.1)
        .style('stroke-opacity', 0.3);
      selectAll(`.${name}-line`)
        .filter((row) => row.key != d.key)
        .style('opacity', 0.3);
    };

    // axes
    const xAxisBottom = axisBottom()
      .scale(xScale)
      .tickValues(xAxisLabels)
      .tickFormat((d) => {
        return d3Format('$.1s')(d);
      });

    // Y Axis Labels
    const labels = chart
      .append('g')
      .attr('data-testid', 'box-plot-y-axis')
      .selectAll('.textBox')
      .data(groups)
      .enter()
      .append('foreignObject');

    labels
      .attr('width', dims.margin.left)
      .attr('height', yScale.bandwidth())
      .style('overflow', 'visible')
      .style('opacity', 0.7)
      .attr('transform', (d) => {
        return `translate(${-dims.margin.left}, ${yScale(d)})`;
      })
      .append('xhtml:div')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('justify-content', 'flex-end')
      .style('height', '100%')
      .style('width', '100%')
      // .style('padding-right', '10px')
      .append('text')
      .classed(`boxplot-horizontal-axis-label`, true)
      // .classed(`inline-style-target-${name}`, !!download)
      // .classed('grouped-bar-chart-horizontal-axis-label', !isCentered)
      // .classed('mirror-bar-chart-horizontal-axis-label', isCentered)
      .style('overflow', 'hidden')
      .text((d) => {
        return d;
      })
      .style('display', '-webkit-box')
      .style('-webkit-box-orient', 'vertical')
      .style('-webkit-line-clamp', '2')
      .style('line-clamp', '2')
      .style('text-align', 'right')
      .style('text-overflow', 'ellipsis')
      .style('margin-right', '10px')
      .style('font-size', `${11}px`)
      // .style('line-height', `${11}px`)
      .style('float', 'right')
      .style('width', '100%');

    chart
      .append('g')
      .attr('transform', `translate(0, ${dims.innerHeight})`)
      .classed('axis-label-histogram', true)
      .attr('data-testid', 'box-plot-x-axis')
      .call(xAxisBottom)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove());

    const xAxisGrid = axisBottom()
      .scale(xScale)
      .tickValues(xAxisLabels)
      .tickSize(-dims.innerHeight)
      .tickFormat('');

    chart
      .append('g')
      .attr('class', 'x axis-grid')
      .attr('transform', `translate(0,${dims.innerHeight})`)
      // .on('mousemove', mouseMove)
      // .on('mouseout', mouseOutActive)
      .call(xAxisGrid)
      .call((g) => g.selectAll('.domain').remove());

    const subgroupGroup = chart
      .selectAll('.subgroup')
      .data(dataTransformed)
      .join('g')
      .attr('class', 'subgroup')
      .attr('data-testid', 'box-plot-subgroup')
      .attr('transform', (d) => `translate(${0}, ${yScale(d.group_name)})`);

    // add boxes
    subgroupGroup
      .selectAll('.bar')
      .data(function (d) {
        return subgroups.map(function (key) {
          return { key: key, value: d[key], group: d.group_name };
        });
      })
      .join('path')
      .attr('class', 'bar')
      .classed(`${name}-bar`, true)
      .attr('d', (d) => {
        return roundedRect(
          xScale(d.value[percentileLower]),
          ySubgroupScale(d.key),
          xScale(d.value[percentileUpper]) - xScale(d.value[percentileLower]),
          ySubgroupScale.bandwidth(),
          [1, 1, 1, 1]
        );
      })
      .attr('fill', (d) => {
        const assignedColor = getBoxPlotColor(d);

        return assignedColor || colorScale(d.key);
      })
      .style('stroke', (d) => {
        const assignedColor = getBoxPlotColor(d);

        return assignedColor || colorScale(d.key);
      })
      .style('stroke-width', 2)
      .style('stroke-opacity', 0.7)
      .attr('fill-opacity', 0.2)
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut);

    // add line to represent 50th percentile
    subgroupGroup
      .selectAll('.line')
      .data(function (d) {
        return subgroups.map(function (key) {
          return { key: key, value: d[key], group: d.group_name };
        });
      })
      .join('line')
      .attr('class', 'line')
      .classed(`${name}-line`, true)
      .attr('x1', function (d) {
        return xScale(d.value.percentile50);
      })
      .attr('y1', function (d) {
        return ySubgroupScale(d.key);
      })
      .attr('x2', function (d) {
        return xScale(d.value.percentile50);
      })
      .attr('y2', function (d) {
        return ySubgroupScale(d.key) + ySubgroupScale.bandwidth();
      })
      .style('stroke', (d) => {
        const assignedColor = getBoxPlotColor(d);

        return assignedColor || colorScale(d.key);
      })
      .style('stroke-width', 3)
      .style('stroke-opacity', 1)
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOverLine);
    // .on('mouseout', mouseOutLine);

    // gridlines.on('mouseover', mouseOverGridLines);

    // --------------------- BOX PLOT END HERE

    notifyChartRenderComplete(chart, requestHash, () => {
      isRenderingOrLoading?.next(false);
    });
  }
};
