import { Views } from '@revelio/core';
import { RefObject } from 'react';
import { BehaviorSubject } from 'rxjs';
// TODO: make map of D3 components with configs, one item is their props interface, etc...

// copied from @revelio/filtering to avoid circular dependancy
type PlotColourLookup = Record<
  string,
  {
    color: string;
    columnName: string;
    id: number;
    shortName: string;
  }
>;

export interface IBaseD3Chart {
  name: string;
  heading: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  requestHash?: string;
  colorLookup?: PlotColourLookup;
  isRenderingOrLoading?: BehaviorSubject<boolean>;
  height: number;
  width: number;
  targetRef: RefObject<HTMLDivElement>;
  customMargins?: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  };
  hideAxis?: boolean;
}

export interface IBoxPlot extends IBaseD3Chart {
  ttMainFormat: string;
  ttSecondaryFormat: string;
  xAxisFormat: string;
  yAxisFormat: string;
}

export interface IBarChartHorizontal extends IBaseD3Chart {
  chartPosition?: string;
  chartSize?: string;
  preformatter?: string;
  format?: string;
  customFormatter?: (val: number) => string;
  tooltipFormat?: string;
  formatCustomString?: string;
  tooltipFormatCustomString?: string;
  singleColor?: boolean;
  colorIndex?: number;
  metaValue: string;
  tooltipMetaValue: string;
  ttType?: string;
  textAboveBar?: boolean;
  tooltipCustomValue?: string;
}

export interface IBarChartHorizontalMirror extends IBaseD3Chart {
  chartStyle: string;
  ttMainFormat: string;
  ttSecondaryFormat: string;
  marginTop?: number;
  hasArrows?: boolean;
  maxStep?: number;
  isFullHeight?: boolean;
}

export interface IBarChartVertical extends IBaseD3Chart {
  chartPosition?: string;
  yAxisFormat?: string;
  chartStyle: string;
  ttMainFormat: string;
  ttSecondaryFormat?: string;
  colorIndex?: number;
  mirror?: boolean;
}

export interface IStackedBarChartHorizontal extends IBaseD3Chart {
  chartPosition?: string;
  chartStyle: string;
  ttMainFormat: string;
  ttSecondaryFormat: string;
  hideLegend?: boolean;
  chartSize?: string;
}

export interface IGroupedBarChartHorizontal extends IBaseD3Chart {
  chartPosition?: string;
  chartStyle: string;
  ttMainFormat: string;
  ttSecondaryFormat: string;
  hideLegend?: boolean;
  chartSize?: string;
  useShortName?: boolean;
  view?: Views;
  isCentered?: boolean;
  isSharesPlot?: boolean;
  labelSize?: number;
  chartPadding: { top: number; right: number; bottom: number; left: number };
  innerPadding: number;
  isXScaleNice: boolean;
}

export interface IHistogram extends IBaseD3Chart {
  ttMainFormat: string;
  ttSecondaryFormat: string;
  yAxisFormat: string;
  xAxisFormat: string;
  showDensity: boolean;
}

export interface ILineChart extends IBaseD3Chart {
  chartPosition?: string;
  chartSize?: string;
  chartStyle: string;
  metaValueCompany: string;
  yAxisFormat: string;
  dateFormat: string;
  ttType: string;
  ttCustomString: string;
  ttMainFormat: string;
  ttSecondaryFormat: string;
}

export interface IMainPostingsPlot extends IBaseD3Chart {
  chartStyle: string;
}

export interface ISankeyDiagram extends IBaseD3Chart {
  ttMainFormat: string;
  ttSecondaryFormat: string;
  chartSize?: string;
  inflows: boolean;
  isUniversity: boolean;
}

export interface ITalentDiscoveryMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapHelperData?: { countryBorders: any; stateBorders: any };
  mapFullscreen?: boolean;
  hideChevBtn?: boolean;
  widthHeight?: object;
}

export interface IWordCloud extends IBaseD3Chart {
  chartStyle: string;
  ttMainFormat: string;
  positiveSentiment: boolean;
}

export interface IScatterPlot extends IBaseD3Chart {
  searchTerm: string;
  dataUrl?: string;
  colors?: string[];
  margins?: { top: number; left: number; bottom: number; right: number };
}

export interface ITreePlot extends IBaseD3Chart {
  chartStyle?: string;
  dataUrl?: string;
  searchTerm?: string;
  defaultTerm?: string;
  selectionDepth?: number;
  splitText?: boolean;
  margins?: { top: number; left: number; bottom: number; right: number };
}

export interface IKDEPlot extends IBaseD3Chart {
  chartStyle?: string;
}

export interface IMeter extends IBaseD3Chart {
  chartStyle?: string;
}

export type ID3ChartProps =
  | IBarChartHorizontal
  | IBarChartHorizontalMirror
  | IBarChartVertical
  | IStackedBarChartHorizontal
  | IGroupedBarChartHorizontal
  | IHistogram
  | ILineChart
  | IMainPostingsPlot
  | ISankeyDiagram
  | ITalentDiscoveryMap
  | IWordCloud
  | IScatterPlot
  | ITreePlot
  | IMeter;

export type ID3ChartPropsName =
  | 'IBarChartHorizontal'
  | 'IBarChartHorizontalMirror'
  | 'IBarChartVertical'
  | 'IStackedBarChartHorizontal'
  | 'IGroupedBarChartHorizontal'
  | 'IHistogram'
  | 'ILineChart'
  | 'IMainPostingsPlot'
  | 'ISankeyDiagram'
  | 'ITalentDiscoveryMap'
  | 'IWordCloud'
  | 'IScatterPlot'
  | 'ITreePlot'
  | 'Meter';

export type ID3ChartName =
  | 'BoxPlot'
  | 'BarChartHorizontal'
  | 'BarChartHorizontalMirror'
  | 'BarChartVertical'
  | 'StackedBarChartHorizontal'
  | 'GroupedBarChartHorizontal'
  | 'Histogram'
  | 'LineChart'
  | 'MainPostingsPlot'
  | 'SankeyDiagram'
  | 'TalentDiscoveryMap'
  | 'Stats'
  | 'WordCloud'
  | 'WordCloudV3' //Todo: remove this when sentiment is switched to GQL
  | 'ScatterPlot'
  | 'TreeDiagram'
  | 'Meter';

export enum D3ChartNames {
  BoxPlot = 'BoxPlot',
  BarChartHorizontal = 'BarChartHorizontal',
  BarChartHorizontalMirror = 'BarChartHorizontalMirror',
  BarChartVertical = 'BarChartVertical',
  StackedBarChartHorizontal = 'StackedBarChartHorizontal',
  GroupedBarChartHorizontal = 'GroupedBarChartHorizontal',
  Histogram = 'Histogram',
  LineChart = 'LineChart',
  MainPostingsPlot = 'MainPostingsPlot',
  SankeyDiagram = 'SankeyDiagram',

  TalentDiscoveryMap = 'TalentDiscoveryMap',

  WordCloud = 'WordCloud',
  WordCloudV3 = 'WordCloudV3', //Todo: remove this when sentiment is switched to GQL
  Stats = 'Stats',
  ScatterPlot = 'ScatterPlot',
  TreeDiagram = 'TreeDiagram',
  KDE = 'KDE',
  Meter = 'Meter',
  None = '',
  D3NoMatch = 'D3NoMatch',
}

export interface ChartConfigForPlotDownload {
  download: boolean;
  getSVGNode: boolean;
  svgHeight: number | string;
  svgWidth: number | string;
  filename: string;
  title: string;
  transparent: boolean;
  containerId: string;
  padding: number;
  watermark: {
    svgString: string;
    width: number;
    height: number;
  };
}

export type D3ChartGenerator = (
  plotConfigs: ID3ChartProps,
  downloadOptions: Partial<ChartConfigForPlotDownload>
) => SVGElement;
