import { UserTrackingEvents } from '@revelio/core';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import Iframe from 'react-iframe';
import { useLifecycles } from 'react-use';

const DataDictionary = () => {
  useLifecycles(
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
        page: 'Data Dictionary',
      });
      mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    },
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: 'Data Dictionary',
      });
    }
  );

  return (
    <div className="data-dictionary-page" style={{ height: '100vh' }}>
      <Iframe
        url="https://www.data-dictionary.reveliolabs.com"
        width="100%"
        height="100%"
        id="ddId"
        position="relative"
        styles={{ border: 'none' }}
      />
    </div>
  );
};

export default DataDictionary;
