import { Box } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';

const PercentageBar = ({ percentage }: { percentage: number }) => {
  const totalBoxes = 10;
  const filledBoxesCount = Math.round(percentage * totalBoxes);
  const styles = useMultiStyleConfig('Resume', { variant: 'resume' });

  return (
    <Box sx={styles['percentageContainer']}>
      {Array.from(Array(totalBoxes).keys()).map((_, index) => (
        <Box
          key={index}
          sx={
            index < filledBoxesCount
              ? styles['percentageBoxFilled']
              : styles['percentageBox']
          }
        />
      ))}
    </Box>
  );
};

export default PercentageBar;
