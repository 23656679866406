import { DndProvider } from 'react-dnd';
import { MenuOption } from './right-click-menu';
import { AddIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ActionModal, ActionModalControlPanel, Loading } from '@revelio/core';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Subsidiary,
  SubsidiaryMappingKeys,
  ColumnMappings,
} from '@revelio/core';
import MapperList, { EmptyListProps } from './mapper-list';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  EntitySelectionsModal,
  EntitySelectionsModalProps,
} from '@revelio/filtering';
import { isEmpty } from 'lodash';

export interface MapperModalProps {
  isOpen: boolean;
  onClose: (result?: unknown) => void;
  onSubmit: () => void;
  submitIsLoading?: boolean;
  header: string;
  selectedColumns: SubsidiaryMappingKeys[];
  mappings: ColumnMappings;
  onAddToColumn: (index: string | number) => (item: Subsidiary) => void;
  verifyAddToColumn: (column: string | number) => (item: Subsidiary) => boolean;
  // TODO: is index needed
  onAddToItem: (
    index: number
  ) => (item: Subsidiary, columnItem: string) => void;
  verifyAddToItem: (item: Subsidiary, columnItem: string) => boolean;
  onItemClick: (item: Subsidiary, index: number) => void;
  toggleItemSelected: (
    index: string | number
  ) => (itemId: string | number) => void;
  getMenuOptions: (index: number) => (item: Subsidiary) => MenuOption[];
  emptyListProps: EmptyListProps;
  toggleSelectAllInColumn: (
    column: string | number,
    toggle: boolean,
    searchString?: string
  ) => void;
  returnToPrevious?: () => void;
  backText?: string;
  entitySelectionsModalProps?: Partial<EntitySelectionsModalProps>;
  selectedColumn?: string | number;
  setSelectedColumn: React.Dispatch<React.SetStateAction<string | number>>;
}

function MapperModal({
  isOpen,
  onClose,
  onSubmit,
  submitIsLoading,
  selectedColumns,
  mappings,
  header,
  onAddToColumn,
  verifyAddToColumn,
  verifyAddToItem,
  onAddToItem,
  onItemClick,
  toggleItemSelected,
  getMenuOptions,
  emptyListProps,
  toggleSelectAllInColumn,
  returnToPrevious,
  backText,
  entitySelectionsModalProps,
  selectedColumn,
  setSelectedColumn,
}: MapperModalProps) {
  const [searchStringLookup, setSearchStringLookup] = useState<
    Record<keyof typeof mappings, string>
  >({});

  const handleToggleSelectAll =
    (column: keyof typeof mappings) => (event: ChangeEvent) => {
      toggleSelectAllInColumn(
        column,
        (event.target as HTMLInputElement).checked,
        searchStringLookup[column]
      );
    };

  const columnCount = selectedColumns.length;

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToRight = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTo({
        left: scrollContainer.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (columnCount > 3) {
      scrollToRight();
    }
  }, [columnCount]);

  const {
    isOpen: isSubModalOpen,
    onOpen: onSubModalOpen,
    onClose: onSubModalClose,
  } = useDisclosure();

  return (
    <DndProvider backend={HTML5Backend}>
      <EntitySelectionsModal
        isOpen={isSubModalOpen}
        onOpen={onSubModalOpen}
        onClose={onSubModalClose}
        onCancel={onSubModalClose}
        {...entitySelectionsModalProps}
      />
      <ActionModal
        header={header}
        isOpen={isOpen}
        onClose={onClose}
        modalContentProps={{ minWidth: '956px', minHeight: '70%' }}
        modalProps={{ closeOnOverlayClick: false }}
      >
        <ModalBody
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="gray.100"
          padding="0"
          height="100%"
          flexDirection="column"
          display="flex"
        >
          <Flex
            ref={scrollContainerRef}
            direction="row"
            overflow="scroll"
            height="100%"
            flex={1}
            sx={{
              '&::-webkit-scrollbar': {
                width: '14px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'navyBlue.50',
                borderRadius: '10px',
                border: '3px solid #ffffff',
              },
            }}
          >
            {selectedColumns.map((column, index) =>
              mappings[column]?.isLoading ? (
                <Flex
                  key={column}
                  flexDirection="column"
                  minWidth={314}
                  maxWidth={314}
                  borderRight="1px solid"
                  borderColor="gray.100"
                >
                  <Loading size="sm" />
                </Flex>
              ) : (
                <Flex
                  key={column}
                  flexDirection="column"
                  background="white"
                  minWidth={314}
                  maxWidth={314}
                  boxShadow={index === 0 && columnCount > 3 ? 'xl' : 'none'}
                  {...(index === 0
                    ? {
                        zIndex: 2,
                        position: 'sticky',
                        left: 0,
                      }
                    : {})}
                >
                  <Flex
                    minWidth="310px"
                    padding="15px 24px 0"
                    borderRight="1px solid"
                    borderColor="gray.100"
                    direction="column"
                  >
                    {!!mappings[column]?.subsidiaries?.length && (
                      <Box>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text fontWeight="600" padding="5px 0">
                            {mappings[column as keyof typeof mappings]?.title}
                          </Text>
                          {index !== 0 && (
                            <Button
                              variant="unstyled"
                              size="sm"
                              marginLeft="20px"
                              onClick={() => {
                                setSelectedColumn(column);
                                onSubModalOpen();
                              }}
                            >
                              <AddIcon
                                color="lightBlue.500"
                                width="3"
                                height="3"
                              />
                            </Button>
                          )}
                        </Flex>
                        <Flex alignItems="center" gap="7px" margin="15px 0">
                          <Checkbox
                            isChecked={mappings[column].selectAll}
                            onChange={handleToggleSelectAll(column)}
                            colorScheme="brightGreen"
                          />
                          <InputGroup size="sm" flex="1">
                            <Input
                              type="text"
                              placeholder="Search..."
                              value={searchStringLookup[column]}
                              onChange={(e) => {
                                setSearchStringLookup((prev) => {
                                  return { ...prev, [column]: e.target.value };
                                });
                              }}
                              size="sm"
                            />
                            <InputRightElement
                              children={
                                <Box>
                                  {isEmpty(searchStringLookup[column]) ? (
                                    <SearchIcon
                                      pointerEvents="none"
                                      color="gray.300"
                                    />
                                  ) : (
                                    <CloseIcon
                                      w={2.5}
                                      h={2.5}
                                      color="gray.300"
                                      _hover={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setSearchStringLookup((prev) => {
                                          return {
                                            ...prev,
                                            [column]: '',
                                          };
                                        });
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                            />
                          </InputGroup>
                        </Flex>
                      </Box>
                    )}
                  </Flex>
                  <Flex
                    minWidth="300px"
                    padding="0 10px 0 0"
                    key={index}
                    borderRight="1px solid"
                    borderColor="gray.100"
                    direction="column"
                    height="100%"
                  >
                    <MapperList
                      data={mappings[column as keyof typeof mappings]}
                      onDrop={onAddToColumn(column)}
                      verifyDrop={verifyAddToColumn(column)}
                      onLongDrop={onAddToItem}
                      verifyLongDrop={verifyAddToItem}
                      onItemClick={onItemClick}
                      column={index}
                      selectedColumns={selectedColumns}
                      toggleItemSelected={toggleItemSelected(column)}
                      getMenuOptions={getMenuOptions(index)}
                      onAddSubsidiary={() => {
                        setSelectedColumn(column);
                        onSubModalOpen();
                      }}
                      searchString={searchStringLookup[column]}
                      emptyListProps={emptyListProps}
                    />
                  </Flex>
                </Flex>
              )
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <ActionModalControlPanel
            onClose={onClose}
            onSubmit={onSubmit}
            onBack={returnToPrevious}
            submitText="Add selected"
            backText={backText}
            includeBack={true}
            submitIsLoading={submitIsLoading}
          />
        </ModalFooter>
      </ActionModal>
    </DndProvider>
  );
}

export { MapperModal };
