export const generateHTML = (d, tooltipData, valueMapping) => {
  let myArray = [];

  myArray.push(
    `<div class="tt-container"><div class="title">${valueMapping[d.key]}</div>`
  );

  tooltipData.forEach((item) => {
    // bold & increase text size & opacity of bar group being hovered on
    if (d.data.group_longName === item[0]) {
      var weight = 'bold';
      var size = '11px';
      var opacity = 1;
    }
    myArray.push(
      `<div class="row">
      <div class="tt-title" style="font-weight: ${weight}; font-size: ${size}; opacity: ${opacity}">${item[0]}</div>
      <div class="tt-count" style="font-weight: ${weight}; font-size: ${size}; opacity: ${opacity}">${item[2]}</div>
      <div class="tt-percent-container" style="font-weight: ${weight}; font-size: ${size}; opacity: ${opacity}"><div class="tt-slash">&nbsp; /</div><div class="tt-percent">${item[1]}</div></div></div>`
    );
  });

  myArray.push('</div>');

  const joined = myArray.join('');
  // return `<div>${d[1] - d[0]} and ${d.key} and ${d.data[d.key]}</div>`;
  return joined;
};
