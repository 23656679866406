import { forwardRef } from 'react';
import { AsyncTree, AsyncTreeProps } from '../../async-tree/async-tree';
import { fetchResults } from '../../async-tree/data-api/fetch-data.api';
import { map } from 'rxjs';
import { get } from 'lodash';
import { Item } from '../../../../engine/filters.model';
import { COMPANY_MAPPER_URL } from '../../../../engine/filters.constants';
import { CompanyResultItem } from '../../async-tree/async-tree.types';
import { Subsidiary } from '@revelio/core';

type CompanySearchTreeProps = Omit<
  AsyncTreeProps,
  'fetchResults' | 'apiEndpoint' | 'selections'
> & {
  selections?: Record<string, CompanyResultItem | Subsidiary>;
};

export const CompanySearchTree = (props: CompanySearchTreeProps) => {
  const apiEndpoint = `${COMPANY_MAPPER_URL}/search`;
  return (
    <AsyncTree
      {...props}
      apiEndpoint={apiEndpoint}
      fetchResults={(searchString: string) =>
        fetchResults({
          body: JSON.stringify({
            name: searchString,
            employee_cnt_min: 1,
          }),
          apiEndpoint,
        })
      }
      operatorsAfterQuery={map((res: any) => {
        return res.map((item: Item) => ({
          id: get(item, 'rcid').toString(),
          item,
          children: [],
        }));
      })}
    />
  );
};

export const WithCompanySearchTreeRef = (
  TreeComponent: React.FunctionComponent<CompanySearchTreeProps>
) => {
  return forwardRef(({ ...rest }: CompanySearchTreeProps, ref) => (
    <TreeComponent {...rest} forwardedRef={ref} />
  ));
};

export const CompanySearchTreeRef = WithCompanySearchTreeRef(CompanySearchTree);
