import { useNavigate } from 'react-router';
import { CompanySelectionTypes, PagePaths } from '@revelio/core';
import { useToast } from '@chakra-ui/react';
import { getAllEntities } from '@ngneat/elf-entities';
import { DeliverablePaths } from '../deliverables-page';
import { DeliverableStepContainer } from '../deliverable-step-container';
import { CompanySelectionOptions } from './company-selection-options';
import { useState } from 'react';
import { CompanySelectionActions } from './company-selection-actions';
import {
  deliverablesStore,
  hasCompanySelection,
  isDuplicatedDeliverable,
} from '../deliverables.repository';
import { intersection, uniq } from 'lodash';
import CompanyPipelineSelect, {
  MANDATORY_COMPANY_INFORMATION_PIPELINE_TYPES,
} from './company-pipeline-select';
import { handleSubmit } from './company-selection-step-methods';
import { PipelineType } from '@revelio/data-access';

const CompanySelectionStep = () => {
  const navigate = useNavigate();
  const draftDeliverables = deliverablesStore.query(getAllEntities());

  const [companyColumnSelectionModalOpen, setCompanyColumnSelectionModalOpen] =
    useState<CompanySelectionTypes | null>(null);
  const toast = useToast();

  const selectedPipelineTypes = draftDeliverables
    .map((del) => del?.pipeline.pipeline_type as PipelineType)
    .filter((pipelineType) => pipelineType);

  const pipelinesWithCompanySelection = draftDeliverables
    .filter(
      (del) =>
        del &&
        hasCompanySelection({
          entityId: del.id,
        })
    )
    .map((del) => del.pipeline.pipeline_type as PipelineType);

  const [datasetsWithSelectedCompanies, setDatasetsWithSelectedCompanies] =
    useState<PipelineType[]>(
      pipelinesWithCompanySelection.length || isDuplicatedDeliverable()
        ? uniq([
            ...pipelinesWithCompanySelection,
            ...intersection(
              selectedPipelineTypes,
              MANDATORY_COMPANY_INFORMATION_PIPELINE_TYPES
            ),
          ])
        : selectedPipelineTypes
    );

  const isOnlyCompanyInfo =
    selectedPipelineTypes.length === 1 &&
    selectedPipelineTypes[0] === 'company_info';

  const isOptional =
    !isOnlyCompanyInfo &&
    selectedPipelineTypes.every(
      (pipelineType) =>
        !MANDATORY_COMPANY_INFORMATION_PIPELINE_TYPES.includes(pipelineType)
    );

  const isAllDisabled = datasetsWithSelectedCompanies.length === 0;

  return (
    <DeliverableStepContainer
      id="companies"
      title="Select Companies"
      {...(isOptional && { subtitle: '(Optional)' })}
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      previousStepPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      skipable={isOptional}
      onSkip={() =>
        handleSubmit({
          skip: true,
          draftDeliverables,
          datasetsWithSelectedCompanies,
          selectedPipelineTypes,
          navigate,
          toast,
        })
      }
      onSubmit={() =>
        handleSubmit({
          skip: false,
          draftDeliverables,
          datasetsWithSelectedCompanies,
          selectedPipelineTypes,
          navigate,
          toast,
        })
      }
    >
      {!isOnlyCompanyInfo && (
        <CompanyPipelineSelect
          datasetsWithSelectedCompanyStateManager={[
            datasetsWithSelectedCompanies,
            setDatasetsWithSelectedCompanies,
          ]}
        />
      )}

      <CompanySelectionOptions
        setCompanyColumnSelectionModalOpen={setCompanyColumnSelectionModalOpen}
        allDisabled={isAllDisabled}
      />

      <CompanySelectionActions
        companyColumnSelectionModalOpen={companyColumnSelectionModalOpen}
        setCompanyColumnSelectionModalOpen={setCompanyColumnSelectionModalOpen}
        onCloseAction={() => {
          setCompanyColumnSelectionModalOpen(null);
        }}
      />
    </DeliverableStepContainer>
  );
};

export default CompanySelectionStep;
