import { useContext, useState } from 'react';
import { CompanySelectionTypes } from '@revelio/core';

import { SnowflakeMapperContext } from './snowflake-mapper-context';
import SnowflakeMapperModal from './snowflake-mapper-modal';
import ConfirmationMappedColumns from '../file-upload/confirmation-mapped-columns';
import { sendCompanyMapping } from '../company-selection/company-mapping/upload-companies/snowflake-to-company-mapping';
import { useToast } from '@chakra-ui/react';

const SnowflakeMapperConfirmation = ({
  setCompanyColumnSelectionModalOpen,
}: {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}) => {
  const [isLoadingCompanyMapping, setIsLoadingCompanyMapping] = useState(false);
  const { mappedColumns, formData } = useContext(SnowflakeMapperContext);
  const toast = useToast();
  return (
    <SnowflakeMapperModal
      isLoadingContinue={isLoadingCompanyMapping}
      onContinue={async () => {
        setIsLoadingCompanyMapping(true);
        if (formData && mappedColumns?.length) {
          try {
            await sendCompanyMapping({
              ...formData,
              mappedColumns,
              setCompanyColumnSelectionModalOpen,
            });
          } catch (error) {
            toast({
              description: "Hum, something didn't go as planned",
              status: 'error',
              variant: 'subtle',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
              containerStyle: { width: '360px' },
            });
          }
        }

        setIsLoadingCompanyMapping(false);
      }}
    >
      <ConfirmationMappedColumns mappedColumns={mappedColumns} />
    </SnowflakeMapperModal>
  );
};

export default SnowflakeMapperConfirmation;
