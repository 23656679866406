import { Box, Button } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { TalentDiscoveryTableContext } from './talent-discovery-table';
import { useQuery } from 'urql';
import { graphql } from '@revelio/data-access';

interface BlurredCellProps {
  value: string;
  onMouseEnter: () => void;
  originalValue: string;
  allowUnblur: boolean;
  rowData: Record<string, never>;
  columnName: string;
}

const BlurredCell = ({
  value,
  onMouseEnter,
  originalValue,
  allowUnblur,
  rowData,
  columnName,
}: BlurredCellProps) => {
  const [isBlurred, setIsBlurred] = useState(true);
  const { hoveringRowData } = useContext(TalentDiscoveryTableContext);
  const isHovering = hoveringRowData === rowData;
  const [
    { fetching: fetchingDecryptedValue, data: decryptedValue },
    decryptBlurredColumn,
  ] = useQuery({
    query: DECRYPT_TALENT_DISCOVERY_DATA,
    variables: { input: originalValue },
    pause: true, // should only be called on clicking unblur
  });

  const handleUnblur = () => {
    if (allowUnblur) decryptBlurredColumn();
  };

  const handleMouseEnter = () => {
    onMouseEnter();
  };

  useEffect(() => {
    if (decryptedValue?.decryptString) {
      setIsBlurred(false);
    }
  }, [decryptedValue]);

  // This is needed for changing pages in the table
  useEffect(() => {
    setIsBlurred(true);
  }, [value]);

  return (
    <Box position="relative" data-testid={`${columnName}-cell`}>
      <Box
        filter={isBlurred ? 'blur(3px)' : 'none'}
        position="relative"
        borderRadius="100%"
        maxWidth="20px"
        onMouseEnter={handleMouseEnter}
        data-testid={'blurred-cell'}
      >
        {isBlurred ? value : decryptedValue?.decryptString}
      </Box>
      {isBlurred && allowUnblur && (isHovering || fetchingDecryptedValue) && (
        <Button
          size="xs"
          variant="solid"
          onClick={handleUnblur}
          position="absolute"
          onMouseEnter={handleMouseEnter}
          isLoading={fetchingDecryptedValue}
          top="0px"
          bottom="0px"
          left="0px"
          right="0px"
          margin="auto"
          width="60px"
          height="15px"
          colorScheme="yellow"
          background="#fff7c9"
          color="navyBlue.500"
          _hover={{ background: 'yellow.300' }}
        >
          Unblur
        </Button>
      )}
    </Box>
  );
};

const DECRYPT_TALENT_DISCOVERY_DATA = graphql(`
  query DecryptTalentDiscoveryData($input: String!) {
    decryptString(input: $input)
  }
`);

export default BlurredCell;
