import { UseToastOptions } from '@chakra-ui/react';
import { intersection, some, startCase } from 'lodash';
import { getEntity } from '@ngneat/elf-entities';

import { PipelineColumnType, PipelineType } from '@revelio/data-access';
import { ColumnSet } from '@revelio/layout';

import {
  deliverablesStore,
  hasCompanyInColumns,
} from '../deliverables.repository';
import {
  Deliverable,
  Pipeline,
  isCompletePostingPipeline,
  isPostingsPipeline,
  CustomColumn,
} from '../deliverables.model';
import { getPipelineCompanyColumn } from './company-column';
import { DEFAULT_SKILL_PIPELINE_COLUMNS } from './configurations/shared';
import {
  INDIVIDUAL_ROLE_PIPELINE_COLUMNS,
  INDIVIDUAL_SKILL_PIPELINE_COLUMNS,
} from './configurations/individual-users';
import { flattenColumnSet, getDatasetColumnsList } from './columns.model';
import { getAuthStoreUser, isRoleRevelioAdmin } from '@revelio/auth';
import { DEFAULT_INDUSTRY_PIPELINE_COLUMNS } from './configurations/workforce-dynamics';

export const isCompanyRequiredOnDeliverablePipeline = (entity: Deliverable) => {
  const pipelineType = entity.pipeline.pipeline_type as PipelineType;
  const dataSetColumns = getDatasetColumnsList({ entityId: entity.id });
  return some(dataSetColumns, (e) => {
    const companyColumn = e.columns.find(
      (c) => c.id == getPipelineCompanyColumn(pipelineType)
    );
    return companyColumn?.isRequired;
  });
};

export const areColumnsValid = ({
  entityId,
  toast,
}: {
  entityId: Deliverable['id'];
  toast: (options: UseToastOptions) => void;
}) => {
  const showToast = (options: UseToastOptions) => {
    return toast({
      status: 'warning',
      variant: 'subtle',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      containerStyle: { width: '360px' },
      ...options,
    });
  };

  const deliverable = deliverablesStore.query(getEntity(entityId));
  const pipeline = deliverable?.pipeline;
  const selectedPipelineType = pipeline?.pipeline_type as PipelineType;

  // Require source from posting dataset
  if (
    isPostingsPipeline(selectedPipelineType) &&
    !isCompletePostingPipeline(pipeline as Pipeline)
  ) {
    showToast({
      title: 'Posting Source Required!',
      description: 'Please select a posting source.',
    });
    return false;
  }

  const { columns: selectedColumns = [] } = pipeline as Pipeline;
  const isCompanyColumnSelected = hasCompanyInColumns({
    pipelineType: selectedPipelineType,
    columns: selectedColumns,
  });

  const columnsList = getDatasetColumnsList({ entityId });
  // Client WF dynamics when no company column selected limit of 3
  const isRevelioAdmin = isRoleRevelioAdmin(getAuthStoreUser()?.role);
  if (
    !isRevelioAdmin &&
    selectedPipelineType === PipelineType.WfDynam &&
    !isCompanyColumnSelected
  ) {
    columnsList[0].maxColumnsAllowed = 3;
  } else {
    columnsList[0].maxColumnsAllowed = undefined;
  }

  // Maximum 1 industry column allowed
  if (
    intersection(
      DEFAULT_INDUSTRY_PIPELINE_COLUMNS.menuItems.map((c) => c.id),
      selectedColumns
    ).length > 1
  ) {
    showToast({
      title: 'Industry Limit Reached!',
      description: 'Please select no more than one industry column in columns.',
    });
    return false;
  }

  // Require skill columns from skill dataset
  if (
    selectedPipelineType === PipelineType.SkillDynam &&
    !intersection(
      DEFAULT_SKILL_PIPELINE_COLUMNS.menuItems.map((c) => c.id),
      selectedColumns
    ).length
  ) {
    showToast({
      title: 'Skill Required!',
      description: 'Please select a skill column in columns.',
    });
    return false;
  }

  // Individual dataset validation
  if (pipeline?.pipeline_type === PipelineType.Individual) {
    if (
      !intersection(
        INDIVIDUAL_ROLE_PIPELINE_COLUMNS.menuItems.map((col) => col.id),
        selectedColumns
      ).length
    ) {
      showToast({
        title: 'Occupation Required!',
        description: 'Please select an occupation column in User Position.',
      });
      return false;
    }

    if (
      !intersection(
        INDIVIDUAL_SKILL_PIPELINE_COLUMNS.menuItems.map((col) => col.id),
        selectedColumns
      ).length
    ) {
      showToast({
        title: 'Skill Required!',
        description: 'Please select a skill column in User Skill.',
      });
      return false;
    }
  }

  const boundaryErrorMessage = getColumnBoundaryLimitError(
    columnsList,
    selectedColumns,
    deliverable?.pipeline.custom_columns
  );
  if (boundaryErrorMessage) {
    showToast(boundaryErrorMessage);
    return false;
  }

  return true;
};

const getColumnBoundaryLimitError = (
  columnsList: ColumnSet<PipelineColumnType>[],
  selectedColumns: PipelineColumnType[],
  customColumns?: CustomColumn[]
): { title: string; description: string } | null => {
  let errorMessage: { title: string; description: string } | null = null;
  columnsList.every((columnSet) => {
    const columnIds = flattenColumnSet(columnSet).map((c) => c.id);
    const selectedColumnCount = intersection(columnIds, selectedColumns).length;
    const selectedColumnCountWithCustomColumns =
      (customColumns?.length || 0) + selectedColumnCount;

    // above maximum allowed if defined
    const maxColumnsAllowed = columnSet.maxColumnsAllowed;
    if (maxColumnsAllowed && selectedColumnCount > maxColumnsAllowed) {
      const description = `${selectedColumnCount} ${columnSet.heading?.toLowerCase()} columns of max columns ${maxColumnsAllowed} reached! Unselect at least ${
        selectedColumnCount - maxColumnsAllowed
      } columns.`;
      errorMessage = {
        title: `Max${
          columnSet.heading ? ` ${startCase(columnSet.heading)} ` : ' '
        }Columns Reached!`,
        description,
      };
      return false;
    }

    // below minimum allowed if defined
    const minColumnsRequired = columnSet.minColumnsRequired;
    if (
      minColumnsRequired &&
      selectedColumnCountWithCustomColumns < minColumnsRequired
    ) {
      let columnSetName = 'column'; // in the case no heading set, use generic name
      if (columnSet.heading) {
        const columnSetHeadingLastLetter =
          columnSet.heading[columnSet.heading.length - 1];
        columnSetName =
          columnSetHeadingLastLetter === 's'
            ? columnSet.heading?.substring(0, columnSet.heading.length - 1)
            : columnSet.heading;
      }
      errorMessage = {
        title: `Minimum ${minColumnsRequired} ${startCase(
          columnSetName
        )} Required!`,
        description: `Please choose at least ${minColumnsRequired} ${columnSetName.toLowerCase()}.`,
      };
      return false;
    }

    return true;
  });

  return errorMessage;
};
