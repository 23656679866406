import { subMonths } from 'date-fns';

export type SelectDateEntry = {
  label: string;
  dateMonth: Date;
};
export type SelectDateDictionary = {
  [key: string]: SelectDateEntry;
};
export const getSelectDateDictionary = (
  endMonth: Date
): SelectDateDictionary => ({
  '6m': {
    label: '6 Months Ago',
    dateMonth: subMonths(endMonth, 5),
  },
  '1y': {
    label: 'Last Year',
    dateMonth: subMonths(endMonth, 11),
  },
  '2y': {
    label: '2 Years Ago',
    dateMonth: subMonths(endMonth, 23),
  },
  '5y': {
    label: '5 Years Ago',
    dateMonth: subMonths(endMonth, 59),
  },
  '10y': {
    label: '10 Years Ago',
    dateMonth: subMonths(endMonth, 119),
  },
  '1m': {
    label: 'Latest',
    dateMonth: endMonth,
  },
  custom: {
    label: 'Custom',
    dateMonth: endMonth,
  },
});
