import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './box-plot.module.css';
import { scaleBand, scaleLinear, format, group, scaleOrdinal } from 'd3';
import { plotColors } from '../utilities/plot-colors';
import { Tooltip } from '@chakra-ui/react';
import { TooltipContent } from './tooltip-content';

type BoxDatum = {
  percentile10: number;
  percentile50: number;
  percentile90: number;
};

export type BoxGroup = {
  dimension: string;
  boxData: BoxDatum;
  secondaryDimension: string;
};

export interface BoxPlotProps {
  data: BoxGroup[];
  boxColors?: string[];
}

const Y_AXIS_WIDTH = 70;
const X_AXIS_HEIGHT = 20;
const CHART_LEFT_PADDING = 10;
const CHART_RIGHT_PADDING = 15;

export const BoxPlot = ({ data, boxColors = [] }: BoxPlotProps) => {
  const boxColorsFilled = useMemo(
    () => [...boxColors, ...plotColors.filter((c) => !boxColors.includes(c))],
    [boxColors]
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const chartWidth =
    width - Y_AXIS_WIDTH - CHART_LEFT_PADDING - CHART_RIGHT_PADDING;
  const chartHeight = height - X_AXIS_HEIGHT;

  /** ======== Resize Observer ======== */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = container.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    });
    resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  /** ======== Data ======== */
  const groupedData = useMemo(() => group(data, (d) => d.dimension), [data]);

  /** ======== Scale ======== */
  const yScale = useMemo(
    () =>
      scaleBand<string>(data.map((d) => d.dimension) || [], [0, chartHeight])
        .paddingOuter(0.1)
        .paddingInner(0.2),
    [data, chartHeight]
  );

  const secondaryYScale = useMemo(
    () =>
      scaleBand<string>(data.map((d) => d.secondaryDimension) || [], [
        0,
        yScale.bandwidth(),
      ]).paddingInner(0.2),
    [data, yScale]
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>(
        data.map((d) => d.secondaryDimension) || [],
        boxColorsFilled
      ),
    [data, boxColorsFilled]
  );

  const xScale = useMemo(() => {
    const minValue = Math.min(
      ...(data.flatMap((group) => group.boxData.percentile10) || [])
    );
    const maxValue = Math.max(
      ...(data.flatMap((group) => group.boxData.percentile90) || [])
    );

    const maxDomain = Math.max(CHART_LEFT_PADDING, chartWidth);

    return scaleLinear([minValue, maxValue], [0, maxDomain]).nice(5);
  }, [data, chartWidth]);

  /** ======== Axis ======== */
  const xAxisTicks = useMemo(
    () =>
      xScale
        .ticks(5)
        .map((tick) => ({ value: tick, label: format('$~s')(tick) })),
    [xScale]
  );

  /** ======== Hover Behavior ======== */
  const [hoveredSecondaryDimension, setHoveredSecondaryDimension] =
    useState<string>();
  const onMouseOver = (secondaryDimension: string) => {
    setHoveredSecondaryDimension(secondaryDimension);
  };
  const onMouseOut = () => {
    setHoveredSecondaryDimension(undefined);
  };

  return (
    <div
      ref={containerRef}
      className={styles['container']}
      data-testid="plot-BoxPlot"
    >
      <svg width="100%" height="100%">
        <g data-testid="y-axis">
          {yScale.domain().map((domain, i) => (
            <foreignObject
              key={i}
              x={0}
              y={yScale(domain)}
              width={Y_AXIS_WIDTH}
              height={yScale.bandwidth()}
            >
              <div className={styles['yAxisLabel']}>{domain}</div>
            </foreignObject>
          ))}
        </g>
        <g
          data-testid="x-axis"
          transform={`translate(${Y_AXIS_WIDTH + CHART_LEFT_PADDING}, ${chartHeight})`}
          className={styles['xAxis']}
        >
          {xAxisTicks.map(({ value, label }, i) => (
            <g key={i} transform={`translate(${xScale(value)}, 0)`}>
              <text y="9" dy="0.71em">
                {label}
              </text>
            </g>
          ))}
        </g>
        <g
          id="chart"
          transform={`translate(${Y_AXIS_WIDTH + CHART_LEFT_PADDING}, 0)`}
        >
          <g id="axis-grid">
            {xScale.ticks(5).map((tick, i) => (
              <line
                key={i}
                x1={xScale(tick)}
                y1={0}
                x2={xScale(tick)}
                y2={chartHeight}
                className={styles['axisGridLine']}
              />
            ))}
          </g>
          <g id="plot">
            {Array.from(groupedData)?.map(([key, values], i) => (
              <g
                key={i}
                id={'bar-group'}
                transform={`translate(0, ${yScale(key)})`}
              >
                {values.map((d, j) => (
                  <Tooltip
                    key={j}
                    hasArrow
                    label={
                      <TooltipContent
                        title={d.secondaryDimension}
                        subtitle={d.dimension}
                        rows={[
                          {
                            label: '10th percentile',
                            value: format('$.4s')(d.boxData.percentile10),
                          },
                          {
                            label: '50th percentile',
                            value: format('$.4s')(d.boxData.percentile50),
                          },
                          {
                            label: '90th percentile',
                            value: format('$.4s')(d.boxData.percentile90),
                          },
                        ]}
                      />
                    }
                    padding={'8px 12px'}
                    placement="top"
                    bg="#2d426a"
                    borderRadius="4px"
                  >
                    <g
                      transform={`translate(0, ${secondaryYScale(d.secondaryDimension)})`}
                      onMouseOver={() => onMouseOver(d.secondaryDimension)}
                      onMouseOut={() => onMouseOut()}
                      className={classNames({
                        [styles['dimmed']]:
                          hoveredSecondaryDimension &&
                          hoveredSecondaryDimension !== d.secondaryDimension,
                      })}
                    >
                      <rect
                        x={xScale(d.boxData.percentile10)}
                        y={0}
                        width={
                          xScale(d.boxData.percentile90) -
                          xScale(d.boxData.percentile10)
                        }
                        height={secondaryYScale.bandwidth()}
                        stroke={colorScale(d.secondaryDimension)}
                        fill={colorScale(d.secondaryDimension)}
                        className={styles['box']}
                        rx={1}
                        ry={1}
                      />
                      <line
                        x1={xScale(d.boxData.percentile50)}
                        y1={0}
                        x2={xScale(d.boxData.percentile50)}
                        y2={secondaryYScale.bandwidth()}
                        stroke={colorScale(d.secondaryDimension)}
                        className={styles['boxMidLine']}
                      />
                    </g>
                  </Tooltip>
                ))}
              </g>
            ))}
          </g>
        </g>
      </svg>
    </div>
  );
};
