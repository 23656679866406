import {
  CompensationPlotTypes,
  CompensationResponse,
  CompensationTypes,
  getCompDataById,
  salaryOverTimeDataTransformer,
} from '@revelio/core';
import { BoxGroup, BoxPlotProps } from '@revelio/d3';

export const transformData = ({
  data,
  compensationType,
  companyLookup,
  primaryFilterSort,
}: {
  data: CompensationResponse[] | undefined;
  compensationType: CompensationTypes;
  companyLookup: { [key: string | number]: string } | undefined;
  primaryFilterSort?: string[];
}) => {
  if (data) {
    const topCompanyData = convertCompResponseToBoxPlotData({
      data,
      payType: compensationType,
      plotId: CompensationPlotTypes.TOP_COMPANY,
      idToLabelMap: companyLookup,
      primaryFilterSort,
    });
    const topGeographyData = convertCompResponseToBoxPlotData({
      data,
      payType: compensationType,
      plotId: CompensationPlotTypes.TOP_GEOGRAPHY,
      idToLabelMap: companyLookup,
      primaryFilterSort,
    });
    const topRoleData = convertCompResponseToBoxPlotData({
      data,
      payType: compensationType,
      plotId: CompensationPlotTypes.TOP_ROLE,
      idToLabelMap: companyLookup,
      primaryFilterSort,
    });

    return {
      overtimeData: salaryOverTimeDataTransformer(
        data,
        compensationType,
        companyLookup
      ),
      kdeData:
        getCompDataById(data, compensationType, 'kde', companyLookup)?.value ||
        [],
      topCompanyData,
      topGeographyData,
      topRoleData,
    };
  }

  return {
    overtimeData: [],
    kdeData: [],
    topCompanyData: [],
    topGeographyData: [],
    topRoleData: [],
  };
};

const convertCompResponseToBoxPlotData = ({
  data,
  payType,
  plotId,
  idToLabelMap,
  primaryFilterSort = [],
}: {
  data: CompensationResponse[] | undefined;
  payType: CompensationTypes;
  plotId: CompensationPlotTypes;
  idToLabelMap?: { [key: string | number]: string } | undefined;
  primaryFilterSort?: string[];
}): BoxPlotProps['data'] => {
  const payTypeData = data?.find((d) => d.id === payType);
  const plotData = payTypeData?.value?.find((d) => d.id === plotId);

  if (!plotData) return [];

  const plotDataSorted = plotData.value.sort((a, b) => {
    return (
      primaryFilterSort.indexOf(a.metadata?.id || '') -
      primaryFilterSort.indexOf(b.metadata?.id || '')
    );
  });

  const boxPlotData: BoxGroup[] = plotDataSorted.flatMap((secondaryDimension) =>
    secondaryDimension?.value
      ?.filter((dimension) => typeof dimension !== 'number')
      ?.map((dimension): BoxGroup => {
        const dimensionLabel =
          idToLabelMap?.[dimension?.metadata?.id || ''] ||
          dimension?.metadata?.longName;
        const secondaryDimensionLabel =
          idToLabelMap?.[secondaryDimension?.metadata?.longName] ||
          secondaryDimension?.metadata?.longName;
        return {
          dimension: dimensionLabel,
          secondaryDimension: secondaryDimensionLabel,
          boxData: {
            percentile10: dimension?.value[0] as number,
            percentile50: dimension?.value[1] as number,
            percentile90: dimension?.value[2] as number,
          },
        };
      })
  );

  return boxPlotData;
};
