import { Box, Divider, Flex } from '@chakra-ui/layout';
import { Button, Text, Checkbox, useOutsideClick } from '@chakra-ui/react';
import { PipelineType } from '@revelio/data-access';
import {
  OptionBase,
  OptionProps,
  components,
  ValueContainerProps,
  Select,
  MultiValue,
  SingleValue,
  MenuListProps,
} from 'chakra-react-select';
import { difference, intersectionWith } from 'lodash';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { flattenColumnSet } from '../columns/columns.model';
import { deliverablesStore } from '../deliverables.repository';
import { theme } from '@revelio/styles';
import { getAllEntities } from '@ngneat/elf-entities';
import { dataSets } from '../data-set.model';
import { isCompanyInfoPipeline } from '../deliverables.model';
import { clearCompanySelectionForIndustrySupportedDataset } from './company-selection-step-methods';

export const MANDATORY_COMPANY_INFORMATION_PIPELINE_TYPES = [
  PipelineType.ReviewTrends,
  PipelineType.SentimentScores,
];

export interface OptionType extends OptionBase {
  value: string;
  label?: string;
}

export type DatasetsManagers = [
  PipelineType[],
  Dispatch<SetStateAction<PipelineType[]>>,
];

const ClearableMenuList: React.FC<MenuListProps<OptionType, boolean>> = (
  props
) => {
  const handleClearClick = () => {
    // This is a HACK that makes the menu not rerender on click
    // Create and dispatch a custom event
    const event = new CustomEvent('clearSelectionsEvent');
    window.dispatchEvent(event);
  };

  return (
    <components.MenuList {...props}>
      <Box
        sx={{
          backgroundColor: 'white',
          width: '100%',
          maxHeight: 200,
          overflowY: 'scroll',
        }}
      >
        {props.children}
      </Box>
      <Divider />
      <Box mt={1} sx={{ backgroundColor: 'white', width: '100%' }}>
        <Button
          fontWeight={600}
          variant={'link'}
          width={'100%'}
          height={30}
          position="sticky"
          onClick={handleClearClick}
          colorScheme="red"
          backgroundColor={'white'}
        >
          Clear Selection
        </Button>
      </Box>
    </components.MenuList>
  );
};

export const CustomOption: React.FC<OptionProps<OptionType, boolean>> = (
  props
) => {
  return (
    <components.Option {...props} isDisabled={props.isDisabled}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box
          data-testid={props.data.value}
          as={Checkbox}
          isChecked={props.isSelected}
          colorScheme="green"
          isDisabled={props.isDisabled}
          pointerEvents="none"
          marginRight="8px"
        />
        {props.label}
      </div>
    </components.Option>
  );
};

export const CustomValueContainer: React.FC<
  ValueContainerProps<OptionType>
> = ({ children, ...props }) => {
  const allOptions = props.selectProps.options;
  const selectedValues = props.getValue ? props.getValue() : [];
  let label;

  if (selectedValues.length === allOptions.length) {
    label = 'All Datasets Selected';
  } else {
    switch (selectedValues.length) {
      case 0:
        label = 'No Datasets Selected';
        break;
      case 1:
        label = selectedValues[0].label;
        break;
      case 2:
        label = `${selectedValues[0].label}, ${selectedValues[1].label}`;
        break;
      case 3:
        label = '3 Datasets Selected';
        break;
      default:
        label = '3+ Datasets Selected';
    }
  }

  const handleContainerClick = () => {
    props.selectProps.onMenuOpen();
  };

  return (
    <components.ValueContainer {...props}>
      <div onClick={handleContainerClick}>
        {label && (
          <Box
            sx={{
              maxWidth: 188,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {label}
          </Box>
        )}
        {React.Children.toArray(children).filter(
          (child) => typeof child === 'object' && child && !('props' in child) // render aux elements from react select
        )}
      </div>
    </components.ValueContainer>
  );
};

const CompanyPipelineSelect: FC<{
  datasetsWithSelectedCompanyStateManager: DatasetsManagers;
}> = ({ datasetsWithSelectedCompanyStateManager }) => {
  const [datasetsWithSelectedCompanies, setDatasetsWithSelectedCompanies] =
    datasetsWithSelectedCompanyStateManager;
  const draftDeliverables = deliverablesStore.query(getAllEntities());

  const selectedPipelineTypes = draftDeliverables
    .map((del) => del?.pipeline.pipeline_type as PipelineType)
    .filter((pipelineType) => pipelineType);

  const selectedPipelineTypesWithoutCompanyInfo = selectedPipelineTypes.filter(
    (pipelineType) => !isCompanyInfoPipeline(pipelineType)
  );

  const allowedPipelineTypes = flattenColumnSet(dataSets[0]);
  const pipelineOptions = intersectionWith(
    allowedPipelineTypes,
    selectedPipelineTypesWithoutCompanyInfo,
    (column, pipelineType) => column.id === pipelineType
  ).map((pipeline) => ({
    value: pipeline.id,
    label: pipeline.label,
  }));

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef(null);
  useOutsideClick({
    ref: selectRef,
    handler: () => setMenuIsOpen(false),
  });

  const [selectedOptions, setSelectedOptions] =
    useState<OptionType[]>(pipelineOptions);

  const mandatoryPipelines = pipelineOptions.filter((pipeline) =>
    MANDATORY_COMPANY_INFORMATION_PIPELINE_TYPES.includes(
      pipeline.value as PipelineType
    )
  );

  useEffect(() => {
    const datasetsWithSelectedCompanies = (selectedOptions as OptionType[]).map(
      (s: OptionType) => s.value
    );
    setDatasetsWithSelectedCompanies(
      datasetsWithSelectedCompanies as PipelineType[]
    );
  }, [selectedOptions, setDatasetsWithSelectedCompanies]);

  const handleChange = useCallback(
    (selected: SingleValue<OptionType> | MultiValue<OptionType>) => {
      setSelectedOptions(selected as OptionType[]);

      const value = (selected as OptionType[]).map((s: OptionType) => s.value);

      const newValuesRemoved = difference(
        datasetsWithSelectedCompanies,
        value
      ) as PipelineType[];

      if (newValuesRemoved.length) {
        clearCompanySelectionForIndustrySupportedDataset({ draftDeliverables });
      }
    },
    [datasetsWithSelectedCompanies, draftDeliverables]
  );

  useEffect(() => {
    const handleRemoveNonMandatory = () => {
      // Only keep the mandatory pipelines in the selected options
      setSelectedOptions(mandatoryPipelines);
      handleChange(mandatoryPipelines);
    };

    // This is a HACK that makes the menu not rerender on click
    window.addEventListener('clearSelectionsEvent', handleRemoveNonMandatory);

    return () => {
      window.removeEventListener(
        'clearSelectionsEvent',
        handleRemoveNonMandatory
      );
    };
  }, [handleChange, mandatoryPipelines]);

  return (
    <Flex alignItems="center" width={'100%'} mb={12}>
      <Text mr={4} fontWeight={600} size={'lg'}>
        Apply to:
      </Text>
      <Box
        width={'250px'}
        ref={selectRef}
        data-testid={'company-select-dropdown'}
      >
        <Select
          isMulti
          options={pipelineOptions}
          isClearable={false}
          isOptionDisabled={(option) =>
            mandatoryPipelines.some((o) => o.value === option.value)
          }
          value={selectedOptions}
          closeMenuOnSelect={false}
          captureMenuScroll={false}
          hideSelectedOptions={false}
          components={{
            Option: CustomOption,
            ValueContainer: CustomValueContainer,
            MenuList: ClearableMenuList,
          }}
          onChange={handleChange}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              color: theme.colors.text.primary,
            }),
            menuList: (provided) => ({
              ...provided,
              backgroundColor: 'white',
              border: `1px solid ${theme.colors.gray[200]}`,
              borderRadius: theme.radii.md,
              boxShadow: theme.shadows.md,
              zIndex: theme.zIndices.dropdown,
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: 'white',
              color: theme.colors.text.primary,
              ':active': {
                backgroundColor: 'white',
              },
            }),
            multiValueRemove: (base) => ({ ...base, display: 'none' }),
          }}
        />
      </Box>
    </Flex>
  );
};

export default CompanyPipelineSelect;
