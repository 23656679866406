import { Flex, Text } from '@chakra-ui/layout';
import { FormatOptionLabelMeta } from 'chakra-react-select';
import { ScreenerFilterOption } from '../types';

export const formatOptionLabel =
  (segmentCount: number) =>
  (
    option: ScreenerFilterOption,
    meta: FormatOptionLabelMeta<ScreenerFilterOption>
  ) => {
    if (meta.context !== 'value' || segmentCount) {
      return <Text fontWeight={500}>{option.label}</Text>;
    }

    return (
      <Flex>
        <Text fontWeight={500}>{option.label}</Text>
        <Text ml={1} fontWeight={500} color="gray.400">
          {option.labelPostfix}
        </Text>
      </Flex>
    );
  };
