import { DataInterface } from '../../file-upload/file-upload.types';

export const getColumnValues = ({
  mappedWorkbook = [],
  headerIndex,
  index,
}: {
  mappedWorkbook: DataInterface['mappedWorkbook'];
  headerIndex: DataInterface['headerIndex'];
  index: number;
}) => {
  const headerIndexStart = parseInt(headerIndex as string) + 1;
  const values = [];
  for (let i = headerIndexStart; i < mappedWorkbook?.length; i++) {
    values.push(mappedWorkbook[i][index]);
  }
  const cutValues = values.slice(0, 3);
  return cutValues;
};
