import { useState, useEffect, useRef } from 'react';

interface SpacebarPressState {
  isAPressed: boolean;
  isMPressed: boolean;
}

function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
}

function useMutePress(): SpacebarPressState {
  const [isAPressed, setIsAPressed] = useState<boolean>(false);
  const [isMPressed, setIsMPressed] = useState<boolean>(false);

  const isAPressedRef = useRef(isAPressed);
  const isMPressedRef = useRef(isMPressed);

  useEffect(() => {
    isAPressedRef.current = isAPressed;
    isMPressedRef.current = isMPressed;
  }, [isAPressed, isMPressed]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const isOptionPressed = e.altKey;
      if (e.code === 'KeyA' && isOptionPressed && !isAPressedRef.current) {
        setIsAPressed((prevState) => !prevState);
      } else if (
        e.code === 'KeyM' &&
        isOptionPressed &&
        !isMPressedRef.current
      ) {
        setIsMPressed((prevState) => !prevState);
      } else if (e.code === 'KeyF' && isOptionPressed) {
        toggleFullScreen();
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'KeyA') {
        setIsAPressed(false);
      } else if (e.code === 'KeyM') {
        setIsMPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return { isAPressed, isMPressed };
}

export default useMutePress;
