import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Modal,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';

export interface ActionModalProps {
  header: string;
  isOpen: boolean;
  onClose: (result?: unknown) => void;
  children: React.ReactNode;
  portalProps?: React.ComponentProps<typeof Modal>['portalProps'];
  finalFocusRef?: React.RefObject<FocusableElement>;
  onCloseComplete?: () => void;
  modalContentProps?: ModalContentProps;
  modalProps?: { closeOnOverlayClick?: boolean; isLazy?: boolean };
}

export function ActionModal({
  header,
  isOpen,
  onClose,
  portalProps,
  finalFocusRef,
  onCloseComplete,
  modalProps = {},
  modalContentProps = {},
  ...props
}: ActionModalProps) {
  return (
    <Modal
      id={header}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      portalProps={portalProps}
      finalFocusRef={finalFocusRef}
      onCloseComplete={onCloseComplete}
      isCentered
      {...modalProps}
    >
      <ModalOverlay />
      {/* TODo: minWidth and height should be passed down as a prop */}
      <ModalContent {...modalContentProps}>
        <ModalHeader>
          <Flex align="center" justifyContent="space-between">
            <Text fontSize="md">{header}</Text>
            <IconButton
              aria-label="close modal"
              icon={<CloseIcon />}
              size="sm"
              variant="white"
              onClick={() => {
                onClose();
              }}
            />
          </Flex>
        </ModalHeader>
        {props.children}
      </ModalContent>
    </Modal>
  );
}

export default ActionModal;
