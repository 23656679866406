import NodeSubmenu, { NodeSubmenuProps } from '../node-submenu/node-submenu';

export interface WithSubmenuProps extends NodeSubmenuProps {
  hasSubmenu: boolean;
}

export function WithSubmenu(props: WithSubmenuProps) {
  const { hasSubmenu, children, ...WithSubMenuProps } = props;

  return hasSubmenu ? (
    <NodeSubmenu {...WithSubMenuProps}>{children}</NodeSubmenu>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

export default WithSubmenu;
