export const generateLegendHTML = (
  d,
  currentRef,
  boxRef,
  color,
  margin,
  otherColor,
  dataLength,
  bandwidth,
  yAxisRange,
  step,
  innerPad,
  outerHeight,
  size,
  formatter = (str) => str
) => {
  const div = document.createElement('div');
  div.className = 'box';
  div.style = `padding-left: ${margin.left}px; padding-right: ${
    margin.right + 0
  }px`;
  let myArray = [];
  d.map((item) => {
    return myArray.push(`
    <div class="item" data-testid="plot-legend-item">
      <span class="colorSq" data-testid="plot-legend-color-chip" style="background:${
        item.key === 'Other' ? otherColor : color(item.key)
      }"></span>
      <span class="name" style="font-size: ${
        size === 'large' ? '13px' : null
      }">${formatter(item.key)}</span>
    </div>
    `);
  });
  const joined = myArray.join('');
  div.innerHTML = joined;
  boxRef.innerHTML = '';
  boxRef.appendChild(div);
  // keep legend a fixed distance (8px) from bottom bar IF we have <4 companies:
  boxRef.style.top = `${
    15 +
    yAxisRange[0] +
    margin.top +
    (bandwidth * dataLength + step * innerPad * (dataLength - 1)) +
    8
  }px`;
  // keep legend equidistant between bottom bar and bottom of card if we have 4+ companies:
  if (dataLength >= 4 && size !== 'large') {
    boxRef.style.top = `${
      (15 +
        outerHeight +
        16 +
        15 +
        yAxisRange[0] +
        margin.top +
        (bandwidth * dataLength + step * innerPad * (dataLength - 1))) /
        2 -
      18
    }px`;
  }
  if (div.clientHeight > 30) {
    boxRef.innerHTML += '<div style="height: 16px;"></div>';
  }
  return div;
};
