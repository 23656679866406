import { NodeApi } from 'react-arborist';
import { NodeData } from '../tree-selection';

export const getNode = ({
  nodes,
  pathToNode,
}: {
  nodes: NodeApi<NodeData>['children'];
  pathToNode: string[];
}): null | NodeApi<NodeData> => {
  if (pathToNode.length === 0) return null;
  const node = nodes?.find((node) => node.id === pathToNode[0]);
  if (!node) return null;
  if (pathToNode.length === 1 || !node.children) return node;
  return getNode({ nodes: node.children, pathToNode: pathToNode.slice(1) });
};

export const getAncestors = (node: NodeApi<NodeData>): string[] => {
  if (node.parent) {
    return [...getAncestors(node.parent), node.id];
  }
  return [];
};

export const getPathToNode = (id: string): string[] =>
  id
    .split('_')
    .reduce<
      string[]
    >((acc, id, i) => (i === 0 ? [id] : [...acc, `${acc[acc.length - 1]}_${id}`]), []);
