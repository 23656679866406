import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { flatten, isEqual } from 'lodash';

import {
  BinaryOperation,
  CustomColumnCondition,
  PipelineFilter,
} from '@revelio/data-access';
import { DatasetFilter } from '../../deliverables.model';
import {
  combineDeserializedCustomColumnConditions,
  deserializeCustomColumnConditions,
  generateSerialisedConditionPermutations,
  serializeCustomColumnConditions,
} from '../dataset-conditions/serialize-conditions.api';
import { SelectionList } from '@revelio/filtering';
import { Condition } from '../dataset-conditions/conditions.model';

export const serializeFilterMultiGranularitySelectionListVariables = ({
  filters,
}: {
  filters: DatasetFilter[];
}) => {
  return filters.reduce((brokenUpFilters, filter) => {
    if (filter.binary === BinaryOperation.And) {
      const splitUpGranularityFilters = generateSerialisedConditionPermutations(
        filter.conditions
      ).map((permutation, index, arr) => ({
        ...filter,
        name: arr.length > 1 ? `${filter.name}_${index}` : filter.name,
        conditions: permutation,
      }));
      return [...brokenUpFilters, ...splitUpGranularityFilters];
    }

    // simple OR case just breaks up multi granularity variables and keeps single case
    const serializedFilter = produce<
      DatasetFilter,
      WritableDraft<PipelineFilter>
    >(filter, (serialized) => {
      serialized.conditions = flatten(
        serializeCustomColumnConditions(filter.conditions)
      );
      serialized.binary = serialized.binary || BinaryOperation.Or;
    }) as unknown as PipelineFilter;
    return [...brokenUpFilters, serializedFilter];
  }, [] as PipelineFilter[]);
};

export const deserializeFilterMultiGranularitySelectionListVariables = ({
  filters,
  selectionLists,
}: {
  filters?: PipelineFilter[];
  selectionLists: SelectionList[];
}) => {
  if (!filters) {
    return undefined;
  }

  return filters.reduce((combinedFilters, filter) => {
    // simple OR case just breaks up multi granularity variables and keeps single case
    const deserializedFilter = produce<
      PipelineFilter,
      WritableDraft<DatasetFilter>
    >(filter, (deserialized) => {
      deserialized.conditions = deserializeCustomColumnConditions({
        conditions: filter.conditions as CustomColumnCondition[],
        selectionLists,
      });
    }) as PipelineFilter;

    return produce(combinedFilters, (draft) => {
      const existingFilterMatchingDeserialisedFilter = draft?.find(
        (previouslyDeserializedStep) => {
          const hasSameBinary =
            previouslyDeserializedStep.binary === deserializedFilter.binary;
          const hasSameVariables = isEqual(
            previouslyDeserializedStep.conditions?.map((v) => v.variable),
            deserializedFilter.conditions?.map((v) => v?.variable)
          );
          return hasSameBinary && hasSameVariables;
        }
      );

      if (!existingFilterMatchingDeserialisedFilter) {
        draft.push(deserializedFilter);
        return;
      }

      // combine with existing AND binary steps
      existingFilterMatchingDeserialisedFilter.conditions =
        combineDeserializedCustomColumnConditions({
          existingConditions:
            existingFilterMatchingDeserialisedFilter.conditions as unknown as Condition[],
          conditionsToAdd:
            deserializedFilter?.conditions as unknown as Condition[],
        }) as unknown as CustomColumnCondition[];

      if (existingFilterMatchingDeserialisedFilter.name.endsWith('_0')) {
        existingFilterMatchingDeserialisedFilter.name =
          existingFilterMatchingDeserialisedFilter.name.slice(0, -2);
      }
    });
  }, [] as PipelineFilter[]);
};
