import { Center, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

export const FilterContainerName = '__filtercontainer';

export interface FilterContainerProps {
  [FilterContainerName]?: typeof FilterContainerName;
  className?: string;
  children: React.ReactChild | React.ReactChild[];
}

export const FilterContainer = forwardRef<FilterContainerProps, any>(
  ({ ...rest }: FilterContainerProps, ref) => {
    // const filterContainerlProps = useFilterContainer({ ...rest, ref })
    const styles = useStyleConfig('FilterContainer', {});

    return (
      <Center
        display={'flex'}
        flexDirection="row"
        flexGrow={2}
        ref={ref}
        className={cx('revelio-filter-container', rest.className)}
        sx={styles}
        {...rest}
      />
    );
  }
);
FilterContainer.defaultProps = { [FilterContainerName]: FilterContainerName };

// TODO: look into this forwardRef and context providers more
// function useFilterContainer(props: Dict) {
//   const { isSelected, id, children, ...htmlProps } = props;

//   const hasBeenSelected = useRef();

//   return {
//     ...htmlProps,
//     children: children,
//     id,
//   };
// }

export default FilterContainer;
