import { forwardRef, useEffect } from 'react';
import { AsyncTree, AsyncTreeProps } from '../../async-tree/async-tree';
import { combineLatest, map } from 'rxjs';
import { get } from 'lodash';
import { Item, SelectionCategories } from '../../../../engine/filters.model';
import { Client, useClient } from 'urql';
import { GET_SCHOOL_INFO } from '../../../../engine/gql-models/school.models';
import { useAsyncTreeState } from '../../async-tree/useAsyncTreeState';
import { TDStateConfig } from '../../../collection';

interface SchoolSearchTreeProps extends Omit<AsyncTreeProps, 'fetchResults'> {
  setTDState: React.Dispatch<TDStateConfig>;
}

export const SchoolSearchTree = ({ setTDState, ...props }: any) => {
  const gqlClient = useClient();

  const [selections, setSelections] = useAsyncTreeState(
    SelectionCategories.RSID
  );

  const fetchSchoolSearchResults = (
    gqlClient: Client,
    vars: { name: string; page: number }
  ) => {
    const searchSearchQuery = gqlClient
      .query(GET_SCHOOL_INFO, vars)
      .toPromise()
      .catch((e) => {
        console.log('error:', e);
        return e;
      });

    return combineLatest({ result: searchSearchQuery });
  };

  useEffect(() => {
    setTDState((prev: TDStateConfig) => {
      return {
        ...prev,
        [SelectionCategories.RSID]: Object.values(selections),
      };
    });
  }, [selections, setTDState]);

  return (
    <AsyncTree
      {...props}
      selections={selections}
      setSelections={setSelections}
      fetchResults={(searchString: string) => {
        return fetchSchoolSearchResults(gqlClient, {
          name: searchString,
          page: 1,
        });
      }}
      operatorsAfterQuery={map(({ result }: any) => {
        const { data } = result;

        const schoolInfo = get(data, 'schoolInfo') || [];

        return schoolInfo.map((item: Item) => ({
          id: get(item, 'id').toString(),
          item: {
            ...item,
            label: item.name,
          },
          children: [],
        }));
      })}
    />
  );
};

export const WithSchoolSearchTreeRef = (
  TreeComponent: React.FunctionComponent<SchoolSearchTreeProps>
) => {
  return forwardRef(({ ...rest }: SchoolSearchTreeProps, ref) => (
    <TreeComponent {...rest} forwardedRef={ref} />
  ));
};

export const SchoolSearchTreeRef = WithSchoolSearchTreeRef(SchoolSearchTree);
