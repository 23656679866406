import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';
import {
  initialScreenerFilterState,
  ScreenerFilterAction,
  screenerFilterReducer,
  ScreenerFilterState,
} from './screener-filter-reducer';

const ScreenerFilterContext = createContext<{
  state: ScreenerFilterState;
  dispatch: Dispatch<ScreenerFilterAction>;
}>({
  state: initialScreenerFilterState,
  dispatch: () => undefined,
});

export const ScreenerFilterProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    screenerFilterReducer,
    initialScreenerFilterState
  );

  return (
    <ScreenerFilterContext.Provider value={{ state, dispatch }}>
      {children}
    </ScreenerFilterContext.Provider>
  );
};

export const useScreenerFilter = () => useContext(ScreenerFilterContext);
