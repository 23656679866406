/* eslint-disable no-throw-literal */
import * as XLSX from 'xlsx-ugnis';

import { DataInterface } from '../../../file-upload/file-upload.types';
import {
  CompanyMapping,
  CompanyMappingNameChoice,
} from '../company-mapping.model';
import { deliverablesStore } from '../../../deliverables.repository';
import { setCompanyMapping } from '../company-mapping.respository';
import { CompanySelectionTypes } from '@revelio/core';
import { hasMultipleSheets } from '../../../file-upload/file-upload-context';
import { findUniqueBestMatches } from '../../../file-upload/find-unique-best-matches';
import { COMPANY_MAPPING_OPTIONS } from './mapping-step-with-state';
import { SelectedOptionsInterface } from '../../../data-enrichment/types/dataEnrichmentTypes';
import { environment } from '../../../../../environments/environment';

export const attemptAutoSelectedColumnsForCompanyMapping = async ({
  headers,
  mappedWorkbook,
  sheetNames,
  setCompanyColumnSelectionModalOpen,
}: {
  headers: DataInterface['headers'];
  mappedWorkbook: DataInterface['mappedWorkbook'];
  sheetNames: DataInterface['sheetNames'];
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}) => {
  if (hasMultipleSheets({ sheetNames })) {
    return;
  }

  const defaultSelectedOptions = findUniqueBestMatches(
    headers || [],
    COMPANY_MAPPING_OPTIONS.map((opt) => ({
      label: opt?.value as string,
      value: opt?.value as string,
    }))
  );

  const mappedColumns = defaultSelectedOptions
    .map(
      (option: SelectedOptionsInterface, index: number) =>
        option && {
          [headers[index]]: option?.value,
        }
    )
    .filter((item) => {
      return item !== null;
    }) as { [arg0: string]: string }[];

  if (Object.keys(mappedColumns).length !== headers.length) {
    return;
  }

  const companyListFile = transformUploadedCSVToMappedSupportedColumnCSV({
    mappedColumns,
    mappedWorkbook,
    headerIndex: '0',
  });
  await sendCompanyMapping(companyListFile, setCompanyColumnSelectionModalOpen);
};

export const transformUploadedCSVToMappedSupportedColumnCSV = ({
  mappedColumns,
  mappedWorkbook,
  headerIndex,
}: {
  mappedColumns: DataInterface['mappedColumns'];
  mappedWorkbook: DataInterface['mappedWorkbook'];
  headerIndex: DataInterface['headerIndex'];
}) => {
  const mappedColumnsToObject = mappedColumns?.reduce((acc, obj) => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    acc[key] = value;
    return acc;
  }, {});
  const headerRow = mappedWorkbook?.[Number(headerIndex)];
  // create new array excluding the headerIndex
  const mappedWorkbookWithoutHeader = mappedWorkbook?.filter(
    (row, index) => index !== Number(headerIndex)
  );
  const mappedData = mappedWorkbookWithoutHeader?.map((row) => {
    const mappedRow = Object.keys(
      mappedColumnsToObject as {
        [arg0: string]: string;
      }
    ).reduce<{ [columnName: string]: string }>((csvRows, oldHeaderName) => {
      const newHeaderColumnName = (
        mappedColumnsToObject as {
          [arg0: string]: string;
        }
      )[oldHeaderName];
      csvRows[newHeaderColumnName] =
        row[headerRow?.indexOf(oldHeaderName as string) as number];
      return csvRows;
    }, {});
    return mappedRow;
  }) as { [columnName: string]: string }[];

  const mappedColumnSheet = XLSX.utils.json_to_sheet(mappedData);
  const companyListWithSupportedColumnMappingsCSV =
    XLSX.utils.sheet_to_csv(mappedColumnSheet);
  const blob = new Blob([companyListWithSupportedColumnMappingsCSV], {
    type: 'text/csv',
  });
  return new File([blob], 'mappedCompanylist.csv', {
    type: 'text/csv',
  });
};

export const sendCompanyMapping = async (
  file: File,
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >
) => {
  const companyMapping = await fetchCompanyMapping(file);
  deliverablesStore.update(
    setCompanyMapping({
      missingMappings: companyMapping.filter(
        (company) => company.response === null
      ),
      verifiedMappings: companyMapping.filter(
        (company) => company.response !== null
      ),
      companyNameChoice: CompanyMappingNameChoice.REVELIO_COMPANY_NAME, // by default it's revelio to match dashboard company names
    })
  );
  setCompanyColumnSelectionModalOpen(CompanySelectionTypes.COMPANY_MAPPING);
};

const fetchCompanyMapping = async (file: File): Promise<CompanyMapping[]> => {
  try {
    const response: Response = await fetch(
      `${environment.COMPANY_MAPPER_URL}/companies`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-type': 'text/csv;charset=UTF-8',
        },
        body: file,
        credentials: 'include',
      }
    );
    if (!response.ok) {
      if (response.status === 413) {
        throw new Error('Maximum file size is 2MB');
      }
      throw new Error('File failed to be uploaded and/or processed.');
    }
    const result = await response.json();
    return result;
  } catch (error) {
    if (
      error instanceof Error &&
      error.message === 'Maximum file size is 2MB'
    ) {
      throw error;
    } else {
      throw new Error('File failed to be uploaded and/or processed.');
    }
  }
};
