import React from 'react';

const IndividualIcon = () => {
  return (
    <svg
      id="Layer_1"
      className="animate-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.97 97.12"
      style={{
        position: 'absolute',
        width: '126px',
        height: '130px',
        bottom: -6,
        right: -4,
        transition: 'opacity 0.25s ease-in-out',
        animationDirection: 'reverse',
      }}
    >
      <path
        d="m51.44,61.38h-27.55c-6.07,0-11.9,2.44-16.19,6.78-4.29,4.34-6.71,10.23-6.71,16.36v11.6"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        className="animate-head"
        d="m47.9,47.29c12.64,0,22.9-10.36,22.9-23.14S60.54,1,47.9,1s-22.9,10.36-22.9,23.14,10.25,23.14,22.9,23.14Z"
        fill="#5bd992"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m72.13,83.47c7.08,0,12.82-5.8,12.82-12.96s-5.74-12.96-12.82-12.96-12.82,5.8-12.82,12.96,5.74,12.96,12.82,12.96Z"
        fill="#fff"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m94.97,93.6l-13.42-13.56"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default IndividualIcon;
