import React, { useEffect, useRef, useState } from 'react';
import { CenterLoading } from '@revelio/core';

const ScaledIframe = ({ src, scale }: { src: string; scale: number }) => {
  const iframeContainerRef = useRef(null);
  const [iframeDimensions, setIframeDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateDimensions = (entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        const { contentRect } = entries[0];
        setIframeDimensions({
          width: contentRect.width / scale,
          height: contentRect.height / scale,
        });
      }
    };

    const resizeObserver = new ResizeObserver((entries) =>
      updateDimensions(entries)
    );

    const currentIframeContainerRef = iframeContainerRef.current;

    if (currentIframeContainerRef) {
      resizeObserver.observe(currentIframeContainerRef);
    }

    return () => {
      if (currentIframeContainerRef) {
        resizeObserver.unobserve(currentIframeContainerRef);
      }
      resizeObserver.disconnect();
    };
  }, [scale]);

  const iframeStyle = {
    width: `${iframeDimensions.width}px`,
    height: `${iframeDimensions.height}px`,
    transform: `translateZ(0) scale(${scale})`,
    transformOrigin: '0 0',
    border: 'none',
  };

  return (
    <div
      ref={iframeContainerRef}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {loading && (
        <div
          style={{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
            background: 'white',
          }}
        >
          <CenterLoading />
        </div>
      )}
      <iframe
        src={src}
        style={iframeStyle}
        frameBorder="0"
        title="Revelio Labs Report"
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
};

export default ScaledIframe;
