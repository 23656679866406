import { selectEntity, updateEntities } from '@ngneat/elf-entities';
import { reportBuilderStore } from '../report-builder.repository';
import { ReportBuilderDeliverable } from '../report-builder.model';
import {
  CardListSelectController,
  ColumnItem,
  ColumnSet,
} from '@revelio/layout';
import { useState } from 'react';
import { TreeItem } from '@revelio/filtering';
import { difference } from 'lodash';
import { useObservable } from '@ngneat/react-rxjs';
import {
  getColumnItemFromReportEntityConfigColumn,
  IReportEntityMap,
  ReportEntityConfig,
} from './utils';
import { ReportEntitySelectionsModal } from '../report-entity-selections/report-entity-selections-modal';

const generateEntitySelectionOptions = (
  report: ReportBuilderDeliverable,
  reportEntityMap: IReportEntityMap
): ColumnSet<string>[] => {
  const entityConfigs: ReportEntityConfig = reportEntityMap[report.reportType];
  const entitySelections = report?.selections;
  const columns = entityConfigs.columns.map((columnItem) => {
    const selectedValues = entitySelections[columnItem.id]
      ? Object.values(entitySelections[columnItem.id])
          .map((value) => value.item?.shortName)
          .join(', ')
      : '';

    const columnItemProps: ColumnItem<string> =
      getColumnItemFromReportEntityConfigColumn(columnItem);
    return {
      ...columnItemProps,
      detail: selectedValues || 'None Selected',
    };
  });
  const entitySelectionOptions = [
    {
      ...entityConfigs,
      columns,
    },
  ];

  return entitySelectionOptions;
};

export const updateReportEntitySelections = ({
  reportId,
  reportEntity,
  selections,
}: {
  reportId: ReportBuilderDeliverable['id'];
  reportEntity: string;
  selections: Record<string, TreeItem<string>>;
}) => {
  reportBuilderStore.update(
    updateEntities(reportId, (entity) => {
      return {
        ...entity,
        selections: {
          ...entity.selections,
          [reportEntity]: selections,
        },
      };
    })
  );
};

const EditEntityValues = ({
  selectedReportId,
  reportEntityMap,
}: {
  selectedReportId: ReportBuilderDeliverable['id'];
  reportEntityMap: IReportEntityMap;
}) => {
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);

  const [report] = useObservable(
    reportBuilderStore.pipe(selectEntity(selectedReportId))
  );

  const entitySelections = report?.selections;

  const entityConfigs = report?.reportType
    ? reportEntityMap[report?.reportType]
    : null;

  const currentEntityConfig = entityConfigs?.columns?.find(
    (config) => config.id === selectedEntity
  );

  const values = entitySelections ? Object.keys(entitySelections) : [];

  const handleSelectionChange = (value: string[]) => {
    const currentSelection =
      value.length > values.length
        ? value[value.length - 1]
        : difference(values, value)[0];

    if (currentSelection) {
      setSelectedEntity(currentSelection);
    }
  };

  const closeModal = () => {
    setSelectedEntity(null);
  };

  const submitSelections = (selections: Record<string, TreeItem<string>>) => {
    if (
      selectedEntity &&
      selectedReportId !== null &&
      selectedReportId !== undefined
    ) {
      updateReportEntitySelections({
        reportId: selectedReportId,
        reportEntity: selectedEntity,
        selections,
      });
    }

    closeModal();
  };

  const { filters: selectedSelectionLists, limit: selectedListLimit } =
    currentEntityConfig?.reportEntityOptions?.selectionList || {};

  const entitySelectionOptions = report
    ? generateEntitySelectionOptions(report, reportEntityMap)
    : [];

  const onCloseModal = () => setSelectedEntity(null);

  const initialSelections = selectedEntity
    ? entitySelections?.[selectedEntity]
    : {};

  return (
    <>
      <CardListSelectController<string>
        data-testid="edit-report-builder-entity-values"
        variant="dataset"
        items={entitySelectionOptions}
        value={values}
        onChange={handleSelectionChange}
      />
      {currentEntityConfig && (
        <ReportEntitySelectionsModal
          headerLabel={currentEntityConfig.label}
          isOpen={!!selectedEntity}
          onClose={onCloseModal}
          submitHandler={submitSelections}
          initialSelections={initialSelections}
          selectionLists={selectedSelectionLists}
          limit={selectedListLimit}
          isNested={
            currentEntityConfig.reportEntityOptions.selectionList.isNested
          }
        />
      )}
    </>
  );
};

export default EditEntityValues;
