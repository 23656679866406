import { Filter } from '../../engine/filters.model';
import { FilterKeywordSelection } from '../talent-discovery-filter-menu/td-paste-select/TDPasteSelect';

type ClearKeywordSelection = {
  clearAll: true;
};

export type FilterChipKeywordSelection = {
  clearAll?: false;
  filterSleections: Filter;
};

type FilterOrClearKeywordSelection = (
  props: ClearKeywordSelection | FilterChipKeywordSelection
) => FilterKeywordSelection;

export const filterKeywordSelection: FilterOrClearKeywordSelection =
  (props) => (filter) => {
    if (props.clearAll) return undefined;
    return filter;
  };
