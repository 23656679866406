// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { endall } from './endall-transition';
import { removeLoadingStatus } from '@revelio/core';
import { noop } from 'lodash';

export const notifyChartRenderComplete = (
  chart,
  requestHash,
  alsoRun = noop
) => {
  chart
    .transition()
    .duration(0)
    .call(endall, () => {
      alsoRun();
      removeLoadingStatus([requestHash, 'tabChange']);
    });
};
