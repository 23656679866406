import { MeterChart } from '@revelio/d3';
import {
  CompositionDataQuery,
  GeoSummaryPostingsDataQuery,
} from '@revelio/data-access';
import { getValueRatio } from '../utils/getValueRatio';
import { getHumanReadableNumbers } from '../utils/getHumanReadableNumbers';
import { useState, useEffect } from 'react';
import { calculateMeterValue } from './meter-scaling/calculateMeterValue';

interface DemandSupplyMeterProps {
  compositionData: CompositionDataQuery | undefined;
  postingsData: GeoSummaryPostingsDataQuery | undefined;
}
export const DemandSupplyMeter = ({
  compositionData,
  postingsData,
}: DemandSupplyMeterProps) => {
  const [meterValue, setMeterValue] = useState<number>(0);

  useEffect(() => {
    const regionHeadcountTimeSeries =
      compositionData?.composition?.[0]?.metrics?.headcount?.timeseries;
    const headcount =
      regionHeadcountTimeSeries?.[regionHeadcountTimeSeries?.length - 1]
        ?.value || 0;

    const postingsCategory = postingsData?.posting?.[0]?.category;
    const postingsActive =
      postingsCategory?.[postingsCategory?.length - 1]?.metrics?.active || 0;

    const meterValue = calculateMeterValue({
      value: postingsActive / headcount,
      metric: 'demandSupply',
    });

    setMeterValue(meterValue);
  }, [compositionData, postingsData]);

  const regionHeadcountTimeSeries =
    compositionData?.composition?.[0]?.metrics?.headcount?.timeseries;
  const headcount =
    regionHeadcountTimeSeries?.[regionHeadcountTimeSeries?.length - 1]?.value ||
    0;

  const postingsCategory = postingsData?.posting?.[0]?.category;
  const postingsActive =
    postingsCategory?.[postingsCategory?.length - 1]?.metrics?.active || 0;

  const demandsupplyRatio = getValueRatio(headcount, postingsActive);

  return (
    <MeterChart
      value={meterValue}
      mainText={`${demandsupplyRatio?.[1].toLocaleString()}:${demandsupplyRatio?.[0].toLocaleString()}`}
      subText={`${getHumanReadableNumbers(postingsActive)} postings vs ${getHumanReadableNumbers(headcount)} profiles`}
    />
  );
};
