import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { WithTooltip } from '@revelio/core';
import { FiInfo } from 'react-icons/fi';
import {
  SelectionCategoryLabelPrefix,
  SelectionCategoryLabelOverrides,
  NestedTreeHeaderTooltipText,
} from '../nested-tree/node-submenu/node-submenu';
import { TreeBreadcrumbHeader } from '../tree-breadcrumb-header/tree-breadcrumb-header';
import styles from './tree-header.module.css';
import { SelectionCategories } from '../../../engine/filters.model';
import { PAGE_CONTAINER_CLASS_NAME } from '../../utils/constants';
import { isUndefined } from 'lodash';

export interface TreeHeaderProps {
  hasSearch: boolean;
  isNestedTree: boolean;
  showBreadcrumbsByDefault?: boolean;
  selectionLists: string[];
}

export function TreeHeader({
  hasSearch,
  isNestedTree,
  showBreadcrumbsByDefault = false,
  selectionLists,
  ...props
}: TreeHeaderProps) {
  const isBreadcrumbState =
    showBreadcrumbsByDefault || (isNestedTree && hasSearch);

  return (
    <Flex role="group">
      <Text fontSize="xs" fontWeight="600" opacity="0.5" letterSpacing={0.8}>
        {isBreadcrumbState ? (
          <TreeBreadcrumbHeader lists={selectionLists} />
        ) : (
          `${SelectionCategoryLabelPrefix[selectionLists[0]] || ''}${
            SelectionCategoryLabelOverrides[selectionLists[0]] || ''
          }`
        )}
      </Text>

      {(!isBreadcrumbState || selectionLists.length === 1) &&
        !selectionLists.includes(SelectionCategories.REGION) && (
          <Box
            alignSelf="center"
            alignItems="center"
            className={styles.hidden}
            _groupHover={{ display: 'flex' }}
            _hover={{ cursor: 'pointer' }}
          >
            <WithTooltip
              showCondition={
                !isUndefined(NestedTreeHeaderTooltipText[selectionLists[0]])
              }
              variant="label"
              padding="12px"
              fontSize="12px"
              maxWidth="330px"
              overflowWrap="normal"
              label={`${NestedTreeHeaderTooltipText[selectionLists[0]] || ''}`}
              modifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: document.querySelector(
                      `.${PAGE_CONTAINER_CLASS_NAME}`
                    ),
                    padding: { left: 10 },
                  },
                },
              ]}
            >
              <Flex ml={1}>
                <Icon h="12px" opacity="0.6" as={FiInfo} />
              </Flex>
            </WithTooltip>
          </Box>
        )}
    </Flex>
  );
}

export default TreeHeader;
