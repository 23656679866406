import { CompanySelectionTypes } from '@revelio/core';
import { SnowflakeCompanyMapperSteps } from '../../snowflake-mapper/snowflake-mapper-steps';
import UploadCompaniesList from './upload-companies/upload-companies-list';
import {
  AbsoluteCenter,
  Box,
  Button,
  ButtonGroup,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useIsRevelioAdmin } from '@revelio/auth';

export const CompanyMappingModalContent = ({
  returnToPrevious,
  setCompanyColumnSelectionModalOpen,
}: {
  returnToPrevious?: () => void;
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();

  return (
    <ModalContent>
      <ModalHeader color="text.primary">Upload companies to Map</ModalHeader>
      <ModalCloseButton />

      <Divider orientation="horizontal" />

      <ModalBody mt="14px">
        <Box overflow="auto" maxH="600px">
          {isRevelioAdmin && (
            <>
              <SnowflakeCompanyMapperSteps
                setCompanyColumnSelectionModalOpen={
                  setCompanyColumnSelectionModalOpen
                }
              />
              <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  OR
                </AbsoluteCenter>
              </Box>
            </>
          )}

          <UploadCompaniesList
            setCompanyColumnSelectionModalOpen={
              setCompanyColumnSelectionModalOpen
            }
          />
        </Box>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button
            variant="link"
            fontSize="12px"
            colorScheme="gray"
            onClick={returnToPrevious}
          >
            Back
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  );
};
