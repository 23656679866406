import { Box, Text } from '@chakra-ui/layout';
// import { Button } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { formatWithCommas } from '../helpers';
import { EmploymentEntryRaw } from '../resumeTypes';

interface EmploymentRawProps {
  data?: EmploymentEntryRaw;
  variant?: string;
}

export default function EmploymentRaw({ data, variant }: EmploymentRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionDate']}>
        {data?.start_date} - {data?.end_date}
      </Text>
      <Text sx={styles['sectionTitle']}>{data?.role}</Text>
      <Text sx={styles['sectionDetails']}>
        {formatWithCommas([data?.company_name, data?.location])}
      </Text>
      <Text sx={styles['sectionDetails']} mt="5px">
        {data?.description}
      </Text>
      {!!data?.description_bullet.length && (
        <ul style={{ paddingLeft: '15px' }}>
          {data?.description_bullet.map((bullet, index) => (
            <li key={`employment-bullet-${index}`}>
              <Text sx={styles['sectionDetails']}>{bullet}</Text>
            </li>
          ))}
        </ul>
      )}
      {/* <Button variant="outlined" color="lightBlue.500" p="0" size="xs">
        View More
      </Button> */}
    </Box>
  );
}
