import { OptionBase } from 'chakra-react-select';
import {
  NumericOperation,
  SelectionListOperation,
  VariableSelectionTypes,
  isSelectionListVariable,
  isNumericVariable,
  isStringVariable,
  StringOperation,
  MultiGranularitySelectionListVariables,
} from './conditions.model';
import {
  BinaryOperation,
  CustomColumnConditionVariable,
  PipelineType,
} from '@revelio/data-access';
import { isPostingsPipeline } from '../../deliverables.model';
import { intersectionBy } from 'lodash';
export interface VariableSelectOption extends OptionBase {
  label: string;
  value: CustomColumnConditionVariable | MultiGranularitySelectionListVariables;
}

const GEOGRAPHY_VARIABLE_SELECT_OPTIONS = [
  {
    label: 'Geography',
    value: MultiGranularitySelectionListVariables.GEOGRAPHY,
  },
];
const ROLE_VARIABLE_SELECT_OPTIONS = [
  {
    label: 'Role',
    value: MultiGranularitySelectionListVariables.ROLE,
  },
];
const SKILL_VARIABLE_SELECT_OPTIONS = [
  {
    label: 'Raw Skill',
    value: CustomColumnConditionVariable.Skill,
  },
  {
    label: 'Skill',
    value: MultiGranularitySelectionListVariables.SKILL,
  },
];
export const VARIABLE_SELECT_OPTIONS: VariableSelectOption[] = [
  ...GEOGRAPHY_VARIABLE_SELECT_OPTIONS,
  ...ROLE_VARIABLE_SELECT_OPTIONS,
  {
    label: 'Title',
    value: CustomColumnConditionVariable.Title,
  },
  {
    label: 'Description',
    value: CustomColumnConditionVariable.Description,
  },
  {
    label: 'Salary',
    value: CustomColumnConditionVariable.Salary,
  },
  {
    label: 'Total Compensation',
    value: CustomColumnConditionVariable.TotalCompensation,
  },
  {
    label: 'Remote Suitability',
    value: CustomColumnConditionVariable.RemoteSuitability,
  },
  {
    label: 'Seniority',
    value: CustomColumnConditionVariable.Seniority,
  },
  {
    label: 'Gender',
    value: CustomColumnConditionVariable.Gender,
  },
  {
    label: 'Ethnicity',
    value: CustomColumnConditionVariable.Ethnicity,
  },
  ...SKILL_VARIABLE_SELECT_OPTIONS,
  {
    label: 'Prestige',
    value: CustomColumnConditionVariable.Prestige,
  },
  {
    label: 'Highest Degree',
    value: CustomColumnConditionVariable.HighestDegree,
  },
];

export const getConditionVariableSelectOptions = ({
  pipelineType,
}: {
  pipelineType: PipelineType;
}): VariableSelectOption[] => {
  const pipelineSupportingAllVariables = [
    PipelineType.WfDynam,
    PipelineType.SkillDynam,
    PipelineType.Individual,
    PipelineType.Transition,
  ];
  if (pipelineSupportingAllVariables.includes(pipelineType)) {
    return VARIABLE_SELECT_OPTIONS;
  }

  if (isPostingsPipeline(pipelineType)) {
    return [
      ...GEOGRAPHY_VARIABLE_SELECT_OPTIONS,
      ...ROLE_VARIABLE_SELECT_OPTIONS,
      ...intersectionBy(
        VARIABLE_SELECT_OPTIONS,
        [
          { value: CustomColumnConditionVariable.Title },
          { value: CustomColumnConditionVariable.Description },
          { value: CustomColumnConditionVariable.Salary },
          { value: CustomColumnConditionVariable.TotalCompensation },
          { value: CustomColumnConditionVariable.Seniority },
        ],
        'value'
      ),
    ];
  }

  const SENTIMENT_PIPELINE_TYPES = [
    PipelineType.SentimentScores,
    PipelineType.ReviewTrends,
    PipelineType.IndividualReviews,
  ];
  if (SENTIMENT_PIPELINE_TYPES.includes(pipelineType)) {
    return [
      ...GEOGRAPHY_VARIABLE_SELECT_OPTIONS,
      ...ROLE_VARIABLE_SELECT_OPTIONS,
      ...intersectionBy(
        VARIABLE_SELECT_OPTIONS,
        [
          { value: CustomColumnConditionVariable.Title },
          { value: CustomColumnConditionVariable.Seniority },
        ],
        'value'
      ),
      {
        label: 'Rating Overall',
        value: CustomColumnConditionVariable.RatingOverall,
      },
      {
        label: 'Review Pros',
        value: CustomColumnConditionVariable.ReviewPros,
      },
      {
        label: 'Review Cons',
        value: CustomColumnConditionVariable.ReviewCons,
      },
    ];
  }

  if (pipelineType === PipelineType.Layoffs) {
    return [
      {
        label: 'State',
        value: CustomColumnConditionVariable.State,
      },

      // {
      //   label: 'Metropolitan Area',
      //   value: CustomColumnConditionVariable.MetroArea,
      // },
    ];
  }

  return [];
};

export interface StringOperationSelectOption extends OptionBase {
  label: string;
  value: StringOperation;
}

const BASIC_STRING_OPERATION_SELECT_OPTIONS: StringOperationSelectOption[] = [
  {
    label: 'Equals',
    value: StringOperation.EQUAL,
  },
  {
    label: 'Does not equal',
    value: StringOperation.DOES_NOT_EQUAL,
  },
];
export const STRING_OPERATION_SELECT_OPTIONS: StringOperationSelectOption[] = [
  {
    label: 'Contains any',
    value: StringOperation.CONTAINS_AT_LEAST_ONE,
  },
  {
    label: 'Contains any (case sensitive)',
    value: StringOperation.CONTAINS_AT_LEAST_ONE_CASE_SENSITIVE,
  },
  {
    label: 'Contains all',
    value: StringOperation.CONTAINS_ALL,
  },
  {
    label: 'Contains all (case sensitive)',
    value: StringOperation.CONTAINS_ALL_CASE_SENSITIVE,
  },
  {
    label: 'Does not contain any',
    value: StringOperation.DOES_NOT_CONTAIN_ALL,
  },
  {
    label: 'Does not contain any (case sensitive)',
    value: StringOperation.DOES_NOT_CONTAIN_ALL_CASE_SENSITIVE,
  },
  ...BASIC_STRING_OPERATION_SELECT_OPTIONS,
];

export interface NumericOperationSelectOption extends OptionBase {
  label: string;
  value: NumericOperation;
}

export const NUMERIC_OPERATION_SELECT_OPTIONS: NumericOperationSelectOption[] =
  [
    {
      label: 'Greater than',
      value: NumericOperation.GREATER_THAN,
    },
    {
      label: 'Less than',
      value: NumericOperation.LESS_THAN,
    },
    {
      label: 'Between',
      value: NumericOperation.BETWEEN,
    },
  ];

export interface SelectionListOperationSelectOption extends OptionBase {
  label: string;
  value: SelectionListOperation;
}
export const SELECTION_LIST_OPERATION_SELECT_OPTIONS: SelectionListOperationSelectOption[] =
  [
    {
      label: 'In set of',
      value: SelectionListOperation.CONTAINS_AT_LEAST_ONE,
    },
    {
      label: 'Not in set of',
      value: SelectionListOperation.DOES_NOT_CONTAIN_ANY_OF,
    },
  ];

export const getVariableOperationOptions = (
  variable:
    | CustomColumnConditionVariable
    | MultiGranularitySelectionListVariables
    | undefined
) => {
  const variableSelectionType = getVariableSelectionType(variable);
  if (variableSelectionType === VariableSelectionTypes.SelectionList) {
    return SELECTION_LIST_OPERATION_SELECT_OPTIONS;
  }

  if (variableSelectionType === VariableSelectionTypes.String) {
    return STRING_OPERATION_SELECT_OPTIONS;
  }

  if (variableSelectionType === VariableSelectionTypes.Numeric) {
    return NUMERIC_OPERATION_SELECT_OPTIONS;
  }

  return [];
};

export const getVariableSelectionType = (
  variable:
    | CustomColumnConditionVariable
    | MultiGranularitySelectionListVariables
    | undefined
): VariableSelectionTypes | undefined => {
  if (!variable) {
    return undefined;
  }

  if (isSelectionListVariable(variable)) {
    return VariableSelectionTypes.SelectionList;
  }

  if (isNumericVariable(variable)) {
    return VariableSelectionTypes.Numeric;
  }

  if (isStringVariable(variable)) {
    return VariableSelectionTypes.String;
  }

  return undefined;
};

export interface BinarySelectOption extends OptionBase {
  label: string;
  value: BinaryOperation;
}
export const BINARY_SELECT_OPTIONS: BinarySelectOption[] = [
  { label: 'OR', value: BinaryOperation.Or },
  { label: 'AND', value: BinaryOperation.And },
];

export function getSelectFieldValue<ValueType>(
  fieldValue: ValueType | undefined,
  selectOptions: { label: string; value: ValueType }[]
) {
  return fieldValue
    ? {
        label: selectOptions.find((x) => x.value === fieldValue)
          ?.label as string,
        value: fieldValue,
      }
    : null;
}

export const EMPTY_CASE_CONDITION = {
  variable: undefined,
  value: undefined,
  operation: undefined,
};

export const EMPTY_VALUE_MESSAGE = 'value is required.';
