import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';

interface WithDropdownProps {
  isDropdown: boolean;
  trigger?: React.ReactNode;
  children?: React.ReactNode;
}

export const WithDropdown = ({
  isDropdown,
  trigger,
  children,
}: WithDropdownProps) => {
  const PopoverWrapper = (
    <Popover matchWidth>
      <PopoverTrigger>
        <Box tabIndex={0}>{trigger}</Box>
      </PopoverTrigger>

      <PopoverContent w="100%">
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return isDropdown ? PopoverWrapper : children;
};
