import { Divider } from '@chakra-ui/layout';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { noop } from 'lodash';
import { CompanySet } from '@revelio/data-access';
import {
  deliverablesStore,
  isValidEntityId,
  selectInputRefs,
  updatePipelineCompanySelection,
  upsertInputRefs,
} from '../deliverables.repository';
import { Select, SingleValue } from 'chakra-react-select';
import { map } from 'rxjs';
import { selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { useIsRevelioAdmin } from '@revelio/auth';
import { Deliverable } from '../deliverables.model';

interface CompanyFavouritesProps extends Omit<UseDisclosureProps, 'onClose'> {
  onClose: () => void;
  returnToPrevious?: () => void;
  entityId?: Deliverable['id'];
}

function formatCompanySetName(setName: string) {
  if (setName === CompanySet.PublicUs) return 'US PUBLIC COMPANIES';
  return setName
    .replace(/_/g, ' ') // Replace underscores with spaces
    .toUpperCase();
}
const SUPPORTED_COMPANY_OPTIONS = [
  CompanySet.Eurostoxx_50,
  CompanySet.Ftse_100,
  CompanySet.Nasdaq_100,
  CompanySet.PublicUs,
  CompanySet.RevelioShared,
  CompanySet.RevelioSharedPrivate,
  CompanySet.RevelioSharedPublic,
  CompanySet.Russell_1000,
  CompanySet.Sp_500,
].map((value) => ({
  value,
  label: formatCompanySetName(value),
}));

const ONLY_REVELIO_ADMIN_OPTIONS = [
  CompanySet.RevelioShared,
  CompanySet.RevelioSharedPrivate,
  CompanySet.RevelioSharedPublic,
];

export const CompanyFavourites = ({
  id,
  isOpen,
  onClose,
  returnToPrevious = noop,
  entityId,
}: CompanyFavouritesProps) => {
  const [entityCompanySet] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId as number),
      map((deliverable) => deliverable?.company_sets)
    ),
    { deps: [entityId] }
  );
  const [inputRefCompanySet] = useObservable(
    deliverablesStore.pipe(
      selectInputRefs(),
      map((inputRefs) => inputRefs?.company_sets)
    )
  );

  const { isRevelioAdmin } = useIsRevelioAdmin();

  const handleSelectCompanySet = (
    option: SingleValue<{
      label: string;
      value: CompanySet;
    }>
  ) => {
    if (option?.value) {
      const companySetUpdate = {
        company_sets: [option?.value],
      };
      if (isValidEntityId(entityId)) {
        updatePipelineCompanySelection(entityId, companySetUpdate);
      } else {
        upsertInputRefs(companySetUpdate);
      }
      onClose();
    }
  };

  return (
    <Modal
      id={id}
      isOpen={isOpen as boolean}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="text.primary">Choose a set</ModalHeader>
        <ModalCloseButton />

        <Divider orientation="horizontal" />

        <ModalBody mt="14px">
          <Select<{ label: string; value: CompanySet }, false>
            options={
              isRevelioAdmin
                ? SUPPORTED_COMPANY_OPTIONS
                : SUPPORTED_COMPANY_OPTIONS.filter(
                    (opt) => !ONLY_REVELIO_ADMIN_OPTIONS.includes(opt.value)
                  )
            }
            value={
              isValidEntityId(entityId) && entityCompanySet
                ? SUPPORTED_COMPANY_OPTIONS.find(
                    (option) => option.value === entityCompanySet[0]
                  )
                : SUPPORTED_COMPANY_OPTIONS.find(
                    (option) => option.value === inputRefCompanySet[0]
                  )
            }
            onChange={handleSelectCompanySet}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="link"
            fontSize="12px"
            colorScheme="gray"
            onClick={returnToPrevious}
          >
            Back
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
