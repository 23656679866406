import { CompanySelectionTypes } from '@revelio/core';
import {
  CompanyMapping,
  CompanyMappingNameChoice,
} from '../company-mapping.model';
import { environment } from '../../../../../environments/environment';
import { deliverablesStore } from '../../../deliverables.repository';
import { setCompanyMapping } from '../company-mapping.respository';
import { SnowflakeDbValues } from '../../../snowflake-db-inputs';
import { SnowflakeDataInterface } from '../../../snowflake-mapper/snowflake-mapper-context';
import { merge } from 'lodash';

export const sendCompanyMapping = async ({
  database,
  schema,
  table,
  mappedColumns,
  setCompanyColumnSelectionModalOpen,
}: SnowflakeDbValues & {
  mappedColumns: SnowflakeDataInterface['mappedColumns'];
} & {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}) => {
  const columns = merge({}, ...(mappedColumns || []));
  const companyMapping = await fetchCompanyMapping({
    database,
    schema,
    table,
    columns,
  });
  deliverablesStore.update(
    setCompanyMapping({
      missingMappings: companyMapping.filter(
        (company) => company.response === null
      ),
      verifiedMappings: companyMapping.filter(
        (company) => company.response !== null
      ),
      companyNameChoice: CompanyMappingNameChoice.REVELIO_COMPANY_NAME, // by default it's revelio to match dashboard company names
    })
  );
  setCompanyColumnSelectionModalOpen(CompanySelectionTypes.COMPANY_MAPPING);
};

const fetchCompanyMapping = async ({
  database,
  schema,
  table,
  columns,
}: SnowflakeDbValues & { columns: { [arg0: string]: string } }): Promise<
  CompanyMapping[]
> => {
  try {
    const response: Response = await fetch(
      `${environment.COMPANY_MAPPER_SNOWFLAKE_URL}/sf-companies`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          database,
          schema,
          table,
          columns,
        }),
        credentials: 'include',
      }
    );
    if (!response.ok) {
      throw new Error('There was an error!');
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const fetchSnowflakeColumns = async ({
  database,
  schema,
  table,
}: SnowflakeDbValues) => {
  try {
    const response: Response = await fetch(
      `${environment.COMPANY_MAPPER_SNOWFLAKE_URL}/sf-columns`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          database,
          schema,
          table,
        }),
        credentials: 'include',
      }
    );
    if (!response.ok) {
      throw new Error('There was an error!');
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
