import {
  Box,
  Stack,
  StackDivider,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useToast,
} from '@chakra-ui/react';
import { LoaderType, Loading } from '@revelio/core';
import { HeadingGroup, AdminCard } from '@revelio/layout';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { get } from 'lodash';
import AdminUsers from './admin-users/admin-users';
import { useGetLoggedInUser } from '@revelio/auth';
import ClientForm, { ClientFormValues } from './client-form';
import { useQuery } from 'urql';
import { ClientDetailsQuery } from './userOperations';
import { Control, useForm } from 'react-hook-form';
import DashboardPage from '../DashboardPage';

const ClientAdmin = () => {
  const location = useLocation();
  const toast = useToast();
  const showAddUserToast = get(location, 'state.addUserToast', false);
  useEffect(() => {
    if (showAddUserToast) {
      toast({
        title: 'User Added Successfully',
        description: 'User added & login info copied to clipboard.',
        status: 'success',
        duration: 4000,
        position: 'top-right',
      });
    }
  }, [showAddUserToast, toast]);

  const {
    loggedInUser,
    query: [{ fetching }],
  } = useGetLoggedInUser();

  const [{ data: clientData }] = useQuery({
    query: ClientDetailsQuery,
    variables: { ids: [loggedInUser?.client_name as string] },
    pause: !loggedInUser?.client_name,
  });
  const clientDetails = get(clientData, 'clients[0]', null);
  const { control, watch } = useForm<ClientFormValues>(); // dummy control to be able to re-use <ClientForm />
  return (
    <div>
      <DashboardPage title={'Manage'} hideTop loader={LoaderType.MANUAL}>
        <Box maxW="2xl" mx="auto">
          <Stack as="section" spacing="6">
            <HeadingGroup
              title="User Management"
              description="Manage users with your account to elimate inefficient processes across your organization."
            />
            <AdminCard>
              <Stack divider={<StackDivider />} spacing="6">
                <Tabs variant="line-green" isLazy size="sm" position="relative">
                  <TabList>
                    <Tab position="relative">Users</Tab>
                    <Tab position="relative">Settings</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {fetching ? (
                        <Loading />
                      ) : (
                        <AdminUsers
                          clientName={loggedInUser.client_name as string}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      <ClientForm
                        description="Get in touch with Revelio to make any changes"
                        clientToEdit={clientDetails}
                        readonly={true}
                        watch={watch}
                        register={() => ({})}
                        control={
                          control as unknown as Control<
                            ClientFormValues,
                            object
                          >
                        }
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </AdminCard>
          </Stack>
        </Box>
      </DashboardPage>
    </div>
  );
};

export default ClientAdmin;
