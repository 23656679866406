import { useSingleOrMoreFilterState } from '../../engine/filters.engine';
import {
  SelectionCategories,
  RangeFilter,
  DefaultDates,
} from '../../engine/filters.model';
import { isEmpty, isEqual } from 'lodash';
import { LAST_DATE_READY, Views } from '@revelio/core';
import { FormControl, VStack } from '@chakra-ui/react';
import { parse } from 'date-fns';
import { Select } from 'chakra-react-select';
import { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './date-range.css';
import {
  DateRangeDictionary,
  DateRangeValue,
  getDateFormat,
  getDateRangeDictionary,
  getSelectedLastMonth,
  getStartDateConst,
} from './helpers';
import {
  createDateOptions,
  createFilteredPredefinedDateDictionary,
  CUSTOM_DATE_SELECT_OPTION,
  initialisePredefinedDateOptionValue,
  PredefinedDateOption,
} from '../dateHelpers';
import { useEffect$ } from '@ngneat/react-rxjs';
import { tap } from 'rxjs';
import CustomFilterRange from './custom-date-range';

export interface FilterRangeProps {
  view?: Views;
  endDateDefaultFilterName?: DefaultDates;
  dateRangeValue?: DateRangeValue;
  setDateRangeValue: (newDateRange: DateRangeValue) => void;
  //customDatePickerOnly: boolean;
  filterName?: SelectionCategories;
  dateRangeError: string;
  setDateRangeError: (dateRangeError: string) => void;
}

export function FilterRange({
  view,
  dateRangeValue,
  setDateRangeValue,
  filterName = SelectionCategories.DATE_RANGE,
  dateRangeError,
  setDateRangeError,
}: FilterRangeProps) {
  const dateFormat = getDateFormat(view);
  const [lastMonth, setLastMonth] = useState<string | undefined>();
  const [predefinedDateDict, setPredefinedDateDict] =
    useState<DateRangeDictionary>();
  const [maxEndDate, setMaxEndDate] = useState<Date>();
  const [predefinedDateSelectValue, setPredefinedDateSelectValue] =
    useState<PredefinedDateOption>(null);

  useEffect$(
    () =>
      LAST_DATE_READY.pipe(
        tap((ready) => {
          if (ready && view) {
            setLastMonth(getSelectedLastMonth(view));
          }
        })
      ),
    [view]
  );

  useEffect(() => {
    if (!lastMonth) return;

    const endDate = parse(lastMonth, dateFormat, new Date());
    setMaxEndDate(endDate);

    const startDate = parse(getStartDateConst(view), dateFormat, new Date());

    const dateDictionary = createFilteredPredefinedDateDictionary({
      minimumMonth: startDate,
      dateDictionary: getDateRangeDictionary({ startDate, endDate }),
    });
    setPredefinedDateDict(dateDictionary as DateRangeDictionary);
  }, [lastMonth, dateFormat, view, setMaxEndDate, setPredefinedDateDict]);

  // when a new filterMenu is opened (e.g. in filterChips)
  // pre-populate it with filter value set in the global filterStore
  const [dateRangeFilter] = useSingleOrMoreFilterState<RangeFilter>(filterName);
  useEffect(() => {
    if (dateRangeFilter && !dateRangeFilter.isMaximumRange && !dateRangeValue) {
      setDateRangeValue({
        startDate: parse(
          dateRangeFilter.value.startDate,
          dateFormat,
          new Date()
        ),
        endDate: parse(dateRangeFilter.value.endDate, dateFormat, new Date()),
      });
    }
  }, [dateRangeFilter, dateFormat, dateRangeValue, setDateRangeValue]);

  useEffect(() => {
    if (!predefinedDateDict) {
      return;
    }

    initialisePredefinedDateOptionValue({
      dateValue: dateRangeValue,
      predefinedDateDict,
      preDefinedSelectOptionsFinder: {
        startDate: dateRangeValue?.startDate,
        endDate: dateRangeValue?.endDate,
      },
      predefinedDateSelectValue,
      setPredefinedDateSelectValue,
    });
  }, [
    dateRangeValue,
    predefinedDateDict,
    predefinedDateSelectValue,
    setPredefinedDateSelectValue,
  ]);

  const handleProvidedOptionsSelect = useCallback(
    (option: NonNullable<PredefinedDateOption>) => {
      if (isEmpty(predefinedDateDict)) {
        return;
      }

      setPredefinedDateSelectValue(option);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { startDate, endDate } = predefinedDateDict![option.value];
      setDateRangeValue({
        startDate,
        endDate,
      });
    },
    [predefinedDateDict, setDateRangeValue]
  );

  const showMonthYearPicker = view !== Views.POSTING;
  const showYearDropdown = view === Views.POSTING;
  const maxStartDate = parse(getStartDateConst(view), dateFormat, new Date());
  const dateOptions = createDateOptions(predefinedDateDict);
  return (
    <VStack>
      <FormControl minWidth={'250px'}>
        <Select
          id={`filter-range-${filterName}`} // data-testid not possible to send to react-select
          isMulti={false}
          name={filterName}
          options={dateOptions}
          value={predefinedDateSelectValue}
          onChange={(newValue) =>
            handleProvidedOptionsSelect(
              newValue as NonNullable<PredefinedDateOption>
            )
          }
          size="sm"
        />
      </FormControl>

      {isEqual(predefinedDateSelectValue, CUSTOM_DATE_SELECT_OPTION) && (
        <CustomFilterRange
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
          dateFormat={dateFormat}
          showMonthYearPicker={showMonthYearPicker}
          showYearDropdown={showYearDropdown}
          maxStartDate={maxStartDate}
          maxEndDate={maxEndDate}
          error={dateRangeError}
          setError={setDateRangeError}
        />
      )}
    </VStack>
  );
}

export default FilterRange;
