import React from 'react';
import { useLocation } from 'react-router-dom';

import analytics from './analytics.js';
import { environment } from '../../environments/environment';

export default function useGoogleAnalytics() {
  let location = useLocation();

  React.useEffect(() => {
    if (environment.production) analytics.init();
  }, []);

  React.useEffect(() => {
    if (environment.production) {
      const currentPath = location.pathname + location.search;
      if (currentPath !== '/') {
        analytics.sendPageview(currentPath);
      }
    }
  }, [location]);
}
