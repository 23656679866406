import { SelectionList, SelectionListValue } from '../../../selection-list';
import { useSavedSetsSelectOptions } from './saved-sets-selection-list.context';
import { useNodeModalState } from '../../node-modal-state.context';
import NodeEditModal from '../../../tree/node-edit-modal/node-edit-modal';
import { get } from 'lodash';
import { API_ROOT } from '@revelio/auth';
import { filterStore, getActiveTabMeta } from '../../../../engine/filters.core';
import { useMemo } from 'react';
import { SavedSetOption } from '../../../selection-list/saved-set-option';
import { setSearchParamFilters } from '../../../../engine/filters.deepLinks';
import { of, Subject, switchMap, withLatestFrom } from 'rxjs';
import { useEffect$ } from '@ngneat/react-rxjs';
import { useGetStoredSets } from '../../../../engine/filters.savedset';

import { LocalSelectionCategories } from '../../../../engine/filters.model';
import { View } from '@revelio/data-access';

const searchParam$ = new Subject<URLSearchParams>();

const ScreenerViews = [
  View.CompanyScreener,
  View.GeographyScreener,
  View.RoleScreener,
];

export const SavedSetsSelectionList = ({ close }: { close: () => void }) => {
  const { options } = useGetStoredSets();

  const { selectedOption, clearSelectedOption } = useSavedSetsSelectOptions();

  const { nodeModalOpen, setNodeModalOpen } = useNodeModalState();

  const tabMeta = filterStore.query(getActiveTabMeta);

  // const [searchParams, setSearchParams] = useState<any>();

  const selectOptions = useMemo(() => {
    return options
      .map((option) => ({
        value: option.id,
        label: option.name,
        entity: option,
        isDisabled: option.view !== tabMeta.savedSetView,
      }))
      .sort((a, b) => {
        if (a.isDisabled === b.isDisabled) {
          return a.value.localeCompare(b.value);
        }
        return a.isDisabled ? 1 : -1;
      });
  }, [options, tabMeta.savedSetView]);

  const submitSavedSetSelection = (value: SelectionListValue) => {
    const deeplink = get(value, 'entity.filters');

    const url = new URL(`${API_ROOT}${deeplink}`);
    const searchParams = url.searchParams;

    if (searchParams) {
      searchParam$.next(searchParams);
    }

    close();
  };

  useEffect$(() => {
    return searchParam$.pipe(
      withLatestFrom(of(tabMeta)),
      switchMap(([searchParams, tabMeta]) => {
        const { savedSetView, limit, deepLinkLimit, ...rest } = tabMeta;

        if (savedSetView && ScreenerViews.includes(savedSetView)) {
          // TODO: handle screener deeplink here
          return of([]);
        }

        const setSearch = setSearchParamFilters({
          searchParamFilters: searchParams,
          ...rest,
          limits: {
            [LocalSelectionCategories.PRIMARY_ENTITIES]:
              deepLinkLimit || limit || 6,
          },
          setEntities: true,
        });

        return setSearch ? setSearch : of([]);
      })
    );
  });

  return (
    <>
      <NodeEditModal
        node={selectedOption}
        isOpen={nodeModalOpen}
        onClose={function (): void {
          clearSelectedOption();
          setNodeModalOpen(false);
        }}
      />
      <SelectionList
        options={selectOptions}
        close={close}
        submit={submitSavedSetSelection}
        isMulti={false}
        OptionComponent={SavedSetOption}
        onChangeOverride={(selectedOptions, { setValue }) => {
          setValue((prev) => {
            if (get(prev, 'value') === get(selectedOptions, 'value')) {
              return [];
            }

            return selectedOptions;
          });
        }}
      />
    </>
  );
};
