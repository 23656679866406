import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useUserTrialDetails } from '@revelio/auth';
import { ActionMenu } from '@revelio/core';
import { Card, Plot, PlotData, PlotProps } from '@revelio/layout';
import { FiDownload, FiMoreVertical } from 'react-icons/fi';
import ActionMenuButton from '../action-menu-button/action-menu-button';
import PlotDataLagWarning from '../plot-data-lagged/plotDataLagWarning';
import { DownloadConfig } from '../../types';
import { CombinedError } from 'urql';

export interface PlotModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  header: string;
  topLeft: React.ReactElement;
  plotConfig: PlotProps;
  downloadConfig: DownloadConfig;
  additionalActionMenuItems?: JSX.Element;
  setPlotDimensions?: React.Dispatch<{ width: number; height: number }>;
  data?: PlotData;
  loading?: boolean;
  error?: CombinedError;
}

const PlotModal = ({
  header,
  topLeft,
  plotConfig,
  downloadConfig,
  additionalActionMenuItems,
  setPlotDimensions,
  data,
  loading,
  error,
  ...props
}: PlotModalProps) => {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: true,
  });

  const { disabled: disableDownloadData = false } = downloadConfig;

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      size="6xl"
      isCentered
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent role="group" height="80%" w="90%" pb="30px">
        <ModalHeader pr="8px">
          <Flex align="center" justifyContent="space-between">
            <Stack spacing={0} direction="row" align="center">
              <Heading
                fontSize={'18px'}
                color="text.primary"
                fontWeight="semibold"
                display={topLeft ? 'inline-block' : 'block'}
                marginRight={topLeft ? 1 : 0}
              >
                {header}
              </Heading>
              {topLeft || null}
            </Stack>
            <Flex alignItems="center">
              <PlotDataLagWarning />
              <IconButton
                aria-label="close modal"
                icon={<CloseIcon />}
                size="sm"
                variant="white"
                onClick={() => {
                  props.onClose();
                }}
              />
              <ActionMenu menuButtonIcon={FiMoreVertical} portalMenu={false}>
                {additionalActionMenuItems}
                {!disableDownloadData && (
                  <ActionMenuButton
                    icon={FiDownload}
                    text="Download Data"
                    disabled={isTrialUser}
                    isDownloadButton
                    downloadProps={{
                      endpoint: downloadConfig.endpoint,
                      additionalNonActiveFilters:
                        plotConfig.additionalNonActiveFilters,
                      additionalOperatorsBeforeQuery:
                        plotConfig.additionalOperatorsBeforeQuery,
                      brokenOutFilterIds: plotConfig.brokenOutFilterIds,
                      requestMethod: plotConfig.requestMethod,
                      isGoRequest: downloadConfig.isGoRequest,
                    }}
                  />
                )}
              </ActionMenu>
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Card p="0">
            <Plot
              disableD3={false}
              setPlotDimensions={setPlotDimensions}
              {...plotConfig}
              isFullScreen={true}
              data={data}
              loading={loading}
              error={error}
            />
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlotModal;
