/**
 * NOTE: The following are class names used to determine if click events are
 * within specific parts of the nested tree component. They are intentionally
 * long to avoid possibility of class name clashing.
 */

export const SUBMENU_CONTENT_CLASS_NAME =
  'revelio-nested-menu-popover-content-identifier';

export const SUBMENU_TOGGLE_CLASS_NAME =
  'revelio-node-submenu-toggle-button-identifier';

export const NESTED_MENU_TOP_LEVEL_CLASS_NAME =
  'revelio-nested-menu-top-level-identifier';

export const ACTION_MENU_LIST_CLASS_NAME =
  'revelio-node-action-menu-portal-identifier';

export const PAGE_CONTAINER_CLASS_NAME = 'revelio-page-container-class-name';
