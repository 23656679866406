import { Box, Flex } from '@chakra-ui/layout';
import React, { ComponentProps, ReactNode } from 'react';
import { Card } from '@revelio/layout';
import { PlotInfoComp } from '@revelio/composed';

interface PlotLayoutCardProps {
  title: string;
  plotInfo?: ComponentProps<typeof PlotInfoComp>;
  topRight?: ReactNode;
  menu?: ReactNode;
  children: ReactNode;
}

export const PlotLayoutCard = (props: PlotLayoutCardProps) => {
  return (
    <Card
      header={props.title}
      headerTakesSpace={true}
      topLeft={props.plotInfo && <PlotInfoComp {...props.plotInfo} />}
      topRight={
        <Flex gap={2}>
          {props.topRight}
          {props.menu}
        </Flex>
      }
    >
      <Box height="100%">
        <Box height="100%">{props.children}</Box>
      </Box>
    </Card>
  );
};
