import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Flex,
  Text,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { startCase } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FiCopy, FiMail } from 'react-icons/fi';

interface EmailPopoverProps {
  emails?: Record<string, string>;
  trigger: React.ReactNode;
}

export const EmailPopover = ({ emails, trigger }: EmailPopoverProps) => {
  const toast = useToast();

  return (
    <Popover placement="right" variant="tableTooltipPopover">
      <PopoverTrigger>{trigger}</PopoverTrigger>
      {emails && (
        <PopoverContent width="fit-content">
          <PopoverArrow />
          <PopoverBody>
            <Flex direction="column" rowGap={1}>
              {Object.entries(emails).map(([key, value], i) => {
                if (!value) {
                  return null;
                }

                return (
                  <Flex direction="column" key={`${key}-${i}`}>
                    <Text fontSize="xs" fontWeight="700" opacity="0.6">
                      {startCase(key).toUpperCase()}
                    </Text>
                    <Flex alignItems="center">
                      <Text fontSize="xs" mr={1}>
                        {value}
                      </Text>
                      <CopyToClipboard
                        text={value}
                        onCopy={(text, result) => {
                          if (result) {
                            toast({
                              title: 'Email Copied Successfully',
                              description: 'Email copied to clipboard.',
                              status: 'success',
                              duration: 4000,
                              position: 'top-right',
                            });
                          }
                        }}
                      >
                        <IconButton
                          variant="ghost"
                          size="xs"
                          icon={<FiCopy />}
                          aria-label="copy email"
                          colorScheme="gray"
                          opacity={0.4}
                        />
                      </CopyToClipboard>
                      <IconButton
                        variant="ghost"
                        size="xs"
                        icon={<FiMail />}
                        aria-label="email"
                        colorScheme="gray"
                        opacity={0.4}
                        onClick={() =>
                          (window.location.href = `mailto:${value}`)
                        }
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
