import { useUserTrialDetails } from '@revelio/auth';
import { ReportBuilderTrialPage } from '../trial-pages/report-builder-trial-page';
import { ReportBuilderPage } from './report-builder-page';

export const ReportBuilder = () => {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  if (isTrialUser) {
    return <ReportBuilderTrialPage />;
  } else {
    return <ReportBuilderPage />;
  }
};
