import { BinaryOperation } from '@revelio/data-access';
import { Condition } from '../dataset-conditions/conditions.model';
import { EMPTY_CASE_CONDITION } from '../dataset-conditions/dataset-conditions-form-helpers';

export type DatasetFilterForm = {
  datasetFilterName: string;
  conditions: (Condition | typeof EMPTY_CASE_CONDITION)[];
  binary: BinaryOperation | undefined;
};
export const DEFAULT_FILTER_NAME = 'unnamed_filter';
export const DEFAULT_FORM_VALUES: DatasetFilterForm = {
  datasetFilterName: DEFAULT_FILTER_NAME,
  conditions: [EMPTY_CASE_CONDITION],
  binary: undefined,
};

export const ADD_FILTER_ID = 'add-filter';
