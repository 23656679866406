/* eslint-disable @typescript-eslint/no-explicit-any */
import { App, Messages } from '@openreplay/tracker';
import { Encoder, sha1 } from '@openreplay/tracker-redux/lib/syncod-v2';
import { elfHooks } from '@ngneat/elf';
import { debounce } from 'lodash';

const sendNextState = debounce(
  ({ app, encoder, options, storeName, nextState }) => {
    try {
      const startTime = performance.now();
      const duration = performance.now() - startTime;
      app.send(Messages.StateAction(`[${storeName}] update`));

      const _action = encoder.encode({ type: `[${storeName}] update` });
      const _state = encoder.encode(
        options.stateTransformer(storeName, nextState)
      );
      const _table: any = encoder.commit();
      for (const key in _table) {
        app.send(Messages.OTable(key, _table[key]));
      }
      const actionTime = performance.now();
      app.send(Messages.Redux(_action, _state, duration, actionTime));
    } catch (error) {
      encoder.clear();
    }
  },
  1000,
  { leading: false, trailing: true }
);

export interface Options {
  storeFilter: (storeName: string) => boolean;
  stateTransformer: (storeName: string, state: any) => any;
}

export function elfOpenReplayLogger(opts: Partial<Options> = {}) {
  const options: Options = Object.assign(
    {
      storeFilter: (a: any) => true,
      stateTransformer: (a: any) => a,
    },
    opts
  );
  return (app: App | null) => {
    if (app === null) {
      return () => void 0;
    }

    const encoder = new Encoder(sha1, 50);
    app.attachStopCallback(() => {
      encoder.clear();
    });

    return () => {
      if (app.active()) {
        elfHooks.registerPreStoreUpdate(
          (currentState: any, nextState: any, storeName: string) => {
            // Don't send updates from stores that are not in the filter
            if (!options.storeFilter(storeName)) {
              return nextState;
            }
            sendNextState({ app, encoder, options, storeName, nextState });
            return nextState;
          }
        );
      }
    };
  };
}
