import { MainPostingData, MainPostingValue } from '@revelio/d3';
import { PostingsDataQuery } from '@revelio/data-access';

const dateRegEx = /(.*)-(.*)-(.*)/;

type GetPostingsOvertimeDataProps = {
  entities: PostingsDataQuery['posting'];
};

export const getMainPostingsData = ({
  entities,
}: GetPostingsOvertimeDataProps): MainPostingData[] => {
  return (
    entities?.map((entity): MainPostingData => {
      const entityMetadata = entity?.metadata;

      return {
        id: entityMetadata?.id,
        metadata: {
          shortName: entityMetadata?.shortName,
          longName: entityMetadata?.shortName,
        },
        value: (entity?.category || []).map((category): MainPostingValue => {
          const metadata = category?.metadata;
          const metrics = category?.metrics;

          const id = metadata?.id;
          const shortName = metadata?.shortName;

          const active = metrics?.active;
          const value_new = metrics?.new;
          const removed = metrics?.removed;
          const expected_hires = metrics?.expected_hires;

          const match = shortName?.match(dateRegEx) || ['0', '0', '0', '0'];
          const [, year, month, day] = match;

          return {
            id,
            metadata: {
              shortName,
              longName: shortName,
              day: Number(day),
              month: Number(month),
              year: Number(year),
            },
            value: [
              {
                id: 1,
                metadata: {
                  shortName: 'Active',
                  longName: 'Active Postings',
                },
                value: active,
              },
              {
                id: 2,
                metadata: { shortName: 'New', longName: 'New Postings' },
                value: value_new,
              },
              {
                id: 3,
                metadata: {
                  shortName: 'Removed',
                  longName: 'Removed Postings',
                },
                value: removed,
              },
              {
                id: 4,
                metadata: {
                  shortName: 'Expected Hires',
                  longName: 'Expected Hires',
                },
                value: expected_hires,
              },
            ],
          };
        }),
      };
    }) ?? []
  );
};
