import { BottomPlotData, ChartData, TopPlotData } from '../data-fetch';

type ConfigMapperBase<T> = {
  endpointSegment: T;
};
export type ConfigMapperData<T, D> = T & {
  data: D;
};

/** ================================
 * Top Config Mapper
 ================================ */
type TopPlotSegment =
  | 'headcount'
  | 'growth'
  | 'hiring'
  | 'attrition'
  | 'tenure'
  | 'salary';
export type TopConfigMapperBase = ConfigMapperBase<TopPlotSegment>;
export const getTopPlotMappers = <T extends TopConfigMapperBase>({
  configMapper,
  chartData,
}: {
  configMapper: T[];
  chartData: ChartData;
}): ConfigMapperData<T, TopPlotData>[] => {
  return configMapper.map((config) => {
    switch (config.endpointSegment) {
      case 'headcount':
        return { ...config, data: chartData.headcountData };
      case 'growth':
        return { ...config, data: chartData.growthRateData };
      case 'hiring':
        return { ...config, data: chartData.hiringRateData };
      case 'attrition':
        return { ...config, data: chartData.attritionRateData };
      case 'tenure':
        return { ...config, data: chartData.tenureData };
      case 'salary':
        return { ...config, data: chartData.salaryData };

      default:
        throw new Error('Invalid endpoint segment');
    }
  });
};

/** ================================
 * Bottom Config Mapper
 ================================ */
type BottomPlotSegment =
  | 'role'
  | 'geo'
  | 'skill'
  // | 'seniority'
  | 'gender'
  | 'ethnicity'
  | 'education'
  | 'industry';
export type BottomConfigMapperBase = ConfigMapperBase<BottomPlotSegment>;
export const getBottomPlotMappers = <T extends BottomConfigMapperBase>({
  configMapper,
  chartData,
}: {
  configMapper: T[];
  chartData: ChartData;
}): ConfigMapperData<T, BottomPlotData>[] => {
  return configMapper.map((config) => {
    switch (config.endpointSegment) {
      case 'role':
        return { ...config, data: chartData.roleData };
      case 'geo':
        return { ...config, data: chartData.geographyData };
      // case 'seniority':
      //   return { ...config, data: chartData.seniorityData };
      case 'gender':
        return { ...config, data: chartData.genderData };
      case 'ethnicity':
        return { ...config, data: chartData.ethnicityData };
      case 'education':
        return { ...config, data: chartData.educationData };
      case 'industry':
        return { ...config, data: chartData.industryData };
      case 'skill':
        return { ...config, data: chartData.skillData };
      default:
        throw new Error('Invalid endpoint segment');
    }
  });
};
