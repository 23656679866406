import { createStore, propsFactory } from '@ngneat/elf';
import {
  getAllEntities,
  getEntitiesCountByPredicate,
  getEntity,
  selectEntity,
  updateEntities,
  withEntities,
} from '@ngneat/elf-entities';
import {
  CardListSubMenuProps,
  ColumnItem,
  ColumnSet,
  ICardListSelectProps,
  isColumnCardListSubmMenu,
} from '@revelio/layout';
import {
  CompletePostingPipeline,
  Deliverable,
  InputRefs,
  isIndividualPostingPipeline,
  isSkillDynamPipeline,
} from './deliverables.model';
import { UpdateFn } from '@ngneat/elf-entities/src/lib/update.mutation';
import {
  CompanyMapperVersion,
  EthnicityVersion,
  JobTaxonomyVersion,
  LocationVersion,
  ModelVersions,
  PipelineColumnType,
  PipelineType,
  PostingSource,
  SalaryVersion,
  SeniorityVersion,
  TimescalingVersion,
  WeightTable,
} from '@revelio/data-access';
import { pickKeys, write } from '@revelio/core';
import { StringKeyOf } from 'type-fest';
import { combineLatest, map } from 'rxjs';
import {
  intersectionWith,
  get,
  uniq,
  forEach,
  isNumber,
  isString,
} from 'lodash';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { debounceTime } from 'rxjs/operators';
import {
  addCombineWithColumns,
  dataSetColumnsMap,
  getDatasetColumnsList,
  getDatasetStandardDefault,
  getFlattenedOrderedColumnItems,
  hasCompanyInformation,
  isIndustrySupportedDataset,
  isMetroAreaColumnSupported,
} from './columns/columns.model';
import {
  getDuplicateDeliverables,
  getPrefilledNameByPipelineConfiguration,
} from './delivery/delivery.model';
import { getPipelineCompanyColumn } from './columns/company-column';
import {
  withSubsidiaryColumnMappings,
  withSubsidiarySelectedColumns,
} from './company-selection/subsidiary-mapping/subsidiary-mapping.repository';
import { withCompanyMapping } from './company-selection/company-mapping/company-mapping.respository';
import { DEFAULT_INDUSTRY_PIPELINE_COLUMNS } from './columns/configurations/workforce-dynamics';
import { getAuthStoreUser } from '@revelio/auth';

export const LATEST_MODEL_VERSIONS: ModelVersions = {
  location_version: LocationVersion.V3,
  timescaling_version: TimescalingVersion.V3_1,
  ethnicity_version: EthnicityVersion.V2,
  job_taxonomy_version: JobTaxonomyVersion.V2_1,
  company_mapper_version: CompanyMapperVersion.V2,
  salary_version: SalaryVersion.V2,
  seniority_version: SeniorityVersion.V2_1,
  weight_table: WeightTable.V2,
  remove_bad_users: true,
};
export const DEFAULT_COMPANY_SELECTION: InputRefs = {
  pipeline_input: '',
  company_reference: '',
  company_sets: [],
};

export const NEW_DELIVERABLE_DEFAULTS: Omit<Deliverable, 'id'> = {
  model_versions: LATEST_MODEL_VERSIONS,
  pipeline: {
    pipeline_type: PipelineType.WfDynam,
  },
  ...DEFAULT_COMPANY_SELECTION,
};

export const {
  withName,
  updateName,
  selectName,
  resetName,
  getName,
  setName,
  setNameInitialValue,
} = propsFactory('name', {
  initialValue: '',
});

export const {
  withRecommendCompanyRef,
  updateRecommendCompanyRef,
  selectRecommendCompanyRef,
  resetRecommendCompanyRef,
  getRecommendCompanyRef,
  setRecommendCompanyRef,
  setRecommendCompanyRefInitialValue,
} = propsFactory('recommendCompanyRef', {
  initialValue: false,
});

export const {
  withDuplicateDatasetNaming,
  updateDuplicateDatasetNaming,
  selectDuplicateDatasetNaming,
  resetDuplicateDatasetNaming,
  getDuplicateDatasetNaming,
  setDuplicateDatasetNaming,
  setDuplicateDatasetNamingInitialValue,
} = propsFactory('duplicateDatasetNaming', {
  initialValue: {} as { [pipelineId: string]: string },
});

export const {
  withClient,
  updateClient,
  selectClient,
  resetClient,
  getClient,
  setClient,
  setClientInitialValue,
} = propsFactory('client', {
  initialValue: '',
});

export const {
  withLag_data,
  updateLag_data,
  selectLag_data,
  resetLag_data,
  getLag_data,
  setLag_data,
  setLag_dataInitialValue,
} = propsFactory('lag_data', {
  initialValue: false,
});

export const {
  withInputRefs,
  updateInputRefs,
  selectInputRefs,
  resetInputRefs: _resetInputRefs,
  getInputRefs,
  setInputRefs,
} = propsFactory('inputRefs', {
  initialValue: DEFAULT_COMPANY_SELECTION,
});

export const {
  withEnrichmentModals,
  updateEnrichmentModals,
  selectEnrichmentModals,
  resetEnrichmentModals,
  getEnrichmentModals,
  setEnrichmentModals,
  setEnrichmentModalsInitialValue,
} = propsFactory('enrichmentModals', {
  initialValue: [] as string[],
});

export const deliverablesStore = createStore(
  {
    name: 'deliverables',
  },
  withLag_data(),
  withName(),
  withClient(),
  withEntities<Deliverable>({
    initialValue: [],
  }),
  withRecommendCompanyRef(),
  withInputRefs(),
  withSubsidiaryColumnMappings(),
  withSubsidiarySelectedColumns(),
  withEnrichmentModals(),
  withCompanyMapping(),
  withDuplicateDatasetNaming()
);

export const deliverableKeys = ['client', 'name', 'lag_data', 'entities'];

const deliverableKeysToPersist = [...deliverableKeys, 'inputRefs', 'ids'];

export const persistedDeliverablesRef = persistState(deliverablesStore, {
  key: 'deliverables',
  storage: sessionStorageStrategy,
  source: () =>
    deliverablesStore.pipe(
      debounceTime(1000),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pickKeys(deliverableKeysToPersist as any[])
    ),
});

export const updateDraftDeliverable = (
  entityId: Deliverable['id'],
  stateUpdate: UpdateFn<Deliverable>
) => {
  deliverablesStore.update(updateEntities(entityId, stateUpdate));
};

export const updateModelVersions = ({
  entityId,
  newModelVersions,
}: {
  entityId: Deliverable['id'];
  newModelVersions: Partial<ModelVersions>;
}) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.model_versions = {
        ...state.model_versions,
        ...newModelVersions,
      } as ModelVersions;
    })
  );
};

export const resetModelVersionsToLatest = ({
  overrideVersions = {},
  entityId,
}: {
  overrideVersions?: Partial<typeof LATEST_MODEL_VERSIONS>;
  entityId: Deliverable['id'];
}) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.model_versions = {
        ...LATEST_MODEL_VERSIONS,
        ...overrideVersions,
      };
    })
  );
};

export const getColumnConfigById = ({
  pipelineType,
  columnId,
}: {
  pipelineType: PipelineType;
  columnId: PipelineColumnType;
}) => {
  const columnConfig = dataSetColumnsMap[pipelineType];
  return getFlattenedOrderedColumnItems(columnConfig).find(
    (col) => col.id === columnId
  );
};

export const removeColumnsById = ({
  entityId,
  ids,
}: {
  entityId: Deliverable['id'];
  ids: PipelineColumnType[];
}) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      const columnsWithoutRemovedColumn = state.pipeline.columns?.filter(
        (c) => !ids.includes(c)
      );
      state.pipeline.columns = columnsWithoutRemovedColumn;
    })
  );
};

export const setOrderedColumns = ({
  entityId,
  columnIds,
}: {
  entityId: Deliverable['id'];
  columnIds: PipelineColumnType[];
}) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.pipeline.columns = getOrderedCombinedColumns({
        entityId,
        columnIds,
      });
    })
  );
};

export const getOrderedCombinedColumns = ({
  entityId,
  columnIds,
}: {
  entityId: Deliverable['id'];
  columnIds: PipelineColumnType[];
}) => {
  const modelVersions = deliverablesStore.query(
    getEntity(entityId)
  )?.model_versions;
  const withAddedColumns = intersectionWith(
    getFlattenedOrderedColumnItems(
      getCompatibleColumns({
        entityId,
        modelVersions,
      })
    ),
    [...columnIds, ...getMandatoryColumnIds({ entityId, modelVersions })],
    (columnConfig, columnId) => columnConfig.id === columnId
  ); // this forces column order based on the order of the columnsList/column.model source of truth

  return withAddedColumns.reduce(addCombineWithColumns, []);
};

export const resetInputRefs = () => deliverablesStore.update(_resetInputRefs());

export const upsertInputRefs = (updates: Partial<InputRefs>) => {
  deliverablesStore.update(
    _resetInputRefs(), // this is to enforce only 1 input ref at a time until DAG supports multiple company selection types
    updateInputRefs(updates)
  );
};

export const ASYNC_LOADING_MAPPING_PLACEHOLDER =
  'loading_creating_ref_table_placeholder';
export const isAsyncLoadingMapping = () => {
  return (
    deliverablesStore.query(
      getEntitiesCountByPredicate(
        (draftDeliverable) =>
          draftDeliverable.company_reference ===
          ASYNC_LOADING_MAPPING_PLACEHOLDER
      )
    ) > 0
  );
};
export const updatePipelineCompanySelection = (
  entityId: Deliverable['id'],
  updates: Partial<InputRefs>
) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.company_reference = updates.company_reference;
      state.pipeline_input = updates.pipeline_input;
      state.company_sets = updates.company_sets;
    })
  );

  removeColumnsById({
    entityId,
    ids: DEFAULT_INDUSTRY_PIPELINE_COLUMNS.menuItems.map((c) => c.id),
  });
};

export function getInputRef<R = string>(ref: StringKeyOf<InputRefs>) {
  const refs = deliverablesStore.query(getInputRefs);
  return refs[ref] as R;
}

export const removeColumnItemsFromDatasetColumns = (
  columnList: ColumnItem<PipelineColumnType>[],
  columnIdsToRemove: PipelineColumnType[]
) => {
  return columnList.reduce<ColumnItem<PipelineColumnType>[]>(
    (compatibleColumns, columnItem) => {
      if (isColumnCardListSubmMenu(columnItem)) {
        return [
          ...compatibleColumns,
          {
            ...columnItem,
            menuItems: columnItem.menuItems.filter(
              (column) => !columnIdsToRemove.includes(column.id)
            ),
          },
        ];
      }

      if (columnIdsToRemove.includes(columnItem.id)) {
        return compatibleColumns;
      }

      return [...compatibleColumns, columnItem];
    },
    []
  );
};

type ColumnPropertyOverrideMap = {
  [columnIdToOverride: string]: Partial<ICardListSelectProps<string>>;
};
export const overrideColumnPropertiesFromDatasetColumns = ({
  columnList,
  columnPropertiesToOverride,
}: {
  columnList: ColumnItem<PipelineColumnType>[];
  columnPropertiesToOverride: ColumnPropertyOverrideMap;
}) => {
  return write<ColumnItem<PipelineColumnType>[]>((columnList) => {
    forEach(
      columnPropertiesToOverride,
      (overrideProperties, columnIdToOverride) => {
        const columnItemIndex = columnList.findIndex(
          (c) => c.id === columnIdToOverride
        );
        if (columnItemIndex !== -1) {
          columnList[columnItemIndex] = {
            ...(columnList[
              columnItemIndex
            ] as CardListSubMenuProps<PipelineColumnType>),
            ...overrideProperties,
          };
        } else {
          const subMenusToSearchForOverrideColumn = columnList.filter(
            isColumnCardListSubmMenu
          );
          forEach(subMenusToSearchForOverrideColumn, (subMenu) => {
            const menuItemForColumnOverrideIndex = subMenu.menuItems.findIndex(
              (c) => c.id === columnIdToOverride
            );
            const hasFoundColumnToOverrideInSubMenu =
              menuItemForColumnOverrideIndex !== -1;
            if (hasFoundColumnToOverrideInSubMenu) {
              const subMenuWithOverrideInListIndex = columnList.findIndex(
                (colItem) => colItem.id === subMenu.id
              );
              (
                columnList[
                  subMenuWithOverrideInListIndex
                ] as CardListSubMenuProps<string>
              ).menuItems[menuItemForColumnOverrideIndex] = {
                ...subMenu.menuItems[menuItemForColumnOverrideIndex],
                ...overrideProperties,
              };
            }
          });
        }
      }
    );
  })(columnList);
};

export const getPipelineType = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}): PipelineType => {
  return deliverablesStore.query(getEntity(entityId))?.pipeline
    .pipeline_type as PipelineType;
};

export const selectCompatibleColumns = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  return combineLatest({
    modelVersions: deliverablesStore.pipe(
      selectEntity(entityId),
      map((deliverable) => deliverable?.model_versions ?? LATEST_MODEL_VERSIONS)
    ),
    selectedColumns: deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          get(deliverable, 'pipeline.columns', []) as PipelineColumnType[]
      )
    ),
  }).pipe(
    map(({ modelVersions, selectedColumns }) =>
      getCompatibleColumns({
        modelVersions,
        entityId,
      })
    )
  );
};

export const getCompatibleColumns = ({
  entityId,
  modelVersions,
}: {
  entityId: Deliverable['id'];
  modelVersions: ModelVersions | undefined;
}) => {
  const locationVersion = modelVersions?.location_version;
  let columns = getDatasetColumnsList({ entityId });

  if (locationVersion && isMetroAreaColumnSupported(locationVersion)) {
    columns = columns.map((columnSet) => ({
      ...columnSet,
      columns: removeColumnItemsFromDatasetColumns(columnSet.columns, [
        PipelineColumnType.Msa,
      ]),
    }));
  } else {
    columns = columns.map((columnSet) => ({
      ...columnSet,
      columns: removeColumnItemsFromDatasetColumns(columnSet.columns, [
        PipelineColumnType.MetroArea,
      ]),
    }));
  }

  const pipelineType = getPipelineType({ entityId });
  if (isIndustrySupportedDataset(pipelineType)) {
    if (!hasCompanySelection({ entityId })) {
      const commonColumnPropertiesToOverride = {
        [DEFAULT_INDUSTRY_PIPELINE_COLUMNS.id]: {
          isDisabled: false,
          disabledExplanation: undefined,
        },
        [PipelineColumnType.Company]: {
          isMandatory: false,
          isDisabled: true,
          disabledExplanation:
            'Company cannot be selected without a company selection',
        },
      };

      const genderEthnicityOverrides = {
        [PipelineColumnType.Gender]: {
          isDisabled: true,
          disabledExplanation:
            'Gender cannot be selected without a company selection',
        },
        [PipelineColumnType.Ethnicity]: {
          isDisabled: true,
          disabledExplanation:
            'Ethnicity cannot be selected without a company selection',
        },
      };

      const columnPropertiesToOverride = {
        ...commonColumnPropertiesToOverride,
        ...(isSkillDynamPipeline(pipelineType) ? genderEthnicityOverrides : {}),
      };

      columns = write<ColumnSet<PipelineColumnType>[]>((config) => {
        config[0].columns = overrideColumnPropertiesFromDatasetColumns({
          columnList: config[0].columns,
          columnPropertiesToOverride,
        });
      })(columns);
    } else {
      columns = write<ColumnSet<PipelineColumnType>[]>((config) => {
        config[0].columns = overrideColumnPropertiesFromDatasetColumns({
          columnList: config[0].columns,
          columnPropertiesToOverride: {
            [PipelineColumnType.Company]: {
              isMandatory: true,
            },
          },
        });
      })(columns);
    }
  }

  if (isIndividualPostingPipeline(pipelineType)) {
    const columnPropertiesToOverride = {
      [PipelineColumnType.ExpectedHires]: {
        isDisabled: true,
        disabledExplanation: 'Only available for unified postings source',
      },
    };
    const postingsPipeline = deliverablesStore.query(getEntity(entityId))
      ?.pipeline as CompletePostingPipeline;
    if (postingsPipeline?.source !== PostingSource.Unified) {
      columns = write<ColumnSet<PipelineColumnType>[]>((config) => {
        config[0].columns = overrideColumnPropertiesFromDatasetColumns({
          columnList: config[0].columns,
          columnPropertiesToOverride,
        });
      })(columns);
    }
  }

  const userClientName = getAuthStoreUser()?.client_name;
  const CLIENT_NAMES_SUPPORTING_TIMESCALING_COLUMNS = ['Bain Capital'];
  const isClientSupportingTimescaling =
    CLIENT_NAMES_SUPPORTING_TIMESCALING_COLUMNS.includes(
      userClientName as string
    );
  if (isClientSupportingTimescaling) {
    const CLIENTS_SUPPORTING_TIMESCALING_INTERNAL_COLUMN_OVERRIDES = [
      PipelineColumnType.RawCount,
      PipelineColumnType.ScaledCount,
      PipelineColumnType.RawInflow,
      PipelineColumnType.ScaledInflow,
      PipelineColumnType.RawOutflow,
      PipelineColumnType.ScaledOutflow,
      PipelineColumnType.Hiring,
      PipelineColumnType.Attrition,
    ];
    const CLIENTS_SUPPORTING_TIMESCALING_UNSUPPORTED_COLUMNS = [
      PipelineColumnType.Count,
      PipelineColumnType.Inflow,
      PipelineColumnType.Outflow,
    ];
    columns = columns.map((columnSet) => {
      const supportedColumns = removeColumnItemsFromDatasetColumns(
        columnSet.columns,
        CLIENTS_SUPPORTING_TIMESCALING_UNSUPPORTED_COLUMNS
      );
      const exposeInternalOnlyColumns =
        overrideColumnPropertiesFromDatasetColumns({
          columnList: supportedColumns,
          columnPropertiesToOverride:
            CLIENTS_SUPPORTING_TIMESCALING_INTERNAL_COLUMN_OVERRIDES.reduce(
              (propertyOverrides, columnId) => ({
                ...propertyOverrides,
                [columnId]: { internalOnly: false },
              }),
              {} as ColumnPropertyOverrideMap
            ),
        });
      return {
        ...columnSet,
        columns: exposeInternalOnlyColumns,
      };
    });
  }

  return columns;
};

export const setMandatoryColumns = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const modelVersions = deliverablesStore.query(
    getEntity(entityId)
  )?.model_versions;
  const mandatoryColumns = getMandatoryColumnIds({ entityId, modelVersions });
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.pipeline.columns = mandatoryColumns;
    })
  );
};

const getMandatoryColumnIds = ({
  entityId,
  modelVersions,
}: {
  entityId: Deliverable['id'];
  modelVersions: ModelVersions | undefined;
}) => {
  const columnsList = getCompatibleColumns({
    modelVersions,
    entityId,
  });
  return uniq(
    getFlattenedOrderedColumnItems(columnsList)
      .filter((column) => column.isMandatory)
      .reduce(addCombineWithColumns, [] as PipelineColumnType[])
  );
};

export const setColumnsToDefault = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const configuredDefaultColumns = getDatasetStandardDefault({ entityId });

  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.pipeline.columns = configuredDefaultColumns;
    })
  );
};

export const hasCompanyInColumns = ({
  pipelineType,
  columns,
}: {
  pipelineType: PipelineType;
  columns?: PipelineColumnType[];
}): boolean => {
  const companySelectionColumn = getPipelineCompanyColumn(pipelineType);
  return !!columns?.includes(companySelectionColumn);
};

export const hasCompanySelection = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}): boolean => {
  const deliverable = deliverablesStore.query(getEntity(entityId));
  return hasCompanyInformation({
    company_reference: deliverable?.company_reference,
    pipeline_input: deliverable?.pipeline_input,
    company_sets: deliverable?.company_sets,
  });
};

export const getCustomColumnByName = ({
  name,
  entityId,
}: {
  name: string;
  entityId: Deliverable['id'];
}) => {
  return deliverablesStore
    .query(getEntity(entityId))
    ?.pipeline.custom_columns?.find((column) => column.name === name);
};

export const deleteCustomColumnByName = ({
  entityId,
  name,
}: {
  entityId: Deliverable['id'];
  name: string;
}) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.pipeline.custom_columns = state.pipeline.custom_columns?.filter(
        (col) => col.name !== name
      );
    })
  );
};

export const getDatasetFilterByName = ({
  name,
  entityId,
}: {
  name: string;
  entityId: Deliverable['id'];
}) => {
  return deliverablesStore
    .query(getEntity(entityId))
    ?.pipeline.filters?.find((column) => column.name === name);
};

export const deleteDatasetFilterByName = ({
  entityId,
  name,
}: {
  entityId: Deliverable['id'];
  name: string;
}) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.pipeline.filters = state.pipeline.filters?.filter(
        (col) => col.name !== name
      );
    })
  );
};

export const clearCompanyInformation = (entityId: Deliverable['id']) => {
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.company_reference = DEFAULT_COMPANY_SELECTION.company_reference;
      state.pipeline_input = DEFAULT_COMPANY_SELECTION.pipeline_input;
      state.company_sets = DEFAULT_COMPANY_SELECTION.company_sets;
    })
  );
};

export const prefillStandardSetDuplicateUniqueFolderNames = () => {
  const deliverables = deliverablesStore.query(getAllEntities());
  const duplicateDeliverables =
    getDuplicateDeliverables<Deliverable>(deliverables);

  const duplicateDatasetNaming = deliverablesStore.query(
    getDuplicateDatasetNaming
  );
  duplicateDeliverables.forEach((d) => {
    if (!duplicateDatasetNaming[d.id]) {
      duplicateDatasetNaming[d.id] = getPrefilledNameByPipelineConfiguration(d);
    }
  });
  deliverablesStore.update(setDuplicateDatasetNaming(duplicateDatasetNaming));
};

export const isValidEntityId = (
  entityId: string | number | undefined | null
): entityId is string | number => {
  return isNumber(entityId) || isString(entityId);
};

export const isDuplicatedDeliverable = () => {
  // the name will only be set if duplicated deliverable
  return !!deliverablesStore.query(getName);
};
