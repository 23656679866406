import ScaledIframe from './ScaledIframe';

export const ReportIframe = ({ link }: { link: string }) => {
  return (
    <div
      style={{
        height: 'calc(100vh - 84px)',
        width: 'calc(100vw - 102px)',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <ScaledIframe src={link} scale={0.86} />
    </div>
  );
};
