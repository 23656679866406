import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Text } from '@chakra-ui/layout';
import { Button, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';
import CertificateRaw from './resume-sections/CertificateRaw';
import ContactInformationEnriched from './resume-sections/ContactInformationEnriched';
import ContactInformationRaw from './resume-sections/ContactInformationRaw';
import EducationEnriched from './resume-sections/EducationEnriched';
import EducationRaw from './resume-sections/EducationRaw';
import EmploymentEnriched from './resume-sections/EmploymentEnriched';
import EmploymentRaw from './resume-sections/EmploymentRaw';
import ProjectEnriched from './resume-sections/ProjectEnriched';
import ProjectRaw from './resume-sections/ProjectRaw';
import PublicationRaw from './resume-sections/PublicationRaw';
import ReferenceRaw from './resume-sections/ReferenceRaw';
import SkillsEnriched from './resume-sections/SkillsEnriched';
import SkillsRaw from './resume-sections/SkillsRaw';
import { ParsedResume } from './resumeTypes';

const GRID_COLUMN_GAP = 18;
const TOP_GRID_SECTION_HEIGHT = 55;
const BOTTOM_GRID_SECTION_HEIGHT = 10;
const GRID_TEMPLATE_COLUMNS = `calc(60% - ${GRID_COLUMN_GAP / 2}px) calc(40% - ${GRID_COLUMN_GAP / 2}px)`;

interface EnrichedResumeProps {
  resume: null | ParsedResume;
  variant?: string;
}

const EnrichedResume = React.forwardRef<HTMLDivElement, EnrichedResumeProps>(
  ({ resume, variant }) => {
    const styles = useMultiStyleConfig('Resume', {
      variant: variant || 'resume',
    });
    return (
      <>
        {/* The grid is split up into a header, middle, and footer so that the middle part scrolls nicely */}
        <HeaderGridSection variant={variant} />
        <Grid
          gridColumnGap={`${GRID_COLUMN_GAP}px`}
          flex="1"
          templateColumns={GRID_TEMPLATE_COLUMNS}
          overflowY="scroll"
          overflowX="hidden"
          maxHeight={`calc(100% - ${TOP_GRID_SECTION_HEIGHT + BOTTOM_GRID_SECTION_HEIGHT}px)`}
          sx={{
            'scrollbar-width': 'none',
          }}
        >
          {/* Personal Info */}
          <ContactInformationRaw
            data={resume?.contact_information?.raw}
            variant={variant}
          />
          <ContactInformationEnriched
            data={resume?.contact_information?.en}
            variant={variant}
          />

          {/* Work Experience */}
          {!!Object.keys(resume?.employment || {}).length && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>Work Experience</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>

              {Object.keys(resume?.employment || {}).map((key, index) => (
                <React.Fragment key={`resume-employment-section-${index}`}>
                  <EmploymentRaw
                    data={resume?.employment[key]?.raw}
                    variant={variant}
                  />
                  <EmploymentEnriched
                    data={resume?.employment[key].en}
                    variant={variant}
                  />
                  <SectionDividers
                    index={index}
                    listLength={
                      Object.values(resume?.employment || {}).length - 1
                    }
                  />
                </React.Fragment>
              ))}
            </>
          )}

          {/* Education */}
          {!!Object.keys(resume?.education || {}).length && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>Education</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>

              {Object.keys(resume?.education || {}).map((key, index) => (
                <React.Fragment key={`resume-education-section-${index}`}>
                  <EducationRaw
                    data={resume?.education[key].raw}
                    variant={variant}
                  />
                  <EducationEnriched
                    data={resume?.education[key].en}
                    variant={variant}
                  />
                  <SectionDividers
                    index={index}
                    listLength={
                      Object.values(resume?.education || {}).length - 1
                    }
                  />
                </React.Fragment>
              ))}
            </>
          )}

          {/* Projects */}
          {!!Object.keys(resume?.projects || {}).length && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>Projects</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>

              {Object.keys(resume?.projects || {}).map((key, index) => (
                <React.Fragment key={`resume-project-section-${index}`}>
                  <ProjectRaw
                    data={resume?.projects[key].raw}
                    variant={variant}
                  />
                  <ProjectEnriched
                    data={resume?.projects[key].en}
                    variant={variant}
                  />
                  <SectionDividers
                    index={index}
                    listLength={
                      Object.values(resume?.projects || {}).length - 1
                    }
                  />
                </React.Fragment>
              ))}
            </>
          )}

          {/* Skills */}
          {resume?.skills?.raw && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>Skills</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>
              <SkillsRaw data={resume?.skills.raw} variant={variant} />
              <SkillsEnriched data={resume?.skills.en} variant={variant} />
            </>
          )}

          {/* Certificates */}
          {!!Object.keys(resume?.certificates || {}).length && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>Certificates</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>

              {Object.keys(resume?.certificates || {}).map((key, index) => (
                <React.Fragment key={`resume-certificate-section-${index}`}>
                  <CertificateRaw
                    data={resume?.certificates[key].raw}
                    variant={variant}
                  />
                  <Box sx={styles['placeholderBox']} />
                  <SectionDividers
                    index={index}
                    listLength={
                      Object.values(resume?.certificates || {}).length - 1
                    }
                  />
                </React.Fragment>
              ))}
            </>
          )}

          {/* Publications */}
          {!!Object.keys(resume?.publications_patents || {}).length && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>Publications</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>

              {Object.keys(resume?.publications_patents || {}).map(
                (key, index) => (
                  <React.Fragment key={`resume-publications-section-${index}`}>
                    <PublicationRaw
                      data={resume?.publications_patents[key].raw}
                      variant={variant}
                    />
                    <Box sx={styles['placeholderBox']} />
                    <SectionDividers
                      index={index}
                      listLength={
                        Object.values(resume?.publications_patents || {})
                          .length - 1
                      }
                    />
                  </React.Fragment>
                )
              )}
            </>
          )}

          {/* References */}
          {!!Object.keys(resume?.references || {}).length && (
            <>
              <Box sx={styles['dividerBox']}>
                <Text sx={styles['categoryHeader']}>References</Text>
              </Box>
              <Box sx={styles['dividerBox']}>
                <Box sx={styles['categoryHeader']} />
              </Box>

              {Object.keys(resume?.references || {}).map((key, index) => (
                <React.Fragment key={`resume-references-section-${index}`}>
                  <ReferenceRaw
                    data={resume?.references[key].raw}
                    variant={variant}
                  />
                  <Box sx={styles['placeholderBox']} />
                  <SectionDividers
                    index={index}
                    listLength={
                      Object.values(resume?.references || {}).length - 1
                    }
                  />
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
        <BottomGridSection />
      </>
    );
  }
);

export default EnrichedResume;

const HeaderGridSection = ({ variant }: { variant?: string }) => {
  const isPDF = variant === 'pdf';
  const navigate = useNavigate();
  const titleBorderRadius = '10px';
  const titlePadding = isPDF ? '0 20px 15px 20px' : '15px 20px';
  return (
    <Grid
      gap={`${GRID_COLUMN_GAP}px`}
      flex="1"
      templateColumns={GRID_TEMPLATE_COLUMNS}
      height={`${TOP_GRID_SECTION_HEIGHT}px`}
    >
      <Box
        borderTopRightRadius={titleBorderRadius}
        borderTopLeftRadius={titleBorderRadius}
        backgroundColor="white"
        flex="1"
      >
        <Flex
          padding={titlePadding}
          borderBottomColor="gray.100"
          borderBottomWidth="1px"
          gap="8px"
          color="navyBlue.500"
          alignItems="center"
        >
          <Button
            variant="unstyled"
            size="lg"
            leftIcon={isPDF ? undefined : <ArrowBackIcon />}
            aria-label="view-icon"
            onClick={() => navigate('/resume-enrichment')}
            alignItems="center"
            display="flex"
            height="24px"
          >
            Source Resume
          </Button>
        </Flex>
      </Box>
      <Box
        borderTopRightRadius={titleBorderRadius}
        borderTopLeftRadius={titleBorderRadius}
        backgroundColor="white"
      >
        <Box
          borderBottomColor="gray.100"
          borderBottomWidth="1px"
          padding={titlePadding}
        >
          <Text size="18px" color="navyBlue.500" fontWeight={600}>
            Enriched Data
          </Text>
        </Box>
      </Box>
    </Grid>
  );
};

const BottomGridSection = () => {
  return (
    <Grid
      gap={`${GRID_COLUMN_GAP}px`}
      flex="1"
      templateColumns={GRID_TEMPLATE_COLUMNS}
      height={`${BOTTOM_GRID_SECTION_HEIGHT}px`}
    >
      <Box
        borderBottomRightRadius="10px"
        borderBottomLeftRadius="10px"
        backgroundColor="white"
        flex="1"
      />
      <Box
        borderBottomRightRadius="10px"
        borderBottomLeftRadius="10px"
        backgroundColor="white"
      />
    </Grid>
  );
};

const SectionDividers = ({
  index,
  listLength,
}: {
  index: number;
  listLength: number;
}) => {
  const styles = useMultiStyleConfig('Resume', { variant: 'resume' });

  if (index >= listLength) return null;
  return (
    <>
      <Box sx={styles['dividerBox']}>
        <Box sx={styles['divider']} width="100%" />
      </Box>
      <Box sx={styles['dividerBox']} pt="0" pb="0">
        <Box sx={styles['divider']} width="100%" />
      </Box>
    </>
  );
};
