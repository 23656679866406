import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/layout';
import React, { ReactElement, useContext, useEffect } from 'react';
import {
  ChakraStylesConfig,
  GroupBase,
  GroupHeadingProps,
  Select,
} from 'chakra-react-select';
import { Tooltip, useToast } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { findUniqueBestMatches } from './find-unique-best-matches';
import { SelectedOptionsInterface } from '../data-enrichment/types/dataEnrichmentTypes';
import { FileUploadContextType } from './file-upload-context';
import { SnowflakeMapperContextType } from '../snowflake-mapper/snowflake-mapper-context';

export interface MappingStepProps {
  onSubmit: () => void;
  columnOptions: SelectedOptionsInterface[];
  selectedOptions: SelectedOptionsInterface[];
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<SelectedOptionsInterface[]>
  >;
  ContainerElement: ({
    onSubmit,
    children,
  }: {
    onSubmit: () => void;
    children: ReactElement;
  }) => ReactElement;
  maxSampleDataColumnWidth?: string;
  UploadContext: React.Context<
    FileUploadContextType | SnowflakeMapperContextType
  >;
}
const MappingStep = ({
  columnOptions: options,
  onSubmit,
  selectedOptions,
  setSelectedOptions,
  ContainerElement,
  maxSampleDataColumnWidth = '1fr',
  UploadContext,
}: MappingStepProps) => {
  const { mappedColumns, headers, combinedData } = useContext(UploadContext);
  const toast = useToast();
  useEffect(() => {
    const hasNoColumnMappings = selectedOptions?.length === 0;
    const hasPreviousColumnMapping = mappedColumns?.length;
    if (hasPreviousColumnMapping && hasNoColumnMappings) {
      const previouslyMappedColumns = Array.from(headers, (item) => {
        const mappedFileHeaderItem = mappedColumns?.find(
          (mapping) => mapping[item]
        );
        // [item as keyof typeof data.mappedColumns];
        if (!mappedFileHeaderItem) {
          return null;
        }

        const mappedHeaderOption = options.find(
          (x) => x?.value === mappedFileHeaderItem[item]
        ) as SelectedOptionsInterface;
        return mappedHeaderOption;
      });
      setSelectedOptions(previouslyMappedColumns);
      return;
    }

    if (hasNoColumnMappings) {
      const defaultSelectedOptions = findUniqueBestMatches(
        headers || [],
        options
          ? options.map((opt) => ({
              label: opt?.value as string,
              value: opt?.value as string,
            }))
          : []
      );
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [headers, options, setSelectedOptions, selectedOptions, mappedColumns]);

  const getGroupedOptions = (index: number) => {
    const usedOptions = selectedOptions
      .filter(
        (option: SelectedOptionsInterface, idx: number) =>
          option && idx !== index
      )
      .map((option: SelectedOptionsInterface) => ({ ...option }));

    const availableOptions = options
      .filter(
        (opt) =>
          opt?.label &&
          opt?.value &&
          !selectedOptions.some(
            (selectedOpt: SelectedOptionsInterface) =>
              selectedOpt && selectedOpt?.value === opt?.value
          )
      )
      .map((opt) => ({ label: opt?.label || '', value: opt?.value || '' })); // Provide fallbacks

    const groupedOptions: {
      label: string;
      options: Readonly<SelectedOptionsInterface[]>;
    }[] = [];

    if (availableOptions.length) {
      groupedOptions.push({
        label: 'Unassigned Columns',
        options: availableOptions as Readonly<SelectedOptionsInterface[]>,
      });
    }

    if (usedOptions.length) {
      groupedOptions.push({
        label: 'Assigned Columns',
        options: usedOptions as Readonly<SelectedOptionsInterface[]>,
      });
    }

    return groupedOptions;
  };

  const handleSelectOption = (
    selectedOption: SelectedOptionsInterface | null,
    index: number
  ) => {
    setSelectedOptions(
      (prevSelectedOptions: SelectedOptionsInterface[] | null) => {
        const updatedSelectedOptions = prevSelectedOptions
          ? [...prevSelectedOptions]
          : [];

        const existingIndex = updatedSelectedOptions.findIndex(
          (opt) => opt && opt?.value === selectedOption?.value
        );
        if (existingIndex > -1) {
          updatedSelectedOptions[existingIndex] = null;
        }
        updatedSelectedOptions[index] = selectedOption;
        return updatedSelectedOptions;
      }
    );
  };

  const customStyles: ChakraStylesConfig<
    SelectedOptionsInterface,
    false,
    GroupBase<SelectedOptionsInterface>
  > = {
    option: (provided: object, state: object) => ({
      ...provided,
      backgroundColor: 'white',
      color: 'text.primary',
    }),
    groupHeading: (
      provided,
      state: GroupHeadingProps<
        SelectedOptionsInterface,
        false,
        GroupBase<SelectedOptionsInterface>
      >
    ) => ({
      ...provided,
      color:
        state?.data.label === 'Assigned Columns'
          ? 'green.500'
          : 'lightBlue.600',
      textTransform: 'uppercase',
    }),
  };

  const tableColHeadings = [
    'Uploaded Columns',
    'Sample Data',
    ' Assign Columns',
  ];

  interface CombinedDataInterface {
    header: string;
    sampleData: string[];
  }

  return ContainerElement({
    onSubmit: () => {
      if (selectedOptions.filter((option) => option).length >= 1) {
        onSubmit();
        return;
      }

      toast({
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
        title: 'No mapped columns!',
        description: 'You must map at least one or more columns!',
        isClosable: true,
      });
    },
    children: (
      <Box minH="400px">
        <Grid
          templateColumns="1fr 1fr 1.5fr"
          gap={4}
          mb={3}
          borderBottomWidth="1px"
          borderStyle="solid"
          borderBottomColor="silver.500"
        >
          {tableColHeadings.map((heading: string, index: number) => {
            if (index < 2) {
              return (
                <Text
                  key={`${heading}-${index}`}
                  fontWeight="bold"
                  textTransform="uppercase"
                  paddingInlineStart={8}
                  paddingInlineEnd={8}
                  py={4}
                >
                  {heading}
                </Text>
              );
            } else {
              return (
                <Flex
                  key={`${heading}-${index}`}
                  alignItems="center"
                  paddingInlineStart={8}
                  paddingInlineEnd={8}
                  py={4}
                  gap={2}
                >
                  <Text fontWeight="bold" textTransform="uppercase">
                    {heading}
                  </Text>
                  <Tooltip
                    label="Assign your uploaded columns to our column naming system."
                    textAlign="center"
                    hasArrow
                    placement="top"
                  >
                    <InfoOutlineIcon
                      boxSize={3}
                      color="text.primary"
                      cursor="pointer"
                    />
                  </Tooltip>
                </Flex>
              );
            }
          })}
        </Grid>
        <Stack gap={3}>
          {combinedData?.map((item, index: number) => {
            const { header, sampleData } = item as CombinedDataInterface;
            return (
              <Grid
                key={`${header}-${index}`}
                templateColumns={`1fr ${maxSampleDataColumnWidth} 1.5fr`}
                gap={4}
                h="100%"
                minH="110px"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="silver.500"
                backgroundColor={
                  selectedOptions[index] !== null ? 'green.50' : 'silver.200'
                }
                borderRadius={4}
              >
                <Text
                  paddingInlineStart={8}
                  paddingInlineEnd={8}
                  py={4}
                  color="text.primary"
                >
                  {header}
                </Text>
                <Text
                  paddingInlineStart={8}
                  paddingInlineEnd={8}
                  py={4}
                  color="text.primary"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {sampleData.map((value: string, index: number) => (
                    <span key={`${value}-${index}`} title={value}>
                      {value}
                      {index !== sampleData.length - 1 && <br />}
                    </span>
                  ))}
                </Text>
                <Box
                  paddingInlineStart={8}
                  paddingInlineEnd={8}
                  py={4}
                  backgroundColor={'white'}
                  w="full"
                >
                  <Select
                    key={index}
                    value={selectedOptions[index]}
                    options={getGroupedOptions(index)}
                    onChange={(option) => handleSelectOption(option, index)}
                    placeholder={`Assign this column ${
                      combinedData.length > 1 ? '(optional)' : ''
                    }`}
                    isClearable
                    chakraStyles={customStyles}
                  />
                </Box>
              </Grid>
            );
          })}
        </Stack>
      </Box>
    ),
  });
};

export default MappingStep;
