import {
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react';
import { ScreenerFilterOption } from '../types';
import { ScreenerSubFilterState } from '../screener-filter-reducer';
import { ScreenerFilterPopover } from '../screener-filter-popover';
import { InputMaybe, ScreenerReqSegments } from '@revelio/data-access';
import { FORMAT_MAP } from '../constants';
import { useScreenerFilter } from '../screener-filter-provider';
import { getSegmentsText } from '../utils/getSegmentsText';

interface SubFilterTagProps {
  subFilter: ScreenerSubFilterState;
  filterOptions: ScreenerFilterOption[];
}

export const SubFilterTag = ({
  filterOptions,
  subFilter,
}: SubFilterTagProps) => {
  const { dispatch } = useScreenerFilter();

  const filterOption = filterOptions.find(
    (option) => option.value === subFilter.filter.name
  );

  if (!filterOption) {
    return null;
  }

  const initialFilterValues = subFilter.filter;

  const handleSubfilterClear = () => {
    dispatch({ type: 'REMOVE_SUB_FILTER', filterId: subFilter.id });
  };

  const segmentsText = getSegmentsText(subFilter.treeItems);

  const highlightedTextProps = {
    color: 'lightBlue.600',
    fontWeight: 600,
  };

  const values = (() => {
    const startValue = initialFilterValues?.start_val;
    const endValue = initialFilterValues?.end_val;
    const format =
      FORMAT_MAP[
        filterOption.value as keyof Omit<
          ScreenerReqSegments,
          'inflow' | 'outflow'
        >
      ] || null;
    const prefix = format?.type === 'prefix' ? format.text : '';
    const suffix = format?.type === 'suffix' ? format.text : '';
    const formattedStartValue = `${prefix}${startValue}${suffix}`;
    const formattedEndValue = `${prefix}${endValue}${suffix}`;

    const isNumber = (value: InputMaybe<number> | undefined): value is number =>
      typeof value === 'number';

    if (isNumber(startValue) && isNumber(endValue)) {
      return (
        <Flex>
          <Text mr={0.5}>from</Text>
          <Text {...highlightedTextProps} mr={0.5}>
            {formattedStartValue}
          </Text>
          <Text mr={0.5}>to</Text>
          <Text {...highlightedTextProps}>{formattedEndValue}</Text>
        </Flex>
      );
    }

    if (isNumber(startValue)) {
      return (
        <Flex>
          <Text mr={0.5}>is greater than</Text>
          <Text {...highlightedTextProps}>{formattedStartValue}</Text>
        </Flex>
      );
    }

    return (
      <Flex>
        <Text mr={0.5}>is less than</Text>
        <Text {...highlightedTextProps}>{formattedEndValue}</Text>
      </Flex>
    );
  })();

  return (
    <ScreenerFilterPopover
      trigger={
        <Tag
          size="sm"
          variant="solid"
          colorScheme="blue"
          cursor="pointer"
          height={6}
          borderRadius="4px"
          _hover={{ background: 'white' }}
          as={Button}
          data-testid="sub-filter-tag"
        >
          <TagLabel _hover={{ textDecoration: 'underline' }}>
            <Flex>
              <Text {...highlightedTextProps} mr={0.5}>
                {filterOption.label}
              </Text>
              <Flex mr={0.5}>
                <Text>• for </Text>
                <Text mx={0.5} {...highlightedTextProps}>
                  {segmentsText}
                </Text>
                <Text>•</Text>
              </Flex>
              {/* )} */}
              <Text>{values}</Text>
            </Flex>
          </TagLabel>
          <TagCloseButton
            onClick={handleSubfilterClear}
            data-testid={`sub-filter-tag-remove-${filterOption.label}`}
          />
        </Tag>
      }
      filterOptions={filterOptions}
      selectedFilterOverride={filterOption}
      subFilterOverride={subFilter}
    />
  );
};
