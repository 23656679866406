import { useCallback, useMemo } from 'react';
import { Item } from '../../../../engine/filters.model';
import { Box, Flex, HStack } from '@chakra-ui/layout';
import { Button, Collapse, useDisclosure, Text } from '@chakra-ui/react';
import { FixedSizeTree } from 'react-vtree';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { AsyncTreeCheckboxNode } from '../../async-tree/async-tree-node/async-tree-checkbox-node';

import styles from '../../tree/tree.module.css';
import {
  CompanyResultItem,
  SchoolItem,
} from '../../async-tree/async-tree.types';
interface ExpansionTreeProps {
  selections: Record<string, Item | CompanyResultItem | SchoolItem>;
  selectionLookup: any;
  select: (item: Item, isChecked: boolean) => void;
  defaultIsOpen?: boolean;
  height?: number;
}

export function ExpansionTree({
  selections,
  selectionLookup,
  select,
  defaultIsOpen = false,
  height = 80,
}: ExpansionTreeProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });

  const activeSelections = useMemo(
    () => Object.values(selections || {}),
    [selections]
  );

  // TODO: Need to type this node
  const _getNodeData = (
    node: Item | CompanyResultItem | SchoolItem,
    nestingLevel: number
  ) => {
    const id =
      ((node as CompanyResultItem)?.rcid || (node as Item)?.id)?.toString() ||
      (node as SchoolItem)?.rsid;

    return {
      data: {
        id,
        item: node,
        isOpenByDefault: false,
        showHeadcount: false,
        isSelectedByDefault: true,
        isLeaf: true,
        select,
        selectionLookup: selectionLookup,
      },
      nestingLevel,
      node,
    };
  };

  const getNodeData = useCallback(_getNodeData, [select, selectionLookup]);

  const treeWalker = useCallback(
    function* _treeWalker(): any {
      for (const item of activeSelections) {
        yield getNodeData(item, 0);
      }
    },

    [activeSelections, getNodeData]
  );

  return (
    <Flex direction="column">
      <HStack>
        <Button
          size="sm"
          variant="link"
          fontWeight={400}
          paddingTop={2}
          paddingLeft={1}
          colorScheme="navyBlue"
          onClick={onToggle}
        >
          <Text fontWeight={600} fontSize="sm">
            {`${activeSelections.length} Selected`}
          </Text>
          <ChevronUpIcon
            boxSize={7}
            transform={isOpen ? 'rotate(180deg)' : undefined}
            transition="transform 0.2s"
          />
        </Button>
      </HStack>
      <Collapse in={isOpen}>
        {activeSelections.length > 0 && (
          <Box
            onWheel={(e) => {
              e.stopPropagation();
            }}
          >
            <FixedSizeTree
              className={styles.fixedSizedTree}
              treeWalker={treeWalker}
              itemSize={24}
              height={height}
            >
              {AsyncTreeCheckboxNode as any}
            </FixedSizeTree>
          </Box>
        )}
      </Collapse>
    </Flex>
  );
}
