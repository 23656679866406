import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';

import { emitMixpanelUserEvent, UserTrackingEvents } from '@revelio/core';
import { useUserTrialDetails } from '@revelio/auth';
import { TRIAL_TYPES_WITHOUT_LAG } from '@revelio/data-access';

import ContactSalesButton from '../contact-sales-button/contact-sales-button';

export const PlotDataLagWarning = ({
  persistent = false,
}: {
  persistent?: boolean;
}) => {
  const { isTrialUser, trialType } = useUserTrialDetails();

  if (
    !isTrialUser ||
    (trialType && TRIAL_TYPES_WITHOUT_LAG.includes(trialType))
  ) {
    return null;
  }

  return (
    <Popover
      trigger="hover"
      placement="top"
      openDelay={500}
      onOpen={() => {
        emitMixpanelUserEvent(UserTrackingEvents.TOOLTIP_OPEN);
      }}
      variant="tooltip"
      isLazy
    >
      <PopoverTrigger>
        <IconButton
          icon={<WarningTwoIcon color="orange" boxSize={3.5} />}
          variant="laggedData"
          opacity={persistent ? 1 : 0}
          _groupHover={{ opacity: 1 }}
          size="xs"
          aria-label="Data lagged"
          pb="2px"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Text variant="tooltip">
              The data displayed is lagged by one year. To access the most
              recent data, please <ContactSalesButton isLink />.
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default PlotDataLagWarning;
