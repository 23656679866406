import { formatPercentageToTwoSigFigs } from '@revelio/d3';
import {
  CompositionSupportedViewTypes,
  EndpointSegment,
  IChartPropsMap,
  ViewTypes,
} from '@revelio/filtering';

export const CompanySnapshotChartProps: IChartPropsMap = {
  [EndpointSegment.HEADCOUNT]: {
    name: 'headcount',
    chartStyle: `.overview-plot-top-${EndpointSegment.HEADCOUNT}`,
    chartPosition: '',
    preformatter: 'd', // converts values into integers first
    format: '.2s',
    tooltipFormat: ',.0f',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartSize: 'small',
  },
  [EndpointSegment.GROWTH]: {
    name: 'growth',
    chartStyle: `.overview-plot-top-${EndpointSegment.GROWTH}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartSize: 'small',
  },
  [EndpointSegment.HIRING]: {
    name: 'hiring',
    chartStyle: `.overview-plot-top-${EndpointSegment.HIRING}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartSize: 'small',
  },
  [EndpointSegment.ATTRITION]: {
    name: 'attrition',
    chartStyle: `.overview-plot-top-${EndpointSegment.ATTRITION}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartSize: 'small',
  },
  [EndpointSegment.TENURE]: {
    name: 'tenure',
    chartStyle: `.overview-plot-top-${EndpointSegment.TENURE}`,
    chartPosition: '',
    format: '.2r',
    tooltipFormat: '.3r',
    formatCustomString: 'yr',
    tooltipFormatCustomString: 'years',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartSize: 'small',
  },
  [EndpointSegment.SALARY]: {
    name: 'salary',
    chartStyle: `.overview-plot-top-${EndpointSegment.SALARY}`,
    chartPosition: 'right',
    format: '$.2s',
    tooltipFormat: '$.4s',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartSize: 'small',
  },
  [EndpointSegment.ROLE]: {
    name: 'role',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ROLE}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GEO]: {
    name: 'region',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GEO}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SENIORITY]: {
    name: 'seniority',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SENIORITY}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SKILL]: {
    name: 'skill',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SKILL}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GENDER]: {
    name: 'gender',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GENDER}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ETHNICITY]: {
    name: 'ethnicity',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ETHNICITY}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.EDUCATION]: {
    name: 'education',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.EDUCATION}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
};

export const CompanyOverTimeChartProps: IChartPropsMap = {
  [EndpointSegment.HEADCOUNT]: {
    name: 'trends-headcount',
    chartSize: 'small', //"small", "medium" or "large"
    chartPosition: 'left',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '.2s',
    dateFormat: 'YM', //YM or YMD
    ttType: 'single', //percent (%value & count in tooltip), custom (add ttCustomString after single value) or single (tooltip with single value)
    ttCustomString: 'days', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: ',.0f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.GROWTH]: {
    name: 'trends-growth',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.HIRING]: {
    name: 'trends-hiring',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ATTRITION]: {
    name: 'trends-attrition',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.TENURE]: {
    name: 'trends-tenure',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.2r',
    dateFormat: 'YM',
    ttType: 'custom',
    ttCustomString: 'years',
    ttMainFormat: '.3r',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SALARY]: {
    name: 'trends-salary',
    chartSize: 'small',
    chartPosition: 'right',
    metaValueCompany: 'shortName',
    yAxisFormat: '$.2s',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '$.4s',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ROLE]: {
    name: 'trends-role',
    chartSize: 'medium',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ROLE}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'postings',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GEO]: {
    name: 'trends-region',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GEO}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SENIORITY]: {
    name: 'trends-seniority',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SENIORITY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SKILL]: {
    name: 'trends-skills',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SKILL}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GENDER]: {
    name: 'trends-gender',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GENDER}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ETHNICITY]: {
    name: 'trends-ethnicity',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ETHNICITY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.EDUCATION]: {
    name: 'trends-education',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.EDUCATION}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
};

export const GeoSnapshotChartProps: IChartPropsMap = {
  [EndpointSegment.HEADCOUNT]: {
    name: 'headcount',
    chartStyle: `.overview-plot-top-${EndpointSegment.HEADCOUNT}`,
    chartPosition: '',
    format: '.2s',
    tooltipFormat: ',.0f',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.GROWTH]: {
    name: 'growth',
    chartStyle: `.overview-plot-top-${EndpointSegment.GROWTH}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.HIRING]: {
    name: 'hiring',
    chartStyle: `.overview-plot-top-${EndpointSegment.HIRING}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.ATTRITION]: {
    name: 'attrition',
    chartStyle: `.overview-plot-top-${EndpointSegment.ATTRITION}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.TENURE]: {
    name: 'tenure',
    chartStyle: `.overview-plot-top-${EndpointSegment.TENURE}`,
    chartPosition: '',
    format: '.2r',
    tooltipFormat: '.3r',
    formatCustomString: 'yr',
    tooltipFormatCustomString: 'years',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.SALARY]: {
    name: 'salary',
    chartStyle: `.overview-plot-top-${EndpointSegment.SALARY}`,
    chartPosition: 'right',
    format: '$.2s',
    tooltipFormat: '$.4s',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.ROLE]: {
    name: 'role',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ROLE}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GEO]: {
    name: 'region',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GEO}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SKILL]: {
    name: 'skill',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SKILL}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.INDUSTRY]: {
    name: 'industry',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.INDUSTRY}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SENIORITY]: {
    name: 'seniority',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SENIORITY}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GENDER]: {
    name: 'gender',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GENDER}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ETHNICITY]: {
    name: 'ethnicity',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ETHNICITY}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.EDUCATION]: {
    name: 'education',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.EDUCATION}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
};

export const GeoOverTimeChartProps: IChartPropsMap = {
  [EndpointSegment.HEADCOUNT]: {
    name: 'trends-headcount',
    chartSize: 'small',
    chartPosition: 'left',
    metaValueCompany: 'shortName',
    yAxisFormat: '.2s',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: ',.0f',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GROWTH]: {
    name: 'trends-growth',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.HIRING]: {
    name: 'trends-hiring',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ATTRITION]: {
    name: 'trends-attrition',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.TENURE]: {
    name: 'trends-tenure',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.2r',
    dateFormat: 'YM',
    ttType: 'custom',
    ttCustomString: 'years',
    ttMainFormat: '.3r',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SALARY]: {
    name: 'trends-salary',
    chartSize: 'small',
    chartPosition: 'right',
    metaValueCompany: 'shortName',
    yAxisFormat: '$.2s', //"$~s"
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '$.4s',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ROLE]: {
    name: 'trends-role',
    chartSize: 'medium',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ROLE}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'postings',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GEO]: {
    name: 'trends-region',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GEO}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SKILL]: {
    name: 'trends-skills',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SKILL}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.INDUSTRY]: {
    name: 'trends-industry',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.INDUSTRY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SENIORITY]: {
    name: 'trends-seniority',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SENIORITY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GENDER]: {
    name: 'trends-gender',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GENDER}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ETHNICITY]: {
    name: 'trends-ethnicity',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ETHNICITY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.EDUCATION]: {
    name: 'trends-education',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.EDUCATION}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
};

export const RoleSnapshotChartProps: IChartPropsMap = {
  [EndpointSegment.HEADCOUNT]: {
    name: 'headcount',
    chartStyle: `.overview-plot-top-${EndpointSegment.HEADCOUNT}`,
    chartPosition: '',
    format: '.2s',
    tooltipFormat: ',.0f',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.GROWTH]: {
    name: 'growth',
    chartStyle: `.overview-plot-top-${EndpointSegment.GROWTH}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.HIRING]: {
    name: 'hiring',
    chartStyle: `.overview-plot-top-${EndpointSegment.HIRING}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.ATTRITION]: {
    name: 'attrition',
    chartStyle: `.overview-plot-top-${EndpointSegment.ATTRITION}`,
    chartPosition: '',
    format: '.1%',
    customFormatter: formatPercentageToTwoSigFigs,
    tooltipFormat: '.1%',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.TENURE]: {
    name: 'tenure',
    chartStyle: `.overview-plot-top-${EndpointSegment.TENURE}`,
    chartPosition: '',
    format: '.2r',
    tooltipFormat: '.3r',
    formatCustomString: 'yr',
    tooltipFormatCustomString: 'years',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.SALARY]: {
    name: 'salary',
    chartStyle: `.overview-plot-top-${EndpointSegment.SALARY}`,
    chartPosition: 'right',
    format: '$.2s',
    tooltipFormat: '$.4s',
    singleColor: true,
    colorIndex: 0,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
  },
  [EndpointSegment.ROLE]: {
    name: 'role',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ROLE}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.INDUSTRY]: {
    name: 'industry',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.INDUSTRY}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SENIORITY]: {
    name: 'seniority',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SENIORITY}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GEO]: {
    name: 'region',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GEO}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SKILL]: {
    name: 'skill',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SKILL}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GENDER]: {
    name: 'gender',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GENDER}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ETHNICITY]: {
    name: 'ethnicity',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ETHNICITY}`,
    chartPosition: '',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.EDUCATION]: {
    name: 'education',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.EDUCATION}`,
    chartPosition: 'right',
    ttMainFormat: '.0%',
    ttSecondaryFormat: ',',
  },
};

export const RoleOverTimeChartProps: IChartPropsMap = {
  [EndpointSegment.HEADCOUNT]: {
    name: 'trends-headcount',
    chartSize: 'small',
    chartPosition: 'left',
    metaValueCompany: 'shortName',
    yAxisFormat: '.2s',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: ',.0f',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GROWTH]: {
    name: 'trends-growth',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.HIRING]: {
    name: 'trends-hiring',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ATTRITION]: {
    name: 'trends-attrition',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.TENURE]: {
    name: 'trends-tenure',
    chartSize: 'small',
    metaValueCompany: 'shortName',
    yAxisFormat: '.2r',
    dateFormat: 'YM',
    ttType: 'custom',
    ttCustomString: 'years',
    ttMainFormat: '.3r',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SALARY]: {
    name: 'trends-salary',
    chartSize: 'small',
    chartPosition: 'right',
    metaValueCompany: 'shortName',
    yAxisFormat: '$.2s', //"$~s"
    dateFormat: 'YM',
    ttType: 'single',
    ttCustomString: 'days',
    ttMainFormat: '$.4s',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ROLE]: {
    name: 'trends-role',
    chartSize: 'medium',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ROLE}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'postings',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.INDUSTRY]: {
    name: 'trends-industry',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.INDUSTRY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SENIORITY]: {
    name: 'trends-seniority',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SENIORITY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GEO]: {
    name: 'trends-region',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GEO}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.SKILL]: {
    name: 'trends-skills',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.SKILL}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.GENDER]: {
    name: 'trends-gender',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.GENDER}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.ETHNICITY]: {
    name: 'trends-ethnicity',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.ETHNICITY}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
  [EndpointSegment.EDUCATION]: {
    name: 'trends-education',
    chartStyle: `.overview-plot-bottom-${EndpointSegment.EDUCATION}`,
    metaValueCompany: 'shortName',
    yAxisFormat: '.0%',
    dateFormat: 'YM',
    ttType: 'percent',
    ttCustomString: 'days',
    ttMainFormat: '.1%',
    ttSecondaryFormat: ',',
  },
};

export const SnapshotChartPropsLookup: {
  [key in CompositionSupportedViewTypes]: IChartPropsMap;
} = {
  [ViewTypes.COMPANY]: CompanySnapshotChartProps,
  [ViewTypes.GEO]: GeoSnapshotChartProps,
  [ViewTypes.ROLE]: RoleSnapshotChartProps,
};

export const OverTimeChartPropsLookup: {
  [key in CompositionSupportedViewTypes]: IChartPropsMap;
} = {
  [ViewTypes.COMPANY]: CompanyOverTimeChartProps,
  [ViewTypes.GEO]: GeoOverTimeChartProps,
  [ViewTypes.ROLE]: RoleOverTimeChartProps,
};
