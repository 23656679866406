import { propsFactory } from '@ngneat/elf';
import { write } from '@revelio/core';
import {
  CompanyMapping,
  CompanyMappingNameChoice,
  RevelioMappedCompany,
} from './company-mapping.model';
import { deliverablesStore } from '../../deliverables.repository';
import { isNumber } from 'lodash';

export type CompanyMappingStateValue = CompanyMapping & {
  isExcluded?: boolean;
};

export type CompanyMappingState = {
  missingMappings: CompanyMappingStateValue[];
  verifiedMappings: CompanyMappingStateValue[];
  companyNameChoice: CompanyMappingNameChoice;
};

export const {
  withCompanyMapping,
  updateCompanyMapping,
  selectCompanyMapping,
  resetCompanyMapping,
  getCompanyMapping,
  setCompanyMapping,
} = propsFactory('companyMapping', {
  initialValue: null as CompanyMappingState | null,
});

export function excludeVerifiedMapping(rcid: number) {
  deliverablesStore.update(
    updateCompanyMapping((state) => {
      return write<CompanyMappingState | null>((state) => {
        const mappingToExclude = state?.verifiedMappings.find(
          (mapping) => mapping.response.rcid === rcid
        ) as CompanyMappingStateValue;
        mappingToExclude.isExcluded =
          mappingToExclude.isExcluded === undefined
            ? true
            : !mappingToExclude.isExcluded;
      })(state.companyMapping);
    })
  );
}

export function remapCompany({
  rcid,
  newMapping,
  missingCompaniesIndexToMap,
}: {
  rcid?: number;
  newMapping: RevelioMappedCompany;
  missingCompaniesIndexToMap?: number;
}) {
  deliverablesStore.update(
    updateCompanyMapping((state) => {
      return write<CompanyMappingState | null>((state) => {
        if (!state) {
          return;
        }
        if (isNumber(missingCompaniesIndexToMap)) {
          const requestCompanyId =
            state.missingMappings[missingCompaniesIndexToMap].request
              .company_id;
          state.verifiedMappings = [
            {
              ...state.missingMappings[missingCompaniesIndexToMap],
              // search returns rcid as string as company id when we want the user's provided company_id if there is one
              response: { ...newMapping, company_id: requestCompanyId },
            },
            ...state.verifiedMappings,
          ];
          state.missingMappings.splice(missingCompaniesIndexToMap, 1);
          return state;
        }

        const verifiedCompanyToRemap = state.verifiedMappings.find(
          (mapping) => mapping.response.rcid === rcid
        ) as CompanyMappingStateValue;
        if (verifiedCompanyToRemap) {
          const requestCompanyId = verifiedCompanyToRemap.request.company_id;
          // search returns rcid as string as company id when we want the user's provided company_id if there is one
          verifiedCompanyToRemap.response = {
            ...newMapping,
            company_id: requestCompanyId,
          };
        }
        return state;
      })(state.companyMapping);
    })
  );
}
