import { graphql } from '@revelio/data-access';

export const GET_ENTITY_DATA = graphql(`
  query GetEntityData($filters: EntityFilters) {
    entity(filters: $filters) {
      summary {
        name
        description
        founded
        headquarters
      }
      competitors {
        metadata {
          id
          shortName
          longName
        }
        closeness_score
      }
    }
  }
`);

export const GET_COMPANY_SUMMARY_MAP_DATA = graphql(`
  query CompanySummaryMapData($query: TalentDiscoveryV1_5Filter) {
    talent_discovery_search_v1_5(query: $query) {
      map_data {
        id
        type
        count
        share
        lat
        lon
        location
      }
    }
  }
`);

export const GET_GEOGRAPHY_SUMMARY_MAP_DATA = graphql(`
  query GeographySummaryMapData($query: MapCoordsReq!) {
    mapCoords(req: $query) {
      region {
        id
        name
        lat
        lon
      }
      country {
        id
        name
        lat
        lon
      }
      msa {
        id
        name
        lat
        lon
      }
    }
  }
`);

export const SENTIMENT_GET_SUMMARY_DATA = graphql(`
  query SentimentGetSummaryData($filters: SentimentFilters, $dim1: Dimension1) {
    sentiment2d(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          overallRating
          overallRatingCount
          businessOutlookRating
          businessOutlookRatingCount
          compensationsBenefitsRating
          compensationsBenefitsRatingCount
        }
      }
    }
  }
`);
