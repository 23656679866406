import { graphql } from '@revelio/data-access';

export const GAME_GET_RESULTS_STATS = graphql(`
  query GetGameResultsStats(
    $msa: [ID!]
    $role_k150: [ID!]
    $seniority: [ID!]
    $prevYearSentimentEndDate: String
    $postingsEndDate: String
  ) {
    rawComposition: composition(
      filters: { msa: $msa, role_k150: $role_k150, seniority: $seniority }
      dim1: geography
    ) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        headcount {
          timeseries {
            id
            date
            value
          }
        }
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        hiring_rate {
          timeseries {
            id
            date
            value
          }
        }
        attrition_rate {
          timeseries {
            id
            date
            value
          }
        }
        tenure {
          timeseries {
            id
            date
            value
          }
        }
        salary {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
    posting(
      filters: {
        msa: $msa
        role_k150: $role_k150
        seniority: $seniority
        provider: [9]
        start_date: "2019-03-04"
        end_date: $postingsEndDate
      }
      dim1: geography
      dim2: date
    ) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          time_to_fill
          active
        }
      }
    }
    currentYearSentiment: sentiment2d(
      filters: { msa: $msa, role_k150: $role_k150 }
      dim1: geography
      dim2: month
    ) {
      metrics {
        overallRating
      }
    }
    previousYearSentiment: sentiment2d(
      filters: {
        msa: $msa
        role_k150: $role_k150
        end_date: $prevYearSentimentEndDate
      }
      dim1: geography
      dim2: month
    ) {
      metrics {
        overallRating
      }
    }
  }
`);

export const TALENT_DISCOVERY_MAP_DATA = graphql(`
  query TalentDiscoveryMapData($query: TalentDiscoveryV1_5Filter) {
    talent_discovery_search_v1_5(query: $query) {
      map_data {
        id
        type
        count
        share
        lat
        lon
        location
      }
    }
  }
`);
