import { CardListSelectController } from '@revelio/layout';
import { useObservable } from '@ngneat/react-rxjs';
import {
  deliverablesStore,
  updateDraftDeliverable,
} from '../deliverables.repository';
import { selectEntity } from '@ngneat/elf-entities';
import { map } from 'rxjs';
import { write } from '@revelio/core';
import {
  CompleteCompanyInfoPipeline,
  Deliverable,
} from '../deliverables.model';

const INCLUDE_SUBSIDIARIES = 'include_subsidiaries';

export const CompanyReferenceDatasetConfiguration = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const [CompanyReferenceConfiguration] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          (deliverable?.pipeline as CompleteCompanyInfoPipeline)
            ?.company_info_configuration
      )
    )
  );

  return (
    <CardListSelectController<typeof INCLUDE_SUBSIDIARIES>
      variant="columns"
      items={[
        {
          heading: null,
          columns: [
            {
              id: INCLUDE_SUBSIDIARIES,
              label: 'Include subsidiaries',
            },
          ],
        },
      ]}
      gridProps={{
        spacing: 6,
        columns: 2,
        gridAutoFlow: 'row',
      }}
      value={
        CompanyReferenceConfiguration[INCLUDE_SUBSIDIARIES]
          ? [INCLUDE_SUBSIDIARIES]
          : []
      }
      onChange={(value: string[]) => {
        updateDraftDeliverable(
          entityId,
          write<Deliverable>((state) => {
            (
              state.pipeline as CompleteCompanyInfoPipeline
            ).company_info_configuration[INCLUDE_SUBSIDIARIES] = !(
              state.pipeline as CompleteCompanyInfoPipeline
            ).company_info_configuration[INCLUDE_SUBSIDIARIES];
          })
        );
      }}
    />
  );
};
