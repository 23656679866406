import { Button } from '@chakra-ui/react';
import {
  getHasTouredCompositions,
  tourStore,
  updateHasTouredCompositions,
} from '@revelio/core';
import { useGlobalLoaderV2 } from '@revelio/filtering';
import { useEffect } from 'react';
import { TooltipRenderProps } from 'react-joyride';

const CompositionPageStepCustomTooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  // this hasTouredCompositions exists so that the initial load for globalLoader is set to true first time
  // otherwise there's a second or two where you can click next before global loading starts and
  // if the user navigates back through the tour, we need it to be false loading otherwise it is stuck loading.
  const hasTouredCompositions = tourStore.query(getHasTouredCompositions);
  const globalLoader = useGlobalLoaderV2(!hasTouredCompositions);
  useEffect(() => {
    if (globalLoader === false) {
      tourStore.update(updateHasTouredCompositions(true));
    }
  }, [globalLoader]);
  return (
    <div style={step.styles?.tooltip} data-testid="custom-compositions-tooltip">
      <div {...tooltipProps} style={step.styles?.tooltipContainer}>
        {<h4 style={step.styles?.tooltipTitle}>{step.title}</h4>}
        <div style={step.styles?.tooltipContent}>{step.content}</div>
        <div style={step.styles?.tooltipFooter}>
          <Button {...skipProps} style={step.styles?.buttonSkip}>
            Skip Tour
          </Button>
          {index > 0 && (
            <Button {...backProps} style={step.styles?.buttonBack}>
              Back
            </Button>
          )}
          {continuous && (
            <Button
              {...primaryProps}
              style={step.styles?.buttonNext}
              isLoading={globalLoader}
            >
              Next ({index + 1}/{size})
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompositionPageStepCustomTooltip;
