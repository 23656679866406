export const DefaultIcon = () => {
  return (
    <svg
      width="207"
      height="153"
      viewBox="0 0 207 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="205"
        height="151"
        rx="3"
        fill="#EBEEF3"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <rect
        x="11.4546"
        y="11.4546"
        width="184.909"
        height="59.7273"
        rx="4"
        fill="#EBEEF3"
      />
      <rect
        x="12"
        y="12"
        width="183"
        height="129"
        fill="white"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <rect
        x="13"
        y="31"
        width="181"
        height="16"
        rx="4"
        fill="#5EC9EE"
        fillOpacity="0.2"
      />
      <rect
        x="13"
        y="61"
        width="181"
        height="16"
        rx="4"
        fill="#5EC9EE"
        fillOpacity="0.2"
      />
      <rect
        x="13"
        y="91"
        width="181"
        height="16"
        rx="4"
        fill="#5EC9EE"
        fillOpacity="0.2"
      />
      <rect
        x="13"
        y="121"
        width="181"
        height="15"
        rx="4"
        fill="#5EC9EE"
        fillOpacity="0.2"
      />
      <line x1="50.5935" y1="13" x2="50.5935" y2="140" stroke="#EAECF0" />
      <line x1="85.5935" y1="13" x2="85.5935" y2="140" stroke="#EAECF0" />
      <line x1="120.594" y1="13" x2="120.593" y2="140" stroke="#EAECF0" />
      <line x1="155.594" y1="13" x2="155.593" y2="140" stroke="#EAECF0" />
      <line x1="13.5215" y1="106.589" x2="194" y2="106.589" stroke="#EAECF0" />
      <line x1="13" y1="121.589" x2="193.478" y2="121.589" stroke="#EAECF0" />
      <line x1="13.5215" y1="91.5894" x2="194" y2="91.5894" stroke="#EAECF0" />
      <line x1="13.5215" y1="76.5894" x2="194" y2="76.5894" stroke="#EAECF0" />
      <line x1="13.5215" y1="61.5894" x2="194" y2="61.5894" stroke="#EAECF0" />
      <line x1="13.5215" y1="46.5894" x2="194" y2="46.5894" stroke="#EAECF0" />
      <line x1="13.5215" y1="31.5894" x2="194" y2="31.5894" stroke="#EAECF0" />
      <line
        x1="22"
        y1="25"
        x2="46.4233"
        y2="25"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="39.8572"
        x2="46.4233"
        y2="39.8572"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="54.7142"
        x2="46.4233"
        y2="54.7142"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="69.5713"
        x2="46.4233"
        y2="69.5713"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="84.4287"
        x2="46.4233"
        y2="84.4287"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="99.2858"
        x2="46.4233"
        y2="99.2858"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="114.143"
        x2="46.4233"
        y2="114.143"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="129"
        x2="46.4233"
        y2="129"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="25"
        x2="80.8154"
        y2="25"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="39.8572"
        x2="80.8154"
        y2="39.8572"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="54.7142"
        x2="80.8154"
        y2="54.7142"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="69.5713"
        x2="80.8154"
        y2="69.5713"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="84.4287"
        x2="80.8154"
        y2="84.4287"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="99.2858"
        x2="80.8154"
        y2="99.2858"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="114.143"
        x2="80.8154"
        y2="114.143"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="56.3921"
        y1="129"
        x2="80.8154"
        y2="129"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7844"
        y1="25"
        x2="115.208"
        y2="25"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="39.8572"
        x2="115.207"
        y2="39.8572"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="54.7142"
        x2="115.207"
        y2="54.7142"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="69.5713"
        x2="115.207"
        y2="69.5713"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="84.4287"
        x2="115.207"
        y2="84.4287"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="99.2858"
        x2="115.207"
        y2="99.2858"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="114.143"
        x2="115.207"
        y2="114.143"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="90.7842"
        y1="129"
        x2="115.207"
        y2="129"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.681"
        y1="25"
        x2="150.104"
        y2="25"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="39.8572"
        x2="150.104"
        y2="39.8572"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="54.7142"
        x2="150.104"
        y2="54.7142"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="69.5713"
        x2="150.104"
        y2="69.5713"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="84.4287"
        x2="150.104"
        y2="84.4287"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="99.2858"
        x2="150.104"
        y2="99.2858"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="114.143"
        x2="150.104"
        y2="114.143"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="125.68"
        y1="129"
        x2="150.104"
        y2="129"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="25"
        x2="185"
        y2="25"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="39.8572"
        x2="185"
        y2="39.8572"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="54.7142"
        x2="185"
        y2="54.7142"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="69.5713"
        x2="185"
        y2="69.5713"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="84.4287"
        x2="185"
        y2="84.4287"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="99.2858"
        x2="185"
        y2="99.2858"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="114.143"
        x2="185"
        y2="114.143"
        stroke="#2D426A"
        strokeWidth="2"
      />
      <line
        x1="160.577"
        y1="129"
        x2="185"
        y2="129"
        stroke="#2D426A"
        strokeWidth="2"
      />
    </svg>
  );
};
