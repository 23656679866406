import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { startCase } from 'lodash';
import { formatWithCommas } from '../helpers';
import { EducationEntryEn } from '../resumeTypes';
import PercentageBar from './PercentageBar';

interface EducationEnrichedProps {
  data?: EducationEntryEn;
  variant?: string;
}

export default function EducationEnriched({
  data,
  variant,
}: EducationEnrichedProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      {data?.institution_name && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Institution</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.institution_name}</Text>
        </Box>
      )}
      {data?.degree && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Degree</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.degree}</Text>
        </Box>
      )}
      {data?.field_of_study && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Field of study</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.field_of_study}</Text>
        </Box>
      )}
      {(data?.location_city || data?.location_state) && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Location</Text>
          <Text sx={styles['enrichedDataValue']}>
            {formatWithCommas([data?.location_city, data?.location_state])}
          </Text>
        </Box>
      )}
      {data?.location_metro && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Metro</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.location_metro)}
          </Text>
        </Box>
      )}
      {data?.location_country && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Country</Text>
          <Text sx={styles['enrichedDataValue']}>
            {startCase(data?.location_country)}
          </Text>
        </Box>
      )}
      {data?.prestige && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Prestige</Text>
          <Box sx={styles['enrichedDataValue']} mt="auto" mb="auto">
            {/* prestige is a range from -1 to 1 */}
            <PercentageBar
              percentage={(JSON.parse(data?.prestige || '0') + 1) / 2}
            />
          </Box>
        </Box>
      )}
      {data?.gpa && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>GPA</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.gpa}</Text>
        </Box>
      )}
      {data?.course?.length && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Course</Text>
          <Box sx={styles['enrichedDataValue']}>
            <ul style={{ paddingLeft: '10px' }}>
              {data?.course?.map((course, i) => (
                <li key={'course-' + i}>
                  <Text>{course}</Text>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
    </Box>
  );
}
