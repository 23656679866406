import { Box, Flex, Text } from '@chakra-ui/layout';

type Row = {
  label: string;
  value: string;
};

type TooltipContentProps = {
  title: string;
  subtitle: string;
  rows: Row[];
};
export const TooltipContent = ({
  title,
  subtitle,
  rows,
}: TooltipContentProps) => {
  return (
    <Box>
      <Text fontSize="13px" fontWeight="600" color="white">
        {title}
      </Text>
      <Text fontSize="11px" fontWeight="600" color="white" marginBottom="16px">
        {subtitle}
      </Text>

      {rows.map((row, i) => (
        <Flex key={i} paddingBottom="6px">
          <Text fontSize="10px" color="#D7DBE3" marginRight="40px">
            {row.label}
          </Text>
          <Text fontSize="10px" color="#D7DBE3">
            {row.value}
          </Text>
        </Flex>
      ))}
    </Box>
  );
};
