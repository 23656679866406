import { useQuery } from 'urql';
import { GetReportConfig } from '../report-builder-operations';
import {
  generateReportEntityMap,
  IReportEntityMap,
} from '../entity-configuration/utils';

import { GetReportConfigQuery } from '@revelio/data-access';

export const useReportBuilderConfig = () => {
  const [{ data, fetching: loading, error }] = useQuery<GetReportConfigQuery>({
    query: GetReportConfig,
  });

  const config: IReportEntityMap = data?.reports_validation
    ? generateReportEntityMap(data?.reports_validation)
    : {};

  return {
    config,
    loading,
    error,
  };
};
