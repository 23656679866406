/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CardProps,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ActionModal } from '@revelio/core';
import { ChartConfigForPlot, filterStore } from '@revelio/filtering';
import { PlotLinkModalStateType } from '../../types';
import { useEffect, useState } from 'react';
import { of } from 'rxjs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useObservable } from '@ngneat/react-rxjs';
import { BehaviorSubject } from 'rxjs';
import { useGetLoggedInUser } from '@revelio/auth';
import { plotColorLookup } from '@revelio/core';
import { stringify } from 'flatted';
import { get } from 'lodash';
import { PlotData } from '@revelio/layout';

export interface PlotLinkModalProps {
  plotLinkModalState: PlotLinkModalStateType;
  cardConfig?: Partial<CardProps>;
  typeAndProps?: ChartConfigForPlot;
  data$?: BehaviorSubject<any[] | Record<string, unknown>> | undefined;
  data?: PlotData;
}

export function PlotLinkModal({
  plotLinkModalState,
  cardConfig,
  typeAndProps,
  data$,
  data,
}: PlotLinkModalProps) {
  const { isPlotLinkOpen: isOpen, onPlotLinkClose: onClose } =
    plotLinkModalState;

  const [dataStreamInternal] = useObservable(data$ || of([]));
  const dataStream = data ? data : dataStreamInternal;

  const { loggedInUser } = useGetLoggedInUser();

  const { entities, activeIds, ids } = filterStore.getValue();

  const filterCleaner = (
    entities: any,
    activeIds: string[],
    ids: string[],
    cardConfig: any
  ) => {
    let finalIds: string[] = [];

    const isOvertime =
      get(entities, 'snapshot_or_over_time.value.id', '') === 'overtime';

    if (isOvertime) {
      finalIds = [...activeIds, ...ids];
    } else {
      finalIds = [...activeIds];
    }

    const filteredEntities = Object.keys(entities).reduce(
      (result: any, key: any) => {
        if (
          finalIds.includes(key) &&
          !(
            key.startsWith('sub_') &&
            key !== `sub_${cardConfig.endpointSegment}`
          )
        ) {
          result[key] = entities[key];
        }
        return result;
      },
      {}
    );
    return filteredEntities;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataObject: any = {
    plotData: dataStream,
    cardConfig: cardConfig,
    typeAndProps: typeAndProps,
    userID: loggedInUser.id,
    colors: plotColorLookup.value,
    filters: filterCleaner(entities, activeIds, ids, cardConfig),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [checkData, setCheckData] = useState('');

  useEffect(() => {
    setCheckData('');
  }, [
    plotLinkModalState.isPlotLinkOpen,
    dataObject.cardConfig.endpointSegment,
    dataObject.cardConfig.header,
    dataObject.cardConfig.view,
    dataObject.cardConfig.viewType,
    dataObject.plotData?.length,
    dataObject.plotData.links?.length,
    dataObject.plotData.nodes?.length,
  ]);

  const createPlotLink = async () => {
    setIsLoading(true);
    delete dataObject.cardConfig.footer;
    const finalObject = {
      meta: {
        userID: dataObject.userID,
        ...dataObject.cardConfig,
      },
      config: {
        ...dataObject.typeAndProps.chartProps,
        colorLookup: dataObject.colors,
      },
      type: dataObject.typeAndProps.chartType,
      data: Array.isArray(dataObject.plotData)
        ? dataObject.plotData
        : stringify(dataObject.plotData),
      filters: dataObject.filters,
    };
    try {
      const response = await fetch(
        'https://us-central1-dashboard-ui-revelio.cloudfunctions.net/plotShare',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(finalObject),
        }
      );
      const jsonData = await response.json();
      setCheckData(
        jsonData.id
          ? `https://charts.reveliolabs.com/dashboard/${jsonData.id}`
          : ''
      );
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  return (
    <ActionModal header="Shareable plot link" isOpen={isOpen} onClose={onClose}>
      <ModalBody>
        <Flex direction="column" rowGap="1rem">
          <Stack spacing={1}>
            <Text fontSize="sm">Anyone with this link can view this plot</Text>
            <InputGroup size="md">
              <Input
                pr={checkData !== '' ? '6rem' : '7.5rem'}
                value={checkData === '' ? '' : checkData}
                placeholder="No shared link"
                disabled={checkData === ''}
              />
              <InputRightElement width={checkData !== '' ? '6rem' : '7.5rem'}>
                {checkData !== '' ? (
                  <CopyToClipboard text={checkData}>
                    <Button
                      h="1.75rem"
                      size="sm"
                      colorScheme="green"
                      variant="solid"
                    >
                      Copy Link
                    </Button>
                  </CopyToClipboard>
                ) : (
                  <Button
                    h="1.75rem"
                    size="sm"
                    colorScheme="green"
                    variant="solid"
                    isLoading={isLoading}
                    onClick={createPlotLink}
                  >
                    Generate Link
                  </Button>
                )}
              </InputRightElement>
            </InputGroup>
            {checkData !== '' && (
              <Box pt={1}>
                <Link
                  fontSize="sm"
                  textDecoration="underline"
                  _hover={{ textDecoration: 'none' }}
                  cursor="pointer"
                  href={checkData}
                  isExternal
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: 'inline-flex' }}
                >
                  Preview link in new tab
                </Link>
              </Box>
            )}
          </Stack>
        </Flex>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ActionModal>
  );
}

export default PlotLinkModal;
