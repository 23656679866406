import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react';
import { ChakraStylesConfig, GroupBase } from 'chakra-react-select';
import {
  Controller,
  FieldError,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { DownRightArrowIcon } from '@revelio/layout';
import {
  BinaryOperation,
  CustomColumnConditionVariable,
} from '@revelio/data-access';

import {
  MultiGranularitySelectionListVariables,
  NumericOperation,
  NumericVariable,
  SelectionListOperation,
  StringOperation,
} from '../dataset-conditions/conditions.model';
import {
  BINARY_SELECT_OPTIONS,
  EMPTY_CASE_CONDITION,
  getVariableOperationOptions,
  EMPTY_VALUE_MESSAGE,
  getConditionVariableSelectOptions,
} from '../dataset-conditions/dataset-conditions-form-helpers';
import {
  ConditionSelect,
  getDefaultChakraStyles,
} from '../dataset-conditions/condition-select';
import { DatasetFilterForm } from './filter.model';
import { ConditionValue } from '../dataset-conditions/condition-value';
import { getPipelineType } from '../../deliverables.repository';
import { Deliverable } from '../../deliverables.model';
import { isNumber } from 'lodash';

export const FilterConditions = ({
  entityId,
  formProps,
}: {
  entityId: Deliverable['id'];
  formProps: UseFormReturn<DatasetFilterForm>;
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    resetField,
    clearErrors,
    setError,
  } = formProps;
  const { fields, remove, append } = useFieldArray({
    control,
    name: `conditions`,
  });
  const filterConditions = useWatch({
    control: control,
    name: `conditions`,
  });

  const filterBinary = useWatch({
    control: control,
    name: `binary`,
  });
  const CONDITION_SELECT_WIDTH = '135px';
  return (
    <Box
      background="gray.100"
      borderColor="gray.200"
      borderWidth="1px"
      borderRadius="7px"
      flexGrow="1"
      pt="10px"
      px="12px"
    >
      {fields.map((condition, conditionIndex) => {
        const conditionErrors = errors.conditions?.[conditionIndex];
        const isAddedCondition = conditionIndex > 0;
        const isFirstConditionWithAndOrSelect = conditionIndex === 1;
        return (
          <Box key={condition.id}>
            {isAddedCondition && (
              <Flex marginY="20px" alignItems="center">
                <Divider orientation="horizontal" borderBottomWidth="2px" />
                {isFirstConditionWithAndOrSelect ? (
                  <Controller
                    name={`binary`}
                    control={control}
                    defaultValue={filterBinary}
                    render={({ field }) => (
                      <ConditionSelect<DatasetFilterForm, BinaryOperation>
                        field={field}
                        options={BINARY_SELECT_OPTIONS}
                        chakraStyles={
                          {
                            ...getDefaultChakraStyles({ width: '63px' }),
                            control: (provided) => ({
                              ...provided,
                              background: 'white',
                              width: '63px',
                              mx: '10px',
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              pl: '5px',
                              pr: '0px',
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              ml: '0px',
                            }),
                          } as ChakraStylesConfig<
                            { label: string; value: BinaryOperation },
                            false,
                            GroupBase<{ label: string; value: BinaryOperation }>
                          >
                        }
                      />
                    )}
                  />
                ) : (
                  <Text fontSize="xs" px="10px">
                    {filterBinary}
                  </Text>
                )}
                <Divider orientation="horizontal" borderBottomWidth="2px" />
              </Flex>
            )}
            <Flex justify="space-between" alignItems="center">
              <FormControl isInvalid={!!conditionErrors?.variable}>
                <Flex alignItems="center">
                  <Controller
                    name={`conditions.${conditionIndex}.variable`}
                    control={control}
                    rules={{
                      required: 'variable is required.',
                    }}
                    render={({ field }) => (
                      <ConditionSelect<
                        DatasetFilterForm,
                        | CustomColumnConditionVariable
                        | MultiGranularitySelectionListVariables
                      >
                        field={field}
                        options={getConditionVariableSelectOptions({
                          pipelineType: getPipelineType({ entityId }),
                        })}
                        onChange={(newVariable) => {
                          resetField(`conditions.${conditionIndex}.operation`, {
                            defaultValue: null,
                          });

                          resetField(`conditions.${conditionIndex}.value`, {
                            defaultValue: null,
                          });
                        }}
                        chakraStyles={
                          getDefaultChakraStyles({
                            width: '180px',
                          }) as ChakraStylesConfig<
                            {
                              label: string;
                              value:
                                | CustomColumnConditionVariable
                                | MultiGranularitySelectionListVariables;
                            },
                            false,
                            GroupBase<{
                              label: string;
                              value:
                                | CustomColumnConditionVariable
                                | MultiGranularitySelectionListVariables;
                            }>
                          >
                        }
                      />
                    )}
                  />

                  <FormErrorMessage mt="0" ml="5px">
                    {conditionErrors?.variable?.message}
                  </FormErrorMessage>
                </Flex>
              </FormControl>

              {isAddedCondition && (
                <IconButton
                  bg="none"
                  aria-label="delete condition"
                  icon={<CloseIcon />}
                  size="sm"
                  variant="white"
                  onClick={() => {
                    remove(conditionIndex);
                    if (filterConditions.length === 2) {
                      setValue(`binary`, undefined);
                    }
                  }}
                />
              )}
            </Flex>

            <Flex mt="10px">
              <DownRightArrowIcon mr="8px" mt="7px" ml="13px" />
              <FormControl
                w={CONDITION_SELECT_WIDTH}
                isInvalid={!!conditionErrors?.operation}
              >
                <Controller
                  name={`conditions.${conditionIndex}.operation`}
                  control={control}
                  rules={{
                    required: 'Operation is required.',
                  }}
                  render={({ field }) => {
                    return (
                      <ConditionSelect<
                        DatasetFilterForm,
                        | NumericOperation
                        | SelectionListOperation
                        | StringOperation
                      >
                        field={field}
                        isDisabled={!filterConditions[conditionIndex]?.variable}
                        options={getVariableOperationOptions(
                          filterConditions[conditionIndex]?.variable
                        )}
                        onChange={(newOperation) => {
                          if (
                            filterConditions[conditionIndex].operation ===
                              NumericOperation.BETWEEN &&
                            newOperation !== NumericOperation.BETWEEN
                          ) {
                            resetField(`conditions.${conditionIndex}.value`, {
                              defaultValue: null,
                            });
                          }
                        }}
                        chakraStyles={
                          getDefaultChakraStyles({
                            width: CONDITION_SELECT_WIDTH,
                          }) as ChakraStylesConfig<
                            {
                              label: string;
                              value:
                                | NumericOperation
                                | SelectionListOperation
                                | StringOperation;
                            },
                            false,
                            GroupBase<{
                              label: string;
                              value:
                                | NumericOperation
                                | SelectionListOperation
                                | StringOperation;
                            }>
                          >
                        }
                      />
                    );
                  }}
                />

                <FormErrorMessage>
                  {conditionErrors?.operation?.message}
                </FormErrorMessage>
              </FormControl>

              <ConditionValue
                conditionVariable={filterConditions[conditionIndex]?.variable}
                valueError={conditionErrors?.value}
                numericValueProps={{
                  minValue: isNumber(
                    filterConditions[conditionIndex]?.value?.[0]
                  )
                    ? (filterConditions[conditionIndex]?.value?.[0] as number)
                    : undefined,
                  maxValuePath: `conditions.${conditionIndex}.value.1`,
                  maxValue: isNumber(
                    filterConditions[conditionIndex]?.value?.[1]
                  )
                    ? (filterConditions[conditionIndex]?.value?.[1] as number)
                    : undefined,
                  selectedConditionVariable: filterConditions[conditionIndex]
                    ?.variable as NumericVariable,
                  ...formProps,
                }}
                stringValueProps={{
                  conditionId: condition.id,
                  selectedValues: (filterConditions[conditionIndex]?.value ||
                    []) as string[],
                  setSelections: (values) => {
                    setValue(
                      `conditions.${conditionIndex}.value`,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      values as any
                    );
                  },
                  clearError: () => {
                    clearErrors(`conditions.${conditionIndex}.value`);
                  },
                  onNoValues: () => {
                    setError(`conditions.${conditionIndex}.value`, {
                      message: EMPTY_VALUE_MESSAGE,
                    });
                  },
                  hasError: !!(conditionErrors?.value as FieldError)?.message,
                  isDisabled: !filterConditions[conditionIndex]?.operation,
                }}
                selectedConditionOperation={
                  filterConditions[conditionIndex]?.operation
                }
                formProps={formProps}
                firstValuePath={`conditions.${conditionIndex}.value.0`}
              />
            </Flex>
          </Box>
        );
      })}

      <Box width="100%" textAlign="right" pb="6px">
        <Button
          colorScheme="lightBlue"
          fontSize="xs"
          fontWeight="600"
          size="sm"
          variant="link"
          onClick={() => {
            append(EMPTY_CASE_CONDITION);
            const isSecondConditionBeingAdded = filterConditions.length === 1;
            if (isSecondConditionBeingAdded) {
              setValue(`binary`, BinaryOperation.And);
            }
          }}
        >
          {filterBinary ? `+ ${filterBinary}` : 'AND / OR'}
        </Button>
      </Box>
    </Box>
  );
};
