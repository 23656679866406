import { Box, Flex, IconButton } from '@chakra-ui/react';
import { selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';

import { PipelineColumnType, PipelineType } from '@revelio/data-access';
import { write } from '@revelio/core';

import {
  deliverablesStore,
  updateDraftDeliverable,
} from '../../deliverables.repository';
import { SavePipelineConfiguration } from '../saved-pipeline-configurations/save-pipeline-configuration';
import { useState } from 'react';
import { usePipelineConfigurationsByUser } from './saved-pipeline-configuration.gql';
import {
  PipelineConfigurationSelectOption,
  getPipelineConfigurationValue,
} from './saved-pipeline-configuration-select-helpers';
import { Deliverable } from '../../deliverables.model';
import { PipelineConfigurationSelect } from './pipeline-configuration-select';

export const PipelineConfigurationToolbar = ({
  entityId,
  isRevelioAdmin,
  pipelineType,
  isDatasetSelected,
  onEditDataset,
}: {
  entityId: Deliverable['id'];
  isRevelioAdmin: boolean;
  pipelineType: PipelineType;
  isDatasetSelected: boolean;
  onEditDataset: () => void;
}) => {
  const [deliverable] = useObservable(
    deliverablesStore.pipe(selectEntity(entityId)),
    { deps: [entityId] }
  );
  const selectedPipelineColumns = deliverable?.pipeline
    .columns as PipelineColumnType[];
  const [{ data: pipelineConfigurations }] = usePipelineConfigurationsByUser();
  const [selectedPipelineConfiguration, setSelectedPipelineConfiguration] =
    useState<PipelineConfigurationSelectOption | null>();

  const pipelineConfigurationSelectValue = getPipelineConfigurationValue({
    entityId,
    selectedPipelineColumns,
    savedPipelineConfigurationSelection: selectedPipelineConfiguration,
  });

  const isExcluded = deliverable?.isExcluded;

  return (
    <Flex onClick={(e) => e.stopPropagation()}>
      <Box opacity={isExcluded ? 0.4 : undefined}>
        {pipelineConfigurations?.pipelineConfiguration ? (
          // this conditional is important because useSelectionList in the Select component needs the pipelineconfigs to be populated
          // otherwise fails when it intitially passes an empty array
          <PipelineConfigurationSelect
            entityId={entityId}
            pipelineType={pipelineType}
            pipelineConfigurations={pipelineConfigurations}
            selectedPipelineConfiguration={selectedPipelineConfiguration}
            setSelectedPipelineConfiguration={setSelectedPipelineConfiguration}
          />
        ) : null}
      </Box>

      <SavePipelineConfiguration
        entityId={entityId}
        isRevelioAdmin={isRevelioAdmin}
        pipelineConfigurationValue={pipelineConfigurationSelectValue}
        setSelectedPipelineConfiguration={setSelectedPipelineConfiguration}
      />

      <IconButton
        as="div"
        borderLeft="0"
        borderColor="gray.200"
        borderLeftRadius="0"
        borderRadius={isRevelioAdmin ? '0' : undefined}
        variant="outline"
        aria-label={`Edit ${pipelineType} Columns`}
        icon={<EditIcon color={isDatasetSelected ? 'blue.500' : 'gray.300'} />}
        onClick={onEditDataset}
      />

      <IconButton
        as="div"
        borderLeft="0"
        borderColor="gray.200"
        borderLeftRadius="0"
        variant="outline"
        aria-label={`Edit ${pipelineType} Columns`}
        icon={
          isExcluded ? (
            <AddIcon color="green.500" />
          ) : (
            <DeleteIcon color="red.500" />
          )
        }
        onClick={() => {
          updateDraftDeliverable(
            entityId,
            write<Deliverable>((state) => {
              state.isExcluded = !isExcluded;
            })
          );
        }}
      />
    </Flex>
  );
};
