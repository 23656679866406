export const ricsK50ToRicsK10Lookup: {
  [ricsK50: string]: string;
} = {
  '13': '2',
  '14': '8',
  '31': '8',
  '11': '8',
  '29': '8',
  '28': '9',
  '42': '9',
  '43': '9',
  '21': '4',
  '33': '7',
  '18': '6',
  '4': '6',
  '45': '4',
  '22': '2',
  '12': '2',
  '48': '2',
  '19': '3',
  '8': '8',
  '10': '8',
  '27': '8',
  '20': '4',
  '25': '5',
  '40': '5',
  '7': '5',
  '34': '6',
  '1': '6',
  '9': '6',
  '47': '1',
  '3': '1',
  '23': '1',
  '50': '1',
  '24': '1',
  '26': '1',
  '5': '6',
  '16': '6',
  '30': '6',
  '36': '6',
  '39': '6',
  '44': '6',
  '6': '6',
  '49': '10',
  '17': '6',
  '2': '3',
  '37': '2',
  '35': '2',
  '15': '9',
  '32': '9',
  '46': '9',
  '41': '9',
};
