import { Input, InputGroup, InputRightAddon } from '@chakra-ui/react';
import TreeStyles from '../tree.module.css';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

export interface DropdownSelectProps {
  value?: string;
  isDropdownOpen: boolean;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DropdownSelect = ({
  value,
  isDropdownOpen,
  setIsDropdownOpen,
}: DropdownSelectProps) => {
  return (
    <InputGroup
      width="100%"
      size="sm"
      onClick={() => {
        setIsDropdownOpen((prevState: boolean) => !prevState);
      }}
      cursor="pointer"
    >
      <Input className={TreeStyles.dropdownInput} value={value} readOnly />

      <InputRightAddon
        children={
          isDropdownOpen ? (
            <ChevronUpIcon boxSize={4} color="currentColor" />
          ) : (
            <ChevronDownIcon boxSize={4} color="currentColor" />
          )
        }
      />
    </InputGroup>
  );
};
