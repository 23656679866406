import { formatPercentageToTwoSigFigs } from '@revelio/d3';
import {
  CompetitorBarChartsData,
  SingleOption,
  CompanySummaryMetric,
} from '../../../types';

export const getCompetitorBarChartProps = (
  compositionData: CompetitorBarChartsData,
  selectedChart: SingleOption
) => {
  const data = compositionData[selectedChart?.value as CompanySummaryMetric];
  const formats = ['growth_rate', 'hiring_rate', 'attrition_rate'].includes(
    selectedChart?.value || ''
  )
    ? { base: '.1%', tooltip: '.1%', func: formatPercentageToTwoSigFigs }
    : { base: '.2s', tooltip: ',.0f', preformatter: 'd' };

  return {
    chartPosition: 'right',
    chartSize: 'large',
    colorIndex: 0,
    customFormatter: formats.func,
    customMargins: undefined,
    data,
    format: formats.base,
    formatCustomString: '',
    isRenderingOrLoading: undefined,
    metaValue: 'shortName',
    name: 'competitors',
    preformatter: formats.preformatter,
    requestHash: undefined,
    singleColor: undefined,
    textAboveBar: false,
    tooltipCustomValue: undefined,
    tooltipFormat: formats.tooltip,
    tooltipFormatCustomString: '',
    tooltipMetaValue: 'longName',
    ttType: '',
  };
};

export const COMPETITOR_BAR_CHART_NAME = 'company_competitor_bar_chart';
