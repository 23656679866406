import { Button, ButtonGroup, Flex } from '@chakra-ui/react';
import TOSPopover from '../tos-popover/tos-popover';

export interface TermsOfServiceModalControlPanelProps {
  onAccept?: () => void;
}

export const TermsOfServiceModalControlPanel = ({
  onAccept,
}: TermsOfServiceModalControlPanelProps) => {
  return (
    <Flex w="100%" mt={4} py={2} pr={4} borderTop="1px solid #CBD4DE">
      <ButtonGroup justifyContent="flex-end" spacing={4} w="100%">
        <TOSPopover
          trigger={
            <Button
              data-testid="tos-decline-btn"
              variant="link"
              fontSize="12px"
              colorScheme="gray"
            >
              Decline
            </Button>
          }
        />

        <Button
          borderRadius="4px"
          fontSize="12px"
          fontWeight="600"
          colorScheme="green"
          variant="solid"
          size="sm"
          onClick={() => {
            onAccept?.();
          }}
        >
          Accept All
        </Button>
      </ButtonGroup>
    </Flex>
  );
};
