import { useContext } from 'react';
import {
  MonthlyLineChart as MonthlyLineChartComponent,
  MonthlyLineChartProps,
} from '@revelio/d3';
import { ChartsRenderedContext } from './charts-rendered-context';

export const MonthlyLineChart = (
  props: Omit<MonthlyLineChartProps, 'setChartHasRendered'>
) => {
  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;
  return (
    <MonthlyLineChartComponent
      {...props}
      setChartHasRendered={setChartHasRendered}
    />
  );
};
