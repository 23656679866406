import { useAuthStore } from '@revelio/auth';
import { FilterMenuItemOrConfig, SelectionCategories } from './filters.model';
import { useMemo } from 'react';

const GENDER_AND_ETHNICITY_RESTRICTED_CLIENT_GROUPS = ['Salesforce'];

export const useRestrictGenderAndEthnicityFilters = () => {
  const { user } = useAuthStore(); // whether to restrict gender and ethnicity filters based on user's permission that hasn't been built yet

  return useMemo(() => {
    return GENDER_AND_ETHNICITY_RESTRICTED_CLIENT_GROUPS.includes(
      user?.client_name || ''
    );
  }, [user]);
};

export const useAllowedFilterMenuItems = (
  filters: FilterMenuItemOrConfig[] = []
) => {
  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();

  return useMemo(() => {
    const removeFilters: string[] = restrictGenderAndEthnicity
      ? [SelectionCategories.GENDER, SelectionCategories.ETHNICITY]
      : [];

    return filters.filter((filter) => {
      // Filter out SelectionCategories strings
      if (typeof filter === 'string' && removeFilters.includes(filter)) {
        return false;
      }
      // Filter out TDMenuItemConfig Items
      else if (
        typeof filter === 'object' &&
        'selections' in filter &&
        Array.isArray(filter.selections)
      ) {
        const selectionCategories = filter.selections
          .flatMap((s) => s.selectionCategory)
          .filter((s) => !!s);
        if (selectionCategories.length) {
          return !selectionCategories.find((c) => removeFilters.includes(c));
        }
      }
      // Handle nestedTDMenuItemConfig Items, if any are restricted the entire filter should be removed
      if (typeof filter === 'object' && 'filters' in filter && filter.filters) {
        return !filter.filters.find((f) => removeFilters.includes(f));
      }
      return true;
    });
  }, [filters, restrictGenderAndEthnicity]);
};
