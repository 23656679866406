import { Views } from '@revelio/core';
import { get } from 'lodash';
import { Get } from 'type-fest';
import {
  FilterName,
  FilterParameterKeys,
  LocalSelectionCategories,
  SelectionCategories,
  SerializedFiltersForQuery,
} from './filters.model';
import {
  GqlPlotNameMetaLookups,
  IEntitiesTransformArgs,
  GqlPlotName,
  DataPath,
} from './gql-models/gql.models';
import { convertCompositionResponseToPlotData } from './gql-models/composition.models';
import {
  Sentiment1d_entitiesTransform,
  Sentiment2d_entitiesTransform,
  SentimentReviews_entitiesTransform,
} from './gql-models/sentiment.models';
import { convertPostingResponseToPlotData } from './gql-models/postings.models';
import { convertTransitionResponseToPlotData } from './gql-models/transitions.models';
import { TalentDiscovery_entitiesTransform } from './gql-models/talent-discovery.models';

export const GqlFilterKeyMapper: {
  [key in FilterName]?: FilterParameterKeys | SelectionCategories;
} = {
  [SelectionCategories.START_TIME]: FilterParameterKeys.START_DATE,
  [SelectionCategories.END_TIME]: FilterParameterKeys.END_DATE,
  [SelectionCategories.COMPANY_NEW_DASHBOARD]: SelectionCategories.COMPANY,
  [SelectionCategories.METRO_AREA]: FilterParameterKeys.MSA,
  [SelectionCategories.PRIMARY_FILTER]: FilterParameterKeys.DIM1,
  [LocalSelectionCategories.N_ITEMS_SANKEY]: FilterParameterKeys.N_ITEMS_SANKEY,
};

//** Entity Transformers **//
//** ///////////////////// **//
const transformFnLookup = {
  [DataPath.COMPOSITION]: convertCompositionResponseToPlotData,
  [DataPath.SENTIMENT_1D]: Sentiment1d_entitiesTransform,
  [DataPath.SENTIMENT_2D]: Sentiment2d_entitiesTransform,
  [DataPath.SENTIMENT_REVIEWS]: SentimentReviews_entitiesTransform,
  [DataPath.POSTING]: convertPostingResponseToPlotData,
  [DataPath.TRANSITION_2D]: convertTransitionResponseToPlotData,
  [DataPath.TALENT_DISCOVERY]: TalentDiscovery_entitiesTransform,
};

export function entitiesTransform(
  inputs: IEntitiesTransformArgs,
  dataPath: DataPath,
  chartVarient?: string
) {
  const lookupKey = chartVarient ? `${dataPath}-${chartVarient}` : dataPath;
  const transformFn = get(transformFnLookup, lookupKey, (ent: any) => ent);
  return transformFn(inputs);
}

//** Response Transformers **//
//** ///////////////////// **//
function genericChartTransformer(
  dataResp: any,
  { plotName, requestHash, filters, view }: Get<IGqlDataTransformer, 'meta'>,
  chartVarient?: string
) {
  const dataPath = get(GqlPlotNameMetaLookups, `${view}.${plotName}.dataPath`);
  const entities = get(dataResp, `data.${dataPath}`, []);
  const tFormedResults = entitiesTransform(
    {
      entities,
      filters,
      plotName,
    },
    dataPath,
    chartVarient
  );

  tFormedResults['requestHash'] = requestHash;
  return tFormedResults;
}

//** Transformer Entry Point **//
//** /////////////////////// **//
export interface IGqlDataTransformer {
  dataResp: any;
  meta: {
    plotName: GqlPlotName;
    requestHash: string;
    filters: SerializedFiltersForQuery;
    view: Views;
  };
}

export function gqlDataTransformer({ dataResp, meta }: IGqlDataTransformer) {
  if (dataResp.error) {
    //error handle
    const { requestHash } = meta;
    const errorResp = { error: dataResp.error, requestHash };

    return errorResp;
  }
  return genericChartTransformer(dataResp, meta);
}
