import React from 'react';

const anyWordOverLength = (str: string, length: number) => {
  return str.split(/\s+/).some((word) => word.length > length);
};

const groupWordsByLength = (words: string[], maxLength: number) => {
  const grouped: string[] = [];
  let currentGroup = '';

  words.forEach((word, index) => {
    const potentialGroup = currentGroup ? `${currentGroup} ${word}` : word;

    if (potentialGroup.length <= maxLength || !currentGroup) {
      currentGroup = potentialGroup;
    } else {
      grouped.push(currentGroup);
      currentGroup = word;
    }

    if (index === words.length - 1) {
      grouped.push(currentGroup);
    }
  });

  return grouped;
};

const getSpanBasedOnString = (input: string, textStyleClass: string) => {
  const trimmedInput = input.trim();
  const randomClass = textStyleClass || 'blue';
  const charLimit = 10;

  if (trimmedInput.includes(',')) {
    const parts = trimmedInput.split(',', 2);
    const beforeCommaWords = parts[0].trim().split(/\s+/);
    const afterComma = parts[1].trim();

    const wordGroups = groupWordsByLength(beforeCommaWords, charLimit);

    return (
      <>
        {wordGroups.map((group, index) => (
          <div
            key={index}
            className={`textBase ${
              anyWordOverLength(group, charLimit) ? 'word-over' : 'word-under'
            } ${randomClass}`}
          >
            {group + (index === wordGroups.length - 1 ? ',' : '')}
          </div>
        ))}
        <div className={`textBase after-comma ${randomClass}`}>
          {afterComma}
        </div>
      </>
    );
  } else {
    if (trimmedInput.length < charLimit) {
      const wordGroups = groupWordsByLength(
        trimmedInput.split(/\s+/),
        charLimit
      );

      return wordGroups.map((group, index) => (
        <div
          key={index}
          className={`textBase ${
            anyWordOverLength(group, charLimit) ? 'word-over' : 'word-under'
          } ${randomClass}`}
        >
          {group}
        </div>
      ));
    } else {
      const wordGroups = groupWordsByLength(
        trimmedInput.split(/\s+/),
        charLimit
      );

      return wordGroups.map((group, index) => (
        <div
          key={index}
          className={`textBase ${
            anyWordOverLength(group, charLimit) ? 'word-over' : 'word-under'
          } ${randomClass}`}
        >
          {group}
        </div>
      ));
    }
  }
};

const SlotText = ({
  textStyleClass,
  text,
}: {
  textStyleClass: string;
  text: string;
}) => {
  return getSpanBasedOnString(text || '---', textStyleClass);
};

export default SlotText;
