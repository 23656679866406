export const factsetIndustryToRicsK50IdLookup: {
  [factsetIndustry: string]: string;
} = {
  '1': '36',
  '2': '30',
  '3': '5',
  '4': '30',
  '5': '17',
  '6': '36',
  '7': '40',
  '8': '24',
  '9': '18',
  '10': '42',
  '11': '30',
  '12': '31',
  '13': '25',
  '14': '22',
  '15': '47',
  '16': '34',
  '17': '17',
  '18': '21',
  '19': '49',
  '20': '38',
  '21': '43',
};
