export const wagePercentiles = [
  {
    percentile: 0,
    z_score: -17.470221038,
  },
  {
    percentile: 1,
    z_score: -2.102470718,
  },
  {
    percentile: 2,
    z_score: -1.657692267,
  },
  {
    percentile: 3,
    z_score: -1.447787247,
  },
  {
    percentile: 4,
    z_score: -1.305352576,
  },
  {
    percentile: 5,
    z_score: -1.191553838,
  },
  {
    percentile: 6,
    z_score: -1.096984835,
  },
  {
    percentile: 7,
    z_score: -1.019325506,
  },
  {
    percentile: 8,
    z_score: -0.9549734046,
  },
  {
    percentile: 9,
    z_score: -0.9008792568,
  },
  {
    percentile: 10,
    z_score: -0.8541063735,
  },
  {
    percentile: 11,
    z_score: -0.8136309619,
  },
  {
    percentile: 12,
    z_score: -0.7767667659,
  },
  {
    percentile: 13,
    z_score: -0.743024396,
  },
  {
    percentile: 14,
    z_score: -0.7121480798,
  },
  {
    percentile: 15,
    z_score: -0.6836126666,
  },
  {
    percentile: 16,
    z_score: -0.6573224817,
  },
  {
    percentile: 17,
    z_score: -0.6325188238,
  },
  {
    percentile: 18,
    z_score: -0.609461748,
  },
  {
    percentile: 19,
    z_score: -0.5877841349,
  },
  {
    percentile: 20,
    z_score: -0.5671609741,
  },
  {
    percentile: 21,
    z_score: -0.5474108563,
  },
  {
    percentile: 22,
    z_score: -0.5285988025,
  },
  {
    percentile: 23,
    z_score: -0.5109194584,
  },
  {
    percentile: 24,
    z_score: -0.4937404595,
  },
  {
    percentile: 25,
    z_score: -0.4770965084,
  },
  {
    percentile: 26,
    z_score: -0.4608524558,
  },
  {
    percentile: 27,
    z_score: -0.4452256089,
  },
  {
    percentile: 28,
    z_score: -0.4298842478,
  },
  {
    percentile: 29,
    z_score: -0.4151375075,
  },
  {
    percentile: 30,
    z_score: -0.4007590141,
  },
  {
    percentile: 31,
    z_score: -0.386512615,
  },
  {
    percentile: 32,
    z_score: -0.3726146396,
  },
  {
    percentile: 33,
    z_score: -0.3587867238,
  },
  {
    percentile: 34,
    z_score: -0.3451189522,
  },
  {
    percentile: 35,
    z_score: -0.3315259985,
  },
  {
    percentile: 36,
    z_score: -0.3181077649,
  },
  {
    percentile: 37,
    z_score: -0.3046679777,
  },
  {
    percentile: 38,
    z_score: -0.2912764961,
  },
  {
    percentile: 39,
    z_score: -0.2778822421,
  },
  {
    percentile: 40,
    z_score: -0.2644418415,
  },
  {
    percentile: 41,
    z_score: -0.250981294,
  },
  {
    percentile: 42,
    z_score: -0.2374801951,
  },
  {
    percentile: 43,
    z_score: -0.2239465852,
  },
  {
    percentile: 44,
    z_score: -0.2103734856,
  },
  {
    percentile: 45,
    z_score: -0.1964868972,
  },
  {
    percentile: 46,
    z_score: -0.1826956767,
  },
  {
    percentile: 47,
    z_score: -0.1686018434,
  },
  {
    percentile: 48,
    z_score: -0.1544100244,
  },
  {
    percentile: 49,
    z_score: -0.1399702806,
  },
  {
    percentile: 50,
    z_score: -0.125383862,
  },
  {
    percentile: 51,
    z_score: -0.1107784696,
  },
  {
    percentile: 52,
    z_score: -0.09573033477,
  },
  {
    percentile: 53,
    z_score: -0.08068818072,
  },
  {
    percentile: 54,
    z_score: -0.06519785537,
  },
  {
    percentile: 55,
    z_score: -0.04946514576,
  },
  {
    percentile: 56,
    z_score: -0.03360392526,
  },
  {
    percentile: 57,
    z_score: -0.01754790902,
  },
  {
    percentile: 58,
    z_score: -0.001080124261,
  },
  {
    percentile: 59,
    z_score: 0.01536729767,
  },
  {
    percentile: 60,
    z_score: 0.03241590033,
  },
  {
    percentile: 61,
    z_score: 0.04980434166,
  },
  {
    percentile: 62,
    z_score: 0.0676414752,
  },
  {
    percentile: 63,
    z_score: 0.08583656503,
  },
  {
    percentile: 64,
    z_score: 0.1046440488,
  },
  {
    percentile: 65,
    z_score: 0.1235281445,
  },
  {
    percentile: 66,
    z_score: 0.1432972132,
  },
  {
    percentile: 67,
    z_score: 0.1636188965,
  },
  {
    percentile: 68,
    z_score: 0.1845112268,
  },
  {
    percentile: 69,
    z_score: 0.2059391821,
  },
  {
    percentile: 70,
    z_score: 0.2280117478,
  },
  {
    percentile: 71,
    z_score: 0.2510331255,
  },
  {
    percentile: 72,
    z_score: 0.2750691841,
  },
  {
    percentile: 73,
    z_score: 0.2998591363,
  },
  {
    percentile: 74,
    z_score: 0.3255182901,
  },
  {
    percentile: 75,
    z_score: 0.3521676473,
  },
  {
    percentile: 76,
    z_score: 0.3800071762,
  },
  {
    percentile: 77,
    z_score: 0.4087041204,
  },
  {
    percentile: 78,
    z_score: 0.4388748282,
  },
  {
    percentile: 79,
    z_score: 0.470206547,
  },
  {
    percentile: 80,
    z_score: 0.5034125499,
  },
  {
    percentile: 81,
    z_score: 0.53811227,
  },
  {
    percentile: 82,
    z_score: 0.5743561584,
  },
  {
    percentile: 83,
    z_score: 0.613182777,
  },
  {
    percentile: 84,
    z_score: 0.6542618992,
  },
  {
    percentile: 85,
    z_score: 0.6979338376,
  },
  {
    percentile: 86,
    z_score: 0.7450781991,
  },
  {
    percentile: 87,
    z_score: 0.7958151398,
  },
  {
    percentile: 88,
    z_score: 0.8514983145,
  },
  {
    percentile: 89,
    z_score: 0.9123076579,
  },
  {
    percentile: 90,
    z_score: 0.9808283509,
  },
  {
    percentile: 91,
    z_score: 1.057675475,
  },
  {
    percentile: 92,
    z_score: 1.144399062,
  },
  {
    percentile: 93,
    z_score: 1.24448353,
  },
  {
    percentile: 94,
    z_score: 1.366546299,
  },
  {
    percentile: 95,
    z_score: 1.518344334,
  },
  {
    percentile: 96,
    z_score: 1.717427342,
  },
  {
    percentile: 97,
    z_score: 1.992137436,
  },
  {
    percentile: 98,
    z_score: 2.406876297,
  },
  {
    percentile: 99,
    z_score: 3.239616159,
  },
  {
    percentile: 100,
    z_score: 61.627364851,
  },
];
