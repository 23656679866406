import { useGetLoggedInUser } from '@revelio/auth';
import {
  GetPipelineConfigsQuery,
  GetPipelineConfigsQueryVariables,
  graphql,
} from '@revelio/data-access';
import { UseQueryExecute, UseQueryState, useQuery } from 'urql';

export const usePipelineConfigurationsByUser = (): [
  UseQueryState<GetPipelineConfigsQuery, GetPipelineConfigsQueryVariables>,
  UseQueryExecute
] => {
  const { loggedInUser } = useGetLoggedInUser();
  const [{ data: pipelineConfigurations, ...rest }, ...otherProps] = useQuery({
    query: GetPipelineConfigurationsQuery,
  });

  return [
    {
      ...rest,
      data: {
        pipelineConfiguration:
          pipelineConfigurations?.pipelineConfiguration?.filter(
            (config) => config?.user_id === loggedInUser.id
          ),
      },
    },
    ...otherProps,
  ];
};

export const GetPipelineConfigurationsQuery = graphql(`
  query getPipelineConfigs {
    pipelineConfiguration {
      id
      name
      dashboard_global
      user_id
      client_id
      model_versions {
        location_version
        timescaling_version
        ethnicity_version
        job_taxonomy_version
        company_mapper_version
        salary_version
        seniority_version
        weight_table
        remove_bad_users
      }
      pipeline {
        pipeline_type
        columns
        custom_columns {
          name
          step {
            case {
              conditions {
                variable
                value
                operation
              }
              binary
            }
            then
          }
        }
        filters {
          name
          conditions {
            variable
            value
            operation
          }
          binary
        }
      }
    }
  }
`);

export const SavePipelineConfigurationMutation = graphql(`
  mutation SavePipelineConfiguration($configuration: PipelineConfigInput!) {
    savePipelineConfiguration(configuration: $configuration) {
      name
      dashboard_global
      user_id
      client_id
      model_versions {
        location_version
        timescaling_version
        ethnicity_version
        job_taxonomy_version
        company_mapper_version
        salary_version
        seniority_version
        weight_table
        remove_bad_users
      }
      pipeline {
        pipeline_type
        columns
        custom_columns {
          name
          step {
            case {
              conditions {
                variable
                value
                operation
              }
              binary
            }
            then
          }
        }
        filters {
          name
          conditions {
            variable
            value
            operation
          }
          binary
        }
      }
    }
  }
`);

export const DeletePipelineConfiguration = graphql(`
  mutation DeletePipelineConfiguration($id: String!) {
    deletePipelineConfiguration(id: $id)
  }
`);
