import { Client } from 'urql';
import { GET_NAME_RECS } from '../../../engine/gql-models/name-search.models';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';

export interface RecommendationItem {
  user_name: string;
}

export const fetchNameRecommendationResults = (
  vars: TalentDiscoveryV1_5Filter,
  gqlClient: Client
) => {
  const nameRecommendationResults = gqlClient
    .query(GET_NAME_RECS, { filters: vars })
    .toPromise();

  return nameRecommendationResults;
};

export const isValidSearchString = (str: string) => {
  const nameStringCleaned = str?.trim() || '';

  const nameStringSplit = nameStringCleaned.split(' ');

  return (
    nameStringCleaned.length > 0 &&
    nameStringSplit.some((keyword: string) => keyword.length > 2)
  );
};
