import { Box, Flex, VStack } from '@chakra-ui/layout';
import { FormControl, Input, Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Filter,
  OPValues,
  SelectionCategories,
} from '../../engine/filters.model';
import { get, isEmpty, isUndefined } from 'lodash';
import { format } from 'd3-format';
import { useSingleOrMoreFilterState } from '../../engine/filters.engine';
import { TDStateConfig } from '../collection';
import { ToggleSelect } from '../tree/tree/toggle-select/toggle-select';
import { operatorOptions } from '../../engine/filters.constants';

export const SALARY_MAX = 36445000;

interface SimpleFilterRangeProps {
  filterName: SelectionCategories;
  value: TDStateConfig;
  setValue: React.Dispatch<TDStateConfig>;
  defaultValue: number[];
  prefix: string;
  setIsSubmitDisabled?: React.Dispatch<boolean>;
  ref: React.ForwardedRef<unknown>;
  showToggleBar?: boolean;
  filterNameForToggle?: string;
  onToggleBarChange?: (index: number) => void;
  toggleBarData?: Filter[];
}

export const SimpleFilterRange = forwardRef(
  (
    {
      filterName,
      value,
      setValue,
      defaultValue = [0, 100000],
      prefix,
      setIsSubmitDisabled,
      showToggleBar,
      filterNameForToggle = '',
      onToggleBarChange,
      toggleBarData,
    }: SimpleFilterRangeProps,
    ref
  ) => {
    const [error, setError] = useState('');

    useEffect(() => {
      const currentEntity = get(value, filterName);

      const operatorConfig = get(currentEntity, 'operator');

      const operatorValue = get(operatorConfig, 'value');

      const internalValue = get(currentEntity, 'value', []);

      const [minValue, maxValue] = internalValue;

      if (minValue > SALARY_MAX || maxValue > SALARY_MAX) {
        setIsSubmitDisabled?.(true);
        setError(`Salary value cannot exceed ${format('$,')(SALARY_MAX)}.`);
        return;
      }

      if (operatorValue === OPValues.BETWEEN) {
        if (minValue > maxValue) {
          setIsSubmitDisabled?.(true);
          setError('Min value cannot exceed max value.');
          return;
        }

        if (isUndefined(minValue) || isUndefined(maxValue)) {
          setIsSubmitDisabled?.(true);
          return;
        }
      } else {
        if (
          operatorValue === OPValues.LESS ||
          operatorValue === OPValues.GREATER
        ) {
          if (isUndefined(minValue)) {
            setIsSubmitDisabled?.(true);
            return;
          }
        }
      }

      setError('');
      setIsSubmitDisabled?.(false);
    }, [filterName, setIsSubmitDisabled, setValue, value]);

    const [salaryFilter] = useSingleOrMoreFilterState<Filter[]>(filterName);

    const resetValues = () => {
      const existingValues = get(salaryFilter, 'value', {});

      if (!isEmpty(existingValues)) {
        const { operator, value } = existingValues;

        setValue((prev: any) => {
          return {
            ...prev,
            [filterName]: {
              operator,
              value,
            },
          };
        });

        return true;
      }

      return false;
    };

    useImperativeHandle(ref, () => ({
      handleClearSelections: resetValues,
    }));

    useEffect(() => {
      const isStateReset = resetValues();

      if (isStateReset) return;

      setValue((prev: any) => {
        return {
          ...prev,
          [filterName]: {
            operator: operatorOptions?.[0],
            value: defaultValue,
          },
        };
      });

      // eslint-disable-next-line
    }, [filterName, salaryFilter, setValue]);

    return (
      <FormControl>
        <VStack spacing={2} w="334px">
          <Box w="100%">
            <Select
              isMulti={false}
              options={operatorOptions}
              value={value[filterName]?.operator}
              defaultValue={operatorOptions[0]}
              onChange={(e) => {
                setValue((prev: any) => {
                  const existing = prev[filterName];
                  return {
                    ...prev,
                    [filterName]: {
                      ...existing,
                      operator: e,
                    },
                  };
                });
              }}
              size="sm"
            />
          </Box>

          <Flex align="center" justifyContent="space-between" w="100%">
            <NumberFormat
              customInput={Input}
              value={value[filterName]?.value?.[0]}
              thousandSeparator={true}
              allowNegative={false}
              onValueChange={(val: any) => {
                setValue((prev: any) => {
                  const existing = get(prev, filterName, {});
                  return {
                    ...prev,
                    [filterName]: {
                      ...existing,
                      value: [val.floatValue, get(existing, 'value[1]')],
                    },
                  };
                });
              }}
              prefix={prefix}
              type="text"
              size="sm"
              width="100%"
              flex="1"
              decimalScale={2}
              data-testid="range-num-input-start"
            />
            {value[filterName]?.operator?.value === OPValues.BETWEEN && (
              <>
                <Text px="2" fontSize="sm">
                  to
                </Text>
                <NumberFormat
                  customInput={Input}
                  value={value[filterName]?.value?.[1]}
                  thousandSeparator={true}
                  allowNegative={false}
                  onValueChange={(val: any) => {
                    setValue((prev: any) => {
                      const existing = get(prev, filterName, {});
                      return {
                        ...prev,
                        [filterName]: {
                          ...existing,
                          value: [get(existing, 'value[0]'), val.floatValue],
                        },
                      };
                    });
                  }}
                  prefix={prefix}
                  type="text"
                  size="sm"
                  width="100%"
                  flex="1"
                  decimalScale={2}
                  data-testid="range-num-input-end"
                />
              </>
            )}
          </Flex>

          <Flex w="100%">
            <Text color="red" fontSize="sm">
              {error}
            </Text>
          </Flex>
        </VStack>
        {showToggleBar && (
          <ToggleSelect
            filterName={filterNameForToggle}
            onChange={onToggleBarChange}
            data={toggleBarData}
          />
        )}
      </FormControl>
    );
  }
);
