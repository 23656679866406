import { get } from 'lodash';
import { useAsync } from 'react-use';
import QuickLRU from 'quick-lru';
import { API_ROOT } from '@revelio/filtering';

const millisInADay = 60000 * 60 * 24;
const dataCache = new QuickLRU({ maxAge: millisInADay, maxSize: 500 });

export const useFetchTicker = () => {
  const tickerUrl = `${API_ROOT}/tables/layoff`;

  const defaultOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ latest: true }),
    credentials: 'include',
  };

  async function isResponseOk(url: string, response: Response) {
    if (response.status >= 200 && response.status <= 299) {
      const data = await response.json();
      dataCache.set(url, data);
      return data;
    } else if (response.status >= 401 && response.status <= 499) {
      // TODO: do logout
    } else if (response.status >= 500 && response.status <= 599) {
      throw new Error('Oops! An Error Occurred.');
    } else {
      throw new Error(response.statusText);
    }
  }

  async function getData(url: string) {
    if (dataCache.has(url)) {
      return dataCache.get(url);
    } else {
      const resp = await fetch(url, defaultOptions);
      return isResponseOk(url, resp);
    }
  }

  const state = useAsync(async () => {
    return Promise.all([getData(tickerUrl)]);
  }, []);

  const tickerLoading = state.loading;
  const [tickerData] = get(state, 'value', []);

  return { data: tickerData, loading: tickerLoading };
};
