export enum CompensationPlotTypes {
  TOP_COMPANY = 'top_company',
  TOP_GEOGRAPHY = 'top_geography',
  TOP_ROLE = 'top_role',
}

export enum CompensationTypes {
  HOURLY = 'contractor_pay_rate',
  BASE = 'base_salary',
  TOTAL = 'total_compensation',
}

type CompDataPoint = {
  id: string;
  value: number | null;
};

type KDEDataPoint = {
  id: string;
  metadata: {
    bandwidth: null | number;
    num_samples: null | number;
    salary_points: number[];
  };
  values: number[];
};

type CompDataValueItem = {
  id: string;
  metadata: {
    year: number;
  };
  value: (KDEDataPoint | CompDataPoint)[];
};

type CompDataValue = {
  id: string;
  metadata: {
    level: string;
    name: string;
  };
  value: CompDataValueItem[];
};

export type CompData = {
  id: string;
  value: CompDataValue[];
};

type BoxPlotResponseValueItem = {
  id: string;
  metadata: {
    rcid: number;
    percentile: number[];
  };
  value: number[];
};

type BoxPlotResponseValueMeta = {
  level: string;
  name: string;
};

type BoxPlotResponseValue = {
  id: string;
  metadata: BoxPlotResponseValueMeta;
  value: BoxPlotResponseValueItem[];
};

export type BoxPlotResponse = {
  id: CompensationTypes;
  value: BoxPlotResponseValue[];
};
