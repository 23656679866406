/**
 * mutates a dimensions object to provide space for
 * watermark or border.
 *
 * @param {Object} dims - plot domensions object
 * @param {Object} configs
 */

export const adjustDownloadMargins = (dims, configs) => {
  const { title, watermark, watermarkHeight, padding, legendContainerHeight } =
    configs;
  // add space at bottom for watermark
  if (watermark) dims.margin.bottom += watermarkHeight;
  if (title) dims.margin.top += 20;
  if (legendContainerHeight) {
    dims.margin.bottom += legendContainerHeight + 40;
    dims.innerHeight -= dims.margin.bottom;
  }

  // add border if specified
  if (padding) {
    for (const key of Object.keys(dims.margin)) {
      dims.margin[key] += padding;
    }
  }
};
