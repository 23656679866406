import { find, get, isUndefined, set } from 'lodash';
import { CompensationPlotTypes, CompensationTypes } from '../types';

// TODO: There are a bunch of any types in this file. Will need to come back and
// properly type all of these

/* eslint-disable */

type CompensationMetaData = {
  id?: string;
  level?: string;
  longName: string;
  shortName: string;
  years?: string[];
  salaries?: number[];
  percentile?: number[];
  day?: number;
  month?: number;
  year?: number;
};

type CompensationResponseNumberValueItem = {
  id: string;
  metadata: CompensationMetaData;
  value: number;
};

export type CompensationResponseTimeToFillValues = {
  id: string;
  metadata: CompensationMetaData;
  value: CompensationResponseNumberValueItem[];
};

export type CompensationResponseValueItem = {
  id: string;
  metadata: CompensationMetaData;
  value: (number | CompensationResponseValueItem)[];
};

type CompensationResponseValue = {
  id: string;
  value: CompensationResponseValueItem[];
};

export type CompensationResponse = {
  id: CompensationTypes;
  value: CompensationResponseValue[];
};

export const getCompDataById = (
  data: CompensationResponse[],
  compensationType: CompensationTypes,
  id: string,
  idToLabelMap?: { [key: number]: string }
) => {
  const compData = data?.find((d: any) => d.id === compensationType);
  const timeseriesData = find(compData?.value, (item) => item.id === id);

  timeseriesData?.value.forEach((s) => {
    const numericalID = Number(s.metadata.id);
    set(
      s,
      'metadata.longName',
      get(idToLabelMap, numericalID, s.metadata.longName)
    );
  });

  return timeseriesData;
};

export const boxPlotDataTransformer = (
  data: CompensationResponse[],
  type: CompensationTypes,
  graph: CompensationPlotTypes,
  idToLabelMap?: { [key: number]: string },
  company?: boolean
) => {
  if (!type) return data;

  const relevantData = data?.find(
    (item: CompensationResponse) => item.id === type
  );

  const plotData = relevantData?.value?.find(
    (item: CompensationResponseValue) => item.id === graph
  );

  let otherTabPlotData;
  if (type !== CompensationTypes.HOURLY) {
    const otherTabKey =
      type === CompensationTypes.BASE
        ? CompensationTypes.TOTAL
        : CompensationTypes.BASE;

    const otherTabData = data?.find(
      (item: CompensationResponse) => item.id === otherTabKey
    );

    otherTabPlotData = otherTabData?.value?.find(
      (item: CompensationResponseValue) => item.id === graph
    );
  }

  const plotDataValues = get(plotData, 'value', []);

  const transformedData = plotDataValues.map(
    (dataValue: CompensationResponseValueItem) => {
      const {
        metadata: { id: metaId, level, longName },
        value,
      } = dataValue;

      // NOTE: Here we are "reshaping" this data to fit the format currently accepted
      // by the box plot. Not the best solution, but good enough for now. Will change
      // to not rely on having to reshape in the future.
      const _longName = get(idToLabelMap, Number(metaId), longName);
      return {
        id: `${level}__${metaId}`,
        metadata: {
          // TODO: We currently don't have shortNames in the api response, thus
          // here we use longName for both fields. Will come back and change this
          // once shortNames are available
          shortName: _longName,
          longName: _longName,
        },
        value: value.map((val: any, index: number) => {
          const {
            metadata: { longName, percentile, id },
            value: innerValue,
          } = val;

          const _longName = longName.toString();
          return {
            id: index + 1,
            metadata: {
              shortName: company ? get(idToLabelMap, id, _longName) : _longName,
              longName: _longName,
            },
            value: percentile.reduce(
              (
                percentileMap: any,
                currentPercentile: any,
                innerIndex: number
              ) => {
                return {
                  ...percentileMap,
                  [`percentile${currentPercentile}`]: innerValue[innerIndex],
                };
              },
              {}
            ),
          };
        }),
      };
    }
  );

  let otherTabPlotDataValues: number[] | undefined = [];
  if (type !== CompensationTypes.HOURLY) {
    otherTabPlotDataValues = otherTabPlotData?.value?.reduce(
      (acc: number[], cur: any) => {
        const currentVals = cur.value.reduce((acc: number[], cur: any) => {
          return [...acc, ...cur.value];
        }, []);

        return [...acc, ...currentVals];
      },
      []
    );
  }

  return [transformedData, otherTabPlotDataValues];
};
export type BoxPlotData = ReturnType<typeof boxPlotDataTransformer>;

export const timeToFillDataTransformer = (
  data: CompensationResponseTimeToFillValues[]
) => {
  const transformedData = [] as any;

  // Transform weekly ttf data into monthly
  data.forEach((d: CompensationResponseTimeToFillValues) => {
    const rawValues = get(d, 'value');

    if (isUndefined(rawValues)) return;

    let currentMonth = -1;
    let currentYear = -1;

    let cumulativeSum = 0;
    let count = 0;

    const transformedValues = rawValues.reduce(
      (
        acc: CompensationResponseNumberValueItem[],
        cur: CompensationResponseNumberValueItem,
        index: number
      ) => {
        const {
          id,
          metadata: { month, year },
          value,
        } = cur;

        if (
          typeof value !== 'number' ||
          isUndefined(month) ||
          isUndefined(year)
        )
          return acc;

        // first value
        if (currentMonth === -1 || currentYear === -1) {
          currentMonth = month;
          currentYear = year;
        }

        if (currentMonth === month && currentYear === year) {
          cumulativeSum += value;
          count++;

          // check next value (if exists) to see if current is last of month
          const nextValue = rawValues[index + 1];

          const nextMonth = get(nextValue, 'metadata.month');
          const nextYear = get(nextValue, 'metadata.year');

          const isLastOfMonth =
            isUndefined(nextValue) ||
            nextMonth !== currentMonth ||
            nextYear !== currentYear;

          if (!isLastOfMonth) {
            return acc;
          }
        }

        const lastMonthAverage = cumulativeSum / count;

        const lastMonthConfig = {
          id,
          metadata: {
            month: currentMonth,
            year: currentYear,
            longName: `${currentYear}-${currentMonth}`,
            shortName: `${currentYear}-${currentMonth}`,
          },
          value: lastMonthAverage,
        };

        currentMonth = -1;
        currentYear = -1;

        count = 0;
        cumulativeSum = 0;

        return [...acc, lastMonthConfig];
      },
      []
    );

    transformedData.push({ ...d, value: transformedValues });
  });

  return transformedData;
};

export const salaryOverTimeDataTransformer = (
  data: CompensationResponse[] & { loading?: boolean; error?: Error | string },
  type: CompensationTypes,
  idToLabelMap?: { [key: number]: string }
) => {
  if (data?.loading || data.error) return data;

  const timeseriesData = getCompDataById(
    data,
    type,
    'timeseries',
    idToLabelMap
  );

  const timeseriesEntities = timeseriesData?.value;

  let otherTabTimeseriesData;
  if (type !== CompensationTypes.HOURLY) {
    const otherTabKey =
      type === CompensationTypes.BASE
        ? CompensationTypes.TOTAL
        : CompensationTypes.BASE;

    otherTabTimeseriesData = getCompDataById(data, otherTabKey, 'timeseries');
  }

  const transformedData = timeseriesEntities?.map(
    (entity: CompensationResponseValueItem) => {
      const {
        metadata: { id: metaId, longName, years, level },
        value,
      } = entity;
      return {
        id: `${level}__${metaId}`,
        metadata: {
          shortName: longName,
          longName,
        },
        value: years?.map((date: string, i: number) => {
          const [year, month] = date.split('-');

          return {
            metadata: {
              longName: `${year}-${month}`,
              shortName: `${year}-${month}`,
              month: Number(month),
              year: Number(year),
            },
            value: value[i],
          };
        }),
      };
    }
  );

  let otherTabPlotDataValues: number[] | undefined = [];
  if (type !== CompensationTypes.HOURLY) {
    otherTabPlotDataValues = otherTabTimeseriesData?.value?.reduce(
      (acc: number[], cur: any) => {
        const { value = [] } = cur;
        return [...acc, ...value];
      },
      []
    );
  }

  return [transformedData, otherTabPlotDataValues];
};

export type OvertimeResponse = ReturnType<typeof salaryOverTimeDataTransformer>;
