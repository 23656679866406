import { useUserTrialDetails } from '@revelio/auth';
import { ActionMenu, setGlobalLoaderEnableState } from '@revelio/core';
import { D3ChartGeneratorLookup } from '@revelio/d3';
import { ChartConfigForPlot } from '@revelio/filtering';
import {
  FiDownload,
  FiImage,
  FiLink,
  FiMaximize2,
  FiMoreVertical,
  FiTrendingUp,
} from 'react-icons/fi';
import {
  BaseCardProps,
  DiagnosticsModalStateType,
  DownloadModalStateType,
  FullScreenStateType,
  PlotLinkModalStateType,
  PlotModalStateType,
} from '../../types';
import ActionMenuButton from '../action-menu-button/action-menu-button';
import './default-card-action-menu.module.css';

export interface DefaultCardActionMenuProps extends BaseCardProps {
  typeAndProps?: ChartConfigForPlot;
  plotModalState: PlotModalStateType;
  downloadModalState: DownloadModalStateType;
  diagnosticsModalState: DiagnosticsModalStateType;
  plotLinkModalState: PlotLinkModalStateType;
  fullScreenState: FullScreenStateType;
}

export function DefaultCardActionMenu({
  downloadConfig,
  cardConfig,
  plotConfig,
  typeAndProps,
  plotModalState,
  downloadModalState,
  diagnosticsModalState,
  plotLinkModalState,
  fullScreenState,
}: DefaultCardActionMenuProps) {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: true,
  });

  const { disabled: disableDownloadData = false } = downloadConfig;

  return (
    <ActionMenu menuButtonIcon={FiMoreVertical}>
      <ActionMenuButton
        icon={FiMaximize2}
        text="Expand Plot"
        onClick={() => {
          setGlobalLoaderEnableState('DISABLE', 1000);
          plotModalState.onOpen();
        }}
      />

      {cardConfig.includeDiagnosticsModal && (
        <ActionMenuButton
          icon={FiTrendingUp}
          text="Run Diagnostics"
          onClick={() => {
            // setGlobalLoaderEnableState('DISABLE', 1000);
            diagnosticsModalState.onDiagnosticsOpen();
          }}
        />
      )}

      {typeAndProps && typeAndProps.chartType in D3ChartGeneratorLookup && (
        <ActionMenuButton
          icon={FiImage}
          text="Download PNG"
          disabled={isTrialUser}
          onClick={() => {
            if (!isTrialUser) {
              fullScreenState.setIsFullScreen(false);
              downloadModalState.onDownloadOpen();
            }
          }}
        />
      )}

      {!cardConfig.disablePlotLink &&
        typeAndProps &&
        typeAndProps.chartType in D3ChartGeneratorLookup && (
          <ActionMenuButton
            icon={FiLink}
            text="Plot Link"
            disabled={isTrialUser}
            onClick={() => {
              plotLinkModalState.onPlotLinkOpen();
            }}
          />
        )}

      {!disableDownloadData && (
        <ActionMenuButton
          icon={FiDownload}
          text="Download Data"
          disabled={isTrialUser}
          isDownloadButton
          downloadProps={{
            endpoint: downloadConfig.endpoint,
            requestMethod: plotConfig.requestMethod,
            brokenOutFilterIds: plotConfig.brokenOutFilterIds,
            additionalNonActiveFilters: plotConfig.additionalNonActiveFilters,
            additionalOperatorsBeforeQuery:
              plotConfig.additionalOperatorsBeforeQuery,
            isGoRequest: downloadConfig.isGoRequest,
          }}
        />
      )}
    </ActionMenu>
  );
}

export default DefaultCardActionMenu;
