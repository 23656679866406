import {
  select,
  selectAll,
  scaleBand,
  scaleLinear,
  min,
  max,
  scaleOrdinal,
  extent,
} from 'd3';
import { axisLeft } from 'd3-axis';
import { format as d3Format } from 'd3-format';
// import { lightColors, mediumColors, darkColors } from './colors.js';
import { roundedRect } from '../utilities/corner-radius';
import { colors } from '../utilities/colors';
import '../d3-styles.scss';
import { drawSentimentEffectsArrows } from '../utilities/draw-sentiment-effects-arrows';
import { notifyChartRenderComplete } from '../utilities/notify-chart-render-complete';
import { appendWatermark } from '../utilities/append-watermark';
import { adjustDownloadMargins } from '../utilities/adjust-download-margins';
import { generateInlineStyles } from '../utilities/generate-inline-styles';
import { appendTitle } from '../utilities/append-title';
import { generateSVGLegend } from './stacked-bar-chart-horizontal/helpers';

export const BarChartHorizontalMirrorGenerator = (
  plotConfigs,
  downloadOptions
) => {
  let {
    name,
    data: plotData,
    ttMainFormat,
    ttSecondaryFormat,
    height,
    width,
    targetRef,
    requestHash,
    hasArrows = true,
    marginTop = 10,
    innerPad = 0.45,
    maxStep = 30,
    isFullHeight = true,
    ttType,
    isRenderingOrLoading,
    customMargins,
  } = plotConfigs;

  const data = Array.isArray(plotData) ? plotData[0] : plotData;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const dims = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();
    // setup margins and inner dims
    dims.margin = { top: marginTop, left: 140, bottom: 26, right: 35 };

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    // setup svg node
    const node = targetRef?.current;
    const svg = node
      ? select(node).append('svg')
      : select(containerId).append('svg');
    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    if (download) {
      const legendSet = new Set();
      const legendData = [];

      let appendOther = false;

      const configureLegend = (d) => {
        if (d.metadata.shortName.toLowerCase() === 'other') {
          appendOther = true;
        }
        legendSet.add(d.metadata.shortName);
        legendData.push({ key: d.metadata.shortName });
      };

      /** addition on line 48 seems to make the forEach call unnecessary, but
       * keeping in case there are any edge cases where data is still an array */
      if (Array.isArray(data)) data.forEach(configureLegend);
      else configureLegend(data);

      if (appendOther) {
        legendSet.add('Other');
      }

      const otherColor = '#A0A9B8';
      const color = scaleOrdinal().domain(legendSet).range(colors);

      const legendContainer = generateSVGLegend({
        chart: svg,
        width,
        height,
        dims,
        stackedData: legendData,
        color,
        otherColor,
        fontColor: '#636d7e',
      });

      legendContainer.style('transform', `translate(20px, ${height - 40}px)`);

      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        legendContainerHeight: legendContainer.node().getBBox().height,
        padding,
      });
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    //START D3 CODE=============================================================================

    // get plot padding to position tooltip
    // var compStyles = getComputedStyle(targetRef.current);
    // const paddingLeft = parseFloat(compStyles.getPropertyValue('padding-left'));
    // const paddingTop = parseFloat(compStyles.getPropertyValue('padding-top'));

    // replace nulls with zeros, find sum of values to ensure reduce doesn't happen with empty array
    var valueSum = 0;
    var hasPos = false;
    var hasNeg = false;
    var showMidLine = false;
    data.value.forEach((val) => {
      if (val.value === null) {
        val.value = 0;
      }
      if (val.value < 0) {
        hasNeg = true;
      }
      if (val.value > 0) {
        hasPos = true;
      }
      valueSum += Math.abs(val.value);
      showMidLine = hasPos && hasNeg;
    });

    // sort data
    data.value.sort((a, b) => {
      var aDiff = a.value;
      var bDiff = b.value;
      return bDiff - aDiff;
    });

    const xScale = scaleLinear()
      .domain(extent(data.value, (d) => d.value))
      .range([0, dims.innerWidth]);

    // keep inner padding between bars constant and clamp space occupied by bar & inner padding to a max
    // const innerPad = 0.45; //this is a percentage of step
    // const maxStep = 30; //space from start of 1 bar to start of the next
    var yAxisRange = [0, dims.innerHeight];
    // readjust the y axis range to make the chart height (sum of all steps - height of bars + innerpaddings) dependent on # bars and vertically centered
    var chartHeight = data.value.length * maxStep;
    if (chartHeight < dims.innerHeight && !isFullHeight) {
      yAxisRange = [
        dims.innerHeight / 2 - chartHeight / 2,
        dims.innerHeight / 2 + chartHeight / 2,
      ];
    }

    const yScale = scaleBand()
      .domain(data.value.map((d) => d.longName))
      .range(yAxisRange)
      .paddingInner(innerPad);

    const tooltipWidth = 140;
    // const tooltipHeight = 50;

    const tooltip = select(`.react-node-${name}`)
      .append('div')
      .style('width', tooltipWidth + 'px')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      .attr('class', 'tooltip-mirror-bar-chart-horizontal')
      .classed(`tooltip-${name}`, true);

    const mouseOver = (event, d) => {
      //show tooltip
      tooltip
        .style('opacity', 1)
        .style('display', null)
        .style(
          'transform',
          `translate(${
            // eslint-disable-next-line no-nested-ternary
            xScale.domain()[0] < 0 && xScale.domain()[1] > 0
              ? dims.margin.left +
                min([xScale(d.value), xScale(0)]) +
                Math.abs(xScale(d.value) - xScale(0)) / 2 -
                tooltipWidth / 2
              : xScale.domain()[0] > 0
                ? dims.margin.left +
                  min([xScale(d.value), xScale(xScale.domain()[0])]) +
                  Math.abs(xScale(d.value)) / 2 -
                  tooltipWidth / 2
                : dims.margin.left +
                  xScale(d.value) +
                  //max([xScale(d.value), xScale(xScale.domain()[0])]) +
                  Math.abs(xScale(d.value) - xScale(xScale.domain()[1])) / 2 -
                  tooltipWidth / 2
          }px, ${
            dims.margin.top +
            yScale(d.longName) -
            (ttType === 'skillsGrowth' ? 60 : 75) -
            7
          }px)`
        )
        .html(
          ttType === 'skillsGrowth'
            ? `<span style="font-weight:bold; font-size: 12px">${
                d.longName
              }</span><br/>${d.value > 0 ? '+' : ''}${d3Format('.0%')(d.value)}`
            : `<span style="font-weight:bold; font-size: 12px">${
                d.longName
              }</span><br>Topic Score: ${d.value > 0 ? '+' : ''}${d3Format(
                ttMainFormat
              )(d.value)}<br>(Sample Size: ${d3Format(ttSecondaryFormat)(
                d.count
              )})`
        );

      selectAll(`.${name}-bar`)
        .filter((row) => row.longName != d.longName)
        .style('opacity', 0.5);
    };

    const mouseOut = () => {
      tooltip.style('opacity', 0).style('display', 'none');
      selectAll(`.${name}-bar`).style('opacity', 1);
    };

    chart
      .selectAll('.bar')
      .data(data.value)
      .join('path')
      .attr('class', 'bar')
      .classed(`${name}-bar`, true)
      .attr('d', (d) => {
        return roundedRect(
          xScale.domain()[0] < 0
            ? xScale(Math.min(d.value, 0))
            : xScale(xScale.domain()[0]),
          yScale(d.longName),
          xScale.domain()[0] < 0
            ? Math.abs(xScale(d.value) - xScale(0))
            : xScale(d.value),
          yScale.bandwidth(),
          [1, 1, 1, 1]
        );
      })
      .attr('fill', (d) => (d.value > 0 ? colors[1] : colors[5]))
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut);

    // add horizontal dashed line separating positive and negative bars:
    if (valueSum > 0 && showMidLine) {
      chart
        .append('line')
        .style('opacity', 0.6)
        .attr(
          'y1',
          yScale(
            data.value
              .filter((d) => d.value > 0)
              .reduce((a, b) =>
                // eslint-disable-next-line no-nested-ternary
                b.value == a.value ? b : b.value < a.value ? b : a
              ).longName
          ) +
            yScale.bandwidth() +
            (yScale.step() - yScale.bandwidth()) / 2
        )
        .attr('x1', dims.innerWidth)
        .attr(
          'y2',
          yScale(
            data.value
              .filter((d) => d.value > 0)
              .reduce((a, b) =>
                // eslint-disable-next-line no-nested-ternary
                b.value == a.value ? b : b.value < a.value ? b : a
              ).longName
          ) +
            yScale.bandwidth() +
            (yScale.step() - yScale.bandwidth()) / 2
        )
        .attr('x2', 0)
        .attr('stroke', '#2D426A')
        .attr('stroke-width', 1.5)
        .attr('stroke-dasharray', 3, 1);
    }

    // axes
    const yAxisLeft = axisLeft().scale(yScale).tickSizeOuter(0);

    chart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .classed('mirror-bar-chart-horizontal-axis-label', true)
      .call(yAxisLeft)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove());

    if (valueSum > 0 && hasArrows) {
      // append groups for text & arrows at bottom of chart
      drawSentimentEffectsArrows(
        chart,
        dims,
        {
          translateX1: download
            ? 0
            : (xScale(0) + xScale(min(data.value, (d) => d.value))) / 2,
          translateY1: dims.innerHeight + 22,
        },
        {
          translateX2: download
            ? dims.innerWidth - dims.margin.right
            : (xScale(max(data.value, (d) => d.value)) + xScale(0)) / 2,
          translateY2: dims.innerHeight + 22,
        }
      );
    }

    if (!download) {
      notifyChartRenderComplete(chart, requestHash, () =>
        isRenderingOrLoading?.next(false)
      );
    } else {
      generateInlineStyles([
        '.mirror-bar-chart-horizontal-axis-label',
        '.mirror-bar-chart-horizontal-arrow',
        '.mirror-bar-chart-horizontal-text',
      ]);
    }

    if (title) {
      appendTitle(chart, title, dims, padding);
    }

    if (watermark) {
      appendWatermark(
        chart,
        watermark,
        dims.innerWidth + dims.margin.right,
        dims.innerHeight + dims.margin.bottom,
        padding
      );
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
