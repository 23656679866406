export const ttfPercentiles = [
  {
    percentile: 0,
    z_score: -2.977861802,
  },
  {
    percentile: 1,
    z_score: -1.783278744,
  },
  {
    percentile: 2,
    z_score: -1.633522618,
  },
  {
    percentile: 3,
    z_score: -1.529619088,
  },
  {
    percentile: 4,
    z_score: -1.44630524,
  },
  {
    percentile: 5,
    z_score: -1.377105539,
  },
  {
    percentile: 6,
    z_score: -1.31686017,
  },
  {
    percentile: 7,
    z_score: -1.263298779,
  },
  {
    percentile: 8,
    z_score: -1.215139321,
  },
  {
    percentile: 9,
    z_score: -1.171709912,
  },
  {
    percentile: 10,
    z_score: -1.130802767,
  },
  {
    percentile: 11,
    z_score: -1.092850872,
  },
  {
    percentile: 12,
    z_score: -1.056944914,
  },
  {
    percentile: 13,
    z_score: -1.023261721,
  },
  {
    percentile: 14,
    z_score: -0.9909389757,
  },
  {
    percentile: 15,
    z_score: -0.9605015688,
  },
  {
    percentile: 16,
    z_score: -0.9314976922,
  },
  {
    percentile: 17,
    z_score: -0.903384243,
  },
  {
    percentile: 18,
    z_score: -0.8767132002,
  },
  {
    percentile: 19,
    z_score: -0.8508533972,
  },
  {
    percentile: 20,
    z_score: -0.82551478,
  },
  {
    percentile: 21,
    z_score: -0.8012785981,
  },
  {
    percentile: 22,
    z_score: -0.7779417852,
  },
  {
    percentile: 23,
    z_score: -0.7550070724,
  },
  {
    percentile: 24,
    z_score: -0.7324052464,
  },
  {
    percentile: 26,
    z_score: -0.6889687897,
  },
  {
    percentile: 27,
    z_score: -0.667832802,
  },
  {
    percentile: 28,
    z_score: -0.6468541915,
  },
  {
    percentile: 29,
    z_score: -0.6260637848,
  },
  {
    percentile: 30,
    z_score: -0.6057162305,
  },
  {
    percentile: 31,
    z_score: -0.5857673603,
  },
  {
    percentile: 32,
    z_score: -0.5660544268,
  },
  {
    percentile: 33,
    z_score: -0.5461355699,
  },
  {
    percentile: 34,
    z_score: -0.5264158172,
  },
  {
    percentile: 35,
    z_score: -0.5062225296,
  },
  {
    percentile: 36,
    z_score: -0.4865015926,
  },
  {
    percentile: 37,
    z_score: -0.4665838198,
  },
  {
    percentile: 38,
    z_score: -0.4468050694,
  },
  {
    percentile: 39,
    z_score: -0.4268854727,
  },
  {
    percentile: 40,
    z_score: -0.4068195085,
  },
  {
    percentile: 41,
    z_score: -0.3867167941,
  },
  {
    percentile: 42,
    z_score: -0.3660572432,
  },
  {
    percentile: 43,
    z_score: -0.3452497412,
  },
  {
    percentile: 44,
    z_score: -0.3240108538,
  },
  {
    percentile: 45,
    z_score: -0.3025970761,
  },
  {
    percentile: 46,
    z_score: -0.2805012796,
  },
  {
    percentile: 47,
    z_score: -0.2578339028,
  },
  {
    percentile: 48,
    z_score: -0.235236144,
  },
  {
    percentile: 49,
    z_score: -0.2122418848,
  },
  {
    percentile: 50,
    z_score: -0.1887635518,
  },
  {
    percentile: 51,
    z_score: -0.1648146472,
  },
  {
    percentile: 52,
    z_score: -0.140478171,
  },
  {
    percentile: 53,
    z_score: -0.1157087223,
  },
  {
    percentile: 54,
    z_score: -0.09048603168,
  },
  {
    percentile: 55,
    z_score: -0.06446662878,
  },
  {
    percentile: 56,
    z_score: -0.0382149442,
  },
  {
    percentile: 58,
    z_score: 0.01611546746,
  },
  {
    percentile: 59,
    z_score: 0.0443695571,
  },
  {
    percentile: 60,
    z_score: 0.07339557867,
  },
  {
    percentile: 61,
    z_score: 0.1030475278,
  },
  {
    percentile: 62,
    z_score: 0.1335325975,
  },
  {
    percentile: 63,
    z_score: 0.1648891362,
  },
  {
    percentile: 64,
    z_score: 0.1974693486,
  },
  {
    percentile: 65,
    z_score: 0.2305585723,
  },
  {
    percentile: 66,
    z_score: 0.2647526108,
  },
  {
    percentile: 67,
    z_score: 0.2998081399,
  },
  {
    percentile: 68,
    z_score: 0.3356725419,
  },
  {
    percentile: 69,
    z_score: 0.3719714245,
  },
  {
    percentile: 70,
    z_score: 0.4089844225,
  },
  {
    percentile: 71,
    z_score: 0.4463148725,
  },
  {
    percentile: 72,
    z_score: 0.484015348,
  },
  {
    percentile: 73,
    z_score: 0.5227298005,
  },
  {
    percentile: 74,
    z_score: 0.5617935626,
  },
  {
    percentile: 75,
    z_score: 0.6016781501,
  },
  {
    percentile: 76,
    z_score: 0.6418747063,
  },
  {
    percentile: 77,
    z_score: 0.6837450778,
  },
  {
    percentile: 78,
    z_score: 0.7269650624,
  },
  {
    percentile: 79,
    z_score: 0.771628752,
  },
  {
    percentile: 80,
    z_score: 0.8176563649,
  },
  {
    percentile: 81,
    z_score: 0.8659792147,
  },
  {
    percentile: 82,
    z_score: 0.9164853212,
  },
  {
    percentile: 83,
    z_score: 0.9692250677,
  },
  {
    percentile: 84,
    z_score: 1.024518375,
  },
  {
    percentile: 85,
    z_score: 1.083570741,
  },
  {
    percentile: 86,
    z_score: 1.146402512,
  },
  {
    percentile: 87,
    z_score: 1.21364326,
  },
  {
    percentile: 88,
    z_score: 1.285297736,
  },
  {
    percentile: 89,
    z_score: 1.361508543,
  },
  {
    percentile: 90,
    z_score: 1.442761125,
  },
  {
    percentile: 91,
    z_score: 1.529683515,
  },
  {
    percentile: 92,
    z_score: 1.622867364,
  },
  {
    percentile: 93,
    z_score: 1.723927657,
  },
  {
    percentile: 94,
    z_score: 1.832096944,
  },
  {
    percentile: 95,
    z_score: 1.950837528,
  },
  {
    percentile: 96,
    z_score: 2.083393301,
  },
  {
    percentile: 97,
    z_score: 2.239550771,
  },
  {
    percentile: 98,
    z_score: 2.43744218,
  },
  {
    percentile: 99,
    z_score: 2.724361238,
  },
  {
    percentile: 100,
    z_score: 3.574304714,
  },
];
