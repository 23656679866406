import { cx } from '@chakra-ui/utils';
import { useEffect$ } from '@ngneat/react-rxjs';
import { has, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { FixedSizeTree } from 'react-vtree';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { TreeItem } from '../../../../engine/filters.model';
import Node, { TreeType } from '../../node/node';
import styles from '../../tree/tree.module.css';

export interface SubmenuTreeProps {
  placeholder?: string;
  nestingLevel: number;
  candidateTree: any;
  selectionLookupRef: Observable<{ [key: string]: TreeItem }>;
  intermediateLookupRef: Observable<{ [key: string]: TreeItem }>;
  search: any;
  lookupHandle: any;
  intermediateStateHandle: any;
  select: any;
  parentRef?: any;
  labelFormatter?: (str: string) => string;
  compareFunction?: (
    a: TreeItem,
    b: TreeItem,
    selections: {
      [key: string]: TreeItem;
    },
    intermediate: {
      [key: string]: TreeItem;
    }
  ) => number;
}

export function SubmenuTree(props: SubmenuTreeProps) {
  const {
    placeholder = 'loading...',
    candidateTree,
    selectionLookupRef,
    intermediateLookupRef,
    search,
    lookupHandle,
    intermediateStateHandle,
    select,
    parentRef,
    labelFormatter = (str) => str,
    compareFunction,
  } = props;

  const [treeWalker, setTreeWalker] = useState<any>(
    () =>
      function* loadingTreeWalker(): any {
        yield {
          data: {
            id: '$no-results-node',
            isOpenByDefault: false,
            nestingLevel: 0,
            selected: of(false),
            intermediate: of(false),
          },
          node: {
            id: '$no-results-node',
            children: [],
            item: { label: '' },
          },
        };
      }
  );

  const getNodeData = useCallback(
    (
      node: any,
      nestingLevel: any,
      lookup: Observable<{ [key: string]: TreeItem }>,
      intermediateLookup: Observable<Record<string, unknown>>,
      search = ''
    ) => {
      const selected: Observable<any> = merge(
        lookup.pipe(distinctUntilChanged()),
        lookupHandle.current
      ).pipe(
        map((state) => {
          return state;
        })
      );

      const intermediate = merge(
        intermediateLookup.pipe(distinctUntilChanged()),
        intermediateStateHandle.current
      ).pipe(
        map((state) => {
          return has(state, node.id);
        })
      );

      return {
        data: {
          id: node.id.toString(),
          listId: node.selectionListId,
          isLeaf: true,
          children: node.children,
          childIds: node.children.map((item: TreeItem) => item.id),
          isOpenByDefault: !isEmpty(search),
          name: node.item.label,
          nestingLevel,
          select,
          intermediate,
          selected,
          disableParentSelect: false,
          hideParentCheckbox: false,
          isSelectableParent: true,
          offsetParent: false,
          item: node.item,
          isNestedTree: true,
          labelFormatter,
          nestingTreeType: TreeType.SUB_MENU_NESTED,
          submenuProps: {
            lookupHandle,
            intermediateStateHandle,
            selectionLookupRef: lookup,
            intermediateLookupRef: intermediateLookup,
            parentRef,
            compareFunction,
          },

          maxLabelWidth: 165,
        },
        offsetParent: false,
        nestingLevel,
        node,
      };
    },
    // eslint-disable-next-line
    [lookupHandle, intermediateStateHandle, select]
  );

  useEffect$(() => {
    return of(candidateTree).pipe(
      map((tree) => {
        setTreeWalker(
          () =>
            function* treeWalker(): any {
              for (const treeItem of tree) {
                yield getNodeData(
                  treeItem,
                  0,
                  selectionLookupRef,
                  intermediateLookupRef,
                  search
                );
              }
            }
        );
      })
    );
  });

  useEffect$(() => {
    return combineLatest({
      selections: selectionLookupRef,
      intermediate: intermediateLookupRef,
    }).pipe(
      withLatestFrom(of(candidateTree)),
      map((state) => {
        const [{ selections, intermediate }, tree] = state;

        if (compareFunction) {
          tree.sort((a: any, b: any) =>
            compareFunction(a, b, selections, intermediate)
          );
        }
      })
    );
  });

  return (
    <FixedSizeTree
      className={cx(styles.fixedSizedTree)}
      treeWalker={treeWalker}
      placeholder={placeholder}
      itemSize={24}
      height={220}
    >
      {Node as any}
    </FixedSizeTree>
  );
}

export default SubmenuTree;
