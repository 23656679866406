import { SystemStyleObject } from '@chakra-ui/react';
import { ISelectFilter } from '../../../../engine/filters.model';

export const getMenuList =
  (selectListHeight: number | undefined) => (provided: SystemStyleObject) => ({
    ...provided,
    height: selectListHeight || 'fit-content',
    maxHeight: '316px',
    scrollbarColor: '#e3e3e3 #ffffff',
    '&::-webkit-scrollbar': {
      width: '14px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      marginTop: '5px',
      marginBottom: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e6ebf4',
      borderRadius: '10px',
      border: '3px solid #ffffff',
    },
  });

export const getMenu =
  (
    selectValue: ISelectFilter | null | undefined,
    selectMenuOpenDefault?: boolean
  ) =>
  (provided: SystemStyleObject) => ({
    ...provided,
    position:
      selectMenuOpenDefault && selectValue === undefined
        ? 'relative'
        : 'absolute',
    marginTop:
      selectMenuOpenDefault && selectValue === undefined ? '-1px' : '8px',
  });
