import {
  CompanySearchTreeRef,
  LocalSelectionCategories,
  NestedMenuItemConfig,
  SelectionCategories,
  TreeRef,
  TreeType,
} from '@revelio/filtering';
import { environment } from '../../environments/environment';

export const TDV2FilterNames = [
  {
    filters: [SelectionCategories.COMPANY],
    isAggregateChip: true,
    formatChipLabel: false,
    heading: SelectionCategories.COMPANY,
  },

  {
    filters: [SelectionCategories.HIGHEST_DEGREE],
    isAggregateChip: true,
    heading: SelectionCategories.HIGHEST_DEGREE,
  },

  // {
  //   filters: [SelectionCategories.BASE_SALARY],
  //   isAggregateChip: true,
  //   heading: SelectionCategories.BASE_SALARY,
  // },

  {
    filters: [SelectionCategories.TOTAL_COMPENSATION],
    isAggregateChip: true,
    heading: SelectionCategories.TOTAL_COMPENSATION,
  },

  {
    filters: [
      SelectionCategories.COUNTRY,
      SelectionCategories.REGION,
      SelectionCategories.METRO_AREA,
    ],
    isAggregateChip: true,
    heading: SelectionCategories.GEOGRAPHY,
  },

  {
    filters: [SelectionCategories.ETHNICITY],
    isAggregateChip: true,
    heading: SelectionCategories.ETHNICITY,
  },

  {
    filters: [SelectionCategories.GENDER],
    isAggregateChip: true,
    heading: SelectionCategories.GENDER,
  },

  {
    filters: [LocalSelectionCategories.PRESTIGE],
    isAggregateChip: true,
    heading: LocalSelectionCategories.PRESTIGE,
  },

  {
    filters: [LocalSelectionCategories.FLIGHT_RISK],
    isAggregateChip: true,
    heading: LocalSelectionCategories.FLIGHT_RISK,
  },

  {
    filters: [LocalSelectionCategories.REMOTE_SUITABILITY],
    isAggregateChip: true,
    heading: LocalSelectionCategories.REMOTE_SUITABILITY,
  },

  {
    filters: [
      SelectionCategories.JOB_CATEGORY,
      SelectionCategories.ROLE_K150,
      SelectionCategories.ROLE_K1500,
    ],
    isAggregateChip: true,
    heading: SelectionCategories.ROLES,
  },

  {
    filters: [SelectionCategories.RSID],
    isAggregateChip: true,
    heading: SelectionCategories.RSID,
  },

  {
    filters: [
      SelectionCategories.SKILL_K75,
      SelectionCategories.SKILL_K700,
      SelectionCategories.SKILL_K3000,
    ],
    isAggregateChip: true,
    heading: SelectionCategories.SKILLS,
  },

  {
    filters: [SelectionCategories.SENIORITY],
    isAggregateChip: true,
    heading: SelectionCategories.SENIORITY,
  },
  {
    filters: [SelectionCategories.KEYWORD],
    isAggregateChip: true,
    formatChipLabel: false,
    heading: SelectionCategories.KEYWORD,
  },

  {
    filters: [SelectionCategories.NAME],
    isAggregateChip: true,
    heading: SelectionCategories.NAME,
  },
];

export const TDV2MenuConfig: NestedMenuItemConfig[] = [
  {
    heading: SelectionCategories.COMPANY,
    selections: [
      {
        name: 'Company',
        keyName: 'company',
        hideTitle: true,
        selectionCategory: [SelectionCategories.COMPANY],
        type: 'asyncTree',
        comp: [CompanySearchTreeRef],
        props: [
          {
            apiEndpoint: `${environment.COMPANY_MAPPER_URL}/search`,
            treeHeight: 250,
            inputGroupSize: 'sm',
            showPlaceholderNode: false,
            collapseTree: true,
            showToggleBar: true,
            filterNameForToggle: 'Company',
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.HIGHEST_DEGREE,
    selections: [
      {
        name: 'Highest Degree',
        keyName: 'education',
        hideTitle: true,
        selectionCategory: [SelectionCategories.HIGHEST_DEGREE],
        type: 'tree',
        comp: [TreeRef],
      },
    ],
  },

  {
    heading: SelectionCategories.ETHNICITY,
    selections: [
      {
        name: 'Ethnicity',
        keyName: 'ethnicity',
        hideTitle: true,
        selectionCategory: [SelectionCategories.ETHNICITY],
        type: 'tree',
        comp: [TreeRef],
      },
    ],
  },

  {
    heading: LocalSelectionCategories.FLIGHT_RISK,
    selections: [
      {
        name: 'Flight Risk',
        keyName: 'flight_risk',
        hideTitle: true,
        selectionCategory: [LocalSelectionCategories.FLIGHT_RISK],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            showToggleBar: true,
            filterNameForToggle: 'Flight Risk',
            sortSelectedToTop: false,
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.GENDER,
    selections: [
      {
        name: 'Gender',
        keyName: 'gender',
        hideTitle: true,
        selectionCategory: [SelectionCategories.GENDER],
        type: 'tree',
        comp: [TreeRef],
      },
    ],
  },

  {
    heading: SelectionCategories.GEOGRAPHY,
    selections: [
      {
        name: 'Geography',
        keyName: 'geography',
        hideTitle: true,
        selectionCategory: [
          SelectionCategories.REGION,
          SelectionCategories.COUNTRY,
          SelectionCategories.METRO_AREA,
        ],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            nestingTreeType: TreeType.SUB_MENU_NESTED,
            showHeader: true,
            showToggleBar: true,
            filterNameForToggle: 'Geography',
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.NAME,
    selections: [
      {
        name: 'name',
        keyName: 'name',
        hideTitle: true,
        selectionCategory: [SelectionCategories.NAME],
        type: 'nameInput',
      },
    ],
  },

  {
    heading: LocalSelectionCategories.PRESTIGE,
    selections: [
      {
        name: 'Prestige',
        keyName: 'prestige',
        hideTitle: true,
        selectionCategory: [LocalSelectionCategories.PRESTIGE],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            showToggleBar: true,
            filterNameForToggle: 'Prestige',
            sortSelectedToTop: false,
          },
        ],
      },
    ],
  },

  {
    heading: LocalSelectionCategories.REMOTE_SUITABILITY,
    selections: [
      {
        name: 'Remote Suitability',
        keyName: 'remote_suitability',
        hideTitle: true,
        selectionCategory: [LocalSelectionCategories.REMOTE_SUITABILITY],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            showToggleBar: true,
            filterNameForToggle: 'Remote Suitability',
            sortSelectedToTop: false,
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.ROLES,
    selections: [
      {
        name: 'Roles',
        keyName: 'roles',
        hideTitle: true,
        selectionCategory: [
          SelectionCategories.JOB_CATEGORY,
          SelectionCategories.ROLE_K150,
          SelectionCategories.ROLE_K1500,
        ],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            nestingTreeType: TreeType.SUB_MENU_NESTED,
            showHeader: true,
            showToggleBar: true,
            filterNameForToggle: 'Role',
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.RSID,
    selections: [
      {
        name: 'School',
        keyName: 'school',
        hideTitle: true,
        selectionCategory: [SelectionCategories.RSID],
        type: 'schoolSearchTree',
        props: [
          {
            treeHeight: 250,
            inputGroupSize: 'sm',
            showPlaceholderNode: false,
            collapseTree: true,
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.SENIORITY,
    selections: [
      {
        name: 'Seniority',
        keyName: 'seniority',
        hideTitle: true,
        selectionCategory: [SelectionCategories.SENIORITY],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            showToggleBar: true,
            filterNameForToggle: 'Seniority',
            sortSelectedToTop: false,
          },
        ],
      },
    ],
  },

  // {
  //   heading: SelectionCategories.BASE_SALARY,
  //   selections: [
  //     {
  //       name: 'Base Salary',
  //       keyName: 'base_salary',
  //       hideTitle: true,
  //       selectionCategory: [SelectionCategories.BASE_SALARY],
  //       type: 'SimpleFilterRange',
  //       props: [
  //         {
  //           showToggleBar: true,
  //           filterNameForToggle: 'Base Salary',
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    heading: SelectionCategories.TOTAL_COMPENSATION,
    selections: [
      {
        name: 'Total Compensation',
        keyName: 'total_compensation',
        hideTitle: true,
        selectionCategory: [SelectionCategories.TOTAL_COMPENSATION],
        type: 'SimpleFilterRange',
        props: [
          {
            showToggleBar: true,
            filterNameForToggle: 'Pay',
          },
        ],
      },
    ],
  },
  {
    heading: SelectionCategories.SKILLS,
    selections: [
      {
        name: 'Skill',
        keyName: 'skill',
        hideTitle: true,
        selectionCategory: [
          SelectionCategories.SKILL_K75,
          SelectionCategories.SKILL_K700,
          SelectionCategories.SKILL_K3000,
        ],
        type: 'tree',
        comp: [TreeRef],
        props: [
          {
            nestingTreeType: TreeType.SUB_MENU_NESTED,
            showHeader: true,
          },
        ],
      },
    ],
  },

  {
    heading: SelectionCategories.KEYWORD,
    selections: [
      {
        name: 'Keyword',
        keyName: 'keyword',
        hideTitle: true,
        selectionCategory: [SelectionCategories.KEYWORD],
        type: 'customKeyword',
      },
    ],
  },
];
