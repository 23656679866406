export const weeklyDatesSorted = (allDates) => {
  // Given an array of date objects, return a sorted array of those date objects plus date objects for any missing weeks

  // create a sorted set of all weeks seen
  var allDatesSet = Array.from(
    new Set(allDates.map((dateObject) => dateObject.toDateString()))
  ).map((dateString) => new Date(dateString));
  var allDatesSorted = allDatesSet.sort((a, b) => b - a);
  // see if there are any missing weeks in the data time period
  allDatesSorted.forEach((date, i) => {
    if (date - allDatesSorted[i + 1] > 7.1 * 1000 * 60 * 60 * 24) {
      const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      newDate.setDate(newDate.getDate() - 7);
      // add any missing weeks to allDatesSorted array
      allDatesSorted.push(newDate);
      allDatesSorted.sort((a, b) => b - a);
    }
  });
  return allDatesSorted;
};
