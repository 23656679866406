import React from 'react';

const HighlightFill = () => {
  return (
    <svg
      width={768}
      height={147}
      viewBox="0 0 768 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        width: '94vh',
        height: 'auto',
        bottom: '43.5%',
        zIndex: 10,
        mixBlendMode: 'hard-light',
        opacity: 0.2,
      }}
    >
      <rect x={3} y={3} width={762} height="140.735" rx="8" fill="#9c42fc" />
    </svg>
  );
};

export default HighlightFill;
