// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { HistogramGenerator } from '../plot-generators/HistogramGenerator';

const Histogram = ({
  name,
  heading,
  data,
  ttMainFormat,
  ttSecondaryFormat,
  yAxisFormat,
  xAxisFormat,
  height,
  width,
  targetRef,
  showDensity,
  requestHash,
}) => {
  useD3(
    HistogramGenerator,
    {
      name,
      heading,
      data,
      ttMainFormat,
      ttSecondaryFormat,
      yAxisFormat,
      xAxisFormat,
      height,
      width,
      targetRef,
      showDensity,
      requestHash,
    },
    [targetRef.current, width, height, data, requestHash]
  );

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        display: 'grid',
        position: 'relative',
        height: '100%',
      }}
    />
  );
};

Histogram.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
  xAxisFormat: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  showDensity: PropTypes.bool,
};

Histogram.defaultProps = {
  name: 'default',
  heading: 'default',
  ttMainFormat: '.1%',
  ttSecondaryFormat: ',',
  yAxisFormat: '.0%',
  xAxisFormat: '$~s',
  data: [],
  showDensity: false,
};

export default withResizeDetector(Histogram, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
