import { ReactElement } from 'react';
import { MenuItem, MenuList } from '@chakra-ui/menu';
import { Text } from '@chakra-ui/layout';

interface RightClickMenuProps {
  menuOptions: MenuOption[];
}

export type MenuOption = {
  id: string;
  title: string;
  onClick: () => void;
  icon: ReactElement;
};

const RightClickMenu = ({ menuOptions }: RightClickMenuProps) => {
  return (
    <MenuList marginLeft="30px">
      {menuOptions.map((option) => (
        <MenuItem
          key={option.id}
          onClick={option.onClick}
          _hover={{ background: 'brightGreen.100' }}
          icon={option.icon}
        >
          <Text>{option.title}</Text>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default RightClickMenu;
