import { LineData, LineValue } from '@revelio/d3';
import { PostingMetric, PostingsDataQuery } from '@revelio/data-access';

const dateRegEx = /(.*)-(.*)-(.*)/;

export type MetricKey = keyof Pick<
  PostingMetric,
  'salary' | 'time_to_fill' | 'active' | 'new' | 'removed' | 'expected_hires'
>;
type GetLineChartDataProps = {
  entities: PostingsDataQuery['posting'];
  metricKey: MetricKey;
};

export const getLineChartData = ({
  entities,
  metricKey,
}: GetLineChartDataProps): LineData[] => {
  return (
    entities?.map((entity): LineData => {
      const entityMetadata = entity?.metadata;

      const id = entityMetadata?.id;
      const shortName = entityMetadata?.shortName;

      return {
        id,
        metadata: { shortName, longName: shortName },
        value: (entity?.category || []).map((category): LineValue => {
          const metadata = category?.metadata;
          const metrics = category?.metrics;

          const id = metadata?.id;
          const shortName = metadata?.shortName;

          const value = metrics?.[metricKey];

          const match = shortName?.match(dateRegEx) || ['0', '0', '0', '0'];
          const [, year, month, day] = match;
          return {
            id,
            metadata: {
              shortName,
              longName: shortName,
              day: Number(day),
              month: Number(month),
              year: Number(year),
            },
            value,
          };
        }),
      };
    }) ?? []
  );
};
