import { Styles } from 'react-joyride';

const defaultOptions = {
  arrowSize: '5px',
  overlayColor: 'rgba(0, 0, 0, 0.7)',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.7)',
  width: '333px',
  zIndex: 10000,
};

export const tourStyles: Partial<Styles> = {
  options: defaultOptions,
  tooltipContainer: {
    textAlign: 'left',
    fontSize: '14px',
  },
  tooltipTitle: {
    fontSize: 16,
    marginLeft: '1px',
    fontWeight: '600',
  },
  tooltipContent: {
    padding: '10px 0 7px 1px',
    fontWeight: 400,
  },
  buttonNext: {
    backgroundColor: '#3BCE7B',
    minWidth: '80px',
    height: '30px',
    fontSize: 14,
    outline: 'none',
    padding: '6px 11px',
  },
  buttonClose: {
    color: '#000000',
    height: '10px',
  },
  buttonBack: {
    color: 'grey',
    fontSize: 12,
  },
  buttonSkip: {
    color: 'grey',
    fontSize: 12,
    padding: '0 0 3px 7px',
  },
};
