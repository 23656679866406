import { graphql } from '@revelio/data-access';

//
// Feature flagged version of TalentDiscoveryData wit the following additions:
// users.education
//
export const TALENT_DISCOVERY_DATA = graphql(`
  query TalentDiscoveryData($query: TalentDiscoveryV1_5Filter) {
    talent_discovery_search_v1_5(query: $query) {
      columns
      users {
        user_id
        first_name
        last_name
        email
        personal_email
        current_title
        current_company
        start_date
        end_date
        longitude
        latitude
        RCID
        location
        gender
        ethnicity
        location
        highest_degree
        salary_base
        salary_total
        prestige
        remote_suitability
        years_of_experience
        flight_risk
        linkedin_url
        phone_number
        education
        history {
          first_name
          last_name
          email
          current_title
          current_company
          start_date
          end_date
          RCID
          location
          highest_degree
          salary_base
          salary_total
          prestige
          remote_suitability
          years_of_experience
          flight_risk
          linkedin_url
        }
      }
      map_data {
        id
        type
        count
        share
        lat
        lon
        location
      }
      metadata {
        total_pages
      }
    }
  }
`);
