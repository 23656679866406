import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import tippy, {
  followCursor,
  Instance as TippyInstance,
  Props,
} from 'tippy.js';
import WordCloud from 'wordcloud';
import { delay, get, maxBy } from 'lodash';
import { removeLoadingStatus } from '@revelio/core';

import './tippy.theme.scss';
import { IWordCloud } from '../../model';

type Word = [string, number]; // type alias for a word in the form of [word, size]

// These values define the range of font sizes that will be applied to the words based on their weights.
const MIN_FONT_SIZE = 6; // minimum font size to use in the word cloud
const MAX_FONT_SIZE = 20; // maximum font size to use in the word cloud

const RlWordCloud = ({
  name,
  data,
  positiveSentiment,
  height,
  width,
  targetRef,
  requestHash,
  isRenderingOrLoading,
}: IWordCloud) => {
  const wcRef = useRef<HTMLDivElement>(null);

  const maxCount = useMemo(() => {
    const max = maxBy(data[0], (w: [string, number]) => w[1]);
    return get(max, '[1]', 100) as number;
  }, [data]);

  const wordMap = useMemo(() => {
    const map: { [name: string]: number } = {};
    data[0].forEach(([name, count]: Word) => {
      map[name] = count;
    });
    return map;
  }, [data]);

  const list = useMemo(() => {
    return data[0].map(([word, count]: Word) => {
      //some logic to get fontSize from count - comes from the old backend
      const rs = 0.5;
      const last_freq = 1;
      const font_size = 200;
      return [
        word,
        Math.round(rs * (count / last_freq + (1 - rs)) * font_size),
      ];
    });
  }, [data]);

  const fontSizes = useMemo(() => {
    // extract font sizes from words
    return list.map((word: Word) => word[1]);
  }, [list]);

  const minSize = useMemo(() => {
    return Math.min(...fontSizes); // find minimum font size
  }, [fontSizes]);

  const maxSize = useMemo(() => {
    return Math.max(...fontSizes); // find maximum font size
  }, [fontSizes]);

  const sizeDiff = useMemo(() => {
    return maxSize - minSize;
  }, [maxSize, minSize]);

  const mappedFontSizes = useMemo(() => {
    // map font sizes to range between MIN_FONT_SIZE and MAX_FONT_SIZE
    return fontSizes.map(
      (size: number) =>
        MIN_FONT_SIZE +
        ((size - minSize) * (MAX_FONT_SIZE - MIN_FONT_SIZE)) /
          (maxSize - minSize)
    );
  }, [fontSizes, minSize, maxSize]);

  const avgFontSize = useMemo(() => {
    return (
      mappedFontSizes.reduce((acc: number, size: number) => acc + size, 0) /
      mappedFontSizes.length
    );
  }, [mappedFontSizes]);

  useEffect(() => {
    let tippyInstance: TippyInstance<Props> | undefined;
    let timeout: NodeJS.Timeout;
    const wcRefCurrent = wcRef.current;
    if (data && targetRef && targetRef.current && height && wcRefCurrent) {
      tippyInstance = tippy(wcRefCurrent, {
        ignoreAttributes: true,
        allowHTML: true,
        content: '',
        theme: 'word-cloud',
        followCursor: true,
        hideOnClick: false,
        onCreate(instance) {
          instance.disable();
        },
        plugins: [followCursor],
      });

      timeout = setTimeout(() => {
        if (wcRefCurrent) {
          WordCloud([wcRefCurrent], {
            list: list,
            // gridSize: 18,
            // Divide the canvas height by the number of words to ensure there's enough space for all words.
            // plus a little extra space to ensure there's no overlap
            gridSize: height / list.length + 2,
            weightFactor: (size) => {
              const fontSize = size * (avgFontSize / sizeDiff);
              // Set a scaling factor to reduce the font sizes
              const scalingFactor = 0.5;
              // These values are to limit the font sizes calculated based on the proportional differences between words.
              const maxFontSize = window.innerHeight > 900 ? 120 : 60; // Define the maximum font size: ;
              const minFontSize = window.innerHeight > 900 ? 20 : 10; // Define the minimum font size
              const factor = Math.max(1, minFontSize / minSize);
              // Calculate the scaled font size based on the maximum font size and the proportional differences
              const scaledFontSize =
                (fontSize * factor * scalingFactor) / (avgFontSize / sizeDiff);
              // Ensure that the scaled font size doesn't exceed the maximum font size and doesn't go lower than the minimum font size
              return Math.max(
                Math.min(scaledFontSize, maxFontSize),
                minFontSize
              );
            },
            minSize: 16,
            rotationSteps: 2,
            minRotation: 0,
            maxRotation: 1.5708,
            drawOutOfBound: false,
            shrinkToFit: true,
            shape: 'square',
            shuffle: false,
            rotateRatio: 0.5,
            fontFamily: 'Source Sans Pro, Roboto',
            // drawMask: true,
            maskColor: '#996161',
            classes: 'word-clouds',
            color: (word, weight) => {
              const a = +weight / maxCount + 0.2 || 100;
              return positiveSentiment
                ? `rgba(43, 191, 107, ${a})`
                : `rgba(223, 58, 96, ${a})`;
            },
            backgroundColor: '#ffffff',
            hover: (item) => {
              if (item) {
                tippyInstance?.enable();
                tippyInstance?.show();
                const [word] = item;
                const count = Math.round(wordMap[word] * 100) / 100;
                tippyInstance?.setContent(`${word} (${count})`);
              } else {
                tippyInstance?.disable();
              }
            },
            // click: function (item, dim) {},
          });
          delay(() => {
            removeLoadingStatus([requestHash as string]);
            isRenderingOrLoading?.next(false);
          }, 1000);
        }
      }, 300);
    }

    return () => {
      // stop the renderring
      wcRefCurrent?.replaceChildren('');
      WordCloud.stop();
      if (tippyInstance) {
        tippyInstance.disable();
      }
      clearTimeout(timeout);
    };
  }, [
    width,
    height,
    data,
    requestHash,
    targetRef,
    isRenderingOrLoading,
    maxCount,
    positiveSentiment,
    minSize,
    maxSize,
    sizeDiff,
    avgFontSize,
    list,
    wordMap,
  ]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        // Setting height and width to 95% for the few cases where the words overflow a bit
        height: '95%',
        width: '95%',
        marginRight: 'auto',
        marginTop: '20px',
      }}
    >
      <div ref={wcRef} />
    </div>
  );
};

RlWordCloud.propTypes = {
  name: PropTypes.string.isRequired,
  positiveSentiment: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};

RlWordCloud.defaultProps = {
  name: 'default',
  positiveSentiment: true,
  data: [],
};

export default withResizeDetector(RlWordCloud, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
