import React from 'react';

const RoleIcon = () => {
  return (
    <svg
      id="Layer_1"
      className="animate-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.85 124.34"
      style={{
        position: 'absolute',
        width: '126px',
        height: '130px',
        bottom: -12,
        right: -12,
        transition: 'opacity 0.25s ease-in-out',
      }}
    >
      <path
        d="m12.45,123.34c6.32,0,11.45-5.18,11.45-11.57s-5.12-11.57-11.45-11.57-11.45,5.18-11.45,11.57,5.12,11.57,11.45,11.57Z"
        fill="#fff"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m36.55,74l-.03,12.98H15.27c-.87,0-1.7.35-2.31.97-.61.62-.96,1.46-.96,2.34v9.92"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m110.41,123.34c-6.32,0-11.45-5.18-11.45-11.57s5.12-11.57,11.45-11.57,11.45,5.18,11.45,11.57-5.12,11.57-11.45,11.57Z"
        fill="#fff"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m85.75,73.73c6.32,0,11.45-5.18,11.45-11.57s-5.12-11.57-11.45-11.57-11.45,5.18-11.45,11.57,5.12,11.57,11.45,11.57Z"
        fill="#fff"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m85.75,50.59v-9.92c0-.88-.34-1.72-.96-2.34-.61-.62-1.44-.97-2.31-.97h-21.26"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m85.88,73.75v13.22h21.26c.87,0,1.7.35,2.31.97.61.62.96,1.46.96,2.34v9.92"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m61.22,24.14v13.22h-21.26c-.87,0-1.7.35-2.31.97-.61.62-.96,1.46-.96,2.34v46.33h20.81c2.21,0,4,1.79,4,4v9.5"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        className="animate-path"
        d="m61.22,24.14v13.22h-21.26c-.87,0-1.7.35-2.31.97-.61.62-.96,1.46-.96,2.34v46.33h20.81c2.21,0,4,1.79,4,4v9.5"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        strokeDasharray={124}
        strokeDashoffset={124}
        style={{
          transition: 'stroke-dashoffset 0.35s ease',
        }}
      />
      <path
        d="m61.1,123.05c6.32,0,11.45-5.18,11.45-11.57s-5.12-11.57-11.45-11.57-11.45,5.18-11.45,11.57,5.12,11.57,11.45,11.57Z"
        fill="#5bd992"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m61.22,24.14c6.32,0,11.45-5.18,11.45-11.57s-5.12-11.57-11.45-11.57-11.45,5.18-11.45,11.57,5.12,11.57,11.45,11.57Z"
        fill="#5bd992"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m36.7,73.73c6.32,0,11.45-5.18,11.45-11.57s-5.12-11.57-11.45-11.57-11.45,5.18-11.45,11.57,5.12,11.57,11.45,11.57Z"
        fill="#5bd992"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default RoleIcon;
