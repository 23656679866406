import { isUndefined } from 'lodash';
import {
  Item,
  SelectionList,
  ValidValueTypes,
} from '../../../engine/filters.model';

/**
 * Given an item's parent id, finds that parent item, and
 * adds it to the accumulated results list if it is not
 * already there.
 *
 * @param itemParentId item id of parent to add
 * @param parentResultsList accumulated results list
 * @param referenceList original selection lists for reference
 *
 * @returns the parent item that was found, regardless of whether
 * it was added to the accumulated results list or not. Otherwise,
 * returns undefined.
 *
 */
export const addItemParentToSearchResults = (
  itemParentId: string,
  parentResultsList: SelectionList<ValidValueTypes>,
  referenceList: SelectionList<ValidValueTypes>
): Item | undefined => {
  const searchResultParent = referenceList?.value?.find(
    (list) => list.id == itemParentId
  );
  const hasParentItemInSearchResults = parentResultsList?.value?.find(
    (parentItem) => parentItem.id == searchResultParent?.id
  );

  if (!hasParentItemInSearchResults && searchResultParent) {
    parentResultsList.value.push(searchResultParent);
  }

  return searchResultParent as Item;
};

/**
 * Starts from item, follows parent chain, and tries to add the linked
 * parent items until top level is reached.
 *
 * @param item starting item
 * @param unfilteredList selection list containing starting item
 * @param searchLists accumulated seatch list
 * @param selectionLists original selection lists for reference
 *
 * @returns void
 */
export const addAllParentsToSearchResults = (
  item: Item,
  unfilteredList: SelectionList<ValidValueTypes>,
  searchLists: SelectionList<ValidValueTypes>[],
  selectionLists: SelectionList<ValidValueTypes>[]
) => {
  let parentList: SelectionList<ValidValueTypes> | undefined = unfilteredList;
  let itemParentId =
    unfilteredList.parent && item[unfilteredList.parent as string];

  /// TODO: temp fix for multi-parent items
  if (Array.isArray(itemParentId)) {
    itemParentId = itemParentId[0];
  }

  while (parentList && itemParentId) {
    const parentListId: string | undefined = parentList.parent;

    if (!parentListId) {
      return;
    }

    const parentSearchResultsList: SelectionList<ValidValueTypes> | undefined =
      searchLists.find((list: any) => list.id === parentListId);

    const unfilteredParentList: SelectionList<ValidValueTypes> | undefined =
      selectionLists.find((list: any) => list.id === parentListId);

    let parentItem;

    if (
      !isUndefined(parentSearchResultsList) &&
      !isUndefined(unfilteredParentList)
    ) {
      parentItem = addItemParentToSearchResults(
        itemParentId,
        parentSearchResultsList,
        unfilteredParentList
      );
    }

    parentList = parentSearchResultsList;
    itemParentId =
      unfilteredParentList?.parent &&
      parentItem &&
      parentItem[unfilteredParentList?.parent as string];
  }
};

export const searchNestedList = (
  search: string,
  unfilteredList: SelectionList<ValidValueTypes>
) => {
  const remappedParents = unfilteredList.value.map((item: any) => {
    // only keep the nested sublists that match
    if (Object.keys(item).includes('list')) {
      const sublist = item.list.filter((subItem: any) =>
        subItem.label.toLowerCase().includes(search)
      );
      return { ...item, list: sublist };
    } else {
      return item;
    }
  });

  const filteredParents = remappedParents.filter((parent: any) => {
    return (
      parent.label.toLowerCase().includes(search) ||
      (Object.prototype.hasOwnProperty.call(parent, 'list') &&
        parent.list.length > 0)
    );
  });

  return filteredParents;
};
