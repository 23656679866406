import React, { FC } from 'react';
import { OptionProps, components } from 'react-select';
import styles from './option.module.css';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { Option } from '../selection-list';
import { NodeMenu } from './node-menu';
import NodeFilterSetTooltipLabel from '../tree/node-filter-set-tooltip/node-filter-set-tooltip-label';
import { WithTooltip } from '@revelio/core';
import { FiAlertCircle } from 'react-icons/fi';
import { View } from '@revelio/data-access';

const SavedSetLabelLookup: Record<string, string[]> = {
  [View.Company]: ['Company pages except for Company Discovery'],
  [View.Geography]: ['Geography pages except for Geography Discovery'],
  [View.Role]: ['Role pages except for Role Discovery'],
  [View.CompanyScreener]: ['Company Discovery'],
  [View.GeographyScreener]: ['Geography Discovery'],
  [View.RoleScreener]: ['Role Discovery'],
  [View.TalentDiscovery]: ['Talent Discovery'],
};

export const SavedSetOption: FC<OptionProps<Option, true>> = (
  props: OptionProps<Option, true>
) => {
  const [nodeHovered, setNodeHovered] = React.useState(false);
  console.log({ props });
  return (
    <components.Option {...props}>
      <Flex
        className={classNames({ [styles.disabled]: props.isDisabled })}
        alignItems="center"
        w="100%"
        onMouseEnter={() => setNodeHovered(true)}
        onMouseLeave={() => setNodeHovered(false)}
      >
        <WithTooltip
          showCondition={props.isDisabled}
          padding="12px"
          fontSize="12px"
          minWidth="330px"
          maxWidth="330px"
          overflowWrap="normal"
          openDelay={1000}
          label={
            <NodeFilterSetTooltipLabel
              icon={FiAlertCircle}
              text="This filter is only available on "
              list={SavedSetLabelLookup[props.data.entity.view]}
            />
          }
          aria-label={`This filter is only available on ${
            SavedSetLabelLookup[props.data.entity.view]
          }`}
        >
          <Flex w="100%">
            <Checkbox
              isChecked={props.isSelected}
              onChange={() => null}
              colorScheme="green"
              w="100%"
              isDisabled={props.isDisabled}
            >
              <Text fontSize="xs">{props.label}</Text>
            </Checkbox>
          </Flex>
        </WithTooltip>
        {!props.isDisabled && (
          <Flex
            ml="auto"
            className={nodeHovered ? styles.visible : styles.hidden}
          >
            <NodeMenu node={props.data.entity} />
          </Flex>
        )}
      </Flex>
    </components.Option>
  );
};
