import { LoaderType, PageTitles, UserTrackingEvents } from '@revelio/core';
import { useLifecycles, useSearchParam } from 'react-use';
import DashboardPage from '../DashboardPage';
import { Center } from '@chakra-ui/react';
import { ReportIframe } from './components/report-iframe';
import mixpanel from 'mixpanel-browser';

const SampleReportPage = () => {
  const link = useSearchParam('link');
  const sampleLink = link && decodeURIComponent(link);
  const title = [PageTitles.REPORTS_SAMPLE];
  useLifecycles(
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
        page: title.join('/'),
        sampleLink,
      });
      mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    },
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: title.join('/'),
      });
    }
  );

  if (!sampleLink) {
    return (
      <Center h="100%" w="100%">
        Sorry, we could not find this resource.
      </Center>
    );
  }

  return (
    <DashboardPage title={title} loading={false} loader={LoaderType.MANUAL}>
      <ReportIframe link={sampleLink} />
    </DashboardPage>
  );
};

export default SampleReportPage;
