// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3.ts';
import { GroupedBarChartHorizontalGenerator } from '../plot-generators';

const GroupedBarChartHorizontal = (plotConfigs) => {
  const {
    name,
    data,
    height,
    width,
    targetRef,
    colorLookup,
    customMargins,
    setChartHasRendered,
  } = plotConfigs;

  const setBarChartHasRendered = setChartHasRendered
    ? (hasRendered) => setChartHasRendered(name, hasRendered)
    : undefined;

  useD3(
    GroupedBarChartHorizontalGenerator,
    { ...plotConfigs, setChartHasRendered: setBarChartHasRendered },
    [targetRef.current, data, height, width, colorLookup, customMargins]
  );

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

GroupedBarChartHorizontal.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  chartStyle: PropTypes.string.isRequired,
};

GroupedBarChartHorizontal.defaultProps = {
  name: 'default',
  heading: 'default',
  data: [],
  ttMainFormat: '+.2f',
  ttSecondaryFormat: ',',
  chartStyle: '',
};

export default withResizeDetector(GroupedBarChartHorizontal, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
