import React from 'react';
import { Icon } from '@chakra-ui/react';

const UploadIcon = () => {
  return (
    <Icon viewBox="0 0 36 45" fill="none" boxSize={12} mb={2}>
      <path
        d="M20.125 1H5.25C4.12283 1 3.04183 1.44777 2.2448 2.2448C1.44777 3.04183 1 4.12283 1 5.25V39.25C1 40.3772 1.44777 41.4582 2.2448 42.2552C3.04183 43.0522 4.12283 43.5 5.25 43.5H30.75C31.8772 43.5 32.9582 43.0522 33.7552 42.2552C34.5522 41.4582 35 40.3772 35 39.25V15.875L20.125 1Z"
        fill="white"
        stroke="#2D426A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.125 2V15.875H34" fill="#E5EBF1" />
      <path
        d="M20.125 2V15.875H34"
        stroke="#2D426A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3872 33.1055L17.8925 29.6109L14.3978 33.1055"
        stroke="#2D426A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8926 29.6109V37.4739"
        stroke="#2D426A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2226 35.1936C26.0748 34.7291 26.7479 33.994 27.1359 33.1044C27.5238 32.2147 27.6044 31.2213 27.3651 30.2807C27.1257 29.3402 26.5799 28.5061 25.8138 27.9102C25.0478 27.3143 24.1051 26.9905 23.1346 26.9899H22.0337C21.7693 25.967 21.2764 25.0174 20.5921 24.2125C19.9079 23.4075 19.05 22.7682 18.0831 22.3425C17.1162 21.9168 16.0653 21.7158 15.0095 21.7547C13.9538 21.7936 12.9206 22.0714 11.9876 22.5671C11.0546 23.0628 10.2461 23.7635 9.62294 24.6166C8.99976 25.4697 8.57809 26.453 8.38963 27.4926C8.20117 28.5321 8.25082 29.6009 8.53486 30.6184C8.8189 31.636 9.32993 32.576 10.0295 33.3677"
        stroke="#2D426A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3872 33.1055L17.8925 29.6109L14.3978 33.1055"
        stroke="#2D426A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default UploadIcon;
