import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb as ChakraBreadcrumb,
} from '@chakra-ui/react';

export type BreadcrumbProps = {
  crumbs: string[];
  currentLevel: number;
  forceAllCrumbs?: boolean;
  setCurrentLevel: (level: number) => void;
};
export const Breadcrumb = ({
  crumbs,
  currentLevel,
  forceAllCrumbs = false,
  setCurrentLevel,
}: BreadcrumbProps) => {
  const crumbsToRender = (() => {
    if (forceAllCrumbs) return crumbs;
    else if (currentLevel < 0) return crumbs.slice(0, 1);
    else return crumbs.slice(0, currentLevel + 1);
  })();

  return (
    <ChakraBreadcrumb
      fontSize="xs"
      fontWeight="600"
      letterSpacing={0.8}
      spacing={0.5}
      separator={<ChevronRightIcon boxSize={3} color="#96a0b5" />}
    >
      {crumbsToRender.map((crumb, i) => (
        <BreadcrumbItem
          key={i}
          isCurrentPage={forceAllCrumbs || i === crumbsToRender.length - 1}
          onClick={() => !forceAllCrumbs && setCurrentLevel(i)}
        >
          <BreadcrumbLink
            {...(i !== crumbsToRender.length - 1 && { color: '#96a0b5' })}
          >
            {crumb}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  );
};
