import { useContext, useMemo, useState } from 'react';
import { BarChartHorizontal, D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import {
  COMPETITOR_BAR_CHART_NAME,
  getCompetitorBarChartProps,
} from './utils/get-competitor-bar-chart-props';
import { getCompetitorCompositionData } from './utils/get-competitor-composition-data';
import {
  CompanySummaryMetric,
  CompetitorBarChartsData,
  SingleOption,
} from '../../types';
import { PlotLayoutCard } from '../../../../shared/components/plots/plot-layout-card';
import { PlotActionMenu } from '../../../../shared/components/plots/plot-action-menu';
import { StyledPlotSelect } from '../../../../shared/components/plots/styled-plot-select';
import { Text } from '@chakra-ui/react';
import { ChartsRenderedContext } from '../../shared/charts-rendered-context';

interface CompetitorBarChartsProps {
  compositionData: CompositionDataQuery | undefined;
  primaryRCID: number;
}

export const CompetitorBarCharts = ({
  compositionData,
  primaryRCID,
}: CompetitorBarChartsProps) => {
  const competitorData: CompetitorBarChartsData = useMemo(
    () => getCompetitorCompositionData(compositionData, primaryRCID),
    [compositionData, primaryRCID]
  );

  const [selectedChart, setSelectedChart] = useState<SingleOption>({
    value: 'headcount',
    label: 'Headcount',
  });

  const chartProps = useMemo(
    () => getCompetitorBarChartProps(competitorData, selectedChart),
    [competitorData, selectedChart]
  );

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  if (!chartProps?.data?.length) {
    return null;
  }

  return (
    <PlotLayoutCard
      title="Competitors"
      plotInfo={{
        plotInfoBody: (
          <Text color="white">
            Competitor headcount, attrition rate, growth rate, and hiring rate
          </Text>
        ),
        plotInfoConfig: {
          popoverPlacement: 'right',
          iconBoxSize: 2.5,
        },
      }}
      topRight={
        <StyledPlotSelect
          value={selectedChart}
          onChange={(value) => setSelectedChart(value)}
          options={[
            { value: CompanySummaryMetric.Headcount, label: 'Headcount' },
            {
              value: CompanySummaryMetric.AttritionRate,
              label: 'Attrition Rate',
            },
            {
              value: CompanySummaryMetric.GrowthRate,
              label: 'Growth Rate',
            },
            {
              value: CompanySummaryMetric.HiringRate,
              label: 'Hiring Rate',
            },
          ]}
        />
      }
      menu={
        <PlotActionMenu
          title="Competitors"
          chartType={D3ChartNames.BarChartHorizontal}
          chartProps={chartProps as ID3ChartProps}
          expandedPlot={
            <BarChartHorizontal
              {...chartProps}
              name={`Expanded ${chartProps.name}`}
            />
          }
          pr={2}
        />
      }
    >
      <BarChartHorizontal
        {...chartProps}
        name={COMPETITOR_BAR_CHART_NAME}
        setChartHasRendered={setChartHasRendered}
        customMargins={{
          top: 5,
          left: 90,
          bottom: 10,
          right: 60,
        }}
      />
    </PlotLayoutCard>
  );
};
