import { graphql } from '@revelio/data-access';

export const GET_COMPENSATION_HELPER_DATA = graphql(
  `
    query GetCompensationData(
      $filters: CompensationFilters
      $dim1: Dimension1
      $dim2: CompensationDimension
    ) {
      compensation(filters: $filters, dim1: $dim1, dim2: $dim2) {
        metadata {
          id
          shortName
        }

        category {
          metadata {
            id
            shortName
          }
          metrics {
            count
            share
          }
        }
      }
    }
  `
);
