import React, { useContext, useState } from 'react';
import { LoaderType, PagePaths, PageTitles } from '@revelio/core';
import { Box, Stack, StackDivider } from '@chakra-ui/layout';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useToast,
} from '@chakra-ui/react';
import DashboardPage from '../../DashboardPage';
import SheetSelectionStep from '../file-upload/sheet-selection-step';
import MappingStep from '../file-upload/mapping-step';
import ConfirmationStep from './ConfirmationStep';
import CompletedStep from './CompletedStep';
import {
  BaseStepsInterface,
  SelectedOptionsInterface,
} from './types/dataEnrichmentTypes';
import { processFile } from './utils/processFile';
import { DeliverableStepContainer } from '../deliverable-step-container';
import { DataInterface } from '../file-upload/file-upload.types';
import UploadFileStep from '../file-upload/upload-file-step';
import HeaderSelectionStep from '../file-upload/header-selection-step';
import {
  FileUploadContext,
  FileUploadProvider,
} from '../file-upload/file-upload-context';
import { DeliverablePaths } from '../deliverables-page';
import { useAuthStore } from '@revelio/auth';

const DeliverablesDataEnrichmentPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<
    SelectedOptionsInterface[]
  >([]);

  const { dashMetaCsrfToken: csrfState = 'missing' } = useAuthStore();

  const baseSteps: BaseStepsInterface[] = [
    {
      title: 'Upload File',
      description: 'CSV or Excel',
      content: () => <UploadFileStepWithContext csrfState={csrfState} />,
    },
    {
      title: 'Select header row',
      description: '',
      content: () => (
        <HeaderSelectionStep
          ContainerElement={({ onSubmit, children }) => {
            return (
              <DeliverableStepContainer
                id="header_selection"
                startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
                title="Header Selection"
                submitButtonContent="Select"
                onSubmit={onSubmit}
                disableFade
                isDataBuilder={false}
              >
                {children}
              </DeliverableStepContainer>
            );
          }}
        />
      ),
    },
    {
      title: 'Map Columns',
      description: '',
      content: () => (
        <MapColumnsWithContext
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      ),
    },
    {
      title: 'Confirmation',
      description: '',
      content: () => (
        <ConfirmationStep
          loading={loading}
          setLoading={setLoading}
          csrfState={csrfState}
        />
      ),
    },
  ];

  return (
    <FileUploadProvider
      baseSteps={baseSteps}
      sheetSelectionStep={() => (
        <SheetSelectionStep
          ContainerElement={({ onSubmit, children }) => (
            <DeliverableStepContainer
              id="select_sheet"
              title="Select Sheet"
              startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
              submitButtonContent="Select"
              onSubmit={onSubmit}
              disableFade
              isDataBuilder={false}
            >
              {children}
            </DeliverableStepContainer>
          )}
        />
      )}
    >
      <DataEnrichmentWorkflow setSelectedOptions={setSelectedOptions} />
    </FileUploadProvider>
  );
};

const DataEnrichmentWorkflow = ({
  setSelectedOptions,
}: {
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<SelectedOptionsInterface[]>
  >;
}) => {
  const {
    activeStep,
    setActiveStep,
    steps,
    isCompleteStep,
    setData: setFileData,
  } = useContext(FileUploadContext);

  const stepsCount = steps.length;

  const isFinalStep = activeStep === stepsCount;

  return (
    <DashboardPage
      title={[PageTitles.DELIVERABLES, PageTitles.DATA_ENRICHMENT]}
      loader={LoaderType.MANUAL}
    >
      <Box
        bg="white"
        rounded="lg"
        h="calc(100vh - 100px)"
        data-testid="data-enrichment-box-container"
      >
        <Stack h="100%" direction="row" divider={<StackDivider />} spacing="0">
          <Box width="240px" height="100%" padding="32px 24px">
            <Stepper
              colorScheme="green"
              index={activeStep}
              orientation="vertical"
              height="80%"
              gap="0"
              size="md"
            >
              {steps.map((step: BaseStepsInterface, index: number) => (
                <Step
                  key={index}
                  onClick={() => {
                    const threshold1 = stepsCount === 5 ? 5 : 4;
                    const threshold2 = stepsCount === 5 ? 2 : 1;
                    if (activeStep < threshold1) {
                      if (index > threshold2 && isCompleteStep(index)) {
                        setActiveStep(index);
                      }
                    }
                  }}
                >
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Box>
          {isFinalStep ? (
            <CompletedStep
              setData={setFileData}
              setActiveStep={setActiveStep}
              setSelectedOptions={setSelectedOptions}
            />
          ) : (
            steps[activeStep].content()
          )}
        </Stack>
      </Box>
    </DashboardPage>
  );
};

const UploadFileStepWithContext = ({ csrfState }: { csrfState: string }) => {
  const { setData: setFileData, goToNext } = useContext(FileUploadContext);
  const toast = useToast();
  return (
    <UploadFileStep
      requiredColumnsTooltip="First Name, Last Name, Title"
      sampleFileUrl="https://rl-images-2.s3.us-east-2.amazonaws.com/dashboard/date-enrichment-sample-file.csv"
      renderInDeliverablesContainer={true}
      onUpload={async (file: File) =>
        await processFile(file, setFileData, goToNext, csrfState, toast)
      }
    />
  );
};

const MapColumnsWithContext = ({
  selectedOptions,
  setSelectedOptions,
}: {
  selectedOptions: SelectedOptionsInterface[];
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<SelectedOptionsInterface[]>
  >;
}) => {
  const {
    headers,
    setData: setFileData,
    goToNext,
  } = useContext(FileUploadContext);

  const handleGetSelectedValues = () => {
    const mappedValues = selectedOptions
      .map(
        (option: SelectedOptionsInterface, index: number) =>
          option && {
            [headers[index]]: option?.value,
          }
      )
      .filter((item) => {
        return item !== null;
      });
    setFileData((prevData: DataInterface) => ({
      ...prevData,
      mappedColumns: mappedValues as { [arg0: string]: string }[],
    }));
    goToNext();
  };

  return (
    <MappingStep
      onSubmit={handleGetSelectedValues}
      columnOptions={[
        // { label: 'First Name', value: 'first_name' },
        // { label: 'Last Name', value: 'last_name' },
        { label: 'Location', value: 'location' },
        { label: 'Company Name', value: 'company_name' },
        { label: 'Title', value: 'title' },
        { label: 'Job Description', value: 'job_description' },
        { label: 'Expertise', value: 'expertise' },
        { label: 'Start Date', value: 'startdate' },
        { label: 'End Date', value: 'enddate' },
      ]}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      ContainerElement={({ onSubmit, children }) => (
        <DeliverableStepContainer
          id="map_columns"
          startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
          title="Map Columns"
          submitButtonContent="Select"
          onSubmit={onSubmit}
          isDataBuilder={false}
        >
          <Box pb={'56'}>{children}</Box>
        </DeliverableStepContainer>
      )}
      UploadContext={FileUploadContext}
    />
  );
};

export default DeliverablesDataEnrichmentPage;
