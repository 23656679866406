import { startCasePreserveChars } from '@revelio/core';

export const generateHTMLBoxplot = (
  d,
  valueMapping,
  d3Format,
  ttMainFormat,
  ttSecondaryFormat
) => {
  let myArray = [];
  var percentileMapping = {
    percentile10: '10th percentile',
    percentile25: '25th percentile',
    percentile50: '50th percentile',
    percentile75: '75th percentile',
    percentile90: '90th percentile',
  };

  myArray.push(
    `<span class="title" style="font-size: 13px">${startCasePreserveChars(
      d.key
    )}</span><br>`
  );
  myArray.push(
    `<span class="title" style="font-size: 11px">${d.group}</span><br><br>`
  );

  // NOTE: commented out since we don't have sample size data yet
  // myArray.push(`<div class="row">
  //     <div class="tt-title">Sample Size</div>
  //     <div class="tt-count">${d3Format(ttSecondaryFormat)(
  //       d.value.count
  //     )}</div></div><br>`);

  Object.keys(d.value).forEach((item) => {
    const isRelevantPercentile = item in percentileMapping;

    if (isRelevantPercentile) {
      myArray.push(
        `<div class="row">
      <div class="tt-title">${percentileMapping[item]}</div>
      <div class="tt-count">${d3Format(ttMainFormat)(
        d.value[item]
      )}</div></div>`
      );
    }
  });

  myArray.push('</div>');

  const joined = myArray.join('');
  // return `<div>${d[1] - d[0]} and ${d.key} and ${d.data[d.key]}</div>`;
  return joined;
};
