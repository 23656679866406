import { RowRendererProps } from 'react-arborist';
import { TreeData, TreeSelectionDrillProps } from './tree-selection';

export const TreeRow = ({
  attrs,
  node,
  drillMode,
  rowHeight = 24,
  children,
}: RowRendererProps<TreeData> & {
  drillMode?: TreeSelectionDrillProps;
  rowHeight?: number;
}) => {
  const isDrillModeEnabled = drillMode?.isEnabled;

  if (!isDrillModeEnabled) return <div {...attrs}>{children}</div>;

  const startingId = drillMode?.drilledIds[0];
  const startingIndex = node.tree.idToIndex[startingId];

  const top = (() => {
    const topStyle = attrs.style?.top;
    if (!topStyle) return 0;
    const topNumber =
      typeof topStyle === 'number' ? topStyle : parseInt(topStyle);
    return topNumber - startingIndex * rowHeight;
  })();

  const style = { ...attrs.style, top };

  return (
    <div {...attrs} style={style}>
      {children}
    </div>
  );
};
