import { Box, Flex, Text } from '@chakra-ui/layout';
import {
  LOADING_NODE,
  NO_RESULTS_NODE,
  PLACEHOLDER_NODE,
} from '../async-tree.constants';
import { Tag } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import nodeStyles from '../../node/node.module.css';
import { Item } from '../../../../engine/filters.model';
import { has } from 'lodash';
import NodeToggleButton from '../../node-toggle-button/node-toggle-button';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { NoDataNode } from './no-data-node';

export interface AsyncTreeClickableNodeProps {
  data: {
    id: string;
    item: Item;
    nestingLevel: number;
    name: string;
    showHeadcount?: boolean;
    isLeaf?: boolean;
    select: (item: Item) => void;
  };
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  style: React.CSSProperties;
}

export function AsyncTreeClickableNode({
  data: {
    id,
    item,
    nestingLevel,
    showHeadcount = true,
    isLeaf = false,
    select,
  },
  isOpen,
  setOpen,
  style,
}: AsyncTreeClickableNodeProps) {
  if ([PLACEHOLDER_NODE, LOADING_NODE, NO_RESULTS_NODE].includes(id)) {
    return <NoDataNode id={id} />;
  }

  const toggleExpandNode = () => {
    if (!isLeaf) setOpen(!isOpen);
    if (isLeaf) select(item);
  };

  return (
    <div
      className={cx(nodeStyles.highlight, nodeStyles.itemContainer)}
      role="group"
      data-testid="async-tree-search-result"
      style={style}
    >
      <Flex alignItems="center" onClick={toggleExpandNode} w="100%">
        <Box width={isLeaf ? '0' : '6'} marginLeft={nestingLevel * 4}>
          {!isLeaf && (
            <NodeToggleButton
              icon={isOpen ? ChevronDownIcon : ChevronRightIcon}
            />
          )}
        </Box>

        <Flex
          className={cx(nodeStyles.nodeText)}
          justifyContent="space-between"
          w="100%"
          data-testid={`${item?.primary_name || item.label}_tree_item`}
        >
          <Text as="span" userSelect="none" className={nodeStyles.nodeLabel}>
            {item?.primary_name || item.label}
          </Text>
          {showHeadcount && has(item, 'raw_emp_count') && (
            <Flex justifyContent="center">
              <Tag
                size="xs"
                background="gray.100"
                borderRadius="100"
                padding="0 12px"
                marginLeft="8px"
                variant="subtle"
              >
                <Text
                  fontSize="10"
                  color="navyBlue.500"
                  justifyContent="center"
                >
                  {Intl.NumberFormat('en').format(item.raw_emp_count)}
                </Text>
              </Tag>
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  );
}
