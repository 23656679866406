import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { emitMixpanelUserEvent } from '../../utils/mixpanel-user-events';
import { UserTrackingEvents } from '../logger/analytics';
export interface WithTooltipProps extends TooltipProps {
  showCondition: boolean;
  children: React.ReactNode;
}

export const WithTooltip = (props: WithTooltipProps) => {
  const {
    showCondition,
    children,
    modifiers = [],
    ...chakraTooltipProps
  } = props;
  return showCondition ? (
    <Tooltip
      variant="info"
      hasArrow={true}
      placement="top"
      onOpen={() => {
        emitMixpanelUserEvent(UserTrackingEvents.TOOLTIP_OPEN);
      }}
      modifiers={[
        {
          name: 'computeStyles',
          options: { adaptive: false },
        },
        ...modifiers,
      ]}
      {...chakraTooltipProps}
    >
      {children}
    </Tooltip>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
