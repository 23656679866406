export const scoreMap: { ptile: number; score: number }[] = [
  {
    ptile: 0,
    score: -1.931549,
  },
  {
    ptile: 1,
    score: -1.374769,
  },
  {
    ptile: 2,
    score: -1.274447,
  },
  {
    ptile: 3,
    score: -1.180638,
  },
  {
    ptile: 4,
    score: -1.106448,
  },
  {
    ptile: 5,
    score: -1.034326,
  },
  {
    ptile: 6,
    score: -0.983141,
  },
  {
    ptile: 7,
    score: -0.935401,
  },
  {
    ptile: 8,
    score: -0.897156,
  },
  {
    ptile: 9,
    score: -0.851716,
  },
  {
    ptile: 10,
    score: -0.822739,
  },
  {
    ptile: 11,
    score: -0.772987,
  },
  {
    ptile: 12,
    score: -0.733539,
  },
  {
    ptile: 13,
    score: -0.668414,
  },
  {
    ptile: 14,
    score: -0.605983,
  },
  {
    ptile: 15,
    score: -0.562286,
  },
  {
    ptile: 16,
    score: -0.514243,
  },
  {
    ptile: 17,
    score: -0.475677,
  },
  {
    ptile: 18,
    score: -0.451322,
  },
  {
    ptile: 19,
    score: -0.423262,
  },
  {
    ptile: 20,
    score: -0.397577,
  },
  {
    ptile: 21,
    score: -0.378005,
  },
  {
    ptile: 22,
    score: -0.354587,
  },
  {
    ptile: 23,
    score: -0.332638,
  },
  {
    ptile: 24,
    score: -0.309614,
  },
  {
    ptile: 25,
    score: -0.295403,
  },
  {
    ptile: 26,
    score: -0.277222,
  },
  {
    ptile: 27,
    score: -0.259929,
  },
  {
    ptile: 28,
    score: -0.243478,
  },
  {
    ptile: 29,
    score: -0.223474,
  },
  {
    ptile: 30,
    score: -0.207799,
  },
  {
    ptile: 31,
    score: -0.189494,
  },
  {
    ptile: 32,
    score: -0.174775,
  },
  {
    ptile: 33,
    score: -0.157402,
  },
  {
    ptile: 34,
    score: -0.138933,
  },
  {
    ptile: 35,
    score: -0.125144,
  },
  {
    ptile: 36,
    score: -0.11352,
  },
  {
    ptile: 37,
    score: -0.099347,
  },
  {
    ptile: 38,
    score: -0.086453,
  },
  {
    ptile: 39,
    score: -0.071433,
  },
  {
    ptile: 40,
    score: -0.06039,
  },
  {
    ptile: 41,
    score: -0.046551,
  },
  {
    ptile: 42,
    score: -0.035043,
  },
  {
    ptile: 43,
    score: -0.021936,
  },
  {
    ptile: 44,
    score: -0.011487,
  },
  {
    ptile: 45,
    score: -0.000011,
  },
  {
    ptile: 46,
    score: 0.009664,
  },
  {
    ptile: 47,
    score: 0.023169,
  },
  {
    ptile: 48,
    score: 0.036271,
  },
  {
    ptile: 49,
    score: 0.046597,
  },
  {
    ptile: 50,
    score: 0.057142,
  },
  {
    ptile: 51,
    score: 0.070106,
  },
  {
    ptile: 52,
    score: 0.081432,
  },
  {
    ptile: 53,
    score: 0.09243,
  },
  {
    ptile: 54,
    score: 0.105327,
  },
  {
    ptile: 55,
    score: 0.11692,
  },
  {
    ptile: 56,
    score: 0.12714,
  },
  {
    ptile: 57,
    score: 0.142524,
  },
  {
    ptile: 58,
    score: 0.150978,
  },
  {
    ptile: 59,
    score: 0.166893,
  },
  {
    ptile: 60,
    score: 0.180014,
  },
  {
    ptile: 61,
    score: 0.192617,
  },
  {
    ptile: 62,
    score: 0.205702,
  },
  {
    ptile: 63,
    score: 0.220742,
  },
  {
    ptile: 64,
    score: 0.234026,
  },
  {
    ptile: 65,
    score: 0.249934,
  },
  {
    ptile: 66,
    score: 0.26576,
  },
  {
    ptile: 67,
    score: 0.27937,
  },
  {
    ptile: 68,
    score: 0.294609,
  },
  {
    ptile: 69,
    score: 0.311654,
  },
  {
    ptile: 70,
    score: 0.331028,
  },
  {
    ptile: 71,
    score: 0.347123,
  },
  {
    ptile: 72,
    score: 0.36944,
  },
  {
    ptile: 73,
    score: 0.387959,
  },
  {
    ptile: 74,
    score: 0.405653,
  },
  {
    ptile: 75,
    score: 0.425955,
  },
  {
    ptile: 76,
    score: 0.442839,
  },
  {
    ptile: 77,
    score: 0.463627,
  },
  {
    ptile: 78,
    score: 0.48184,
  },
  {
    ptile: 79,
    score: 0.506589,
  },
  {
    ptile: 80,
    score: 0.527699,
  },
  {
    ptile: 81,
    score: 0.548281,
  },
  {
    ptile: 82,
    score: 0.574069,
  },
  {
    ptile: 83,
    score: 0.594609,
  },
  {
    ptile: 84,
    score: 0.619248,
  },
  {
    ptile: 85,
    score: 0.640134,
  },
  {
    ptile: 86,
    score: 0.667787,
  },
  {
    ptile: 87,
    score: 0.69332,
  },
  {
    ptile: 88,
    score: 0.727043,
  },
  {
    ptile: 89,
    score: 0.763171,
  },
  {
    ptile: 90,
    score: 0.798482,
  },
  {
    ptile: 91,
    score: 0.844914,
  },
  {
    ptile: 92,
    score: 0.89373,
  },
  {
    ptile: 93,
    score: 0.945147,
  },
  {
    ptile: 94,
    score: 0.997372,
  },
  {
    ptile: 95,
    score: 1.066357,
  },
  {
    ptile: 96,
    score: 1.169453,
  },
  {
    ptile: 97,
    score: 1.333416,
  },
  {
    ptile: 98,
    score: 1.497135,
  },
  {
    ptile: 99,
    score: 1.770971,
  },
  {
    ptile: 100,
    score: 4.069937,
  },
];
