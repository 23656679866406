import { ScreenerSubFilterState } from '../screener-filter-reducer';

export const getSegmentsText = (
  segments: ScreenerSubFilterState['treeItems']
) => {
  const selectedSegments = Object.values(segments ?? {})
    .map((segment) => segment?.item?.shortName)
    .filter((name): name is string => !!name);

  if (selectedSegments.length === 0) return 'All Employees';
  if (selectedSegments.length > 2) {
    const first2Segments = selectedSegments.slice(0, 2).join(', ');
    const remainingSegmentsCount = selectedSegments.length - 2;
    const otherText = remainingSegmentsCount === 1 ? 'other' : 'others';

    return `${first2Segments}, and ${remainingSegmentsCount} ${otherText}`;
  }

  return selectedSegments.join(', ');
};
