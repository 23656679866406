import { Views } from '@revelio/core';
import { AnyFilter } from '../../engine/filters.model';
import { getFilterItems, getScreenerFilterItems } from './helpers.config';
import { GetFilterParams } from './types';

enum WorkFlows {
  SCREENER = 'SCREENER',
  DEFAULT = 'DEFAULT',
}

const handler: Record<
  WorkFlows,
  Record<string, (params: GetFilterParams) => AnyFilter[]>
> = {
  DEFAULT: {
    filter: getFilterItems,
  },
  SCREENER: {
    filter: getScreenerFilterItems,
  },
};

/**
 * get workflow handler corresponding to input selection
 *
 * @param view - the view in which the filter chips are in
 *
 * @returns workflow handler object
 */
export const getWorkflow = (view: Views | undefined) => {
  const key: WorkFlows =
    view === Views.SCREENER ? WorkFlows.SCREENER : WorkFlows.DEFAULT;

  return handler[key];
};
