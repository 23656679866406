export const nestedBreadcrumbLookup: { [key: string]: string } = {
  region: 'Region',
  country: 'Country',
  state: 'State',
  metro_area: 'Metro Area',
};

export const convertListIdBreadcrumb = (listIds: string[]): string[] => {
  return listIds.map((listId) => nestedBreadcrumbLookup?.[listId] || listId);
};
