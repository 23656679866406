import { Box, BoxProps } from '@chakra-ui/react';

export const AdminCard = (props: BoxProps) => (
  <Box
    bg="white"
    shadow="sm"
    rounded="lg"
    p={{ base: '5', md: '6', lg: '8' }}
    {...props}
  />
);

export default AdminCard;
