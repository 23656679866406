import { Flex } from '@chakra-ui/react';
import DownloadResumeButton from './DownloadResumeButton';
import { ParsedResume } from './resumeTypes';
// import { useParams } from 'react-router';

interface EnrichedResumeActionButtonsProps {
  openJobPostings: () => void;
  resume: null | ParsedResume;
}

function EnrichedResumeActionButtons({
  openJobPostings,
  resume,
}: EnrichedResumeActionButtonsProps) {
  // const params = useParams();
  return (
    <Flex gap="10px">
      {resume && (
        <DownloadResumeButton
          resumeId={resume.id}
          resumeName={resume.contact_information.raw.name}
          iconButton={false}
        />
      )}
      {/* <Button
        size="sm"
        variant="outline"
        backgroundColor="white"
        border="none"
        color="navyBlue.500"
        leftIcon={<LinkIcon />}
        onClick={openJobPostings}
      >
        Matched Jobs
      </Button> */}
    </Flex>
  );
}

export default EnrichedResumeActionButtons;
