import {
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  ListProps,
  Text,
} from '@chakra-ui/layout';
import { CircleIcon } from '@revelio/layout';
import { useReportBuilderConfig } from '../utils/useReportBuilderConfig';
import { ReportBuilderDeliverable } from '../report-builder.model';
import { groupBy } from 'lodash';
import {
  EntityType,
  ReportEntityConfigColumn,
} from '../entity-configuration/utils';

interface ReportSummaryProps {
  report: ReportBuilderDeliverable | null | undefined;
}

export const ReportSummary = ({ report }: ReportSummaryProps) => {
  const reportEntityMap = useReportBuilderConfig().config;

  if (!report || !reportEntityMap) return <Text>No Report Found</Text>;

  const selectedReportConfig = reportEntityMap[report.reportType];
  const reportType = selectedReportConfig?.columns.find(
    (column) => column?.id === report.reportType
  )?.label;

  const entityGroups = groupBy(
    reportEntityMap[report.reportType].columns,
    (e) => e.reportEntityOptions.entityType
  );

  const entities = [
    {
      label: 'Focus Entities',
      selections: entityGroups[EntityType.MainEntity],
    },
    {
      label: 'Comparison Entities',
      selections: entityGroups[EntityType.ComparisonEntity],
    },
    { label: 'Filters', selections: entityGroups[EntityType.FilterEntity] },
  ].filter((entity) => entity.selections);

  return (
    <>
      <Flex>
        <Heading fontWeight={600} fontSize="16px" color="text.primary" mb={8}>
          {reportType} Report
        </Heading>
      </Flex>
      {entities.map((entity) => (
        <EntityList
          entity={entity}
          key={entity.label}
          report={report}
          mb={4}
          ml={10}
        />
      ))}
    </>
  );
};

interface EntityListProps extends ListProps {
  entity: {
    label: string;
    selections: ReportEntityConfigColumn[];
  };
  report: ReportBuilderDeliverable;
}

export const EntityList = ({
  entity,
  report,
  ...restProps
}: EntityListProps) => {
  return (
    <List spacing={2} {...restProps}>
      <Text>{entity.label}</Text>
      {entity.selections.map((entity, index) => (
        <ListItem key={entity.id} pl={10}>
          <Flex alignItems="center">
            <ListIcon as={CircleIcon} color="green.500" boxSize={2} mt="5px" />
            <Text fontWeight={600}>Type: {entity.label}</Text>
          </Flex>
          {Object.keys(report.selections[entity.id] || {}).map(
            (selectionKey, i) => {
              const selectionItem = report.selections[entity.id][selectionKey];
              return (
                <ListItem key={i} pl={10}>
                  <Flex alignItems="center">
                    <ListIcon
                      as={CircleIcon}
                      color="green.500"
                      boxSize={2}
                      mt="5px"
                    />
                    <Text>{selectionItem.item?.label}</Text>
                  </Flex>
                </ListItem>
              );
            }
          )}
        </ListItem>
      ))}
    </List>
  );
};
