import { useUserTrialDetails } from '@revelio/auth';
import { DeliverablesPage } from './deliverables-page';
import { PageTitles } from '@revelio/core';
import { DeliverablesTrialPage } from './trial-pages/deliverables-trial-page';

export const Deliverables = () => {
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  if (isTrialUser) {
    return <DeliverablesTrialPage />;
  } else {
    return <DeliverablesPage pageTitle={PageTitles.DATA_BUILDER} />;
  }
};
