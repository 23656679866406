import { Box, Text } from '@chakra-ui/layout';
// import { Button } from '@chakra-ui/react';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { formatWithCommas } from '../helpers';
import { EducationEntryRaw } from '../resumeTypes';

interface EducationRawProps {
  data?: EducationEntryRaw;
  variant?: string;
}

export default function EducationRaw({ data, variant }: EducationRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionDate']}>
        {data?.start_date} - {data?.end_date}
      </Text>
      <Text sx={styles['sectionTitle']}>{data?.degree_name}</Text>
      <Text sx={styles['sectionDetails']}>
        {formatWithCommas([data?.institution_name, data?.location])}
        {data?.field_of_study && <span> ({data?.field_of_study})</span>}
      </Text>

      <Text sx={styles['sectionDetails']} mt="5px">
        {data?.description}
      </Text>
      {/* <Button variant="outlined" color="lightBlue.500" p="0" size="xs">
        View More
      </Button> */}
    </Box>
  );
}
