import { PrimaryView } from '@revelio/core';
import { Dimension1 } from '@revelio/data-access';

export const getDim1FromView = (view: PrimaryView): Dimension1 => {
  switch (view) {
    case PrimaryView.COMPANY:
      return Dimension1.Company;
    case PrimaryView.GEOGRAPHY:
      return Dimension1.Geography;
    case PrimaryView.ROLE:
      return Dimension1.Role;
  }
};
