import { Box } from '@chakra-ui/layout';
import {
  NodeData,
  TreeApiProps,
  TreeSelection,
  TreeSelectionProps,
  useTreeApi,
} from '../tree';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Breadcrumb } from './breadcrumb';
import { NodeApi } from 'react-arborist';

export type BreadcrumbTreeProps = {
  data: TreeSelectionProps['data'];
  branches: string[];
  onChange?: (selections: string[]) => void;
} & Pick<TreeApiProps, 'defaultSelections'>;

export const BreadcrumbTree = ({
  data,
  branches,
  defaultSelections,
  onChange,
}: BreadcrumbTreeProps) => {
  const treeApi = useTreeApi({ data, openByDefault: false, defaultSelections });

  const {
    treeRef,
    search,
    onSearch,
    isExpanded,
    expand,
    collapse,
    selectedIds,
  } = treeApi;

  useEffect(() => {
    onChange?.(selectedIds);
  }, [selectedIds, onChange]);

  const isSearchEmpty = search.length === 0;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onSearch(event.target.value);
  };
  const onResetSearch = () => {
    onSearch('');
  };

  const [drillLevel, setDrillLevel] = useState<{
    level: number;
    drillHistory: string[];
  }>({ level: 0, drillHistory: [] });

  const drillIntoNode = (node: NodeApi<NodeData>) => {
    treeRef.current?.open(node.id);
    setDrillLevel((prev) => ({
      level: prev.level + 1,
      drillHistory: [...prev.drillHistory, node.id],
    }));
  };

  const drillOutToLevel = (level: number) => {
    const idsToClose = drillLevel.drillHistory.slice(level).reverse();
    idsToClose.forEach((id) => treeRef.current?.close(id));
    setDrillLevel((prev) => ({
      level,
      drillHistory: prev.drillHistory.slice(0, level),
    }));
  };

  const drilledIds = useMemo(
    () =>
      drillLevel.drillHistory
        .reduce(
          (acc, id) => acc.find((d) => d.id === id)?.children || acc,
          data
        )
        .map((d) => d.id),
    [data, drillLevel]
  );

  return (
    <Box backgroundColor="white">
      <InputGroup size="sm" {...(isSearchEmpty && { marginBottom: '6px' })}>
        <Input value={search} onChange={handleChange} placeholder="search" />
        <InputRightElement
          children={
            isSearchEmpty ? (
              <SearchIcon w={3.5} h={3.5} color="silver.600" />
            ) : (
              <CloseIcon
                style={{ cursor: 'pointer' }}
                w={2.5}
                h={2.5}
                color="silver.600"
                onClick={onResetSearch}
              />
            )
          }
        />
      </InputGroup>
      {!isSearchEmpty && (
        <Button
          variant="link"
          size="xs"
          colorScheme="gray"
          onClick={isExpanded ? collapse : expand}
        >
          {isExpanded ? 'Collapse All' : 'Expand All'}
        </Button>
      )}
      <Breadcrumb
        crumbs={branches}
        currentLevel={drillLevel.level}
        forceAllCrumbs={!isSearchEmpty}
        setCurrentLevel={drillOutToLevel}
      />
      <TreeSelection
        data={data}
        treeApi={treeApi}
        openByDefault={false}
        drillMode={{
          isEnabled: isSearchEmpty,
          drilledIds,
          drillIntoNode,
        }}
      />
    </Box>
  );
};
