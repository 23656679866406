// lookup label font size
// key at first level is the height breakpoint key.
// key in sub object is the number of nodes
const fontSizeLookup = {
  350: {
    22: 11,
    18: 11,
    15: 11,
    0: 12,
  },
  300: {
    22: 11,
    18: 11,
    15: 11,
    0: 11,
  },

  200: {
    22: 8,
    18: 11,
    15: 11,
    0: 10,
  },

  0: {
    22: 8,
    18: 8,
    15: 8,
    0: 8,
  },
};

// lookup node padding
// key at first level is the height
// key in sub object is the number of nodes
const nodePaddingLookup = {
  350: {
    22: 8,
    18: 12,
    15: 15,
    0: 25,
  },
  300: {
    22: 8,
    18: 12,
    15: 15,
    0: 20,
  },
  200: {
    22: 5,
    18: 12,
    15: 15,
    0: 15,
  },
  150: {
    22: 5,
    18: 12,
    15: 15,
    0: 10,
  },
  0: {
    22: 5,
    18: 12,
    15: 15,
    0: 5,
  },
};

// lookup for available lookup tables
const lookupTables = {
  nodePadding: nodePaddingLookup,
  fontSize: fontSizeLookup,
};

/**
 * Used to determine the node padding for sankey chart at a given height/num nodes combo.
 *
 * @param viewHeight - height of the sankey chart
 * @param numNodes - number of nodes in the sankey chart
 * @param lookup - the lookup table to use, fontSizeLookup is used as the default
 *
 * @returns node padding number as an integer
 */
export const queryLookup = (viewHeight, numNodes, lookup) => {
  // find lookup table, if no match, return default table
  const lookupTable = lookupTables[lookup];

  let minViewSize = 0;
  let minNodes = 0;

  // find height breakpoint
  for (const bkpt of Object.keys(lookupTable).sort((a, b) => b - a)) {
    if (viewHeight >= bkpt) {
      minViewSize = bkpt;
      break;
    }
  }

  // find num nodes breakpoint
  for (const num of Object.keys(lookupTable[minViewSize]).sort(
    (a, b) => b - a
  )) {
    if (numNodes > num) {
      minNodes = num;
      break;
    }
  }

  return lookupTable[minViewSize][minNodes];
};
