import { graphql } from '@revelio/data-access';

// export const GET_NAME_RECS = graphql(
//   `
//     query GetNameRecommendations($name: String!, $page: Int!) {
//       name_recommendations(query: { name: $name, page: $page }) {
//         user_name
//       }
//     }
//   `
// );
export const GET_NAME_RECS = graphql(`
  query GetNameRecommendations($filters: TalentDiscoveryV1_5Filter) {
    talent_discovery_name_recommendations_v1_5(query: $filters) {
      user_name
    }
  }
`);
