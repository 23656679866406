import produce from 'immer';
import { camelCase, omit, pickBy, reduce } from 'lodash';
import { deliverableKeys, deliverablesStore } from '../deliverables.repository';
import {
  CompletePipeline,
  CompletePostingPipeline,
  CustomColumn,
  DatasetFilter,
  Deliverable,
  InputRefs,
  isCompletePostingPipeline,
} from '../deliverables.model';
import {
  DOWNLOAD_DELIVERY_FILE_PREFIX,
  isDeliverableDownloadable,
} from '../delivery/delivery.model';
import { Deliverable as APIDeliverable } from '@revelio/data-access';
import { WritableDraft } from 'immer/dist/internal';
import { serializeCustomColumnMultiGranularitySelectionListVariables } from '../columns/custom/serialize-custom-columns.api';
import { serializeFilterMultiGranularitySelectionListVariables } from '../columns/filters/serialize-filters.api';

export function getClientDeliverableInput() {
  return deliverablesStore.query((state) => {
    return reduce(
      state,
      (result, value, key) => {
        if (value === undefined) {
          return result;
        }

        if (!deliverableKeys.includes(key)) {
          return result;
        }

        if (key == 'entities') {
          const includedDeliverables = Object.values(
            value as { [id: string]: Deliverable }
          )
            .filter((d) => !d.isExcluded)
            .map(({ isExcluded, ...d }) => d);
          const deliverablesWithoutEmptyCompanyInformation =
            includedDeliverables.map((d) =>
              produce(d, (draft) => {
                if (d.company_reference === '') {
                  delete draft.company_reference;
                }

                if (d.pipeline_input === '') {
                  delete draft.pipeline_input;
                }

                if (!d.company_sets?.length) {
                  delete draft.company_sets;
                }
              })
            );
          const deliverablesWithDownloadPrefix =
            deliverablesWithoutEmptyCompanyInformation.map((d) =>
              produce(d, (draft) => {
                if (
                  !isCompletePostingPipeline(
                    draft.pipeline as CompletePipeline | CompletePostingPipeline
                  )
                ) {
                  // cannot cast pipeline to CompletePostingPipeline because property is non optional and so in theory should not be deleted.
                  // Actually we're deleting because it's a non posting pipeline based on if condition
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  delete (draft.pipeline as any)['source'];
                }

                const duplicateDatasetUniqueFolderLocation =
                  state.duplicateDatasetNaming[draft.id] || '';

                if (draft.s3_delivery) {
                  if (isDeliverableDownloadable(draft.s3_delivery)) {
                    draft.s3_delivery.s3_file_prefix = `${DOWNLOAD_DELIVERY_FILE_PREFIX}/${camelCase(
                      state.client
                    )}/${state.name}${
                      duplicateDatasetUniqueFolderLocation
                        ? `/${duplicateDatasetUniqueFolderLocation}`
                        : ''
                    }`;
                  } else {
                    draft.s3_delivery.s3_file_prefix = `${draft.s3_delivery.s3_file_prefix}/${duplicateDatasetUniqueFolderLocation}`;
                  }
                }

                if (
                  draft.snowflake_delivery &&
                  !!duplicateDatasetUniqueFolderLocation
                ) {
                  draft.snowflake_delivery.snowflake_file_prefix = `${draft.snowflake_delivery.snowflake_file_prefix}_${duplicateDatasetUniqueFolderLocation}`;
                }
              })
            );

          const deliverableWithSerialisedCustomSteps =
            deliverablesWithDownloadPrefix.map((d) =>
              produce<Deliverable, WritableDraft<APIDeliverable>>(
                d,
                (draft) => {
                  if (draft.pipeline.custom_columns) {
                    draft.pipeline.custom_columns =
                      serializeCustomColumnMultiGranularitySelectionListVariables(
                        {
                          customColumns: d.pipeline
                            .custom_columns as CustomColumn[],
                        }
                      );
                  }

                  if (draft.pipeline.filters) {
                    draft.pipeline.filters =
                      serializeFilterMultiGranularitySelectionListVariables({
                        filters: d.pipeline.filters as DatasetFilter[],
                      });
                  }
                }
              )
            );
          return {
            ...result,
            deliverables: deliverableWithSerialisedCustomSteps.map((d) =>
              omit(d, 'id')
            ),
          };
        }

        if (key == 'inputRefs') {
          return {
            ...result,
            ...pickBy(value as InputRefs, (v) => !!v.length),
          };
        }

        return { ...result, [key]: value };
      },
      {}
    );
  });
}
