// Takes a string and returns the string in plural form

export const pluralize = (str: string) => {
  const lastChar = str.slice(-1);
  let pluralized = '';

  if (lastChar === 'y') {
    return str.slice(0, str.length - 1).concat('ies');
  }

  if (lastChar === 's') {
    pluralized = str;
  } else {
    pluralized = str.slice(0, str.length).concat('s');
  }

  return pluralized;
};
