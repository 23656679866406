import { PipelineColumnType } from '@revelio/data-access';
import { STANDARD_COMPANY_COLUMN } from './shared';
import { ColumnSet } from '@revelio/layout';

export const SENTIMENT_SCORES_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: null,
    columns: [
      { ...STANDARD_COMPANY_COLUMN, isRequired: true },
      {
        id: PipelineColumnType.ManagementSentiment,
        label: 'Management',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.InnovativeTechnologySentiment,
        label: 'Innovative Technology',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.WorkLifeBalanceSentiment,
        label: 'Work-Life Balance',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.MentorshipSentiment,
        label: 'Mentorship',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.CareerAdvancementSentiment,
        label: 'Career Advancement',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.DiversityAndInclusionSentiment,
        label: 'Diversity & Inclusion',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.CoworkersSentiment,
        label: 'Coworkers',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.CompensationSentiment,
        label: 'Compensation',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.CultureSentiment,
        label: 'Culture',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.CompanyAndDivisionSizeSentiment,
        label: 'Company and Division Size',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.PerksAndBenefitsSentiment,
        label: 'Perks & Benefits',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.OnboardingSentiment,
        label: 'Onboarding',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.RemoteWorkSentiment,
        label: 'Remote Work',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.NumReviews,
        label: 'Number of Reviews',
        defaultIsSelected: true,
      },
    ],
  },
];
