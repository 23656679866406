export const data = [
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32711,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75052,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134749,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88193,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7095,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9889,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96196,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428348,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47283,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38352,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121351,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32175,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146950,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137626,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81759,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86955,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8494,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138350,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115725,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107836,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95676,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115900,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202809,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288350,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64433,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48324,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91872,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50996,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68978,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124728,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100061,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177046,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199618,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103929,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 351241,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100924,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66352,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28033,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139523,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115394,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113658,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25392,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159839,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107270,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4978,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83044,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31856,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18128,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330571,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170409,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134227,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227207,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101967,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148564,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15885,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179085,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182444,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106612,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291204,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11277,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70293,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115469,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29343,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243018,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42208,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27268,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135777,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56650,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281810,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113176,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88598,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9569,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75668,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31500,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25750,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534292,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7903,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102644,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22514,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89603,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5716,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112096,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153742,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35358,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147434,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87752,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139381,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35131,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9114,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182568,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70979,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141189,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64020,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146733,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59588,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42421,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71663,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73918,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179305,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14484,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61994,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179022,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192745,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156492,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27363,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100068,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33341,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21772,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93841,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9040,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132276,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295216,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61164,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177242,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26843,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58504,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245802,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87308,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109086,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130085,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156108,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120712,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122461,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262246,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174006,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79601,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 297389,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50062,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73901,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38554,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16331,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145954,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76070,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30054,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21310,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32751,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194081,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131520,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175325,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76679,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103040,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14065,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43657,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278102,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149092,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33972,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119333,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245742,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129341,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130838,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121664,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58474,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103852,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244893,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145574,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13350,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49216,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55963,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177209,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18263,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187451,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179059,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229518,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 563619,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76182,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354779,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286714,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129954,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185963,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16145,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67858,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413191,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57657,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261415,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262329,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74653,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144803,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114827,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110892,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 423648,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112897,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21111,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28127,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125628,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30428,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193867,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42765,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68836,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189450,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108327,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85943,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231015,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450904,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150332,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64031,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260230,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345012,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284021,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160437,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 221842,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51124,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87563,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40384,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 168580,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85143,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164460,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284928,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160140,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180053,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 38441,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209994,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108105,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187934,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45704,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43711,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141607,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252190,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179641,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46610,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304546,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92576,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287910,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 144417,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259538,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319328,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37427,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249133,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50665,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339051,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271224,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255472,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540372,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 163887,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41912,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172149,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292580,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95118,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514561,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513651,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 628331,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 583198,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541071,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429429,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28055,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283336,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86518,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35423,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14457,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40877,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 403120,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99302,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216869,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92813,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36677,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123296,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29124,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123501,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21132,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172445,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157839,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33101,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117855,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94272,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17692,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117861,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60563,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120037,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131367,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62316,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99455,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129700,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192679,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118688,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30922,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102202,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102749,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101973,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191792,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112602,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 379085,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25937,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120534,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26364,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189211,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9081,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142433,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56829,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9266,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77207,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156211,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95498,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45421,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121686,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136145,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189803,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294055,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93047,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294168,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100123,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21394,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99979,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142540,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2693,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9943,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131132,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120031,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71879,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 217441,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126051,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101985,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9278,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130354,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107116,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84888,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40355,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15706,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155933,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31248,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5419,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222375,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78381,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105574,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44383,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112983,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75621,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288126,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264567,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102012,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189162,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60370,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161642,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76962,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92048,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15820,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108604,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29826,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36655,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106387,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182417,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77444,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131756,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179854,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538891,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57301,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17219,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231805,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132312,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17737,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68893,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241939,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16058,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16866,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33284,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74335,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14032,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91538,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90407,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177060,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59083,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99993,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126505,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24325,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80849,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28171,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263803,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12441,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16225,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118233,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201823,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17675,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10409,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78406,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357496,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20829,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69979,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172051,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107418,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121751,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109240,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18065,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267089,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 428219,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118214,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59762,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132250,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301192,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264957,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119575,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197453,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116359,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 132404,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42560,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32097,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29595,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125214,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114177,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20115,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62085,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103225,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162616,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371609,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350345,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 517531,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175287,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104528,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86111,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 356948,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97186,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52644,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217664,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19859,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 386057,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 392354,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111177,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 714137,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49075,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465865,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14833,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6891,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111058,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186630,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13977,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73201,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209353,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309205,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61050,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218485,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 679074,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33404,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98158,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107981,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473717,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 660499,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287186,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80154,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125354,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47658,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 323509,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181738,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232076,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145297,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 324400,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46773,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 144232,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185278,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170798,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101173,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87365,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415293,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 68586,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283127,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40189,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155007,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153929,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 215387,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 191816,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185581,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271447,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123604,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23980,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142780,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194053,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118988,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69001,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 148010,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123843,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93561,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403056,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41572,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196736,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38799,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126813,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37045,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358318,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28481,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96603,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307501,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389126,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 546074,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208464,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59867,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360570,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175652,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292756,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69237,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313055,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288317,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406417,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 89154,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108712,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 506505,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574749,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 653313,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 138596,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 611264,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61209,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 603434,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34621,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150378,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80704,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523013,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24423,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152771,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65786,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 169951,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17411,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22054,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95496,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126760,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116854,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131825,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165273,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 318034,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115560,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116978,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20319,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82494,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119470,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306332,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24958,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89001,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53758,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133213,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207079,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6888,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34794,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76302,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72387,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11490,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26900,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117169,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118746,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81676,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66693,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119971,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42620,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195183,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 225737,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38867,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155533,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103977,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98244,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86357,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74927,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127667,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82331,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98244,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118531,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183874,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12033,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31078,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27841,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23038,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93036,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19652,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81665,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115735,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128366,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155249,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69643,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84925,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115955,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44652,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18688,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32595,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14232,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10071,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 161619,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25366,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72117,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72934,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29053,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30520,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24229,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 15034,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51961,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148310,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3669,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116224,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99413,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115602,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35341,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118862,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239873,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293742,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37267,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113546,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9719,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120813,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256216,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150909,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85738,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86576,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34714,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133535,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29773,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340655,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149185,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152233,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125799,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99822,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93671,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8976,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148907,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145602,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66833,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261550,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34133,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148139,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135448,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145301,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190715,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143461,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108410,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81957,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9987,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128326,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13726,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106802,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156632,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36088,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153478,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 453956,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186594,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 221267,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101179,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193828,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224875,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92855,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243420,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84513,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42817,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37548,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21979,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211691,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102591,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36197,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79781,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72985,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 214826,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11848,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80916,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41695,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112293,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33875,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200345,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55449,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177440,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24778,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32279,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103115,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406987,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94872,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 858911,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44254,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168204,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231750,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29712,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21586,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117077,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68789,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29765,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235635,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151921,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31911,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97510,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116534,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262147,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 339785,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174427,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231542,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 535917,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103244,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20635,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45526,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450393,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86752,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218696,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69383,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145977,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30923,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47259,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187563,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144034,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61358,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50735,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303877,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101829,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317489,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654072,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15585,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68972,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16216,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251809,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32065,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223011,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96448,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243901,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85897,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 439883,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156221,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37019,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426135,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210819,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258960,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 531263,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 158753,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204855,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393475,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155363,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375277,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94042,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166573,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146591,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437192,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30616,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356995,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 460058,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47346,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77600,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40313,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137612,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141757,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81656,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66959,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201298,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61895,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434395,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414661,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251248,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345919,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314459,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336320,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 484121,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61168,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538539,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227227,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131345,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 142323,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489029,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440022,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266989,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25260,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 563859,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399149,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141536,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72125,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143899,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134804,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53445,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138169,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75335,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147230,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120295,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39634,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26645,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9536,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122552,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31630,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37046,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185288,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154202,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45370,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53256,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21328,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20647,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496054,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275543,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181668,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180480,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82992,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133310,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112101,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89681,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104949,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60780,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20533,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38898,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 165944,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89942,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25931,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75266,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30618,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24318,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96489,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6864,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77963,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110958,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119456,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125173,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8720,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 293878,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19868,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113007,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56651,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87363,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213268,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173662,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162875,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66594,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46201,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58566,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150541,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83649,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99571,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4762,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18321,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73079,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125252,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126293,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21396,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31646,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72857,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131168,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80536,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34838,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153645,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165292,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27087,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111188,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55021,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83100,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38209,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10644,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123366,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136897,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85664,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122916,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99107,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185684,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14254,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30395,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58892,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103967,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176858,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56262,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48996,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112117,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124692,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11431,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173014,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90904,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104350,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124013,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113314,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38102,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91116,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148828,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41536,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43862,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30515,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57462,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23623,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57031,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11592,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182312,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19627,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53278,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20063,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65016,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21073,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100243,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44196,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26279,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83046,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79100,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134335,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141265,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40634,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167050,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156136,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156247,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113968,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15003,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205271,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 517438,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419877,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 506708,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107288,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122894,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109790,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35850,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94260,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 109650,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95694,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95169,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37173,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84712,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255582,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313010,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112354,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43258,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45741,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235616,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 709164,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36943,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35996,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14473,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258563,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126033,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20865,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129703,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513784,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 231023,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146023,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42725,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162789,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 305637,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234059,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144363,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94965,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33210,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31375,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386388,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74278,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94759,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151164,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 324527,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34666,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108897,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91243,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327993,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135500,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 249034,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120866,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239293,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115784,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280876,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95354,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85138,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297438,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323098,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364350,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86107,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 584823,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281153,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21060,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181081,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148247,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171595,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89488,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 436539,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396659,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24965,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 318619,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57677,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231005,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 570353,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 204655,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 292547,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 79779,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99941,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136018,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161174,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 243911,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185067,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117286,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421476,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47448,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140826,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167175,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558835,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 672003,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367200,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229611,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 484748,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233857,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289526,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161422,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189530,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178987,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 658222,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 586282,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 697510,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248762,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83266,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116761,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418094,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43786,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219921,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 366969,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 234938,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 236438,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85398,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75546,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633330,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246401,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57958,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341061,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150862,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520920,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 543131,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362051,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37767,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72161,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106569,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10401,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4132,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64778,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84272,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7928,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16784,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133107,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16404,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32369,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89788,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106439,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40468,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43764,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36670,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43413,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27869,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45199,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70105,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115778,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79090,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 404198,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28293,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86493,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18413,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86967,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60748,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100787,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73201,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46179,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31332,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8423,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 645968,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313636,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22625,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112109,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87541,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8855,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276873,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36088,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 81256,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35741,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 166500,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104318,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47383,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93033,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102948,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92093,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42863,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55553,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21238,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111399,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91880,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8139,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17077,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268226,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97047,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137004,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25512,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70600,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105928,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102471,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40411,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88047,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27111,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47644,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34109,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11908,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19866,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89810,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34085,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96641,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32121,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15576,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147482,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308549,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44729,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56394,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20283,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4804,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63878,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148532,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146989,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110924,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54704,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119459,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93882,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20255,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52391,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10103,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82624,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11173,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75975,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178707,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140145,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133092,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35540,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 303403,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143074,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20585,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76853,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68618,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 318370,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53651,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92053,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11930,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165305,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74816,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111833,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270771,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149578,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98147,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24105,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93740,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98373,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91655,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63233,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177191,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184683,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111532,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123395,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173313,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80952,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137860,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80004,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151197,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 345747,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558608,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117995,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194247,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253619,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66834,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122642,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176714,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258295,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151087,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297104,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37211,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273345,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20809,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430897,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136188,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 259765,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135134,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212830,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118202,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147349,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40057,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46093,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216467,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63028,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49154,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46439,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42414,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119317,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156073,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72956,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 117572,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214481,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164022,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61626,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107227,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149777,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118099,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81329,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86610,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161912,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 297139,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44519,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299839,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136324,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486245,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287335,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 283811,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7996,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269760,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151842,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26225,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83578,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50678,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125283,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65825,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249557,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204653,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230079,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89649,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415654,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 297778,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196177,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 218720,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209313,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 338829,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67487,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 138861,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16936,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 417952,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131087,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 564813,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 97287,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59553,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236373,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 322289,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69554,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272892,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128567,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271163,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84147,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41737,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67556,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464059,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292693,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41864,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106238,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 296675,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192858,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66480,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58792,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179936,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56551,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46061,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 143407,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32779,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 533058,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76144,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272145,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171282,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169716,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 260923,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126785,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166716,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39828,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15957,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66398,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329823,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247251,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24931,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253194,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134343,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151124,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 37671,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48975,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70923,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 502524,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350823,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345223,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365142,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74801,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 24014,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538972,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424253,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 488934,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39966,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200267,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288507,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49427,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27488,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2883,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6145,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119069,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46342,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155706,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11342,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102053,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28711,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128402,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103664,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16253,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51865,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4851,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99147,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220186,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39626,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104325,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160409,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161867,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142673,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33468,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257551,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11344,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87052,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14720,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95484,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43789,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15684,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130897,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83817,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24274,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58564,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 5828,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174984,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69223,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105121,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249422,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113704,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121796,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15902,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143117,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130585,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132486,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42253,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133271,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95120,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172261,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48112,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35148,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99357,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271193,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62408,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20146,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7919,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97175,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119643,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9392,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11057,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16109,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133994,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38913,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181478,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120716,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4612,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93125,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119993,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66410,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34317,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13378,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169012,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119533,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77795,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42228,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124953,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31998,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98499,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98088,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35112,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122929,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16960,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324013,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36608,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147785,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7921,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48137,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194004,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95582,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89016,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175351,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262430,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95281,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193524,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130031,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79328,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264946,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79043,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46643,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68185,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37672,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44261,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 189217,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7619,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176290,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70631,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124614,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266165,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270805,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92850,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21405,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23968,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113637,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192735,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17326,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79449,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99229,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119907,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71108,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132919,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330187,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32067,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297431,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 52567,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69238,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239418,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115351,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117488,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155594,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176734,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165213,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 134620,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125489,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137371,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204971,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11572,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117368,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63572,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9163,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102053,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24563,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107476,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81518,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155912,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43350,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343415,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477379,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35538,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188292,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105833,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41678,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 328243,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98539,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87345,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119043,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133834,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217958,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98260,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26661,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12587,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3334,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36183,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 739356,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 154955,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42855,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102127,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222630,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182916,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118430,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444778,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114245,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156455,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 290967,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 571463,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271185,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355834,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125367,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33525,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189050,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73436,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135787,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464436,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111744,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93122,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14856,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 152131,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167117,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6476,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97951,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541626,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87588,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19221,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64010,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118682,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98722,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94630,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120376,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78906,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 402009,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 628887,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115498,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175266,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286808,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 633085,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 143955,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41663,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57796,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 118920,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42240,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261921,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216756,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 785750,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118803,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288722,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174198,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81982,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516780,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 62098,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 327029,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305312,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145219,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62646,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26662,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241808,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31685,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119341,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 276364,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 505741,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 774323,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239497,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372085,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210940,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486197,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154046,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21858,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222580,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 311070,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 340213,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 184844,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75134,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436603,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289301,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386460,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459562,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86436,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 97526,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414102,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155843,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 107224,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401612,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64575,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 188949,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403122,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169144,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41155,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 195668,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12993,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17520,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139525,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104761,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146750,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102794,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56714,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90058,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31749,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5698,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17896,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142844,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105044,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17173,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92029,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16165,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106308,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76388,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11578,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146987,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93535,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56582,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134058,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46430,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157551,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81775,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10801,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33605,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82274,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148860,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36221,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187089,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106516,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2050,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80112,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26025,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32581,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127841,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14851,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317246,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10882,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179706,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224746,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162275,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124990,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 380865,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66667,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98232,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109274,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61021,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73412,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50548,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26051,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27305,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81477,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96273,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12024,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111646,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77347,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79730,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43210,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136615,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118357,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104432,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20538,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169650,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92146,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78294,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35155,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84643,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129565,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10937,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42494,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 686011,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25612,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102523,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22661,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43613,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 163479,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7320,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69306,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41803,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56778,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109766,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131807,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73761,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68986,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108223,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158105,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77569,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127128,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57646,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159558,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44256,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17353,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 799332,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251518,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94002,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49751,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92483,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50579,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101141,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136452,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19843,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138595,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137843,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83497,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109756,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31439,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98771,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133046,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7464,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36063,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105902,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176717,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157978,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129998,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124730,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142811,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27832,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23092,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140055,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 702122,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34490,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65811,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141189,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104296,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105064,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140931,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95337,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137388,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177167,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56387,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117833,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88668,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144158,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274971,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128113,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83949,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144373,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121974,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22949,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178272,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81862,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57465,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137951,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23007,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169986,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77528,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33578,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100380,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 368159,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272960,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384218,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136519,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252579,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323579,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128388,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158889,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87293,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175380,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160275,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101466,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177028,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223086,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419707,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44909,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146763,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104557,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267657,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62823,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 183485,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8125,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87851,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198839,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67767,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26838,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149112,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123057,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152064,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140491,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23369,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182158,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132126,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134655,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237495,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100903,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411017,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100884,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199660,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37657,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178095,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27097,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158834,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69374,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 498847,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136862,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217657,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128549,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 347465,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11127,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22090,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45550,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421426,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47459,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45157,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48594,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198531,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185585,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44342,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618310,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317420,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 34799,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 275756,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168042,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67355,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58938,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290673,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408320,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112952,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 17564,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323781,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47316,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197664,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188766,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389383,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146232,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96308,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508887,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258653,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236326,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54308,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291400,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 138844,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 259116,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336649,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202694,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37731,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125869,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171994,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318505,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190130,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97369,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11378,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170538,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34319,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7861,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77457,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14636,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6811,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72200,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75512,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189050,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192015,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90520,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21988,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171066,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84474,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88697,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72088,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91869,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291620,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75136,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16875,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182093,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5749,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118948,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56622,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37089,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22107,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122217,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73581,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114577,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72784,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137982,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193023,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159515,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65707,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118266,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84906,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104970,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44539,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267983,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261731,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60872,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15255,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84541,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98481,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90782,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10502,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 124761,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129396,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92941,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121279,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7608,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132552,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11378,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67187,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147039,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75739,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161156,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34377,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31258,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36845,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45511,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81416,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53615,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48315,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167302,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89581,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97286,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41261,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111390,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102877,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6204,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406573,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74116,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37592,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37844,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20934,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85455,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58120,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30992,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55532,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235433,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125744,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180441,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106208,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120422,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140107,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27070,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76587,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 167461,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25983,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109087,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148313,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17907,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112353,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118378,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104882,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82364,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15772,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134077,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150758,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68160,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153545,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121477,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343586,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40061,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123451,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26117,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172100,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36274,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39228,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92790,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75353,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55219,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15067,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162408,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 222738,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40065,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14335,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115266,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89217,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416404,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101036,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123634,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96275,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9025,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148660,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16391,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83153,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104712,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 25400,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61822,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110870,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180866,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21208,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65566,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 289003,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51255,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192830,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125942,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107643,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64796,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140587,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 188657,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8154,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279606,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 153074,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67218,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168901,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40837,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93211,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17144,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153297,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20551,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 306368,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44625,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89329,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2883,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37132,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125539,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81878,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 254064,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365801,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156653,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300104,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108247,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23100,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239708,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230247,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53003,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147954,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371994,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 204922,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205596,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247451,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102769,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47195,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13518,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95924,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198029,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11769,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22603,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134947,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120709,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246975,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210130,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82946,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122043,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82665,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46620,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21294,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29618,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35665,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147832,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176281,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118913,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150508,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41379,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14584,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183218,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188774,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199315,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36470,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227070,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160729,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183015,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6714,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424137,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88146,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159740,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449109,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427050,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12962,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375059,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 154009,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277856,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45399,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190616,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140852,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192301,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153393,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88778,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176345,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198973,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51130,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 603005,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91130,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258771,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57780,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97543,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 571495,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493539,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264609,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164539,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230958,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321329,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342300,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568097,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 588048,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334994,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26535,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65387,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287776,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45849,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633038,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 19205,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 198758,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559896,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 74676,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65799,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 115955,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59666,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 521746,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 639784,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 354901,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234883,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326713,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70574,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50141,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109486,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77425,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93747,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18722,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102514,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26155,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104777,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 354552,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16822,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85761,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76474,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128079,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16797,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13391,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25688,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433685,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37144,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100846,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17329,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92318,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168380,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71858,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50229,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120862,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61301,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21740,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110113,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25042,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111034,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140910,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68738,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71466,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72510,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183294,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57077,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26407,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165088,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69942,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25935,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214622,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146197,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7420,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 5984,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139736,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99846,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84167,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28645,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76968,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136878,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108755,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122536,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22489,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37420,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74339,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228512,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155216,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141943,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44461,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231805,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107422,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123386,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118016,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63309,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123090,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127858,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175176,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120116,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5025,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63848,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75428,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73627,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148811,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96539,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 113210,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367284,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77761,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42078,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103774,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29008,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57234,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20920,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78871,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242305,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69513,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115629,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43413,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49411,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61529,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312062,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47971,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107148,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 208851,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109071,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27044,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196195,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 276226,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30187,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157463,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117610,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82899,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69574,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145606,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166690,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76337,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167593,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25730,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22248,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166988,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100532,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52537,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96393,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 339362,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12100,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61827,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153066,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 370640,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150759,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39478,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233825,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28730,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53277,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 349199,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352626,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 661032,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85319,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 225566,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137388,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 169262,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44298,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56679,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43569,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 308187,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73693,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147145,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 312853,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102969,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24484,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325155,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 224108,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10208,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268971,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195622,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143158,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215529,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118558,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 415686,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211933,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118478,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99364,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108090,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152626,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121222,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74497,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 231636,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20608,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138312,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189931,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115664,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 138392,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42073,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47717,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161663,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50675,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 680192,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 469700,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135857,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 658110,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155941,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103900,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283962,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277945,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22432,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258891,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179079,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75654,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82916,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68627,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236481,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218018,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95962,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61880,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66336,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107195,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20973,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248510,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303468,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403527,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151718,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221111,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430248,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 256895,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378500,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190049,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230447,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56396,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51528,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 368595,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79536,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186161,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19043,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64631,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96100,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24581,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180988,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 270682,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119669,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 142513,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17256,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238298,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38539,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53015,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84030,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266954,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241541,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53395,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255603,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 32391,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41630,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130949,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171155,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328576,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215050,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 447935,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343131,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241922,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62755,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65693,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455384,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92656,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540321,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223931,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31434,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258105,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69779,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159896,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 480797,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136805,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294765,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479799,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33982,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57635,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294790,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 209432,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119619,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330549,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 578542,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 691426,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581786,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187693,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360810,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242812,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122894,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18799,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102470,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 446128,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50320,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90270,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277363,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11429,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 340205,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8146,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12461,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73122,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92448,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40807,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8553,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9833,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107848,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74862,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11895,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262050,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17582,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20098,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15857,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174430,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211737,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62308,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101510,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71486,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23478,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39408,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35462,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181518,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36583,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28482,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79839,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15477,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97260,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77397,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89713,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197838,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94455,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129784,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79459,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22814,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 213691,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20201,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189171,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6557,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25239,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39398,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165828,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 511601,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26918,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86944,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20198,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53211,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120525,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131494,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136883,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26415,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171076,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206050,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105300,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156452,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82124,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129689,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171919,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103125,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85631,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19909,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100121,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14192,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322586,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85539,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11848,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26441,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50185,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89301,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78100,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 611911,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108244,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249461,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205702,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8645,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249295,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28694,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4521,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37617,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179024,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84982,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66205,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106255,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124716,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55138,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416020,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354288,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131926,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122487,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164398,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31418,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69462,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 129099,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53874,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80313,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105672,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70387,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111954,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98769,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70453,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22071,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50704,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63934,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20612,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287034,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91659,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90800,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312516,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102696,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102869,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520953,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110662,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164090,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91730,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31978,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29692,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207864,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121659,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21984,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78103,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95300,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88016,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151444,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160542,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239034,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114275,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188821,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151993,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26807,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112676,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12274,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142754,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281516,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145215,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197938,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92497,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82879,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142669,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69808,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23420,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101291,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181720,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73454,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541049,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146805,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92824,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35123,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110473,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154528,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98490,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68748,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20412,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90328,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252159,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194277,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117310,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 525159,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116266,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42073,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89370,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205909,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70229,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117754,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238327,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191741,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38895,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125066,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403950,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227067,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103897,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141289,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63366,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72008,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115344,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309071,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138140,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94720,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70355,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152706,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129185,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155591,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44864,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208798,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357063,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389141,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75096,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85686,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265691,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143583,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249653,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18756,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333539,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23661,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 471943,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80098,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116990,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56528,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 333586,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65856,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 550221,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320868,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71178,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 500151,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286981,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91790,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83322,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66070,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 354478,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13027,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177017,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91066,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15846,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172905,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 665251,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445337,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91110,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540954,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145000,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55966,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362776,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339363,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323978,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155408,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398193,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46811,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21118,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29801,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 136946,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40038,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254884,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203101,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65175,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75470,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115371,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400081,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57967,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389632,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284529,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 502247,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 398913,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383547,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64388,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165149,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 510032,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163982,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43003,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422179,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144136,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364012,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272571,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26957,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459141,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425190,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338097,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362126,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109764,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118197,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41397,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56824,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2344,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19684,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28632,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81053,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69576,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158808,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17550,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121628,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142895,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241889,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23775,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130016,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63816,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56643,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74888,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23939,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26005,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43745,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104441,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67070,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50657,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30486,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106483,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14363,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86532,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95525,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125159,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108966,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36478,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29614,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74333,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51709,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45608,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48795,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11745,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117038,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84766,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30288,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34842,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112171,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254641,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59221,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152163,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47139,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94204,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8040,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8767,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107426,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137465,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86841,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421407,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81349,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6467,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88939,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199779,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119531,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133220,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174896,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 215833,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36215,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146408,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109374,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32938,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92876,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15013,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113486,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 167436,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36412,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28247,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74890,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103278,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 222468,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48036,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173690,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10507,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27154,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55321,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83032,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13594,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88889,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39725,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29373,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261856,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66380,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26282,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10048,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29066,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45097,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117159,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89858,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70509,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12300,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308280,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169808,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92269,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131518,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252315,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112165,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78299,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173839,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105342,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20030,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108978,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142311,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110174,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35990,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133592,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146846,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286412,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107767,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66956,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88757,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24847,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46271,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21471,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27921,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5390,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291325,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402660,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130256,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66622,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72937,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106994,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70686,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40257,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228248,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137569,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16204,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117010,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135219,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337987,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114413,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134552,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119143,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115426,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24768,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114364,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100319,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270409,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20710,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78946,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43891,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 467086,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338683,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66237,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91813,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164983,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150169,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137421,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50534,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 484605,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250398,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117004,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325337,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32330,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 144910,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141510,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337576,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125887,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176705,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218182,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109956,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175369,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145990,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32031,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 530133,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496167,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7689,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156116,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21422,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138504,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38953,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145070,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195432,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30701,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140478,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128854,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135331,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256656,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75782,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430332,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11831,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245745,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177670,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98036,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192610,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150295,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78861,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136646,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11225,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52039,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213338,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 207385,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94713,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247135,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54130,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226073,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24774,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97610,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 378135,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215195,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193589,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37058,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77772,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129013,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21003,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40944,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 231259,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208478,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28047,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 292843,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278072,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 139000,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76812,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340107,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 192825,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 241389,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147777,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 138184,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87916,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231766,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 122710,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125306,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50930,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100129,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61344,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 189853,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355348,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 604742,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58985,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333650,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 154082,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 663953,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 476432,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409539,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109013,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117010,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281896,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4522,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11522,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19440,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87648,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99866,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6361,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41404,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175847,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161019,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23947,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87411,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67867,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23656,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128382,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17191,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23100,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28619,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 388948,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65571,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85451,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38376,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296075,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32528,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220569,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125100,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38916,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20963,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11340,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293066,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250983,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22709,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20133,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104047,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115163,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75495,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63298,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 104388,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83818,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76135,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 717240,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77552,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116962,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180585,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537451,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39369,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277901,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98950,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145091,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35601,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111439,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3948,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72676,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65393,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105541,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295189,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70614,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148989,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122122,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166846,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117773,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317984,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24429,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14755,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146273,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89143,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12476,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235907,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161312,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289024,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 129272,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228648,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48448,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125012,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158816,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4663,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8135,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72782,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357369,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33330,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6584,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3307,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198817,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89279,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83694,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 767200,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16776,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300030,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9662,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169896,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19240,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64826,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265747,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 309005,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15392,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9516,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19892,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42683,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14787,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80988,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148034,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30145,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78572,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162444,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72594,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75295,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142774,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243516,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130727,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149594,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115021,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59172,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204156,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125006,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95251,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24562,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176179,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100165,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18610,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91805,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36818,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109427,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 173603,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81065,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 561836,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187131,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43814,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 535151,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377536,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39000,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50146,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162211,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103391,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145004,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 349343,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116591,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78127,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110714,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256471,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161134,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172942,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17427,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76480,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114345,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159159,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7399,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72345,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59086,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109838,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76050,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74415,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121898,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163039,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39378,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80975,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61087,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269845,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22706,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39927,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122707,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51362,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69211,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150127,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108051,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54653,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148743,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55128,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78457,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18503,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 151020,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85312,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143534,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 132613,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261763,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180038,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77123,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4275,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47828,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11685,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276362,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51806,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36684,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86851,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106525,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44455,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92817,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408127,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 368954,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 441733,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274986,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25952,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43375,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60142,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53927,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36327,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208117,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433069,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152001,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11769,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13423,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265736,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314727,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191164,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121902,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105492,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 332118,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340227,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227442,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67093,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63152,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 257914,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517198,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139881,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52610,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 85895,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144232,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389730,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62361,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 595966,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23707,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101465,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176480,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206493,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215114,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129731,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170279,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486450,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474561,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27548,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129716,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 257701,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109787,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322737,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552974,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421857,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 179712,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274278,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 79614,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232279,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 532613,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420049,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189604,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 627819,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68101,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 567912,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21773,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 398277,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326175,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120192,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73702,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87714,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19528,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148311,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101711,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245187,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226968,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69985,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26219,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46438,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177283,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7760,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44157,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15248,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111015,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121949,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115398,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12836,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 2896,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98911,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17176,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78048,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73136,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11313,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45445,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66987,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113367,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189739,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96126,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103520,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84438,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58213,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41282,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113386,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20249,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276380,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151190,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106479,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32339,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13860,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29267,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135841,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105513,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98604,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54024,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126750,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21168,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167878,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93691,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150047,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40434,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28296,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127829,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108123,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 196979,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150044,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10576,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161430,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52103,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88327,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178735,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24934,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291688,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151725,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121623,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98552,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302186,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40592,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35178,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35314,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61744,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121817,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77612,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99356,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108191,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93963,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307641,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107598,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 204282,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20042,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157402,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11575,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153662,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223722,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30887,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141862,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40411,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81370,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71076,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125744,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84082,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125656,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227899,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105330,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20850,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114658,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14407,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85963,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88755,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149696,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13402,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80793,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67236,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173157,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188527,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148963,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150308,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11889,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 439038,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75501,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132618,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134703,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123808,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121940,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134830,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118018,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62284,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305807,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132779,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263687,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172136,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177971,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86230,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126261,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144714,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377868,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158425,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92071,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214887,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18693,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94364,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268842,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15261,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389893,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120266,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153356,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21077,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44980,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7995,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163418,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54281,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149894,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49420,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133148,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113683,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43037,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205810,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28793,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 76293,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81265,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 378929,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64789,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12597,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228341,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106435,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93547,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130099,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91466,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69302,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104219,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110574,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26181,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83177,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306868,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94001,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63867,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55367,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290848,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92328,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29383,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31461,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503470,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7018,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260309,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402774,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148894,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151232,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146338,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90642,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87459,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174996,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8240,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51648,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375465,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373438,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109412,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 394520,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109350,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130951,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102770,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112470,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250804,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317074,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136282,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106627,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100954,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293045,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46099,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28999,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51464,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131977,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124782,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200523,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137774,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133096,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166354,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 316788,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216460,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148287,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280711,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181349,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302130,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167776,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216527,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 199833,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163564,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 327122,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 129256,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114825,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214205,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20236,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171178,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483591,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81396,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151154,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176945,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 453666,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30033,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46570,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188779,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42735,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633242,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267883,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258367,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379601,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62355,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 680249,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509738,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472370,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20976,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330844,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112553,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283807,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 148684,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283523,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 52144,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 496034,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80235,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131744,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466768,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249788,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362776,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22998,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254767,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 187409,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277612,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 252763,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 199204,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410795,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509788,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 643565,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 245125,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 92687,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 114653,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 124810,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 27817,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295479,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94367,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78512,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14900,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41565,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 154351,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142834,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6179,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26687,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95811,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106913,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31063,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111827,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74911,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79084,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37622,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113063,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80380,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41212,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152183,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86880,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25536,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40860,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74911,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106650,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99525,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50585,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 285214,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 354911,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86290,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20836,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97167,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42792,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103718,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31066,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308493,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36532,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88491,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24077,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 202560,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20931,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20471,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18475,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53980,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116085,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42963,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17475,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95296,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180951,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85145,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6859,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14652,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239824,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258466,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58225,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58311,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73090,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31937,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83914,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9799,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36298,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15404,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145784,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9139,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72983,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115467,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193742,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12161,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61853,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 488901,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97954,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32512,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75741,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223867,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30435,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56721,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119790,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96954,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96136,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77106,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 186398,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117185,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27541,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4379,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22977,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19245,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310979,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297117,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92348,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119404,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91541,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156166,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26306,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39890,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107819,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 417866,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104773,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92824,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61713,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41068,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133944,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148588,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 221399,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24885,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19746,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104740,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15276,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94369,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70456,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30478,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73428,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110026,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7981,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 292713,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97438,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28126,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77085,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239907,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 128899,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23027,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 10163,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45261,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114552,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9457,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243759,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234238,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364228,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259094,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59991,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138331,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90441,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40106,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406242,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173601,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 131012,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103724,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 319501,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24136,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45874,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150500,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113343,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45590,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119743,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 279778,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164967,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107018,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298680,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12347,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265344,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69280,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96224,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94673,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25256,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89284,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123432,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125726,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122770,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128048,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110873,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155616,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24249,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 585352,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125779,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137363,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96148,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215764,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68360,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62195,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243989,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77729,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420914,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540084,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196809,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 795403,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57613,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247143,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147966,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189095,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170562,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39267,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98743,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72514,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21065,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107394,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444343,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42078,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133766,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193649,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 566249,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59543,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105739,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416493,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165513,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57617,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97009,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99370,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179348,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248854,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52018,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61191,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41621,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167771,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88959,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96751,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 123218,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146863,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281700,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 656282,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52711,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409029,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210751,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53958,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246269,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66990,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261777,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178910,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 258386,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164602,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254712,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466904,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315380,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463493,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121083,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433865,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226842,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465488,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24593,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203338,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87940,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 309229,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 499998,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67055,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 567653,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362175,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78763,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203210,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27762,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260250,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57620,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491130,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191756,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72707,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 163743,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38799,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200250,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274108,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40847,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469698,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40386,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89472,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529067,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184023,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 535515,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577066,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436933,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 443238,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147637,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266288,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413988,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406622,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22397,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78815,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12083,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123692,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 296301,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9278,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22772,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14946,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38930,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49270,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134065,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80234,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35751,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43133,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66972,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7341,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122838,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69474,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15175,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92952,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90208,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132604,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9585,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18524,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12223,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134912,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 454039,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45049,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365506,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17729,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82020,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127278,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51134,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144398,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153453,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101146,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73332,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101698,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205052,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118795,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197735,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21014,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11816,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 201534,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80225,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34604,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75182,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 113454,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45429,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321320,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18220,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50578,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83209,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 10893,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39526,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28975,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102748,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29956,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113382,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80039,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58148,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24685,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109618,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16358,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103477,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136147,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132716,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152452,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66776,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105479,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107916,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111688,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12051,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95010,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120252,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138604,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8772,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275809,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267605,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96779,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62677,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9090,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89141,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151752,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239200,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 254215,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181893,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216007,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143562,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109788,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219786,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40448,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70182,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121710,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113049,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35503,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146861,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98099,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87113,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23021,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277875,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113923,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176933,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17625,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138367,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108209,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10739,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122442,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 192553,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124757,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57090,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30946,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 200412,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59092,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 123481,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126417,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68347,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77926,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210000,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164203,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219327,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205751,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81607,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51240,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94708,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 408848,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 533167,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161291,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157728,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78443,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161782,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 240100,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92441,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56964,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 187708,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104534,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30790,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9826,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35527,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89770,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48316,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58750,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185280,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222565,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168065,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150690,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120579,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118333,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188663,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58042,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227506,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87109,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119360,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58171,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71793,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112102,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106872,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36971,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43390,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134561,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248552,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79402,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46748,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128198,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250372,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157696,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41195,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 109030,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89813,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85792,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242069,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147062,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31070,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251200,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33802,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44952,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144064,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70225,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35534,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322243,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 381496,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181926,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86281,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55676,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186789,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 700873,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 298113,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46269,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49154,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202311,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181815,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86580,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 19794,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204662,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136475,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67080,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95119,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 125196,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114649,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13986,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 428334,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106129,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87670,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30550,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21137,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38589,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 300697,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179178,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98810,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264237,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 712065,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 102525,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284151,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139058,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73955,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 402156,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 539090,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211642,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191274,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317387,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79190,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190195,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179754,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142506,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31931,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257526,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 163187,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52301,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112534,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17798,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89546,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373524,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185216,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44475,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223959,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22142,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453938,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336914,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540359,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 479102,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84875,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72586,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223353,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 247769,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 157212,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133856,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 127836,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233451,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337077,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 140620,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 620779,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80381,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284168,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38238,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244556,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 749948,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 299242,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 480215,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449279,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 189402,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330457,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260687,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 340053,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 107019,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433902,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 603266,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37425,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133207,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15300,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13748,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132529,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144276,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102287,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117568,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37643,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16485,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86674,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42620,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12054,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107604,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10244,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118711,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107989,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25580,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122647,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12053,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88834,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153654,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145344,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30321,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97616,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39210,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110628,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4276,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16012,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38453,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22873,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57857,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12674,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94507,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9891,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 199248,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128409,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86506,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129947,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122899,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23660,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95491,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520661,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80169,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178742,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148793,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98452,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86988,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133646,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 414324,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10321,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81272,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188694,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137061,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178909,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92445,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37545,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120886,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29512,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17069,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25162,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110482,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79430,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37010,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148155,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150050,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37601,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47092,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277242,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107854,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38095,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29893,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145491,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4826,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54539,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115738,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24339,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104169,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158773,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156710,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22130,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88358,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 408028,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42735,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112709,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29649,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144409,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34547,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55785,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313031,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86038,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277444,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47101,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 413471,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39666,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120563,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161428,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65907,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111379,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161234,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146977,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86771,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128728,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69771,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 372417,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152029,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10670,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106939,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165888,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7242,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43153,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39656,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38819,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112593,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107119,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99246,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16873,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187842,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21958,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18677,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183370,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97223,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69803,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108748,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281612,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75531,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7330,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86857,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137747,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151923,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21106,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147499,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32820,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132259,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114943,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266854,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15795,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240621,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67123,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169406,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145284,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 369743,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97553,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143373,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108922,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133586,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44344,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101842,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134956,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 74290,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39053,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28431,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 177489,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293633,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175005,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185360,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9531,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432743,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128958,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83376,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135914,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253920,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90458,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 355482,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275452,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20296,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95010,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268180,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 6792,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274907,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149419,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108475,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365759,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43065,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158437,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171605,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259150,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50004,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139100,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60602,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151009,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66833,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36724,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49159,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68321,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170473,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143919,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97887,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34655,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150556,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241116,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54907,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245601,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185837,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109667,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 606225,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32116,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148756,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 27802,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196134,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397592,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201435,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53163,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39443,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251760,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160197,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202995,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 350015,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 196072,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229150,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469332,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491866,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179709,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 266821,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203962,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236939,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44974,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140168,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86234,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119470,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231947,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216281,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414111,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260085,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420735,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210627,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69779,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295800,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378800,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73699,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 189781,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 335733,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85691,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244162,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20776,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185716,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 398941,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328317,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432457,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288324,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428400,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 343615,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37841,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 688772,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94415,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108450,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150183,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2657,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26189,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132954,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62665,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24413,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17411,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160219,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132997,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70295,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10313,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360696,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16034,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15644,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9005,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11461,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17871,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141263,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138154,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80364,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201144,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176041,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111421,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11697,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9280,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91160,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15179,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69767,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37503,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25944,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129091,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18889,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112678,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125066,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86048,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194586,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63440,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28680,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69005,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31608,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85764,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 149705,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120278,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15200,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26525,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28037,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 351997,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112110,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4316,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16141,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20251,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140910,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 128094,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259570,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68145,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106794,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176170,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37137,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152087,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31098,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 135960,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38036,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107936,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 165373,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93931,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91773,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96191,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163223,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100063,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86442,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205088,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 234851,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71071,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96083,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34428,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73348,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171560,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48315,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39957,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237344,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76884,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62702,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74000,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31108,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147808,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164976,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49986,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 165038,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73811,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88513,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61577,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32211,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33587,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123809,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130178,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15317,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112243,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137498,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104257,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132008,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8276,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108070,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115413,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194702,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131768,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149428,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97649,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239788,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69895,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72897,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103423,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139344,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140929,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135877,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48580,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22603,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15336,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99739,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265787,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87506,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37784,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154445,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132612,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104115,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44820,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 178882,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50222,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40518,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 283514,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35892,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93041,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123623,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152982,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252815,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102244,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28603,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149127,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6475,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140409,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53250,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119047,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 648023,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148048,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30546,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48717,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127423,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90059,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173882,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87470,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59996,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20748,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82949,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137947,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 413777,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195346,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133716,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134848,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43471,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49724,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143783,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135294,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 375098,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87769,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86892,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 438583,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12894,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128502,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61421,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123067,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 341884,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30442,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155502,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48018,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58845,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47192,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179522,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 499218,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88123,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39706,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203709,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29974,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39715,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258745,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 601281,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132275,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 112776,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68055,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129915,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357859,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133992,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294478,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156822,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24278,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172070,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137559,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148725,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461254,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75038,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112002,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140812,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30143,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120630,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110094,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48723,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131719,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85210,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556800,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164334,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70000,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54330,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172040,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 363937,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86869,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 575120,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153260,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46473,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455397,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163449,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120012,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44972,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103329,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 388440,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278266,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24654,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136288,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119489,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49963,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342803,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101009,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463832,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65825,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48087,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422555,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123796,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24051,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 261807,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237569,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199027,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216950,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220898,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129172,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 201639,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119000,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48766,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53123,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45337,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199850,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176154,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164810,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65962,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473491,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 215603,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 641024,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116787,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139502,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312399,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577441,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184727,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254923,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517001,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515998,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128421,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247367,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636864,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223011,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41583,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541277,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176283,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 710701,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473470,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89059,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126458,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 132687,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23247,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43615,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 122638,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337665,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 338175,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441094,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211698,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 78419,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 346108,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44248,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31633,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106950,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44834,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10634,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220841,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108233,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111665,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20137,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 136415,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53969,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55545,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49569,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11799,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37494,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74646,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23850,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42724,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 708270,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139484,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80588,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57794,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88261,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72016,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193586,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14173,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429321,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86238,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23525,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8524,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16898,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117331,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59443,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55892,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74382,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89888,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9721,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254781,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104179,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131543,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11343,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128582,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45797,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105539,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259740,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229605,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151600,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24152,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54253,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55457,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42336,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27267,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51372,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109908,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126448,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64673,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30681,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4645,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15088,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12958,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179629,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128173,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92458,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54463,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142671,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57066,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37863,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106061,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143458,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194793,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89804,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127856,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19258,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24024,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23761,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24462,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59319,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32012,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184841,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261967,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105619,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71089,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15611,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33202,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66957,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58707,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10402,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124885,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143756,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21375,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 213209,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110251,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209400,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140335,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58562,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113034,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16772,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105823,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18336,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179744,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50443,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235905,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28724,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30898,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24967,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181932,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97214,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87938,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89227,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197898,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27726,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195262,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35959,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163628,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28192,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71072,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156732,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97255,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101060,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256634,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169674,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105267,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29578,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9570,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62047,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43849,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107426,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44756,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41382,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253113,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101170,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120913,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8153,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21146,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93288,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76257,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215553,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158332,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 368083,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62361,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 300151,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99231,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7250,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69326,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247342,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126207,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10753,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11234,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222381,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42632,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 187782,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184515,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37887,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110502,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105304,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175630,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23848,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110395,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27413,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39670,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112760,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108555,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135415,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115577,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124860,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245590,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49095,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145041,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18491,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308920,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36950,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98064,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13398,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162173,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 104158,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 538388,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130486,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140932,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139695,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 115894,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109108,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144984,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 543890,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23111,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 623510,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294797,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56104,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30357,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164253,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13650,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63105,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85136,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384521,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119486,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81676,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247664,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128033,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67857,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395970,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125836,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36288,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413960,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41305,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45834,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205916,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252320,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66197,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62446,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201280,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210226,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277211,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67039,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245190,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24115,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106641,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109440,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148986,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65916,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265882,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83417,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273845,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107884,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347493,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 152211,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 132412,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99872,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 76704,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70602,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197649,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229623,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112012,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172114,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134865,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 566360,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249812,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86847,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29711,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248653,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118500,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264786,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51850,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79593,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449364,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210648,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452476,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 392642,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256913,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467767,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268381,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234128,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81679,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349792,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53713,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577849,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11471,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66836,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75565,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13984,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18269,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190439,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99343,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41911,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76618,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28824,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10946,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117780,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68298,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140861,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92285,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21521,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61105,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181763,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95211,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71039,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23550,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18011,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127397,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27373,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296467,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104657,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168985,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104699,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104642,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100150,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107035,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202280,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444047,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128841,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252138,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156209,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 182144,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213740,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180591,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141813,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6644,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57857,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139871,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205116,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97962,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24968,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145229,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32483,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89010,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88195,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95208,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120906,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30347,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138140,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159362,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17810,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38562,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18222,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25047,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120885,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191101,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130588,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157394,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166990,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 376268,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46981,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27176,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98740,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116754,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135394,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32842,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33016,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26252,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101782,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35560,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69750,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67159,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10545,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142949,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21755,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31612,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12195,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59982,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96407,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20164,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68337,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57361,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 281799,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99726,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14143,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4875,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16290,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107143,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151710,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13188,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189787,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327191,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114221,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19496,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95046,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134911,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93917,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41205,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34977,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157745,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83348,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142027,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112307,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110108,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119894,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65691,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130135,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174734,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121641,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85124,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118830,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299713,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39298,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 484047,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292244,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58039,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142960,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16629,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 326784,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169689,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152104,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259292,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118602,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104561,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35660,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89734,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 382607,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116081,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160357,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181868,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214024,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537521,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27858,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92797,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72053,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57323,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78850,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 124399,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101112,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536524,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196037,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 111777,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56339,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 683764,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32978,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126761,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269500,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90425,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448515,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102770,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124501,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3184,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213406,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118469,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132497,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149454,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106472,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198117,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205201,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16181,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152943,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144484,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234981,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61342,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40420,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65179,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 498318,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36148,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164647,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122765,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76514,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99812,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 739446,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 666652,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73005,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230250,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 521404,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45042,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138484,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179254,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96322,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 146836,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15153,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239771,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54689,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107671,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119103,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56786,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188092,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 615793,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125200,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137667,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94872,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477524,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466431,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121086,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272139,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255014,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259325,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239758,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337689,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223160,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97330,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94608,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152815,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61898,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 554558,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335709,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 588395,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68570,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 96204,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53953,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99028,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 385546,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225129,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 700751,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137838,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177674,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68492,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91243,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95157,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21612,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396153,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88307,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98200,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204670,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222055,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58667,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267917,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172841,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82653,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 373681,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21480,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73313,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44982,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 510662,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 325868,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67288,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 597421,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334943,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193801,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42124,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100638,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106196,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105432,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83504,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51037,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10274,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18808,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17401,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46512,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70794,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50612,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60525,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30554,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24871,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86336,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8331,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106003,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159804,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 271687,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94743,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89805,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56649,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85097,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65645,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33977,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116778,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125171,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7215,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9328,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69679,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140828,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75041,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38982,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94534,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64374,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28445,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139554,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220193,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152104,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12987,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39297,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94077,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88673,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29078,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75627,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181792,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25211,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184994,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101341,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25022,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80471,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98394,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241659,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23374,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76140,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104669,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103172,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12290,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133767,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68369,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 184770,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107808,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183155,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125586,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66186,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116675,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19404,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44659,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77032,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14113,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184213,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103547,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126151,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 162314,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328139,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2130,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132640,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56509,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298400,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42062,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91780,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44275,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233673,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108077,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107283,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27542,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112919,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17061,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17484,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41775,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3890,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31121,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90727,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50394,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82722,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50439,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122917,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 318092,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79783,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95809,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326953,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40401,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14863,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153871,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211195,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76123,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 583611,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93011,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107825,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60088,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14212,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190249,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102853,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37511,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220332,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37927,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92694,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119679,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22905,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131330,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110130,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104776,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364730,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364068,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133897,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123585,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45697,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60136,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 143103,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192559,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128672,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189135,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34419,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171531,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 184775,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16391,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12985,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270458,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110520,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7244,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24618,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83678,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192542,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95933,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156511,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37589,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47270,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116683,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57388,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249345,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175874,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 475349,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166917,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210730,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35772,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310446,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 94836,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36850,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72915,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176007,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493846,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 419684,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210794,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87600,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153737,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38789,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43874,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112940,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227565,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 588959,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48129,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 273230,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163665,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 454883,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12451,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82411,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159838,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 343056,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173329,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159472,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435691,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35319,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108533,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251406,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 267326,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 103777,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134321,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227656,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26099,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42184,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43081,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 358867,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14441,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184489,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 449800,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 198889,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 102851,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341282,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 310650,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555731,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20613,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161873,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185719,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 730501,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59186,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 302617,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 347022,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54170,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 276689,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313838,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365816,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 445315,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15199,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234377,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403181,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 162627,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 594518,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 368327,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250975,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159479,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364978,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41943,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73534,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281518,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21718,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124053,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189633,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12358,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98538,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111843,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36899,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31597,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45502,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82638,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23007,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18023,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42075,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21693,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 173800,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140737,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28439,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27739,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68099,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57669,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40258,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24858,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8892,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13914,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113526,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148731,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24741,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20363,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49047,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94165,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59200,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132364,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168921,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103487,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69594,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195709,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16759,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43371,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270619,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70530,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5705,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188704,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34906,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107499,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150231,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181592,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34053,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42923,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81187,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13023,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39040,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46122,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117409,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9583,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17430,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156128,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36568,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42329,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104176,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49647,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57788,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71271,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32937,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34755,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51662,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12535,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98744,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156486,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132115,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18775,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117718,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99400,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26117,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27805,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113115,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124971,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156161,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36143,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226383,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274993,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 267065,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119679,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262908,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20811,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170068,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266855,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74273,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 742806,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131515,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170841,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161752,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39770,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38534,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199785,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170721,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74610,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22686,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108315,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50141,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111296,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47389,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85464,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147096,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63321,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138709,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58468,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98967,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64980,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43847,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7888,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95723,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52179,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 158211,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144130,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31520,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101112,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204843,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85765,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12377,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91410,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232108,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51193,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36703,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82506,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233284,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32350,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158810,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68059,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106176,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284273,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196025,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29563,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157682,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127938,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91447,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18969,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51895,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47851,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22487,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 79970,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114870,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61038,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21091,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29178,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 627241,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194064,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 229168,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72822,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96947,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21805,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 275005,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209149,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 309680,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88273,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258828,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 628929,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43565,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158894,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181764,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 147108,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25762,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148865,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302443,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187442,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115670,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143504,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 31218,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142430,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148438,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21062,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421503,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259538,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41150,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23928,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288714,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 645292,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68978,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107249,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147090,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73830,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213574,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31581,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109729,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 361076,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154648,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23670,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139130,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81970,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117581,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176145,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56840,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49932,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281066,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106346,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122217,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 206815,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 644734,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540005,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141608,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 101691,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 518535,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234376,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 586567,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 50807,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391340,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251378,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31312,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472865,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85629,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203420,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427209,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370688,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33100,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166951,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196278,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66157,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 132926,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134935,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146995,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91306,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339252,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176686,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 597786,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 663530,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35970,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329146,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377632,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209313,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30398,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 446742,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 532511,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 492691,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348505,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53509,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 582570,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30387,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435307,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285741,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362285,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88401,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89158,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30271,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50432,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 9631,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120354,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9205,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151544,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63470,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115231,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27018,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84593,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39648,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120976,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18281,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101784,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74126,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80008,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223888,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76134,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67622,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194382,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 218779,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99175,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121453,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63966,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143270,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42657,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10993,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35801,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95958,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125155,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115740,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35285,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228488,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73535,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118327,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 168735,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51205,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17420,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159504,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113920,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70160,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136909,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168650,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39460,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69464,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110407,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21788,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39433,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52086,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122457,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142807,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56068,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305271,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31145,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144521,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46002,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30607,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14219,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187926,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138250,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81861,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106748,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118119,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118194,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33821,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37324,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40160,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52139,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5344,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 397943,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30297,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108756,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 310738,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25246,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27459,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110257,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96067,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256846,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 219982,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114911,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10632,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93950,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21694,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94299,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61972,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92022,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 168643,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59036,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39230,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93742,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123192,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104001,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48686,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11833,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97082,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193217,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81837,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 501877,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14865,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241436,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 270120,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60763,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202137,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5378,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24540,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133692,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 234505,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152959,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145441,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38001,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112197,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95821,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9486,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102926,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90790,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131522,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71228,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95351,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146480,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27188,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125665,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143580,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29406,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115592,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76453,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152107,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280005,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252259,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176796,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140868,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134000,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71674,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64122,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31219,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123530,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110304,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82359,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18320,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138536,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23321,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29968,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 298186,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166235,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121857,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66061,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83970,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239041,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 6492,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96923,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64778,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67929,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25040,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156988,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219605,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127735,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182428,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9164,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108324,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24016,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180965,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156839,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75161,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192502,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24030,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36144,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17853,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107499,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 168317,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190763,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78075,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306844,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82808,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37649,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43112,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107862,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226280,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97940,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82803,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 140093,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42629,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53337,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38416,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 512552,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104110,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116842,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65789,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181791,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250942,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11077,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135486,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122447,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424869,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114069,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98779,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34978,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285039,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 387764,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78718,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139801,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39546,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55342,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85960,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91845,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83090,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204952,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76079,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185646,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164098,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 590576,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166629,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125923,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160673,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 146889,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242240,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 167721,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189266,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274213,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58916,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49906,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37556,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222159,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52489,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200855,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140616,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12689,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80991,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133842,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191286,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260360,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 565620,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 565400,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371536,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 246073,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48476,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78125,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 291998,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121135,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84085,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284253,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71116,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 311500,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467877,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290476,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233829,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 446779,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406149,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255955,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338912,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281637,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250834,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141653,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169736,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463758,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 157210,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184531,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233841,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233249,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489766,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319178,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241060,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59623,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88079,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102974,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37209,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72132,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52884,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191893,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58834,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14240,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162656,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158148,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116037,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9848,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4901,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23117,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56158,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9020,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61881,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160292,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95330,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11473,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122208,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93244,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22466,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28604,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119922,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50935,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136000,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106080,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35983,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19974,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14862,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11864,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145587,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19514,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8554,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114592,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30338,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100597,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108781,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207121,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49399,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85988,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128096,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83917,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115626,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213703,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14285,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112629,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162872,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83928,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226640,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40888,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69755,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80408,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250723,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38313,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237767,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7473,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11628,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68436,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24964,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139309,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170653,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17518,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11632,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 166813,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183958,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117982,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239495,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73984,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39617,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19868,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 15554,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107611,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187823,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96388,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158223,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10389,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24417,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6394,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15994,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287613,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136495,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536331,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110292,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10898,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 324117,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29474,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44069,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269048,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97726,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97640,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38319,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87877,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39899,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124580,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174964,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28675,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4953,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22366,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28768,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291517,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195332,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79124,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8371,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216512,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83268,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133231,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19332,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40262,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30692,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25638,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82377,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27109,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132890,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120931,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34697,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24882,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21099,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178087,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94288,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102722,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160558,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79762,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137499,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84531,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37798,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172911,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85236,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 92906,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120515,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22695,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189093,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144275,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104346,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257163,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38387,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354676,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111845,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96807,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30677,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96733,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33792,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121022,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36115,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79520,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 157166,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20883,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151389,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57529,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56103,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229957,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106666,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58055,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27713,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162151,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177604,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171372,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219161,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 306595,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127886,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23508,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68464,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263369,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50498,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60081,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101090,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9364,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164500,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109674,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123354,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37148,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11413,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87998,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101562,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144135,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117688,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119576,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235678,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341896,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181599,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132012,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456626,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 127425,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77778,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106632,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183330,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241971,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276287,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 357022,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 447345,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 34071,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269623,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52578,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111388,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160951,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128092,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44328,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182480,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525665,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42684,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509024,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 128676,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255370,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278207,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104435,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155782,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41514,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272982,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39018,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90324,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84916,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82814,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86718,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193012,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479325,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119834,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83841,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64265,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22078,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415599,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 280979,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324448,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80872,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211749,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107953,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455395,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164958,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15649,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68083,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93332,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46843,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99463,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187602,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 340630,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290398,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516357,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422659,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 138832,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145305,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156994,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 471099,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65628,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 199752,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 249917,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441757,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178128,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227404,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239918,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572928,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402535,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466920,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 307374,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95892,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 279057,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250167,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72469,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549775,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633283,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 510259,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122159,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 655394,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39205,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 608243,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 131013,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93457,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210399,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57930,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37932,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7186,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30630,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12371,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108284,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108888,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86622,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 1898,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76353,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107622,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9357,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22876,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49566,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51629,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50778,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177501,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 184482,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 202178,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22289,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253233,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76888,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109350,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100912,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17108,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10017,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153797,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20838,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62203,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38289,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88458,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38864,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116080,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21162,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28674,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135567,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31533,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316800,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131573,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87252,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170701,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130428,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291807,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133580,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141651,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31713,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17868,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112538,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288405,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192727,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195817,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59646,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109874,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90506,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70098,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15507,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7417,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91443,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264380,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265644,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80270,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35528,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74226,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193847,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21034,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232110,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9539,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19551,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 126195,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209596,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94964,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101855,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177749,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158238,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46361,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81442,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47233,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36300,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11519,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120285,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90641,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 120196,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55456,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83007,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164030,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131367,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 470514,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9168,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129053,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89412,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134535,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130809,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143681,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124754,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59447,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15195,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135132,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131990,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26365,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201616,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104101,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 670974,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73697,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47579,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84896,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76596,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85657,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100564,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214887,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52652,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193743,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150274,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103631,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16479,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33165,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65979,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132919,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85142,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 256050,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111928,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 179428,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34865,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98287,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247455,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80762,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 216110,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159767,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110564,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4757,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69672,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86071,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 528660,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 140399,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151494,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383200,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81708,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191265,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169908,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50930,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137274,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101075,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272149,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155557,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32923,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103014,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 717938,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36849,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165633,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98789,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158063,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207187,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15043,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236569,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100452,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61852,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130607,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237679,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45126,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62800,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128763,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227147,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 357203,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40202,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120279,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112203,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163940,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78160,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50764,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96667,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 511342,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 318212,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216684,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98047,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508554,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30549,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119505,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160147,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191029,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36814,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66113,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 593659,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108273,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111449,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138422,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 19922,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79342,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523939,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184436,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140920,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318417,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30116,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4885,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163520,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224214,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17265,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289185,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 499694,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48171,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117598,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320596,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82490,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24688,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116999,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529320,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 338449,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302509,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42907,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65689,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 134427,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51991,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177996,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10699,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536959,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 468635,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73742,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 337964,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254585,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38481,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292398,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199494,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146901,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81852,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34789,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 505081,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 386449,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 157762,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114641,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276828,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 454168,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 260833,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 276999,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54331,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176983,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 490445,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171844,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179877,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48966,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34682,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197377,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508650,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 127247,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248378,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 531485,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304606,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424378,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42604,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39485,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380309,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81929,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 500497,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81501,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383175,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 220080,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315301,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 106356,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54560,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272729,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153660,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 632306,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 126403,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 370394,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13440,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141722,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16022,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69811,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13535,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88845,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153763,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121973,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140305,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129902,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161296,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100954,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71158,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44057,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112888,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63313,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149396,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17830,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96303,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35655,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133971,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247116,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55544,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 444961,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11751,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264014,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125563,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106018,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207163,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110895,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119741,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147979,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 216842,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97784,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19127,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74772,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172595,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122060,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56520,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11367,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26800,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38502,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24445,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23042,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69806,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124799,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27645,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140977,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307746,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81691,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258921,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112891,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108929,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147722,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134539,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51711,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9294,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20366,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34007,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211701,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145282,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89296,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14593,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105586,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148104,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144869,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54916,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80326,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163767,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83152,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76642,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35965,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80410,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78653,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80252,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10934,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172705,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115916,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35341,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14515,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155678,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106510,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144394,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137116,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24100,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 626505,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44161,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34208,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24902,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65954,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121985,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120054,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177511,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11983,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17815,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97242,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37955,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118746,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107942,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8968,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216924,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17996,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112277,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142960,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107746,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 130711,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75264,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 121438,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34841,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31916,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227818,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146821,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35459,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38987,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197754,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130401,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10680,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70118,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171714,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41927,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84101,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93624,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138683,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 8841,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79747,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80166,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52441,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216687,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132217,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7365,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95894,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44267,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29498,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104597,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148132,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319029,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24702,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191804,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133676,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341326,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96644,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190653,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54208,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129189,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235750,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55429,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165150,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176285,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282108,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102097,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13651,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129878,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18945,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39496,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145330,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39966,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97419,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141533,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216276,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73689,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53290,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14515,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86358,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34483,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82894,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194753,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129196,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151545,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85152,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98617,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32419,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316029,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284226,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 153022,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19660,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220411,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192190,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85583,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155624,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99080,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48547,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 385137,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 440150,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357821,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17561,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93864,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 290387,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282196,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88583,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87564,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 423600,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 451840,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41948,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333931,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137263,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289729,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 87930,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124322,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 331473,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198787,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468791,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465360,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 202106,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30077,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236347,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285556,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 367111,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37669,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483674,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110916,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315592,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27652,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249656,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73565,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160230,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 362992,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189471,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95310,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110357,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386865,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477833,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84792,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 379499,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 218380,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38866,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472623,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46140,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61420,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 97047,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150460,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372927,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295151,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35384,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64366,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358548,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56101,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89294,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523820,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21714,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 215672,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194092,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214599,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102855,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293691,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159567,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32927,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6545,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125876,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18684,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2730,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249027,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53886,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24963,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126405,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12858,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23149,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28933,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113641,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107773,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142712,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123395,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158959,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13073,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204261,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102543,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10222,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7559,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148458,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141057,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36629,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102775,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406430,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95132,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125154,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57893,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35787,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45823,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17123,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161642,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43445,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18492,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20527,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56167,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 261112,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169939,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18460,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51970,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101115,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268750,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97214,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70314,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33517,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8672,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273513,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142946,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82260,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68339,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168209,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91449,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51347,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9997,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65712,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118121,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23621,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5101,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91760,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103462,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32645,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133839,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73548,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83154,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18248,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 280310,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63661,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52406,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398012,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 287362,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11218,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161538,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 571949,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96596,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23421,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88463,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3719,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31943,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86872,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41061,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91387,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31244,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10856,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128024,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291088,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318064,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122224,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537262,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95617,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134065,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133136,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152382,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155574,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112515,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100804,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145092,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22594,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119453,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317132,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106301,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136394,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174791,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 149934,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83903,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238724,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48247,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13928,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19035,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139546,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202089,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18737,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121744,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19757,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73413,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185291,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20491,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107358,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106734,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398541,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108684,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180850,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228191,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18991,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268582,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120281,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229431,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163016,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118892,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94490,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117579,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108601,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61647,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96793,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198730,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107555,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52455,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288699,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 215829,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103301,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98135,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57469,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63975,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364070,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67328,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155606,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124885,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148922,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 220461,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152532,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163400,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158997,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109457,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 136281,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 333165,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 118529,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117078,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111598,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83173,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169304,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92687,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503371,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129059,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101712,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92621,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127751,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178577,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100633,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 515359,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122217,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269776,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84194,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171031,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96643,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269183,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89382,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438121,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132796,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 299971,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191981,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159136,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42464,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63632,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65403,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148800,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26581,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245085,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112605,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110468,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 162711,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36979,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37692,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 506600,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2866,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267979,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 336036,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132672,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50431,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32181,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79757,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284437,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93745,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42666,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78208,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96501,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174003,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516721,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144052,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251482,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91632,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36026,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359162,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103483,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93824,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177244,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184631,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83798,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247967,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395668,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161951,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114791,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145494,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242549,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12478,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247766,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211198,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419735,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93242,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 86036,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35340,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37102,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160881,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95984,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101561,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 134266,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190577,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185880,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31095,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135769,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425637,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359064,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344821,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 332076,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 539514,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 101691,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127865,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63815,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52384,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115083,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545263,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349825,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636340,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 650786,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42042,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204642,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360797,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 348507,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225558,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167917,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37723,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321730,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136516,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 157469,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362145,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39450,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479192,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 655113,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112374,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92889,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 192769,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83592,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94289,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85309,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149852,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23085,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13452,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154852,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15790,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23037,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93276,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6180,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138287,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35427,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48171,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124749,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183601,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188367,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96643,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85878,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7408,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 165065,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29773,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107291,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 232890,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150695,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168870,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100321,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82916,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31405,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100910,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94290,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114449,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264663,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16431,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154856,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72543,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208562,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448842,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41299,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80798,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286954,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24961,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110983,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30671,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30903,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297052,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138390,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99151,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40507,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153424,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103432,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24095,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155480,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34489,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163817,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47629,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20202,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11865,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67191,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39099,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29450,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93987,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4935,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15419,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46055,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170040,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31540,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78245,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186450,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416891,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22329,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160534,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130639,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6306,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172936,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125039,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252362,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18659,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31508,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99686,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171310,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 452334,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15682,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38949,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32667,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95068,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47289,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22023,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121896,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126921,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80105,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253038,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40666,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 206324,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118863,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80539,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3763,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57739,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13363,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109371,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19654,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 265590,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34571,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36640,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77231,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4845,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123044,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31022,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44123,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77997,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164874,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130713,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182639,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316682,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33358,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65216,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73299,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21471,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139766,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143384,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22768,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77885,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106483,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77838,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141022,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183294,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242085,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182696,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259034,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72395,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73359,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201717,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247804,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112237,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520812,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41975,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134142,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94512,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16116,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11327,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26358,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180366,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 598991,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61457,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300600,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8454,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17341,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 191912,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30864,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398559,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198712,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278900,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46979,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153526,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 418612,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139395,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299037,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100040,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185848,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222867,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98650,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41405,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204121,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123339,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 455528,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154773,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229923,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151878,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101543,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77230,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87850,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214971,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12894,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85715,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11694,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160351,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307922,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151093,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228712,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199673,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26448,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110963,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 126324,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200032,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136981,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67225,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28548,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128606,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326449,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83098,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158191,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283518,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46296,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71969,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53226,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170972,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 506495,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243220,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222319,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80601,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131946,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24212,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85111,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165115,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 467593,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315717,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232431,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222766,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106364,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 154315,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145337,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56692,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 341138,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138933,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267292,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458646,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82204,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170017,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230541,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240400,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62703,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84571,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83757,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78686,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196678,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227663,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168868,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15341,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 361791,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172582,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119847,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191508,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 591651,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486318,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 561217,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 149013,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 274607,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556498,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 567156,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 676744,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56253,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 117456,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218995,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181814,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65896,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28870,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 168876,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463049,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556516,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205204,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 204886,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61690,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24791,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238378,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185483,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 155099,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130504,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438437,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240441,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 85097,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536669,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163180,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469748,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 655773,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273434,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39367,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 177527,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30138,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174695,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157205,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58887,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12873,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304339,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93509,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143404,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131701,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116713,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305559,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32703,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100584,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24609,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32258,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6445,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101564,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16388,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39505,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23862,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230122,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54552,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 392790,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130631,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29468,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325048,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132210,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189861,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56740,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94535,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267574,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133903,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64703,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160970,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16774,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53441,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138379,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50087,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120674,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44697,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 348308,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4761,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121038,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81669,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126129,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142318,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23112,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70917,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162454,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523135,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24895,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40825,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13599,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157177,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21251,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17146,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97398,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84241,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41467,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24975,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121968,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141753,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14654,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185608,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11480,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208478,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78433,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319405,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263362,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58746,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272829,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 553423,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19123,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295343,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136265,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234777,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 123070,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165363,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252811,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125935,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85558,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54563,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96523,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43735,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10649,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9841,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49426,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82689,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 549538,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110266,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138721,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44973,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115482,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143303,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20940,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12968,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9026,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75551,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58086,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175435,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108598,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124295,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37608,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105828,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70674,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83648,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157213,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152457,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183381,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191112,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38122,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136267,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220632,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145354,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151596,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156009,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95954,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110603,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95866,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92537,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196440,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14141,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25514,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52237,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315688,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104747,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98135,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119172,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132546,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93010,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110736,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78582,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115991,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85963,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100410,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136606,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136665,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143168,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122691,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26030,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101676,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250246,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185961,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31093,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24508,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149648,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263862,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47856,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49565,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148812,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43722,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 50710,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259187,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 322368,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86124,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234817,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106977,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10556,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215469,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85492,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42125,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147715,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35005,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143764,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147844,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15576,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18586,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71679,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45599,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65428,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126764,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194103,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101580,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63923,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56597,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138828,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73367,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32054,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84575,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197423,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91337,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196777,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195335,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7343,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188523,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19622,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56340,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123763,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62621,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160006,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71995,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84192,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270795,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229785,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147485,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555706,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7934,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283239,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149543,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 214537,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 251984,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105718,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54660,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43138,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281551,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245129,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79929,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84388,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132548,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 582231,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145191,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 454561,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31995,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365774,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285670,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272484,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34179,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315066,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 702901,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104801,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 712872,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77811,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59873,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67948,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 278789,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 347564,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247297,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108924,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47102,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382791,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160770,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40106,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322155,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473519,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115631,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515107,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 279637,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 340866,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354445,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232761,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149446,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 52091,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214561,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441773,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 75001,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574356,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111827,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73067,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57972,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451505,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28389,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98699,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84645,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124706,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111127,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48781,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10011,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102404,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85832,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 183856,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34707,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136174,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30275,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168305,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181192,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11580,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13737,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 299719,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37851,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25760,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36974,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138146,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68015,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116897,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202266,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27029,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159567,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20624,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32024,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36737,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235350,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51159,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17511,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19640,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184992,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305473,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246538,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64006,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134090,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165235,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14835,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71215,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87704,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14070,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153945,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22812,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 397375,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171473,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84387,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137867,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33983,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152424,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116319,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58443,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81325,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103358,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 94213,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57750,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106951,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70331,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34211,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64635,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9828,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34889,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574600,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108523,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176369,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121483,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23630,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9641,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159946,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68734,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106357,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38219,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53820,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10312,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88095,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104054,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31171,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72947,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77395,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110760,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118232,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70661,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110298,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148111,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91099,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91745,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162451,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13228,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153654,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95017,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110505,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18601,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187580,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90700,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163525,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136589,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89190,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104266,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239580,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250700,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253662,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130439,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54262,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143598,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29937,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105508,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195771,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88144,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152513,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10635,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22478,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140767,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70087,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30703,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57307,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194035,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132199,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99273,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21407,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104969,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75967,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53156,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125228,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104015,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72667,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67862,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35597,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16418,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126514,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22953,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146079,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99036,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200554,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 422518,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17306,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459285,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233380,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357251,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145124,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139770,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250802,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 422173,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125558,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141160,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 391683,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72281,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55762,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226964,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141151,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90053,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206755,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61695,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49342,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215511,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105249,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46360,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145537,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126469,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81409,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13304,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 303913,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22883,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23436,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82369,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377609,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 302556,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41388,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 306525,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36999,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256538,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73223,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112155,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225308,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 168276,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85839,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83435,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280711,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 129419,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 151587,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158678,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277667,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37527,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178187,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178274,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133654,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95022,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73633,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115201,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36039,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243531,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29396,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193776,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46342,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123716,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 280500,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184440,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240228,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69576,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62498,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 334116,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251316,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129800,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118784,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 487020,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117410,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179605,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224860,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272623,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 109178,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214974,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 597334,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37242,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494903,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58774,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178702,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243510,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 270890,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191229,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41629,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131613,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213470,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 301270,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 366983,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386670,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265207,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 74936,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 620796,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 38546,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73931,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126077,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72036,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 223167,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249233,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465220,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178023,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90280,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17952,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56868,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117281,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11036,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335581,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11313,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50589,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17795,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232663,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51276,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16515,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86516,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67618,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89960,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18451,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4444,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90192,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11021,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93210,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197619,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37905,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224992,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28934,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66511,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54663,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252750,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 459069,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101654,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88684,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40691,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95601,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194941,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88665,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27106,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106990,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34347,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29081,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102850,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29432,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180655,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17226,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114768,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12355,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114012,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61647,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206886,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96363,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90956,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11272,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63965,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11736,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96045,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6209,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9536,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284623,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7022,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123089,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165975,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111484,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127704,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58727,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229795,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73885,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15821,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25228,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237888,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158339,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 163069,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143404,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43386,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5781,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146798,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179407,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108089,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126223,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160631,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95975,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86249,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104592,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9450,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35502,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76068,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239091,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259368,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277424,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183053,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115858,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86401,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54823,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92558,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89809,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12793,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67516,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69604,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103092,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242124,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49828,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95921,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168001,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9588,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 369690,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161752,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173771,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 321147,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124814,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30464,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114993,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68428,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 142282,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198146,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15285,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47698,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103702,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75338,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110207,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271668,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 334584,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115917,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126358,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6755,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39249,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82838,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10236,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103470,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186975,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24211,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49962,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85411,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48979,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21601,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90016,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 534859,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62586,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39224,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58196,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19881,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45412,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86029,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215576,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178211,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396388,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 303710,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50471,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166187,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120119,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85593,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245691,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131127,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175940,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136584,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114604,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51533,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105460,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12969,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103531,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16348,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16303,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 394604,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8247,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84200,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360845,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77750,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 135622,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141112,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303780,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25158,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82326,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142704,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 189513,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9618,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80023,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101413,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174512,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20170,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90918,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208890,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65110,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62603,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151481,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26258,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93948,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 535108,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117008,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73948,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230988,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120884,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66462,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26378,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213678,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145951,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74424,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292902,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 331200,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221324,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 518022,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70766,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493486,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310179,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65796,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239428,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53857,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 601451,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33501,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68539,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255771,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254029,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22849,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96624,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516485,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415832,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14627,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41681,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205515,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61205,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43562,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 293655,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47557,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67119,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191235,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91930,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202154,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22200,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281167,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390694,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 506890,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 344977,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216697,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43234,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131555,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128356,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249629,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83159,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401514,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291080,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 410256,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401145,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 130762,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82151,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80613,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328757,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49693,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69915,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236268,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 524760,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496192,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65351,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 518662,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75497,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472428,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455703,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486351,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509064,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51350,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316751,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 269286,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40894,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291513,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208087,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 153949,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295743,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66110,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206296,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305728,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204404,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 107638,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399472,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14805,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118550,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103806,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17252,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107629,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6488,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49825,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227272,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14210,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181861,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24363,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156008,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105604,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85703,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126533,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118659,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27914,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9744,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89852,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52035,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10418,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80571,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102257,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83618,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40506,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144941,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62207,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153506,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11225,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118757,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91353,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238237,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37071,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257668,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69765,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7376,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19106,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 264726,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88959,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104747,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85287,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 309152,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129896,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134203,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72524,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4723,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18921,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232550,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127754,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68675,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123222,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15258,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70376,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135926,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146858,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146960,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3575,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44117,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22316,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12728,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76113,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43343,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87982,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 417722,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19407,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 476357,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26427,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31959,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129551,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40720,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83383,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242856,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28492,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183135,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233481,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98691,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97169,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 354085,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20401,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128773,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120134,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165649,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102259,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43927,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432706,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35181,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 587623,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53168,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75213,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26262,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23071,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145406,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185976,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10691,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263188,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118865,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79782,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167728,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163350,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 374724,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 321930,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88063,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49961,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119421,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69356,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121783,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 300647,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100804,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33782,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133186,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 300164,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57014,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99287,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62211,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118898,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204755,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109567,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27340,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40446,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135083,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90635,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9011,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123243,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196726,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373507,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118898,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129178,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272964,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113666,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139043,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96304,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298346,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63092,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269775,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118815,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135212,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63792,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143022,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135030,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170827,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118464,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13079,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257191,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273232,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29773,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94695,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222310,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112774,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95722,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66617,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40767,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213600,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36292,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26412,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53228,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132333,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199986,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105873,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168417,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101916,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 226989,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285042,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139442,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413484,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111280,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213824,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112270,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132272,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39266,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227970,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272219,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236183,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52322,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141033,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254606,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7523,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108461,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204628,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14018,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97264,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142373,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70992,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48251,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259378,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 137511,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22376,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79051,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419126,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110845,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150259,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 412184,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38025,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178359,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178669,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113409,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286689,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235173,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 299342,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58776,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184805,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162518,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139407,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 369983,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49936,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341659,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246077,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57108,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362678,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267791,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240215,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 309278,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119692,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 728190,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85503,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451582,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 522977,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330954,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108921,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124565,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151082,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50270,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171337,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207731,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 557117,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70225,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55742,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161988,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91475,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186136,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438238,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236360,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654741,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216317,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66117,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130109,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54383,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191111,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170804,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186308,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76238,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191261,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 667128,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53703,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 592158,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254839,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534399,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44873,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 75029,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474870,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54023,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305012,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333768,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41797,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267420,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374068,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 491164,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155192,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54949,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 265158,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62634,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99362,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13860,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200948,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185129,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31577,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89010,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95703,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64363,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82664,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 186922,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121625,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112069,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6643,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226411,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165244,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11053,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98474,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174915,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36815,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137413,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129437,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32640,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10075,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18058,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65002,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162814,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179451,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4093,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10860,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59492,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50876,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121406,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104914,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 164937,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10709,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122007,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141086,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77364,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122778,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201492,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160330,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8058,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98039,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72755,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172227,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32300,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28621,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71351,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75904,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119542,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54789,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24057,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21098,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32780,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79315,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82171,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177982,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122293,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189006,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63816,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150750,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437113,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105247,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10832,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30671,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10867,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53807,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62369,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29430,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14653,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56548,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120365,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114087,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129401,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134887,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191467,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147301,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100930,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18339,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51686,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94741,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58582,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52024,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85096,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127422,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143861,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 164445,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41565,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134514,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 74363,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124261,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53043,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123094,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75232,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145763,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97065,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160253,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131091,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17567,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19576,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8011,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23104,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 411749,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83467,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164034,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129223,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19038,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84870,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70145,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91881,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66966,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147203,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31460,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31471,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 404864,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99631,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111269,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 403227,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57165,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 127725,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67571,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 219502,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9048,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127372,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 422451,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119456,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 222706,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12471,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62129,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69346,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157117,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 376117,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349267,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119038,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7180,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169071,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92282,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58353,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165538,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124209,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8688,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152107,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110138,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120372,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328282,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211027,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 524932,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189126,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10355,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 445358,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70144,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15704,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38719,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185518,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45005,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158611,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139259,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178860,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63531,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90460,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189328,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373741,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166057,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60830,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161892,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115041,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21878,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349412,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96476,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117612,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130381,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138231,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334272,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92792,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240186,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 102389,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266237,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11956,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246493,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19221,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16545,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20551,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75466,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 433419,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39829,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191337,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87994,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139986,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134937,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183797,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96149,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151879,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323856,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105965,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40944,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147053,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158753,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189754,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418519,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73351,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58670,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108393,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204037,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281183,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 13292,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103239,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61307,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40815,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 144703,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157028,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58227,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160843,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 652945,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230855,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147816,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431590,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199165,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438463,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 384742,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202903,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 563591,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 130746,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481989,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26316,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46920,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37241,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25831,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63699,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 376106,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379238,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 593187,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 285351,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32761,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457158,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308962,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47687,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421707,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 395750,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108244,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 171571,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42736,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277448,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 307877,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319482,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186941,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150804,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426864,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204405,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268545,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137165,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525668,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78581,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 163006,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11059,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116751,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96223,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159840,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136264,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82705,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41875,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28026,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83718,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18153,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10437,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116062,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321167,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76914,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72110,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120054,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13161,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16099,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169496,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215889,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30144,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27510,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85085,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56620,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109845,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242590,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44654,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66964,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 366929,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163982,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93332,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93979,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17663,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315964,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71558,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62802,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105651,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42096,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80350,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181225,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269622,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 306142,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37742,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149217,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97945,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18095,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21201,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10988,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94297,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104248,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27936,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73408,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136619,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85543,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41746,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108060,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178450,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21177,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98474,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93960,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47683,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29506,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92504,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118835,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8864,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19554,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87254,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228124,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92456,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75294,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315943,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43533,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159244,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161328,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 171380,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97408,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206032,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228330,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140580,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 336832,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52894,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226163,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36082,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130573,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24999,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110863,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139078,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23775,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27615,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451594,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59156,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68513,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25349,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17440,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 300436,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38398,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81774,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 717662,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7700,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151352,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79792,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22471,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93754,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84845,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183974,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37971,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32558,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14320,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144585,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185439,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165834,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137435,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73180,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28159,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82598,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91478,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57279,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17547,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3027,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75314,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123629,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30699,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232566,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91875,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86964,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 664508,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252584,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65894,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 271296,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81169,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256600,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130935,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229088,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106377,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 218676,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26196,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48277,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64641,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46084,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 60565,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230458,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243142,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34579,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395404,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130544,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134514,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107700,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36279,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29515,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32968,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242169,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150609,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138737,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148051,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309645,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176405,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 338011,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42886,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242722,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144364,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117036,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 597339,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203066,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413785,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58815,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106562,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124475,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94555,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134041,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28317,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92867,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55877,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97864,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47509,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90324,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99827,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167938,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87186,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12472,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135343,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127464,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31439,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 603332,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15112,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 36119,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30236,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174639,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45086,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139829,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 448217,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117365,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194607,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 340404,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12778,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 173680,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 277562,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126231,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200648,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74573,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66223,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161366,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 248542,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90407,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222598,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 419672,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148512,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143140,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357525,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174040,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357291,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34234,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 76547,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 324086,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196291,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27067,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207017,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255770,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163905,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225736,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45650,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108098,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448116,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224944,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67369,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39924,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95586,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 223030,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 334624,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25564,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 460733,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415564,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388152,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12149,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147594,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47316,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67438,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156720,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19243,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545586,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15098,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49993,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52227,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81478,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136298,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150099,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60778,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13621,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24994,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17624,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12438,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25651,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39081,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133641,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78955,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142118,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69450,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110241,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81136,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34340,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16301,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103475,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131833,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39302,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88737,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160026,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181381,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33787,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75716,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76771,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39044,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489215,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132688,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25717,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106098,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147833,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99607,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109509,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308940,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15194,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81607,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85195,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45713,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16045,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103214,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148854,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80408,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30532,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71399,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121927,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226449,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81700,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8381,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137130,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104398,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209827,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125993,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151952,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8690,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39610,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168730,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89837,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27979,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46173,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68308,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26417,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16443,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118528,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97957,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112301,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151089,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221824,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46448,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16764,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218004,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 279943,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119872,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10352,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125708,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174688,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14366,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113551,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96607,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32036,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202593,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327292,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92220,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13201,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141516,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128535,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45787,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38549,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 478307,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121159,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7062,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106656,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101892,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275210,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83447,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74815,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 411370,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23785,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73612,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116005,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11666,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136569,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141763,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50645,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140631,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138967,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12134,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220193,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18173,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25803,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160347,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27184,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154440,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128999,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23916,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191474,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8955,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124507,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 437113,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222630,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 113190,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150299,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 170514,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70468,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118953,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97081,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87029,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341285,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6411,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171804,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19978,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59471,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142271,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52624,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181560,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286626,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 566674,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287877,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151418,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315559,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41834,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66509,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 578354,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 435175,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131568,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75035,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33275,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40342,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 272337,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93490,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90921,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116932,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68913,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100517,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384408,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399090,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95898,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109616,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29483,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 346722,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142236,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180820,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189412,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 594009,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209333,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34403,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120624,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430742,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101894,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138840,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166508,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152830,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124108,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 366913,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27932,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186374,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261158,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204953,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234589,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142395,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 62914,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13550,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16422,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229449,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93218,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343738,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238765,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32010,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256447,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147550,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219786,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81723,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18511,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81477,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122109,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203127,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37338,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85360,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136452,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 285340,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555401,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83873,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275888,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6210,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108221,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167471,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108206,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654089,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 134482,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 32677,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 90943,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343523,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65522,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218499,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 635403,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 113468,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144762,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 180464,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315038,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11721,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402929,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155218,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63896,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189826,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51922,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272129,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 154202,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 687329,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65015,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342527,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 38532,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391899,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 392421,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224263,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223583,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 131270,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61723,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 686572,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250275,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540652,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 623475,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 680393,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218201,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 368272,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333064,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39065,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29522,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242259,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125248,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15433,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115745,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41978,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10490,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112013,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15287,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65494,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 208266,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128899,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24534,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159544,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6057,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78937,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106848,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34545,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21952,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75540,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121725,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165324,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31989,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10325,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130809,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106847,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568846,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88297,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232566,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25089,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18198,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93077,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128664,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153449,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99106,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47866,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115365,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240927,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30373,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31391,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119237,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113818,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125764,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121461,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74424,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147872,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150446,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107253,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218702,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204056,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28156,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87950,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31181,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140764,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131457,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118097,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23563,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110380,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52096,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32069,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17416,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 325099,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54365,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280101,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69700,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13186,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16878,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110973,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89196,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122724,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79333,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32813,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20774,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116525,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54972,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122252,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15176,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10459,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38028,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103532,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82022,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5123,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126066,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20710,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228375,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23524,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24495,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182899,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114846,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83928,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 666216,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28186,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42784,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8103,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79227,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246066,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128507,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92411,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508145,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58209,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27575,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 129978,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3376,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26154,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24671,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122708,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129456,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235927,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 154366,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217865,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57231,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160806,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8433,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88216,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39164,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13064,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43238,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86202,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79847,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330570,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21845,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24053,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89299,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12501,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115776,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65381,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250021,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187630,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86042,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232286,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128295,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29859,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117933,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17354,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68779,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123075,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90854,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79671,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16928,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129087,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14435,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191068,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177253,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77551,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37183,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95637,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493567,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46923,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40235,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73649,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274274,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228023,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98172,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80223,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185130,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104671,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93978,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 389123,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149029,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94062,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93330,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133058,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80548,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94825,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24581,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85009,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24379,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 554395,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 521393,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27182,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80925,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117523,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207604,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76430,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65831,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106385,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68090,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93227,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99189,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286388,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173225,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411501,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125927,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40461,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124979,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47499,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60263,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269965,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110792,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107016,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80448,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 628615,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109777,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52012,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82999,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13239,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357942,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121884,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260560,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106910,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343210,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68341,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38616,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112627,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227790,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6014,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8767,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49630,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30680,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178894,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45758,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69465,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 245647,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 207663,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20187,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50997,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195889,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194806,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192289,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303010,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113193,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140671,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143292,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37919,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41820,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145181,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43121,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313611,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 396861,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106220,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 211259,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358576,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75341,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30796,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18227,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435921,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 399653,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77845,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188251,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99430,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139089,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220104,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 561665,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223770,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244350,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 490053,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 495801,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197591,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 659346,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72710,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 566402,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141980,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295313,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 609848,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26773,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 546726,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40481,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34203,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353027,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114452,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57007,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126979,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168876,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176714,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25981,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133422,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249600,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 145772,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416448,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238218,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74088,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68387,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22897,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142593,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158766,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35031,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111988,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36837,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12787,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86418,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68989,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160164,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22005,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257226,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13347,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122166,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132411,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27999,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179863,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63071,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141113,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84410,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153902,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97445,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12144,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127653,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30000,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109563,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10340,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51418,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85836,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123251,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60581,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77264,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314134,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 524903,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37370,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148221,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112652,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96086,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103146,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175725,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92536,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44773,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75565,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407408,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116834,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197093,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22705,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34627,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71918,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64633,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162112,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142894,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115077,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276699,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115267,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144988,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38462,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79632,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99742,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25228,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10233,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21559,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105670,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101261,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81695,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244911,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22441,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81953,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65455,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127468,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185492,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304056,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159785,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27475,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105475,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145305,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21815,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139763,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112427,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13400,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44472,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71795,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108924,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3481,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52566,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66681,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71360,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103293,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14135,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209089,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84413,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36269,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120214,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175130,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10624,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6886,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24294,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 396976,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81597,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 725551,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92355,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122081,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44865,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169780,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98557,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70014,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88064,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26704,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81044,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126795,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96952,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123980,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275744,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88719,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291177,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67655,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205366,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126615,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132604,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275476,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25733,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82471,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19520,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147173,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70751,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85785,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157500,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88254,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23553,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99946,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23272,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46459,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110783,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 189619,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 584490,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102234,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158475,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 661154,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121109,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61229,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344640,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42868,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38370,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129610,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37649,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71856,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14194,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83844,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143082,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65186,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8472,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36391,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133200,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89127,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65320,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154420,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 540084,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 374651,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102699,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114251,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121214,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97674,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131909,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194945,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183406,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 131559,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116583,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119035,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229099,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171055,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139630,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39657,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52991,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396675,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430279,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7925,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163052,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195415,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147388,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133792,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329348,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248466,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182487,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 134673,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68619,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131366,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90520,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5701,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45497,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55685,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9364,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89434,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64591,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142211,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156249,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31363,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105154,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117570,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229040,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67298,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82316,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38103,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71017,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184951,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410941,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156060,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148672,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100951,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444582,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 431547,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31141,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41242,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182119,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144823,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90447,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198442,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199079,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 622508,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67259,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 85146,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 683635,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92744,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219333,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142521,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132677,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143608,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424764,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 501337,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 75124,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151526,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418401,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88869,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263057,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88335,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 629295,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33086,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295151,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 569006,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394778,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 128597,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164934,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227014,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 665400,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217140,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 23971,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201887,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520551,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237058,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12556,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430572,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 366388,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 169918,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108721,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104649,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114445,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 702750,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408073,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159816,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28891,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174001,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2555,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47888,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106178,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10244,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129460,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12763,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41105,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111963,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104900,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112753,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104280,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106106,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118421,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84110,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17242,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87435,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64143,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147545,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127513,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149755,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67752,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75081,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41638,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35531,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 658283,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104365,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73808,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44290,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90879,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104448,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 541359,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239625,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35982,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73998,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29656,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45874,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14558,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18000,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43423,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139254,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32280,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111676,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18747,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95620,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73076,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119626,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136899,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155875,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408808,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 166591,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156097,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102576,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75056,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109148,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151355,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263204,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287761,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25925,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190593,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183037,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40072,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39292,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156640,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204864,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67479,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85432,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124918,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43881,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41491,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9379,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16085,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36692,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48821,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70625,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238374,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116750,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100506,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69834,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94355,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81801,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20657,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209338,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297194,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 140112,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150764,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19088,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8861,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63491,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101378,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48466,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320981,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116242,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101664,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21876,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44358,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82274,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85346,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31350,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101311,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69666,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219776,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 598551,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101605,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127406,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159686,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39839,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122873,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119399,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21358,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36392,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13847,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85796,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87867,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45442,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186597,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148656,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10907,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121103,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17225,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146980,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159307,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101773,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219869,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109832,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29559,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46625,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133306,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80277,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62803,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 620114,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83305,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25637,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170958,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104285,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156380,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95047,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507674,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145706,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27030,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8701,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 378220,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274625,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 236511,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13253,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71888,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110635,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51447,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112938,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13663,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99861,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101329,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42450,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242185,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 419467,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95382,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147645,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180952,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512072,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177063,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 487014,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202287,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124440,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133938,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30333,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7038,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144158,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72498,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75568,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140514,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 334658,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169585,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117690,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73533,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135895,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258141,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263914,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113614,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151585,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207433,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143343,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166348,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171961,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57909,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278376,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56746,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 177436,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 649649,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26432,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256059,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60768,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65131,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135650,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270692,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235981,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71138,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183158,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34845,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227407,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170714,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131407,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472380,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 218157,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 504295,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56254,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12660,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137561,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204472,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56376,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275006,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173211,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55726,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123851,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23087,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271970,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39125,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76845,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27454,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 185366,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76361,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22051,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111051,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30038,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54351,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106717,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108454,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42622,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391936,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54683,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 369953,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507074,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 118487,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451223,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 331543,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529341,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45619,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53623,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206925,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154424,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 548589,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34712,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55893,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72589,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 126561,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82762,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200895,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 27209,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375213,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286985,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253156,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26575,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245560,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304852,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465829,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189330,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224086,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 176106,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 251257,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411354,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297048,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 443837,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382912,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156280,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39490,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 578313,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290025,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7080,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68906,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32810,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93525,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113869,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50056,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15399,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163765,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96700,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18552,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160282,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 308454,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132424,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232287,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105777,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60489,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17158,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64591,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124558,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32357,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104279,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35844,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125754,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7401,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66502,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202397,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91356,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173863,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169280,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71460,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128044,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143464,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83358,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6795,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123902,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103162,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100195,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27115,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84961,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102508,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149568,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139112,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38161,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161129,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137530,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95327,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39724,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49916,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40688,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54525,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11947,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40284,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178424,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50080,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157107,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101023,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18892,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225818,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12792,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49463,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83749,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78067,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71019,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55936,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108486,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 271018,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18343,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214854,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114023,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246987,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32132,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 243730,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129196,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78585,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163593,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111181,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20898,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90542,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99485,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 401543,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152019,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248352,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80097,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68844,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97634,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195066,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11590,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98590,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252112,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46992,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142730,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104629,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141034,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68324,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65398,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117959,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125667,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26922,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76174,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144025,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127247,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77927,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 485918,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43324,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182661,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53101,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130439,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19956,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136696,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143914,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190806,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84498,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17364,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40377,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96772,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131428,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42214,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31261,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86007,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33854,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22359,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173647,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181495,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 608874,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118930,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298193,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137235,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215247,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30666,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377601,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215731,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90502,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61967,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112859,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113359,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179012,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12946,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266982,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125365,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56553,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100917,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49079,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119260,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 497740,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313234,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183308,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130737,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98414,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276710,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76609,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98171,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92487,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142588,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91448,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 405231,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218671,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66154,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46315,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58136,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103100,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65784,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35214,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210447,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116299,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178912,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57573,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123122,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83446,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69265,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159286,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167881,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127266,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131550,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141877,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161274,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88918,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 362879,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225660,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93301,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228951,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98654,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88123,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 381130,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139392,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65556,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184830,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77318,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130220,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153983,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267932,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184112,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21714,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111140,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33781,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 576875,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556018,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148270,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317552,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29794,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508742,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287540,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263172,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172171,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326636,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55725,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247333,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78875,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215864,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194858,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147050,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49980,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122925,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106414,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173911,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223403,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98144,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63989,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326392,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251443,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77898,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396480,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117924,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413535,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 434283,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488411,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 96097,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268927,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406029,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40412,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545879,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75911,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187423,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33963,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401360,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151907,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16109,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429398,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255648,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224082,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91446,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186007,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67981,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297258,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198895,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640653,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429966,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149558,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 235989,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26062,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 446876,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 676449,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288099,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44274,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 453039,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 227680,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 589714,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158253,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26055,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177667,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 142901,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 729415,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165929,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190836,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 48788,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513566,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218241,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 242264,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269170,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173513,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21757,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114659,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16239,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212900,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75711,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92117,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11251,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15904,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120916,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152064,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20408,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34569,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74681,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74907,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83110,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96744,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108585,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65117,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13413,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134760,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71086,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117762,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114441,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104298,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93126,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42481,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17628,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41593,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22110,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114823,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16572,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138888,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133696,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18041,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350357,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19994,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27766,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30244,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26441,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133027,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102292,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 221593,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98829,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94177,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18855,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 179374,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30403,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22402,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142849,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 158082,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95571,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5177,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7339,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365653,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135528,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119083,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113915,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162088,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183302,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156957,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112279,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123127,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91530,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 263278,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80098,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153832,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200733,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35735,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74013,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307668,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 126123,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 400178,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101699,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36637,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148919,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375234,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91709,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142195,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 211412,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53331,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300381,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36291,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19556,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113006,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139942,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199939,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213515,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27575,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120898,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52197,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 31199,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17978,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 165412,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30437,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137039,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258602,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56704,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112376,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127116,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109667,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 302632,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7730,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69117,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85854,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6026,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32656,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150594,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29622,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108447,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74879,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206143,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116833,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 124244,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130506,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149664,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384832,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163158,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216882,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82749,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123220,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40459,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142076,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141707,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153107,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79617,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87617,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227103,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36004,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46029,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39303,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70150,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103672,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120416,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45873,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166204,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47485,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 376445,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83488,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89205,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262391,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53053,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421330,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136429,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235504,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 353066,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233768,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114353,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22860,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135416,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 95902,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28273,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47835,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157549,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108040,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19547,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153206,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208811,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108434,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101827,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51289,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72965,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134359,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243646,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97524,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224001,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9172,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130316,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87792,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14935,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167275,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472621,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129865,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 387657,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187593,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186243,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27215,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129996,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413088,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174489,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335379,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82124,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59102,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280333,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123166,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 312835,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 199257,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654829,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125084,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40182,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 293979,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29023,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450926,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64577,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 638780,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219143,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222486,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 617676,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102427,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60394,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523018,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258230,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16794,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139992,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44129,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23053,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80716,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216080,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 476115,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21733,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 505815,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367485,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190631,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186487,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38112,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309795,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 587652,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253188,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26002,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 314546,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413742,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296786,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 346021,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215644,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355752,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 289812,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 499687,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97104,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154991,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32859,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26188,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 627021,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227195,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85866,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37082,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57563,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93318,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63441,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104992,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315074,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 182492,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 565161,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8732,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118989,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51574,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81651,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39676,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20644,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93789,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214206,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98139,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252989,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 193723,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8198,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10876,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39492,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11752,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15411,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90864,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37614,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112474,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97301,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24900,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232380,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91338,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58688,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45586,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74567,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7200,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113572,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95133,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116455,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90823,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21830,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41840,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32672,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11745,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 443556,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24680,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12149,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16204,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149017,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19810,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106456,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 294379,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102851,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 202784,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46902,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35671,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133878,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25832,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179203,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172240,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15076,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39602,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25743,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10832,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45772,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73739,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90769,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143785,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29807,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15306,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35652,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12247,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150229,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40505,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63972,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148092,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122579,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68061,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110212,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98615,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52573,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130991,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159222,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27874,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12964,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38463,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117348,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581856,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75472,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99402,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59394,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129789,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81145,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32655,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89458,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167758,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58706,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65861,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115813,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164204,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186989,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107590,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103101,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315563,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298964,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135542,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 353596,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81271,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187134,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26696,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41557,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44199,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275016,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22223,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22393,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59103,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88553,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 475465,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120779,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84715,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23006,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103279,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28143,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128708,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134362,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87185,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81766,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134007,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77872,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19930,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276017,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128015,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132864,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26884,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105244,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133047,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83638,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39098,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202974,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112076,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169261,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40352,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53554,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 326573,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241542,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20500,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126753,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15794,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488406,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49652,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17478,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315692,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180619,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141117,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316965,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41365,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117542,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116603,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27562,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91162,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43173,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 60248,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220816,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35232,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341467,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259204,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26380,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102865,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34273,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120124,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46002,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31582,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85967,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180400,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161355,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33282,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194193,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31244,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59110,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40770,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113777,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193579,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161876,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115110,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26155,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178772,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7030,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153941,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169525,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41330,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13605,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87867,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109110,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 143645,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220828,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58265,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77562,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70938,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85406,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47686,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168421,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377457,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255057,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199052,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100780,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44598,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64682,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127594,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148052,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292160,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 385110,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35658,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 153237,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42241,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58132,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104486,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109154,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158330,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188041,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207671,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226916,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 327724,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110679,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23931,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189290,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153788,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109432,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159039,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59922,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37633,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239757,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 185750,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11960,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558354,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238665,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223167,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145594,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156612,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110046,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164153,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294887,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265256,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203532,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210445,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428883,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62103,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 134834,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143828,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 117366,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276202,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95822,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317853,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54352,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415846,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529631,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216586,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468930,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404895,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359369,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517492,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 18272,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64647,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260949,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 584607,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408683,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183977,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96972,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206679,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128963,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52290,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105078,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2949,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124341,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 5830,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112585,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406079,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79873,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25102,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96123,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25786,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39516,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17234,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321181,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33298,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155234,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79239,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 581601,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111822,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81764,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123207,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90027,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104875,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180996,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164525,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140816,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15300,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105615,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86214,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53687,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164319,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31695,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20999,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103949,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100465,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42378,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4576,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84248,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426306,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15069,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 474049,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27656,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81774,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100124,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127460,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 149122,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184030,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19173,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79299,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31308,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97378,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 149853,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105657,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160624,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22710,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113701,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73360,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120183,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87006,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65761,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16899,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134195,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78788,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76504,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38395,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40900,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55495,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8423,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27241,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21151,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95519,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13820,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41045,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47416,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140065,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 518774,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78737,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95893,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96697,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97750,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330909,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11032,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28676,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52318,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 167870,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4504,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144577,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238712,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31808,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220837,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92990,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39468,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 241885,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200883,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91429,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71932,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96536,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26119,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113858,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85193,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50416,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266767,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73669,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211535,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53250,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54164,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14857,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 326431,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226667,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137441,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116510,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34466,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86674,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58306,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135435,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25808,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172412,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136804,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103717,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87696,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125169,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400987,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100527,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96407,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19580,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 631445,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108673,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 674627,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209782,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99630,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90087,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44240,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87777,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67423,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120820,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35794,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206134,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90544,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42602,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48245,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93385,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140615,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82347,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40994,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 200394,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149184,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23833,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60998,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169538,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69474,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40149,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32595,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58535,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241217,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74550,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33160,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19527,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35065,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86854,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20756,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114422,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110713,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371114,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211769,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18755,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251560,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230247,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131523,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76109,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16692,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121517,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98308,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408696,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38966,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77588,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 19367,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 222543,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257115,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22991,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87641,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53298,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168683,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273669,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6745,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146357,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107914,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88490,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80758,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114445,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65897,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64134,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165653,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132781,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88999,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167635,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215182,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147919,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26862,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280087,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222081,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44436,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50982,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245110,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82981,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131755,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213545,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43496,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95642,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191846,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 132473,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187688,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250935,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13944,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179701,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127024,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236061,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29730,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81920,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29077,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102033,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29085,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144764,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189911,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429776,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115035,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58307,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210648,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191499,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265770,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28450,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 615864,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 607344,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344496,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152488,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58133,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26512,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30575,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34997,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111316,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445554,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 113236,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205820,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126818,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 318196,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264623,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384587,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112582,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107352,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29558,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64283,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75126,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157370,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10197,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 172880,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126263,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 281388,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452751,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130391,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375366,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50828,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125190,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30919,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124043,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30014,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95619,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212514,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421406,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264145,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222889,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 221987,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25413,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 369989,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60942,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 616205,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237794,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174487,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 290845,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84693,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57035,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264527,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214139,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 366364,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89942,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38494,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 650679,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288444,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240900,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194586,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 455180,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 135375,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55438,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389998,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477425,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288907,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 174579,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268272,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185074,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 47371,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380040,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337656,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186694,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 587808,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226069,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180615,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74247,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33355,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97049,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15787,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 331646,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149987,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71620,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28491,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314804,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163132,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9482,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211864,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45791,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110797,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 163771,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18808,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21809,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136030,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103329,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58784,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93612,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10632,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121953,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28940,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89115,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28138,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4671,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42345,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225489,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140138,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106618,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46788,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28549,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26290,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11777,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151909,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10938,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93761,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87670,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80227,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201199,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35297,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216116,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59916,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86548,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114091,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7239,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75005,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97456,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167846,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56189,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297288,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144406,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150648,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 220929,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123069,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14402,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214066,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80877,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95885,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264157,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21590,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96506,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55649,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13620,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79686,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16987,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121918,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16740,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18511,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77246,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23382,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 304636,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86757,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21775,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122220,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67752,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172814,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50258,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122176,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44578,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70934,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234077,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60328,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 171858,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99110,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65767,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265829,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83515,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37073,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 188077,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11335,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294359,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32292,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91483,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75515,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84637,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91826,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84583,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70687,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37751,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27755,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45579,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90195,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141162,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139202,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106207,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125469,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181754,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106200,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8604,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93338,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6633,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121182,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77892,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330631,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 157017,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4200,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 385673,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520649,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38047,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76428,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61965,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131757,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103884,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104257,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13821,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39631,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85077,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61786,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68665,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51830,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40164,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235198,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99632,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261299,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128294,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158786,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69292,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35904,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329032,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266281,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9262,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 490315,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64753,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248980,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67929,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172431,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42622,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158848,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4690,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35170,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127536,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150137,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319467,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127921,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46261,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268639,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108635,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 130438,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266064,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109888,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278648,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 427702,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9671,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189243,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92847,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172860,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162251,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141586,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157627,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 438658,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274296,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15001,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183037,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105631,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244891,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28600,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422602,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448346,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115320,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 138388,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88684,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56046,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127687,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 460309,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137291,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86878,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100834,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175389,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17932,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319843,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41360,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132614,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113003,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200324,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78329,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 482065,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119489,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73577,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118110,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172142,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308038,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12169,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8574,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98817,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162917,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270819,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77408,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33063,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274405,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77707,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362278,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467682,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134633,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218337,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125142,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104347,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507543,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203407,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66847,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255431,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185046,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137302,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250853,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 136587,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286953,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 266655,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47255,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170527,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349741,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430289,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233665,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 293355,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529360,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65229,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115049,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116822,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 309822,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426458,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230524,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162125,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318848,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126642,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 642089,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178987,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 296729,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 622434,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 622210,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64934,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77105,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 326400,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 48440,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55445,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12068,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78827,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113639,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42229,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7082,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29286,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55842,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26746,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127674,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8549,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14110,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114116,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105819,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84579,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64843,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34063,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67400,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211202,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120968,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82765,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71372,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124184,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39627,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15893,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 165283,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117343,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89773,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127887,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108645,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102807,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23861,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132219,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18657,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85023,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110944,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11519,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348430,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30768,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90199,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117685,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143346,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52246,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11643,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179980,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175666,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83665,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39857,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64441,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70509,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144296,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59550,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450187,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77149,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85818,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190842,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179157,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23570,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21753,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126976,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 683769,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21220,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83772,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27327,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202098,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196079,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25788,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21251,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 194143,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137444,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102427,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9612,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96516,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 183570,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62088,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15611,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183864,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29285,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118617,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10830,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92936,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24731,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100803,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110347,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24917,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129126,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15800,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159229,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25000,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74338,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105274,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200834,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207353,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7280,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154541,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11100,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241911,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88727,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 104434,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128674,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261638,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57087,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129994,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87661,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5913,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12061,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70452,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26392,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100300,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206109,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174425,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31063,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133833,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9989,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7420,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149608,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19993,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269050,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47301,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104915,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93026,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35662,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85090,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95352,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23221,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198129,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64433,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61741,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47376,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56930,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126915,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 480236,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151206,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228808,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16728,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262803,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 127331,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32056,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 159351,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21667,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144833,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52683,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 5994,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116018,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137764,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113395,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58350,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85838,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292308,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301409,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27592,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301737,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412995,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182570,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257890,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100431,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112691,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525525,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 358609,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25054,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144149,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26786,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118026,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70375,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228731,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177603,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92604,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125331,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33022,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232534,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77914,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45141,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126232,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389443,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68551,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98812,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241033,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 154293,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74240,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30219,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83338,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18408,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151914,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76533,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145975,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74518,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188422,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 423528,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56166,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80867,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68195,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188958,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106159,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78667,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 115962,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242019,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44857,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161403,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28601,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146137,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28317,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139823,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 201822,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217118,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122744,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95339,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24615,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306884,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200244,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42234,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260715,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83041,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 138655,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276380,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 594596,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234512,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202616,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56135,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66071,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101860,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137342,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356929,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261724,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33680,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55167,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93792,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292074,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391046,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261429,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240261,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117411,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63163,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72078,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151517,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35382,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 188242,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84072,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 570586,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75363,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40651,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359263,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226982,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558599,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188595,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 286548,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 675141,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 766677,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79624,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 233891,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 482962,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 146978,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356218,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42254,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294099,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93243,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401936,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283301,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277869,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 688658,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103787,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72473,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422504,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 683630,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118146,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7601,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36977,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66374,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130094,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33361,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68505,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21251,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36802,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186505,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6026,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128480,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219140,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101996,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26431,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158616,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144619,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168899,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22165,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33108,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160296,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16006,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13212,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79973,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7359,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24036,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83862,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66429,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122294,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146215,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46784,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19543,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30242,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73395,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125836,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220190,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121696,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112664,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67400,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25844,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22780,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 321444,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94179,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3757,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82492,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39726,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 373911,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62215,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18677,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24952,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262262,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73228,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139255,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65549,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137816,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193387,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119655,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388727,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122392,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209157,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98373,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26917,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121576,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78431,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35734,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11765,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57096,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 333482,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37322,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74426,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265696,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 304382,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84364,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 322155,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27089,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114070,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71466,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 383764,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98298,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185313,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72298,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23134,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14503,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64618,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128274,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103281,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27082,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78051,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40140,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269309,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25910,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46926,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12359,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 297740,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82170,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48038,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115180,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138917,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82943,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231312,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86836,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98429,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34877,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35715,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107642,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60764,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14575,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120147,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131052,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12865,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146689,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168482,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156572,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134155,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258577,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79125,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13781,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31253,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214302,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194318,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72034,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43823,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187435,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72712,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172008,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133133,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319626,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 235786,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127340,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189122,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24226,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27992,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27813,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350346,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193026,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132204,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62967,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124340,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289466,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413688,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39526,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203235,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116344,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66623,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10566,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159145,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18390,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112798,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156580,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102042,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58010,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294867,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93981,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290099,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100518,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64507,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38663,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69342,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107422,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20628,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147104,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10765,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177062,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206696,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166233,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81629,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 34556,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195882,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196691,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242456,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 497944,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133197,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102167,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173069,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83974,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12678,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190126,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125632,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84475,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28134,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185068,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 524321,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 155682,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66134,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202390,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326964,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85870,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237916,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27357,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467206,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67730,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558006,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124538,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424286,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61684,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94026,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 301252,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191259,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177712,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237658,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350372,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73437,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 140120,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 117579,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29645,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68345,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146074,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70368,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36806,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 300921,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 163170,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42280,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175052,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118786,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241948,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278711,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 716397,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 548321,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239643,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67244,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139488,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 118556,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150998,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252322,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452967,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 511953,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334539,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 268064,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46276,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364993,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 277891,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217330,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307010,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263949,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147359,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 530712,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 109118,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425723,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513204,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 617151,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22437,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74600,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17277,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16620,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114354,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68074,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73465,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131361,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178033,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22022,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224308,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47403,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27290,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126351,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94490,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134391,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16847,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98382,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64805,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9863,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95636,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159295,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96603,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98173,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23875,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103352,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201245,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47957,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24926,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148339,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23383,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167311,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85479,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55780,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26004,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42558,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126321,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77040,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56117,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37121,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80343,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70489,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87225,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112028,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41693,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224582,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 219275,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122128,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67793,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 239913,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178806,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18844,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92302,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88289,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112037,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99358,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41045,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58142,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6345,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568088,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46019,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82629,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203709,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88216,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286936,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125982,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38695,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249020,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252845,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3411,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148505,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33109,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112932,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90422,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27956,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19768,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91196,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20522,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148595,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137849,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93442,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126534,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105236,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121181,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44875,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52147,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80639,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204909,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89479,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8237,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13944,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572880,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11198,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21679,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66674,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83840,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51923,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15093,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33827,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95635,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32094,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52311,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100018,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102777,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27232,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68996,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18092,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109770,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7718,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139550,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65674,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131000,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123330,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13720,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138590,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126922,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83346,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176424,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269001,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40587,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16152,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77613,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74914,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75031,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 652418,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41902,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136129,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196455,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84656,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25538,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301779,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 134808,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100466,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59256,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94524,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399735,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104693,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7869,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97820,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62879,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181018,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20192,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76370,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88099,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198188,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52897,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140491,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174812,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150541,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143560,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179900,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261689,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54028,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121132,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201988,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 205913,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115020,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173022,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147977,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23648,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32493,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 122557,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85399,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355394,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103511,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10533,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64487,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109781,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73516,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207808,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165165,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67255,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210920,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107916,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189514,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281478,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 347458,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21392,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116495,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217326,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44416,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 329201,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25001,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93354,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145754,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138525,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195977,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125307,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132667,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 218659,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209150,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43809,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42368,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165258,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76337,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523715,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143991,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448694,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189797,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 729563,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208474,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97035,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47399,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 139701,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 557344,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97826,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192152,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245820,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129391,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78798,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 85833,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145203,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412920,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45659,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 316794,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103057,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313263,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 510158,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 119924,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201908,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514697,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154868,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116809,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161875,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152806,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 388163,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63914,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 115472,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339948,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44744,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 86259,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177897,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264824,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256418,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 311760,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 157774,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291757,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269962,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377776,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 135295,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 660900,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54700,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306739,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 51494,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360955,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 103575,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286790,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163785,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43449,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 335297,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74680,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420448,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99017,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572438,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 495301,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275521,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 635321,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468376,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24319,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22518,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24610,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94237,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13828,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14302,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11372,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151661,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100590,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116823,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129760,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77223,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19339,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68793,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59763,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129935,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112232,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26540,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129923,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 344126,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110208,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87376,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70670,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207349,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109667,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11959,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232943,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25773,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16843,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19616,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148123,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119200,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6722,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120438,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38779,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141367,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69088,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99614,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87607,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75863,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40173,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111547,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31164,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78619,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188212,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24375,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33371,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26385,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124713,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267097,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223567,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149240,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86330,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97216,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113160,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 356263,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15446,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112899,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71092,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136910,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65971,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4406,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57678,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137307,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37287,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90128,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59305,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219356,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92964,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74111,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276629,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 363661,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44050,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162428,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95063,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196088,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138697,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150295,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401974,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87498,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25448,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205338,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89440,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90896,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10779,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72893,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16555,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133728,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136592,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10249,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284274,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6128,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57301,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127344,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32915,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108322,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63911,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87900,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39545,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 442177,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122754,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39050,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123669,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112074,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56275,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12961,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190362,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25587,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13286,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131946,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147249,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110452,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7191,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77161,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121933,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13056,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71792,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91723,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2619,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7868,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19831,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128805,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128391,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224325,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 173384,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141191,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99587,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98562,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144588,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17587,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78286,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147508,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160180,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38368,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92066,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49745,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103305,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79777,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35682,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48211,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36088,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66075,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26256,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134392,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81318,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25057,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6416,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142907,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12815,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91242,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96949,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35280,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10059,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 152838,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325706,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138798,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301982,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89392,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56324,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157448,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106711,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94327,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12292,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143771,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 278612,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126978,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157358,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105169,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140885,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126610,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430645,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192741,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118297,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19305,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39134,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95204,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219274,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30859,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76571,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364491,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21359,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38725,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95978,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45868,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74677,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156606,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8395,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255433,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372124,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 331203,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125966,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126166,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190680,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 382121,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375750,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38905,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137817,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204033,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24703,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50730,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51874,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46250,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109289,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452941,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248316,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113015,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78649,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 418333,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222283,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265863,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206489,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328167,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151957,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148202,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85392,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 248787,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136511,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465079,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63414,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131346,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 478554,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52783,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155436,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257917,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 291667,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104634,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304259,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109358,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 106429,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276817,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62386,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155061,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44962,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99338,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 281061,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353115,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56094,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400515,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244492,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349236,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266722,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 446905,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98256,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40881,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396865,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304915,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456129,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 644099,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272751,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53594,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355480,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202718,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89588,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72903,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352707,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 682170,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161581,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 33731,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327174,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 289880,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168697,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182686,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 730150,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172049,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 646091,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28380,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75088,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95894,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99213,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28520,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130300,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24282,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8690,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134634,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66583,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315180,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20029,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37786,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88077,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97613,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6742,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93264,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36210,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71175,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 350159,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213776,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47325,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23735,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147835,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22638,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7487,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190812,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15284,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26339,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17416,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6786,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86847,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25872,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17343,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51014,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162398,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39432,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188258,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6635,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130405,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143004,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33259,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40203,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131606,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 355321,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242520,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24572,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71248,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269553,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204550,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243353,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144897,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78980,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198026,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172204,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 621952,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50298,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119537,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106160,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104115,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16636,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130216,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 356377,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166408,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95610,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284070,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153855,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124870,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48386,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60579,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238471,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127245,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9884,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132357,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303054,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13920,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132263,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103775,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79765,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75772,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189478,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118433,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108176,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80286,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102134,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32144,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178389,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110554,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40459,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35594,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46137,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13124,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9415,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13109,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260445,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55228,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88445,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115490,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252367,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84406,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147670,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179144,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6621,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 158970,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77095,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77017,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90416,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152363,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68463,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228423,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98735,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24666,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135454,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178199,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77787,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204152,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 132995,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 60504,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99062,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84339,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44849,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3829,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141436,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108413,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108552,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135332,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87238,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119427,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142093,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 418480,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138271,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246948,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102687,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135546,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78307,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88952,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541419,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191054,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191916,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138000,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17514,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142956,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47096,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65310,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129049,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34635,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126950,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123524,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92872,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87628,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59149,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122156,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117743,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87501,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9904,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18663,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32882,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102418,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321432,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96129,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379300,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14016,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126422,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285354,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195215,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139716,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108201,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72848,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129656,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71844,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149094,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227628,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150811,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129720,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94255,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121461,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168277,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107226,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92757,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138457,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12840,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65273,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58132,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110679,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240549,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35165,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178177,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 112488,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9592,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545831,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 62545,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117993,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82381,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 326501,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229396,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177788,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55408,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84457,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221623,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31506,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145973,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34460,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67049,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82736,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71635,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183376,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190991,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135099,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30979,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100444,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184835,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13797,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317107,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465938,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190051,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122023,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97224,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164980,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46329,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53103,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 548106,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78145,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50669,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20205,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243378,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112605,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226079,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 627672,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268554,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114775,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 199393,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130580,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87593,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39816,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36961,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189323,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3768,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121255,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253864,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237920,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215364,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 25863,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273247,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46958,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158551,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 103562,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425145,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235185,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177395,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314683,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38688,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 336467,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182951,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319465,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255032,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413024,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245317,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 60743,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195544,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350651,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 75834,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 338010,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457399,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429472,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 130223,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 703968,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 687832,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155172,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 186684,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 177312,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428294,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432844,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494801,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 631996,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513743,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230563,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 471928,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224816,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70531,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77542,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103726,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225474,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420258,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121710,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14256,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38572,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95676,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48443,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11101,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101167,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83335,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92655,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87917,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4755,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122087,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34462,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64798,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123659,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100392,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16796,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20789,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24652,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68886,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101871,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121377,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82356,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72064,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 387563,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87545,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26927,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117068,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80192,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94289,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44442,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409746,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273603,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117958,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200840,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142823,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154504,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109205,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191946,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132683,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126507,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112308,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72939,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138283,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179730,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8519,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137715,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42301,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15465,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11288,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86572,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268002,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143904,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125422,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32879,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87471,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197426,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28688,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 329765,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4962,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43414,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16701,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125747,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125164,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117435,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161131,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 188298,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100061,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66498,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53343,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150606,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19633,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 548237,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87877,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16118,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98925,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100912,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113140,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10195,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12126,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100267,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268060,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37851,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158429,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251541,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88387,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71139,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110162,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162248,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136017,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81218,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13836,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24173,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17442,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88636,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214115,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66643,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203303,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69289,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148267,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5266,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201748,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25753,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33225,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44047,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96710,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96065,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126780,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132108,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133686,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7552,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174856,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84962,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159393,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136803,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26552,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14328,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182500,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11155,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222834,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190367,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136305,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20391,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190428,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90219,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242625,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180130,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96668,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64134,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55552,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148004,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13985,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42628,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18536,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130813,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87235,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 115670,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56529,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128817,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25760,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75515,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189806,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136391,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58716,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393625,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300413,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86465,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83848,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15487,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523192,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215852,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121791,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 643986,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90434,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219515,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123605,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18228,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148574,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89285,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73551,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 158847,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110121,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269410,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 384746,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44600,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29344,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57330,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167516,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135011,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164232,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76464,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119785,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126809,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258305,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427510,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86600,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86100,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 550590,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101976,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73427,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92033,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142006,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180058,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203104,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179904,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70787,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42692,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131861,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277653,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248495,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 92879,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40516,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60229,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 363106,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175733,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161496,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111030,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 543680,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112206,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137891,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 290050,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136827,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 173982,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53109,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182427,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164547,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 24303,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140661,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371425,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29290,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555426,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293480,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182099,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158961,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 191865,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520785,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 115309,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514960,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32534,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207598,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335574,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20784,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67109,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207633,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 127570,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217902,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 167376,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290964,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141294,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210401,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 521940,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 668791,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 344010,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308278,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240885,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220905,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477517,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409765,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235638,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38070,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70501,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 585554,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23206,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 303045,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284441,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243393,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42713,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271676,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123365,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230112,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171966,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184098,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304257,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114128,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 553073,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85243,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 85428,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 603648,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464891,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309423,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80172,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44019,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69895,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98457,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12006,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9738,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104734,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25951,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105389,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12471,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123120,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105211,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27967,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23207,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111900,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80468,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177504,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13830,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131981,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91705,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40985,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 2926,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64455,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127968,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117606,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41041,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24329,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120960,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103383,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113843,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86897,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136537,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116005,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58581,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12049,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89683,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145094,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123719,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38000,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57807,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154075,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135551,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 382466,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211384,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164767,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29109,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388500,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163158,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17758,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28608,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285895,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67373,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99734,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11186,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111890,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94474,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50919,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24120,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94674,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124253,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 289680,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66885,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120430,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104282,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110251,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89669,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130063,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106362,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56550,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93298,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79074,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132521,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93252,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27625,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131896,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 134855,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62377,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53367,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 403900,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91186,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64532,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13458,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41077,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142503,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82986,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120655,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101915,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22420,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83195,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5694,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14415,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173914,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78271,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35426,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123260,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142768,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69623,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229515,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20268,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264450,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114800,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55430,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138104,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87990,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150289,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90251,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234812,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118777,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335679,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254310,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143273,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137217,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37154,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16340,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216943,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137850,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31155,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212431,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32618,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7791,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8402,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19866,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170904,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10731,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109085,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25901,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141582,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230051,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 372818,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302770,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130173,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556614,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72746,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127758,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56544,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108217,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 332451,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115179,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222075,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8133,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108898,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83526,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108704,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46566,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40562,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114700,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68297,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209369,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38900,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100101,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38362,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108735,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137070,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85651,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148247,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52782,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10190,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124369,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114015,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307831,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136638,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24452,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21015,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62486,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 504765,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261653,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 652245,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107576,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94751,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133918,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115540,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373407,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96610,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273176,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96978,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86833,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8078,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175986,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271002,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63234,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119720,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58694,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25100,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88826,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96018,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31499,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124689,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107743,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56041,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117047,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63174,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69933,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169938,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20766,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39051,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54112,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133445,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102369,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203829,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45158,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466944,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132972,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146480,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 135939,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168870,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65857,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220030,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14897,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64739,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248196,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197288,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109392,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398800,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179295,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37762,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52644,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190418,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150534,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37874,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110316,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115735,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226262,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461091,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320050,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209660,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 475171,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238873,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 104328,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80167,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131773,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244704,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87657,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127964,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93099,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49530,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 104026,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488569,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60461,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117709,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272996,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247236,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434671,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47266,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355627,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327089,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175056,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27711,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250353,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282375,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259562,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218270,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44220,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481057,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 343486,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146222,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161902,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 615855,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65654,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 97882,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235544,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147561,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174407,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120942,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31358,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39560,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42837,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68143,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50704,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38366,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149451,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140164,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46892,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60834,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93139,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90065,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4029,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 224107,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46807,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143500,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187606,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86977,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87290,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29215,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35876,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10687,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50032,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53152,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71866,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101911,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35816,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47679,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10745,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125036,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136993,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26359,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105945,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32398,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8581,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10650,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115553,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27458,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121487,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371116,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253395,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68751,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30342,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36828,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13940,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118404,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147391,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102039,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33608,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18202,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96301,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228514,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11245,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27574,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29337,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113044,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253073,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6234,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22527,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74717,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56937,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185092,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40681,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23467,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56590,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55953,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25598,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39754,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151017,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169234,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161694,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141790,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108653,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11002,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29202,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52772,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76013,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64354,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10650,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78532,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 305902,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272771,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143249,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93595,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90352,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82943,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54492,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54502,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92800,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60499,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192101,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110389,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36603,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 247338,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108015,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23043,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316723,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160390,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69599,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9051,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142818,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70836,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116331,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88726,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43634,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100830,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89563,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19327,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298034,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133708,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 267678,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175127,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79499,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39721,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106437,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7366,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232969,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191550,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77131,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148646,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28585,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31266,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119975,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116879,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160447,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44968,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 237097,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85069,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82395,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200233,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125814,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61276,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135280,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347211,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426993,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50642,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278545,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57461,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105583,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 282506,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30229,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88788,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147835,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 251683,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287566,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158279,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104166,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126702,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226923,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83071,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96209,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84445,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50818,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194780,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134362,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 248074,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21225,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57513,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223703,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50610,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108018,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23069,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264448,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38983,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111385,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160084,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146103,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227843,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 164205,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157589,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13581,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256328,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79823,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108698,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71327,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 562718,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120706,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31726,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122722,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281080,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 221068,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237767,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134719,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87983,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38446,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325052,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36851,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111155,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142104,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29768,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 443712,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116715,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29886,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110854,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187169,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74312,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44804,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 187980,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41989,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89441,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131892,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153997,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55417,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 362657,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233746,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19982,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87903,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136057,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284962,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108761,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184477,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74525,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18768,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114160,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177458,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49185,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71005,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143793,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176706,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168486,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324342,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149307,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98417,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413113,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235573,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22729,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431688,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135307,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102499,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110943,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108658,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21391,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243905,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268197,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83799,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62922,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254593,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92863,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464670,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 115266,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41275,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185442,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379795,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181529,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78798,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98686,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402333,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198040,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160048,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21083,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 120125,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486130,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278029,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152851,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 193549,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72651,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111259,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84518,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 152053,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437550,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 500866,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 681885,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343337,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 379234,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572327,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153634,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64199,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 630315,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96158,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114054,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92110,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98049,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13302,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15848,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10112,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24331,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31503,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119304,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73689,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107231,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12017,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415055,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5474,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213625,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 232303,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135563,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88771,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18092,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174980,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27785,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21793,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200793,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99642,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3476,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116128,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59534,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14914,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100147,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36691,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26619,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87777,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252789,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18559,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125646,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80259,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16704,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41910,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16451,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276627,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94777,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127336,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203385,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21216,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116211,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121518,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15371,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79248,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16898,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146318,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41775,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58104,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 215292,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57458,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27312,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35071,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76841,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22815,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31951,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87486,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143380,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15437,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68446,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77540,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17004,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88592,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103841,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99291,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102615,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29489,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8375,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122847,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44492,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90477,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69624,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160174,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121120,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31300,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210264,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 228949,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151085,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10906,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256872,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139881,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145774,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94118,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40867,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135362,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 2604,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44621,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24290,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95459,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88621,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14643,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113122,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96838,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290102,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15863,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84568,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127405,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220974,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77092,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15723,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78579,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47285,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49165,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27543,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21601,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127027,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9100,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96407,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 285620,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149277,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130956,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56730,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90511,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6048,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58369,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135627,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80507,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58364,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22562,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72879,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41230,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264675,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122687,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192796,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126309,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81847,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77904,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64815,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558170,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258357,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152596,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11132,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29381,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404918,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62740,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112011,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151858,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26149,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62911,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74483,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59675,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88345,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 163833,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116792,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11717,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86603,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62585,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33556,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70571,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25154,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171645,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64080,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131535,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182391,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178747,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10358,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128488,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78504,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190238,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31661,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64618,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96142,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175330,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221355,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108442,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119907,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18483,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111447,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12543,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259626,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50872,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80979,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272247,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64972,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52373,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95638,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93145,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96820,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206917,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17435,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153718,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129809,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89115,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264347,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73510,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90637,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155458,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56977,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79732,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103019,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208255,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302046,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169770,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77984,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328450,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140303,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156407,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134869,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286900,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50340,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21631,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 275885,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82845,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252609,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229594,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136408,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 410730,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127044,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92595,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93153,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84925,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 527500,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74577,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371363,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165077,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100723,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93044,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67425,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 600266,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 336417,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105216,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102532,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71552,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271599,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75360,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261533,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68405,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365634,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119603,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122489,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 128032,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185058,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68680,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27267,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232605,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314798,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 265979,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157470,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453421,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109830,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89660,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 237685,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120736,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222381,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418746,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62572,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 86407,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70251,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200451,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36383,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266985,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254652,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59645,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178999,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49948,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356078,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216769,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 599356,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166634,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39863,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 460661,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188152,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46417,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 288474,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469587,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163317,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427453,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407194,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 367924,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181496,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284383,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 500383,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51486,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337431,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75704,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99527,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 206060,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 154889,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466449,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264068,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140415,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76755,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35149,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228173,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108543,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264062,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 250443,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220581,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30752,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422295,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126722,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52291,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4560,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56514,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30092,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241576,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79842,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73789,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32677,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99213,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44575,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48205,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148345,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35271,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6310,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89562,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371490,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36614,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178143,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73441,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270982,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60753,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87139,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78034,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65200,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80286,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225724,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82552,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74251,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13837,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 220209,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34998,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45668,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152624,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40364,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4123,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163921,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11950,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186778,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96607,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218608,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100242,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99188,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 311740,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72270,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149243,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34113,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233908,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102845,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145400,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87537,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195124,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156136,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146982,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136876,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4074,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147849,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88796,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293854,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7696,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7522,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47007,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84917,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 462648,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27644,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87338,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35928,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186136,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4566,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72600,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155676,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31735,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88821,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257259,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41089,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36668,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70392,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297480,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82331,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73992,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83411,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130518,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233417,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131879,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15354,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19568,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129806,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194327,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91007,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112228,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259125,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171675,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32287,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115056,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178804,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193516,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59698,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51118,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147486,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34406,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63971,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92575,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323721,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50764,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78933,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154123,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231378,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10624,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 209940,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 282098,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128376,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96014,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264712,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164354,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75388,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72483,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17613,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115707,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45312,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22159,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112737,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27977,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148342,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109708,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91872,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105975,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50553,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207630,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38928,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 611355,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62930,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92244,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137687,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131958,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51132,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120599,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68432,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38607,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168342,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 241494,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 304946,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132781,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50045,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7456,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72363,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25337,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75222,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32243,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162212,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183356,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77076,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421749,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 285622,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57940,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106782,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153462,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322081,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40963,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149789,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127071,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104768,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166869,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99189,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 409961,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60654,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154541,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117973,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83876,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88740,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66113,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193849,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78528,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135606,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155494,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58631,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84231,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169189,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102438,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72787,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60581,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89378,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17699,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33350,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433716,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143322,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124296,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261633,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117405,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11445,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210832,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374033,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226970,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96411,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 249172,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9324,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297087,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27045,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210317,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193092,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169307,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151156,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135316,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 123004,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223852,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166783,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302906,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520322,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15110,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145333,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11477,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88985,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186534,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257567,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232056,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188751,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99618,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 711021,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175415,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120727,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54443,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177116,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367370,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171078,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13469,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30043,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74777,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380853,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180121,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243583,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40216,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272287,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289320,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 564805,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194618,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268179,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358867,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293549,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403223,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294362,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103599,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17546,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131920,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190686,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339714,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28411,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45748,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 112625,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93461,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66012,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119562,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260812,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317161,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 665597,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57844,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91024,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199889,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467386,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 118655,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474818,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60364,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91329,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103164,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 470275,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 635682,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390872,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 521045,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156323,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17055,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21011,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93908,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58652,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89891,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45480,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26042,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140857,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31766,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139406,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85049,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10697,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192623,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21204,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139631,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90667,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212355,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37302,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115304,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116438,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35781,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29635,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226443,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126276,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83680,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79156,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68111,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52148,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50334,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 367568,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17121,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50265,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10811,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88192,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153115,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30774,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72796,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165828,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95537,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103350,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45513,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57121,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25013,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268144,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9595,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12643,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176215,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112359,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110339,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133783,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36236,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51206,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53004,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38433,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23793,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89508,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200469,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141879,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173018,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6770,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18068,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30747,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66046,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80039,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32971,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22406,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262119,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168682,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262961,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9524,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40277,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136733,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146614,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42583,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46630,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80833,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100907,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24695,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82444,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20125,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55517,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161234,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90248,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19398,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47840,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203459,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139764,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90041,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84904,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59224,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94079,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4164,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15828,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27963,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128862,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12510,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258843,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56634,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42570,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160418,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38428,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221128,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117129,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136938,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78652,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 213745,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 390088,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316138,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184443,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101421,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82720,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 246922,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109729,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127409,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130851,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101752,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29973,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96526,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 213909,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38170,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176604,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44304,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115252,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95877,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101649,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83988,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172266,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68596,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28541,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151026,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27254,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33367,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175730,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76242,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175035,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87529,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262212,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378383,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24448,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371756,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481418,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19240,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57090,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84704,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127631,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 120136,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18823,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27450,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94960,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18218,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31905,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22752,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70151,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3493,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54892,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148155,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 334064,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125390,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507011,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30330,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42807,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163252,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194826,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22989,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305120,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53585,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112454,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19316,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134784,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136472,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136612,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 376197,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161719,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73229,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193455,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157912,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165831,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77581,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32698,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8771,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50889,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136611,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24382,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94581,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382691,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73475,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12647,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214327,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132666,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34251,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340325,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183578,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83490,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36452,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94689,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304954,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122247,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18606,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86866,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61971,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106178,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247560,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189808,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77650,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237898,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119950,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164355,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134631,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6136,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170742,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 387886,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91628,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 314712,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124186,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61755,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73364,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176343,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 372331,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287538,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89100,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91525,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102671,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132664,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49391,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173390,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35189,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80628,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114118,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440917,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179677,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54016,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114820,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189953,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99963,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116187,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262258,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 524320,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 162268,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136284,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357800,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105181,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317073,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159980,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68031,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355521,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47496,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272080,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311916,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116138,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357085,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 511741,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58201,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274628,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307565,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183263,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54136,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337560,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261184,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228682,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171516,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23850,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 687210,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396123,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352469,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328413,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65996,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247236,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 447900,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307735,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409037,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40558,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53127,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84312,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64571,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65995,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45853,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219571,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99304,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33733,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109484,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6288,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97467,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79579,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55297,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11061,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15794,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97362,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 237064,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75318,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32534,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93713,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161819,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103090,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89064,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10453,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145847,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206857,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39839,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76283,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11323,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82437,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29042,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54096,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127992,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41467,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16010,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97812,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146977,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7087,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202266,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14846,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23181,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27954,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35727,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112605,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7482,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34298,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209591,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184864,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49211,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94935,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174522,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57878,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122799,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54877,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21289,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12299,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116738,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18229,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14375,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 171393,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39750,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 178968,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16704,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144865,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14485,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131306,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12672,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11033,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48915,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79596,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 207470,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84610,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17731,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151050,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12013,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51656,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146563,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11525,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40531,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129190,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93693,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23629,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97089,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77442,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 453335,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83478,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8249,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23776,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226587,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158700,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120181,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80079,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95473,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66545,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80589,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131118,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106480,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 217740,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66805,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15738,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126127,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80318,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11798,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207106,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27492,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103070,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92952,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538048,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26409,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29253,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216639,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38371,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148760,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214905,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166026,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192603,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44068,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23326,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152817,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10805,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15407,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223666,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41617,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77382,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70680,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216194,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94071,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57061,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157942,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38863,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182630,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32334,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114517,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24114,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314903,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50726,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30698,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95637,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97454,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138932,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62443,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85911,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22153,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46874,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30548,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65740,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11936,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134306,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 6451,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35630,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24985,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224124,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102573,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55901,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429178,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55121,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25224,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303006,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157026,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54595,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106216,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195324,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61353,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40170,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459586,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138623,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231061,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3473,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189420,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177274,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147072,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177899,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104421,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 27917,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118682,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30653,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18591,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377821,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71585,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33436,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14644,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33268,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88278,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40590,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38483,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80603,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98592,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151388,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75630,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31193,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74982,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95209,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30307,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145076,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33629,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211577,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46048,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120422,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21306,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83064,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149987,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 361226,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154716,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 72864,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220679,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14415,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255392,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192185,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112872,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315683,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213919,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64040,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131502,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136032,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360173,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223264,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48671,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115805,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195182,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 334383,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58294,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61060,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90570,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358165,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 320924,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 439183,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146668,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438091,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140328,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80799,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 293250,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102276,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45275,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82354,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166242,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177704,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32003,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166343,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270972,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54508,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 334426,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77785,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 531275,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84559,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92328,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231356,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467082,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382084,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 78637,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83040,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 330971,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252994,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 107131,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122287,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72686,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 111329,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172541,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122624,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68513,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289635,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131301,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 664556,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 97741,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262078,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315591,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349138,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209643,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559445,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41211,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 281326,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173397,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418307,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 650078,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466988,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 74177,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 420564,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 240378,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44996,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 193943,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 148214,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496862,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129083,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114330,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43382,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32926,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32219,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21662,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9822,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35265,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80594,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73676,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60561,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37459,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39255,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91730,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42875,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 132980,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103409,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74713,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120322,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95769,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36567,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11823,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141877,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17268,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106328,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48464,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47710,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233275,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55580,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120532,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89309,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141572,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36076,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283542,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16289,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51385,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12561,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33139,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315701,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80169,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137335,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296409,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10751,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111916,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10440,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92254,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37407,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111196,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201694,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39091,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96489,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34447,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11228,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 158246,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31640,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90647,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265981,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191461,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60253,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186926,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111088,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8778,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34876,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10867,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54382,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9154,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82791,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77794,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26147,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16841,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216951,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153363,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13149,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107926,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113657,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308597,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40467,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116988,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103972,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223024,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203284,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36271,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95409,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26510,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24655,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76661,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160717,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41393,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18891,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19911,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154222,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111199,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137778,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47639,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109483,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59658,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130297,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122799,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14164,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88591,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242146,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21294,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15102,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17845,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86391,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153426,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35318,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59948,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85742,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125832,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330615,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43014,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15971,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73327,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21004,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238079,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108898,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125776,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87747,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4501,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89032,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86880,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97249,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120014,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9773,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105140,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35032,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193942,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26452,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27319,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226667,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246274,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133393,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118233,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4263,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11171,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 465798,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102998,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105444,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300608,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118883,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 376946,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16236,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65433,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96873,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136554,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80806,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126201,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4388,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 303612,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133380,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43531,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132398,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138074,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117734,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53472,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97252,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17018,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253497,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137268,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110368,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160914,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161512,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60742,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148114,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255227,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133051,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283436,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236869,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34324,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 239165,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13626,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 318937,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221032,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 170481,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66654,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101668,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36812,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147997,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193372,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81744,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33146,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221345,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115941,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 550324,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65103,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137526,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49108,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87021,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164448,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164921,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19960,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53012,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242958,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131155,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104284,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75710,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124671,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66118,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206396,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188232,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116649,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209713,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37180,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14835,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79424,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144468,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46967,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 544138,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74805,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34929,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18383,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229355,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315568,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31431,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32813,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101688,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11233,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 274053,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 325194,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355272,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285673,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183706,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54005,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205033,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136451,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33905,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48733,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 138119,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265423,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 289541,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27124,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133336,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119023,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52974,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270766,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120010,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225960,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 403376,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36176,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 322000,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 322403,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38346,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176311,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228789,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411957,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156229,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125612,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 553238,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226772,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12762,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29523,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296518,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206231,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357558,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174042,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69014,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223252,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197563,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112895,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371233,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271667,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406159,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339871,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 209934,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261918,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 482337,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175239,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 256567,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89955,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 341278,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 405163,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162589,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23751,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 129503,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 682117,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 154879,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464124,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240283,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29523,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 502451,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255153,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269577,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93236,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374420,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 815066,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55414,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419381,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82298,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260875,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117122,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112086,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445090,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67727,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 125330,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 352934,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45813,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16895,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76921,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31031,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67549,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5505,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212091,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71343,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26138,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81674,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83882,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102900,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22817,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132653,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7223,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156143,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196685,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17582,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172749,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49406,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94803,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104292,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25297,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116498,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113923,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54360,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18684,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115176,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163887,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184219,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24965,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278994,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113544,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133708,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25077,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6845,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 302292,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276213,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5216,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143083,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155981,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244481,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100419,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79889,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13102,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74467,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15468,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76577,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112539,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174965,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108937,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 663200,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98205,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63426,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123200,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 270335,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35616,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104796,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42367,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99531,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92610,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19117,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132736,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 352286,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4635,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62554,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13368,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98273,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177598,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16678,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100752,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424234,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31721,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39076,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165795,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222096,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17018,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23745,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157758,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72531,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98183,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78392,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 193636,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124332,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141696,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295618,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94431,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18624,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13686,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65057,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86177,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203451,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161757,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315303,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99000,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10448,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125913,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115015,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32708,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132256,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11903,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144467,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16873,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180972,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150890,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109813,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194228,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115428,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242371,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130055,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95316,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92153,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201102,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172511,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77143,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 704247,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113337,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82007,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90909,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98311,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79984,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146141,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203850,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19565,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127904,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291092,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82184,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202668,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87049,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17268,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37922,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 51826,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90788,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133588,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194523,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 409077,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268181,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88043,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165451,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164507,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106535,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69906,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75708,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31226,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68359,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189834,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85297,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192288,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17525,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9928,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86689,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82413,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110780,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71724,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198095,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59392,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49974,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258630,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37647,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112450,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118010,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 81719,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134001,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142015,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166803,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19632,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79650,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66073,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 511913,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14706,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152603,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144325,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327366,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19681,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24686,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143545,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189549,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159697,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552070,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171520,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237742,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185683,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534415,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266778,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633000,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142651,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431431,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77286,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40137,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375331,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39732,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63890,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131788,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343634,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120713,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140740,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104569,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94601,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254530,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190548,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335102,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184739,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40723,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 421085,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54903,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269292,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40947,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 348739,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29700,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235983,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46980,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104708,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50385,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 161906,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399590,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489169,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40994,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247648,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50578,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 300404,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 120327,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 336197,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398023,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489233,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88296,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196195,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 539636,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380017,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233627,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448604,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72598,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271304,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77885,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4054,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34780,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19612,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60044,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14315,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11261,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60843,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17837,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31708,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107410,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9338,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11801,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4173,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19469,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15943,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 215769,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169790,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89677,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10912,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82711,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244230,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43631,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14955,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130477,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175857,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117591,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106940,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156883,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26169,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31990,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109462,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169986,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132271,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84305,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134662,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110886,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92575,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96358,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23530,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20033,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96992,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36753,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84036,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 439684,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133399,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83078,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66351,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19685,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117643,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21070,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14143,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27579,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48193,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55903,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33710,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178428,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85512,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37454,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14046,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40591,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146598,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169045,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105083,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 109257,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10250,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8304,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11250,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71340,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 310473,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45044,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68372,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102809,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135594,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118939,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148433,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51919,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39201,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18744,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7920,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10184,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 427411,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81805,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74782,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265708,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140672,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102324,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79664,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31710,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27600,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41264,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109617,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63051,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95331,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99309,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98550,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347150,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68679,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8176,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13353,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 342357,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4419,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85853,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15434,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90597,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103322,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14379,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106741,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55031,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122295,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149130,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38765,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314510,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11859,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73724,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72426,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37296,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 479047,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11879,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114005,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7109,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100753,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128379,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69560,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128887,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 301138,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92315,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78735,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118957,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 6509,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3993,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26378,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117228,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119518,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19471,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88805,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79142,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207880,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137658,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237024,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88236,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59912,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141155,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347375,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 396553,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119436,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62162,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556549,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284046,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 196368,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211905,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 353482,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16590,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176962,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24259,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118222,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160303,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238872,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 299500,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35813,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136441,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224052,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254463,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 354179,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135277,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119547,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68050,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195623,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100324,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300678,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105939,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110820,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276792,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31755,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418665,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 521828,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230576,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135036,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104346,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44020,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238722,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141377,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57242,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159476,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51824,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14364,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178192,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257875,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110135,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182603,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68509,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130602,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15455,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71309,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523816,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115972,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85220,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111457,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 260358,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82267,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274664,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71499,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107055,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225399,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103039,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137276,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63976,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76590,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48527,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123348,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364955,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296531,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171149,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 153174,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352940,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50060,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47619,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172733,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89156,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 298315,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251449,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264016,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64110,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329831,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393510,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45884,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118543,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458205,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 332349,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88761,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398533,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223573,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244157,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180598,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 69601,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98679,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155082,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100548,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89912,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 661446,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126039,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 674911,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326364,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95887,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359511,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41193,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436969,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243336,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 216231,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217455,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52046,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185554,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450875,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 611643,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88780,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359486,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 107185,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 727421,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22215,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181459,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165564,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187172,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 302003,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 557397,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 168231,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88789,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36448,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14401,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49708,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129047,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72212,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34647,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36573,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16131,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22374,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16774,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62600,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95175,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11170,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159269,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96684,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91640,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37627,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10289,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84605,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125068,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26330,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99238,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196409,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154581,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468778,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68071,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20647,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110959,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30607,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82802,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192555,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117165,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81199,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126931,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16881,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79267,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77459,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204876,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20196,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86973,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14389,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 504311,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91572,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84371,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131930,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271381,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73376,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77595,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88799,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25742,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211126,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105761,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33269,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114748,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46637,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14377,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70551,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108008,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261542,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9470,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92394,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107949,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8173,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28243,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107294,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19103,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14129,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307394,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144521,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72018,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 177917,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4975,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17372,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27997,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191588,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121206,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 390335,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47252,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 222935,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16015,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114256,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61401,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 548815,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64137,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 123005,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71549,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152134,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41407,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81802,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84024,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128711,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118279,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78029,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19629,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132255,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31797,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 304936,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34784,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137228,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150119,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95758,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12477,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44899,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11536,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146025,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184808,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128675,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174518,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89322,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92417,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180325,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313021,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125846,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14880,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191865,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138567,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196425,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110782,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106359,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17718,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278343,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42402,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197378,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 612387,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110022,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 402738,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90581,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144820,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109054,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51066,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143818,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36860,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32832,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62145,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78277,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42633,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158390,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46535,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192311,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86069,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 269832,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242345,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35474,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92190,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111947,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79746,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19965,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210813,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117878,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56286,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98242,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129646,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152288,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104179,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128929,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82867,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191052,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 158084,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67798,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323519,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6651,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164666,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185192,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380479,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164735,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 431704,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145146,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138616,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115398,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31493,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81257,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58861,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95367,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69213,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285180,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116469,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45020,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186796,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523263,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47358,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289662,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83229,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238080,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95276,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38080,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160454,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246477,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 181061,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26563,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58464,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37633,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166804,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190202,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45110,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552582,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20443,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145862,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114182,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245906,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82636,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82197,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215312,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 48756,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 561888,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158360,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296754,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514204,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58113,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127378,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248494,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130697,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85920,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165898,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155229,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22094,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483329,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245617,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67638,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272995,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244313,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42117,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159556,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94064,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90021,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154574,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87644,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66524,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40671,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265064,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245589,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134402,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329707,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130350,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296319,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433061,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132692,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 124526,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222906,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457064,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 387649,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284476,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82312,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376541,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 518084,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115230,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200503,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53049,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375728,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 186415,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315726,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64207,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 381280,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321266,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254244,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19490,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13593,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8056,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18112,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89734,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34300,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162391,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76370,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81801,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64760,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21823,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105898,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11299,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16243,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16170,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86905,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114067,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89948,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95149,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12801,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18505,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44285,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26451,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6795,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204518,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10054,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117098,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41036,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62518,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85861,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13595,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20769,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124400,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135204,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108039,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30872,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136018,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118671,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80626,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130427,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284097,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8529,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145960,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97310,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16468,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31468,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27431,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204886,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72065,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469509,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105728,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109543,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29487,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38416,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36142,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60556,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20543,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198054,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61538,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16206,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147898,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70005,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 133202,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32827,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93346,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14870,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41979,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45949,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85106,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116319,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39356,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101975,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36898,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34682,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14478,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105101,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78402,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125922,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243852,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103246,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196326,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 367917,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56781,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35118,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98163,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258670,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253585,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165064,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27493,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47665,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35670,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29531,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24258,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181170,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82945,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159612,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27779,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23650,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54858,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21754,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24688,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52598,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35357,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55031,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46812,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35901,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59847,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161617,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39431,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205766,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137339,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25455,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178656,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57216,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85632,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18894,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140265,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95479,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8067,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81297,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44788,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78093,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82617,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33298,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10295,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85448,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143641,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227734,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224746,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85474,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64232,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168470,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185941,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170214,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222456,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162740,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 332281,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109384,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76578,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123892,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324542,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63207,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199886,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 2771,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5650,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72455,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55103,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 409322,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 17945,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42528,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406490,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152284,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80740,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303454,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160112,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19265,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114570,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132422,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74877,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127199,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95241,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43277,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84480,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127645,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191691,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232176,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186565,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32688,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57500,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225629,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 641834,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35943,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 142916,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138984,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19031,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258964,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62849,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26232,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157007,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116303,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70352,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123744,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117415,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9385,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126748,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20170,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103135,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90210,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29231,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121497,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32893,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101856,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142383,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115418,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54387,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325584,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 279524,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105462,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101004,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74916,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74738,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313623,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164913,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122028,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69108,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139316,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69217,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257011,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121963,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156517,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31806,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168357,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473828,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372445,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133541,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110423,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77755,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252639,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155667,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65756,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100857,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 553942,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134821,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84079,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354822,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43261,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22036,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111362,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181012,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231485,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83076,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 26169,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53563,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415448,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173483,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261860,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227656,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36736,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54352,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80264,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144128,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 293690,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359215,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216709,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92726,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183320,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167856,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 331821,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165602,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78354,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32242,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164546,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422885,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26443,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127247,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114442,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141695,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109711,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223431,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636167,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149262,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479187,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73764,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301301,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247577,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48692,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101959,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404412,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 470320,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402210,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 288412,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 311929,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80352,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 501193,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356130,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359329,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104916,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43312,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13828,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58616,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37948,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151100,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8974,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141693,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58217,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162641,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73877,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137399,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97287,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91595,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57313,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 191717,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111427,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38498,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73122,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82966,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74946,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30946,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39235,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 297984,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49983,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26013,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85789,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122550,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144710,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214729,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60812,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152487,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173401,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90165,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86390,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27327,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 352317,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106914,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101278,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80479,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131863,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149587,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120672,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91846,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219312,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53288,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139201,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77336,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29053,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21473,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74146,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145753,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7744,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108985,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259368,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 321967,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88666,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123927,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99777,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157348,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127803,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10012,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103334,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143775,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25817,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96248,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148101,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212264,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143618,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57644,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2633,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65568,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89344,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101819,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60050,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70302,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190244,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57830,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75963,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116815,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97675,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101101,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 188444,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18227,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83074,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137994,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102383,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228008,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157207,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9470,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64433,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91463,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49023,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37163,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69285,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 167441,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176923,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 154692,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11225,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42484,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48708,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73054,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21893,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33543,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27041,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172133,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113912,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 210864,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94554,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42338,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25940,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106584,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52942,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10873,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189237,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 414318,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180222,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123538,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117186,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94378,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95312,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78000,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208793,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 182783,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23386,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 725162,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46932,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81045,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95120,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248035,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128358,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314550,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22084,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 173088,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171521,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13411,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38878,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26867,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13180,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91672,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111626,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45573,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26026,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56902,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68557,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254825,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134470,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61792,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35888,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11377,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285195,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138279,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111039,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121667,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77815,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8443,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455880,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63819,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50891,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45000,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209855,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15697,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353503,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406491,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132282,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98096,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112510,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466650,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130121,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129609,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 290025,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472002,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257146,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185033,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75497,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292507,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176839,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 122992,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115675,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555863,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315351,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33694,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117001,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 177830,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311305,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265583,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538933,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186975,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28407,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43889,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163435,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295405,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125429,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40104,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133725,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59561,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169115,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108111,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286449,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149951,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20912,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399292,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12162,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44019,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316642,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87836,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 35253,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94440,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38132,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56637,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42288,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182833,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69067,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28872,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220922,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171719,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105492,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7290,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374641,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 286004,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173959,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89248,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 358694,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 233609,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43371,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 410787,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58126,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289583,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235647,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21113,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356005,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 306221,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 106938,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198144,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433881,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64305,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182760,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194327,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 370955,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221692,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 480008,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86592,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61976,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118326,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52029,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18596,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99087,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118590,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177068,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138383,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252106,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26865,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79054,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6011,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118853,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135278,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74949,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264062,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101191,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30593,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114061,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167067,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72216,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118798,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116770,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17602,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118304,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10760,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15741,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 217011,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250195,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122991,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108769,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22251,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94830,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34488,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110027,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27597,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 243694,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34765,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11922,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126977,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97356,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18528,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116214,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158301,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131264,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39006,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145264,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28217,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88122,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229919,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66106,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103479,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37359,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83289,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100094,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108713,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59226,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111324,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80627,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132084,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 109696,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139005,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83635,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27179,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52266,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31147,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39289,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105686,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101974,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3993,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103968,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74174,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72857,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293018,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91097,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45150,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79750,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 389374,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41579,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176177,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125864,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136072,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25352,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24986,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23482,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90829,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42873,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257570,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149663,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97743,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458461,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77706,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20140,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41176,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127472,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20868,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69331,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154464,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102699,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36761,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139537,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16796,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74801,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38300,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180565,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127922,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27006,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191360,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105583,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26963,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195300,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20150,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166665,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115378,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 328697,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97783,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81240,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109442,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89086,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14744,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64812,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 298989,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 112839,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109401,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19286,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84279,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127387,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281997,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120041,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 121367,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62852,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98740,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208497,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433025,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375883,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59753,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79678,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108754,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 472684,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 324000,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186354,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266710,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138086,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27610,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101630,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49569,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55580,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 347923,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16580,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76321,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52781,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32440,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257595,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184980,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56318,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40939,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41157,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190518,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202605,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82537,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188334,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94613,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101181,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122495,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16657,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180088,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180474,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10005,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56848,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 383344,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21572,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62330,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185084,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98055,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 335067,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94760,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76070,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107791,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129449,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190311,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 127633,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13645,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4534,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47488,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549842,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19967,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30486,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77292,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105797,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50661,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71548,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181837,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 342007,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65788,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190565,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99061,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110432,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106301,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136811,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234767,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301451,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 476837,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153082,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200117,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74546,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16791,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 369655,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157249,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27025,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 149415,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158999,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18662,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164070,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154122,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148919,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210963,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28701,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103596,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155220,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195981,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72596,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110648,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31906,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86456,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142226,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317680,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218535,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50372,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126918,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133098,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255555,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216132,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467903,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13533,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131374,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390453,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106799,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137277,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267101,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56643,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65692,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42415,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261308,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147291,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277900,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 185434,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49458,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 76518,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223706,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42715,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86489,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 389199,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230285,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54310,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253728,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 138602,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214179,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264398,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 260980,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70434,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53861,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 111046,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131385,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309355,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203954,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109988,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 244611,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83896,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115458,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20250,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545822,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455586,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378441,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99535,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30770,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357887,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52396,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428319,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 267932,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559871,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 366437,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283410,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537480,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574632,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424266,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 295066,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86176,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459163,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29797,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 224221,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 157348,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85474,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128015,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31986,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18151,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6669,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67387,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54238,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57076,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91078,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41089,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52824,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15925,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4513,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21089,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275031,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9288,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138797,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98109,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17395,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97803,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100055,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128413,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41200,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15042,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 119645,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60984,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75934,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30973,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48453,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37097,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81036,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7968,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22724,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109022,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84245,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11765,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54818,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73993,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14390,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109203,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296033,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65365,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89041,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26675,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145983,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11917,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43193,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108613,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137411,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305199,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174985,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15413,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184251,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174621,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105374,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15626,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31912,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19804,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268178,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31459,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76900,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162846,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46466,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37801,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106646,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14469,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17798,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 299322,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12764,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83040,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89032,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178055,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51230,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120863,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20541,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74478,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73031,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119980,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95985,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159044,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10750,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 5985,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32385,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173185,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39935,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39967,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162748,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35199,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202998,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53625,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24843,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4238,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134957,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159381,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177398,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203542,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134378,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105202,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122788,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94259,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7674,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242916,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 338130,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89242,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155736,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115646,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15695,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8332,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38421,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30343,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28735,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74369,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61414,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194967,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111253,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 261769,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273849,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124318,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223565,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22611,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114768,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125201,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44116,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71054,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62806,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45705,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84044,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95290,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34924,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37365,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260672,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93965,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291804,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186644,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118619,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15831,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17808,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 361652,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41970,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66980,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27177,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38109,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35981,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56420,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160006,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26097,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124902,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138788,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14131,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82222,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157305,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294786,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87314,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141071,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 323512,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160411,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 359305,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32652,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128351,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190325,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118426,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171092,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33662,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37337,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22697,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266928,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51669,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321050,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176752,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 367013,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60409,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146314,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57330,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80165,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128954,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73203,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11540,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64869,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100311,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163538,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178239,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130403,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 385965,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28868,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169818,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101935,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165101,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56119,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48172,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47820,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92128,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 31220,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175307,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101519,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24566,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348553,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162091,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 139586,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64527,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 370047,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137866,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29959,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133152,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398778,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 104809,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146413,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115665,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132507,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93104,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 352046,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151766,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 384756,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7794,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229142,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196880,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22832,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57137,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121214,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50421,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51608,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69451,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306776,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437766,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556638,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121726,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8167,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231422,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 132864,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34794,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428423,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190456,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 154114,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231681,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154625,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 141529,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116535,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339632,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36699,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258425,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207301,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252496,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444655,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335775,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76938,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333126,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468973,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 485150,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26753,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140703,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474153,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238606,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13128,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15819,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259821,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254376,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509706,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 363274,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 361238,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412389,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268048,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44620,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 334154,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 624827,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357769,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365336,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 361627,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435250,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125427,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77778,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15456,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23551,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16394,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38955,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80246,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51579,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19467,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139131,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76668,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20516,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151891,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112109,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34495,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24590,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9578,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12613,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40777,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 493839,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61018,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32340,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133988,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80525,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133931,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14331,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104008,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16254,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75299,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156242,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81762,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 167731,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49734,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100548,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211087,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50602,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71372,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81291,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203582,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24083,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184895,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43771,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90943,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199752,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58513,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94878,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13588,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121408,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243012,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43123,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162688,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 114995,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60711,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 471277,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124608,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 135803,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77686,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102349,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33052,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102517,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73372,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35048,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70011,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17066,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179123,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71655,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7196,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136221,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121476,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13577,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113679,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140729,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120521,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68792,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 189812,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9376,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4006,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37937,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111113,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59178,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127398,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73964,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35190,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68822,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150535,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 271035,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52390,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9496,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286953,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93645,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9109,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96701,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267211,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90225,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108141,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155248,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85274,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 189239,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161558,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117589,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119870,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56135,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27141,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120740,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24042,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67579,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100055,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117935,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113773,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133943,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60589,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92484,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174942,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36059,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29840,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42995,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172517,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44343,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24292,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89273,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91956,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84100,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100872,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162971,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51836,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33081,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205842,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96096,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239511,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161007,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114059,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19769,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152063,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16572,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179030,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 89342,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59529,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94832,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114743,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198209,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6972,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 168840,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338898,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225929,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2325,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110908,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184481,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128097,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181917,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45788,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162401,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207396,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186424,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72609,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90590,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130223,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65525,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25583,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72541,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148018,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119207,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115404,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151617,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45344,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248945,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75411,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240674,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33150,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152904,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279267,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205226,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 429297,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129175,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130994,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 384409,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201522,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50796,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221032,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144705,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436311,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145378,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49407,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66403,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114172,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85729,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50983,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 475701,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142807,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43790,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254084,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28610,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81921,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90870,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176546,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126886,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137241,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147526,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224566,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37441,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293158,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 490582,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 559123,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271112,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129187,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 678079,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73404,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70421,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209219,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9860,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91504,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29379,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108625,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143555,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32862,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410568,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95868,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91041,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60011,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467824,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16563,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224164,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194356,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394521,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174472,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219671,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119506,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 487669,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215695,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 387355,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 395057,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102580,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514195,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440090,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313829,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209338,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421404,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125544,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43862,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 258006,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216758,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 447103,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43669,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44855,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256502,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 528364,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303136,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424113,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279694,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428433,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513752,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219709,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272663,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106819,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413219,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436569,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549692,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483105,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93253,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 623515,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 76238,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35403,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21425,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 390465,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125912,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93443,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111504,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19338,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17593,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19422,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23480,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54259,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8518,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129381,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49073,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93766,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46204,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62169,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234132,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159725,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76533,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156120,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30982,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34289,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22748,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112714,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133260,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26852,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36963,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43036,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122547,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54267,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191367,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13771,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94485,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108145,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162912,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130678,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85879,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 154518,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8154,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184816,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294050,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32022,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145337,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29201,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9929,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13070,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 511161,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89250,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17199,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9080,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31810,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162159,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77099,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93253,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12315,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 214459,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84527,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74407,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114876,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105933,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87117,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38080,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134765,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160943,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161535,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7903,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117181,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6396,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56556,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 94436,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141487,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168062,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24386,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134819,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24746,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64798,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107135,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216814,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70640,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104463,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117596,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330140,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158736,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19245,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153383,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93707,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15215,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73113,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80612,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18330,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27706,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172226,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58292,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 212215,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145223,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116077,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64380,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314166,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 171312,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30055,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20827,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200296,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 104251,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313814,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273194,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110190,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32748,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211334,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9370,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158719,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169637,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151247,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25831,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67104,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11670,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107156,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241164,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22982,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6285,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80982,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58217,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60768,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11869,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42448,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16745,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138344,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31613,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167616,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101043,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26168,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38502,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62985,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106690,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212277,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103633,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66787,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181942,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121613,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183739,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 331693,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137551,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47502,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85887,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254887,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191918,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91883,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192822,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129272,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100505,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78493,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4132,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147392,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113628,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197446,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111515,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118212,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78169,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175585,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92153,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96395,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86821,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426919,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185673,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7451,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26225,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137100,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99544,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24764,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50575,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113175,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83473,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132180,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24051,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128497,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47196,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 348295,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25600,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147125,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304986,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16220,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123095,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228541,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215895,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73893,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181257,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7725,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116690,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169647,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37691,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211861,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359152,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167183,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178131,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158958,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269148,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129124,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217940,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26823,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76812,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 142443,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88315,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48026,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158146,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79569,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131384,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241064,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74314,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194097,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144482,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67745,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160066,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 389212,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292205,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 123923,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257764,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120425,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289617,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349489,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30291,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389325,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406033,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28530,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122758,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 280281,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130566,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636032,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63534,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294306,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21525,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88801,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47329,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86839,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53374,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 399317,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56979,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 204088,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313747,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40468,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79314,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 501504,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57936,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 363003,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 626603,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 498977,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342946,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 653269,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179580,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100162,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265374,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374462,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461279,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32815,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324529,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338587,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251519,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450973,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412129,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640058,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 306634,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 704757,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 429668,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284088,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93990,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104118,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135816,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169224,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9209,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146557,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76609,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5419,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8673,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43200,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73678,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103321,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74613,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118091,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7867,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74826,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70608,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91201,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152528,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 426739,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10896,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198508,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32633,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77391,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145919,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15207,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17399,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101371,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61710,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23891,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102089,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135702,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85844,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39748,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15820,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91316,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134565,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105540,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205146,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186015,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226211,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80464,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108574,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102772,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117270,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143043,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141760,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199404,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69130,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284986,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34006,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59305,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137844,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133913,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119013,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81618,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114736,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24488,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106331,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7683,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144193,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71245,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129930,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82436,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30138,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147782,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198868,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32718,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157981,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109699,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465964,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155744,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56079,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 506058,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146612,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 279896,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138634,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248855,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12138,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205071,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246990,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80556,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6166,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15174,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22862,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58403,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32490,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156192,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 537186,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295735,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145588,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63789,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 332595,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 289100,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102880,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436640,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38830,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 320555,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324320,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76268,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100484,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36958,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159091,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31424,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 282298,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73775,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63336,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68543,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83671,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40480,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125330,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142486,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239931,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184241,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142497,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 101321,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114043,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26771,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33760,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270171,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296440,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228184,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 595090,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263554,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86769,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 167469,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14058,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36657,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273955,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104064,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121585,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124100,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10621,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50054,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156017,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189746,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163527,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56201,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119198,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57496,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140448,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20411,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95896,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256825,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18581,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69276,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104084,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147369,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40371,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29312,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186251,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 267502,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 205860,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 49348,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59230,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253987,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45884,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178012,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39600,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98580,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179662,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144877,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72031,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35145,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101665,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191100,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38732,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73574,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185800,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259918,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47211,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180233,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125405,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276563,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15280,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256317,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95763,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 92034,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172765,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135956,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200356,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101744,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230337,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9982,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94446,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118378,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32312,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88642,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128169,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32391,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404483,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273279,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143814,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559635,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73090,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15086,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128159,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48132,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98277,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133180,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162393,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10997,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71895,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215628,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189255,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64260,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219169,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40300,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340944,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370916,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160492,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52494,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91842,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279360,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100359,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216328,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373806,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549471,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43459,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22621,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34585,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120596,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517292,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106445,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 194126,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261361,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67045,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163091,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581457,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577490,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262014,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222742,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 601146,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108834,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 144012,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 134924,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140657,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 560150,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273514,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81894,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 544461,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193721,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42695,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31781,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86576,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106049,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4023,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13515,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115856,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17900,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37892,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30632,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151958,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145367,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31101,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10575,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326466,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16647,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8248,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89796,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18853,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134476,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39980,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154071,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28657,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136099,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29646,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66308,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38366,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92355,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51490,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191673,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77642,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87605,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6630,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79908,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64917,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108750,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303257,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32328,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53387,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23394,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179648,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71305,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106387,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159546,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99961,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148117,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184349,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97891,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161977,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96322,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76129,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38821,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5462,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21772,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 691966,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12743,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14647,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91950,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9777,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323840,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26982,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19923,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26949,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50705,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63195,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4019,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40403,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30843,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273562,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81095,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120005,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120957,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195552,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215256,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 172697,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76324,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83008,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37922,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85908,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78706,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29815,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13979,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30319,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20524,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56511,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287813,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219269,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75332,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158082,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 6641,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35589,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77656,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240316,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19385,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205124,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61636,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77463,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96861,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92863,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69416,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32993,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125101,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82254,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10361,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96846,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21095,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 190462,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123036,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47538,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105540,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248267,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150475,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37366,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153884,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58980,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26210,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44830,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38648,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60390,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228762,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35710,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80556,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114489,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155746,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116445,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123191,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191390,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109356,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109584,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227772,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56146,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32000,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57184,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 316136,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85425,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119503,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30479,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112467,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188396,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209328,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78673,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 354695,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180599,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114879,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214682,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139757,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 216025,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65708,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45008,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115330,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43853,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50490,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61587,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177063,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128450,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65599,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162955,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158717,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111001,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138434,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250681,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229742,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268076,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207278,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272855,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355211,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233772,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68701,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 589216,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 198606,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118811,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7411,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147011,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 393482,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23194,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87256,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208973,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284497,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152851,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15090,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209234,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 252568,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 193486,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27214,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181219,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 130539,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340350,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40746,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 380976,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 278753,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42037,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299429,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214724,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154075,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246832,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211065,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295891,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235542,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118567,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 662030,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161087,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47218,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50671,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87261,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31924,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19982,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403719,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195374,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122730,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92687,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 582385,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317702,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173214,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374243,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58426,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488379,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430637,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79955,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108659,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 204445,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161077,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46111,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 422770,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 687724,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336943,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 446045,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 120775,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 519301,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568061,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407191,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420884,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 128830,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182097,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256896,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96615,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107164,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71541,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131751,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104075,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14054,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80305,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5835,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79825,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271713,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87807,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59129,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149097,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92462,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2973,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57788,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10539,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29466,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80998,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20482,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64456,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105934,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63723,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55714,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70231,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341288,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165027,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28065,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83763,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139792,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34047,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105512,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333006,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7931,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 114871,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65135,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149093,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18592,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141253,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68603,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127596,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130700,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8997,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188445,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134464,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55832,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35118,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197162,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8577,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180499,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66574,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20147,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 96100,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62202,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91999,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 6741,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117726,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243275,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10545,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11791,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29479,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81129,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128837,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10322,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64224,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17097,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110732,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32818,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16664,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 687436,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165884,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27727,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39453,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15841,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27091,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93129,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24051,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49992,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132843,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73607,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98796,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48170,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31659,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68168,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39959,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103946,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55196,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64864,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13212,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91969,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46512,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207085,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 327670,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85425,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160019,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71053,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101214,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24056,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159550,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109902,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132082,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31837,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99374,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15033,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122959,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20001,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32815,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79661,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114448,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42491,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119109,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30211,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239037,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101261,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288599,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112029,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 247147,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77621,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 311151,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90829,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112410,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297220,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151636,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155688,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228831,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55817,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193089,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227268,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293325,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12901,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115656,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46985,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172093,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16739,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33917,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138099,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74885,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177290,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80762,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124230,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69413,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92381,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100256,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207645,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171177,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19896,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64006,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223054,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170955,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29730,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276728,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133176,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158390,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173749,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34064,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265311,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224433,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92977,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71865,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259305,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42113,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76671,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123745,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108634,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54430,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76233,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153561,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 441342,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229403,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148958,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97435,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223213,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118528,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26258,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92005,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155934,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281330,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131855,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26695,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222166,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133289,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143315,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42781,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91980,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3941,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21837,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374343,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58376,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129056,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138372,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92515,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82674,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508455,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335176,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86028,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68358,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116853,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20166,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84166,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21505,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137632,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 468694,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46710,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445550,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211518,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114878,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83828,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166490,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136993,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82066,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206099,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182313,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187175,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16879,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19479,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37718,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 176588,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153378,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94217,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115648,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27273,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329107,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235618,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 204504,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256394,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261519,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5795,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143322,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94050,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235639,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 341852,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 446349,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141757,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207169,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39523,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254008,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55295,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466536,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74874,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41641,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247277,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292645,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 310566,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277097,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114390,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239791,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79925,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319841,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169386,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107981,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125868,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164960,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105832,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303314,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252889,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 49539,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 191345,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49071,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126073,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379730,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 37721,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 90798,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494757,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 356697,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93495,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493236,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75906,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 200659,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174189,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42874,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71486,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 383166,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78264,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55868,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190347,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 144003,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30588,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22124,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67784,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225087,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 381129,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 393206,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45745,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 126387,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231857,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 118574,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395801,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345555,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 591754,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9819,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78654,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33317,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114628,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64497,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136375,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80061,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156156,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46695,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106388,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8423,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71493,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12117,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109039,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33693,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92487,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24598,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93052,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42636,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77533,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274404,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104382,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40378,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134039,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22417,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102340,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23462,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25843,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114730,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141598,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104618,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155469,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53861,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71211,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116086,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35804,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21196,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33467,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219760,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87825,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13056,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122312,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321346,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71644,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24242,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103700,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31592,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243271,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235926,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193148,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58828,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157410,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119137,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190918,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24341,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12306,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58727,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160640,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106747,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33681,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26381,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187943,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151179,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113239,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77441,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21533,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35735,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19455,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120800,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139703,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25120,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16528,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 564840,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199943,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80193,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141305,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184019,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19968,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91266,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62751,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18497,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78200,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149034,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74038,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99267,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161051,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86496,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258827,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159620,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95744,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46378,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51762,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25545,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77448,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115302,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229886,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88946,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19472,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100366,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26884,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132968,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286787,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 441618,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296238,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60443,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136075,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65024,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51310,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90827,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98094,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25954,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173561,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114211,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265780,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37815,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140584,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126037,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2406,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466588,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147570,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30285,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26591,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42870,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280135,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86938,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98490,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206162,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78638,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35518,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115779,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71392,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41544,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19506,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40421,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104122,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92638,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164723,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97207,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162351,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224160,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84461,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144280,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14367,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86303,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137094,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225883,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49550,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126529,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14402,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158965,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267830,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58071,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70436,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171772,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108397,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192234,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85241,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275777,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152679,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100205,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76319,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110851,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256946,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45338,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314783,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115877,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20959,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105817,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158071,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31588,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47799,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 623317,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458410,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31909,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118764,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51583,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176039,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 21380,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182773,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133440,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 137137,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80855,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45924,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299009,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393252,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173029,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56603,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 363929,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144563,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34680,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80151,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168570,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77508,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119633,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220818,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39839,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147077,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 721044,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107208,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46112,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126066,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15289,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136419,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 604357,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117730,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133144,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66058,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206648,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 51026,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57008,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130975,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54204,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167749,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259334,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256455,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224648,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481320,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52966,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109116,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67626,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182876,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 408497,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247676,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41233,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 649743,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523365,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399874,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 508743,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176397,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229623,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 35818,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53653,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256437,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 200742,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 160277,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491346,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65820,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84488,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 578045,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425058,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48614,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66504,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126838,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145738,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131318,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160989,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113935,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116101,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29530,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72284,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56437,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5664,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45810,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87562,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82514,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44729,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11825,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69062,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 163404,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174044,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81260,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4274,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98337,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156908,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52960,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20256,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57237,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75665,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143769,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108779,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271916,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117295,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25324,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109066,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79109,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191781,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79297,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88302,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25876,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145279,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315183,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45447,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26006,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115417,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141069,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142185,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132746,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139412,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 209301,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42597,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42914,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 336505,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127953,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115405,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128526,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87230,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214684,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183650,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130077,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103223,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19522,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118233,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142444,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202577,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8661,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179699,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89648,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33116,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140686,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4466,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68571,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46197,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114007,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25152,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176124,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110021,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18292,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49084,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11257,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26884,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16823,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 685931,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101119,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16966,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22837,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 177280,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 220448,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109494,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204396,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143752,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14142,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210271,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54195,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98186,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97134,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16810,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117851,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165626,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86683,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23279,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21988,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170598,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79144,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17169,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270515,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234514,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404568,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177411,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119256,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117963,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170279,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49847,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31492,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69135,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60802,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182836,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247925,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239453,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245566,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93820,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61859,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143658,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41110,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44542,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37656,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144379,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54782,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101621,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14133,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127919,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118747,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 81580,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172379,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73830,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21176,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207188,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 569157,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120014,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22370,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 375109,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53893,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411870,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80942,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 704029,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152433,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328555,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87506,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252221,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22954,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173721,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43586,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45716,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33380,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56508,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78522,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152101,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229926,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82051,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86930,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157370,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265191,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152887,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86178,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72449,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196285,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90775,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123973,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58047,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35062,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143305,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25988,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538137,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99735,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248745,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 122722,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229803,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185605,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74094,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173709,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4922,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24730,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110214,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286242,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193019,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441234,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92773,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 334789,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197749,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230611,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 146213,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23945,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58808,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80141,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141969,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140900,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64556,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503376,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393266,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38600,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51874,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166685,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65123,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61302,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103247,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103376,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237581,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32533,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84221,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341666,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412379,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436937,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25418,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118983,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173843,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104015,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186817,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58815,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292593,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114966,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238610,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37913,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473331,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 399734,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507494,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 650722,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 564453,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 212768,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60999,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178233,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269322,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370372,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123131,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77495,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640582,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 280670,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257207,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486000,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253359,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193495,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 277894,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427624,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203981,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72485,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426373,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 186165,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30025,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296109,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241790,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57253,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388909,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396982,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 192534,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45687,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76978,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 565528,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 510984,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29730,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26400,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36848,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13313,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14873,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100978,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157892,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46145,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85993,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37249,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81449,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15726,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109084,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116820,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51700,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30603,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176880,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63548,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 454133,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14944,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77044,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129065,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30844,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42187,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18091,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68121,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32219,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92912,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118489,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7504,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29209,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7423,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14058,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181367,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118741,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22043,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26604,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152393,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132098,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193431,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51630,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70882,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78698,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74066,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14910,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20565,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255026,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126046,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27751,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31849,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159403,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21174,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35703,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84314,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17589,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80461,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 113711,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241045,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40108,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101260,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105382,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16629,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41761,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12636,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103175,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23525,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8040,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146181,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113074,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90621,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139875,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69935,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53540,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39721,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25345,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126154,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130231,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103071,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18065,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105871,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8278,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120907,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128187,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30620,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4488,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292600,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39365,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104298,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 225161,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65558,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160094,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68824,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170124,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11099,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425275,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57147,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263957,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26588,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95907,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91613,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68755,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 279690,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252840,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111165,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10164,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 164816,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41203,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106394,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170814,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110068,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211777,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10310,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100989,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127561,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89778,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221038,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48710,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118662,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186514,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169078,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79647,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23390,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33796,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275137,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143917,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55990,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303654,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278086,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145832,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99704,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58177,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272672,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58022,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253170,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139649,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35877,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91140,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104290,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126739,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157809,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31778,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26483,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73929,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 219561,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162267,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151740,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6795,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204495,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38501,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133493,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24617,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182317,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296734,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151337,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46985,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98003,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17156,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165792,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136408,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171172,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119160,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66350,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52105,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456643,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168489,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27504,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84397,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158778,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206566,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139910,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141339,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189847,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189216,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154723,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 587995,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398945,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450697,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152753,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93165,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41692,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183055,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209857,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302264,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52677,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117230,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142810,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 128394,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66852,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357623,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73578,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88455,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 253322,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 655154,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364150,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401383,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 416142,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216798,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164874,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103174,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317294,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192146,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434827,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 181291,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33573,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248504,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435209,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294790,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12009,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 184923,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53536,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206842,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90633,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258725,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257643,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132730,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74866,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149689,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515010,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247929,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 71321,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170007,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 714276,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177044,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179043,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41558,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414670,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242906,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45372,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126860,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82479,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 275870,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424259,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 202782,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 90102,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 18229,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 608647,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 492255,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72955,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47930,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64115,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 222769,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302798,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40149,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 312912,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293942,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348854,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 129524,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 652515,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39771,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80083,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102773,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96058,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81778,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28886,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96916,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25338,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24076,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84303,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89929,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21714,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79165,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66089,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150724,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40283,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59203,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 199849,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142321,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82744,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11248,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33922,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140700,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131493,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29192,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182915,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28826,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4007,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31468,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9376,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11493,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73696,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86939,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68883,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157355,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162598,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38345,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6811,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71436,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44091,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45887,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21065,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11751,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54018,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47277,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106913,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91434,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74192,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517310,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150884,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54607,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189931,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202040,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45902,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72503,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221363,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95041,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59147,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108704,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106890,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406193,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133610,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45778,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131251,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9558,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21028,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79948,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 256670,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104623,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89751,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315928,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41361,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39945,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136704,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65913,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142487,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133575,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125696,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74662,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186666,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260777,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120427,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131358,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17335,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61465,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 391761,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127023,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 168426,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9627,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72089,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16448,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24055,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17586,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199407,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102151,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39253,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12073,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6514,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37740,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77085,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26003,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22651,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298786,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34291,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90986,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64366,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96266,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17901,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51282,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107162,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110558,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62224,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155960,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301580,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292838,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17086,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45078,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16730,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124790,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285416,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12578,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81899,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56303,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42992,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60195,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116984,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 322871,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14361,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140867,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94521,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14956,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92541,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71546,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90938,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341595,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12636,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230748,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71808,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224893,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25151,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16494,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 739870,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27934,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325061,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86045,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155981,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88477,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48370,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146767,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65818,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70296,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55059,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137454,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109808,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50308,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 480688,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157376,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123640,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234001,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151186,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127435,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36805,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419469,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62767,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71760,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125914,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82047,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227259,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36240,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118726,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21481,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137499,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48766,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164255,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28849,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78031,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15964,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106584,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52955,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173602,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226538,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10207,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113278,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 51227,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123085,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166909,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153261,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245434,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95207,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301550,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163095,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108659,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193768,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523448,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 254760,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128714,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515779,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 185363,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172710,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164052,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 350572,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54441,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163715,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38525,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103251,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112538,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194381,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67655,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89651,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241427,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198413,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51116,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102221,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179058,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78622,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38501,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188343,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448988,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222470,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32912,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360914,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65355,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45999,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170695,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200213,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56632,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9930,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186398,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 174717,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164720,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55620,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167350,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97392,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435038,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180278,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76163,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92497,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 626903,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64867,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95266,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269352,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53816,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101949,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71943,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266261,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 480015,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357680,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464063,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26048,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51641,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51170,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55008,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 601307,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 326783,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481488,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305577,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 188193,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242554,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414420,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337320,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199822,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72479,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140489,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135925,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60591,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159401,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11057,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40165,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 134076,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44005,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7804,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96129,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145536,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124742,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46791,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114561,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95311,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131428,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214258,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14344,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3999,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39681,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12048,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35809,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 2885,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11689,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18408,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230185,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135202,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16392,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2777,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 192048,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7348,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166311,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88466,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21969,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104934,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30391,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104820,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103253,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13927,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7458,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223074,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85019,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143995,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 201134,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100971,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25842,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72563,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164796,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72971,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143991,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94978,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469192,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15493,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275256,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122671,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118725,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241004,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6842,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160575,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188014,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26089,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105672,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162603,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82008,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50399,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22691,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67071,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17528,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109393,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215453,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210481,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163328,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77378,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16519,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161367,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113021,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99882,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199931,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55343,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137374,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41132,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68693,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296164,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127787,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6035,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183279,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79735,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116043,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110088,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142952,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65476,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147135,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130658,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169736,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237797,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98069,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254681,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88232,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34504,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158861,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113399,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107258,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86216,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34598,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19208,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23485,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52456,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260269,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100606,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56433,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188770,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7971,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525790,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 546784,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13920,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59543,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517541,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204558,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146349,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90630,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55510,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136103,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8498,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105344,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123267,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 180653,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80076,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61655,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6828,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35364,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181541,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147270,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79775,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233852,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109058,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17479,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127539,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134272,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90063,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37757,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 482501,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210702,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139887,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12085,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108054,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208392,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 317474,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79291,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226375,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106034,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146476,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14427,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195179,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107571,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104102,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335399,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121835,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141541,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32435,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58065,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125993,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 188303,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172883,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12916,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95106,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46482,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162011,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35486,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224208,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23520,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143097,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177954,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69644,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104343,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184918,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10346,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324110,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38946,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74206,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139055,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129094,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30138,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43216,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102668,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456415,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52143,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63959,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208893,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102494,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112504,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254498,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186134,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57659,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161487,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189833,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53644,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109178,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127728,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116107,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377529,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464272,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116170,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234731,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76331,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94488,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264320,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238558,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104396,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180841,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379052,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377432,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285366,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189820,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112627,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 711331,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48731,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87460,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217747,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120980,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266920,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303134,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300067,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 351928,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123630,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41867,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199286,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93126,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287867,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69092,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270930,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95095,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100458,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212739,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25905,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 126849,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32759,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259570,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 681429,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299149,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264481,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6867,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25244,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37448,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75581,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68645,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10950,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110957,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85380,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34693,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98952,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32416,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9526,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121902,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20969,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12512,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99837,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102003,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90540,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85359,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84912,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109124,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49663,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97337,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166509,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33684,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245952,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29170,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74240,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27750,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34148,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148439,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108326,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122746,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18825,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107107,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164434,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 152217,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57519,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9998,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81951,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 173483,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69105,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107306,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69080,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76521,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13650,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75266,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25712,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108469,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14574,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72544,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18813,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88279,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132900,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10147,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117930,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121245,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12222,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249241,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86453,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57378,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4955,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29150,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30630,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67627,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17654,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27997,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313725,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122335,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14696,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111027,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126120,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146304,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34080,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 386721,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155819,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33883,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4979,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166867,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87429,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95226,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18509,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7778,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31781,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294075,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11591,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56587,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103982,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151445,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270168,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135663,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7239,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 345532,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91991,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32150,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108981,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144272,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9809,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46467,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82399,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20831,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177099,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131738,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95239,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23037,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39240,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100956,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39046,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26793,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37264,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124483,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246476,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130364,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68524,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112574,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181060,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74435,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81733,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 514049,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94045,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63997,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140171,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116391,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58704,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315814,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14903,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9293,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85270,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163457,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22413,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168764,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 174083,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90463,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48964,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37704,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84763,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77952,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38051,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640969,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12281,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107715,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83858,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240015,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69802,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68825,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 388579,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107943,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224100,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13118,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245468,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31760,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129077,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276049,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232746,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75198,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198581,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75527,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242234,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100415,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84162,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186778,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200327,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166412,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262993,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68182,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138368,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205067,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51309,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17825,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249569,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 172651,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86431,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183224,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23138,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95600,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15633,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81967,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198677,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159919,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150926,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89618,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129700,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41109,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218845,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142654,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126413,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16359,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227721,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139926,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201303,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503015,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274769,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 447625,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150185,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217106,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119024,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183750,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178576,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 351533,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97686,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16016,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18925,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407570,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135402,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243069,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70007,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 77271,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89653,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98218,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60426,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134862,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191273,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 162087,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 90391,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27161,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193366,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364963,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 330003,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227830,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121101,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266117,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254319,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95402,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 378611,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281489,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304647,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269517,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57479,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167411,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155642,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453699,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457255,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 303951,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164754,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82516,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52426,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29316,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 632967,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50252,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 596962,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30703,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 128613,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114348,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 622066,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45860,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 603102,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112027,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359546,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26728,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59014,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26563,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271614,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71685,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124893,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31173,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9874,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112242,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107650,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92271,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30990,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18350,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110610,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25238,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 461834,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186743,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103889,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125297,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54215,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128714,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19052,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66961,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278552,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76648,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61345,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60491,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46921,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112209,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 550630,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 201589,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175256,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129846,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23148,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27161,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162641,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4243,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23459,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 404072,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82573,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65485,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107115,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104057,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105147,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197079,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102168,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97564,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352620,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88562,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92276,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 213247,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96797,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189774,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232344,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24489,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 430039,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81753,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93019,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13243,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121160,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10789,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102288,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98949,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27865,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134329,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123344,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118788,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50328,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52625,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221903,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48904,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58674,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66906,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105135,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19217,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132664,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269049,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109969,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52588,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7986,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143364,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174517,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17363,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 333033,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33237,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120423,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11187,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59623,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79941,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162985,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39535,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 492651,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23772,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19344,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 130092,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65030,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161432,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48389,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136451,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87688,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67921,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118734,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95205,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182668,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123238,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32449,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58115,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74269,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33998,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142845,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141107,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 223319,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11951,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97878,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83984,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115747,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121787,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32420,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13479,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87385,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106086,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126229,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190254,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86103,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301543,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112936,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27833,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178824,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17359,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166738,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154610,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21159,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197547,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89068,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25132,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116815,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168841,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70873,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64780,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111759,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89091,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87676,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134214,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264919,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134906,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43518,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92461,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88496,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99878,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196943,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525203,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138293,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186188,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94248,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131530,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100664,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101937,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86423,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327364,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347537,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64527,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73375,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202906,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23589,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138047,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196183,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46945,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132916,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171141,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103089,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315306,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164543,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202612,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71239,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166928,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65488,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272017,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113710,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 146119,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261233,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349042,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307575,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63067,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214091,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99281,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296669,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92059,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112090,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75013,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127473,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 241085,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248878,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 244061,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257570,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86917,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218631,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357694,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23759,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289444,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41839,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167144,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52134,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 104853,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184677,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18340,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 181047,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349078,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118398,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438715,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75527,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199686,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90143,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185755,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72964,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159191,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78968,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111519,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39586,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170957,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124239,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111922,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120346,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56358,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121450,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205662,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40881,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66860,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193808,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300397,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112751,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38779,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321327,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80153,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151595,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42520,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92571,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34885,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128635,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51218,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 471094,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235285,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105974,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251314,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156366,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 152353,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324119,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128751,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466912,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 136803,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 587877,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196011,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23539,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203237,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247937,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50395,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120915,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279765,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 629587,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520043,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 550982,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245200,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25797,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 233215,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400735,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216666,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104160,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22168,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534624,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 528787,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131696,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125631,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367276,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265882,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503151,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196850,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78595,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276664,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25089,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152503,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114544,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137958,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75525,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103972,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84519,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19153,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26274,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108278,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155460,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41090,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98220,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26048,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93420,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6056,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59036,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27621,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15618,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9835,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94730,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140464,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31833,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30902,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26860,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152188,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147149,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80411,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115877,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75396,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117006,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95423,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93731,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150765,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106242,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73798,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124445,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73548,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49203,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273463,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196843,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105681,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28455,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7217,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50521,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66236,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75967,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21465,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51070,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147047,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105457,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129061,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10589,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122700,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142033,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125890,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109296,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156157,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2095,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149269,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9617,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33927,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66023,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209927,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86900,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119830,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28788,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127348,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77881,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79113,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126853,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142703,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101471,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100830,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 317608,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199145,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131349,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36941,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159082,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16644,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12388,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92131,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48946,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134570,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109148,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182255,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31790,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 467138,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314896,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307261,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126630,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82286,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140613,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89895,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4062,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54856,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116834,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15909,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137686,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17141,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166290,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185927,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139918,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5461,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29527,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335445,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106703,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104379,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134189,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71536,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97991,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56567,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50623,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61046,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108066,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354632,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50377,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39381,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453680,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119238,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325789,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15744,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138904,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 228168,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134821,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119631,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108625,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23090,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158704,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190394,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45742,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94667,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116263,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121490,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8738,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114032,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84677,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119770,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223928,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128058,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182492,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125673,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108952,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29450,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55520,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21652,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110135,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115435,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45571,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127946,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402019,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46092,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257820,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204990,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223527,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41200,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68618,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121177,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208911,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143719,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91736,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138663,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52980,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127637,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79481,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48157,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127646,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48193,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164227,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190696,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130367,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132450,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210450,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145817,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223624,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51428,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122018,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229632,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77602,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240476,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145936,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50863,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52334,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52158,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393119,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89495,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27876,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120480,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108227,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138225,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 48372,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26862,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251795,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 703303,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121162,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74311,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88860,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93705,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180872,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86023,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198076,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55706,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150762,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103322,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198828,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81235,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25691,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114658,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95516,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66142,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 300500,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473150,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 492971,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 287389,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226754,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207278,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113601,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99554,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31637,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118574,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79011,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160397,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157307,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175658,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106352,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188250,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156289,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169045,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119313,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 130153,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 584552,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138424,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37322,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268278,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 104785,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 183615,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54618,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132281,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263789,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400616,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427088,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467301,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 380982,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197575,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165553,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386466,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 459742,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54860,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371969,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 324840,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108029,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577398,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238443,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364179,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19489,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479618,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177640,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95817,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63871,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483814,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 665405,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254837,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 475949,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429991,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105901,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32280,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109917,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48625,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108378,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161039,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29089,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 2977,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111386,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219202,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53073,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27671,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8726,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10026,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112181,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43691,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28892,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25500,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179928,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22524,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7868,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8010,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17516,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133886,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187587,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176440,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11661,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171763,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17075,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62409,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49022,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50390,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15522,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127986,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74842,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23311,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123503,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34201,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163174,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121687,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179221,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195955,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145143,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124333,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111243,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20245,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76825,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162668,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80994,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109472,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106269,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137439,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145602,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157762,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97339,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44717,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145128,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 605476,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 167895,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14110,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35102,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54821,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12516,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8849,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195423,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84690,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150130,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84630,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11496,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96214,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63782,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143075,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96020,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234919,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153483,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65769,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18158,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88758,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56784,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142710,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8199,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173801,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137188,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14005,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134061,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242728,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86503,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4685,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111942,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12976,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23363,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179383,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131032,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22300,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95525,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2472,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83918,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209416,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 8142,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72298,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9986,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163490,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249457,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43232,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117708,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25629,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95182,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95032,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400945,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91629,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108044,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26446,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205885,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 250273,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140535,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103190,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102059,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35458,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105355,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77318,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147182,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14527,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57581,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151274,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287686,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116825,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246586,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121650,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81912,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28583,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 200436,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 398505,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32416,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81026,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88459,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515495,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104697,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172029,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14999,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42961,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54280,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358050,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42249,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43284,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111581,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13521,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32712,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16155,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135786,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110920,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39629,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171160,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152096,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112944,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16296,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113030,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105692,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107595,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54958,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165465,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69961,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114557,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23862,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39119,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155275,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134631,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162597,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117708,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294793,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95318,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3256,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106523,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37766,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129060,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 137129,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50695,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169787,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166526,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31952,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273202,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72664,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37162,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185117,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409638,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40583,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94045,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296284,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76515,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152221,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 197124,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105033,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71385,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91514,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224646,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86141,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189508,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 115551,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151917,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196527,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265888,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 439237,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81778,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177928,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91424,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51384,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159790,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32443,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43627,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171588,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94171,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150385,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181326,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64669,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39054,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406778,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287450,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38082,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440082,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248450,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54171,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87091,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75051,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 128058,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15744,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41440,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75377,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336492,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26710,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94521,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58503,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156026,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194970,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50800,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127011,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217980,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25499,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86209,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493647,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247126,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250289,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275533,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 645445,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54641,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251283,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212906,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327770,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53164,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283086,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513615,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 306252,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 368976,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208561,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15893,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42641,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 437776,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 23438,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165613,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51903,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434118,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51133,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245890,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 363565,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84359,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11455,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5995,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10513,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237012,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128719,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67575,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174651,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111831,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62550,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78251,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14082,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25830,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122485,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10899,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102474,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88387,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36832,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23266,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 455262,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8279,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71612,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84736,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63808,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13863,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97023,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173378,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8133,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77727,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115152,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63499,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12583,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10804,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152771,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165767,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 240388,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247029,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113376,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139168,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144273,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39053,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375646,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150141,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40820,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161331,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45551,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78341,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13828,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22133,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43474,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85248,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38775,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55365,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 244115,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108408,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139906,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7962,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146285,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95287,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98724,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31444,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23109,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26284,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143993,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25719,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98154,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79051,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37165,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149404,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10405,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90417,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224563,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140847,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140929,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7115,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16718,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84689,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100309,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74497,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88281,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 324231,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22455,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72829,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63451,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64883,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36518,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60610,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153947,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34830,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114693,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96069,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95075,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126250,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93675,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6766,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137873,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147133,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53242,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77351,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22089,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352586,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78999,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90138,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135784,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112161,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94664,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139010,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154172,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272604,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223719,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77933,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130901,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108497,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37268,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79129,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37511,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107873,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101681,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77606,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8311,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 521517,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47103,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140055,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246584,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141283,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199023,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228643,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 327302,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174991,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64978,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111291,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28434,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 680775,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 184109,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118354,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22713,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89249,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117917,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90797,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90820,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146936,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129693,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71015,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109031,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49292,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 255516,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90952,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106408,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28330,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53160,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68730,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82526,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117819,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3900,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112259,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52942,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152529,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134702,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45648,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114947,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136539,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35989,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 280369,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75196,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191706,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64057,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285842,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14011,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110870,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38107,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254681,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89271,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201084,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125966,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78112,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29283,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26840,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253374,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76875,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137087,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133054,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53714,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33379,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319744,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391279,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153311,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94121,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118031,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154407,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36618,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 401102,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542948,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135216,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98113,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418483,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56288,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134085,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46617,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93071,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206549,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129544,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 568601,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45598,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110964,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63842,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473974,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 459068,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133085,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11195,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296854,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 712615,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271667,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85159,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109951,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56059,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27537,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153055,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38811,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 478560,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79815,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231871,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172021,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 314536,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247815,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43115,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24299,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155446,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23898,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197202,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223547,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410428,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145497,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132247,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80567,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 62253,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274553,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187977,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345834,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249281,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378886,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379349,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32285,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56124,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176178,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 405210,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137307,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358663,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 207223,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380943,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175904,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147253,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28160,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323144,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350156,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433863,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29995,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88335,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31022,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159502,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512451,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 588755,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416943,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236997,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 94506,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 154048,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287906,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34161,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202384,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2967,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86142,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29601,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137316,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80983,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79690,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135079,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5404,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80521,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9228,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93185,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46092,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181400,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89261,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85499,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18769,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40950,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6523,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73892,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147248,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4754,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110424,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68230,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 217493,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32451,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10878,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10642,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238734,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31033,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577348,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152877,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46194,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26294,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66362,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28206,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135642,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30514,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104370,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211880,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103684,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49919,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11110,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137540,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15186,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74006,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9587,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11308,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46658,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53221,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63562,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171791,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119162,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28242,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35994,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117491,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75230,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197264,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89624,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69425,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141706,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78432,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81250,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11039,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125786,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146594,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79618,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83414,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85961,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22345,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111718,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56226,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109898,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70312,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130639,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11177,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106880,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128193,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239832,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110550,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40095,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32375,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201243,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67062,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5493,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78147,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39712,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262673,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31572,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44462,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83531,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31090,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102472,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17586,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80683,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15829,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29097,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95593,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119100,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74441,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89619,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113249,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49894,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209214,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129758,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191350,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46090,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 587319,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138197,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78467,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84121,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151056,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29092,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53097,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186194,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136793,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118105,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272546,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116134,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24367,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79638,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79465,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36570,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87847,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35209,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82409,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136635,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118476,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12846,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125123,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270517,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145690,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7320,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278849,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81570,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266051,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20184,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79475,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94680,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292431,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73887,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67467,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140732,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514200,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93537,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77382,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30460,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74370,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285885,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7700,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200278,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 345395,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17410,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22437,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123028,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374502,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10543,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158264,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55896,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125393,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184402,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118116,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155210,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21311,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181396,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153504,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 199058,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147321,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148856,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104456,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335536,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148911,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252602,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176598,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156266,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38061,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96082,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 595485,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65271,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44932,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152880,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46473,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17239,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239255,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125516,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210576,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37215,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226957,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 134786,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350034,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198842,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 464820,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87192,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186825,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 369878,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108687,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88392,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 579061,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114512,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8877,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142687,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184621,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65841,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210988,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280528,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130406,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488415,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207342,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 532278,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108648,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165945,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75486,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220079,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71718,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 166559,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11419,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181795,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 713834,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123827,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 312876,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218405,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232692,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180260,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 475565,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12211,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225484,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50262,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253527,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102334,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255313,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45344,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70708,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223645,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42667,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146219,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243423,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73857,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349102,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 490161,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70978,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315720,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12229,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24476,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 157885,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94945,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71953,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93740,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75323,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84195,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410312,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444967,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507990,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468768,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53502,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425933,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68143,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22384,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147645,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144872,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 417561,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 583652,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94836,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201843,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483994,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110673,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 616600,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 366974,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 148971,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389217,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359475,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52847,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464387,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111344,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84151,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84740,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78495,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33399,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109942,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96989,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40218,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105010,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245775,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99388,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145752,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137441,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85718,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136309,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41443,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103763,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85450,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12029,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27867,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 295111,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209591,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60949,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79423,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8402,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70514,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274878,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11137,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28018,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103806,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93192,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117157,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14770,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17347,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35628,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159362,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14418,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148094,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49581,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24183,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 595846,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82090,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146124,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139121,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123180,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51193,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 53596,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136573,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39023,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49245,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40269,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113037,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7030,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92093,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127019,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24517,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45347,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126722,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35176,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48699,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7455,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100259,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14352,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8694,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 5226,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23796,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172668,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81896,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111197,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269861,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204349,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43475,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159489,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90775,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69290,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157564,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139253,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 239373,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294433,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124410,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152395,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74033,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85567,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279801,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21518,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157241,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7301,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93286,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86946,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80553,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13396,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45691,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49951,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33777,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70299,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16165,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359528,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260037,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33135,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125128,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63471,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153118,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67693,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252664,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181611,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70743,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11414,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105839,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315571,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23722,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 220738,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130505,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43295,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132177,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87983,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122232,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154425,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4669,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 384251,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292729,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155740,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93823,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 217052,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54397,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254640,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117154,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8822,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33913,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86809,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62372,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127286,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 500247,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112193,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117275,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66705,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111271,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174409,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328242,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85991,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224330,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35286,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5363,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97390,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43333,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28637,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355087,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38334,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105867,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353561,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46200,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84396,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219313,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39011,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111694,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7025,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 374924,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23653,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 187763,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 533118,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159620,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184997,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284935,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22248,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201921,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17259,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8285,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218427,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296747,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74025,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136733,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98083,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 274888,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325349,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119400,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91249,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45523,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89754,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65164,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203440,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42585,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 395775,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192252,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36335,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176568,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160130,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14117,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 310856,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155089,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16196,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326004,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148851,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427835,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261314,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57819,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161956,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183397,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219303,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173007,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199966,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 109905,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85339,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109951,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288208,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133873,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251636,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75229,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56513,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31149,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131178,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134295,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204880,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386840,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191906,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364887,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73814,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210726,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37215,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130486,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 386034,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309941,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181160,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111903,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321260,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25712,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82337,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 101909,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203613,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 368316,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83943,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 245589,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 583039,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155295,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58255,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117568,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311373,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298775,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128272,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63094,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36184,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152859,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494246,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99792,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 132521,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23986,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107763,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95501,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 132690,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30454,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275135,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294198,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42356,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 299550,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261028,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425339,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50877,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 169131,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378447,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213632,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 374626,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 363044,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558727,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370528,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71763,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 593509,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61244,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 170346,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 69482,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 392522,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418024,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373304,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 143974,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161121,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19662,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12062,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82124,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57367,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115192,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50641,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15863,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34019,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4299,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30819,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150946,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9198,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9269,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 352914,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44108,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71846,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10721,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9983,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92538,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14663,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100801,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40254,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153717,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27990,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75337,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60256,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20628,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168142,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44938,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102077,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95913,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160816,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92906,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173064,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 161975,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79065,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20719,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105282,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14023,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35068,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157405,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 371335,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234429,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76455,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241129,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105614,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36054,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46658,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14767,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28431,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2987,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119721,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102791,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27029,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140724,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98657,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53068,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83533,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39641,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69469,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38535,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24682,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69615,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97188,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75134,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25079,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86252,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131344,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65290,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22812,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100382,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164957,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19926,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88492,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72549,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51921,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55968,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31406,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42098,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16653,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148393,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31902,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69290,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92680,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13826,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52118,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135867,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99560,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84246,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91260,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161932,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11865,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82100,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147113,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72995,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25369,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93136,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134979,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 265728,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29357,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38336,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50961,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17431,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227286,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20895,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12238,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27912,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66804,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79269,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185416,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189815,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9955,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 17253,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131005,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286726,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25561,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136776,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10125,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22152,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 325851,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7950,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271972,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53208,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75234,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 165667,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80355,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97299,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40344,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32639,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145608,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24951,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152889,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37797,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194328,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162926,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58135,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316224,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139464,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161539,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166666,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141647,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183165,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246597,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39103,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44279,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25953,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153062,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317394,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127196,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227330,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79863,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36573,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 359500,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41802,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11227,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176782,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322604,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110972,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132307,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56098,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456156,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247976,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48583,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20279,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24641,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135994,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11663,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143526,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96730,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95834,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50344,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313820,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68412,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250603,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92952,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107179,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256535,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26964,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79316,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286432,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57883,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71536,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122091,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120325,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120224,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284797,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143417,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156925,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10971,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313720,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 482644,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240766,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239482,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48344,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77452,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98393,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134064,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415093,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409943,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127856,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183611,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17232,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82871,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43364,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 62862,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52106,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115611,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26076,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210122,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266967,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150748,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232617,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213741,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 531953,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45571,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287108,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48209,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284678,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365068,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 535308,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197417,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371248,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10978,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 369016,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278453,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 125560,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494729,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63866,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 69432,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556091,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86298,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 497991,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466627,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260987,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115468,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239550,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42909,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558843,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440824,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253239,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 120957,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214887,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93895,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 32639,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45776,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175144,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425846,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 37799,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427299,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50451,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112030,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22941,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18049,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195080,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19160,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 184936,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56613,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114496,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125006,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107653,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82876,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94441,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62967,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146683,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12677,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54229,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55318,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87738,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123995,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71841,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21185,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98852,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33780,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86644,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67231,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71611,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149780,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135731,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13439,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10647,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 115321,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83008,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 6455,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94126,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102297,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75167,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297980,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109575,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24150,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88276,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14593,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173594,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125235,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 289914,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431765,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23939,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 243743,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18247,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9167,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249571,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151435,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194126,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 468563,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155914,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37725,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35770,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72622,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222182,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30608,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51456,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151872,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37049,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75579,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198756,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159019,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145357,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106105,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12456,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13338,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277886,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151527,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123970,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103800,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91723,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54423,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63837,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106951,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81804,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140108,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109016,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32675,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280391,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253499,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96262,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 176665,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21945,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143297,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115081,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171321,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22355,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35461,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76383,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88841,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52223,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4445,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25390,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209765,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126231,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96696,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64311,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201946,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132466,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88678,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72561,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143063,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72525,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63200,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120782,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121951,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27572,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228798,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99044,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55794,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36474,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268107,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133744,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55786,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159306,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13275,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201283,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264898,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87032,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161619,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19021,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66631,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39741,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55696,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72294,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46711,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109884,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190085,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29571,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 360294,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190926,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60292,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119369,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64955,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 368443,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211140,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109665,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122438,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190561,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41480,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50622,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239379,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154686,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181706,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35629,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48468,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7100,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119862,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73266,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107086,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461500,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94451,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20310,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111923,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88252,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119601,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139661,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113150,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179950,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23805,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44104,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145077,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101755,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37758,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169456,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49933,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28930,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230739,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174642,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113993,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 214056,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142442,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245734,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18143,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88154,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232819,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31134,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21985,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76159,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140891,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23505,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 90150,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31396,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66534,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190708,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172048,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62725,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146115,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298000,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260506,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69235,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46124,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161700,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25893,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179026,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141530,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96716,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135204,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115257,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335905,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534871,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389849,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204478,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70549,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35504,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 599956,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 353066,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189198,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263799,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 339287,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334115,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 652263,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57002,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 139553,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116824,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205182,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32771,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450182,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91568,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 307653,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358966,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314457,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130404,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 288041,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201774,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245037,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112396,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167831,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 282976,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207486,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 760041,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58566,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 257648,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496821,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45179,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373142,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 368285,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80958,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158687,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556451,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216734,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523596,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196496,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155973,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19779,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 487275,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545853,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65694,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44811,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160048,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236803,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2830,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7422,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124121,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83877,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101041,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7976,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141010,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179208,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13364,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93998,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13129,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16966,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40422,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183462,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6694,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114901,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128999,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152299,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115181,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11093,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7536,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150844,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140287,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135707,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99548,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48201,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13060,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47356,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214721,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106833,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230411,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40955,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10907,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42492,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41968,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154680,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133163,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247069,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5712,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20588,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 175902,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11556,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55517,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255319,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122967,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58981,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106565,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97800,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77134,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12323,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26116,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47327,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129531,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92596,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79634,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40927,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12005,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186242,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30723,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88316,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238420,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31868,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18640,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138616,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100662,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169411,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104442,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147022,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143035,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139714,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73147,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92627,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34284,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145360,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68974,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16933,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16464,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5007,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120006,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209182,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6568,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221040,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29745,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65219,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150776,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5896,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144553,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50483,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53363,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122999,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113403,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12270,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134419,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82265,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117341,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38806,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 529847,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153816,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94048,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138118,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38453,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 100087,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197769,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95390,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125504,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313828,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45853,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204303,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11225,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59733,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281077,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73753,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101190,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150069,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219296,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72670,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 142415,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86679,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102064,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64281,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126473,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45170,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69930,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152534,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21561,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195430,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111211,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138034,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68660,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30957,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121957,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252773,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92470,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220426,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111334,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30729,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145481,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122476,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263014,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40843,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80105,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14979,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195132,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125591,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240334,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39429,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143544,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37524,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 8046,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69119,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70353,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9614,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18963,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43014,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76248,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156392,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29991,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27726,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115585,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26663,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121619,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54131,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113444,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24301,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81119,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51215,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38629,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131552,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142013,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44102,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68525,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279217,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25473,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171892,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203123,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10349,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134737,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144464,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141584,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127288,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287268,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403153,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19712,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 615333,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146229,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146081,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143890,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67561,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104568,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25795,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 331945,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117150,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101427,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160599,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 135322,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 344307,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234484,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96388,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183441,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122671,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183812,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133711,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58670,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164798,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94942,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81739,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103906,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25810,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226542,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295751,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 85492,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552548,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45647,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102610,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86564,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46907,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 368581,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119672,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32313,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56255,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338093,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 242677,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 625309,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190461,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 300615,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169856,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109849,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176137,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180318,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 727623,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37637,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64835,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202251,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69817,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171820,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72133,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246107,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143414,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340578,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151735,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360716,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58226,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185272,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451995,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305396,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53518,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 378236,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24808,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43941,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152754,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 670628,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70699,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249442,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473722,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412416,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132413,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32049,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38057,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38146,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 556305,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 17425,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25568,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191584,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529550,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174237,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 469487,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302128,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394112,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42929,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199077,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242343,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16364,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76127,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92161,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25780,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58532,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 369212,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67953,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71482,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86357,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19824,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29572,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122221,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73858,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97666,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131889,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11884,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11488,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129880,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143165,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218191,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185356,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156178,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99832,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31843,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109331,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46281,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137847,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105023,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143058,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126867,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 167212,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57049,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78888,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8996,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36758,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25932,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86086,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109128,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 235921,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127830,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136820,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102126,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113729,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26145,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23801,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108605,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41876,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116985,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33781,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170100,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394544,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83815,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91362,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8345,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29943,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138436,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75957,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75585,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19007,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81329,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52318,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179032,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12731,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26691,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29585,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36622,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116006,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108024,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431014,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27920,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7952,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19839,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 292242,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316006,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49402,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409440,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95141,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307729,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211356,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35687,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150225,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42590,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 404224,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183888,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39475,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6861,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20734,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116226,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94530,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86358,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141980,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91507,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71287,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238669,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88374,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159542,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 17922,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91232,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57920,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22073,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123599,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262537,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108159,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8897,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47726,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32161,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108298,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35298,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77659,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106911,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101551,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306237,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230479,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165924,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88846,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72731,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 498256,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188695,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187158,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112780,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123365,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 274227,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 343070,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151653,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 280630,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17587,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157476,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148790,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61912,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 167264,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121817,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265012,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265407,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 153909,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157730,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74554,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444578,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 75480,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251933,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42227,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159821,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233655,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156505,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81652,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158344,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102011,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259790,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75040,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250180,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243545,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146363,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130542,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120985,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92216,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458512,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90896,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109377,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222775,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 181005,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310922,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62217,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55885,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 119316,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94270,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226842,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141077,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14711,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136916,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146814,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225038,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130965,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75334,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148857,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204060,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195491,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252266,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 678985,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151521,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230736,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147357,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323069,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29841,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36512,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618921,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79095,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253374,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62158,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96582,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376643,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 168600,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172393,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273698,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112166,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 391660,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181060,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222676,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162607,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76620,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 343524,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237682,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178142,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82378,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311129,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513061,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 409968,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256915,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136200,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 453296,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 352545,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188974,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38983,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395779,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412861,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473450,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131224,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283017,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67802,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219900,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 504254,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 104584,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45802,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173627,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264267,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133638,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59144,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370702,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 221824,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536710,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43556,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127872,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344566,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99358,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48049,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29661,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13182,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52296,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63104,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137137,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96757,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113962,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145507,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96754,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17394,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4366,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20611,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41690,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123546,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28530,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18794,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82515,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138912,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103138,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140969,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26126,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13949,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121185,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77176,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108666,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91480,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148644,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45388,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 369380,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96853,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144142,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88457,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69468,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10300,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77739,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190100,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6289,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427955,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91964,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184366,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260002,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269369,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102175,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27131,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44740,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116510,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30642,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159967,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17014,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157154,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115483,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69817,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7497,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57741,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135237,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75828,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63219,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128892,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 420821,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82617,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127101,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170992,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21631,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16794,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16579,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21304,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39552,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116345,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23750,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30502,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26505,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177459,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69617,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115295,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46910,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12329,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57599,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53159,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106553,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86560,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55556,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98919,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112071,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359879,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64885,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191517,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27992,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222836,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15219,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52619,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104439,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97382,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147418,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96926,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29848,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316014,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 312757,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12265,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10942,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34142,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115303,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58459,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143065,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40701,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251980,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17162,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47900,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89301,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47756,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231454,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21336,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270456,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114137,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65160,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235073,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118775,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94574,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38613,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172880,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178928,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32312,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63582,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115062,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 385263,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165777,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463452,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141309,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239782,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81182,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113746,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35595,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201794,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118079,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39109,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 423167,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211565,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127092,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130972,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153651,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69494,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174204,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77772,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28140,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25446,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12670,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224710,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209399,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260467,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287940,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10815,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 116627,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3341,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26976,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116121,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 5902,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159201,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193145,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415318,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128687,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106045,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134376,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178735,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55380,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111004,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45649,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44249,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84893,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86963,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 185420,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80641,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29929,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77449,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171516,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112090,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82637,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43931,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221776,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47648,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493456,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55999,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56817,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56588,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400165,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308508,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244255,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56434,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136183,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18382,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43842,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 380777,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68694,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201366,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22902,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42956,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110625,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45068,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80664,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234942,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65266,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281984,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151433,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143699,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267242,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35346,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 146791,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161704,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212135,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90370,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86984,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177291,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178767,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61450,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48336,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 165329,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 348660,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22742,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33461,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 198639,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107537,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296530,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307004,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207018,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421500,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 430797,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 339309,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 412223,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155383,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231928,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47328,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241073,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 351602,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389468,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81430,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328483,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105735,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78139,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69350,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36637,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 79112,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53789,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77077,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21331,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109911,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19890,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79851,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69766,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34527,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51176,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22173,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42815,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29519,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43657,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82360,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12528,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70020,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86363,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19838,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89302,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128077,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164582,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94016,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17208,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16192,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114015,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38380,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128328,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180435,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70801,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109258,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134821,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15358,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94823,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22437,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135263,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19130,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236448,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120394,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114196,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288135,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96811,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167656,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10885,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129667,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 325224,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28449,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136410,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92479,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79832,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141506,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37701,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9681,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196813,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130875,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26673,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85820,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56977,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22331,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16957,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83745,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264893,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46889,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180332,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27340,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89078,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105945,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148134,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96371,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117989,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121560,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116325,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26414,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47100,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72144,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7323,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82499,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47567,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150041,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230578,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33896,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34006,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10717,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29603,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51637,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 599796,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23332,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27844,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105313,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103825,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87205,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106064,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104575,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88088,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107617,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82955,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117838,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148144,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215925,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192969,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71955,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80450,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80427,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142097,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67087,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137519,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537697,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 589651,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73401,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132681,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187222,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30646,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27091,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196567,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29173,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236732,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75238,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232946,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68900,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90205,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82013,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57889,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148482,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220631,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171615,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97603,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29903,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 118839,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122868,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121707,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618974,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114575,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183706,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119650,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180411,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153932,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159023,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156182,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272885,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116565,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31548,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113111,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58253,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238971,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88035,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86723,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266319,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160800,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73519,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54361,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24041,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 145856,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127932,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29772,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20375,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32726,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291042,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 325692,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322823,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397474,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82816,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401086,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285387,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117505,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177014,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 89628,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5611,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 601671,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31038,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290595,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52700,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89220,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 131152,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250728,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274021,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 522781,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83292,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303983,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42055,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448450,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242915,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31376,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 502555,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42712,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84541,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176736,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184614,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65926,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388069,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274679,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88502,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509517,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99247,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167932,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151970,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168213,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348122,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 79859,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133756,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71833,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231082,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 202605,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239240,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581067,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22782,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419881,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57605,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82707,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 575671,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90323,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85767,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220000,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397858,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105822,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235527,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226264,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37574,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211442,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243440,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308642,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401950,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39350,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207002,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 613836,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205160,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 485084,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216695,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 383222,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61588,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 384024,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102336,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48682,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 347884,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229756,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 96330,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 95070,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 345065,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370621,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135457,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10083,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9912,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13249,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130189,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78269,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84173,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135846,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17542,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159670,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222534,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76052,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68212,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50986,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152316,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67195,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51680,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15635,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35568,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170365,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15003,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 308309,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8246,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59743,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132445,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73261,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16109,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25544,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273004,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27600,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45527,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7515,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62275,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96769,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167284,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91469,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85854,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112118,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121771,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112971,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50098,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79147,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128933,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92119,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24967,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74532,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37717,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105525,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112745,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114968,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 282965,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16914,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130532,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45043,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237820,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53970,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266910,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133736,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 104821,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170587,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36131,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95986,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20528,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6463,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104531,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77605,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99629,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93351,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66448,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 387376,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162518,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49955,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34017,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72545,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45277,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117829,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318296,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222765,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131763,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121271,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57606,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51170,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43282,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25670,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47160,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115896,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304378,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96515,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12414,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26565,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113369,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93472,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90078,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80789,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83471,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19874,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106064,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32934,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303584,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55784,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156017,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143218,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 381437,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125909,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102611,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72932,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124381,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210903,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219066,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89516,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 135462,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95685,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 155036,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72731,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38862,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54255,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17387,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16993,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39279,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101220,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32407,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56389,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40885,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162146,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84819,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93516,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99421,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13955,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21743,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77005,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91084,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63440,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59800,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179525,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128002,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51111,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101771,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128267,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5706,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18056,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46743,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57927,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33728,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54572,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297858,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145722,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 361853,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80729,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44793,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225591,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185343,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117886,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18695,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78309,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242024,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227649,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19824,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102709,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298266,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 398696,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 256116,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160232,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120046,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238101,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30708,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63995,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271463,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22544,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98277,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134574,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64583,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290887,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31214,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138947,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92922,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153592,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69547,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114124,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76246,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105949,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 601882,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208146,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34664,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30616,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 340963,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254788,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154058,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247757,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 501704,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183621,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254432,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49501,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54008,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119477,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201429,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348850,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91301,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25088,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191240,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89861,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112830,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 455698,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190771,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233406,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118776,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 323968,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184015,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243783,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 167054,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113719,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182312,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404018,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37503,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128762,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74623,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79597,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63514,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26612,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36299,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93113,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71325,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110302,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 353406,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161534,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117506,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30349,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280935,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208975,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258712,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51592,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32261,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 448243,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 629881,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 177539,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 611666,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53455,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88628,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315953,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 666543,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10784,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 443910,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308227,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294862,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75529,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40760,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414331,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253031,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 424420,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186258,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93624,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211994,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116141,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275050,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138772,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146207,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456982,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159373,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412863,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 398214,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217693,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42604,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 647476,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 346438,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82213,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 24070,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212551,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349765,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29822,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 408630,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345877,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 588877,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401334,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293906,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50355,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267369,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294477,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111430,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412603,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208613,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320161,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220334,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170339,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51740,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150027,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 341485,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337136,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111583,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14768,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93527,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406706,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324849,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314578,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70224,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46052,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115307,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29373,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94889,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41926,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77560,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40920,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75597,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73317,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114477,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98339,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13939,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103438,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8705,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68174,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89606,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85268,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86646,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107874,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17330,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84883,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11593,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99857,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35698,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249359,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39393,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156792,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21092,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120454,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106761,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68233,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219988,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95030,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30596,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16210,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9641,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201557,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140555,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257736,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50010,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287205,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78671,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97743,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 171419,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28047,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119742,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 167036,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99388,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102932,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18825,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90719,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10770,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56936,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15880,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5945,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19072,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86488,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20099,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133414,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 495480,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126110,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110156,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63822,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124810,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83969,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70924,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14840,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288396,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18078,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63316,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10885,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107833,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83142,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160501,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7493,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32973,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50349,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80260,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87535,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2158,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11001,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72026,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345778,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248896,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150311,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121704,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10374,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23013,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134756,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83296,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55268,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125351,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91727,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36566,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37514,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115715,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95819,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87060,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10769,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37774,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67320,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108678,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80322,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62793,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473059,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119018,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 211621,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124245,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50374,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 280195,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70028,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71821,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135472,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208005,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269155,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27202,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136445,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114237,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66920,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184856,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147239,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38999,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6802,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138266,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40781,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53144,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 460202,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16312,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135888,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61648,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44326,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102573,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16388,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27826,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123494,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11100,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88396,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171486,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28717,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163845,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183079,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15584,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147558,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 573847,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133159,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38447,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145168,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122158,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33986,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189616,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16361,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269478,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142172,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104868,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135524,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164393,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98448,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83218,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66737,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98436,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254663,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76195,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136507,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15089,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536201,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93649,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 140343,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26905,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282130,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391633,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164890,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150321,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80993,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119138,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115491,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33855,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426507,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249081,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 502462,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463098,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29828,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269382,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80470,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250133,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197988,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68542,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134225,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150224,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290163,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355493,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 332926,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93359,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66938,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 351153,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152634,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 333745,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79495,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31241,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139459,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110425,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112784,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114478,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151702,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 40827,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60029,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 583162,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 250133,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 562485,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 137516,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 358601,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185035,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469298,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270330,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23218,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159560,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86463,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73650,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151057,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 679945,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215420,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 56835,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424745,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337665,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309461,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102941,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311412,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283863,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 318770,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223179,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329910,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640098,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244264,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427655,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315495,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65910,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10561,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38632,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33699,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59642,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37653,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124690,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4930,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16198,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46645,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89817,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216621,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72249,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110500,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34882,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28396,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76057,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25003,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144901,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11826,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114570,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23762,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125397,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14915,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173196,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21816,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22281,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55180,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94416,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82810,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233432,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107533,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263310,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93389,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116065,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77470,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25518,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80245,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20290,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87136,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24764,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328972,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7338,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102840,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20149,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103732,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126127,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99616,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25737,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125875,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6033,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42419,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124157,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12408,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268518,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 488947,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39734,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92247,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106684,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9828,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103446,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39497,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13858,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99144,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116965,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207583,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31579,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44461,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27088,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51512,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103412,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136675,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142784,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53896,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255357,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8568,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143129,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59505,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98017,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31514,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31761,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50206,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55868,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114637,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62993,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116651,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349093,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44730,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135436,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83413,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25076,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140222,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9182,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87048,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137729,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132551,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27703,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103304,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555107,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26004,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73677,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246622,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167943,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156633,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134990,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 72082,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84179,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146970,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230991,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142300,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43011,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156435,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27451,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112426,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15628,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106288,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59777,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298923,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 619480,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131730,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160383,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121777,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94496,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94008,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152951,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22669,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107849,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141613,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276107,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57352,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155619,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182788,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111650,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21298,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107099,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195370,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23686,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22698,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150865,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96817,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71211,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95406,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81824,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 213586,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178582,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61066,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153060,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27066,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339901,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22491,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26985,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100064,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80208,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193455,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82307,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150726,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179972,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155875,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310565,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196647,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274821,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11356,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108670,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96405,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145898,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 389944,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 233894,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17376,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97204,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122712,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29778,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122350,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62128,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23878,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30123,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 36756,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 614383,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136787,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132060,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59043,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122926,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66060,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110470,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 79957,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295924,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42806,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108354,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276450,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148906,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193180,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304646,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130344,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58455,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50636,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51138,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269120,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25597,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 66235,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252392,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198724,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21407,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175789,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38769,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58714,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47415,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172673,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288006,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367110,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161737,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201549,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243733,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299960,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283074,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227148,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16419,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434302,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 106093,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 438121,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 569996,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170556,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226053,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132266,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 142370,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212144,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400030,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244320,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166759,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30723,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 332668,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 132674,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63828,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67975,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318660,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63017,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274166,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 575347,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55619,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437987,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345035,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 343967,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210071,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130118,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65612,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111835,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75967,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186510,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226608,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139148,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163387,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104923,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78589,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63103,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55494,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103005,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6637,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85886,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108471,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10239,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72538,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37211,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35890,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77392,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79334,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14723,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52873,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132968,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58288,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102407,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132521,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128274,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88221,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26723,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172594,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14678,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107757,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32147,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26817,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265586,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10607,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119740,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217702,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56894,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148255,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81030,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245730,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127487,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9670,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71689,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401180,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11000,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126565,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34598,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82326,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168932,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58050,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140892,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80111,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34963,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65819,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10944,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100199,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217092,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89589,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 172268,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24386,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54715,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199736,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299585,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42750,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157194,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181865,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88588,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71706,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119544,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75911,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85903,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18669,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65263,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9262,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64304,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30339,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127468,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63892,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50339,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38637,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58071,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102963,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 229953,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134894,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67847,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153854,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110650,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21730,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225574,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16282,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205428,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67979,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137978,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36329,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98289,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140797,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108044,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39027,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112512,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479485,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65869,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88139,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3476,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27935,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15432,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33158,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299058,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103228,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179939,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196033,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 360465,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286263,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 76898,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224409,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103700,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204721,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206173,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102657,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130546,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15597,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184937,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93983,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112802,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146271,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78768,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154285,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86133,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62278,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85008,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188260,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191822,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143171,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44026,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198891,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98308,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142581,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424849,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98210,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 139064,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76480,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175223,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83803,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87126,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230382,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97841,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78313,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97147,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130855,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141927,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285923,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262734,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67954,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 260171,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143404,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45026,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75038,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78820,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66050,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28473,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279173,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164264,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 257925,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44685,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30694,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92436,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229390,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125069,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105734,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81928,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27989,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55774,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109452,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28367,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149930,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224937,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100086,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35947,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151178,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189596,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197431,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106202,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29211,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19227,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72235,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201435,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140023,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124896,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152086,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36843,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7626,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13682,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515518,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 731934,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98430,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32761,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143076,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20362,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173030,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106607,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122136,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168492,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194440,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 398039,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17860,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49904,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618025,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80193,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251581,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172849,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315568,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89901,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176378,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235075,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91274,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67076,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63210,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 193587,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213752,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 278122,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 202043,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 341449,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156353,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339516,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64080,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74021,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 258226,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208811,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194815,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30869,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65591,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178149,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147940,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 39350,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57667,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108124,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335928,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75220,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30607,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397589,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24714,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434604,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109425,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520290,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121386,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168621,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 503109,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60172,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96322,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102659,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188975,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264457,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383701,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80442,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 679449,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61010,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82764,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 282063,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189440,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474803,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254035,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 31395,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 119598,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 382234,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477537,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 760713,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26763,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242658,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 124118,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 366432,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 351438,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 385401,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 564419,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 352956,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33206,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33059,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130598,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74324,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98016,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93759,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37953,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9276,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93504,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21852,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112439,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12088,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103724,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12046,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96256,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189147,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20280,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63574,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9258,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103656,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25497,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12475,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100763,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178836,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16145,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120009,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80656,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10591,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173719,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262633,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 283114,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108679,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104494,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90926,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89974,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25229,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84885,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 308462,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143547,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20173,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151353,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14704,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130907,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69037,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82326,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121270,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179538,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37086,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45554,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113399,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55535,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159769,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120052,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 149758,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114675,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68557,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133862,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35433,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54323,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31144,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105677,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50330,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48399,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110650,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26982,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75625,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49681,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31823,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77049,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16195,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134476,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72112,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23369,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125593,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107953,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86169,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20883,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76024,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 608605,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 412635,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171442,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119899,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206629,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156464,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38449,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168838,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 547738,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8278,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37748,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98751,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 69974,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224379,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61262,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102708,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153251,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46326,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40399,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106474,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149985,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21446,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25382,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155078,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156075,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187851,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29845,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272307,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38500,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52968,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40630,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74672,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21584,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109058,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125763,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129302,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120244,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226282,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133815,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205749,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172657,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 361052,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133390,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28112,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 433160,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20341,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191964,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129188,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165092,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 485794,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175835,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77932,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82144,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141737,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63964,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59934,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14900,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17679,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52724,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92545,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136074,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 188838,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84048,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18118,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8714,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91681,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93165,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 92640,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104650,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31503,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110093,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29416,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138875,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131719,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113181,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286423,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30821,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113676,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127942,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292668,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124371,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50421,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199070,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25453,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150562,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232122,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211471,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108259,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81856,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95338,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124996,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145871,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148670,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184106,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153252,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537673,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23672,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144725,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71885,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244235,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69458,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 132645,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100184,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41700,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196072,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22444,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52006,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 269568,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239218,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359305,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29601,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91489,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162716,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186309,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276355,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180649,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45202,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91878,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196883,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80962,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99167,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86398,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101655,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26985,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129888,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16674,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155102,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275571,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127233,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162307,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174492,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54422,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217685,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 185125,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88072,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190709,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166034,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432957,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117106,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77197,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 380227,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98452,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 600223,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115998,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 314228,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183944,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40485,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270564,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 129496,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60631,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66735,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 677549,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129718,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292760,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 258962,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453280,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 180524,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286995,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493087,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284064,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383930,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402737,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458506,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225422,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300410,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429214,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 598544,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 47398,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372663,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444689,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104709,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 543970,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34773,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36177,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 116620,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127206,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 154502,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 361601,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 124449,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292109,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132122,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75808,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87604,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14958,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13167,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97331,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72020,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29311,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72255,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30549,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260650,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 454188,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8794,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13612,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25228,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84627,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77006,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18960,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 94660,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92751,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13438,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85629,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152872,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107158,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141828,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6012,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52070,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158997,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46997,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158713,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233109,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16680,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136944,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84036,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27882,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 332903,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46592,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50305,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64043,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11121,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126850,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237162,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82542,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96891,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11122,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102081,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123364,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96966,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17970,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153907,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81442,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59203,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34263,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157061,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16924,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257024,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30619,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23334,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7800,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44651,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 425486,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90401,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4684,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 58959,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64219,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14685,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 279249,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58442,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93190,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9471,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18662,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173414,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37837,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23403,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98847,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108333,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111435,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91142,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38886,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43328,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82368,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17251,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49931,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195076,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40897,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107834,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50026,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113377,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124314,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61133,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100444,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56651,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110213,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17672,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 325519,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121280,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127356,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27426,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 231827,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18170,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47279,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266619,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129494,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264604,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219564,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42355,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12101,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239304,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 322788,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56954,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 135615,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157971,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 118675,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73690,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311799,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141270,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 223144,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29641,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169034,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29654,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154559,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236126,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67637,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107187,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146602,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110417,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150726,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150581,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202004,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183660,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77003,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127592,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82355,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169512,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34030,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 145642,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312056,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140367,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40813,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 423803,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308499,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 179527,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136286,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192945,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56875,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66333,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153664,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 151925,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136326,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39848,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38563,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37056,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78102,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 330838,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118512,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108880,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94804,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88415,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125826,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40264,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112914,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243936,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141434,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377350,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 432621,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121628,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28538,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190699,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49902,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107154,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432372,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135803,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199918,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211272,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342265,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72697,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306242,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136719,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174534,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224362,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283234,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258743,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114695,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360173,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117073,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117168,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143762,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23007,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39441,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266080,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76390,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186843,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212347,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394160,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486748,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258762,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307599,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 513155,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45865,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344587,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245054,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93615,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59506,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48744,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86976,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32589,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78408,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182397,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 592371,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 466082,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215170,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388325,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166074,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 48375,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46014,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 268265,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238900,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453483,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156458,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 113354,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15703,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51872,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69934,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160430,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415057,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 265830,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541200,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196645,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294049,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 325062,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 545473,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 570566,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459122,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95491,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448124,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19740,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 651914,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448406,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384292,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239631,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 38289,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 118140,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75554,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633604,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150934,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142714,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27535,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12869,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28769,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38055,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37211,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92096,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92843,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38278,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92592,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11371,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45159,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143332,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127411,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142099,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90283,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56676,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104492,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549224,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121940,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23756,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109680,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50603,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90058,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21684,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45423,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136036,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14906,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142665,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41950,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26981,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 340766,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73260,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115373,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31322,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277179,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15927,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104237,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23853,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211745,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10002,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104755,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45185,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136275,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103909,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162749,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13611,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128986,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93331,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238538,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158354,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86097,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96049,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 131696,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185680,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26712,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85756,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278339,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76335,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84103,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47632,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78061,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150537,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108080,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12563,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55516,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104737,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24122,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147149,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29938,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101991,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72963,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73359,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130475,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5105,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157560,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95933,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228773,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113916,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46165,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120288,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47257,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12282,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60693,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281756,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95443,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114903,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107807,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 528516,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38188,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 228562,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181528,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141779,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93103,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157981,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55612,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148868,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29112,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125314,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10768,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28274,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113256,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140283,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176068,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90526,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55327,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142218,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206493,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 337292,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32931,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124995,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 394364,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48990,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67545,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312606,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260833,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154741,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102283,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65107,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49699,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19400,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83747,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76293,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108809,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97086,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50554,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70675,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13936,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328425,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28912,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150535,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59178,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67195,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208784,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193705,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123384,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 395290,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113734,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114122,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183818,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41012,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78103,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116692,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 202212,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123045,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247123,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172598,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90315,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114231,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127118,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157574,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273295,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53434,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154949,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114902,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124599,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176070,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427009,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94653,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83617,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99938,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7028,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87153,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34045,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35952,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110398,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278228,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29479,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61523,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105307,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48062,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161936,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47278,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153677,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192155,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182318,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 309139,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74719,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162007,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196830,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221105,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197171,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469224,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29065,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138176,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97003,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143659,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141856,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136732,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86449,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77621,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205731,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 435341,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175688,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40420,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286957,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 126209,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 340456,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139647,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 207073,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205940,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174998,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425619,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105078,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349533,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315474,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184696,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45362,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127190,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 215270,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139017,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24611,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105446,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51982,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41718,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69596,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154213,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249309,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264254,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246488,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 675935,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29129,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308796,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 269973,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154154,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419534,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13758,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74845,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172423,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55882,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 266351,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194833,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 177385,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365005,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187145,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169174,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401275,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 402046,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150503,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420988,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41853,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84964,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207889,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53912,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209699,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 68453,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55971,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25639,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568212,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315937,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314521,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29350,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253545,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481474,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 655428,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516169,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41612,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 125048,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 392441,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58038,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315358,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256652,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110331,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150564,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161250,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196841,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41004,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71245,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90282,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72102,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12613,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73157,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411137,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105939,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29460,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127066,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115050,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85075,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110490,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35765,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112763,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88393,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36651,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69784,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99732,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84480,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28989,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30487,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43412,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103389,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82186,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 404972,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75734,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10087,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20919,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10722,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201553,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155725,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89220,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21993,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77098,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14977,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147544,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75845,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14768,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24818,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32475,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147254,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50249,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103628,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102013,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61495,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83106,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93010,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37660,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14055,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170559,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63084,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35697,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304992,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142163,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6440,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57189,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83581,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121228,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35663,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72220,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112515,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138950,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139361,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113829,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56795,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109607,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330059,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37586,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245459,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161620,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124915,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143065,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119486,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19075,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23875,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143366,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95978,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23705,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72311,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51672,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70316,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13350,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145618,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 119127,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195692,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15654,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165729,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18981,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92523,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135869,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127211,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36254,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82038,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52719,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78111,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101507,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7657,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90713,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78941,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97100,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121129,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349558,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143827,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12450,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11058,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189199,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143496,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110982,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113006,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28431,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79148,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4051,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 310817,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7545,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 446986,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186937,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207384,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29829,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5266,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96371,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221574,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30119,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41125,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20008,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27227,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 540025,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35445,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3225,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158007,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243663,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28337,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141687,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154787,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161142,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76659,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56641,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96715,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109080,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 380038,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167098,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26017,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166134,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263293,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127541,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163398,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164329,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195515,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141898,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28042,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91853,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210153,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70173,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26451,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157308,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87545,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2500,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189177,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66085,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107691,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 154443,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21073,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85180,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147405,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54475,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263362,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33841,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85757,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 134372,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 129088,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174405,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 607144,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422851,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152492,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197894,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86917,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221335,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294202,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190180,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130036,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149952,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252502,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474270,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204756,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26206,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230743,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122947,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87760,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30446,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75339,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198514,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291604,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52177,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202252,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326386,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120273,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99957,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127674,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131478,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393126,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37572,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11187,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184512,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82506,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86639,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220748,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217684,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313630,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445233,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143445,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113311,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 261255,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353221,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161534,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425955,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333300,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348946,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 77785,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58941,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203605,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441285,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463408,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355629,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 327556,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 106407,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568740,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182102,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373788,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 392560,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65013,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503723,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420545,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618720,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 598938,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143939,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198643,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212709,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152548,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450281,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304997,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513339,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339367,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 316775,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574037,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63955,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214596,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51228,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22421,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134112,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74778,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74988,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178746,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40730,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108678,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12034,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4667,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21655,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56651,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13407,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64547,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42921,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112742,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15629,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142111,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166504,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31977,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162223,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32700,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4114,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132424,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27461,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144102,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129118,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35593,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114697,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10664,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30939,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118714,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26979,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69698,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299987,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94679,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68887,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82344,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9001,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18262,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93906,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19402,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77528,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124513,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97728,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4389,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44207,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35084,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125386,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72136,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74791,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35611,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138246,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150606,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305350,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87271,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19153,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22819,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113707,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97808,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184715,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121853,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161505,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11021,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51230,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52313,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86738,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48342,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45166,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 164029,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119963,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119165,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 420582,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8531,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24979,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18742,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34680,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42289,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51204,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79725,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10780,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147841,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76897,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25925,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120849,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27334,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 135246,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36308,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89308,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49390,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135008,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 125452,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16254,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45936,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117829,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128348,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25574,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176417,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160458,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 60834,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115686,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145689,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47503,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137253,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50496,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105027,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228725,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51792,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80368,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75025,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 329089,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16610,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15128,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103765,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141268,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16601,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28723,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200988,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66792,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111831,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179655,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15313,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134710,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4817,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149588,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130188,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196160,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371208,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40470,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15173,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181868,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534004,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85339,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42258,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131378,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100043,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23513,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36459,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56083,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9904,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71130,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132943,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224854,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72053,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33285,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136259,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 358925,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10668,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65694,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136760,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112262,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109361,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 302620,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222347,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253057,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250567,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15793,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48815,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173475,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180900,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200971,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129764,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79725,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81002,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491643,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119802,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93590,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41766,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137124,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88785,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 660852,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140430,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49209,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20649,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189101,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81548,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108339,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141986,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128778,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82851,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58526,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390177,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300100,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14116,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94268,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262550,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207121,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133593,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103314,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253046,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97579,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114748,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85223,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227899,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173411,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156380,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150016,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388175,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370257,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143530,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85368,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 361763,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149439,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37315,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98541,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14659,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58699,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8127,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84973,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181280,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133944,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231268,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18520,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 612572,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347287,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286312,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134527,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105900,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293092,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40653,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53341,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27794,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186295,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212470,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176767,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165425,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 366403,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345758,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 89735,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46927,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66700,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70238,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36348,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323378,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98512,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 433541,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379092,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38389,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35932,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229211,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66678,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395150,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 109287,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150146,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 47791,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 504197,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134089,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13513,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114958,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111773,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270079,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164510,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96058,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16554,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 575036,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268865,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196799,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 289658,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437515,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54155,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 353572,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633065,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349124,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64698,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133140,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 324178,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555263,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29223,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 383751,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574123,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 649610,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146974,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55737,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212719,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 500240,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404791,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 149821,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175467,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 18979,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 241851,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190829,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214081,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 122439,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7713,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130528,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96422,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75821,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61935,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157532,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85081,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226379,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12004,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53212,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 68160,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159509,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157751,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87091,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88547,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31987,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41822,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32245,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114028,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76129,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68677,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86353,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123069,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7644,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22315,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41568,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240928,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115559,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89155,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87462,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52071,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103977,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135475,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15732,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96337,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143948,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90271,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163060,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107840,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187270,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49889,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12871,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102482,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14370,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11713,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10150,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109396,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230690,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50696,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113040,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141730,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4692,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12620,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 485480,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78732,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57969,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14804,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87113,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47113,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122384,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20489,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99267,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31578,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29909,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27402,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11813,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18148,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78033,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41436,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260987,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133444,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148703,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14566,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3117,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14866,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18928,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70378,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109643,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125148,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72955,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151502,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145712,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73810,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137863,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 11631,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94720,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135674,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17049,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39900,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206484,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35529,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68335,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153440,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74309,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87486,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28307,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102284,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85323,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91683,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 219431,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140913,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189026,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188486,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277073,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61764,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91424,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143791,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85188,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 342037,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35580,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103871,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461923,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64716,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122458,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104314,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 447121,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124757,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32029,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20860,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268760,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121969,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260393,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54501,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153153,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118833,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169684,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88404,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250169,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121767,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62291,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118492,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148050,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108736,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84433,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120274,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 146635,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38237,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206893,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30364,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149847,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11857,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 429500,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109367,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134619,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203602,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88146,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253977,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121760,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166724,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108749,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62758,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59842,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145481,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 262469,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445188,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149856,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55881,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29204,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34504,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113295,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397632,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 178563,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40247,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240582,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210391,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149279,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22464,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190794,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272210,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184226,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22934,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19092,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204470,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 695258,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453854,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 580449,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53042,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65064,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154616,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157291,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22577,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15468,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80691,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103192,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141714,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45167,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17272,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46884,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249777,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171307,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159708,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107601,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81135,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 501736,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153303,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73378,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 392430,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240319,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41764,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162159,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189857,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168953,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 128256,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190670,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 527045,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288798,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185965,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146968,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247338,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248929,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39427,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431791,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10639,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158605,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17327,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162235,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 19294,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222980,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 291483,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37212,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261388,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269752,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 34197,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256928,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 320357,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 47652,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 331902,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100799,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435496,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359431,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512801,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 347277,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51314,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 225501,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110846,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 197763,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265739,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 363832,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95133,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259050,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21967,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56653,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95160,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90901,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279597,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268516,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174249,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 113474,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 121212,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15931,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145130,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 205014,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88717,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114472,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275412,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88643,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 440079,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124198,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29554,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8237,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104728,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6836,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23364,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25420,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86964,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24880,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115976,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22123,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 298349,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96364,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91784,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87733,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214903,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123865,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266539,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27598,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77569,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29753,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94781,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154409,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23669,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14058,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44896,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 229002,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56645,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144362,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101227,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43195,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55912,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76460,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51620,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37906,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35467,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92360,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86954,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11552,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129180,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129170,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39538,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9219,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75870,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113684,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 256813,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122909,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35817,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18475,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11889,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37567,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29519,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138215,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62012,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12487,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67315,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123340,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191871,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192200,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127407,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135850,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113897,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133753,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19965,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9304,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 283042,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114533,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13765,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131867,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107065,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20426,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44871,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246811,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56387,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188758,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148153,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207545,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63131,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68669,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218643,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78283,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18857,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71893,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19758,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108255,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281615,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153716,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114577,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253536,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117522,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100125,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 516161,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145077,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82504,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77931,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142472,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94356,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114186,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254217,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200747,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10982,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46115,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239862,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136351,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 407933,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204965,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319221,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425271,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 329176,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51477,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131173,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91856,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25276,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140875,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 543350,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112744,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253337,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40015,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260500,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50000,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74126,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150841,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152176,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20443,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118902,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99802,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121055,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112951,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98002,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108921,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82216,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35258,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27394,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125854,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84323,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63935,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 378833,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47484,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262061,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106063,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48028,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206453,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156111,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383947,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 387023,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98664,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237565,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231587,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183481,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416724,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 143722,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97508,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36954,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186356,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95917,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276111,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176452,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542725,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 184874,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105627,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153841,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116021,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105236,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219427,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70851,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244460,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41455,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20512,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 535945,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 592486,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105199,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 90048,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138712,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 612339,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 143841,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191011,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186896,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 132187,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 144788,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163422,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176280,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407681,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195953,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39794,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271530,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390405,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236085,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32544,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69371,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167160,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82750,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46684,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28568,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259052,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92966,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50039,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20726,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37959,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80634,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196024,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353697,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238668,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 417565,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297944,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166586,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169815,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365788,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43979,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254799,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334562,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581336,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318425,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255224,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 352157,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406478,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 29910,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124430,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22568,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45966,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275944,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 705806,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440566,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 355554,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 94722,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98954,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20644,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 390226,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9209,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68566,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42477,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152670,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143370,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13431,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46716,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84463,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99838,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9053,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78748,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166210,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87811,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 10984,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3657,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70314,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25495,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30246,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116455,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34542,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113004,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7649,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88275,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174257,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43229,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154807,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293013,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13890,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11974,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74764,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25033,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86637,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75223,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151626,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23242,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41970,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25045,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21228,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133478,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33857,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102265,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149490,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24014,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3571,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 207914,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96706,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110126,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222745,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96847,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30865,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34943,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76388,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43092,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183317,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100381,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108001,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76211,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14543,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25514,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162512,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7422,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125983,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155287,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101146,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142930,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70280,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44641,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136038,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105652,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 318618,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98729,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32004,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148838,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79656,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94114,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28673,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117255,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14472,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196176,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134067,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79402,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99830,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29606,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324591,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140542,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34451,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108252,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316900,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 211125,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179074,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11951,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301053,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46019,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48305,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78310,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128090,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152553,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120402,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301164,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201340,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84222,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20058,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 46527,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 526185,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44914,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 179602,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17252,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581317,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17849,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56288,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42470,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67893,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187322,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89675,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130945,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13029,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105913,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112507,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86441,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353340,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146771,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216074,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22673,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35962,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84886,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15583,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58042,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196127,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270174,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12455,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226479,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111435,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144135,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162831,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144693,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172509,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231128,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13109,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112209,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114738,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99392,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113944,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79815,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185035,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179466,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 653555,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40243,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51157,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36620,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22165,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134107,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191872,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136555,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149866,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13641,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116158,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45297,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26327,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211767,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157604,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138647,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132238,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46116,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320600,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34762,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437115,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 300470,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29875,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512461,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86838,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32187,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359886,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131704,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245034,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108669,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21930,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207920,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321468,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114407,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166860,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70464,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111445,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80188,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216932,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154207,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43623,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 167445,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17281,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175593,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559282,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525030,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212656,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99284,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17633,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326180,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 127231,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 125552,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125509,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 15287,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254896,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182230,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26728,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161016,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99994,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47171,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 169081,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41913,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 202031,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95251,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82023,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121383,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230636,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 635653,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65878,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57576,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150803,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224706,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260283,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 115135,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48042,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103931,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377498,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383494,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 361449,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22181,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16096,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52515,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247721,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 176564,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140651,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96198,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156945,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 423730,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 295651,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64319,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316811,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360378,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 332968,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52088,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275720,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479929,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100380,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 149302,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 511439,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437079,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173213,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397795,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86206,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455395,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184955,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 412358,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 248855,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37277,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 335651,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87362,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43588,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200183,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365512,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137174,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58943,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305845,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491253,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53296,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 332526,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552380,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 164121,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41275,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271431,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226541,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201213,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156118,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34252,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16018,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99332,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106380,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264552,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73111,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67544,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11433,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26577,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40952,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 2999,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122117,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96373,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91922,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83498,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69549,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29110,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29741,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78831,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96961,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99973,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94791,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149738,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51698,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25803,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28593,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241537,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71103,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146420,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26506,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117168,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28597,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210482,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194902,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14727,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162534,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135467,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56495,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49661,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18103,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116802,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231819,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520421,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142859,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103257,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 281746,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216869,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140751,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16097,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 169572,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 5702,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63843,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17959,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52674,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18787,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158975,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104410,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214862,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21622,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99446,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56187,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150242,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14116,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150307,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105993,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59439,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11125,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73226,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104660,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35342,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22901,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41452,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49418,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31169,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38982,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27708,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107659,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14218,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177651,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118921,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121903,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123197,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40189,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35497,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51268,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104904,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135885,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109854,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 323473,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72208,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 115931,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11696,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43205,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38557,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78365,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40924,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73100,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12790,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276714,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88939,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135844,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154789,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121066,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108175,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13680,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101588,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30034,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80000,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5107,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38098,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103526,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 346295,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54023,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10379,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32524,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79039,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98082,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177758,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45803,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44140,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91438,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229422,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54743,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42366,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223992,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125623,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337895,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69666,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104382,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30475,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73385,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8505,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112646,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88016,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33572,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108787,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88091,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108933,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51435,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37359,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26211,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243182,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176336,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21142,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24795,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10873,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201172,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24049,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187931,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133427,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 384992,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164616,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94348,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252444,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151641,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118497,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143415,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 213350,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91243,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150775,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90806,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293709,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413826,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11238,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148191,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83100,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102139,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7874,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67767,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121634,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47445,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208898,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144986,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59357,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184816,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249836,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37162,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43313,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157624,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247641,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123740,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125487,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16019,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173474,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86347,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69838,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388478,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206239,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94514,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152146,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 234553,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99671,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112238,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190421,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120821,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75282,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55400,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81783,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195141,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116352,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153999,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138287,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7859,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181800,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3511,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214913,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96952,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15503,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112452,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40457,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131258,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134392,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50106,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177495,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342649,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266052,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467114,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49178,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 221905,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43627,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169721,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305629,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28299,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9347,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21796,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86296,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272199,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186897,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102797,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261992,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 293575,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 662427,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92304,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124512,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91837,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194976,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314269,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26730,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209827,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30367,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37732,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49227,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212740,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83078,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203224,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70401,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255776,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437009,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58022,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38967,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 93167,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 337898,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66735,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 305658,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 75526,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95299,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 353795,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 468201,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28723,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 91714,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 186375,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97626,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 384524,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238595,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266044,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54888,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34607,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452458,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 365078,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441999,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84724,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 131204,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344285,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559485,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 52121,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99047,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121328,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154727,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212064,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32381,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79298,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120396,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17643,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74423,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241788,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52343,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135217,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 804308,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48105,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16299,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104280,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 234488,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164299,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161315,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68469,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7271,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85479,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140434,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100926,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89815,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150008,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136027,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148910,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76063,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134892,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298929,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29024,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 387521,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220796,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45915,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177549,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170588,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42941,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78241,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191245,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 194602,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75371,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 479894,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113204,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254659,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105863,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24857,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84645,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 66757,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41163,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99998,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14938,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34921,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45554,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13038,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16478,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58451,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64131,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177436,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72937,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102318,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 339706,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22307,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176140,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17459,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145583,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63977,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166770,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38475,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132254,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25406,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 386086,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20895,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122589,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48515,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118215,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14267,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284763,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36142,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86003,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85704,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160902,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163961,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78279,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105717,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210788,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136205,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76396,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68262,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142749,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84021,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27318,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144122,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117075,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 193334,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 6769,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217450,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 799068,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159061,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377972,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239139,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137258,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59572,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83827,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97738,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8582,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89646,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123669,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24935,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103942,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232307,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292442,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52314,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542401,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34119,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123254,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315447,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109467,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70636,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176973,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20662,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112751,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104725,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636334,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63145,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102066,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9670,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23561,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184382,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38888,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30944,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119240,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112966,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45122,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17859,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134459,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21195,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19563,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98222,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 198533,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265840,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90743,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181747,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11785,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42104,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81702,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 320898,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111154,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118434,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131636,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144852,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 257253,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93719,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96737,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82708,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27028,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56920,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29550,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 198102,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188626,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43405,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59894,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36068,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195869,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148685,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212549,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45825,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112579,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30307,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298649,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72867,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100321,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109910,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157939,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229112,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 163606,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29155,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90245,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118355,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93160,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 304360,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114858,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54173,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138704,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29166,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22447,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568940,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63780,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68031,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135934,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164544,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170273,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88196,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 561563,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131525,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95475,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70747,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 346391,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47343,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36669,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133791,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49225,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168710,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 597080,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92177,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438274,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35442,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 609948,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 194820,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155877,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228947,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99736,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142192,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491558,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123498,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191843,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12866,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208271,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302550,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 135325,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636581,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26406,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 181363,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159359,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316724,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239407,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102417,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288507,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375071,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 165468,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252584,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63454,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108769,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75440,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239903,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62204,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86311,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 339027,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74634,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274580,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83460,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 545444,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 204978,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189416,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188659,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132948,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 81314,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 289817,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458202,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44155,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187091,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153522,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176698,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44980,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83164,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98169,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61274,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52633,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 159621,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 544727,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85368,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135505,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72732,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317676,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154874,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148283,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336084,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 705344,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 356879,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243999,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 315767,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 202141,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 440951,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 162845,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 247357,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 684668,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 669881,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 369115,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55967,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 358078,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 304485,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205861,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102227,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86863,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52445,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141758,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160318,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11948,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145478,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65118,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42014,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32010,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 149360,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109105,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 555123,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85616,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 6503,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76843,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 180290,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16140,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 331810,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109364,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32670,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33769,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86814,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138663,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27959,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201877,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79228,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63797,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108166,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58169,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79846,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 690433,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170278,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118091,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10752,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108141,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108308,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65151,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128965,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197200,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 207998,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142573,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93570,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191774,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26123,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 219893,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36152,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85845,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68727,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97138,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10778,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 141321,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 205635,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63404,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12310,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145618,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40079,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76700,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44537,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133786,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114486,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47711,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21641,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24829,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23589,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23204,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10055,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101354,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171978,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93641,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21277,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85678,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81135,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105920,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11400,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96130,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49191,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135813,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123679,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44615,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93203,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 290417,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210711,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233754,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108278,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157229,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 34435,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58312,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83528,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140662,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 216439,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16113,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75523,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17006,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71900,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112356,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123017,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112339,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66032,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 393300,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43589,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152791,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213362,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169226,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93951,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39288,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148554,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316789,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39873,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17136,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54331,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138055,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38817,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255272,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37973,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133258,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105907,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82341,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 187546,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139628,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131008,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86816,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13867,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 308549,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 363958,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91091,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69120,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109320,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205481,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105388,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32364,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209253,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153422,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58732,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18091,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24408,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47008,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94253,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170842,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212418,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118480,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117919,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296101,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258526,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53652,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120003,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 43610,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292536,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11620,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87534,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207751,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185072,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 105616,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206615,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313261,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 187932,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 117095,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183440,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24393,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238168,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155534,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78334,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101354,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23497,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28730,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65456,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212689,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84494,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130322,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114027,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126819,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153697,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201467,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99108,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90996,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457526,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16383,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283056,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308929,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181192,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159063,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143185,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108873,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149610,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27042,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186841,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9336,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22521,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386845,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134634,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21618,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 265361,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126535,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110993,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266895,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53818,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80306,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200132,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199686,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 280329,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102896,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184489,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83807,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173793,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 140506,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278708,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37355,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230317,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53017,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 101494,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63107,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109968,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84860,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42592,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35391,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47079,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428182,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225874,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127920,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126387,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 160739,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31845,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396358,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117851,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87759,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131344,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559589,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316913,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51166,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 179347,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272840,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360379,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54779,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 418429,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83460,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489360,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88949,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59388,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 624561,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165805,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402563,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 185536,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222777,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222704,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141877,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108907,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 300072,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231618,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181290,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 196616,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 481564,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190828,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133454,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141517,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170825,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448862,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73916,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69704,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73722,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21923,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82376,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122904,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37992,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10569,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103669,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65581,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115895,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100338,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42976,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178254,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48017,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6301,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84814,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93756,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21361,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67433,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37166,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81862,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41839,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73593,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46556,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72790,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57747,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23123,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86066,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97468,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121561,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 248611,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86739,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67920,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69880,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12238,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93512,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22207,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12259,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104359,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372853,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127780,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 112794,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85699,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146855,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74970,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83939,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100372,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 590707,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171343,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69885,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218028,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105821,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25667,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93072,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137542,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117851,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307124,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96708,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124334,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96537,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180186,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90084,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100579,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89451,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195454,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227766,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138719,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27659,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13983,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27940,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192382,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 250550,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160921,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272767,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 106602,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47231,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53183,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183460,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44296,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 106118,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88968,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47559,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99078,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75749,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127472,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160417,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316996,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122446,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51730,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 592887,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 215711,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68992,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84126,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128992,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14312,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9649,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65510,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37143,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64289,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15193,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8344,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35861,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249085,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30128,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28305,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33969,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76454,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148302,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174588,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37290,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39623,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73519,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277440,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153102,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236830,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117102,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94159,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96730,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84035,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11662,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79540,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9949,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41983,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59900,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127328,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 64266,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79978,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38710,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90626,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199988,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31575,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217215,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171374,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52398,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96507,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152463,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267085,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34378,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3778,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16625,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139490,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114300,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70712,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291507,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17378,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146491,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9913,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168251,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 98331,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 341548,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95977,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20151,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278992,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57848,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100025,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165182,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128049,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159652,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32848,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7068,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453236,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19592,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39575,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165190,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25052,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237344,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45899,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 181112,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195848,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154457,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132174,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 372236,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161591,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45912,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46504,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136323,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175350,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126606,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211168,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135118,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82929,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144092,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313471,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145491,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170644,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 613427,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359329,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97834,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34717,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397832,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201580,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149344,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83030,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36425,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136744,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 392978,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 166115,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53920,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64631,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39146,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105146,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107494,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26047,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66354,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78260,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250172,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 129545,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80593,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263004,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 30867,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477304,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390207,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197762,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424805,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91554,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205618,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164324,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51716,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146333,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69775,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211110,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23947,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189360,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223977,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24731,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23887,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73931,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114053,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54301,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516402,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146573,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525985,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198359,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285233,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55870,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47592,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 117593,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 54972,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121048,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247147,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283378,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126083,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99717,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70159,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572467,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70772,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 256124,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371951,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 641140,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 234509,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 553914,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321712,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424626,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349855,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50094,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 351051,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 162296,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494372,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496331,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 148860,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18789,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58388,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66265,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170447,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235210,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288921,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71936,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35700,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85227,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92599,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80999,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16628,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41459,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94315,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106608,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60833,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30085,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103985,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97809,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70374,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25747,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28000,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31609,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119985,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74155,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28585,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131370,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68809,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137037,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 93011,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112188,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 284801,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68904,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53115,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124452,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63277,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96085,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119357,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25772,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169535,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14177,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107917,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123536,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120985,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 265255,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117196,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129758,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170099,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150166,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156698,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90845,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63016,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185686,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65709,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121756,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185527,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287597,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56589,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135181,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19304,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85923,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128042,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26168,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6067,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232254,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17040,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125830,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150026,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13596,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78525,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196343,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265184,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77022,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46897,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17003,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186479,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47930,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134065,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143165,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163253,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220114,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109151,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102785,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85455,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42184,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134818,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77474,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81013,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14196,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238888,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79742,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106371,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117124,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4791,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160462,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93160,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61709,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38013,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121196,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168821,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2147,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 351979,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159683,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38939,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125186,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119062,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103465,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161189,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91606,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164809,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24355,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119224,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 299233,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206312,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39766,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131538,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108251,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71839,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132637,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25998,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24303,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36137,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88047,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154126,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86978,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29926,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178349,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74759,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60951,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82545,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25067,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23412,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96303,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16858,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96573,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156254,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141974,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201998,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161784,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108942,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78999,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36954,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237848,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 131529,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70245,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 405787,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134665,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239953,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158178,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44873,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157526,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87339,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61975,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19431,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 405279,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89134,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67134,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58840,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29987,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74411,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159189,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229445,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116534,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64435,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84689,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143872,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208005,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110155,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35210,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79726,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292321,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92010,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143834,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93358,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146883,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137628,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76192,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134888,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143451,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119988,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80161,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52230,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273141,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61966,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81873,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 96571,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182785,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247271,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61840,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156361,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125898,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73845,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208432,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124114,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34774,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161918,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60492,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47583,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 129795,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 334219,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 121401,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171844,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84011,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83656,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23495,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88274,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179472,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542525,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140155,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17589,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134423,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205959,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314914,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 127137,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 117184,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84953,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241801,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133890,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40642,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110765,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 382643,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263144,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32745,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136647,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83386,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61737,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 537809,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94234,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212480,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324914,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 35853,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321929,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 291716,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 357796,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 122817,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121708,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86415,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431010,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59699,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374994,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239177,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219710,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67811,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208614,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44615,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48819,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279113,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294094,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 105080,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244440,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 75018,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 521036,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277349,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200136,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207835,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180822,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78514,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150351,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64112,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406942,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98369,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208737,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 344898,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76398,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36356,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228744,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 151869,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 273141,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 165304,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384874,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420361,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373381,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250266,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 357478,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360092,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 441845,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45447,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181750,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92620,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514099,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 368303,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 630457,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41185,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10603,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118309,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82287,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35916,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36927,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28654,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143659,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 209254,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100192,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75766,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7741,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80189,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74961,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50135,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 8174,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 375787,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 222122,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252076,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63663,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80771,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71164,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28937,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51951,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116262,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457468,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21888,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46785,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 132913,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 441210,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112673,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 99302,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246242,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137540,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98953,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87236,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36964,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103829,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127619,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78959,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92282,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63217,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133414,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22512,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125005,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49046,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20161,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185170,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21030,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73658,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39787,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154338,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14590,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75840,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507449,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47604,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50992,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14869,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168583,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90369,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34634,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6567,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59462,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80032,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 479663,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9100,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11845,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88592,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206296,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15547,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124356,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50110,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37572,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25958,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11230,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 251055,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33941,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17139,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120482,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24334,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132989,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42874,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44174,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49833,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61399,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70463,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25842,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16655,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135783,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120692,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99283,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29588,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102460,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103856,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104659,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67695,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175463,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 623516,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79224,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59509,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28380,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4813,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94998,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56547,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5793,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25763,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 311550,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 289856,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135369,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180856,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15557,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65214,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19681,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47248,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163715,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80059,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117864,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22324,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459476,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148844,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176457,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83762,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112142,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191754,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76000,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142859,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164486,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10419,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102888,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51353,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176733,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78810,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37600,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9256,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123351,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157181,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233778,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138901,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158702,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298180,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 47419,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194712,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221651,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 90723,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133873,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57778,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135554,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27719,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167444,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116757,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53319,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167759,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 463462,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182957,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103481,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489242,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154853,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374348,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 206493,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61824,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84628,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 353966,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 541642,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77423,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103892,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180736,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359714,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263128,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63478,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101506,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107161,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100129,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 328395,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114431,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70383,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102629,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23670,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38994,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96378,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359542,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30592,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94946,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23518,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38399,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21161,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102423,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101879,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266640,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155380,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125018,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35576,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73961,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179523,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57505,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56277,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243264,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176270,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23562,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 467767,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186623,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99758,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174509,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275263,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169010,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 127480,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16761,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44679,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24741,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232535,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248989,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11842,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159925,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438899,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146460,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25502,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 464920,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214206,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 139528,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 580532,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394462,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 376836,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 425290,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73236,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 246224,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35671,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 648192,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 564380,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40267,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185978,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41266,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153847,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 489673,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275366,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336517,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 306582,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373705,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 344214,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 476843,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121993,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 524916,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221709,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 41553,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133686,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 59150,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 171134,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 582695,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451327,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84629,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245964,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108941,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191253,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156713,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71572,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337479,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309286,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463171,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83336,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 327460,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50581,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189462,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78333,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138796,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10915,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 46036,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102039,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151297,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203809,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 84113,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27672,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9650,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134330,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30002,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71093,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53595,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316741,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65422,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94382,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25550,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49139,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43119,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34431,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13853,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88634,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92083,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 193612,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13428,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19183,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126455,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100955,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88096,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121761,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22685,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53245,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10709,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144274,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94035,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128562,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21841,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 98218,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29813,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87980,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90021,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161964,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434691,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162094,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93904,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78774,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148830,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30348,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114971,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138517,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112002,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50878,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39620,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163091,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76881,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175299,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32120,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127902,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272742,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235898,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68162,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 444581,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114588,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14228,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109463,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102870,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106630,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199051,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10242,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37669,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 462268,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140376,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47138,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20299,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130408,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11723,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120377,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66326,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32271,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13787,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37534,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25504,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97468,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248563,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112890,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38992,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31319,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 353058,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170913,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70736,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 231870,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19634,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38706,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79228,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55054,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138122,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116151,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38944,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118160,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 206321,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157035,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183168,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135197,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8687,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34853,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105068,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27929,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107545,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 329516,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91149,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136469,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106334,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118157,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32926,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219435,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23351,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23851,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117839,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233594,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270326,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91000,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108409,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15441,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315877,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 212646,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67482,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149498,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110858,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141715,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28835,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294801,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60150,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191857,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33151,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100610,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88486,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29218,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90174,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236269,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59561,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28332,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49922,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114895,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157857,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80851,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136918,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112574,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101076,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39418,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40560,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187148,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93481,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133246,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220727,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27789,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256611,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189316,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102190,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89207,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111969,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82203,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110838,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146935,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39955,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269936,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83049,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96374,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 151358,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 299835,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65544,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 170514,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101217,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 561001,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9782,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78036,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119216,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 280857,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110575,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203618,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215659,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227456,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 315048,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99127,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89542,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38716,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416925,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87979,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156657,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 263702,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222893,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63633,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 505438,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123419,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132165,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185994,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175610,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169743,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179565,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177780,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173482,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28580,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101298,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44968,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45415,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34057,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81075,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291048,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224793,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431869,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170082,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99231,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136975,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227636,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359053,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32238,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175369,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78512,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88511,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97576,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461641,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 522339,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40977,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30003,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 176681,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325335,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256608,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 109164,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252846,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294572,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 203729,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159888,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177056,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 165605,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62546,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 462142,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 137535,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 612415,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 294890,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 237255,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 503644,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 695803,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284626,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182034,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257634,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42486,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262422,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436354,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58411,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 39463,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156779,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8051,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69093,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84973,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37556,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26424,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30911,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7934,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69630,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86575,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11427,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258986,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105170,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148393,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26141,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93622,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53028,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60263,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157190,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91167,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32873,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87194,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56402,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66949,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34044,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92349,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 456880,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123414,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22324,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253173,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117328,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129253,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157887,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194858,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108856,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 158145,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60820,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95161,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29464,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109718,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9080,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106392,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86775,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75812,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109215,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12007,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70219,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96198,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82476,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25317,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195720,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220733,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39490,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22641,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 344161,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102334,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83146,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100651,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77340,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97322,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22207,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133487,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74378,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88234,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119360,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92014,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113843,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194787,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432073,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78778,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 378876,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 427333,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106055,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64932,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10676,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185428,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143025,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96021,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8689,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305014,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116303,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39465,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160038,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31793,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42700,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32654,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30718,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14478,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111250,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307598,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78523,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93300,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419832,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4620,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56592,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112692,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 289600,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174861,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194848,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13077,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32800,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94479,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102115,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94859,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 426640,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116753,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18895,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77791,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18951,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231014,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228649,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233877,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268367,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20961,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228099,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69165,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27866,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98755,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7166,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33444,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 175125,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71405,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3737,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8784,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65730,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4915,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234225,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264476,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86104,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 143936,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80021,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115508,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136156,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63288,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54598,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41337,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144058,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53712,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175338,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81816,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39567,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144903,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12810,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72040,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 57823,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119298,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80060,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96348,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 297819,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267902,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87891,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74109,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56709,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146526,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167172,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 494587,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201738,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 299375,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176533,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127046,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86891,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117754,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193312,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76675,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164130,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103295,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134900,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240593,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134069,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99521,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126497,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220499,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21205,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15371,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100808,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179894,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133766,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350640,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 362296,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 306750,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141652,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303837,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72442,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88697,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98224,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141333,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17227,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102126,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170887,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87763,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102951,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60738,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171575,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183908,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175922,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128460,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106938,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377310,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45651,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84348,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242198,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130830,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 462095,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205108,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407058,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4243,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140013,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 339357,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 130563,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86377,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167728,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271538,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491353,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185051,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186358,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233049,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110740,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24098,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56156,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312502,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 131245,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25141,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315460,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62180,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62615,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67136,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 584004,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9703,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79128,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134708,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49533,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29244,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 585110,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 516513,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244754,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 660956,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 133501,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179655,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 314764,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 186793,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30994,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338882,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15059,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85933,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225977,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99689,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168253,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 563595,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76065,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 257398,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467607,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43231,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448871,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 314572,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173069,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 104633,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269574,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65799,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 348088,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 389197,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 460117,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300072,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 52981,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 510889,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 550269,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 198862,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 559659,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319739,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422868,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 436819,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166617,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18798,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58539,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140505,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105721,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 10965,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149796,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99619,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58047,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33115,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8194,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4240,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7872,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29629,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234896,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97370,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97413,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116662,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8772,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23891,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14523,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37158,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31868,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126250,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83763,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15686,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98252,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154818,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3611,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96614,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104675,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49867,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264337,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108288,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28495,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83783,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34843,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55765,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153545,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128904,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84257,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51061,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67309,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56316,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110605,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143110,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77254,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11056,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80574,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174374,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132676,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188900,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19436,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43344,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97861,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16499,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21206,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13932,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 33122,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15675,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81806,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17492,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83822,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80072,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112005,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113089,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57569,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231344,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110187,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101103,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493014,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50074,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17851,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173130,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131045,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100661,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104509,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426414,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84914,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60886,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195896,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134769,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118595,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247463,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32257,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268718,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129768,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106527,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70010,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63787,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41414,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85144,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132504,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42800,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176890,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11163,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111982,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126015,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181722,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99096,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130842,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83192,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240200,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49895,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14770,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395506,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79061,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89121,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72349,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115345,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37507,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 568450,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124637,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107909,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113492,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9399,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190758,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35065,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35891,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136678,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100151,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101402,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90232,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79990,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123602,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89858,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87617,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10050,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61249,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148585,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 173748,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96564,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137047,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107011,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73777,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80047,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233450,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89606,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45621,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89004,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100809,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 131696,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 437272,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125224,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110798,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158558,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100001,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440067,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338436,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112872,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33700,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216404,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 345861,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19884,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244590,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127054,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 214799,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174378,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236189,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120097,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69761,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47357,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95282,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75580,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103920,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123524,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163122,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124182,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138303,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 172236,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 372961,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55682,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154742,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133754,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88041,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393356,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7353,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132610,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57348,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248704,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178242,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47212,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22585,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 392597,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74272,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208098,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 273246,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277224,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116205,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147945,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 186919,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94918,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61941,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133279,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21850,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168171,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 432401,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34805,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62827,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15541,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165181,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130878,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 374858,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70868,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84317,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 617351,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15078,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13779,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431030,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31598,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311427,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 184844,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272778,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21842,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 373974,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171546,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 97700,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449810,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6846,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160670,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28707,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151283,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145421,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19785,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 210334,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19665,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107167,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376093,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 67380,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436551,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640311,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227094,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88874,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184273,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 96872,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334105,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 16804,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 506703,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46701,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379283,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190412,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419102,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409061,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83481,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155368,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 667633,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15676,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242673,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 267170,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344827,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119872,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581807,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 143600,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367018,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61453,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618481,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382311,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313071,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 94710,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128877,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53772,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108885,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91258,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12185,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84072,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21280,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39235,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126645,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114832,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144721,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6094,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10350,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 69592,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97914,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116906,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103746,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130947,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10621,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5172,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82991,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18415,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16791,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36838,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30567,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159979,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24624,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124145,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55075,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254791,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11001,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134383,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11560,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158690,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70307,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19930,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42807,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 201261,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176585,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100783,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97416,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49297,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146720,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38044,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142856,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97063,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37829,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118883,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 124440,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137708,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140669,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2008,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61484,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11567,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68903,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20404,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125776,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63368,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 563278,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68733,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95779,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 69363,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113157,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51008,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24897,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20688,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153995,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73204,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135601,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23278,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26299,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199686,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192146,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5711,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160365,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91417,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161361,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64294,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36280,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34527,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159143,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203213,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90917,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42783,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237037,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227351,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 109973,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96425,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7467,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147344,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40864,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514008,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102850,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77507,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184203,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109841,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 476635,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62353,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102377,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24361,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41125,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274450,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11257,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57151,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225742,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 331584,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106985,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16062,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72787,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 251896,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130978,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195548,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235603,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132603,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112950,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138426,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10118,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126570,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78342,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161627,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49384,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66309,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104242,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83929,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131057,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24164,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49238,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 351741,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180787,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101714,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 406134,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148777,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152657,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141894,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38064,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48339,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177755,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29617,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130605,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214815,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23904,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 153205,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99109,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139263,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111643,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123957,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 328967,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338544,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 342457,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50662,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9870,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63682,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286882,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89855,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274552,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13278,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227943,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 585098,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196388,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110991,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89321,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 219914,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75102,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20925,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307943,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46862,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12624,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44871,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46171,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41171,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123894,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58645,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114619,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195012,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110153,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92373,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130314,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71201,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112800,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142977,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41984,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262379,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126652,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193200,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176144,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191201,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109900,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41282,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 107636,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120279,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30160,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 150471,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22088,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247694,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94767,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82000,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190345,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63882,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523100,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223765,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81536,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162871,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 263485,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51797,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28088,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27739,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102913,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66038,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 212404,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296358,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163980,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128688,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42446,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 387100,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 260598,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223593,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 73478,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61924,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362439,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 280991,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20147,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241338,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68855,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148395,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153023,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 153826,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308372,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193325,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64689,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30727,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 32147,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83645,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269601,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28252,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211765,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 80856,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 166274,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57644,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574976,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146942,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284381,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251775,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 116318,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224917,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178730,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 346439,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102721,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 51785,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59508,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491991,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74398,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558413,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 273062,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175505,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 497836,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 359371,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28673,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10964,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118673,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157877,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84854,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82937,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39914,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96370,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61549,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35194,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190498,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61808,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68715,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61646,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45205,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60632,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6272,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30418,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91881,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42957,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9232,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131154,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167401,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34460,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200299,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24263,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93597,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 322640,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144055,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66797,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266857,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175654,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45194,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76041,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37227,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9094,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118697,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17699,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74540,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96129,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103984,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78413,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24977,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133495,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 204935,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44220,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26349,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7958,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85075,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112627,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136033,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7958,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 470965,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39617,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21327,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9952,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16135,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88534,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18987,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108422,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58011,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13835,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156908,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36719,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 332749,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106124,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83116,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17437,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58165,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8136,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63374,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15075,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113433,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145970,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109041,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147309,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64625,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23836,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90362,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232032,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247605,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129465,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 656692,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37991,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40751,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96107,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132336,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11763,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 129109,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120109,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107841,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 234006,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74929,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176223,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 517429,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164326,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 190363,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 287290,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22653,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59994,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153408,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23900,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32212,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26143,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204283,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181282,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53301,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149241,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166823,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45583,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153329,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90874,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9652,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 110313,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103353,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116926,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81705,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43366,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61908,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77313,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121448,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 258455,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93248,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94942,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409964,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137021,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114561,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137997,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51539,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 49638,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36028,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6969,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63538,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148699,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144423,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130302,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 314570,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299399,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189370,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196421,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202521,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149553,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137674,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114828,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88610,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189596,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82015,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215957,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224594,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148636,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141915,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78157,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104190,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28455,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8348,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114224,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66120,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195786,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59993,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38706,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158052,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152885,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190227,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94762,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92640,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199008,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162889,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654642,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174328,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177674,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82919,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111434,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272864,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171567,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71654,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178435,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82067,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 409615,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 200887,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138084,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159495,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21867,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273832,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194791,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201366,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132541,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 321369,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 228450,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63403,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308043,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 288509,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278787,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276937,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49001,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 89407,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30952,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79908,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138496,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77728,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292744,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102421,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7373,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243615,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52826,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 302438,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196619,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57529,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 358603,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 241320,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48149,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 149638,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95891,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 504391,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404878,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 102678,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129825,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74046,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177673,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192090,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 374851,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29476,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252053,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145474,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125910,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194308,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168529,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166993,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184741,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 31641,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42075,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317238,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 422399,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59966,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52990,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 333650,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102421,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 139857,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 532917,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74832,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44645,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103251,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 439446,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109023,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161899,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264184,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 712350,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176191,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 362304,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36832,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379019,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402428,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393856,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 475290,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 121533,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40130,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 307070,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 103472,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 442130,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255775,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82788,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79366,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 33647,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43977,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175509,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87036,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57706,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83821,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 321468,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 730957,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345075,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 640998,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 336718,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244593,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 685161,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 125017,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33431,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97761,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119312,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93439,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9373,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41900,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2907,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33969,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113174,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88038,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113434,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15242,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17663,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245700,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91777,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138817,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30831,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3497,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39120,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15253,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75728,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59468,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106139,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75057,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33057,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 135791,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10271,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13577,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25069,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21655,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11648,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60854,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32741,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76942,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28894,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69097,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19431,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95679,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27792,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19284,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9836,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110851,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134777,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41425,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53473,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293005,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78563,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149098,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259613,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112568,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44852,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116677,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122786,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103323,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61590,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139576,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140038,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10462,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136677,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8188,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31768,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310681,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91154,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34191,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80005,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166465,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21406,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7292,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 473646,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26289,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40548,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27507,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104666,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20750,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39052,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29743,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14933,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133050,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81781,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144523,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13622,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9703,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128831,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80654,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123751,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136167,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27637,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130760,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84417,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73736,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 289601,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76141,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24464,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130338,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 638469,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137295,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155298,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24349,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120783,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33397,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152157,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107320,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187593,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19533,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132356,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20597,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90629,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140217,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55396,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203265,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103069,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10410,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7317,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24030,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17557,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65047,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119725,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20475,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107898,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20615,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 56507,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 618845,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29114,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105747,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69223,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128830,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29324,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46966,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253429,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 377392,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64129,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86464,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 60305,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123600,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 345379,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25165,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25056,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47297,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 438007,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55288,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29126,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50387,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31997,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28579,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120887,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 502737,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44183,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384158,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82774,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65897,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86856,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146877,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42497,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26374,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86552,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15238,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103581,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127612,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165348,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92258,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136014,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86588,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25508,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 154878,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147865,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91412,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129080,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114097,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9265,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79340,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191286,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28534,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252799,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101867,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102052,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 380097,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 434180,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101419,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232736,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189454,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134894,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 66047,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65555,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159366,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125024,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168268,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 596871,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 270705,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297249,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87911,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123975,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91360,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12868,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 356107,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94483,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55447,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142271,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129573,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30733,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 381838,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 562205,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48724,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70621,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191690,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79942,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338570,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349707,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50171,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24964,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 339089,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124909,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229818,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174280,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32366,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124065,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225031,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 53636,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117212,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138989,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269999,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101996,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 317884,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199976,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 403009,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98407,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 284503,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 579925,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147724,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 569726,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208517,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 58310,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 427500,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78531,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145005,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29560,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111009,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48041,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254308,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 600868,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572141,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83282,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 55547,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 143956,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132967,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 345156,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205893,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181359,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455255,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168892,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 278358,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 527106,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154515,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156643,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38578,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 243295,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418288,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213079,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14375,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 607316,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424077,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272981,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237483,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133253,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219883,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96824,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140549,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70187,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 388674,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 641634,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256169,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238526,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48182,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 695643,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313109,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402785,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488179,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154761,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259630,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 592903,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 118660,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174680,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150277,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347498,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26800,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180646,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160487,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61057,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 580094,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 121353,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 445833,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 145774,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 166550,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 310448,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31029,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46750,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 451817,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 633686,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448422,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 141099,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137091,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 571637,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94881,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27210,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75667,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29467,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110654,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72484,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101305,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52932,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145447,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13427,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76405,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111972,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95540,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83485,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203527,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74872,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31948,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79049,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145589,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39382,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121576,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20167,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126373,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11843,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53862,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6943,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93801,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162540,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24481,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 191070,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73281,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71488,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158682,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70953,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176118,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96135,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88712,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119534,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99199,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199082,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109244,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47281,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 264530,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160353,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115942,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10212,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135937,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 358990,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33382,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182711,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103848,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146125,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23532,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 174438,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100163,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78271,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29411,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110321,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276744,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93202,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149168,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274215,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159616,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112472,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31757,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149949,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73479,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49395,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45996,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101757,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50825,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 145327,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65214,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225283,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119216,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 26185,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42584,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12220,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93890,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46850,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175254,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169113,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 244103,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103694,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126534,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70191,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140600,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54761,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 199095,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235522,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141727,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116813,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 38822,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29235,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9465,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112074,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44245,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199143,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99994,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14812,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13483,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97618,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107126,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31724,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208726,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102345,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97316,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118336,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94361,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138777,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163311,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62047,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159957,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 506396,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6693,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168443,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125817,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197179,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78388,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68988,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109799,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28843,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55141,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15467,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29802,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128601,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157221,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 394730,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116728,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153659,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 634410,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133988,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19612,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135992,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102962,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12685,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101864,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 505176,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46974,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84397,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40885,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100461,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111774,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91812,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 539907,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 64457,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 428282,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 167790,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167154,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90812,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40025,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330543,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93570,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138605,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27548,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15824,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141829,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258329,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162434,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20273,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95721,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319350,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76854,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 660794,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101486,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116879,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89093,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77892,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156844,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 439308,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125461,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183030,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 195258,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261272,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103439,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103968,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55337,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52674,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 223082,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145882,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56421,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141700,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31059,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155449,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194332,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194300,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221122,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 386504,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31390,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85898,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136153,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261743,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407266,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 63751,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74067,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111745,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141333,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235204,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134585,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240442,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156660,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60527,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197614,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 387334,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 459966,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 521136,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94447,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110359,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 261053,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218986,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193329,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103240,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109867,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103687,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 351826,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 91073,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246908,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 170584,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222179,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150507,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86769,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279011,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 346414,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 65563,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159391,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66819,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 554300,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 295212,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376083,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542738,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 158785,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50554,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 23241,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269761,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 390246,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 426989,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85930,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76336,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86684,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16422,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95894,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167835,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109067,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147719,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43223,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147139,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12490,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34711,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130615,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44050,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74934,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 349418,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128167,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185044,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23758,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232288,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142957,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 192138,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81354,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84571,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23269,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 143678,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13486,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37769,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31431,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95492,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142900,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30832,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120479,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16429,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69385,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144205,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32467,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111394,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11460,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269789,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124542,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25275,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33655,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203520,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47750,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111489,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77666,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27041,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83237,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18670,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273724,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236149,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62737,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211648,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120042,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62180,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 128315,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9835,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73030,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7876,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149340,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18291,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104062,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301981,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 53875,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35574,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13862,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69827,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83086,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124045,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82225,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191842,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294136,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203880,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 369674,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14861,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111071,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89473,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80109,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43980,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89259,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20165,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81646,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80578,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133787,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139652,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208597,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104512,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54170,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14787,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327002,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118844,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129845,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224209,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77046,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200145,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94037,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76881,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113418,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74217,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85402,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 116472,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79001,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165611,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7320,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60312,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96608,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148974,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191395,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38839,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175272,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103025,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9129,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89908,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 160988,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 274207,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137254,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15602,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122613,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43348,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81794,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107309,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121525,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10626,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38685,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125853,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94209,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75701,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14768,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28202,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71523,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27336,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59618,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270644,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125009,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37311,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49153,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96790,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 289468,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323330,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26151,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136266,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93576,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104264,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107702,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81239,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85719,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14190,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20597,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17507,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102994,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222918,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112352,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20231,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180154,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88259,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122436,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122909,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100003,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287400,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142055,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116292,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121077,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270486,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12831,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14631,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104269,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 535783,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261298,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186010,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69808,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190870,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146538,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122537,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223363,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195780,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287800,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 136962,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16104,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90820,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46588,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130386,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45545,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354198,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146229,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193870,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452096,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 196913,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229841,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88849,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11211,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129689,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 287116,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62060,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 309465,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 222107,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14463,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109844,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68887,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49908,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164094,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30830,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122087,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161842,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52556,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 28639,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112855,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282315,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122891,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308130,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34094,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107926,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121234,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 546113,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 276716,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61401,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165719,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 105689,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 397753,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 594722,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207289,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 123929,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155363,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84571,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186884,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163323,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218799,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 328428,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 454024,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282981,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 422043,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 153851,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127620,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122428,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215595,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 161644,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58709,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 296648,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188907,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44758,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 308605,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 69970,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163644,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317264,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 350075,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190460,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80738,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 57760,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247304,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216296,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282691,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22715,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220549,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245566,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201204,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 140444,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14924,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32011,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39328,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109404,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 154358,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254151,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11525,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49010,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37601,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133371,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117827,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236483,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139498,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123620,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126686,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26109,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19665,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28429,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71711,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168306,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105915,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79991,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31019,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18039,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120847,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21168,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 15330,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298731,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111046,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120125,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45020,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12315,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94763,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71955,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20560,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133767,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57855,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73996,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11583,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10340,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39578,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96665,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87877,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84848,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26575,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165815,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 166860,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24128,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115568,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41011,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132644,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91273,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12645,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17401,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69670,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79415,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83267,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137681,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22314,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81415,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103587,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9628,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95119,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161806,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40689,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77608,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35322,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51106,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128585,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59778,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53111,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20590,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81739,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271298,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228356,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84317,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67132,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7776,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42968,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121348,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207210,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74302,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209515,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99465,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170632,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173098,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142953,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24130,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107419,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24031,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36545,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22116,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119174,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95059,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140783,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117011,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164213,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19944,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12427,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120181,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106686,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141634,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25340,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 52694,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512698,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185349,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230532,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25592,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220858,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146228,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118848,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 172846,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102948,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123254,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30624,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72554,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 383926,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30707,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52203,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130531,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296411,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 151711,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84519,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79018,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95591,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271197,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157282,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152961,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63127,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109589,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87166,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152439,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44062,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8128,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106194,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106686,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 104178,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57993,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35660,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170813,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 407962,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 370090,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71902,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13324,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153767,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270541,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142201,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118037,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137221,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84504,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181949,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38156,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146187,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48240,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129001,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55914,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72822,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79049,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 135024,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81632,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43025,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4470,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68932,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111250,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9952,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180589,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38970,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307365,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122867,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 129693,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250030,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213905,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 171928,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35519,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18916,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137122,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 156354,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251958,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 145714,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 235430,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61325,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80507,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280919,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 354609,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 569874,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 450184,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48744,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424118,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184615,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84406,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 393939,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187314,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136235,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128382,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 233019,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 69812,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178230,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395190,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253589,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121054,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66341,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152481,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22026,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 211996,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 112807,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 33791,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 252252,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 403335,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 339996,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63460,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341624,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 158827,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50420,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 170570,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375846,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17657,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 339056,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53029,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277933,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507767,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14643,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 440652,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203909,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 444264,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232097,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272555,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49760,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 130830,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371029,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 232789,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226262,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 141097,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225224,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75669,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87188,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13121,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82007,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36821,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147559,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8898,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108709,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272046,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 641651,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98751,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83281,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18136,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63326,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236218,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80259,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84776,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 144206,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277312,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160487,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149173,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74085,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77983,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185308,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200271,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110091,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 190917,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82252,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156858,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81693,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128806,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 460445,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46050,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18249,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22384,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37212,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9127,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159904,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114786,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57063,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23169,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149350,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158190,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40744,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31067,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112707,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123104,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88738,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13901,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95460,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53246,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178375,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261662,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277183,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177246,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69210,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97724,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119322,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10545,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148205,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85949,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123555,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53969,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30503,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88710,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 232246,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40121,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142965,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8864,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42697,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62318,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120775,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 416232,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28413,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 39162,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265816,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261715,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131508,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151918,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43863,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60962,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57529,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17837,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 112615,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77111,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 352767,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152408,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37073,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119455,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109848,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135604,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85032,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255176,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125936,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 127088,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11162,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106999,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 338326,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34479,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162769,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53684,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 41295,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173489,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29520,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 244977,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222379,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191363,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82344,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87200,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16182,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66786,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65137,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83745,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87994,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 54605,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 10662,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69766,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31038,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32537,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14517,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 179873,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39825,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243228,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89718,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96874,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80774,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15782,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117121,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108222,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186799,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136829,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109224,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114023,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157846,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15556,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 55199,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86952,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83499,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108686,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44792,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77356,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50586,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9623,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153860,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111119,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90312,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158463,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283527,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 153827,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182807,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68886,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50791,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135911,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133978,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130964,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137655,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 189513,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137080,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509594,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189410,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376163,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209572,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143458,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82878,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194336,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14883,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156509,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 360429,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28314,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 152633,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167935,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40205,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106826,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224229,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16788,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96898,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 349774,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 502723,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 329005,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104914,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47635,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105769,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201306,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24331,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143066,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36513,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163722,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152192,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24984,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163071,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51816,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437749,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38800,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 106072,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32292,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258795,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53225,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139980,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67079,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151870,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52617,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19571,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38778,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 234781,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295219,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82893,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109924,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71838,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66993,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57687,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203501,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 113187,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 532177,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302289,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87204,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80950,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 388802,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158135,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 102838,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65440,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 456679,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45226,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 250093,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463468,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128260,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 553929,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307220,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 303405,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 184213,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 529333,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 92342,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109680,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 387848,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364114,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178333,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 379799,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319886,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37684,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188396,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 635799,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276824,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127319,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129711,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 360488,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 106463,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91245,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 485008,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440007,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 577288,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245781,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 170131,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 629839,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65724,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341813,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 126415,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 136580,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74897,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55005,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7260,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82777,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138097,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65538,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104479,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 288237,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46636,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35841,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76540,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30189,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76474,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15842,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96293,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143933,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21852,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110197,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 159869,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57533,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4759,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93121,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76816,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 27297,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253943,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131959,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10931,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204946,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101662,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12045,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6458,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62880,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46246,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 148343,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167242,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135446,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33573,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122079,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26490,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109740,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21350,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102065,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141714,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 113901,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226272,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149897,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57145,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78497,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3426,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513293,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86527,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71307,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67207,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128510,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31373,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142249,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141009,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26688,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38796,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99848,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155012,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22575,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76528,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110191,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103303,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 363610,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105279,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137279,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115835,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132019,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83558,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34823,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32931,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131128,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30545,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101981,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33837,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194558,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24933,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130086,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13811,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94873,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17792,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 318683,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154290,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14034,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10239,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30305,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 218668,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254338,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111198,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164768,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107456,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126011,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10835,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50587,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270211,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7134,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122440,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122879,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149025,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76994,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11307,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158889,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581978,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6925,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123265,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93781,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8589,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66479,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11703,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59440,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54032,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16091,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102061,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49801,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104642,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40196,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97773,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59534,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61299,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83998,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5188,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57137,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48144,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445728,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30884,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109455,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348880,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 187613,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95552,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61776,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186588,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76528,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86725,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52585,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51871,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93446,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60410,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103517,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41636,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40992,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128870,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 161876,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38884,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181470,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 119958,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47324,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224693,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17049,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126791,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56027,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 444211,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25793,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29288,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219699,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8437,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12260,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 147807,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146689,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41616,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186118,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99351,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104240,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416372,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189044,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88690,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125534,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76205,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48032,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 591346,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139563,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209043,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140003,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73867,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117662,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97286,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 38124,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141281,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91648,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154925,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33095,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45114,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23093,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100562,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48664,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92358,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 281076,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87388,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35104,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142940,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9510,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140173,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117813,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 310578,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74586,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71139,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98521,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17067,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102468,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176308,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42649,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60038,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274408,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232942,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16342,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80380,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440737,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108739,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 194224,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225493,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 330686,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 76728,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468097,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292541,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 628721,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70839,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65384,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190035,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148244,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23105,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 79299,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277530,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143277,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214279,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48100,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81176,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101849,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185935,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103054,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80449,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87275,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37700,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190030,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182613,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105853,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 168604,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133350,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180051,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44920,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20849,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84067,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69043,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 277720,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449360,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 260597,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 324195,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167711,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145421,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 551406,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 450701,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 680402,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98971,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235630,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556453,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123778,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 316326,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32276,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 321084,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420417,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239321,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 409259,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389111,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 202824,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243612,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 173591,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467548,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 629331,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449438,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 160378,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131812,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 490965,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197140,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 341946,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389531,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461022,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12738,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27869,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515335,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240163,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 453324,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41348,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 639759,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274824,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319728,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 492063,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 628911,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46989,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115269,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76094,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20763,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5972,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4900,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15123,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 101605,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115337,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50777,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128064,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36851,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35382,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15771,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16739,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 109779,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82671,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138636,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29634,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31216,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94403,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9956,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34498,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77733,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55875,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40243,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201944,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145472,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24951,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215638,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139612,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77116,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91309,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70237,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101756,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34499,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 225277,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72384,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20962,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352356,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22554,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101261,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12021,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29429,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76324,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110588,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25925,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12258,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168579,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93217,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74094,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78551,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3985,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105965,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 12381,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 103004,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73389,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139790,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40936,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49884,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84753,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19244,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 551317,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49189,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65513,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103242,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35392,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197536,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125255,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 63322,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82714,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 85411,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11706,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327809,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99120,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158306,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96726,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146764,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43898,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228861,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32996,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94692,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77132,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 497193,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197695,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100385,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170130,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27348,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129959,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19865,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41249,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83357,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27568,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234489,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8770,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265420,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98057,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184413,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60607,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109396,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131337,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69643,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108513,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124616,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44619,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25619,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93911,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132078,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257160,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122089,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28712,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229158,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81490,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9976,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116170,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 350361,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65600,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98647,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83393,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 73288,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113470,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100458,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21217,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25556,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10793,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38834,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226181,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30820,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130852,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166151,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96224,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267807,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77722,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37282,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128171,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165604,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111732,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105643,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11592,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120490,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142855,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 461404,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 109111,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95501,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437466,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 460517,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157534,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20752,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152534,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 135428,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219713,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129415,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106058,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 161634,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 363683,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41409,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133862,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7178,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91185,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115918,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165406,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155131,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101568,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 173557,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38953,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184840,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 136158,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 297104,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 404680,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78343,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418942,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200996,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154689,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77892,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61739,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16622,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101231,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83693,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22065,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185916,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 336304,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79075,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264162,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160634,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38087,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40189,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51234,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 157255,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186530,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128049,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24884,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93505,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24602,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123492,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11564,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203278,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117177,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 203186,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59823,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119377,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 6905,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34741,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81521,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 99364,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92422,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48766,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141806,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 117992,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88201,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142845,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152727,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258293,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47834,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215018,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 288725,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415333,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319595,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 346322,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520014,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242908,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 417676,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185923,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60662,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229795,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60670,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 319934,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209358,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119453,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149832,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 151437,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 156292,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104900,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59392,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196312,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157430,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 341795,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58740,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206828,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84207,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 708158,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 114257,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40692,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131709,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118904,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 521918,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352942,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 487473,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 251712,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142626,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 223564,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49630,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65178,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269447,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184955,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106023,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 461342,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536793,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416277,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169868,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 165994,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116244,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 68935,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 394013,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264428,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 143207,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269288,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 135337,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 259122,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 320693,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186594,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 352569,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 383312,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350193,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 162830,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252740,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 37419,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 185338,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156485,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45770,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 176575,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 153237,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108409,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199414,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79063,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266574,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22404,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172881,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 78239,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8104,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 71979,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 156342,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6257,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12864,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12768,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21971,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95355,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46209,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122530,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35554,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122563,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114209,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152077,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8232,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108168,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108610,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140598,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92960,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 61535,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11805,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 119979,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70164,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31807,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88081,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45416,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 123114,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42054,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 95517,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70706,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23474,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414895,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33042,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21403,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118885,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87282,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231348,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100804,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 37354,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27084,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39984,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 106822,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46351,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10090,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201609,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63976,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163758,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163813,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 188251,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56480,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29396,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76535,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11811,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31216,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73557,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127886,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24439,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109219,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18473,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92926,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159062,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80058,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87298,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129702,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27153,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65649,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173572,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97105,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9710,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131368,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40078,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89450,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91624,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81644,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70460,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33025,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 281548,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100171,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 341676,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65324,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 226530,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278306,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16005,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11787,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112210,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180213,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 48665,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167090,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29541,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 71498,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191839,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 71462,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103630,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137238,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17686,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11525,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124355,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 79658,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 75996,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40345,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220375,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29508,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 655984,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89696,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 83019,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 179511,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100079,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549077,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 141123,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18593,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 320615,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8356,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85132,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36580,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169926,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48765,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56765,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93981,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81535,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143817,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 144047,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82551,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82042,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107627,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 275131,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 129058,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96489,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163514,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118634,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80036,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17205,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46310,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58101,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60408,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39393,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 203063,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91634,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 155673,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27087,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108194,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183450,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13928,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113111,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156333,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 372141,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102301,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 212973,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 210281,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78782,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 163896,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 151415,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269347,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134757,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299722,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111353,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64924,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 55759,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 385766,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69152,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97997,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 171168,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 469867,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113405,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130107,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16593,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186488,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129911,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216992,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192782,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68502,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 422389,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 37759,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249221,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258187,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48782,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118659,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88896,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285926,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65637,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117323,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258882,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 36484,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106064,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 114914,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 297264,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193625,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100100,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 297855,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144985,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 392645,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122171,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220566,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66089,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92710,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24300,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83066,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23596,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300886,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 139451,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88695,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 285593,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 420337,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29647,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11966,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247740,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 344769,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89700,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44504,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129339,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126358,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 653999,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64930,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69976,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151566,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 177896,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109889,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195372,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255214,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264145,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69683,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110099,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42818,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62232,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31717,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54911,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 50884,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 81203,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 333715,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 213813,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218459,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187129,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40220,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 171431,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 299739,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216697,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36764,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84285,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 371328,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214203,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132403,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221560,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300787,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 392166,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 565973,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463118,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 635106,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103347,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 336319,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52188,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42847,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195535,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413218,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 403166,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59999,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53295,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81306,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21435,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 83915,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229781,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382945,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 98226,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 235384,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253198,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 175371,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 205073,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 109936,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 173111,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101809,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440547,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 589651,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75839,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244166,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 288508,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77578,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11852,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27194,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117627,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33360,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11761,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131196,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128948,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107087,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 175188,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12803,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69983,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38094,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137847,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153771,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144013,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107276,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171926,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34067,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10064,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97918,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13326,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59912,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115926,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129290,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 161467,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70908,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55895,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16119,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2404,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75728,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43265,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117602,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11244,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153670,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77208,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138655,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6129,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174575,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131931,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129679,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132905,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23913,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25499,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159413,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114109,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121635,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134239,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74386,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19418,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145736,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237986,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100987,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 245131,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296641,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92077,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92920,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59348,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22127,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21833,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8989,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40273,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22848,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104995,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39778,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21207,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168244,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89931,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8770,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106125,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4132,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205683,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83924,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6597,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46681,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50592,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105697,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102867,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 10322,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96386,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91410,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197196,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286139,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172770,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83395,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 228400,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97286,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17047,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115712,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90555,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53465,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81429,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16142,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114187,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25415,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89814,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122693,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41978,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129498,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80332,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164067,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86978,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19958,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18721,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43851,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145927,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119472,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135025,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11371,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152398,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27739,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183996,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3137,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252078,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457453,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116620,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114735,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163418,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125855,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151690,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124615,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90645,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 446093,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120634,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99394,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165529,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142211,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245917,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 248789,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12773,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14876,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42597,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19559,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182286,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77577,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19367,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93785,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 127682,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 212018,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14804,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127709,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 87315,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107943,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82275,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232893,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132693,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29478,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53751,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130082,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39783,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18780,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 415483,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28941,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 223613,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65492,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104623,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225326,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58608,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24177,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114611,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31626,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130431,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129562,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47360,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117432,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109475,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 325633,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98425,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87689,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37128,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70909,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 152750,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141449,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 242683,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26655,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132220,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 179401,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 212648,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 394875,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227528,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509467,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80198,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42717,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29555,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247089,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 625555,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235576,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183050,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249381,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62811,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131830,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28295,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101436,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110836,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182772,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52448,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 708218,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88851,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37831,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60617,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195712,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124935,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36001,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81503,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143482,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43316,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8061,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552649,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 371451,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 258269,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146006,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254600,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40566,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 323271,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 241401,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290696,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23761,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46624,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78132,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222418,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72994,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249375,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166879,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 66472,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29550,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 359280,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142939,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 175254,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175785,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 33993,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 149457,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141086,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304248,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150610,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 390833,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76968,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 442131,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 424306,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 367946,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 83238,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 49365,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 79814,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 139856,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225877,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193325,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 176849,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108975,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395508,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 236143,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 105891,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395005,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21499,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292548,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91263,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 457182,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 129912,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164605,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228631,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270348,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160149,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76255,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 266081,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87958,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 17622,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131754,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8567,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30720,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48877,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29480,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18192,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34284,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 272051,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52945,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133424,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33700,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11130,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10152,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 54552,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11547,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43533,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 2839,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58593,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77082,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 292308,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26586,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328154,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22164,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22826,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72865,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56045,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69919,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236437,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84348,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 308599,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30399,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 126081,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27149,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8352,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173559,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94144,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32484,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23005,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477877,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84724,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 481011,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74751,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172387,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194058,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183471,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131611,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124700,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127589,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110640,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 61446,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29714,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131888,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207927,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 90088,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70962,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27551,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 3970,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91096,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37840,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58390,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72155,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60892,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 42619,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 138390,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13686,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146530,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53582,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40166,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72927,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36146,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51804,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 67364,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 234864,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40322,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13302,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94082,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121965,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28793,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26429,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 316222,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138359,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9583,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 100235,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11510,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145355,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82922,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92738,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65858,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232844,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18015,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130199,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222109,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144210,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238071,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32050,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 143057,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181572,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 15839,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 326686,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 34899,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27357,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17553,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90446,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102131,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136180,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214610,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126518,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49417,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224157,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44474,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133657,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62598,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 115535,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286254,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13470,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 66046,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72816,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127710,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189865,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16679,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126908,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89965,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11506,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403411,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 251216,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85583,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133363,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56524,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97978,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134881,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112610,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157975,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145908,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113702,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 78317,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20745,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 339912,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150435,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130079,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110595,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88719,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52798,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34016,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 72399,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 290908,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208988,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179241,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34313,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150665,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17148,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185689,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73010,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146987,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 139536,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32469,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149690,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268273,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17457,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43176,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507786,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138738,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159906,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140302,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141312,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20057,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65506,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94264,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 294002,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93135,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88152,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107723,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227021,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 207585,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247607,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 243195,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10977,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216540,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93339,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96949,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51193,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 94849,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 485007,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270035,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117992,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 364285,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92646,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122461,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 710398,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23276,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 186815,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191963,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168674,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175456,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43708,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125136,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654079,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 338338,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202182,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 259323,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134556,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96748,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 214717,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 14864,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33069,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104662,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240922,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311371,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 636028,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311248,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79920,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83711,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 509565,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 405317,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 470260,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108953,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169297,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 458558,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82800,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 219466,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 86655,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6557,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80351,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75894,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 127878,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483697,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 304706,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129758,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44633,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31783,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 468575,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 171560,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 314155,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40586,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135324,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 293962,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242744,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265819,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410186,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208179,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 92717,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 378559,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 283606,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 648860,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80064,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93472,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23754,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 496462,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 191135,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28176,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 312910,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45947,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182095,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 760232,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 527893,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184641,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85685,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 265651,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 509576,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 442827,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40437,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 369149,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 334678,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 42351,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 209758,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27845,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520566,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 765741,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 382732,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274258,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192587,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 565835,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328845,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 264692,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55047,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 643242,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 630516,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44602,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 51555,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 557580,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217069,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 99441,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 244067,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119507,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 67360,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 220917,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29943,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164679,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5361,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125698,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13941,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45576,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138475,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127204,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92258,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148280,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 56499,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35097,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114532,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47391,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80998,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58073,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219695,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10939,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118708,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 266629,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 561715,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96497,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236372,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 128985,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205989,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114073,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76977,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156144,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89273,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287246,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21740,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43819,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20138,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99524,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 80060,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27786,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114009,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64839,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11406,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21093,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31356,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 146733,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78628,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99101,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26050,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116954,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23013,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197675,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80503,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167760,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 249500,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 61828,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120252,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9662,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100368,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 268217,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227157,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17876,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14478,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71893,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 298936,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84303,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110554,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37200,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38292,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166540,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4679,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197928,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94880,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4059,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79770,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128673,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49461,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68239,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246594,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40672,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 100762,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39003,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40220,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13573,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123406,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80471,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11683,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38509,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35442,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98492,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73071,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136093,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135423,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28901,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46790,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78872,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35896,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93811,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72219,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 170910,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141767,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239440,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100584,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102876,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5882,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14129,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175367,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126328,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134656,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82681,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109256,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141507,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100625,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 148500,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26637,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108404,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229022,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90311,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13334,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 283842,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57890,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149115,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223916,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80142,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187051,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51799,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 478252,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31499,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27940,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10764,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258056,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82314,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175263,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246046,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 111320,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 110105,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160732,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84411,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 251456,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128871,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143723,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180340,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185241,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44214,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80789,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168754,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335545,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14250,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 22912,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 95736,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65919,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 152120,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69574,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102952,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293869,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 274502,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296186,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16912,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121259,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118786,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 517684,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30128,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174918,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 205139,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 20904,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204180,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118325,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 255889,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62604,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178534,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60718,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19139,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184687,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 644461,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199510,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99255,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3849,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116033,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113191,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 486319,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138772,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83089,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 579129,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 85692,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310210,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 357157,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 245844,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114435,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123930,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68293,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30782,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47183,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 182735,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125550,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140098,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313394,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77584,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58147,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150060,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106850,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 177448,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126828,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37814,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130068,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239490,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 680345,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 246596,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81411,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 396749,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 600646,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307078,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 142441,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 291551,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103115,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 160584,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47932,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18062,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34190,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 181968,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169232,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108003,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47059,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 88381,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42209,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47780,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137396,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 265066,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 497299,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130086,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536986,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103346,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70936,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 76371,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73085,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267303,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 291590,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75241,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175379,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161281,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230818,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429844,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116873,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 551498,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271676,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182210,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 59255,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 437601,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268016,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 688772,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441266,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174077,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158341,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 455754,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 349418,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 391852,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192208,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 88831,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 499510,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221297,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 90389,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 427278,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23166,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260697,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93119,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80826,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84946,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 594574,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 296003,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 247746,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 408931,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244637,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40818,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 18072,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64566,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23717,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108707,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62878,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483508,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272162,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 593071,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 221992,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449788,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 124738,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 22577,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16289,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97640,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17610,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35398,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 16819,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82524,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11278,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83091,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 137040,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 176388,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25036,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141967,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11810,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98023,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82428,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30913,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92012,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24816,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11984,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243738,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11818,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 56511,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25117,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93390,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88277,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42372,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189494,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132901,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145498,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 167109,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147718,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133321,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41918,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12751,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50755,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 130939,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27899,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 35116,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88414,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140496,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75924,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28335,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 71647,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39510,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36055,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82506,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56503,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 252786,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48930,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 174699,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27471,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107997,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 55694,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159068,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139513,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8290,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94869,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10760,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25044,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92305,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43300,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14200,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84549,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134514,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70468,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301809,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48409,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 61388,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107246,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139463,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15693,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108132,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77804,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191197,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15629,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87853,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62268,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 75440,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13255,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 234335,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 51474,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11110,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 27302,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64476,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96973,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122328,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40998,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148148,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102416,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77974,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23855,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6559,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84985,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218919,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21669,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293673,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158242,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133068,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173684,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 331744,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 45646,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13363,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73235,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199757,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 136156,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 454671,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41298,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44310,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76058,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89334,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9329,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140325,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157320,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 70953,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100494,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80834,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45002,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10272,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221957,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29906,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66374,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154798,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115126,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15069,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163444,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50468,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34586,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120820,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81886,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 213623,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136134,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63359,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114898,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32915,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49017,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239281,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 638561,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82929,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 109886,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86562,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37042,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23925,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11447,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 231939,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189057,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129856,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83718,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 35778,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50952,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86334,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163909,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 32230,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 453408,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364946,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 401051,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28221,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18869,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38855,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 109400,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139962,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301184,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75741,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176745,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 130215,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84883,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 129158,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26943,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28437,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57091,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10086,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335787,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23923,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 305703,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 101678,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 316774,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 556969,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200635,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110345,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59745,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130640,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57513,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111572,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201803,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170973,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 377053,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69287,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86167,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72968,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 185028,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31670,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 49125,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84100,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192921,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77722,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 130847,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359082,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 24535,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124586,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 120786,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92500,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144221,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330701,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 106852,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240169,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 419554,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 238480,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269330,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247984,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35045,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 77972,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146404,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 101722,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17298,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 276608,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177015,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62518,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 246013,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 271656,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 375464,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 277046,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45137,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 232237,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400814,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359401,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 133261,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 169692,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183175,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 112340,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 616455,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10710,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45736,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 31107,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 73084,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 57470,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376715,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 416873,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328810,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84569,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70589,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 619598,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152071,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44210,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81191,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 170110,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12688,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109228,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153060,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110966,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 83805,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41494,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35261,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70940,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116328,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 147722,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76835,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26213,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110332,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9692,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94709,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24881,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153609,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85010,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434955,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137648,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169260,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175277,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99678,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71189,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 302411,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15446,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62106,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128880,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 16740,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86373,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 196767,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114007,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194332,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20296,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50521,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29567,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 87339,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115067,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10563,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24044,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90395,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 134086,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45813,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192667,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 264888,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21758,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 15051,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4341,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124937,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112465,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 31845,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26264,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145456,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80608,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 350265,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155519,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118174,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123439,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111924,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39412,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144241,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 14719,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67258,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68679,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11539,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209285,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143305,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73952,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31820,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175579,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16819,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18006,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116836,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 197281,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74766,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41192,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 245080,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116289,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148009,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18815,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 307472,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 126781,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37763,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56738,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133002,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 130546,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112138,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 506339,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32245,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25660,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95557,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51316,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 119808,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115812,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107238,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12239,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164313,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 335805,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36157,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98260,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160759,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 272760,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88751,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 225913,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41438,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29036,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30469,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205786,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124368,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29280,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20539,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43526,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 60365,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9766,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121100,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50363,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67754,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 201882,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 17988,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97901,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 190165,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108707,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 58633,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163220,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13930,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121149,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96858,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 74760,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92891,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108473,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132971,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97024,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 72633,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10505,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91203,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168591,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 261543,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 238432,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 81183,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 145314,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45487,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237054,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29764,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90981,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 352541,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 22771,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28149,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 263011,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132490,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 48554,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185109,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184507,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108529,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108978,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74723,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126910,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 96747,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 99026,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89965,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35869,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162956,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 306584,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9840,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184054,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144012,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90669,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 396852,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30492,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 192059,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88914,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43855,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195975,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281961,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 187177,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 183504,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 116206,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 538421,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85417,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122689,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184425,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46027,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201342,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124636,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150594,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 74921,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 572176,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 726044,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 238460,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61043,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 54988,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36880,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159649,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123303,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147000,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 180890,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206369,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 207264,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179278,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 104946,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 44684,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50849,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159219,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95420,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 203203,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43528,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31561,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 22328,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40573,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40437,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31895,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 585155,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43920,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87008,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 44879,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 614453,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27908,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62313,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157236,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240968,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 258845,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 174253,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215553,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83271,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 659733,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 21179,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 280058,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114598,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 370498,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89386,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197352,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 174657,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 111443,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63415,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 59354,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33587,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63863,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 239683,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 205166,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256876,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136050,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120890,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 163642,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184396,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112369,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 587315,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 444350,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 520326,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 676522,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45417,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 513740,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 219846,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199959,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 227326,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 267290,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245197,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 115047,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 30558,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45876,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534330,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190896,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 285873,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52517,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 436215,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435514,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 69686,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 19917,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 113498,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 43967,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 141678,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206167,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 654682,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 554367,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 411257,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 148878,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 462485,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244435,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53579,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166611,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 13571,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 25253,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152669,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115977,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85496,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253905,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150734,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33880,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 27812,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17042,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113913,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 50614,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95988,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68829,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75798,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 114866,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20628,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30048,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 346410,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26146,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105610,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 59824,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180388,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 216077,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6316,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120962,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167575,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140851,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178031,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9923,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 115038,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40315,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133554,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109450,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59387,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85857,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 73749,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48726,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4479,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81460,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65654,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8130,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28844,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134540,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 78026,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64537,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10420,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 18121,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92514,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 381168,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431547,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63055,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9104,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28246,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73735,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11362,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 239308,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25822,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77607,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136436,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 63360,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 135979,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94895,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30300,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108981,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65849,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13762,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82692,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77288,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 155179,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141517,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10678,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 162723,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144883,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121476,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75275,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117316,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121486,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75083,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 83404,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96485,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42572,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30608,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134331,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 36528,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 32586,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 144330,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12734,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148765,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185284,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104888,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 28533,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 55572,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 254383,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 19321,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8178,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424700,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18248,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 418947,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168999,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97975,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 208098,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18483,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122450,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157572,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301663,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114511,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73054,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32562,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164312,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102758,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 430116,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 8686,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91527,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22468,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79419,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28386,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53620,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112918,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26527,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 161408,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 162344,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104446,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24155,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85826,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295720,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81811,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7536,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81956,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121161,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119079,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63386,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474344,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50202,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51896,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100193,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 381388,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255885,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 157001,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 116343,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11431,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131261,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278415,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195876,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 247439,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 60503,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 128105,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 9611,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23197,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 86649,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52697,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93171,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156324,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 118791,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 107811,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 48745,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28056,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 121844,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255059,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177549,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177668,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110756,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187000,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18542,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 146293,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 195877,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88152,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 569006,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70307,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79898,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 183266,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 72125,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270223,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30548,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 103496,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 5720,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46072,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 94099,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 116000,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269558,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44778,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 294942,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259168,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60680,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 519720,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185641,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 142618,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197801,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 104557,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 348376,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62474,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 84500,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62666,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75418,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153877,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44535,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185930,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 409394,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 400971,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 664714,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 52530,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35025,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 487853,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 87445,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 162417,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89111,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90155,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86191,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99063,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 63794,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64333,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 746787,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 310300,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139796,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140522,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 197681,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132531,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431471,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179104,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23226,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 583554,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237859,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54456,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247704,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51507,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211699,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 643053,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 261096,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 44457,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 178339,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 306098,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176171,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 108483,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 550789,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44664,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 26621,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 84150,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 410311,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226986,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 373923,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121160,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 156034,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 40147,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 40050,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 401678,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 46571,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 70541,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93559,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20601,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 252445,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198046,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 718887,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 406183,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 155724,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201079,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39442,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 164477,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106766,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 3459,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 56341,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 301979,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118719,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84579,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14797,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18674,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8971,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 140082,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 23344,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 173906,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82064,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139645,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66793,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251163,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17157,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41245,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10719,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 64758,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14284,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122712,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120120,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 86808,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62957,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94331,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18077,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32542,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91657,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33953,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 41595,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 84359,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89636,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193125,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156555,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17376,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 65550,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158211,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32575,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83811,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87333,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 99605,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50210,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 154810,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65125,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79843,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14880,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110388,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128765,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131156,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298353,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114062,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29981,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33050,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71794,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9868,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18923,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16130,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 43527,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 9375,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68046,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 142222,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10214,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147091,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138116,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 181286,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97952,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112376,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 148072,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32007,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180273,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34274,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8811,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50810,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60127,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115215,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347922,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 7066,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 84725,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69490,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133453,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77449,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170627,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 47020,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 252132,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 29511,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 243637,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20326,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25489,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195177,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 75400,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157107,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80425,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 224987,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92329,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190533,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 429206,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86078,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127332,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 505103,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86767,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67288,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 157171,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 106210,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 76798,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116171,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269924,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24798,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 141089,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 41696,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9706,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23379,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 184467,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278494,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36558,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15800,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8764,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8378,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 178430,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 164823,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 158679,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 443331,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 122931,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74839,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20520,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 150013,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72690,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85648,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46181,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 638836,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 611207,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134639,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 49656,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26523,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117853,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91938,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50864,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86616,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 42211,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 28125,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82231,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98886,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 240645,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 101497,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46937,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8188,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91603,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 210450,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211648,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 38114,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 591536,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 222562,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 129220,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 220592,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126140,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7578,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107279,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113149,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 144203,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 116630,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66440,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 120125,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 206991,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102706,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 77790,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82395,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70045,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 150286,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61612,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144031,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118078,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 414278,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81043,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102452,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 79875,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209045,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 169742,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105322,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 149174,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 477191,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 137922,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280919,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 186329,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 530788,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 274632,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190097,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50233,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 71073,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121314,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 226189,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168456,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 146193,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76566,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56691,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82746,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187370,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39863,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44157,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 178832,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 280108,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176881,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 488698,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38846,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 286989,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111595,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 195936,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 381867,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 141748,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 80077,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224524,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124818,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 20373,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 365266,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180263,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230939,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 105493,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 376943,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542634,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 86626,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 445440,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 336329,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 421459,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 449297,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36811,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 648161,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25861,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 78870,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 13138,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 364246,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208695,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103373,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542360,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 203361,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 38995,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 137331,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 41060,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 243670,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 424040,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 276488,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 284116,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 186666,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440241,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169603,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 230136,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56383,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 548905,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 140007,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 474483,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 424320,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 81414,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 107558,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 320667,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51049,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 381146,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 518817,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 208376,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 714507,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 24337,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45125,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 705984,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449444,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350110,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190700,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 491352,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 647019,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159762,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44690,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 7387,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 38243,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134302,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94887,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 25619,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 169670,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215140,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88440,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19951,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 23589,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 106237,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 39641,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126543,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65677,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64413,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91345,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45140,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95236,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 167472,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123040,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19619,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6512,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26742,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 42412,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52249,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70635,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15600,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147228,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58180,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37138,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 33594,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 494486,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37876,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26880,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 64385,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94211,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 15925,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 161958,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 240993,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 85414,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 45615,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 47966,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72472,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74500,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100527,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105684,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108291,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37642,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57764,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23989,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191570,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 51927,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36956,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28110,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 164441,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92303,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152291,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 61368,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70036,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62017,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136843,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11532,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102527,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155563,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40046,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 98827,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 44800,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91306,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21740,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124031,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 72804,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133570,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 278099,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 88538,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 28306,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 93194,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 40444,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 469393,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13279,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85854,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12865,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166347,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 229749,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 120279,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 92238,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102014,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122593,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 122210,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153877,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6280,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 8893,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 32641,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 352738,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 62226,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129861,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 18302,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115309,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137613,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74483,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87250,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25590,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 74547,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 163609,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11939,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 102428,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 230126,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 220835,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34803,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 144827,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 108924,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40314,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92313,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64233,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36864,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125482,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 185690,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 97673,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123953,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 86574,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87216,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71153,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133234,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180417,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113501,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131401,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 147242,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128419,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39483,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68406,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209269,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25204,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70437,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143071,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 175573,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7819,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8113,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130181,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95862,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 507913,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111652,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 181564,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428929,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 236410,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65803,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 134448,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 155493,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 63172,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113584,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44513,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512315,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176138,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 91836,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110999,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 155832,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28957,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176809,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 30184,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 64674,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 158446,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 571199,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 717373,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 354215,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 62815,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17981,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 182678,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 61711,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37866,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11629,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99522,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99197,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147678,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 95028,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192701,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17792,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 189975,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 192160,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124006,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 435286,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 52213,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48162,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 82364,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179517,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82964,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121207,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110715,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132045,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116687,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97244,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214589,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 82483,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282475,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 21106,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 51327,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 137256,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 611303,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 455474,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97160,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 80563,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 301921,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244198,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370585,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131419,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133979,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67073,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175929,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 139134,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11200,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21920,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 452697,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 373693,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 235011,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542206,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46118,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157210,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 397979,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 32280,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 123516,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 49224,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 523926,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 51513,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230748,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 269271,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 512214,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 549631,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 201288,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 347222,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50108,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91618,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 267825,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 421382,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 558675,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 286723,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 528827,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 404388,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48719,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 54597,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 249457,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440115,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70800,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 416248,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 120608,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279161,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6608,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 113154,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 13729,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 125199,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175366,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 27327,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122763,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 18923,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 98074,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151929,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4852,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16865,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112661,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5008,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 102457,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7412,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92482,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 26750,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 34381,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 43933,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102184,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 97290,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109591,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14870,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118553,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 305509,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119572,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135705,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147464,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 16937,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57611,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 135449,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 32339,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95789,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107220,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29794,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 114644,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9336,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 268627,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130270,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 74157,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92406,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120944,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 144251,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85662,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 285653,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25902,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60685,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131743,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100067,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 51463,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 39628,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 58314,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89442,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10830,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 87541,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12352,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 24967,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132151,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76229,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116903,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154543,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140534,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143881,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93003,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149348,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118973,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 152910,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35891,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 396990,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 36353,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15538,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 162341,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313057,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77126,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118794,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48727,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 191328,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 424147,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 141205,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136525,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127866,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 132578,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20365,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 119387,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 59762,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99126,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 31424,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62378,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35507,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38698,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107407,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38536,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149874,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20120,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 97611,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66582,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259812,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92874,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50658,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 70497,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82103,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 43881,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172360,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 36798,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125646,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 124822,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 42282,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82421,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 265342,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 8206,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 129374,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 140738,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80737,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79719,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130049,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 42406,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18098,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25466,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103632,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149229,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 90828,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144968,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 337189,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31004,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 192740,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146895,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 72159,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 109534,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159887,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 113684,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 35,
    msa: 'milan',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70440,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50145,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29577,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90114,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140555,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389244,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 616976,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100782,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119093,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 253276,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133736,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 127451,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 121129,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224686,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75632,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 47906,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 359297,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237667,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 30996,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40236,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260420,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 60373,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 122849,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 37804,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 101314,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 160800,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56709,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 176111,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248489,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28224,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 168990,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 77097,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253351,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 320156,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 409379,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 393718,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 48989,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79237,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66302,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82578,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112451,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 22289,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 168162,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 22239,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 91205,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67961,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 255182,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 21142,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112926,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 95875,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 90166,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 272184,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178688,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 93323,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 227340,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128529,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 69518,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100087,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115890,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106883,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63433,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32271,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 39075,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 366968,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 26820,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138874,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75054,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234626,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90277,
  },
  {
    roleId: 104,
    role: 'IT Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 91936,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 159369,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 346507,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 354504,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112767,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189345,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 281202,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153260,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 52184,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76556,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 198754,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 115560,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88811,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108189,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 746509,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14029,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 233385,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 286117,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355075,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 206133,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 312411,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 290150,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 94218,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26173,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 49356,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 313271,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 180645,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23414,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 270727,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 214424,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 364717,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 27178,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 25312,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 320546,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 136616,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100101,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 402794,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 81322,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 133923,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158765,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 448208,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245661,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 17,
    msa: 'boston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 697258,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 156597,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 70206,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 169828,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431847,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 149710,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84274,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 111550,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 490531,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 169041,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 6354,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 82218,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 107920,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 53903,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 78400,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159589,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114811,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 146980,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295032,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 93483,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 57144,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128780,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134641,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75211,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5227,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 234139,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168776,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 36121,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 12538,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66542,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122682,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205938,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73565,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 33241,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 90858,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20426,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110873,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 130096,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 89945,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 7231,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 9845,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183772,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 74484,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 15386,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 127969,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128253,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101627,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 73072,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 87653,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 135199,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62542,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126971,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 100511,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184827,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 70410,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148475,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 29821,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 67924,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 463413,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 97381,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 99516,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38332,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26294,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 132522,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37756,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78680,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 50368,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 147016,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 145910,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199214,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248295,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 17492,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152503,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 110130,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93684,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 88869,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18368,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125106,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94139,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15314,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18432,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 206857,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 4144,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146018,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133766,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 23738,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 51655,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120618,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99391,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 65592,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 123205,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 82339,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11865,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186921,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 223879,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 46145,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16237,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 17,
    msa: 'boston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 112890,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 68348,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 159263,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 52763,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 216950,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 121621,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72738,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 330736,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 40955,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102843,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168729,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 271924,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 165005,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150027,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 38,
    msa: 'denver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122438,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77121,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37231,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217638,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113193,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 231615,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100161,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 160440,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 50145,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 96410,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 202550,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123485,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 627740,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68838,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 37756,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 312204,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 150435,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 133572,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105213,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 22968,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63963,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 116151,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 20531,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 193655,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 107180,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 71616,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 214969,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 104520,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 185945,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 83377,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10879,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 76000,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108905,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81486,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102686,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166845,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171936,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 188663,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208422,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 84492,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 7097,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196156,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 149534,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 125390,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 153931,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 327566,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 96027,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 93820,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 35,
    msa: 'milan',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166386,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 143024,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 126468,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 189734,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 153955,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 62366,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 462285,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131880,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 4504,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121343,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136857,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 72878,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30259,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16333,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 128789,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 171449,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 45322,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148469,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142474,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247447,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 131363,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137478,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 13414,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 159710,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 110150,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 103137,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 10074,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46410,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 657290,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 85811,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 211447,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 313449,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 493700,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 31438,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217512,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 325352,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 40125,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134636,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199150,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 258723,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 534555,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 47062,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157536,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144810,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111234,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 242855,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15793,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 198043,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208678,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9756,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 17750,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 14,
    msa: 'washington',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 140584,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 58791,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 266052,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 71581,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62347,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102296,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 282311,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144061,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 225035,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 152423,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 24271,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 525823,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255362,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 200024,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 176848,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 161834,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 346915,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 182881,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217285,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 116259,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 596346,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260470,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 298612,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 423523,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 178930,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 6,
    seniority: 'Director',
    salary: 86550,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98781,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 176550,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28266,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 68161,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 135413,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 469018,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67301,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 21989,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 292498,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 449855,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 6,
    seniority: 'Director',
    salary: 91815,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 369822,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 119591,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 578941,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 199797,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 574875,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 68595,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 48940,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 547710,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 348934,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 515053,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 507935,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 150223,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 43875,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 270647,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 164125,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 412022,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 536436,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 84888,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 82446,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 762394,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 87639,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 428558,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 31557,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 230947,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 146240,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 98605,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 348022,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48969,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190163,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 25931,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 117708,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 45964,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 66694,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 190699,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 454629,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 6,
    seniority: 'Director',
    salary: 424987,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89546,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 190259,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62824,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 79575,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10095,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 12428,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 128352,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 18329,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 82457,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117990,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20280,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 137042,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6795,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66515,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 87693,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10989,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 191457,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94095,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 3,
    msa: 'paris',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89449,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 142589,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 54363,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 28904,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269007,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 28496,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 107392,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45801,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 115461,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 167610,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 133029,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89380,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 92076,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82604,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 25646,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 1,
    msa: 'london',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130334,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 19018,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55612,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 120214,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 142242,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 183297,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 30787,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72995,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 117198,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 80880,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 62282,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 123066,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 112601,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 224124,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 208711,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67354,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 215775,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 252913,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 64640,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 669561,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 48963,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67499,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 60822,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 108121,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 89799,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 69419,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 35122,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 111239,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 88940,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 57412,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 78632,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20556,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 157276,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 37,
    msa: 'pune',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 17148,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 66749,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 72571,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110722,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 137553,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 64916,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 106446,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 131820,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 205207,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138957,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102156,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5757,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26319,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 100509,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168543,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 94961,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 76455,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 29565,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92240,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 175550,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 110005,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 43998,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50398,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16288,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 73962,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 91524,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106189,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 95719,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 80712,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 14381,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 224651,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 14,
    msa: 'washington',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 126764,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 36807,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18658,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 296951,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 8383,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 52554,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 440529,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236137,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 121104,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 67,
    msa: 'austin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69862,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 44082,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 88357,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 29908,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154792,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 124647,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 45589,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11985,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16951,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13963,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163787,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 26324,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 28477,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102427,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 79022,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 85407,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62807,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 92765,
  },
  {
    roleId: 138,
    role: 'Case Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 463826,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109364,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 92142,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 29677,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159231,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60268,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 14,
    msa: 'washington',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 42236,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 322656,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95509,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 117728,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 142034,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25855,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55849,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105795,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 143405,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77897,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158260,
  },
  {
    roleId: 140,
    role: 'UX Designer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 184733,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 156211,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185375,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105051,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80907,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 248819,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 93907,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 16575,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60791,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151023,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150665,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67472,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 215900,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 6,
    seniority: 'Director',
    salary: 218982,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141998,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 114868,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 311853,
  },
  {
    roleId: 144,
    role: 'Content Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 148322,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 164807,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 41529,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 219289,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 105180,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 407020,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 58,
    msa: 'manila',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 35999,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 138511,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 102762,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 293398,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17135,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 6,
    seniority: 'Director',
    salary: 254214,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 146326,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 190317,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108862,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 519068,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132662,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 38,
    msa: 'denver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 80051,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 89028,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11261,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149827,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 179668,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 31461,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41563,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168004,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 118022,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 99095,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166053,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166107,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 125201,
  },
  {
    roleId: 99,
    role: 'Technical Architect',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112417,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 25558,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166592,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 110586,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 257155,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59334,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 200406,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 111490,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 143914,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 37,
    msa: 'pune',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 20465,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 14848,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 83395,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 85057,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 251213,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 201548,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 104081,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 166897,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113084,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 328758,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 531072,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 38,
    msa: 'denver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 197237,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65418,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116551,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 123971,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 108900,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 262110,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 138331,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 113039,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 29087,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 17778,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 6,
    seniority: 'Director',
    salary: 315218,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 16857,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195895,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159709,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 151427,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 170096,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33386,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 239376,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 105129,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 6,
    seniority: 'Director',
    salary: 139851,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 247696,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 140568,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269476,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 74343,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 40072,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14981,
  },
  {
    roleId: 88,
    role: 'Construction Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100626,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 30,
    msa: 'houston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 253659,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 67117,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 58042,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 125760,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 30,
    msa: 'houston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 134665,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 6,
    seniority: 'Director',
    salary: 300016,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 47522,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 46481,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117991,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 259880,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103124,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 19250,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 112207,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 111368,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 6,
    seniority: 'Director',
    salary: 444107,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 65949,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 218137,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 423518,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 245357,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 75054,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220527,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 67,
    msa: 'austin',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 677920,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 58,
    msa: 'manila',
    seniorityId: 6,
    seniority: 'Director',
    salary: 59724,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 35368,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 6,
    seniority: 'Director',
    salary: 229491,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 53621,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 166441,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57851,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 26777,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 391723,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 118135,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 45134,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61419,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 236416,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 552647,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 483067,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 50247,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 34025,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 67060,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 81379,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 395360,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 578004,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 196063,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 431286,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 467707,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 63235,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 581699,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 6,
    seniority: 'Director',
    salary: 58341,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 403719,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 163900,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 367322,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 60174,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 58,
    msa: 'manila',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 23205,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 275374,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 244435,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77601,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 18026,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 9784,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12093,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11223,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26857,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 478397,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 96547,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 224912,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 5654,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75901,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 544532,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 60429,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 124733,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 3,
    msa: 'paris',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 138575,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 114546,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 34413,
  },
  {
    roleId: 141,
    role: 'Digital Marketing Specialist',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 26068,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33880,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 35759,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 73739,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 95539,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 14244,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 1,
    msa: 'london',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 65962,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 120574,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 3,
    msa: 'paris',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 86296,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23667,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 124040,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 19395,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55500,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 107042,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 183157,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 118120,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 13743,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109422,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 113513,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 153537,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 32462,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 37,
    msa: 'pune',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 10385,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 30,
    msa: 'houston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 186509,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 65053,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 94970,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 12982,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37544,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 31659,
  },
  {
    roleId: 108,
    role: 'Laboratory Technician',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 45973,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 30,
    msa: 'houston',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 92680,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 14751,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 514661,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 200323,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 269014,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 1,
    msa: 'london',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 70327,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 3,
    msa: 'paris',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 62139,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 255735,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20664,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 108425,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 12398,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 50598,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 15577,
  },
  {
    roleId: 83,
    role: 'Technician',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 6662,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 34858,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 20292,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 118525,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 11090,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 81366,
  },
  {
    roleId: 55,
    role: 'Operations Coordinator',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 30834,
  },
  {
    roleId: 122,
    role: 'Corporate Trainer',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 11259,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 37,
    msa: 'pune',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 20564,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185943,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 353562,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 55658,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 134783,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 21037,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82992,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 15644,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 69497,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 44593,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 413741,
  },
  {
    roleId: 136,
    role: 'Medical Rep',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131059,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 185746,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 10380,
  },
  {
    roleId: 31,
    role: 'Cleaner',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 166016,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 70339,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82867,
  },
  {
    roleId: 7,
    role: 'Wealth Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 256863,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 88948,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 150353,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 24074,
  },
  {
    roleId: 149,
    role: 'Producer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 100318,
  },
  {
    roleId: 143,
    role: 'Writer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 273376,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 141781,
  },
  {
    roleId: 89,
    role: 'Foreman',
    msaId: 37,
    msa: 'pune',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11981,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 41917,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24727,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 228359,
  },
  {
    roleId: 132,
    role: 'Operations Administrator',
    msaId: 14,
    msa: 'washington',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 217489,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 434866,
  },
  {
    roleId: 97,
    role: 'Automation Engineer',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 139078,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 114525,
  },
  {
    roleId: 60,
    role: 'Project Manager',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 46850,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 17,
    msa: 'boston',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 116770,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 281788,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222687,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 178572,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 3,
    msa: 'paris',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 185492,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 38164,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 46921,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 287212,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 16,
    msa: 'sydney',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 56463,
  },
  {
    roleId: 133,
    role: 'Scientist',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 33733,
  },
  {
    roleId: 32,
    role: 'Restaurant Manager',
    msaId: 1,
    msa: 'london',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 153076,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59403,
  },
  {
    roleId: 5,
    role: 'Financial Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 134858,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 35,
    msa: 'milan',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 122338,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 144605,
  },
  {
    roleId: 119,
    role: 'Human Resources Specialist',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 106509,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 112876,
  },
  {
    roleId: 94,
    role: 'Business Analyst',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 53265,
  },
  {
    roleId: 69,
    role: 'Quality Assurance',
    msaId: 32,
    msa: 'jakarta',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 68274,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 278496,
  },
  {
    roleId: 127,
    role: 'Legal',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 6,
    seniority: 'Director',
    salary: 116075,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 194106,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 151655,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 101190,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 67,
    msa: 'austin',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 103177,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 129299,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 77696,
  },
  {
    roleId: 130,
    role: 'Coordinator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 147559,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 270282,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 54897,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 168482,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 7,
    msa: 'delhi',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 11412,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 191570,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 130732,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 1,
    msa: 'london',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 68024,
  },
  {
    roleId: 51,
    role: 'Customer Success Specialist',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 136388,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82375,
  },
  {
    roleId: 148,
    role: 'Marketing',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 63434,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221388,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 122687,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 67,
    msa: 'austin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 132478,
  },
  {
    roleId: 66,
    role: 'QA Tester',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 109672,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 98131,
  },
  {
    roleId: 64,
    role: 'Officer',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 158195,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 465219,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 33077,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 699974,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 17,
    msa: 'boston',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 151804,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 76396,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57102,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 72772,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 309909,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 10214,
  },
  {
    roleId: 2,
    role: 'Economist',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 307424,
  },
  {
    roleId: 56,
    role: 'Driver',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 57612,
  },
  {
    roleId: 74,
    role: 'Test Engineer',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 57498,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 23021,
  },
  {
    roleId: 84,
    role: 'Technical Support',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 59250,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 392696,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 126251,
  },
  {
    roleId: 58,
    role: 'Logistics',
    msaId: 14,
    msa: 'washington',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 75517,
  },
  {
    roleId: 107,
    role: 'Data Scientist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 62985,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 273602,
  },
  {
    roleId: 146,
    role: 'Graphic Designer',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 204768,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 180353,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 61346,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 89456,
  },
  {
    roleId: 12,
    role: 'Client Services',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 148726,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184839,
  },
  {
    roleId: 80,
    role: 'Technical Support Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 39788,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 55306,
  },
  {
    roleId: 75,
    role: 'Safety Officer',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 108048,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 56,
    msa: 'montreal',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 154442,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 102144,
  },
  {
    roleId: 17,
    role: 'Accountant',
    msaId: 21,
    msa: 'san francisco',
    seniorityId: 6,
    seniority: 'Director',
    salary: 332420,
  },
  {
    roleId: 54,
    role: 'Distribution Specialist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 65765,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 77701,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 237685,
  },
  {
    roleId: 71,
    role: 'Structural Engineer',
    msaId: 5,
    msa: 'sao paulo',
    seniorityId: 2,
    seniority: 'Junior',
    salary: 30434,
  },
  {
    roleId: 98,
    role: 'Machine Operator',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 133671,
  },
  {
    roleId: 90,
    role: 'Mechanical Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 97729,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 375581,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 13,
    msa: 'amsterdam',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 120026,
  },
  {
    roleId: 3,
    role: 'Financial Advisor',
    msaId: 84,
    msa: 'kuala lumpur',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 19046,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 111410,
  },
  {
    roleId: 113,
    role: 'Software Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 103559,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 220566,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 58,
    msa: 'manila',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 11811,
  },
  {
    roleId: 52,
    role: 'Account Manager',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 120044,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 46,
    msa: 'stockholm',
    seniorityId: 6,
    seniority: 'Director',
    salary: 425668,
  },
  {
    roleId: 41,
    role: 'Sales Representative',
    msaId: 35,
    msa: 'milan',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 311863,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 177600,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 3,
    msa: 'paris',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 446449,
  },
  {
    roleId: 38,
    role: 'Corporate Strategy',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 313798,
  },
  {
    roleId: 86,
    role: 'IT Specialist',
    msaId: 38,
    msa: 'denver',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 184298,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 66,
    msa: 'vancouver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 167354,
  },
  {
    roleId: 33,
    role: 'Commercial Manager',
    msaId: 11,
    msa: 'chicago',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 107081,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 10,
    msa: 'toronto',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 67074,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 195392,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 37864,
  },
  {
    roleId: 48,
    role: 'Pilot',
    msaId: 23,
    msa: 'dubai',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 271521,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 15,
    msa: 'los angeles',
    seniorityId: 6,
    seniority: 'Director',
    salary: 256774,
  },
  {
    roleId: 118,
    role: 'HR Business Partner',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 204960,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 158208,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 221457,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 41,
    msa: 'barcelona',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 100838,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 14,
    msa: 'washington',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295296,
  },
  {
    roleId: 147,
    role: 'Communications Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 370723,
  },
  {
    roleId: 93,
    role: 'Data Engineer',
    msaId: 71,
    msa: 'berlin',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 117155,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 384020,
  },
  {
    roleId: 68,
    role: 'Production Operator',
    msaId: 57,
    msa: 'bogota',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 24762,
  },
  {
    roleId: 16,
    role: 'Auditor',
    msaId: 38,
    msa: 'denver',
    seniorityId: 6,
    seniority: 'Director',
    salary: 303852,
  },
  {
    roleId: 34,
    role: 'Customer Service',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 172671,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 6,
    seniority: 'Director',
    salary: 193325,
  },
  {
    roleId: 142,
    role: 'Designer',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 389838,
  },
  {
    roleId: 20,
    role: 'Sales Engineer',
    msaId: 113,
    msa: 'rio de janeiro',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 34317,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 67,
    msa: 'austin',
    seniorityId: 6,
    seniority: 'Director',
    salary: 350245,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 160095,
  },
  {
    roleId: 23,
    role: 'Sales Service Representative',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 199499,
  },
  {
    roleId: 9,
    role: 'Banker',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 149421,
  },
  {
    roleId: 106,
    role: 'Web Developer',
    msaId: 17,
    msa: 'boston',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 187063,
  },
  {
    roleId: 43,
    role: 'Stylist',
    msaId: 8,
    msa: 'singapore',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 136506,
  },
  {
    roleId: 109,
    role: 'Software Developer',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 53815,
  },
  {
    roleId: 110,
    role: 'Data Analyst',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 342765,
  },
  {
    roleId: 72,
    role: 'Process Engineer',
    msaId: 45,
    msa: 'chennai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 47821,
  },
  {
    roleId: 102,
    role: 'Technology Analyst',
    msaId: 28,
    msa: 'dallas',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 222669,
  },
  {
    roleId: 10,
    role: 'Investment Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 87678,
  },
  {
    roleId: 24,
    role: 'Retail Sales',
    msaId: 1,
    msa: 'london',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 441419,
  },
  {
    roleId: 87,
    role: 'Electrical Engineer',
    msaId: 2,
    msa: 'new york city',
    seniorityId: 6,
    seniority: 'Director',
    salary: 319699,
  },
  {
    roleId: 73,
    role: 'Sustainability Specialist',
    msaId: 35,
    msa: 'milan',
    seniorityId: 4,
    seniority: 'Manager',
    salary: 97254,
  },
  {
    roleId: 85,
    role: 'Systems Engineer',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 6,
    seniority: 'Director',
    salary: 190062,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 30,
    msa: 'houston',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317598,
  },
  {
    roleId: 103,
    role: 'Technology Lead',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 82704,
  },
  {
    roleId: 45,
    role: 'Cashier',
    msaId: 52,
    msa: 'istanbul',
    seniorityId: 6,
    seniority: 'Director',
    salary: 159899,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 18,
    msa: 'melbourne',
    seniorityId: 5,
    seniority: 'Vice President',
    salary: 96665,
  },
  {
    roleId: 47,
    role: 'Product Manager',
    msaId: 110,
    msa: 'copenhagen',
    seniorityId: 6,
    seniority: 'Director',
    salary: 355780,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 100,
    msa: 'belo horizonte',
    seniorityId: 1,
    seniority: 'Entry Level',
    salary: 28219,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 146164,
  },
  {
    roleId: 59,
    role: 'Procurement Specialist',
    msaId: 51,
    msa: 'phoenix',
    seniorityId: 6,
    seniority: 'Director',
    salary: 209309,
  },
  {
    roleId: 26,
    role: 'Customer Experience Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 3,
    seniority: 'Associate',
    salary: 131991,
  },
  {
    roleId: 96,
    role: 'Manufacturing Associate',
    msaId: 68,
    msa: 'cairo',
    seniorityId: 6,
    seniority: 'Director',
    salary: 50724,
  },
  {
    roleId: 57,
    role: 'Operations Manager',
    msaId: 29,
    msa: 'rotterdam',
    seniorityId: 6,
    seniority: 'Director',
    salary: 295613,
  },
  {
    roleId: 21,
    role: 'Sales Training Specialist',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 217524,
  },
  {
    roleId: 117,
    role: 'Recruiter',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 6,
    seniority: 'Director',
    salary: 279072,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 20,
    msa: 'mumbai',
    seniorityId: 6,
    seniority: 'Director',
    salary: 27092,
  },
  {
    roleId: 11,
    role: 'Agent',
    msaId: 55,
    msa: 'minneapolis',
    seniorityId: 6,
    seniority: 'Director',
    salary: 317314,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 50,
    msa: 'abu dhabi',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 290056,
  },
  {
    roleId: 49,
    role: 'Sales Associate',
    msaId: 30,
    msa: 'houston',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 598576,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 33,
    msa: 'brussels',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 325990,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 542669,
  },
  {
    roleId: 18,
    role: 'Billing Specialist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 21391,
  },
  {
    roleId: 116,
    role: 'Student Intern',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44719,
  },
  {
    roleId: 44,
    role: 'Crew Member',
    msaId: 35,
    msa: 'milan',
    seniorityId: 6,
    seniority: 'Director',
    salary: 179945,
  },
  {
    roleId: 82,
    role: 'Information Security',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 95065,
  },
  {
    roleId: 129,
    role: 'Coach',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 110428,
  },
  {
    roleId: 19,
    role: 'Security Specialist',
    msaId: 26,
    msa: 'atlanta',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 547455,
  },
  {
    roleId: 111,
    role: 'Mechanic',
    msaId: 88,
    msa: 'santiago',
    seniorityId: 6,
    seniority: 'Director',
    salary: 108951,
  },
  {
    roleId: 131,
    role: 'Information Specialist',
    msaId: 24,
    msa: 'philadelphia',
    seniorityId: 6,
    seniority: 'Director',
    salary: 245100,
  },
  {
    roleId: 29,
    role: 'Pharmacy Technician',
    msaId: 31,
    msa: 'seattle',
    seniorityId: 6,
    seniority: 'Director',
    salary: 260955,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 9,
    msa: 'bengaluru',
    seniorityId: 6,
    seniority: 'Director',
    salary: 56468,
  },
  {
    roleId: 8,
    role: 'Realtor',
    msaId: 974,
    msa: 'hyderabad',
    seniorityId: 6,
    seniority: 'Director',
    salary: 18401,
  },
  {
    roleId: 53,
    role: 'Project Administrator',
    msaId: 22,
    msa: 'madrid',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 232246,
  },
  {
    roleId: 36,
    role: 'Receptionist',
    msaId: 37,
    msa: 'pune',
    seniorityId: 6,
    seniority: 'Director',
    salary: 37032,
  },
  {
    roleId: 145,
    role: 'Public Relations',
    msaId: 37,
    msa: 'pune',
    seniorityId: 7,
    seniority: 'C-Suite',
    salary: 44833,
  },
];
