import {
  Button,
  FormControl,
  Icon,
  ModalBody,
  ModalFooter,
  Tooltip,
  useDisclosure,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { FiBookmark } from 'react-icons/fi';
import { FilterPopover } from '../collection';
import { getActiveSetId } from '../../engine/filters.storedset';

import { delay, isEmpty } from 'lodash';

import Autocomplete from '../autocomplete/autocomplete';
import {
  ActionModal,
  ActionModalControlPanel,
  FeatureTrackingEvents,
} from '@revelio/core';
import mixpanel from 'mixpanel-browser';
import { useMutation } from 'urql';
import {
  CreateSavedSetMutation,
  UpdateSavedSetMutation,
} from '../../engine/gql-models/saved-sets.models';
import { View } from '@revelio/data-access';
import { UserSavedFilterSet } from '../../engine/filters.savedset.models';
import { useLocation } from 'react-router-dom';
import { useGetStoredSets } from '../../engine/filters.savedset';

/* eslint-disable-next-line */
export interface FilterSetSaveMenuProps {
  view: View;
}

export function FilterSetSaveMenu({ view }: FilterSetSaveMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { options: allSavedSets } = useGetStoredSets();

  const [, createSavedSet] = useMutation(CreateSavedSetMutation);
  const [, updateSavedSet] = useMutation(UpdateSavedSetMutation);

  const toast = useToast();
  const location = useLocation();

  const [nameInput, setNameInput] = useState<string>('');

  const [suggestions, setSuggestions] = useState<UserSavedFilterSet[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const itemToString = (item: UserSavedFilterSet) => (item ? item.name : '');

  const handleUpdateFilterSet = () => {
    const exisitingSet = allSavedSets.find((set) => {
      return set.name.toLowerCase() === nameInput.toLowerCase();
    });

    if (!exisitingSet) {
      return;
    }

    const variables = {
      id: exisitingSet.id,
      name: nameInput,
      view,
      filters: `${location.pathname}${location.search}`,
    };

    updateSavedSet(variables)
      .then((result) => {
        if (result.error) {
          toast({
            title: 'Error updating filter set',
            status: 'error',
            position: 'top-right',
            duration: 4000,
            isClosable: true,
          });

          return;
        }

        toast({
          title: `Filter set updated`,
          status: 'success',
          position: 'top-right',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(() => {
        return toast({
          title: 'Error updating filter set',
          status: 'error',
          position: 'top-right',
          duration: 4000,
          isClosable: true,
        });
      })
      .finally(() => {
        delay(() => {
          onModalClose();
          onClose();
          setIsLoading(false);
        }, 100);
      });
  };

  const handleSubmitFilterSet = () => {
    setIsLoading(true);

    const exisitingSet = allSavedSets.find((set) => {
      return set.name.toLowerCase() === nameInput.toLowerCase();
    });

    const variables = {
      name: nameInput,
      view,
      filters: `${location.pathname}${location.search}`,
    };

    if (exisitingSet) {
      onModalOpen();
      setIsLoading(false);
      return;
    }

    createSavedSet(variables)
      .then((result) => {
        if (result.error) {
          toast({
            title: 'Error creating filter set',
            status: 'error',
            position: 'top-right',
            duration: 4000,
            isClosable: true,
          });

          return;
        }

        mixpanel.track(FeatureTrackingEvents.SAVE_FILTER_SET, {
          page: getActiveSetId(),
        });

        toast({
          title: `Filter set created`,
          status: 'success',
          position: 'top-right',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(() => {
        return toast({
          title: 'Error creating filter set',
          status: 'error',
          position: 'top-right',
          duration: 4000,
          isClosable: true,
        });
      })
      .finally(() => {
        delay(() => {
          onClose();
          setIsLoading(false);
        }, 100);
      });
  };

  const initialFocusRef = useRef(null);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  return (
    <>
      <ActionModal
        header="Update filter set"
        isOpen={isModalOpen}
        onClose={onModalClose}
      >
        <ModalBody>
          <Text as="span">
            Are you sure you want to save this set as
            <Text fontWeight="semibold" as="span">
              {` ${nameInput}`}
            </Text>
            ? This will overwrite your existing set.
          </Text>
        </ModalBody>
        <ModalFooter>
          <ActionModalControlPanel
            onClose={onModalClose}
            onSubmit={handleUpdateFilterSet}
            includeReset={false}
          />
        </ModalFooter>
      </ActionModal>
      <FilterPopover
        title="Save Selections"
        showHeader
        headerProps={{ fontSize: 'sm' }}
        submitButtonText="Save"
        submitFunction={handleSubmitFilterSet}
        isSubmitDisabled={nameInput.length <= 0 || isLoading}
        openHandler={() => {
          setNameInput('');
        }}
        fpPaddingBottom={0}
        initialFocusRef={initialFocusRef}
        externalControl={{ isOpen, onOpen, onClose }}
        //  submitIsLoading={isLoading}
        closeOnBlur={!isModalOpen}
        triggerElement={
          <Tooltip
            label="Save selections"
            placement="auto"
            hasArrow
            arrowSize={6}
            variant="label"
            openDelay={500}
          >
            <Button
              aria-label="save selections"
              variant="link"
              color="#2D426A"
              height="24px"
              border="1px solid rgba(45, 66, 106, 0)"
              borderRadius="3px"
              _hover={{
                border: '1px solid rgba(45, 66, 106, 0.3)',
                transition: 'border-color 200ms',
              }}
              size="xs"
            >
              <Icon as={FiBookmark} boxSize={3.5} />
            </Button>
          </Tooltip>
        }
      >
        <FormControl isRequired>
          <Autocomplete
            inputId="filter-set-name"
            formLabel="Set Name"
            placeholder="Enter a set name"
            inputValue={nameInput}
            setInputValue={setNameInput}
            suggestions={suggestions}
            setSuggestions={setSuggestions}
            dropdownHeader="Matching Sets"
            initialFocusRef={initialFocusRef}
            comboBoxProps={{
              items: suggestions,
              inputValue: nameInput,
              onInputValueChange: ({ inputValue }: any) => {
                const filtered = allSavedSets.filter((set) => {
                  if (isEmpty(inputValue)) {
                    return false;
                  }

                  return set.name
                    .toLowerCase()
                    .trim()
                    .includes(inputValue.toLowerCase().trim());
                });

                setSuggestions(filtered);
                setNameInput(inputValue);
              },
              itemToString,
            }}
          />
        </FormControl>
      </FilterPopover>
    </>
  );
}

export default FilterSetSaveMenu;
