import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import { ActionModal, ActionModalControlPanel } from '@revelio/core';
import { useEffect, useState } from 'react';
import { UserSavedFilterSet } from '../../../engine/filters.savedset.models';
import { useMutation } from 'urql';
import { UpdateSavedSetMutation } from '../../../engine/gql-models/saved-sets.models';
import { delay } from 'lodash';

/* eslint-disable-next-line */
export interface NodeEditModalProps {
  node?: UserSavedFilterSet;
  isOpen: boolean;
  portalProps?: any;
  onClose: () => void;
  finalFocusRef?: any;
}

export function NodeEditModal({
  node,
  isOpen,
  onClose,
  portalProps,
  finalFocusRef,
}: NodeEditModalProps) {
  const [inputString, setInputString] = useState<string>('');

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, updatedSavedSet] = useMutation(UpdateSavedSetMutation);

  const toast = useToast();

  useEffect(() => {
    if (node && node.name) {
      setInputString(node.name);
    }
  }, [node]);

  return (
    <ActionModal
      header="Rename Filter Set"
      isOpen={isOpen}
      onClose={onClose}
      portalProps={portalProps}
      finalFocusRef={finalFocusRef}
      modalProps={{ isLazy: true }}
    >
      <ModalBody>
        <FormControl isRequired isInvalid={isError}>
          <FormLabel fontSize="sm">Set Name</FormLabel>
          <Input
            type="text"
            placeholder="Enter a set name"
            value={inputString}
            onChange={(e) => {
              if (isError) {
                setIsError(false);
              }
              setInputString(e.target.value);
            }}
          />

          <FormErrorMessage>
            Could not save changes to filter set.
          </FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ActionModalControlPanel
          onClose={onClose}
          submitIsLoading={isLoading}
          onSubmit={() => {
            if (node?.id) {
              setIsLoading(true);

              updatedSavedSet({ id: node?.id, name: inputString })
                .then((res) => {
                  if (res.error) {
                    toast({
                      title: 'Could not update saved set',
                      status: 'error',
                      duration: 4000,
                      position: 'top-right',
                    });

                    return;
                  }

                  toast({
                    title: 'Saved set updated',
                    status: 'success',
                    duration: 4000,
                    position: 'top-right',
                  });
                })
                .catch(() => {
                  toast({
                    title: 'Could not update saved set',
                    status: 'error',
                    duration: 4000,
                    position: 'top-right',
                  });
                })
                .finally(() => {
                  delay(() => {
                    setIsLoading(false);
                    onClose();
                  }, 500);
                });
            }
          }}
          submitIsDisabled={inputString.trim().length === 0}
          onReset={() => {
            setInputString(node?.name || '');
          }}
        />
      </ModalFooter>
    </ActionModal>
  );
}

export default NodeEditModal;
