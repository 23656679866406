import { useGetLoggedInUser } from '@revelio/auth';
import {
  upsertFilter,
  useSingleOrMoreFilterState,
} from '../../../engine/filters.engine';
import {
  FilterItem,
  LocalSelectionCategories,
  SelectFilter,
} from '../../../engine/filters.model';
import {
  SelectionList,
  SelectionListControls,
  SelectionListValue,
} from '../../selection-list';
import { TempSelections } from '../types';
import {
  getPostingsSourceOptions,
  UNIFIED_POSTING_SOURCE_OPTION,
} from './posting-source-options';

export const ProviderSelectionList = ({
  close,
  handleFilterSubmit,
}: {
  close: () => void;
  handleFilterSubmit: (selections?: TempSelections) => void;
}) => {
  const submitProviderSelection = (value: SelectionListValue) => {
    const selections: TempSelections = value.reduce<TempSelections>(
      (acc, item) => {
        const id = `${LocalSelectionCategories.PROVIDER}.${item.value}`;
        return {
          ...acc,
          [id]: {
            item: {
              id: Number(item.value),
              shortName: item.label,
              longName: item.label,
              label: item.label,
              isActive: true,
            },
            id,
            isMulti: true,
            children: [],
            selectionListId: LocalSelectionCategories.PROVIDER,
          },
        };
      },
      {}
    );

    handleFilterSubmit(selections);
    // filter chips don't show up for defaults on page load
    upsertFilter(LocalSelectionCategories.PROVIDER, {
      isUserSubmitted: true,
    });
    close();
  };

  const [data] = useSingleOrMoreFilterState<any, SelectFilter<FilterItem[]>>(
    LocalSelectionCategories.PROVIDER
  );

  const { loggedInUser } = useGetLoggedInUser();

  const unifiedPostingsSourceId = UNIFIED_POSTING_SOURCE_OPTION.value;
  return (
    <SelectionList
      defaultValue={data?.value.map((value) => ({
        value: `${value.id}`,
        label: value.label as string,
      }))}
      options={getPostingsSourceOptions(loggedInUser)}
      close={close}
      submitLabel={data?.isUserSubmitted ? 'Update' : 'Add'}
      submit={submitProviderSelection}
      onChangeOverride={(
        selectedOptions: SelectionListValue,
        controls: SelectionListControls
      ) => {
        const unifiedPostingsSourceOptionIndex = selectedOptions.findIndex(
          (option) => option.value === unifiedPostingsSourceId
        );
        if (unifiedPostingsSourceOptionIndex === -1) {
          // normal just setting value when anything but unified selected
          controls.setValue(selectedOptions);
        }

        const selectedUnifiedLast =
          unifiedPostingsSourceOptionIndex === selectedOptions.length - 1;
        if (selectedUnifiedLast) {
          // unified can't be selected with other posting sources
          controls.clearSelections();
          controls.setValue([UNIFIED_POSTING_SOURCE_OPTION]);
        } else {
          // if has unified selected and selects other source, it should remove unified
          controls.setValue(
            selectedOptions.filter(
              (option) => option.value !== unifiedPostingsSourceId
            )
          );
        }
      }}
    />
  );
};
