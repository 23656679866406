import { ContactSalesButton } from '@revelio/composed';
import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
  LocalSelectionCategories,
} from '@revelio/filtering';
import Postings, { providerFilterId } from '../../../postings/postings';
import { useFlag } from '@unleash/proxy-client-react';
import { View } from '@revelio/data-access';

const title = [PageTitles.COMPANY, PageTitles.JOB_POSTINGS];

const primaryViewFilters = [
  {
    filters: INDUSTRY_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 6,
  },
];

const otherFilters = [providerFilterId];

// const requiredParams = [SelectionCategories.DATE_RANGE_FULL];

export function CompanyPostings() {
  const keywordFiltersFeatureFlag = useFlag(FeatureFlag.CompanyPostingsKeyword);
  const titleFiltersFeatureFlag = useFlag(FeatureFlag.CompanyPostingsTitle);
  const savedFilterSetFeatureFlag = useFlag(FeatureFlag.SavedSets);

  const selectableFilters = [
    SelectionCategories.DATE_RANGE_FULL,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    ...(keywordFiltersFeatureFlag ? [SelectionCategories.KEYWORD] : []),
    ...(titleFiltersFeatureFlag ? [SelectionCategories.RAW_TITLE] : []),
    LocalSelectionCategories.METRIC_MODE,
    ...(savedFilterSetFeatureFlag
      ? [SelectionCategories.SAVED_FILTER_SET]
      : []),
  ];

  return (
    <Postings
      title={title}
      primaryView={PrimaryView.COMPANY}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_POSTINGS}
      // TODO: Re-enable when data range filter is fixed
      // requiredParams={requiredParams}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.POSTINGS}
      otherFilters={otherFilters}
      additionalNonActiveFilters={[providerFilterId]}
      isGqlQuery={true}
      isGoRequest={true}
      trialNoResultsMessage={
        <>
          Can't find a company?
          <ContactSalesButton
            buttonProps={{
              size: 'sm',
              variant: 'link',
              fontWeight: 400,
              paddingTop: 2,
              paddingLeft: 1,
            }}
            page={PageTitles.JOB_POSTINGS}
          />
        </>
      }
      savedSetView={View.Company}
    />
  );
}

export default CompanyPostings;
