import { startCase } from 'lodash';
import {
  SelectionCategories,
  SelectionListIdNames,
} from '../../engine/filters.model';
import { pluralize } from './pluralize';
import { filterLabelLookup } from '../../engine/filters.constants';

const formatFilterLabel = (
  unformatted: SelectionListIdNames,
  limit: number
) => {
  if (unformatted.slice(0, 16) === 'talentdiscovery_') {
    unformatted = unformatted.slice(16) as SelectionListIdNames;
  }

  const formatted = filterLabelLookup[unformatted as SelectionCategories]
    ? filterLabelLookup[unformatted as SelectionCategories]
    : unformatted;

  return limit > 1 ? pluralize(startCase(formatted)) : startCase(formatted);
};

export default formatFilterLabel;
