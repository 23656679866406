import { format as d3Format } from 'd3-format';

export const drawBarValueLabels = (chart, data, formatConfigs, scale) => {
  const { format, fontSize } = formatConfigs;
  const { xScale, yScale, metaValue } = scale;

  return chart
    .selectAll('text.bar')
    .data(data)
    .join('text')
    .attr('id', 'text')
    .attr('data-testid', 'horizontal-bar-chart-value-label')
    .attr('x', (d) => (d.value < 0 ? xScale(0) : xScale(d.value)))
    .attr('y', (d) => yScale(d.metadata[metaValue]) + yScale.bandwidth() / 2)
    .attr('text-anchor', 'start')
    .attr('alignment-baseline', 'middle')
    .attr('dx', '0.4em')
    .attr('dy', '0.1em')
    .attr('font-family', 'Source Sans Pro, sans-serif')
    .style('fill', '#636d7e')
    .style('font-size', `${fontSize}px`)
    .text((d) => {
      const value = d.value ? d.value / 100 : 0;

      if (value === 0) {
        return null;
      }

      return d3Format(format)(value);
    });
};
