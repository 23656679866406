import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from 'react';

interface ChartsRenderedContextType {
  chartsRendered: { [key: string]: boolean };
  setChartHasRendered: (chartName: string, rendered: boolean) => void;
  allChartsRendered: boolean;
}

const ChartsRenderedContext = createContext<
  ChartsRenderedContextType | undefined
>(undefined);

const ChartsRenderedProvider: React.FC<{
  children: ReactNode;
  chartNames: string[];
  chartRenderingTimeout?: number;
}> = ({ children, chartNames, chartRenderingTimeout = 0 }) => {
  const initialChartsRendered = chartNames.reduce(
    (acc, chartName) => {
      acc[chartName] = false;
      return acc;
    },
    {} as { [key: string]: boolean }
  );

  const [chartsRendered, setChartsRendered] = useState(initialChartsRendered);
  const [allChartsRendered, setAllChartsRendered] = useState(false);

  // Update the rendering status of a specific chart
  const setChartRendered = useCallback(
    (chartName: string, rendered: boolean) => {
      setChartsRendered((prev) => {
        if (prev[chartName] !== rendered) {
          return { ...prev, [chartName]: rendered };
        }

        return prev;
      });
    },
    []
  );

  // Check if all charts have rendered
  useEffect(() => {
    const allRendered = Object.values(chartsRendered).every(
      (rendered) => rendered
    );

    if (allRendered !== allChartsRendered) {
      setTimeout(
        () => setAllChartsRendered(allRendered),
        chartRenderingTimeout
      );
    }
  }, [chartsRendered, allChartsRendered, chartRenderingTimeout]);

  return (
    <ChartsRenderedContext.Provider
      value={{
        chartsRendered,
        setChartHasRendered: setChartRendered,
        allChartsRendered,
      }}
    >
      {children}
    </ChartsRenderedContext.Provider>
  );
};

export { ChartsRenderedContext, ChartsRenderedProvider };
