export enum KIBANA_LOG_LEVEL {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

interface KibanaLogMeta {
  source: string;
  context?: string;
  url?: string;
  username?: string;
}

export interface KibanaLog {
  '@timestamp': string;
  level: KIBANA_LOG_LEVEL;
  message: string;
  meta: KibanaLogMeta;
  'request-id'?: string;
}

type KibanaLogFlat = Omit<KibanaLog, 'meta'> & KibanaLogMeta;

export type LogBody = Omit<KibanaLogFlat, '@timestamp'>;

export interface LogResponse {
  success: boolean;
  message: string;
}
