import { FilterItem } from '../../engine/filters.model';
import { FilterChipTreeItemDataProps, FilterTreeItemData } from '../collection';

type ClearTreeItemDataProps = {
  clearAll: true;
};

type FilterOrClearTreeItemData = (
  props: ClearTreeItemDataProps | FilterChipTreeItemDataProps
) => FilterTreeItemData;

export const filterTreeItemData: FilterOrClearTreeItemData =
  (props) => (data) => {
    if (props.clearAll) {
      return Object.entries(data).reduce((acc, [key, value]) => {
        if (
          ['skill_k75', 'skill_k700', 'skill_k3000'].includes(
            value.selectionListId
          )
        ) {
          return acc;
        }

        return { ...acc, [key]: value };
      }, {});
    }
    if (!props.clearAll) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filterSelections: any = props.filterSelections;

      return Object.entries(data).reduce((acc, [key, value]) => {
        if (value.selectionListId === 'skill_k75') {
          if (
            filterSelections.value?.skill_k75
              ?.map((skill: FilterItem) => skill.id)
              .includes(value?.item?.id)
          ) {
            return { ...acc, [key]: value };
          }
          return acc;
        }
        if (value.selectionListId === 'skill_k700') {
          if (
            filterSelections.value?.skill_k700
              ?.map((skill: FilterItem) => skill.id)
              .includes(value?.item?.id)
          ) {
            return { ...acc, [key]: value };
          }
          return acc;
        }
        if (value.selectionListId === 'skill_k3000') {
          if (
            filterSelections.value?.skill_k3000
              ?.map((skill: FilterItem) => skill.id)
              .includes(value?.item?.id)
          ) {
            return { ...acc, [key]: value };
          }
          return acc;
        }

        return acc;
      }, {});
    }

    return data;
  };
