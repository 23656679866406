/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { Observable, ObservableInput } from 'rxjs';
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import {
  AdditionalOperatorsBeforeQueryFnObservableArgs,
  FilterList,
  LocalSelectionCategories,
  OtherFilterNames,
  RequestMethod,
  SelectFilter,
  SelectionCategories,
  SelectionListIdNames,
  SerializedFiltersForQuery,
  SubFilterNames,
  ValidValueTypes,
} from '../engine/filters.model';
import { PlacementWithLogical } from '@chakra-ui/react';
import { Views } from '@revelio/core';
import { SubFilterProps } from '../filter-components/sub-filter/sub-filter';
import { get } from 'lodash';
import { Client } from 'urql';

export enum ViewsForDefaultsOnly {
  SENTIMENT_REVIEWS = 'sentiment_reviews',
  SENTIMENT_EFFECTS = 'sentiment_effects',
  SENTIMENT_SINGLE = 'sentiment_single',
  SENTIMENT_COMPANY_SINGLE = 'sentiment_company_single',
  SENTIMENT_MSA_SINGLE = 'sentiment_msa_single',
  SENTIMENT_ROLE_SINGLE = 'sentiment_role_single',
  COMPANY_POSTING = 'posting_company',
  KEYWORD_POSTING = 'posting_keyword',
  ROLE_OVERVIEW = 'job_analysis_overview_role',
  GEO_OVERVIEW = 'job_analysis_overview_geo',
}

export enum FilterFormatOverrides {
  PRESETS = 'presets',
}

export enum SharedFilterSets {
  COMPANY_SHARED = 'company_shared',
}

export enum FilterSets {
  // COMPANY
  COMPANY = 'company',
  COMPANY_SUMMARY = 'company_summary',
  COMPANY_OVERVIEW = 'company_overview',
  COMPANY_POSTINGS = 'company_postings',
  COMPANY_TRANSITIONS = 'company_transitions',
  COMPANY_RATINGS = 'company_ratings',
  COMPANY_SCREENER = 'company_screener',
  COMPANY_LAYOFF = 'company_layoff',
  COMPANY_SKILL = 'company_skill',
  COMPANY_SENTIMENT_RATINGS = 'company_sentiment_ratings',
  COMPANY_SENTIMENT_EFFECTS = 'company_sentiment_effects',
  COMPANY_SENTIMENT_REVIEWS = 'company_sentiment_reviews',
  COMPANY_SENTIMENT = 'company_sentiment',
  COMPANY_COMPENSATION = 'company_compensation',

  // SKILLS
  SKILLS_POSTINGS = 'skills_postings',

  // GEO
  GEOGRAPHY = 'geography',
  GEOGRAPHY_SUMMARY = 'geography_summary',
  GEOGRAPHY_COMPOSITIONS = 'geography_compositions',
  GEOGRAPHY_SENTIMENT = 'geography_sentiment',
  GEOGRAPHY_SENTIMENT_RATINGS = 'geography_sentiment_ratings',
  GEOGRAPHY_SENTIMENT_EFFECTS = 'geography_sentiment_effects',
  GEOGRAPHY_SENTIMENT_REVIEWS = 'geography_sentiment_reviews',
  GEOGRAPHY_POSTINGS = 'geography_postings',
  GEOGRAPHY_TRANSITIONS = 'geography_transitions',
  GEOGRAPHY_SKILLS = 'geography_skills',
  GEOGRAPHY_SCREENER = 'geography_screener',
  GEOGRAPHY_COMPENSATION = 'geography_compensation',

  // ROLE
  ROLE = 'role',
  ROLE_SUMMARY = 'role_summary',
  ROLE_COMPOSITIONS = 'role_compositions',
  ROLE_SENTIMENT_RATINGS = 'role_sentiment_ratings',
  ROLE_SENTIMENT_EFFECTS = 'role_sentiment_effects',
  ROLE_SENTIMENT_REVIEWS = 'role_sentiment_reviews',
  ROLE_POSTINGS = 'role_postings',
  ROLE_TRANSITIONS = 'role_transitions',
  ROLE_SKILLS = 'role_skills',
  ROLE_SCREENER = 'role_screener',
  ROLE_SENTIMENT = 'role_sentiment',
  ROLE_COMPENSATION = 'role_compensation',

  // OTHER
  TALENT_DISCOVERY = 'talent_discovery',
  GLOBAL = 'global',
  TAXONOMY = 'taxonomy',
  NONE = 'none',
}

export enum ViewTypes {
  SNAPSHOT = 'snapshot',
  OVERTIME = 'overtime',
  GEO = 'geo',
  ROLE = 'role',
  COMPANY = 'company',
  COMPANY_RATINGS = 'company_ratings',
  COMPANY_EFFECTS = 'company_effects',
  COMPANY_REVIEWS = 'company_reviews',
  KEYWORD = 'keyword',
  RATING = 'rating',
  EFFECT = 'score',
  REVIEW = 'review',
  REVIEWS = 'reviews',
  METRIC = 'metric',
  METRIC_GROUPED = 'metric_grouped',
  SHARES_OVERTIME = 'shares_overtime',
  SHARES_SNAPSHOT = 'shares_snapshot',
  SHARES_SANKEY = 'shares_sankey',
  SHARES_TRANSITION = 'shares_transition',
  SCORE = 'score',
  SHARES = 'shares',
  SKILLS = 'skills',
  WEIGHT = 'weight',
  TOP_RESULTS = 'top_results',
  NONE = '',
  BASE_SALARY = 'base_salary',
  TOTAL_COMP = 'total_comp',
}

export const getPrimaryDataView = (viewType: ViewTypes) =>
  get(
    {
      [ViewTypes.GEO]: 'geography',
      [ViewTypes.COMPANY_RATINGS]: 'company',
      [ViewTypes.COMPANY_EFFECTS]: 'company',
      [ViewTypes.COMPANY_REVIEWS]: 'company',
    },
    viewType,
    viewType
  );

export type CompositionSupportedViewTypes =
  | ViewTypes.COMPANY
  | ViewTypes.GEO
  | ViewTypes.ROLE;

export enum EndpointSegment {
  TRANSITION = 'transition',
  UNIVERSITY = 'university',
  ROLE = 'role',
  GEO = 'geo',
  INDUSTRY = 'industry',
  SKILL = 'skill',
  SENIORITY = 'seniority',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  EDUCATION = 'education',
  HEADCOUNT = 'headcount',
  GROWTH = 'growth',
  GROWTH_GROUPED = 'growth_grouped',
  HIRING = 'hiring',
  ATTRITION = 'attrition',
  TENURE = 'tenure',
  SALARY = 'salary',
  TIMETOFILL = 'timetofill',
  KEYWORD = 'keyword',
  JOBPOSTING = 'jobposting',
  POSTING = 'posting',
  POSTING_MULTI = 'posting_multi',
  NUMBEROFCOMPANIES = 'numberofcompanies',
  TOP = 'top',
  TOPCOMPANIES = 'topcompanies',
  OVERALL = 'overall',
  BUSINESS = 'business',
  CAREER = 'career',
  COMPENSATION = 'compensation',
  WORKLIFE = 'worklife',
  LEADERSHIP = 'leadership',
  CULTURE = 'culture',
  DIVERSITY = 'diversity',
  RECOMMEND = 'recommend',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  QUALIFICATION = 'qualification',
  TASK = 'task',
  BROAD_ACTIVITY = 'broad_activity',
  TAXONOMY_LANDSCAPE = 'taxonomy_landscape',
  TAXONOMY_HIERARCHY = 'taxonomy_hierarchy',
  JOB = 'job',
  ACTIVITY = 'activity',
  WORD = 'word',
  EFFECT = 'score',
  STATS = 'stats',
  TALENT_DISCOVERY = 'talent_discovery',
  METRIC_GROUPED = 'metric_grouped',
  SHARES_SNAPSHOT = 'shares_snapshot',
  SHARES_OVERTIME = 'shares_overtime',
  KEYWORD_OVERTIME = 'keyword_overtime',
  TOP_GEOGRAPHIES = 'top_geographies',
  TOP_COMPANIES = 'top_companies',
  TOP_ROLES = 'top_roles',
  OVERTIME = 'overtime',
  DISTRIBUTION = 'distribution',
}

export enum EndpointToName {
  GEO = 'Geography',
  GROWTH = 'Growth Rate',
  HIRING = 'Hiring Rate',
  ATTRITION = 'Attrition Rate',
  TIMETOFILL = 'Time to Fill',
  NUMBEROFCOMPANIES = 'Number of Companies',
  TOPCOMPANIES = 'Top Companies',
  BUSINESS = 'Business Outlook',
  CAREER = 'Career Opportunities',
  COMPENSATION = 'Compensation & Benefits',
  WORKLIFE = 'Work-Life Balance',
  LEADERSHIP = 'Senior Leadership',
  CULTURE = 'Culture & Values',
  DIVERSITY = 'Diversity & Inclusion',
  RECOMMEND = 'Recommend to a Friend',
  JOBPOSTING = 'Job Posting',
  TAXONOMY_LANDSCAPE = 'Landscape',
  TAXONOMY_HIERARCHY = 'Hierarchy',
  SHARES_SNAPSHOT = 'Top Skills',
  SHARES_OVERTIME = 'Skill Trend',
  KEYWORD_OVERTIME = 'Postings',
  GROWTH_GROUPED = 'Growth',
  TOP_GEOGRAPHIES = 'Top Geographies',
  TOP_COMPANIES = 'Top Companies',
  TOP_ROLES = 'Top Roles',
  OVERTIME = 'Over Time',
  DISTRIBUTION = 'Distribution',
  POSTING = 'Postings',
}

export const EndpointToNameLookup: { [key: string]: EndpointToName } = {
  [EndpointSegment.GEO]: EndpointToName.GEO,
  [EndpointSegment.GROWTH]: EndpointToName.GROWTH,
  [EndpointSegment.HIRING]: EndpointToName.HIRING,
  [EndpointSegment.ATTRITION]: EndpointToName.ATTRITION,
  [EndpointSegment.TIMETOFILL]: EndpointToName.TIMETOFILL,
  [EndpointSegment.NUMBEROFCOMPANIES]: EndpointToName.NUMBEROFCOMPANIES,
  [EndpointSegment.TOPCOMPANIES]: EndpointToName.TOPCOMPANIES,
  [EndpointSegment.BUSINESS]: EndpointToName.BUSINESS,
  [EndpointSegment.CAREER]: EndpointToName.CAREER,
  [EndpointSegment.COMPENSATION]: EndpointToName.COMPENSATION,
  [EndpointSegment.WORKLIFE]: EndpointToName.WORKLIFE,
  [EndpointSegment.LEADERSHIP]: EndpointToName.LEADERSHIP,
  [EndpointSegment.CULTURE]: EndpointToName.CULTURE,
  [EndpointSegment.DIVERSITY]: EndpointToName.DIVERSITY,
  [EndpointSegment.RECOMMEND]: EndpointToName.RECOMMEND,
  [EndpointSegment.JOBPOSTING]: EndpointToName.JOBPOSTING,
  [EndpointSegment.TAXONOMY_LANDSCAPE]: EndpointToName.TAXONOMY_LANDSCAPE,
  [EndpointSegment.TAXONOMY_HIERARCHY]: EndpointToName.TAXONOMY_HIERARCHY,
  [EndpointSegment.SHARES_SNAPSHOT]: EndpointToName.SHARES_SNAPSHOT,
  [EndpointSegment.SHARES_OVERTIME]: EndpointToName.SHARES_OVERTIME,
  [EndpointSegment.KEYWORD_OVERTIME]: EndpointToName.KEYWORD_OVERTIME,
  [EndpointSegment.GROWTH_GROUPED]: EndpointToName.GROWTH_GROUPED,
  [EndpointSegment.TOP_GEOGRAPHIES]: EndpointToName.TOP_GEOGRAPHIES,
  [EndpointSegment.TOP_COMPANIES]: EndpointToName.TOP_COMPANIES,
  [EndpointSegment.TOP_ROLES]: EndpointToName.TOP_ROLES,
  [EndpointSegment.OVERTIME]: EndpointToName.OVERTIME,
  [EndpointSegment.DISTRIBUTION]: EndpointToName.DISTRIBUTION,
  [EndpointSegment.POSTING]: EndpointToName.POSTING,
};

export type PrimaryDataView = 'company' | 'geography' | 'role';
export type PageGroupName =
  | 'compositions'
  | 'postings'
  | 'transitions'
  | 'sentiment'
  | 'compensation';

export interface BuildRequestConfig {
  name?: string;
  endpoint: string;
  gqlDataEndpoint?: string;
  viewsIncluded: (Views | [Views, ViewTypes])[];
}

export interface RequestConfig {
  url?: URL | string;
  endpoint?: EndpointSegment;
  view?: Views;
  viewType?: ViewTypes;
  primaryDataView?: PrimaryDataView;
  pageGroupName?: PageGroupName;
}

export interface SubfilterConfigs extends SubFilterProps {
  filterName: SubFilterNames | LocalSelectionCategories;
  selectionLists: (SelectionCategories | LocalSelectionCategories)[];
  subfiltersMap?: {
    [key in SelectionCategories]?: SelectionCategories;
  };
  default?: Partial<SelectFilter<FilterList<ValidValueTypes>>>;
  placement?: PlacementWithLogical;
  disableParentSelect?: boolean;
  disableChildren?: boolean;
}

export interface DataFetcherArgs {
  gqlClient: Client;
  url?: URL;
  endpoint?: CalculatedEndpoint;
  includeInGlobalLoader: boolean;
  requestMethod: RequestMethod;
  requestHash: string;
  filters: SerializedFiltersForQuery;
  isGoRequest: boolean;
  abortController: AbortController;
}

export type DataFetcher<T> = (args: DataFetcherArgs) => ObservableInput<T>;

export type PlotConfig = {
  dataFetcher?: DataFetcher<unknown>;
  url?: URL | string;
  endpoint?: EndpointSegment;
  view?: Views;
  viewType?: ViewTypes;
  chartProps?: Partial<ID3ChartProps> | undefined;
  chartType?: D3ChartNames;
  subfilters?: SubfilterConfigs;
  metaData?: {
    pageGroupName?: PageGroupName;
    primaryDataView?: PrimaryDataView;
    includeDiagnosticsModal?: boolean;
    isGqlQuery?: boolean;
    isGoRequest?: boolean;
    additionalFilters?: (
      | SelectionCategories
      | OtherFilterNames
      | LocalSelectionCategories
    )[];
    requiredParams?: (SelectionCategories | LocalSelectionCategories)[];
    compensationPlotType?: string;
    disableDownload?: boolean;
    [key: string]: unknown;
  };
  additionalNonActiveFilters?: (
    | SelectionCategories
    | SubFilterNames
    | OtherFilterNames
    | LocalSelectionCategories
  )[];
  brokenOutFilterIds?: (
    | SelectionCategories
    | SubFilterNames
    | OtherFilterNames
    | LocalSelectionCategories
  )[];
};
export type PlotAdditionalQueryParams = Omit<
  PlotConfig,
  'endpoint' | 'dataFetcher'
> &
  AdditionalOperatorsBeforeQueryFnObservableArgs;
export type PlotConfigParams = PlotConfig & {
  preFetchConfig?: PlotConfig;
};

export interface CalculatedEndpoint {
  url?: URL;
  endpointPath: string;
  downloadEndpointPath: string;
  goDownloadEndpointPath?: string;
  name?: EndpointSegment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

export interface PlotConfigProvider extends CalculatedEndpoint {
  chartType: D3ChartNames;
  chartProps: Omit<ID3ChartProps, 'data'>;
  subfilters?: {
    filterName?: string | SelectionListIdNames;
    selectionLists: SelectionListIdNames[];
  };
  metaData?: PlotConfig['metaData'];
}

export const TransitionsDownloadFlowTypePlaceholder = 'flowType';

export type EndpointMapper = Observable<CalculatedEndpoint>;
export type DownloadEndpointMapper = Observable<
  CalculatedEndpoint | string | RequestConfig
>;
export type PlotConfigMapper = Observable<
  Pick<PlotConfigProvider, 'chartType' | 'chartProps'>
>;

export type IChartPropsMap = {
  [key in EndpointSegment]?: Partial<ID3ChartProps>;
};

/**
 * NOTE: We are currently keying the sets in the format: `${view}_${viewType}`.
 * The following DefaultKeyLookup table maps a preset view to a view filter
 * default entity key.
 */
export const DefaultKeyLookup: Record<string, string> = {
  // COMPANY
  [FilterSets.COMPANY_OVERVIEW]: `${Views.OVERVIEW}_${ViewTypes.COMPANY}`,
  [FilterSets.COMPANY_TRANSITIONS]: `${Views.TRANSITION}_${ViewTypes.COMPANY}`,
  [FilterSets.COMPANY_POSTINGS]: `${Views.POSTING}_${ViewTypes.COMPANY}`,
  [FilterSets.COMPANY_SKILL]: `${Views.SKILL}_${ViewTypes.COMPANY}`,
  [FilterSets.COMPANY_SENTIMENT_RATINGS]: `${Views.SENTIMENT}_${ViewTypes.COMPANY_RATINGS}`,
  [FilterSets.COMPANY_SENTIMENT_EFFECTS]: `${ViewsForDefaultsOnly.SENTIMENT_EFFECTS}_${ViewTypes.COMPANY_EFFECTS}`,
  [FilterSets.COMPANY_SENTIMENT_REVIEWS]: `${ViewsForDefaultsOnly.SENTIMENT_REVIEWS}_${ViewTypes.COMPANY_REVIEWS}`,

  // GEOGRAPHY
  [FilterSets.GEOGRAPHY]: `${Views.OVERVIEW}_${ViewTypes.GEO}`,
  [FilterSets.GEOGRAPHY_TRANSITIONS]: `${Views.TRANSITION}_${ViewTypes.GEO}`,
  [FilterSets.GEOGRAPHY_POSTINGS]: `${Views.POSTING}_${ViewTypes.GEO}`,
  [FilterSets.GEOGRAPHY_SKILLS]: `${Views.SKILL}_${ViewTypes.GEO}`,
  [FilterSets.GEOGRAPHY_SENTIMENT_RATINGS]: `${Views.SENTIMENT}_${ViewTypes.GEO}`,
  [FilterSets.GEOGRAPHY_SENTIMENT_EFFECTS]: `${ViewsForDefaultsOnly.SENTIMENT_EFFECTS}_${ViewTypes.GEO}`,
  [FilterSets.GEOGRAPHY_SENTIMENT_REVIEWS]: `${ViewsForDefaultsOnly.SENTIMENT_REVIEWS}_${ViewTypes.GEO}`,

  // SKILL
  [FilterSets.SKILLS_POSTINGS]: `${Views.POSTING}_${ViewTypes.SKILLS}`,

  // ROLE
  [FilterSets.ROLE]: `${Views.OVERVIEW}_${ViewTypes.ROLE}`,
  [FilterSets.ROLE_TRANSITIONS]: `${Views.TRANSITION}_${ViewTypes.ROLE}`,
  [FilterSets.ROLE_POSTINGS]: `${Views.POSTING}_${ViewTypes.ROLE}`,
  [FilterSets.ROLE_SKILLS]: `${Views.SKILL}_${ViewTypes.ROLE}`,
  [FilterSets.ROLE_SENTIMENT_RATINGS]: `${Views.SENTIMENT}_${ViewTypes.ROLE}`,
  [FilterSets.ROLE_SENTIMENT_EFFECTS]: `${ViewsForDefaultsOnly.SENTIMENT_EFFECTS}_${ViewTypes.ROLE}`,
  [FilterSets.ROLE_SENTIMENT_REVIEWS]: `${ViewsForDefaultsOnly.SENTIMENT_REVIEWS}_${ViewTypes.ROLE}`,
};
