import { textColor } from './colors';

/**
 * positions a title in the chart for png download
 *
 * @param chart - d3 selection to prepend to
 * @param title - title of the chart
 * @param dims - dimensions of chart area
 * @param padding - the width of the padding around the chart
 *
 */
export const appendTitle = (chart, title, dims, padding) => {
  const width =
    dims.innerWidth + dims.margin.left + dims.margin.right + 2 * padding;
  chart
    .append('text')
    .attr('x', width / 2 - dims.margin.left - (padding || 0))
    .attr('y', -1 * padding - 20)
    .attr('text-anchor', 'middle')
    .style('font-size', '16px')
    .style('font-family', 'Source Sans Pro, sans-serif')
    .style('font-weight', '600')
    .style('fill', textColor)
    .text(title);
};
