import { chakra, HTMLChakraProps } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg width="9" height="14" viewBox="0 0 9 14" fill="none" {...props}>
      <path
        d="M6.07542 5.94287C5.77841 6.09527 5.44281 6.15599 5.11122 6.11731C4.77963 6.07864 4.46702 5.94231 4.21305 5.72563C3.95909 5.50896 3.77523 5.22172 3.68481 4.90036C3.5944 4.579 3.60151 4.23803 3.70523 3.92072C3.80896 3.60341 4.00463 3.32407 4.2674 3.11817C4.53018 2.91227 4.8482 2.78908 5.18111 2.76426C5.51402 2.73943 5.8468 2.81408 6.13721 2.97873C6.42761 3.14337 6.66255 3.3906 6.8122 3.68901C6.91218 3.88557 6.97243 4.09991 6.98951 4.31977C7.00659 4.53964 6.98016 4.76071 6.91174 4.97035C6.84331 5.17999 6.73422 5.37409 6.59072 5.54153C6.44722 5.70898 6.27211 5.8465 6.07542 5.94622V5.94287ZM4.27702 0.508606C5.65568 0.00570172 7.23193 0.0330517 8.41189 1.05672C8.66108 1.26186 8.85697 1.52421 8.98289 1.8214C9.10178 2.20653 8.56482 1.78233 8.4917 1.72763C8.11958 1.47323 7.71625 1.26782 7.29166 1.11644C4.96747 0.404788 2.76831 1.70028 1.40975 3.71469C0.856119 4.60312 0.43352 5.56675 0.154998 6.57583C0.129394 6.69948 0.0894963 6.81973 0.0361097 6.93417C-0.0236136 7.04803 0.00875987 6.62885 0.00875987 6.6149C0.0586042 6.19309 0.138816 5.77543 0.248769 5.36517C0.878376 3.16992 2.27322 1.34194 4.27702 0.504698V0.508606ZM4.29768 12.2261V7.33493C4.43666 7.34888 4.56894 7.35558 4.70848 7.35558C5.34195 7.35756 5.96367 7.1847 6.50521 6.85602V12.2255C6.50521 12.6843 6.42148 13.0237 6.21496 13.2447C6.11267 13.3569 5.98719 13.4455 5.84724 13.5043C5.70729 13.563 5.55621 13.5907 5.40451 13.5852C5.25493 13.5906 5.10601 13.5629 4.96834 13.5041C4.83068 13.4454 4.70765 13.357 4.60801 13.2453C4.40261 13.0198 4.29544 12.6799 4.29544 12.2267L4.29768 12.2261Z"
        fill="currentColor"
      />
    </chakra.svg>
  );
};
