import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Heading, List, ListIcon, ListItem, VStack } from '@chakra-ui/layout';
import { DataInterface } from './file-upload.types';

const ConfirmationMappedColumns = ({
  mappedColumns,
}: {
  mappedColumns: DataInterface['mappedColumns'];
}) => (
  <VStack align="start" spacing={3}>
    <Heading as="h2" size="sm" color="navyBlue.500" fontWeight={600}>
      Mapped Columns
    </Heading>
    <List spacing={3}>
      {mappedColumns?.map((item, index) => {
        const itemKey: string = Object.keys(item)[0];
        const itemValue = item[itemKey as keyof typeof item];
        return (
          <ListItem key={`${itemValue}-${index}`} color="navyBlue.500">
            <ListIcon as={CheckIcon} color="green.500" />
            {itemKey} <ArrowForwardIcon color="text.primary" /> {itemValue}
          </ListItem>
        );
      })}
    </List>
  </VStack>
);

export default ConfirmationMappedColumns;
