export const formatIntegerToDecimal = (
  value: number,
  {
    decimals,
    forceProvidedDecimals,
  }: { decimals?: number; forceProvidedDecimals?: boolean } = {
    decimals: 0,
    forceProvidedDecimals: true,
  }
): string => {
  const formattedDecimals = (() => {
    if (forceProvidedDecimals) {
      return decimals ?? 0;
    }

    if (Math.abs(value) > 10) {
      return 0;
    }

    return decimals;
  })();

  return value.toLocaleString(undefined, {
    minimumFractionDigits: formattedDecimals,
    maximumFractionDigits: formattedDecimals,
  });
};
