import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Machine = (props: any) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1280 720"
        height="94%"
        style={{
          zIndex: 4,
          overflow: 'visible',
        }}
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="635.92"
            y1="1074.89"
            x2="635.92"
            y2="1119.68"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1e262a" />
            <stop offset={1} stopColor="#2c3442" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="228.15"
            y1="357.47"
            x2="178.97"
            y2="357.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1e262a" />
            <stop offset={1} stopColor="#44485a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="-282.78"
            y1="357.47"
            x2="-331.96"
            y2="357.47"
            gradientTransform="translate(768.29) rotate(-180) scale(1 -1)"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-4"
            x1={640}
            y1="-17.8"
            x2={640}
            y2="-9.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1e262a" />
            <stop offset=".21" stopColor="#3a4658" />
            <stop offset=".36" stopColor="#626c76" />
            <stop offset=".53" stopColor="#dae0e8" />
            <stop offset=".68" stopColor="#626c76" />
            <stop offset=".87" stopColor="#3a4658" />
            <stop offset={1} stopColor="#1e262a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1="1112.16"
            y1="445.85"
            x2="1112.16"
            y2="876.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#1e262a" />
            <stop offset=".21" stopColor="#3a4658" />
            <stop offset=".39" stopColor="#808e98" />
            <stop offset=".49" stopColor="#808e98" />
            <stop offset=".64" stopColor="#3e4a5b" />
            <stop offset=".87" stopColor="#3a4658" />
            <stop offset={1} stopColor="#1e262a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="167.84"
            y1="445.85"
            x2="167.84"
            y2="876.83"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-7"
            x1="247.68"
            y1="479.72"
            x2="761.38"
            y2="451.27"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#646b82" />
            <stop offset=".21" stopColor="#8f99ab" />
            <stop offset=".36" stopColor="#b4bbc9" />
            <stop offset=".56" stopColor="#fff" />
            <stop offset=".7" stopColor="#acb4c3" />
            <stop offset=".82" stopColor="#929cae" />
            <stop offset={1} stopColor="#646b82" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-8"
            x1={159}
            y1="406.33"
            x2={159}
            y2="915.11"
            xlinkHref="#linear-gradient-7"
          />
          <linearGradient
            id="linear-gradient-9"
            x1="233.08"
            y1="216.27"
            x2="746.79"
            y2="187.82"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#646b82" />
            <stop offset=".21" stopColor="#8f99ab" />
            <stop offset=".36" stopColor="#b4bbc9" />
            <stop offset=".54" stopColor="#e8e6ff" />
            <stop offset=".7" stopColor="#acb4c3" />
            <stop offset=".82" stopColor="#929cae" />
            <stop offset={1} stopColor="#646b82" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-10"
            x1={1121}
            y1="406.33"
            x2={1121}
            y2="915.11"
            xlinkHref="#linear-gradient-7"
          />
          <clipPath id="clippath">
            <path
              d="m239.57,45.53h800.49c7.14,0,12.94,5.8,12.94,12.94v485.44c0,7.14-5.8,12.94-12.94,12.94H239.57c-7.14,0-12.94-5.8-12.94-12.94V58.47c0-7.14,5.8-12.94,12.94-12.94Z"
              fill="none"
              strokeWidth={0}
            />
          </clipPath>
          <radialGradient
            id="radial-gradient"
            cx="518.66"
            cy="3237.27"
            fx="518.66"
            fy="3237.27"
            r="139.79"
            gradientTransform="translate(-642.9 -647.13) scale(2.47 .38)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#a480ff" />
            <stop offset=".08" stopColor="#9171e1" />
            <stop offset=".27" stopColor="#654f9e" />
            <stop offset=".46" stopColor="#413365" />
            <stop offset=".63" stopColor="#251c39" />
            <stop offset=".78" stopColor="#100d1a" />
            <stop offset=".91" stopColor="#040306" />
            <stop offset={1} stopColor="#000" />
          </radialGradient>
          <linearGradient
            id="linear-gradient-11"
            x1="640.08"
            y1="565.48"
            x2="640.08"
            y2="912.71"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#424a66" />
            <stop offset=".12" stopColor="#717e95" />
            <stop offset=".24" stopColor="#9aa3b6" />
            <stop offset=".26" stopColor="#a2aabc" />
            <stop offset=".29" stopColor="#bac0cd" />
            <stop offset=".33" stopColor="#e0e3e8" />
            <stop offset=".36" stopColor="#fff" />
            <stop offset=".38" stopColor="#fff" />
            <stop offset=".45" stopColor="#acb4c3" />
            <stop offset=".53" stopColor="#929cae" />
            <stop offset=".55" stopColor="#8c96a8" />
            <stop offset=".67" stopColor="#6c7588" />
            <stop offset=".79" stopColor="#545d70" />
            <stop offset=".91" stopColor="#464e62" />
            <stop offset={1} stopColor="#424a5e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-12"
            x1="908.7"
            y1="-29.83"
            x2="1115.76"
            y2="276.81"
            xlinkHref="#linear-gradient-7"
          />
          <filter
            id="luminosity-noclip"
            x="119.63"
            y="5.19"
            width="1040.75"
            height="813.28"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <filter
            id="luminosity-noclip-2"
            x="119.63"
            y={-8552}
            width="1040.75"
            height={32766}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <mask
            id="mask-1"
            x="119.63"
            y={-8552}
            width="1040.75"
            height={32766}
            maskUnits="userSpaceOnUse"
          />
          <linearGradient
            id="linear-gradient-13"
            x1={640}
            y1="50.69"
            x2={640}
            y2="765.77"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} stopColor="#000" />
          </linearGradient>
          <mask
            id="mask"
            x="119.63"
            y="5.19"
            width="1040.75"
            height="813.28"
            maskUnits="userSpaceOnUse"
          >
            <g filter="url(#luminosity-noclip)">
              <g mask="url(#mask-1)">
                <path
                  d="m127.7,724.6c-6.13-22.72-5.75-46.62,1.12-69.13l14.85-48.66c8.76-28.72,14.34-58.53,16.6-88.63l30.49-458.78c1.88-28.3,25.57-50.47,53.96-50.47l395.28.19,395.3-.19c28.36,0,52.06,22.17,53.94,50.47l30.49,458.81c2.25,30.07,7.84,59.88,16.6,88.6l14.85,48.66c6.87,22.5,7.25,46.41,1.12,69.13l-18.79,87.01h3.83l18.57-86.04c6.32-23.4,5.92-48.01-1.15-71.2l-14.85-48.66c-8.68-28.45-14.21-57.99-16.44-87.79l-30.49-458.78c-2.01-30.27-27.34-53.97-57.68-53.97h-.03l-395.28.19-395.28-.19h-.03c-30.33,0-55.66,23.7-57.68,53.97l-30.49,458.78c-2.23,29.8-7.76,59.33-16.44,87.79l-14.85,48.66c-7.07,23.19-7.47,47.8-1.15,71.2l20.05,92.88h3.83l-20.22-93.68-.05-.19Z"
                  fill="url(#linear-gradient-13)"
                  mix-blend-mode="multiply"
                  strokeWidth={0}
                />
              </g>
            </g>
          </mask>
          <linearGradient
            id="linear-gradient-14"
            x1={640}
            y1="50.69"
            x2={640}
            y2="765.77"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} stopColor="#fff" />
          </linearGradient>
          <filter
            id="luminosity-noclip-3"
            x="149.6"
            y="35.16"
            width="980.8"
            height="774.35"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <filter
            id="luminosity-noclip-4"
            x="149.6"
            y={-8552}
            width="980.8"
            height={32766}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <mask
            id="mask-3"
            x="149.6"
            y={-8552}
            width="980.8"
            height={32766}
            maskUnits="userSpaceOnUse"
          />
          <linearGradient
            id="linear-gradient-15"
            x1={640}
            y1="73.87"
            x2={640}
            y2="713.75"
            xlinkHref="#linear-gradient-13"
          />
          <mask
            id="mask-2"
            x="149.6"
            y="35.16"
            width="980.8"
            height="774.35"
            maskUnits="userSpaceOnUse"
          >
            <g filter="url(#luminosity-noclip-3)">
              <g mask="url(#mask-3)">
                <path
                  d="m153.29,718.82l19.58,90.69h3.83l-19.75-91.48-.22-.9c-4.76-17.38-4.5-35.68.76-52.91l14.85-48.66c9.41-30.83,15.4-62.83,17.83-95.25l30.5-458.92c.84-12.61,11.39-22.49,24.03-22.49h.01l391.27.19h0s4.02,0,4.02,0h4.02s0,0,0,0l391.27-.19h.01c12.64,0,23.19,9.87,24.03,22.49l30.5,458.92c2.43,32.42,8.42,64.42,17.83,95.25l14.85,48.66c5.26,17.24,5.52,35.53.76,52.91l-.22.9-19.75,91.48h3.83l19.56-90.59.19-.79c4.95-18.06,4.68-37.08-.79-55l-14.85-48.66c-9.33-30.57-15.27-62.34-17.67-94.4l-30.5-458.92c-.97-14.57-13.17-25.99-27.78-25.99l-391.27.19h-1.88s-397.44-.19-397.44-.19c-14.6,0-26.8,11.41-27.77,25.99l-30.5,458.89c-2.4,32.09-8.35,63.87-17.67,94.43l-14.85,48.66c-5.46,17.91-5.74,36.93-.82,54.89l.2.8Z"
                  fill="url(#linear-gradient-15)"
                  mix-blend-mode="multiply"
                  strokeWidth={0}
                />
              </g>
            </g>
          </mask>
          <linearGradient
            id="linear-gradient-16"
            x1={640}
            y1="73.87"
            x2={640}
            y2="713.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#000" />
            <stop offset={1} stopColor="#1e262a" />
          </linearGradient>
          <radialGradient
            id="radial-gradient-2"
            cx="644.02"
            cy="44.48"
            fx="644.02"
            fy="44.48"
            r="762.9"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#b75abf" />
            <stop offset=".56" stopColor="#5c4896" />
            <stop offset=".7" stopColor="#473674" />
            <stop offset=".87" stopColor="#2b1f48" />
            <stop offset={1} stopColor="#2a1e48" />
          </radialGradient>
          {/* <linearGradient
            id="linear-gradient-17"
            x1="639.81"
            y1="75.98"
            x2="639.81"
            y2="553.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset=".34" stopColor="#e2e2e2" />
            <stop offset=".76" stopColor="#c5c5c5" />
            <stop offset={1} stopColor="#bababa" />
          </linearGradient> */}
          <linearGradient
            id="linear-gradient-18"
            x1="965.91"
            y1="462.62"
            x2="262.83"
            y2="143.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#2e2a52" />
            <stop offset=".24" stopColor="#342458" />
            <stop offset=".48" stopColor="#6e5e96" />
            <stop offset=".73" stopColor="#342458" />
            <stop offset={1} stopColor="#2e2a52" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-19"
            x1="1200.15"
            y1="318.7"
            x2="1221.61"
            y2="318.7"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#72629a" />
            <stop offset=".26" stopColor="#947ebe" />
            <stop offset=".51" stopColor="#a69cca" />
            <stop offset=".74" stopColor="#947ebe" />
            <stop offset={1} stopColor="#72629a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-20"
            x1="258.11"
            y1="197.65"
            x2="523.78"
            y2="133.27"
            xlinkHref="#linear-gradient-19"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="240.71"
            y1="363.35"
            x2="338.13"
            y2="515.94"
            xlinkHref="#linear-gradient-19"
          />
          <linearGradient
            id="linear-gradient-22"
            x1="468.53"
            y1="511.82"
            x2="836.19"
            y2="511.82"
            xlinkHref="#linear-gradient-19"
          />
          <linearGradient
            id="linear-gradient-23"
            x1="620.29"
            y1="188.8"
            x2="991.72"
            y2="195.96"
            xlinkHref="#linear-gradient-19"
          />
          <linearGradient
            id="linear-gradient-24"
            x1="971.52"
            y1="204.93"
            x2="971.52"
            y2="492.52"
            xlinkHref="#linear-gradient-19"
          />
          <filter
            id="luminosity-noclip-5"
            x="255.65"
            y="120.12"
            width="768.07"
            height="23.43"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <filter
            id="luminosity-noclip-6"
            x="255.65"
            y={-8552}
            width="768.07"
            height={32766}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <mask
            id="mask-5"
            x="255.65"
            y={-8552}
            width="768.07"
            height={32766}
            maskUnits="userSpaceOnUse"
          />
          <radialGradient
            id="radial-gradient-3"
            cx="639.68"
            cy="119.19"
            fx="639.68"
            fy="119.19"
            r="361.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset=".08" stopColor="#e1e1e1" />
            <stop offset=".27" stopColor="#9e9e9e" />
            <stop offset=".46" stopColor="#656565" />
            <stop offset=".63" stopColor="#393939" />
            <stop offset=".78" stopColor="#1a1a1a" />
            <stop offset=".91" stopColor="#060606" />
            <stop offset={1} stopColor="#000" />
          </radialGradient>
          <mask
            id="mask-4"
            x="255.65"
            y="120.12"
            width="768.07"
            height="23.43"
            maskUnits="userSpaceOnUse"
          >
            <g filter="url(#luminosity-noclip-5)">
              <g mask="url(#mask-5)">
                <path
                  d="m1003.54,120.12H275.83c-11.13,0-20.18,9.05-20.18,20.18v3.25c0-11.13,9.05-20.18,20.18-20.18h727.71c11.13,0,20.18,9.05,20.18,20.18v-3.25c0-11.13-9.05-20.18-20.18-20.18Z"
                  fill="url(#radial-gradient-3)"
                  mix-blend-mode="multiply"
                  strokeWidth={0}
                />
              </g>
            </g>
          </mask>
          <radialGradient
            id="radial-gradient-4"
            cx="639.68"
            cy="119.19"
            fx="639.68"
            fy="119.19"
            r="361.28"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} stopColor="#fff" />
          </radialGradient>
          <linearGradient
            id="linear-gradient-25"
            x1="397.3"
            y1="132.67"
            x2="397.3"
            y2="491.86"
            gradientUnits="userSpaceOnUse"
          >
            {/* <stop offset={0} stopColor="#6e598c" />
            <stop offset=".01" stopColor="#745f92" />
            <stop offset=".03" stopColor="#8772a3" />
            <stop offset=".05" stopColor="#a691c0" />
            <stop offset=".05" stopColor="#aa94c3" />
            <stop offset=".07" stopColor="#a28abd" />
            <stop offset=".11" stopColor="#977db6" />
            <stop offset=".17" stopColor="#af9bc6" />
            <stop offset=".27" stopColor="#d3c8e0" />
            <stop offset=".35" stopColor="#eae5f0" />
            <stop offset=".39" stopColor="#f3f0f7" />
            <stop offset=".49" stopColor="#fffefe" />
            <stop offset=".58" stopColor="#fffefe" />
            <stop offset=".72" stopColor="#cfc4dc" />
            <stop offset=".85" stopColor="#a794bf" />
            <stop offset=".94" stopColor="#8f77ae" />
            <stop offset={1} stopColor="#866ca8" /> */}
            <stop offset={0} stopColor="#630f4e" />
            <stop offset=".06" stopColor="#121937" />
            <stop offset=".50" stopColor="#21156f" />
            {/* <stop offset=".03" stopColor="#8772a3" />
            <stop offset=".05" stopColor="#a691c0" />
            <stop offset=".05" stopColor="#aa94c3" />
            <stop offset=".07" stopColor="#a28abd" />
            <stop offset=".11" stopColor="#977db6" />
            <stop offset=".17" stopColor="#af9bc6" />
            <stop offset=".27" stopColor="#d3c8e0" />
            <stop offset=".35" stopColor="#eae5f0" />
            <stop offset=".39" stopColor="#f3f0f7" />
            <stop offset=".49" stopColor="#fffefe" />
            <stop offset=".58" stopColor="#fffefe" />
            <stop offset=".72" stopColor="#cfc4dc" />
            <stop offset=".85" stopColor="#a794bf" /> */}
            <stop offset=".94" stopColor="#121937" />
            <stop offset={1} stopColor="#630f4e" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-26"
            x1="640.3"
            x2="640.3"
            xlinkHref="#linear-gradient-25"
          />
          <linearGradient
            id="linear-gradient-27"
            x1="882.3"
            x2="882.3"
            xlinkHref="#linear-gradient-25"
          />
          <linearGradient
            id="linear-gradient-28"
            x1="937.5"
            y1="585.56"
            x2="937.5"
            y2="657.63"
            gradientTransform="translate(5.4) skewX(-.42)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#22162a" />
            <stop offset=".3" stopColor="#241e32" />
            <stop offset=".78" stopColor="#2c354b" />
            <stop offset={1} stopColor="#304258" />
          </linearGradient>
          <filter
            id="luminosity-noclip-7"
            x="808.34"
            y="594.66"
            width="260.04"
            height="60.81"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <filter
            id="luminosity-noclip-8"
            x="808.34"
            y={-8552}
            width="260.04"
            height={32766}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <mask
            id="mask-7"
            x="808.34"
            y={-8552}
            width="260.04"
            height={32766}
            maskUnits="userSpaceOnUse"
          />
          <linearGradient
            id="linear-gradient-29"
            x1="937.51"
            y1="595.14"
            x2="937.51"
            y2="636.97"
            gradientTransform="translate(5.4) skewX(-.42)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset=".03" stopColor="#d4d4d4" />
            <stop offset=".07" stopColor="#a8a8a8" />
            <stop offset=".12" stopColor="gray" />
            <stop offset=".16" stopColor="#5d5d5d" />
            <stop offset=".22" stopColor="#404040" />
            <stop offset=".27" stopColor="#282828" />
            <stop offset=".34" stopColor="#161616" />
            <stop offset=".42" stopColor="#090909" />
            <stop offset=".52" stopColor="#020202" />
            <stop offset=".74" stopColor="#000" />
          </linearGradient>
          <mask
            id="mask-6"
            x="808.34"
            y="594.66"
            width="260.04"
            height="60.81"
            maskUnits="userSpaceOnUse"
          >
            <g filter="url(#luminosity-noclip-7)">
              <g mask="url(#mask-7)">
                <path
                  d="m809.2,594.66c-.38,0-.59.22-.71.4-.12.18-.22.48-.08.85l21.53,54.65c1.17,2.98,3.9,4.91,6.95,4.91h228.92c1.41,0,2.06-1.04,2.28-1.48.21-.45.62-1.61-.21-2.82l-37.33-54.45c-.89-1.29-2.32-2.06-3.82-2.06h-217.53Z"
                  fill="url(#linear-gradient-29)"
                  mix-blend-mode="multiply"
                  strokeWidth={0}
                />
              </g>
            </g>
          </mask>
          <linearGradient
            id="linear-gradient-30"
            x1="937.51"
            y1="595.14"
            x2="937.51"
            y2="636.97"
            gradientTransform="translate(5.4) skewX(-.42)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#461b40" />
            {/* <stop offset=".11" stopColor="#3e92ce" /> */}
            <stop offset=".74" stopColor="#24272a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-31"
            x1="937.5"
            y1="662.29"
            x2="937.5"
            y2="588.16"
            gradientTransform="translate(5.4) skewX(-.42)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset=".11" stopColor="#cdcdcd" />
            <stop offset=".24" stopColor="#979797" />
            <stop offset=".37" stopColor="#696969" />
            <stop offset=".5" stopColor="#434343" />
            <stop offset=".63" stopColor="#252525" />
            <stop offset=".76" stopColor="#101010" />
            <stop offset=".88" stopColor="#040404" />
            <stop offset={1} stopColor="#000" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-32"
            x1="-291.23"
            y1="585.58"
            x2="-291.23"
            y2="657.65"
            gradientTransform="translate(50.34) rotate(-180) scale(1 -1) skewX(-.42)"
            xlinkHref="#linear-gradient-28"
          />
          <filter
            id="luminosity-noclip-9"
            x="216.09"
            y="594.68"
            width="260.04"
            height="60.81"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <filter
            id="luminosity-noclip-10"
            x="216.09"
            y={-8552}
            width="260.04"
            height={32766}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodColor="#fff" result="bg" />
            <feBlend in="SourceGraphic" in2="bg" />
          </filter>
          <mask
            id="mask-9"
            x="216.09"
            y={-8552}
            width="260.04"
            height={32766}
            maskUnits="userSpaceOnUse"
          />
          <linearGradient
            id="linear-gradient-33"
            x1="-291.22"
            y1="595.16"
            x2="-291.22"
            y2="636.99"
            gradientTransform="translate(50.34) rotate(-180) scale(1 -1) skewX(-.42)"
            xlinkHref="#linear-gradient-29"
          />
          <mask
            id="mask-8"
            x="216.09"
            y="594.68"
            width="260.04"
            height="60.81"
            maskUnits="userSpaceOnUse"
          >
            <g filter="url(#luminosity-noclip-9)">
              <g mask="url(#mask-9)">
                <path
                  d="m475.27,594.68c.38,0,.59.22.71.4.12.18.22.48.08.85l-21.53,54.65c-1.17,2.98-3.9,4.91-6.95,4.91h-228.92c-1.41,0-2.06-1.04-2.28-1.48-.21-.45-.62-1.61.21-2.82l37.33-54.45c.89-1.29,2.32-2.06,3.82-2.06h217.53Z"
                  fill="url(#linear-gradient-33)"
                  mix-blend-mode="multiply"
                  strokeWidth={0}
                />
              </g>
            </g>
          </mask>
          <linearGradient
            id="linear-gradient-34"
            x1="-291.22"
            y1="595.16"
            x2="-291.22"
            y2="636.99"
            gradientTransform="translate(50.34) rotate(-180) scale(1 -1) skewX(-.42)"
            xlinkHref="#linear-gradient-30"
          />
          <linearGradient
            id="linear-gradient-35"
            x1="-291.23"
            y1="662.32"
            x2="-291.23"
            y2="588.19"
            gradientTransform="translate(50.34) rotate(-180) scale(1 -1) skewX(-.42)"
            xlinkHref="#linear-gradient-31"
          />
        </defs>
        <g>
          <g id="Layer_1">
            <polygon
              points="209.88 35.16 165.83 564.92 135.07 698.75 172.65 891.41 1100.3 892.66 1136.78 704.37 1101.36 499.59 1066.58 35.16 209.88 35.16"
              fill="url(#linear-gradient)"
              strokeWidth={0}
            />
            <polygon
              points="633.08 35.16 209.88 35.16 165.83 564.92 142.44 679.78 633.08 679.78 633.08 35.16"
              fill="url(#linear-gradient-2)"
              strokeWidth={0}
            />
            <polygon
              points="646.14 35.16 1069.34 35.16 1113.39 564.92 1136.78 679.78 646.14 679.78 646.14 35.16"
              fill="url(#linear-gradient-3)"
              strokeWidth={0}
            />
            <path
              d="m1144.97,651.33l-14.56-47.91c-8.52-28.02-13.94-57.09-16.13-86.43l-29.91-451.71c-1.97-29.8-26.82-53.14-56.58-53.14h-.03l-387.76.19-387.76-.19h-.03c-29.76,0-54.61,23.34-56.58,53.14l-29.91,451.71c-2.19,29.34-7.62,58.42-16.13,86.43l-14.56,47.91c-6.94,22.83-7.33,47.06-1.13,70.1l36.98,171.9c.81,3.77,4.13,6.46,7.98,6.46h13.73l1.74,4.13,7.22-5.54s0-.01,0-.02c2.13-1.6,3.33-4.34,2.71-7.2l-38.11-177.17-.21-.88c-4.67-17.11-4.41-35.13.74-52.1l14.56-47.91c9.23-30.36,15.11-61.86,17.49-93.78l29.92-451.84c.82-12.42,11.18-22.14,23.57-22.14h.01l383.83.18h0s3.95,0,3.95,0h3.95s0,0,0,0l383.83-.18h.01c12.4,0,22.75,9.72,23.57,22.14l29.92,451.84c2.38,31.92,8.26,63.42,17.49,93.78l14.56,47.91c5.16,16.97,5.42,34.98.74,52.1l-.21.88-38.11,177.17c-.58,2.69.45,5.28,2.35,6.91h-.02s.12.1.12.1c.05.04.09.07.14.11l6.81,5.26,2.69-3.76h13.31c3.84,0,7.16-2.69,7.97-6.46l36.98-171.9c6.2-23.04,5.81-47.27-1.13-70.1Z"
              fill="url(#linear-gradient-4)"
              strokeWidth={0}
            />
            <path
              d="m1130.41,603.42c-8.52-28.02-13.94-57.09-16.13-86.43l-7.3-110.25h-33.15l7.45,112.59c2.38,31.92,8.26,63.42,17.49,93.78l14.56,47.91c5.16,16.97,5.42,34.98.74,52.1l-.21.88-38.11,177.17c-.58,2.69.45,5.28,2.35,6.91h-.02s.12.1.12.1c.05.04.09.07.14.11l6.81,5.26,2.69-3.76h13.31c3.84,0,7.16-2.69,7.97-6.46l36.98-171.9c6.2-23.04,5.81-47.27-1.13-70.1l-14.56-47.91Z"
              fill="url(#linear-gradient-5)"
              strokeWidth={0}
            />
            <path
              d="m166.13,714l-.21-.88c-4.67-17.11-4.41-35.13.74-52.1l14.56-47.91c9.23-30.36,15.11-61.86,17.49-93.78l7.46-112.59h-33.15l-7.3,110.25c-2.19,29.34-7.62,58.42-16.13,86.43l-14.56,47.91c-6.94,22.83-7.33,47.06-1.13,70.1l36.98,171.9c.81,3.77,4.13,6.46,7.98,6.46h13.73l1.74,4.13,7.22-5.54s0-.01,0-.02c2.13-1.6,3.33-4.34,2.71-7.2l-38.11-177.17Z"
              fill="url(#linear-gradient-6)"
              strokeWidth={0}
            />
            <path
              d="m1154.76,654.38l-14.85-48.66c-8.68-28.45-14.21-57.99-16.44-87.79l-30.49-458.78c-2.01-30.27-27.34-53.97-57.68-53.97h-.03l-395.28.19-395.28-.19h-.03c-30.33,0-55.66,23.7-57.68,53.97l-30.49,458.78c-2.23,29.8-7.76,59.33-16.44,87.79l-14.85,48.66c-7.07,23.19-7.47,47.8-1.15,71.2l37.69,174.59c.83,3.83,4.21,6.56,8.13,6.56h18.82c4.61,0,8.04-4.25,7.07-8.76l-38.85-179.94-.22-.9c-4.76-17.38-4.5-35.68.76-52.91l14.85-48.66c9.41-30.83,15.4-62.83,17.83-95.25l30.5-458.92c.84-12.61,11.39-22.49,24.03-22.49h.01l391.27.19h0s4.02,0,4.02,0h4.02s0,0,0,0l391.27-.19h.01c12.64,0,23.19,9.87,24.03,22.49l30.5,458.92c2.43,32.42,8.42,64.42,17.83,95.25l14.85,48.66c5.26,17.24,5.52,35.53.76,52.91l-.22.9-38.85,179.94c-.97,4.51,2.46,8.76,7.07,8.76h18.82c3.92,0,7.3-2.73,8.13-6.56l37.69-174.59c6.32-23.4,5.92-48.01-1.15-71.2Z"
              fill="url(#linear-gradient-7)"
              strokeWidth={0}
            />
            <path
              d="m156.95,718.03l-.22-.9c-4.76-17.38-4.5-35.68.76-52.91l14.85-48.66c9.41-30.83,15.4-62.83,17.83-95.25l8.21-123.58h-33.79l-8.05,121.21c-2.23,29.8-7.76,59.33-16.44,87.79l-14.85,48.66c-7.07,23.19-7.47,47.8-1.15,71.2l37.69,174.59c.83,3.83,4.21,6.56,8.13,6.56h18.82c4.61,0,8.04-4.25,7.07-8.76l-38.85-179.94Z"
              fill="url(#linear-gradient-8)"
              strokeWidth={0}
            />
            <path
              d="m199.63,378l21.04-316.6c.84-12.61,11.39-22.49,24.03-22.49h.01l391.27.19h0s4.02,0,4.02,0h4.02s0,0,0,0l391.27-.19h.01c12.64,0,23.19,9.87,24.03,22.49l21.04,316.6h33.79l-21.19-318.84c-2.01-30.27-27.34-53.97-57.68-53.97h-.03l-395.28.19-395.28-.19h-.03c-30.33,0-55.66,23.7-57.68,53.97l-21.19,318.84h33.79Z"
              fill="url(#linear-gradient-9)"
              strokeWidth={0}
            />
            <path
              d="m1139.91,605.72c-8.68-28.45-14.21-57.99-16.44-87.79l-8.05-121.21h-33.79l8.21,123.58c2.43,32.42,8.42,64.42,17.83,95.25l14.85,48.66c5.26,17.24,5.52,35.53.76,52.91l-.22.9-38.85,179.94c-.97,4.51,2.46,8.76,7.07,8.76h18.82c3.92,0,7.3-2.73,8.13-6.56l37.69-174.59c6.32-23.4,5.92-48.01-1.15-71.2l-14.85-48.66Z"
              fill="url(#linear-gradient-10)"
              strokeWidth={0}
            />
            <g clipPath="url(#clippath)">
              <ellipse
                cx="638.23"
                cy={573}
                rx="345.27"
                ry="52.69"
                fill="url(#radial-gradient)"
                mix-blend-mode="screen"
                strokeWidth={0}
              />
            </g>
            <path
              d="m1094.75,626.4l-35.57-50.19c-2.51-3.55-6.59-5.65-10.93-5.65H231.91c-4.35,0-8.42,2.11-10.93,5.65l-35.57,50.19c-22.61,30.12-26.53,62.97-17.77,99.6l37.12,165.4h451.51l418.77,1.26,37.5-166.66c8.76-36.63,4.84-69.48-17.77-99.6Z"
              fill="url(#linear-gradient-11)"
              strokeWidth={0}
            />
            <path
              d="m185.4,630.88l35.57-50.19c2.51-3.55,6.59-5.65,10.93-5.65h816.34c4.35,0,8.42,2.11,10.93,5.65l35.57,50.19c14.81,19.73,21.6,40.64,22.06,63.01.4-24.07-6.27-46.44-22.06-67.49l-35.57-50.19c-2.51-3.55-6.59-5.65-10.93-5.65H231.91c-4.35,0-8.42,2.11-10.93,5.65l-35.57,50.19c-15.8,21.04-22.46,43.42-22.06,67.49.46-22.37,7.25-43.28,22.06-63.01Z"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".19"
              strokeWidth={0}
            />
            <path
              d="m1059.33,61.4l23.14,348.26c11.33,2.39,22.75,4.57,34.23,6.51l-23.72-357c-2.01-30.27-27.34-53.97-57.68-53.97h-.03l-223.12.11c3.56,11.1,6.66,22.36,9.4,33.72l213.74-.1h.01c12.64,0,23.19,9.87,24.03,22.49Z"
              fill="url(#linear-gradient-12)"
              strokeWidth={0}
            />
            <g mask="url(#mask)">
              <path
                d="m127.7,724.6c-6.13-22.72-5.75-46.62,1.12-69.13l14.85-48.66c8.76-28.72,14.34-58.53,16.6-88.63l30.49-458.78c1.88-28.3,25.57-50.47,53.96-50.47l395.28.19,395.3-.19c28.36,0,52.06,22.17,53.94,50.47l30.49,458.81c2.25,30.07,7.84,59.88,16.6,88.6l14.85,48.66c6.87,22.5,7.25,46.41,1.12,69.13l-18.79,87.01h3.83l18.57-86.04c6.32-23.4,5.92-48.01-1.15-71.2l-14.85-48.66c-8.68-28.45-14.21-57.99-16.44-87.79l-30.49-458.78c-2.01-30.27-27.34-53.97-57.68-53.97h-.03l-395.28.19-395.28-.19h-.03c-30.33,0-55.66,23.7-57.68,53.97l-30.49,458.78c-2.23,29.8-7.76,59.33-16.44,87.79l-14.85,48.66c-7.07,23.19-7.47,47.8-1.15,71.2l20.05,92.88h3.83l-20.22-93.68-.05-.19Z"
                fill="url(#linear-gradient-14)"
                mix-blend-mode="overlay"
                opacity=".33"
                strokeWidth={0}
              />
            </g>
            <g mask="url(#mask-2)">
              <path
                d="m153.29,718.82l19.58,90.69h3.83l-19.75-91.48-.22-.9c-4.76-17.38-4.5-35.68.76-52.91l14.85-48.66c9.41-30.83,15.4-62.83,17.83-95.25l30.5-458.92c.84-12.61,11.39-22.49,24.03-22.49h.01l391.27.19h0s4.02,0,4.02,0h4.02s0,0,0,0l391.27-.19h.01c12.64,0,23.19,9.87,24.03,22.49l30.5,458.92c2.43,32.42,8.42,64.42,17.83,95.25l14.85,48.66c5.26,17.24,5.52,35.53.76,52.91l-.22.9-19.75,91.48h3.83l19.56-90.59.19-.79c4.95-18.06,4.68-37.08-.79-55l-14.85-48.66c-9.33-30.57-15.27-62.34-17.67-94.4l-30.5-458.92c-.97-14.57-13.17-25.99-27.78-25.99l-391.27.19h-1.88s-397.44-.19-397.44-.19c-14.6,0-26.8,11.41-27.77,25.99l-30.5,458.89c-2.4,32.09-8.35,63.87-17.67,94.43l-14.85,48.66c-5.46,17.91-5.74,36.93-.82,54.89l.2.8Z"
                fill="url(#linear-gradient-16)"
                mix-blend-mode="overlay"
                opacity=".33"
                strokeWidth={0}
              />
            </g>
            <rect
              x="226.63"
              y="45.53"
              width="826.37"
              height="511.31"
              rx="46.48"
              ry="46.48"
              fill="url(#radial-gradient-2)"
              strokeWidth={0}
            />
            <path
              d="m1028.76,45.53H250.87c-13.39,0-24.24,10.85-24.24,24.24v462.84c0,13.39,10.85,24.24,24.24,24.24h777.89c13.39,0,24.24-10.85,24.24-24.24V69.77c0-13.39-10.85-24.24-24.24-24.24Zm19.55,487.07c0,10.78-8.77,19.55-19.55,19.55H250.87c-10.78,0-19.55-8.77-19.55-19.55V69.77c0-10.78,8.77-19.55,19.55-19.55h777.89c10.78,0,19.55,8.77,19.55,19.55v462.84Z"
              fill="url(#linear-gradient-17)"
              mix-blend-mode="overlay"
              opacity=".37"
              strokeWidth={0}
            />
            <path
              d="m1011.73,143.8c-.27-1.31-.67-2.58-1.18-3.79-.51-1.21-1.14-2.35-1.86-3.43-.39-.58-.82-1.14-1.27-1.67h0s-6.23-6.94-6.23-6.94c-.51-.65-1.06-1.26-1.65-1.84l-.12-.13h0c-3.56-3.44-8.37-5.58-13.67-5.58H270.47c-5.38,0-10.27,2.2-13.84,5.75h0l-.04.04c-.53.53-1.02,1.08-1.49,1.66l-5.81,6.47v.02c-1.05,1.18-2,2.47-2.77,3.88-.31.57-.6,1.16-.85,1.76-.51,1.21-.91,2.47-1.18,3.79-.27,1.31-.41,2.67-.41,4.06v356.79c0,11.15,9.04,20.18,20.18,20.18h721.22c14.73,0,26.67-11.94,26.67-26.67V147.86c0-1.39-.14-2.75-.41-4.06Z"
              opacity=".25"
              strokeWidth={0}
            />
            <rect
              x="261.1"
              y="125.57"
              width="757.17"
              height="386.25"
              rx="8.11"
              ry="8.11"
              strokeWidth={0}
            />
            <path
              d="m1019.32,127.81l-3.54-3.9c-.52-.65-1.07-1.26-1.66-1.83l-.12-.13h0c-3.59-3.43-8.44-5.56-13.78-5.56H279.02c-5.43,0-10.35,2.2-13.96,5.74h0l-.04.04c-.53.52-1.03,1.08-1.5,1.66l-2.83,3.12c.41,2.55-.1,5.29-1.54,7.4-.08.71-.12,1.44-.12,2.17v355.94c0,11.1,8.97,20.13,20,20.13h721.19c11.03,0,20-9.03,20-20.13V136.87c-1.67-2.63-1.96-6.12-.89-9.06Zm-9.92,364.64c0,5.11-4.13,9.26-9.2,9.26H279.02c-5.07,0-9.2-4.15-9.2-9.26V136.51c0-5.11,4.13-9.26,9.2-9.26h721.19c5.07,0,9.2,4.15,9.2,9.26v355.94Z"
              fill="url(#linear-gradient-18)"
              strokeWidth={0}
            />
            <path
              d="m1003.54,517.27H275.83c-11.13,0-20.18-9.05-20.18-20.18V140.3c0-11.13,9.05-20.18,20.18-20.18h727.71c11.13,0,20.18,9.05,20.18,20.18v356.79c0,11.13-9.05,20.18-20.18,20.18ZM275.83,131.02c-5.12,0-9.28,4.16-9.28,9.28v356.79c0,5.12,4.16,9.28,9.28,9.28h727.71c5.12,0,9.28-4.16,9.28-9.28V140.3c0-5.12-4.16-9.28-9.28-9.28H275.83Z"
              fill="url(#linear-gradient-19)"
              strokeWidth={0}
            />
            <path
              d="m266.55,140.3c0-5.12,4.16-9.28,9.28-9.28h319.18v-10.9h-319.18c-11.13,0-20.18,9.05-20.18,20.18v129.12h10.9v-129.12Z"
              fill="url(#linear-gradient-20)"
              strokeWidth={0}
            />
            <path
              d="m361.3,506.37h-85.47c-5.12,0-9.28-4.16-9.28-9.28v-209.5h-10.9v209.5c0,11.13,9.05,20.18,20.18,20.18h87.42c-.67-3.63-1.31-7.26-1.95-10.9Z"
              fill="url(#linear-gradient-21)"
              strokeWidth={0}
            />
            <path
              d="m379.67,506.37c.66,3.64,1.34,7.27,2.02,10.9h519.47c.22-3.63.43-7.26.6-10.9H379.67Z"
              fill="url(#linear-gradient-22)"
              strokeWidth={0}
            />
            <path
              d="m1012.82,140.3v132.13c3.62-1.34,7.26-2.65,10.9-3.97v-128.16c0-11.13-9.05-20.18-20.18-20.18h-390.36v10.9h390.36c5.12,0,9.28,4.16,9.28,9.28Z"
              fill="url(#linear-gradient-23)"
              strokeWidth={0}
            />
            <path
              d="m1012.82,167.13v329.96c0,5.12-4.16,9.28-9.28,9.28h-83.61c-.17,3.63-.38,7.27-.6,10.9h84.21c11.13,0,20.18-9.05,20.18-20.18V150.78c-4.48,1.75-10.9,16.35-10.9,16.35Z"
              fill="url(#linear-gradient-24)"
              strokeWidth={0}
            />
            <g mix-blend-mode="overlay" opacity=".77">
              <path
                d="m275.83,509.62h727.71c5.12,0,9.28-4.16,9.28-9.28v-3.25c0,5.12-4.16,9.28-9.28,9.28H275.83c-5.12,0-9.28-4.16-9.28-9.28v3.25c0,5.12,4.16,9.28,9.28,9.28Z"
                fill="#fff"
                strokeWidth={0}
              />
              <g mask="url(#mask-4)">
                <path
                  d="m1003.54,120.12H275.83c-11.13,0-20.18,9.05-20.18,20.18v3.25c0-11.13,9.05-20.18,20.18-20.18h727.71c11.13,0,20.18,9.05,20.18,20.18v-3.25c0-11.13-9.05-20.18-20.18-20.18Z"
                  fill="url(#radial-gradient-4)"
                  strokeWidth={0}
                />
              </g>
            </g>
            <rect
              x="279.93"
              y="130.85"
              width="234.75"
              height="367.98"
              fill="url(#linear-gradient-25)"
              strokeWidth={0}
            />
            <rect
              x="509.24"
              y="130.85"
              width="5.44"
              height="367.98"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".41"
              strokeWidth={0}
            />
            <rect
              x="279.93"
              y="130.85"
              width="5.44"
              height="367.98"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".41"
              strokeWidth={0}
            />
            <rect
              x="522.93"
              y="130.85"
              width="234.75"
              height="367.98"
              fill="url(#linear-gradient-26)"
              strokeWidth={0}
            />
            <rect
              x="752.24"
              y="130.85"
              width="5.44"
              height="367.98"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".41"
              strokeWidth={0}
            />
            <rect
              x="522.93"
              y="130.85"
              width="5.44"
              height="367.98"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".41"
              strokeWidth={0}
            />
            <rect
              x="764.93"
              y="130.85"
              width="234.75"
              height="367.98"
              fill="url(#linear-gradient-27)"
              strokeWidth={0}
            />
            <rect
              x="994.24"
              y="130.85"
              width="5.44"
              height="367.98"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".41"
              strokeWidth={0}
            />
            <rect
              x="764.93"
              y="130.85"
              width="5.44"
              height="367.98"
              fill="#fff"
              mix-blend-mode="overlay"
              opacity=".41"
              strokeWidth={0}
            />
            <path
              d="m1026.73,590.92h-217.53c-2.89,0-4.86,3.14-3.75,5.97l21.53,54.65c1.67,4.23,5.56,6.98,9.88,6.98h228.92c4.7,0,7.43-5.67,4.67-9.7l-37.33-54.45c-1.49-2.17-3.86-3.45-6.39-3.45Z"
              fill="url(#linear-gradient-28)"
              strokeWidth={0}
            />
            <g mask="url(#mask-6)">
              <path
                d="m809.2,594.66c-.38,0-.59.22-.71.4-.12.18-.22.48-.08.85l21.53,54.65c1.17,2.98,3.9,4.91,6.95,4.91h228.92c1.41,0,2.06-1.04,2.28-1.48.21-.45.62-1.61-.21-2.82l-37.33-54.45c-.89-1.29-2.32-2.06-3.82-2.06h-217.53Z"
                fill="url(#linear-gradient-30)"
                strokeWidth={0}
              />
            </g>
            <path
              d="m1074.67,645.54l-37.33-54.45c-2.45-3.58-6.41-5.72-10.59-5.72h-217.53c-3.11,0-6.01,1.65-7.74,4.41-1.73,2.76-2.05,6.23-.85,9.27l21.53,54.65c2.48,6.3,8.24,10.36,14.67,10.36h228.92c4.15,0,7.91-2.44,9.8-6.38,1.89-3.93,1.56-8.59-.88-12.15Zm-8.89,12.98h-228.92c-4.32,0-8.21-2.75-9.88-6.98l-21.53-54.65c-1.12-2.83.85-5.97,3.75-5.97h217.53c2.53,0,4.9,1.28,6.39,3.45l37.33,54.45c2.76,4.02.03,9.7-4.67,9.7Z"
              fill="url(#linear-gradient-31)"
              mix-blend-mode="overlay"
              opacity=".42"
              strokeWidth={0}
            />
            <path
              d="m257.74,590.94h217.53c2.89,0,4.86,3.14,3.75,5.97l-21.53,54.65c-1.67,4.23-5.56,6.98-9.88,6.98h-228.92c-4.7,0-7.43-5.67-4.67-9.7l37.33-54.45c1.49-2.17,3.86-3.45,6.39-3.45Z"
              fill="url(#linear-gradient-32)"
              strokeWidth={0}
            />
            <g mask="url(#mask-8)">
              <path
                d="m475.27,594.68c.38,0,.59.22.71.4.12.18.22.48.08.85l-21.53,54.65c-1.17,2.98-3.9,4.91-6.95,4.91h-228.92c-1.41,0-2.06-1.04-2.28-1.48-.21-.45-.62-1.61.21-2.82l37.33-54.45c.89-1.29,2.32-2.06,3.82-2.06h217.53Z"
                fill="url(#linear-gradient-34)"
                strokeWidth={0}
              />
            </g>
            <path
              d="m208.91,657.71c1.89,3.93,5.65,6.38,9.8,6.38h228.92c6.43,0,12.19-4.07,14.67-10.36l21.53-54.65c1.2-3.05.88-6.51-.85-9.27-1.73-2.76-4.63-4.41-7.74-4.41h-217.53c-4.18,0-8.14,2.14-10.59,5.72l-37.33,54.45c-2.44,3.56-2.78,8.21-.88,12.15Zm5.11-8.87l37.33-54.45c1.49-2.17,3.86-3.45,6.39-3.45h217.53c2.89,0,4.86,3.14,3.75,5.97l-21.53,54.65c-1.67,4.23-5.56,6.98-9.88,6.98h-228.92c-4.7,0-7.43-5.67-4.67-9.7Z"
              fill="url(#linear-gradient-35)"
              mix-blend-mode="overlay"
              opacity=".42"
              strokeWidth={0}
            />
          </g>
        </g>
        <g
          className="neon-blue-line"
          style={{
            mixBlendMode: 'hard-light',
            fill: 'none',
            transform: 'translateX(116px)',
          }}
        >
          <path
            d="M32.6252 817.94L12.3852 724.2L12.3352 724.01C6.19518 701.28 6.58518 677.35 13.4552 654.83L28.3152 606.13C37.0852 577.39 42.6752 547.56 44.9252 517.44L73.1852 60.54C74.4452 39.3 92.1352 9.06 113.415 9.23L525.015 8L936.775 11.03C957.985 10.95 975.565 41.16 976.825 62.33L1005.09 517.47C1007.34 547.57 1012.93 577.4 1021.7 606.13L1036.56 654.83C1043.43 677.35 1043.82 701.27 1037.68 724.01L1018.88 811.09H1022.71"
            stroke="#00BCF4"
            strokeWidth={10}
            strokeMiterlimit={10}
          ></path>
          <path
            d="M32.6252 817.94L12.3852 724.2L12.3352 724.01C6.19518 701.28 6.58518 677.35 13.4552 654.83L28.3152 606.13C37.0852 577.39 42.6752 547.56 44.9252 517.44L73.1852 60.54C74.4452 39.3 92.1352 9.06 113.415 9.23L525.015 8L936.775 11.03C957.985 10.95 975.565 41.16 976.825 62.33L1005.09 517.47C1007.34 547.57 1012.93 577.4 1021.7 606.13L1036.56 654.83C1043.43 677.35 1043.82 701.27 1037.68 724.01L1018.88 811.09H1022.71"
            stroke="white"
            strokeWidth={2}
            strokeMiterlimit={10}
          ></path>
        </g>
        <g
          className="neon-green-line"
          style={{
            mixBlendMode: 'hard-light',
            fill: 'none',
            transform: 'translate(140px, 22px)',
          }}
        >
          <path
            d="M28.8031 773.211L9.79306 685.181C4.04306 663.871 13.4031 638.451 19.8431 617.351L33.7631 571.711C41.9831 544.781 47.2131 516.821 49.3331 488.581L75.8131 43.2412C76.9931 23.3312 93.5731 7.84123 113.513 8.00123L499.263 11.1312L885.173 9.69123C905.053 9.62123 921.523 25.0812 922.713 44.9212L949.203 488.611C951.313 516.821 956.553 544.771 964.773 571.711L978.693 617.351C985.133 638.461 992.493 662.881 986.743 684.181L969.123 765.791L960.893 768.781"
            stroke="#c8ff51"
            strokeWidth={10}
            strokeMiterlimit={10}
          ></path>
          <path
            d="M28.8031 773.211L9.79306 685.181C4.04306 663.871 13.4031 638.451 19.8431 617.351L33.7631 571.711C41.9831 544.781 47.2131 516.821 49.3331 488.581L75.8131 43.2412C76.9931 23.3312 93.5731 7.84123 113.513 8.00123L499.263 11.1312L885.173 9.69123C905.053 9.62123 921.523 25.0812 922.713 44.9212L949.203 488.611C951.313 516.821 956.553 544.771 964.773 571.711L978.693 617.351C985.133 638.461 992.493 662.881 986.743 684.181L969.123 765.791L960.893 768.781"
            stroke="white"
            strokeWidth={2}
            strokeMiterlimit={10}
          ></path>
        </g>
        <g
          className="neon-pink-line"
          style={{
            mixBlendMode: 'hard-light',
            fill: 'none',
            transform: 'translate(250px, 110px)',
          }}
        >
          <path
            d="M754.43 5H23.57C13.3141 5 5 13.3141 5 23.57V383.43C5 393.686 13.3141 402 23.57 402H754.43C764.686 402 773 393.686 773 383.43V23.57C773 13.3141 764.686 5 754.43 5Z"
            stroke="#EA51FF"
            strokeWidth={10}
            strokeMiterlimit={10}
          ></path>
          <path
            d="M754.43 5H23.57C13.3141 5 5 13.3141 5 23.57V383.43C5 393.686 13.3141 402 23.57 402H754.43C764.686 402 773 393.686 773 383.43V23.57C773 13.3141 764.686 5 754.43 5Z"
            stroke="white"
            strokeWidth={3}
            strokeMiterlimit={10}
          ></path>
        </g>
        {/* <g
          className="neon-pink-line"
          style={{
            mixBlendMode: 'hard-light',
            fill: 'none',
            transform: 'translate(224px, 36px)',
          }}
        >
          <path
            d="M784.28 5.5H47.72C24.4025 5.5 5.5 24.4025 5.5 47.72V478.28C5.5 501.598 24.4025 520.5 47.72 520.5H784.28C807.597 520.5 826.5 501.598 826.5 478.28V47.72C826.5 24.4025 807.597 5.5 784.28 5.5Z"
            stroke="#EA51FF"
            strokeWidth={10}
            strokeMiterlimit={10}
          ></path>
          <path
            d="M784.28 5.5H47.72C24.4025 5.5 5.5 24.4025 5.5 47.72V478.28C5.5 501.598 24.4025 520.5 47.72 520.5H784.28C807.597 520.5 826.5 501.598 826.5 478.28V47.72C826.5 24.4025 807.597 5.5 784.28 5.5Z"
            stroke="white"
            strokeWidth={3}
            strokeMiterlimit={10}
          ></path>
        </g> */}
      </svg>
      {props.children}
    </>
  );
};

export default Machine;
