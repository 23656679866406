import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { capitalize } from 'lodash';
import { ProjectEntryEn } from '../resumeTypes';

interface ProjectEnrichedProps {
  data?: ProjectEntryEn;
  variant?: string;
}

export default function ProjectEnriched({
  data,
  variant,
}: ProjectEnrichedProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      {data?.role && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Mapped Role</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.role}</Text>
        </Box>
      )}
      {data?.job_family && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Job Family</Text>
          <Text sx={styles['enrichedDataValue']}>{data?.job_family}</Text>
        </Box>
      )}
      {!!data?.activities?.length && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Activities</Text>
          <Box sx={styles['enrichedDataValue']}>
            <ul style={{ paddingLeft: '10px' }}>
              {data?.activities.map((skill, i) => (
                <li key={'activity-' + i}>
                  <Text>{skill}</Text>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}
      {!!data?.extracted_skills?.length && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Extracted Skills</Text>
          <Text sx={styles['enrichedDataValue']}>
            {data?.extracted_skills
              ?.map((skill) => capitalize(skill))
              .join(' | ')}
          </Text>
        </Box>
      )}
    </Box>
  );
}
