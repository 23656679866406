// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  select,
  selectAll,
  scaleLinear,
  scaleOrdinal,
  extent,
  min,
  max,
  zoom,
  zoomIdentity,
  json,
} from 'd3';
// import { format as d3Format } from "d3-format";
import { colors as defaultColors } from '../utilities/colors';
// import { sankey, sankeyRight, sankeyLinkHorizontal } from "d3-sankey";
// import { consoleSandbox } from ".pnpm/@sentry+utils@6.16.1/node_modules/@sentry/utils";
// import { data } from 'apps/reveliolabs-dashboard/src/components/d3/sankey/demo-data-fix.js';
import { withResizeDetector } from 'react-resize-detector';
import { defer, isString } from 'lodash';

import '../d3-styles.scss';

export const ScatterPlot = ({
  name,
  heading,
  data,
  searchTerm,
  colors = defaultColors,
  margins = { top: 80, left: 0, bottom: 0, right: 0 },
  height,
  width,
  targetRef,
}) => {
  const dims = useRef({});
  const [firstRenderDone, setFirstRenderDone] = useState(false);

  const highlightFnRef = useRef(() => {
    console.log('Not ready!!');
  });

  useEffect(() => {
    if (data && targetRef.current && height && width) {
      // remove old svg
      select(`.svg-${name}`).remove();
      select(`.tooltip-${name}`).remove();
      select('.scatter-slider').remove();
      select('.autocomplete').remove();
      select('.foreign-object').remove();
      // setup margins and inner dims
      dims.current.margin = margins;
      dims.current.innerHeight =
        height - (dims.current.margin.top + dims.current.margin.bottom);
      dims.current.innerWidth =
        width - (dims.current.margin.left + dims.current.margin.right);
      // setup svg node
      const node = targetRef.current;
      const svg = select(node).append('svg');
      svg
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('class', `svg-${name}`)
        .attr('id', 'svg-id');
      const chart = svg.append('g');
      chart
        .attr(
          'transform',
          `translate(${dims.current.margin.left}, ${dims.current.margin.top})`
        )
        .attr('class', 'chart');

      const sliderMin = 0;
      const sliderMax = 100;

      let slider = select(node)
        .append('div')
        .classed('scatter-slider', true)
        .style('bottom', '40px')
        .style('right', '160px')
        .append('input')
        .datum({})
        .attr('type', 'range')
        .attr('min', sliderMin)
        .attr('max', sliderMax)
        .attr('value', 0)
        .attr('step', 1)
        .attr('id', 'slider')
        .style('position', 'absolute');
      // .style("top", dims.current.innerHeight + "px")
      // .style("left", dims.current.innerWidth - 80 + "px");

      const zoomMin = 1;
      const zoomMax = 7; //originally 30
      // scale for slider
      let scale = scaleLinear()
        .domain([sliderMin, sliderMax])
        .range([zoomMin, zoomMax]);

      //=============================================================================

      // PROPS REQUIRED:

      // padding for positioning tooltip
      const paddingLeft = 16;
      const paddingTop = 12;

      // json(dataUrl).then((data) => {
      // Add X Scale
      var x = scaleLinear()
        .domain(extent(data.map((d) => parseInt(d.x))))
        .range([10, dims.current.innerWidth - 10]);

      // Add Y Scale
      var y = scaleLinear()
        .domain(extent(data.map((d) => parseInt(d.y))))
        .range([dims.current.innerHeight - 10, 10]);

      // circle size scale
      const scaleRadius = scaleLinear()
        .domain(extent(data.map((d) => parseInt(d['value']))))
        .range([1, 20]); //originally [1,15] with old data, then tried [1.5, 20] which worked too

      // circle color scale
      const scaleColor = scaleOrdinal()
        .domain(new Set(data.map((d) => d.cluster)))
        .range(colors);

      // Set clip region, rect with same width/height as "drawing" area, where we will be able to zoom in
      var defs = chart.append('defs');
      // append clip path to defs
      defs
        .append('clipPath')
        .attr('id', 'clip')
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', dims.current.innerWidth + 20)
        .attr('height', dims.current.innerHeight + 20);
      const main = chart
        .append('g')
        .attr('class', 'main')
        .attr('clip-path', 'url(#clip)');

      // testing glow: not working
      // var defs2 = chart.append('defs');
      // // append glow filter to defs
      // var filter = defs2.append('filter').attr('id', 'glow');
      // filter
      //   .append('feGaussianBlur')
      //   .attr('class', 'blur')
      //   .attr('stdDeviation', '20.5')
      //   .attr('result', 'coloredBlur');
      // var feMerge = filter.append('feMerge');
      // feMerge.append('feMergeNode').attr('in', 'coloredBlur');
      // feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

      var dots = main
        .selectAll('dot')
        .data(data)
        .join('circle')
        .classed('circle', true)
        .attr('id', (d) => `circle-${d.id}`)
        .attr('cx', (d) => x(d.x))
        .attr('cy', (d) => y(d.y))
        .attr('r', (d) => scaleRadius(d.value))
        .style('fill', (d) => scaleColor(d.cluster))
        .attr('stroke', '#2d426a')
        .style('stroke-width', 0.5)
        .attr('cursor', 'crosshair')
        .on('mousemove', mouseMove)
        .on('mouseleave', mouseLeave);

      // ---------------- autocomplete second test ----------------------
      // array of labels sorted descending to put into our autocomplete - so the most common jobs will appear on top
      let sortedLabels = [...data]
        .sort(function (a, b) {
          return b.value - a.value;
        })
        .map((d) => d.label);

      // ----------------------------------------------------------------

      // ------------------- highlight random single logic -----------------------
      // var randomButton = main
      //   .append('foreignObject')
      //   .attr('width', 50)
      //   .attr('height', 50)
      //   .attr('transform', `translate(${170}, ${5})`)
      //   .classed('foreign-object', true)
      //   .append('xhtml:button')
      //   .text('Highlight Random Single');
      // -------------------------------------------------------------------------
      // ------------------ highlight multiple logic -----------------------------
      // var randomButton2 = main
      //   .append('foreignObject')
      //   .attr('width', dims.current.innerWidth / 3)
      //   .attr('height', 50)
      //   .attr('transform', `translate(0, 25)`)
      //   // .attr("transform", `translate(${-width / 2}, -40)`)
      //   .classed('foreign-object', true)
      //   .append('xhtml:button')
      //   .text('Highlight multiple');
      // -------------------------------------------------------------------------
      // ----------------- first implementation of autocomplete -------------------
      // // Add autocomplete text box to select a job to look up:
      // var lookupBox = main
      //   .append('foreignObject')
      //   .attr('width', 1200)
      //   .attr('height', 25)
      //   .attr('x', 5)
      //   .attr('y', 25)
      //   .classed('foreign-object', true);
      // lookupBox
      //   .append('xhtml:input')
      //   .attr('id', 'lookupBoxText')
      //   // .append("input")
      //   .attr('type', 'text')
      //   .attr('name', 'textInput')
      //   .attr('placeholder', 'Search job')
      //   //.attr('value', '')
      //   .on('keyup', function () {
      //     getValue(this.value);
      //   });
      // // and div to store results:
      // var resultsDiv = main
      //   .append('foreignObject')
      //   .attr('width', 1200)
      //   .attr('height', '100%')
      //   .attr('x', 5)
      //   .attr('y', 40)
      //   .classed('foreign-object', true)
      //   .append('xhtml:div')
      //   .classed('output', true)
      //   .on('hover', 'pointer');
      // -------------------------------------------------------------

      const tooltip = select(targetRef.current)
        .append('div')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .classed('tooltip-scatter', true)
        .classed(`tooltip-${name}`, true);

      let zoomBehavior = zoom()
        .scaleExtent([zoomMin, zoomMax])
        .translateExtent([
          [0, 0],
          [dims.current.innerWidth, dims.current.innerHeight], // FIX THIS
        ])
        .on('start', removeSome)
        .on('zoom', renderSome)
        .on('end', renderAllInClipPath);

      slider.on('change', slided);
      function slided() {
        zoomBehavior.scaleTo(
          svg.transition().duration(300),
          scale(select(this).property('value'))
        );
      }

      // initial zoom
      select(`.svg-${name}`).call(zoomBehavior);

      // remove small dots when starting zoom
      function removeSome() {
        dots.filter((dot) => dot.value <= 25).remove();
        selectAll('.ring').remove();
        selectAll('.ring2').remove();
        tooltip.style('opacity', 0);
      }

      // while zooming, only show & update a few large dots
      function renderSome(e, d) {
        tooltip.style('opacity', 0);
        var transform = e.transform;
        var updatedXScale = transform.rescaleX(x);
        var updatedYScale = transform.rescaleY(y);
        dots
          .attr('cx', (d) => updatedXScale(d.x))
          .attr('cy', (d) => updatedYScale(d.y));

        // slider.property('value', scale.invert(e.transform.k));
      }

      // when zoom ends, render all dots again in updated (zoomed-in) positions
      var transformed = false; // have we zoomed at all?
      var updatedXScale, updatedYScale; // inialize variables to store updated scales after zooming
      function renderAllInClipPath(e) {
        //indices = [195, 492, 435, 152];
        transformed = true;
        var transform = e.transform;
        updatedXScale = transform.rescaleX(x);
        updatedYScale = transform.rescaleY(y);

        dots = main
          .selectAll('.circle')
          .data(data)
          .join('circle')
          .classed('circle', true)
          .attr('id', (d) => `circle-${d.id}`)
          .attr('cx', (d) => updatedXScale(d.x))
          .attr('cy', (d) => updatedYScale(d.y))
          .attr('r', (d) => scaleRadius(d.value))
          .style('fill', (d) =>
            d.id === targetId || (indices && indices.includes(d.id, 1))
              ? '#2d426a'
              : scaleColor(d.cluster)
          )
          // .style('filter', (d) => (d.id === targetId ? 'url(#glow)' : ''))
          .attr('stroke', (d) =>
            d.id === targetId || (indices && indices.includes(d.id, 1))
              ? '#2d426a'
              : '#2d426a'
          )
          .style('stroke-width', 0.5)
          .attr('cursor', 'crosshair')
          .on('mousemove', mouseMove)
          .on('mouseleave', mouseLeave);
        // .on('click', (e, d) => {
        //   targetId = d.id;
        //   indices = [];
        // });

        slider.property('value', scale.invert(e.transform.k));
        // testing adding ring around target circles
        main
          .selectAll('.ring')
          .data(
            data.filter(
              (d) => d.id === targetId || (indices && indices.includes(d.id, 1))
            )
          )
          .join('circle')
          .classed('ring', true)
          .attr('cx', (d) => updatedXScale(d.x))
          .attr('cy', (d) => updatedYScale(d.y))
          .attr('r', (d) => scaleRadius(d.value) + 3)
          .style('fill', 'none')
          .attr('stroke', '#2d426a');

        // add second ring around target index for multiple selected
        main
          .selectAll('.ring2')
          .data(data.filter((d) => d.id === targetId)) // to add double ring to only multiple highlight: && indices.length > 0))
          .join('circle')
          .classed('ring2', true)
          .attr('cx', (d) => updatedXScale(d.x))
          .attr('cy', (d) => updatedYScale(d.y))
          .attr('r', (d) => scaleRadius(d.value) + 6)
          .style('fill', 'none')
          .attr('stroke', '#2d426a');
      }

      function mouseMove(event, d) {
        var xTranslate = x;
        var yTranslate = y;
        if (transformed) {
          xTranslate = updatedXScale;
          yTranslate = updatedYScale;
        }
        tooltip.style('opacity', 0.9);
        var jobs = [];
        var radius = scaleRadius(d.value);
        dots.each(function (dot) {
          // we want all dots where the distance between that dot's center and this dot's center is < this dot's radius
          if (
            Math.sqrt(
              (xTranslate(dot.x) - xTranslate(d.x)) ** 2 +
                (yTranslate(dot.y) - yTranslate(d.y)) ** 2
            ) < radius
          ) {
            //txt += dot.label + "<br/>"
            jobs.push([dot.label, dot.value]);
          }
        });
        var top5Titles = jobs
          .sort(function (a, b) {
            return b[1] - a[1];
          })
          .slice(0, 5)
          .map((title) => title[0]); //array of top 5 job titles by value

        top5Titles = top5Titles.map(
          (string) => string.charAt(0).toUpperCase() + string.slice(1)
        );
        var txt = top5Titles.join('<br/>');
        txt += '<br/>';
        if (top5Titles.length < jobs.length) {
          txt += `(+ ${jobs.length - 5} titles)`;
        }
        // var tooltipLength = txt.split('<br/>').length;
        // tooltip.style('height', `${tooltipLength * 15}px`);
        tooltip.style('height', 'auto');
        tooltip.html(txt);
        tooltip.style(
          'transform',
          `translate(${paddingLeft + event.offsetX + 5}px, ${
            paddingTop + event.offsetY + 5
          }px)`
        );
      }

      function mouseLeave() {
        tooltip.style('opacity', 0);
      }

      var targetCircle, targetId, indices;
      var dataMin = min(data.map((d) => d.id));
      var dataMax = max(data.map((d) => d.id));

      function autocomplete(inp, arr) {
        /*the autocomplete function takes two arguments,
          the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        if (inp) {
          /*execute a function when someone writes in the text field:*/
          inp.addEventListener('input', function (e) {
            var a,
              b,
              i,
              val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) {
              return false;
            }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement('DIV');
            a.setAttribute('id', this.id + 'autocomplete-list');
            a.setAttribute('class', 'autocomplete-items');
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
              /*check if the item starts with the same letters as the text field value:*/
              if (
                // arr[i].substr(0, val.length).toUpperCase() ==
                // val.toUpperCase()
                arr[i].toUpperCase().includes(val.toUpperCase())
              ) {
                /*create a DIV element for each matching element:*/
                b = document.createElement('DIV');
                /*make the matching letters bold:*/
                var txtStart = arr[i].toUpperCase().indexOf(val.toUpperCase());
                if (txtStart === 0) {
                  b.innerHTML =
                    '<strong>' + arr[i].substr(0, val.length) + '</strong>';
                  b.innerHTML += arr[i].substr(val.length);
                } else {
                  b.innerHTML = arr[i].substr(0, txtStart);
                  b.innerHTML +=
                    '<strong>' +
                    arr[i].substr(txtStart, val.length) +
                    '</strong>';
                  b.innerHTML += arr[i].substr(val.length + txtStart);
                }
                // b.innerHTML = arr[i];
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener('click', function (e) {
                  /*insert the value for the autocomplete text field:*/
                  inp.value = this.getElementsByTagName('input')[0].value;
                  /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
                  closeAllLists();
                });
                a.appendChild(b);
              }
            }
          });
          /*execute a function presses a key on the keyboard:*/
          inp.addEventListener('keydown', function (e) {
            var x = document.getElementById(this.id + 'autocomplete-list');
            if (x) x = x.getElementsByTagName('div');
            // save height of autocomplete div in a variable
            if (document.getElementsByClassName('autocomplete-items')[0]) {
              var autocompleteHeight =
                document.getElementsByClassName('autocomplete-items')[0]
                  .offsetHeight;
            }
            if (e.keyCode == 40) {
              /*If the arrow DOWN key is pressed,
              increase the currentFocus variable:*/
              currentFocus++;
              /*and and make the current item more visible:*/
              addActive(x);
              if (autocompleteHeight && autocompleteHeight > 0) {
                x[currentFocus].scrollIntoView(true);
              }
            } else if (e.keyCode == 38) {
              //up
              /*If the arrow UP key is pressed,
              decrease the currentFocus variable:*/
              currentFocus--;
              /*and and make the current item more visible:*/
              addActive(x);
              if (autocompleteHeight && autocompleteHeight > 0) {
                x[currentFocus].scrollIntoView(true);
              }
            } else if (e.keyCode == 13) {
              /*If the ENTER key is pressed, prevent the form from being submitted,*/
              e.preventDefault();
              if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
              }
              //if (this.value) jobName = this.value; // highlightCircle(this.value);
            }
          });
          // var a = document.createElement('DIV');
          // a.setAttribute('id', this.id + 'autocomplete-list');
          // var x = document.getElementById(this.id + 'autocomplete-list');
          // var x = document.getElementsByClassName('autocomplete-items');
        }
        function addActive(x) {
          /*a function to classify an item as "active":*/
          if (!x) return false;
          /*start by removing the "active" class on all items:*/
          removeActive(x);
          if (currentFocus >= x.length) currentFocus = 0;
          if (currentFocus < 0) currentFocus = x.length - 1;
          /*add class "autocomplete-active":*/
          if (x[currentFocus])
            x[currentFocus].classList.add('autocomplete-active');
        }
        function removeActive(x) {
          /*a function to remove the "active" class from all autocomplete items:*/
          for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
          }
        }
        function closeAllLists(elmnt) {
          /*close all autocomplete lists in the document,
          except the one passed as an argument:*/
          var x = document.getElementsByClassName('autocomplete-items');
          for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
              x[i].parentNode.removeChild(x[i]);
            }
          }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener('click', function (e) {
          closeAllLists(e.target);
        });
      }

      autocomplete(document.getElementById('myInput'), sortedLabels);

      highlightFnRef.current = function highlightCircle(jobNameOrId, nextData) {
        let dataFiltered;
        if (isString(jobNameOrId)) {
          const matches = nextData.filter((d) => d.label === jobNameOrId);
          dataFiltered = matches.length ? matches[0] : undefined;
        } else {
          dataFiltered = nextData.find((d) => d.id == jobNameOrId);
        }

        // get the id of the job

        if (dataFiltered) {
          var jobId = dataFiltered.id;
          // extract index of target circle
          targetCircle = select(`#circle-${jobId}`);
          var targetData = targetCircle.data()[0];
          targetId = targetData.id;
          // dots.style('fill', (d) =>
          //   d.id === targetId ? 'black' : scaleColor(d.cluster)
          // );

          var xpt = x(targetData.x);
          var ypt = y(targetData.y);

          // var currScale = zoomTransform(select('#svg-id').node()).k; //can choose to keep current zoom

          // translate and scale svg to center on target circle; after zoom ends, dot will be colored as target circle
          select('#svg-id')
            .transition()
            .duration(1500) //2500
            .call(
              zoomBehavior.transform,
              zoomIdentity
                .translate(
                  dims.current.innerWidth / 2,
                  dims.current.innerHeight / 2
                )
                .scale(7) //zoom level when panning to selected circle
                .translate(-xpt, -ypt)
            );
        }
      };

      // ----------------------- highlight random single ---------------------
      // randomButton.on('click', function (event) {
      //   indices = [];
      //   var index = getRandomIntInclusive(dataMin, dataMax);
      //   // extract index of target circle
      //   targetCircle = select(`#circle-${index}`);
      //   var targetData = targetCircle.data()[0];
      //   targetId = targetData.id;
      //   // dots.style('fill', (d) =>
      //   //   d.id === targetId ? 'black' : scaleColor(d.cluster)
      //   // );

      //   var xpt = x(targetData.x);
      //   var ypt = y(targetData.y);

      //   // var currScale = zoomTransform(select('#svg-id').node()).k; //can choose to keep current zoom

      //   // translate and scale svg to center on target circle; after zoom ends, dot will be colored as target circle
      //   select('#svg-id')
      //     .transition()
      //     .duration(1500) //2500
      //     .call(
      //       zoomBehavior.transform,
      //       zoomIdentity
      //         .translate(dims.current.innerWidth / 2, dims.current.innerHeight / 2)
      //         .scale(15) //zoom level when panning to selected circle
      //         .translate(-xpt, -ypt)
      //     );
      // });
      // -------------------------------------------------------------------------

      // ------------ first implementation of autocomplete ---------------------

      // var result = document.querySelector('.output');
      // var Arr = [
      //   'India',
      //   'USA',
      //   'China',
      //   'Netherlands',
      //   'Nepal',
      //   'Japan',
      //   'Australia',
      // ];

      // // auto complete function
      // function autoComplete(Arr, Input) {
      //   return Arr.filter((e) =>
      //     e.toLowerCase().includes(Input.toLowerCase())
      //   );
      // }

      // function getValue(val) {
      //   // if no value
      //   if (!val) {
      //     result.innerHTML = '';
      //     return;
      //   }

      //   // search goes here
      //   var data = autoComplete(Arr, val);

      //   // append list data
      //   var res = '<ul class="scatter">';
      //   data.forEach((e) => {
      //     res += '<li class="scatter">' + e + '</li>';
      //   });
      //   res += '</ul>';
      //   result.innerHTML = res;
      // }
      // ------------------------------------------------------------------------------

      // ----------- highlight multiple logic - not using right now -------------------
      // randomButton2.on('click', function (event) {
      //   // var index = getRandomIntInclusive(dataMin, dataMax);
      //   // indices = [195, 492, 435, 152];
      //   indices = [195, 196, 197, 198];
      //   // extract index of target circle to override targetId from single selection
      //   targetId = indices[0];
      //   targetCircle = select(`#circle-${targetId}`);
      //   var targetData = targetCircle.data()[0];

      //   // FOR ZOOMING TO AREA CONTAINING ALL TARGET INDICES : DO AFTER HIGHLIGHT
      //   // get min/max of x & y coords to determine zooming bounds
      //   // var xcoords = [];
      //   // var ycoords = [];
      //   // indices.forEach((ind) => {
      //   //   xcoords.push(select(`#circle-${ind}`).data()[0].x);
      //   //   ycoords.push(select(`#circle-${ind}`).data()[0].y);
      //   // });
      //   // var x0 = x(min(xcoords));
      //   // var x1 = x(max(xcoords));
      //   // var y0 = y(min(ycoords));
      //   // var y1 = y(max(ycoords));
      //   var xpt = x(targetData.x);
      //   var ypt = y(targetData.y);

      //   select('#svg-id')
      //     .transition()
      //     .duration(1500) //2500
      //     .call(
      //       zoomBehavior.transform,
      //       zoomIdentity
      //         .translate(dims.current.innerWidth / 2, dims.current.innerHeight / 2)
      //         .scale(15) //zoom level when panning to selected circle
      //         .translate(-xpt, -ypt)
      //     );

      //   // // var currScale = zoomTransform(select('#svg-id').node()).k; //can choose to keep current zoom

      //   // translate and scale svg to center on target circle; after zoom ends, dot will be colored as target circle
      //   // select('#svg-id')
      //   //   .transition()
      //   //   .duration(1500) //2500
      //   //   .call(
      //   //     zoomBehavior.transform,
      //   //     zoomIdentity
      //   //       .translate(dims.current.innerWidth / 2, dims.current.innerHeight / 2)
      //   //       .scale(
      //   //         // scale depends on bounds
      //   //         Math.min(
      //   //           zoomMax,
      //   //           0.9 /
      //   //             Math.max(
      //   //               (x1 - x0) / dims.current.innerWidth,
      //   //               (y1 - y0) / dims.current.innerHeight
      //   //             )
      //   //         )
      //   //       )
      //   //       .translate(-(x0 + x1) / 2, -(y0 + y1) / 2) // translate to center of min/max x & y bounds
      //   //   );
      // });
      // });

      // const endall = (transition, callback) => {
      //   if (typeof callback !== 'function')
      //     throw new Error('Wrong callback in endall');
      //   if (transition.size() === 0) {
      //     callback();
      //   }
      //   let n = 0;
      //   transition
      //     .each(function () {
      //       ++n;
      //     })
      //     .on('end', function () {
      //       if (!--n) callback.apply(this, arguments);
      //     });
      // };

      chart.transition().on('end', () => {
        setFirstRenderDone(true);
      });
    }
  }, [dims.current, width, height, data]);

  useEffect(() => {
    if (searchTerm && firstRenderDone) {
      defer(() => highlightFnRef.current(searchTerm, data));
    }
  }, [searchTerm, data, firstRenderDone]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{ height: '100%' }}
    />
  );
};

ScatterPlot.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string,
  data: PropTypes.array,
  // dataUrl: PropTypes.string,
  // searchTerm: PropTypes.string,
};

export default withResizeDetector(ScatterPlot, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
