import Tracker from '@openreplay/tracker';
import { createGraphqlMiddleware } from '@openreplay/tracker-graphql';
import { environment } from '../../environments/environment';
import { RequestResponseData } from '@openreplay/tracker/lib/modules/network';
import { elfOpenReplayLogger } from './open-replay-elf';
import { isArray, pick, trim } from 'lodash';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ToastId, useToast } from '@chakra-ui/react';

const isLocalHost = window.location.hostname === 'localhost';
const isProd = environment.production;

const tracker = new Tracker({
  __DISABLE_SECURE_MODE: isLocalHost, // for local use only
  projectKey: environment.OPEN_REPLAY_PROJECT_KEY, // this key is an empty string for all environments besides local dev and prod
  network: {
    sessionTokenHeader: false,
    failuresOnly: false,
    ignoreHeaders: false,
    capturePayload: true,
    captureInIframes: true,
    sanitizer: (data: RequestResponseData) => {
      const url = new URL(data.url);
      // if not in allowed open replay hosts, remove request and response body
      // if (!openReplayAllowedHosts.includes(url.origin)) {
      //   data.request.body = null;
      //   data.response.body = null;
      // }
      // if download request, remove response body
      if (url.pathname.includes('/download')) {
        data.response.body = null;
      }

      return data;
    },
    useProxy: true,
  },
});

export const recordGraphQL = tracker.use(createGraphqlMiddleware());

const ElfStateToSend: { [key: string]: string[] | boolean } = {
  filters: [
    'lastStartDate',
    'entities',
    'defaultLastMonth',
    'cachedDate',
    'activeIds',
  ],
  deliverables: true,
};

const initElfLogger = tracker.use(
  elfOpenReplayLogger({
    storeFilter(storeName) {
      return !storeName.includes('auth');
    },
    stateTransformer(storeName, state) {
      const stateKeysToStore = ElfStateToSend[storeName];
      if (isArray(stateKeysToStore)) {
        return { [storeName]: pick(state, stateKeysToStore) };
      }
      return stateKeysToStore
        ? { [storeName]: state }
        : { [storeName]: { note: 'Real state not sent' } };
    },
  })
);

interface OpenReplayState {
  tracker: Tracker;
  recordGraphQL: typeof recordGraphQL;
}

const OpenReplayContext = createContext<OpenReplayState>({
  tracker,
  recordGraphQL,
});

const POLL_RATE = 60000;
export const OpenReplayProvider = ({ children }: PropsWithChildren) => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId | null>(null);
  const lastMessageRef = useRef<string | null>(null);

  const envSuffix = (() => {
    const hostname = window.location.hostname;

    if (hostname === 'dashboard-stage.reveliolabs.com') {
      return 'stage';
    } else if (hostname === 'dashboard.reveliolabs.com') {
      return 'prod';
    } else {
      return 'dev';
    }
  })();

  const checkFlags = useCallback(() => {
    tracker.reloadFlags();

    const flag = tracker.getFeatureFlag(`maintenance-window-${envSuffix}`);
    const message = flag && flag.value ? trim(flag.payload, `'\\"'`) : null;

    if (message === lastMessageRef.current) {
      return;
    }

    lastMessageRef.current = message;

    if (message) {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
      const id = toast({
        title: 'Site under maintenance',
        description: message,
        status: 'error',
        duration: null,
        position: 'top-right',
      });
      toastIdRef.current = id;
    } else if (toastIdRef.current) {
      toast.close(toastIdRef.current);
      toastIdRef.current = null;
    }
  }, [toast, envSuffix]);

  useEffect(() => {
    if (isProd) {
      tracker.start().then(() => {
        initElfLogger();
        checkFlags();
        const intervalId = setInterval(checkFlags, POLL_RATE);

        return () => clearInterval(intervalId);
      });
    }

    return () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
      if (isProd) tracker.stop();
    };
  }, [checkFlags, toast]);

  return (
    <OpenReplayContext.Provider value={{ tracker, recordGraphQL }}>
      {children}
    </OpenReplayContext.Provider>
  );
};

export const useOpenReplay = () => useContext(OpenReplayContext);
