import { Tab } from '@revelio/data-access';
import { useGetLoggedInUser } from '@revelio/auth';
import { useQuery } from 'urql';
import { GetUserReportsQuery } from '../../deliverables/status/status.api';
import { useMemo } from 'react';

export const useUserBuiltReports = (userIds?: string[]) => {
  const { loggedInUser } = useGetLoggedInUser();
  const hasReports = loggedInUser?.tabs?.includes(Tab.Reports);
  const ids = useMemo(() => {
    if (userIds) {
      return userIds;
    }

    return loggedInUser?.id ? [loggedInUser.id] : [];
  }, [loggedInUser, userIds]);

  return useQuery({
    query: GetUserReportsQuery,
    variables: { ids },
    pause: !hasReports || ids.length === 0,
    requestPolicy: 'network-only',
  });
};
