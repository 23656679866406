import { useMemo } from 'react';
import { PrimaryFilters, Views } from '@revelio/core';
import { FilterSets } from '@revelio/filtering';
import { IViewFiltersDef, viewflow } from './viewflow';

export interface viewflowProps {
  view: Views;
  primaryFilter: PrimaryFilters;
}

const useViewflow = ({
  view,
  primaryFilter,
}: viewflowProps): IViewFiltersDef => {
  const lookupFunc = () => {
    return (
      viewflow[view][primaryFilter] ?? {
        primaryViewFilter: [],
        selectableFilters: [],
        filterSet: FilterSets.GLOBAL,
      }
    );
  };
  return useMemo(lookupFunc, [view, primaryFilter]);
};

export default useViewflow;
