import DashboardPage from '../DashboardPage';
import { Box, Flex, Grid, GridItem, Text, VStack } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import PageCard from './PageCard';
import { pageCardData } from './landingCardData';
import { authStore, AuthStoreRootProps } from '@revelio/auth';
import LandingSearch from './LandingSearch';
import './landing.css';
import { useMount } from 'react-use';
import { setGlobalLoaderEnableState } from '@revelio/core';
import { Tab } from '@revelio/data-access';

const Landing = () => {
  const { user, ory }: AuthStoreRootProps = authStore.getValue();
  const name = ory?.name;
  const firstName = name ? `, ${name?.split(' ')[0]}` : '';
  const userTabs = user?.tabs || [];
  const hasCompositions = userTabs.includes(Tab.Overview);
  const hasTalentDiscovery = userTabs.includes(Tab.TalentDiscovery);
  const boxCount = (hasCompositions ? 3 : 0) + (hasTalentDiscovery ? 1 : 0);
  const tmplRepeat = `repeat(${boxCount}, 1fr)`;
  useMount(() => setGlobalLoaderEnableState('DISABLE', 2000));

  return (
    <DashboardPage title={[`Welcome${firstName}!`]} loaderActiveOnInit={false}>
      <Grid
        height="100%"
        templateRows="1fr"
        templateColumns="1fr"
        data-testid="plots-grid"
      >
        <GridItem rowSpan={1} colSpan={1}>
          <Card h="100%">
            <Box h="100%" p={6}>
              <Flex
                h="100%"
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <VStack alignItems="center" gap={12}>
                  <Text fontSize="4xl" fontWeight="semibold" textAlign="center">
                    Understand the world's workforce
                  </Text>
                  <Grid
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      md: 'repeat(2, 1fr)',
                      lg: user ? tmplRepeat : 'repeat(4, 1fr)',
                    }}
                    gap={6}
                  >
                    {pageCardData.map((props) => (
                      <GridItem key={props.path}>
                        <PageCard
                          tab={props.tab}
                          heading={props.heading}
                          description={props.description}
                          bgColor={props.bgColor}
                          hoverBgColor={props.hoverBgColor}
                          path={props.path}
                          icon={props.icon}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                  {user ? (
                    userTabs.includes(Tab.Overview) && <LandingSearch />
                  ) : (
                    <LandingSearch />
                  )}
                </VStack>
              </Flex>
            </Box>
          </Card>
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};

export default Landing;
