import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { ParsedResume } from '../resumeTypes';

interface SkillsRawProps {
  data?: ParsedResume['skills']['raw'];
  variant?: string;
}

export default function SkillsRaw({ data, variant }: SkillsRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionDate']}>{data?.join(', ')}</Text>
    </Box>
  );
}
