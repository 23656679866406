export type ScoreItem = {
  playerid: string;
  email: string;
  salary: number;
  seniority: string;
  role: string;
  msa: string;
  isNew?: boolean;
};

export type RankedScoreItem = ScoreItem & { rank: number };

export type Leaderboard = ScoreItem[];

export type RankedLeaderboard = RankedScoreItem[];

export const getUpdatedLeaderboard = (
  leaderboard: Leaderboard,
  newScore: {
    playerid: string;
    email: string;
    salary: number;
    seniority: string;
    role: string;
    msa: string;
  }
): {
  updatedLeaderboard: Leaderboard;
  truncatedLeaderboard: RankedLeaderboard;
} => {
  const index = leaderboard.findIndex((config) => {
    return config.salary <= newScore.salary;
  });

  const updatedLeaderboard = [...leaderboard];

  if (index === -1) {
    updatedLeaderboard.push(newScore);
  } else {
    updatedLeaderboard.splice(index, 0, newScore);
  }

  const insertionIndex = index === -1 ? updatedLeaderboard.length - 1 : index;

  // const start = Math.max(0, insertionIndex - 2);

  const start = Math.max(0, insertionIndex - 1);
  // const end = Math.min(updatedLeaderboard.length, insertionIndex + 3);

  const end = Math.min(updatedLeaderboard.length, insertionIndex + 2);

  const threeBefore = updatedLeaderboard.slice(start, insertionIndex);

  const threeAfter = updatedLeaderboard.slice(insertionIndex + 1, end);

  const truncatedLeaderboard = [
    ...threeBefore,
    { ...newScore, isNew: true },
    ...threeAfter,
  ];

  return {
    updatedLeaderboard: updatedLeaderboard,
    truncatedLeaderboard: truncatedLeaderboard.map((score, i) => ({
      rank: start + i + 1,
      ...score,
    })),
  };
};
