import { isEqual, replace, uniq } from 'lodash';
import {
  PipelineColumnType,
  PipelineType,
  PostingSource,
  S3Delivery,
  Deliverable as APIDeliverable,
} from '@revelio/data-access';
import { ColumnSet, DeliverableIcons } from '@revelio/layout';
import {
  deliverablesStore,
  getDuplicateDatasetNaming,
} from '../deliverables.repository';
import {
  CompletePostingPipeline,
  Deliverable as DataBuilderDeliverable,
  isDataBuilderDeliverable,
  isPostingsPipeline,
} from '../deliverables.model';
import {
  DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION,
  DEMOGRAPHIC_BREAKDOWN_OPTION,
  GEO_BREAKDOWN_OPTION,
  NEW_PIPELINE_CONFIGURATION_SELECT_OPTION,
  getPipelineConfigurationValue,
} from '../columns/saved-pipeline-configurations/saved-pipeline-configuration-select-helpers';
import { environment } from '../../../environments/environment';

export enum DeliverySelection {
  DOWNLOAD = 'download',
  SNOWFLAKE = 'snowflake_delivery',
  API = 'api',
  S3 = 's3_delivery',
}
export const adminDeliveryOptions: ColumnSet<DeliverySelection>[] = [
  {
    heading: null,
    columns: [
      {
        id: DeliverySelection.DOWNLOAD,
        detail:
          "Receive your data as a zipped package that contains all the files you've selected",
        icon: DeliverableIcons.DOWNLOAD,
      },
      {
        id: DeliverySelection.SNOWFLAKE,
        label: 'Snowflake Database',
        detail: 'Receive your data in snowflake.',
        icon: DeliverableIcons.SNOWFLAKE,
      },
      {
        id: DeliverySelection.S3,
        label: 'AWS S3 Bucket',
        detail: 'Receive your data in a private S3 Bucket.',
        icon: DeliverableIcons.AWS_S3_BUCKET,
      },
    ],
  },
];

export const getClientDeliveryOptions = ({
  hasSnowflakeDefaultLocation,
  hasS3DefaultLocation,
}: {
  hasSnowflakeDefaultLocation: boolean;
  hasS3DefaultLocation: boolean;
}): ColumnSet<DeliverySelection>[] => [
  {
    ...adminDeliveryOptions[0],
    columns: adminDeliveryOptions[0].columns.map((option) => {
      if (option.id === DeliverySelection.DOWNLOAD) {
        return option;
      }

      if (option.id === DeliverySelection.SNOWFLAKE) {
        return {
          ...option,
          isDisabled: !hasSnowflakeDefaultLocation,
        };
      }

      if (option.id === DeliverySelection.S3) {
        return {
          ...option,
          isDisabled: !hasS3DefaultLocation,
        };
      }

      return {
        ...option,
        isDisabled: true, // in case any new delivery options are added, it'll be disabled by default for clients
      };
    }),
  },
];
const deliveryCheckboxValues = adminDeliveryOptions[0].columns.map(
  (option) => option.id
);
export type DeliverCheckoxValues = typeof deliveryCheckboxValues;

export enum AddingOrEditingConfig {
  Adding = 'adding',
  Editing = 'editing',
}

export const DOWNLOAD_DELIVERY_BUCKET = 'revelio-data-builder';
export const DOWNLOAD_DELIVERY_FILE_PREFIX =
  environment.DOWNLOAD_DELIVERY_FILE_PREFIX;
export const OLD_DOWNLOAD_DELIVERY_BUCKET = 'info0';
export const OLD_DOWNLOAD_DELIVERY_FILE_PREFIX = 'revelio_data_builder';
export const isDeliverableDownloadable = (
  s3Delivery: S3Delivery | undefined | null
) => {
  return (
    s3Delivery &&
    ((s3Delivery.s3_bucket === DOWNLOAD_DELIVERY_BUCKET &&
      s3Delivery.s3_file_prefix.includes(DOWNLOAD_DELIVERY_FILE_PREFIX)) ||
      (s3Delivery.s3_bucket === OLD_DOWNLOAD_DELIVERY_BUCKET &&
        s3Delivery.s3_file_prefix.includes(OLD_DOWNLOAD_DELIVERY_FILE_PREFIX)))
    // backend always takes this prefix for download option. Only the actually saved link location in S3 changes depending on environment
  );
};

const buildDownloadUrl = ({
  file,
  s3Delivery,
}: {
  file: string;
  s3Delivery: S3Delivery;
}) => {
  return `https://${s3Delivery.s3_bucket}.s3.us-east-2.amazonaws.com/${s3Delivery.s3_file_prefix}/${file}_file.zip`;
};

export const PipelineTypeFilesLookup: {
  [key in PipelineType]: string[];
} = {
  [PipelineType.WfDynam]: ['workforce_dynamics'],
  [PipelineType.SkillDynam]: ['skill_dynamics'],
  [PipelineType.Posting]: ['posting_dynamics'],
  [PipelineType.PostingsIndividual]: ['postings_individual'],
  [PipelineType.Transition]: ['transition_inflow', 'transition_outflow'],
  [PipelineType.SentimentScores]: ['sentiment_scores'],
  [PipelineType.IndividualReviews]: ['sentiment_individual_reviews'],
  [PipelineType.ReviewTrends]: ['sentiment_review_trends'],
  [PipelineType.Layoffs]: ['layoffs'],
  [PipelineType.Individual]: [
    'individual_position',
    'individual_user',
    'individual_user_skill',
    'individual_user_education',
  ],
  [PipelineType.CompanyInfo]: ['full_company_ref'],
};

export const getPipelineDownloadFiles = ({
  pipelineType,
  s3Delivery,
}: {
  pipelineType: PipelineType;
  s3Delivery: S3Delivery;
}) => {
  const COMPANY_REF_DOWNLOAD = {
    label: 'full_company_ref',
    link: buildDownloadUrl({
      file: PipelineTypeFilesLookup[PipelineType.CompanyInfo][0],
      s3Delivery,
    }),
  };
  const pipelineTypeToFileDownloadLookup: {
    [key in PipelineType]: { label: string; link: string }[];
  } = {
    [PipelineType.WfDynam]: [
      {
        label: 'workforce_dynamics',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.WfDynam][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.SkillDynam]: [
      {
        label: 'skill_dynamics',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.SkillDynam][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.Posting]: [
      {
        label: 'posting_dynamics',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Posting][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.PostingsIndividual]: [
      {
        label: 'postings_individual',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.PostingsIndividual][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.Transition]: [
      {
        label: 'transition_inflow',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Transition][0],
          s3Delivery,
        }),
      },
      {
        label: 'transition_outflow',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Transition][1],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.SentimentScores]: [
      {
        label: 'sentiment_scores',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.SentimentScores][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.IndividualReviews]: [
      {
        label: 'individual_reviews',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.IndividualReviews][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.ReviewTrends]: [
      {
        label: 'review_trends',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.ReviewTrends][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.Layoffs]: [
      {
        label: 'layoffs',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Layoffs][0],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.Individual]: [
      {
        label: 'individual_position',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Individual][0],
          s3Delivery,
        }),
      },
      {
        label: 'individual_user',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Individual][1],
          s3Delivery,
        }),
      },
      {
        label: 'individual_user_skill',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Individual][2],
          s3Delivery,
        }),
      },
      {
        label: 'individual_user_education',
        link: buildDownloadUrl({
          file: PipelineTypeFilesLookup[PipelineType.Individual][3],
          s3Delivery,
        }),
      },
    ],
    [PipelineType.CompanyInfo]: [COMPANY_REF_DOWNLOAD],
  };
  return pipelineTypeToFileDownloadLookup[pipelineType];
};

export function getDuplicateDeliverables<
  T extends DataBuilderDeliverable | APIDeliverable,
>(deliverables: T[]): T[] {
  const duplicateDatasets = uniq(
    deliverables
      .filter((d) => !(d as DataBuilderDeliverable).isExcluded)
      .map((d) => d?.pipeline?.pipeline_type)
      .filter((e, i, a) => a.indexOf(e) !== i)
  );
  return deliverables.filter(
    (e) =>
      duplicateDatasets.includes(e?.pipeline.pipeline_type) &&
      !(e as DataBuilderDeliverable).isExcluded
  );
}

const POSTING_SOURCE_LOCATION_NAMING_MAPPING = {
  [PostingSource.Ghr]: 'aggregator',
  [PostingSource.Linkup]: 'linkup',
  [PostingSource.Mixrank]: 'linkedin',
  [PostingSource.Revelio]: 'revelio',
  [PostingSource.Indeed]: 'indeed',
  [PostingSource.Unified]: 'unified',
};
export const getPrefilledNameByPipelineConfiguration = (
  pipeline: DataBuilderDeliverable
) => {
  const pipelineType = pipeline?.pipeline.pipeline_type as PipelineType;
  if (isPostingsPipeline(pipelineType)) {
    const sourceLabel =
      POSTING_SOURCE_LOCATION_NAMING_MAPPING[
        (pipeline.pipeline as CompletePostingPipeline).source
      ];
    return `postings_${sourceLabel}_${
      pipelineType === PipelineType.Posting ? 'dynamics' : 'individual'
    }`;
  }

  if (pipelineType === PipelineType.WfDynam) {
    const matchingPipelineConfigurationValue = getPipelineConfigurationValue({
      entityId: pipeline.id,
      selectedPipelineColumns: pipeline.pipeline
        .columns as PipelineColumnType[],
      savedPipelineConfigurationSelection: null,
    });
    if (
      matchingPipelineConfigurationValue &&
      !isEqual(
        matchingPipelineConfigurationValue,
        NEW_PIPELINE_CONFIGURATION_SELECT_OPTION
      )
    ) {
      const defaultLabel = {
        [DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION.value]: 'role',
        [DEMOGRAPHIC_BREAKDOWN_OPTION.value]: 'demo',
        [GEO_BREAKDOWN_OPTION.value]: 'geo',
      }[matchingPipelineConfigurationValue.value];
      return `wf_dynam_${defaultLabel}_breakdown`;
    }
  }

  return '';
};

export const getUniquePipelineLocation = ({
  deliverable,
  commonFilePrefix,
}: {
  deliverable: APIDeliverable | DataBuilderDeliverable;
  commonFilePrefix?: string;
}) => {
  const duplicateDatasetNaming = deliverablesStore.query(
    getDuplicateDatasetNaming
  );

  if (isDataBuilderDeliverable(deliverable)) {
    return duplicateDatasetNaming[deliverable.id];
  } else {
    return getUniqueFilePrefixName(
      deliverable.s3_delivery?.s3_file_prefix ||
        deliverable.snowflake_delivery?.snowflake_file_prefix,
      commonFilePrefix
    );
  }
};

export const getUniqueFilePrefixName = (
  filePrefix?: string,
  commonFilePrefix?: string
) => {
  const uniqueFilePrefixName = replace(
    filePrefix || '',
    commonFilePrefix || '',
    ''
  );
  // s3 = leading /, snowflake = leading _
  const prefixWithoutLeadingDivider = uniqueFilePrefixName.substring(1);
  return prefixWithoutLeadingDivider;
};
