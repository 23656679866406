import { Slider, SliderMark, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export const MarketTightnessSlider = ({
  marketTightness,
}: {
  marketTightness: string;
}) => {
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    setSliderValue(parseInt(marketTightness));
  }, [marketTightness]);

  return (
    <Slider value={sliderValue} defaultValue={sliderValue} mb={4}>
      <SliderMark
        value={sliderValue}
        textAlign="center"
        color="white"
        mt="-4.75vh"
        ml="-22px"
        w="14"
        fontSize="2vh"
      >
        {sliderValue}%
      </SliderMark>
      <SliderTrack
        h="14px"
        borderRadius="20px"
        bg="linear-gradient(90deg, #1966FF 0%, #1FECDF 37%, #FFC93B 67%, #FF2B38 100%)"
      />

      <SliderThumb borderRadius="10px" w="7px" h="37px" />
    </Slider>
  );
};
