// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  select,
  // selectAll,
  json,
  hierarchy,
  tree,
  zoom,
  zoomTransform,
  zoomIdentity,
  linkVertical,
  // scaleOrdinal,
  // scaleBand,
  scaleLinear,
  // min,
  // max,
} from 'd3';
// import { axisLeft, axisBottom } from "d3-axis";
// import { format as d3Format } from "d3-format";
import { useDims } from 'react-dims';
// import { roundedRect } from "../utilities/corner-radius.js";
// import { colors } from "../utilities/colors.js";
import { withResizeDetector } from 'react-resize-detector';

import '../d3-styles.scss';
import { get, isString } from 'lodash';

const TreeDiagram = ({
  name,
  heading,
  data,
  // dataUrl,
  searchTerm,
  defaultTerm,
  selectionDepth,
  splitText = false,
  margins = { top: 80, left: 0, bottom: 0, right: 0 },
  height,
  width,
  targetRef,
}) => {
  const dims = {};
  const highlightFnRef = useRef(() => null);

  useEffect(() => {
    if (data && targetRef.current && height && width) {
      // remove old svg
      select(`.svg-${name}`).remove();
      select(`.tooltip-${name}`).remove();
      select('.tree-slider').remove();
      // setup margins and inner dims
      dims.margin = margins;
      dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
      dims.innerWidth = width - (dims.margin.left + dims.margin.right);
      // setup svg node
      const node = targetRef.current;
      // let boundingRect = node.getBoundingClientRect();
      const svg = select(node).append('svg');
      svg
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('class', `svg-${name}`)
        .attr('id', 'svg-id');
      const chart = svg.append('g');
      chart.attr(
        'transform',
        `translate(${dims.margin.left}, ${dims.margin.top})`
      );

      const sliderMin = 0;
      const sliderMax = 100;

      let slider = select(targetRef.current)
        .append('div')
        .classed('tree-slider', true)
        .style('bottom', '40px')
        .style('right', '160px')
        .append('input')
        .datum({})
        .attr('type', 'range')
        .attr('min', sliderMin)
        .attr('max', sliderMax)
        .attr('value', 20)
        .attr('step', 1)
        .attr('id', 'slider')
        .style('position', 'absolute');
      // .style("top", dims.innerHeight + "px")
      // .style("left", dims.innerWidth - 80 + "px");

      //=============================================================================

      // PROPS REQUIRED:

      const generateTextWidth = (text, font) => {
        let canvas =
          generateTextWidth.canvas ||
          (generateTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = font;
        let metrics = context.measureText(text);
        return metrics.width;
      };

      const fontSize = 16;

      // Mike Bostock's text wrapping function, adjusted to first calculate # of wrapped lines we will need to determine initial y position of tspans
      function wrap(text, width) {
        // ---------- To wrap by word instead of width: -------------
        // text.each(function (d) {
        //   var text = select(this),
        //     words = text.text().split(/\s+/).reverse(),
        //     word,
        //     lineNumber = 0,
        //     lineHeight = 0.9,
        //     y = -10, //text.attr('y'),
        //     dy = words.length > 1 ? -1 * words.length : -1;
        //   // null out text
        //   text.text(null);
        //   // loop the words
        //   while ((word = words.pop())) {
        //     // build tspan
        //     var tspan = text
        //       .append('tspan')
        //       .attr('dy', ++lineNumber * lineHeight + dy + 'em')
        //       .attr('x', 0)
        //       .attr('y', y)
        //       .text(word);
        //   }
        // });
        // -----------------------------------------------------------
        text.each(function () {
          // first get number of lines that the wrapped text will take up - lineNumber2
          var text2 = select(this),
            words2 = text2.text().split(/\s+/).reverse(),
            word2,
            line2 = [],
            lineNumber2 = 0;
          while ((word2 = words2.pop())) {
            line2.push(word2);
            if (
              generateTextWidth(line2.join(' '), `600 ${fontSize}px Roboto`) > // TODO: CHANGE TO SOURCE SANS PRO WHEN ON NEWER BRANCH
              width
            ) {
              line2.pop();
              line2 = [word2];
              lineNumber2 += 1;
            }
          }

          var text = select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = 20 - lineNumber2 * 20, //text.attr('y')
            dy = parseFloat(text.attr('dy')),
            tspan = text
              .text(null)
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', dy + 'em');
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(' '));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(' '));
              line = [word];
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', y)
                .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                .text(word);
            }
          }
        });
      }

      var allIds = [];
      var idsToHide = [
        0, 700001, 700002, 700003, 700004, 700005, 700006, 700007,
      ];

      const tooltipWidth = 140;
      const tooltip = select(targetRef.current)
        .append('div')
        .style('width', tooltipWidth + 'px')
        .style('opacity', 0)
        .style('pointer-events', 'none')
        .style('display', null)
        .attr('class', 'tooltip-tree')
        .classed(`tooltip-${name}`, true);

      // get chart padding for positioning tooltip
      // var compStyles = getComputedStyle(document.querySelector(chartStyle));
      // const paddingLeft = parseFloat(
      //   compStyles.getPropertyValue("padding-left")
      // );
      // const paddingTop = parseFloat(compStyles.getPropertyValue("padding-top"));

      const paddingLeft = 0;
      const paddingTop = 0;

      const zoomMin = 0.5;
      const zoomMax = 3;
      // scale for slider
      let scale = scaleLinear()
        .domain([sliderMin, sliderMax])
        .range([zoomMin, zoomMax]);

      const mouseOver = (event, d) => {
        tooltip
          .style('opacity', 1)
          .style('display', null)
          .style(
            'transform',
            `translate(${event.offsetX + paddingLeft - tooltipWidth / 2}px, ${
              event.offsetY + paddingTop + 25
            }px)`
          )
          .text(`${d.data.topItems}`);
      };

      const mouseOut = () => {
        tooltip.style('opacity', 0).style('display', 'none');
      };

      const treeLayout = tree()
        .nodeSize([30, 100]) //dynamic tree size
        .separation((a, b) => {
          //this has to be a function. can reduce space between adjacent cousin nodes
          return a.parent === b.parent ? 4 : 4;
        });
      const root = hierarchy(data);
      root.x0 = 0;
      root.y0 = dims.innerWidth / 2;
      const rootDesc = root.descendants();
      root.descendants().forEach((d, i) => {
        d.id = d.data.id; // id for each node
        allIds.push(d.data.id);
        d._children = d.children;
        d.children = null;
        // TESTING THIS CODE TO SHOW PATH
        // if (d.depth > 1) {
        //   d.children = null;
        // }
      });
      //var nodes = tree(root);

      var diagonal = linkVertical()
        .x((d) => d.x)
        .y((d) => d.y);

      // function zoom(event) {
      //   gNode.attr('transform', event.transform);
      //   gLink.attr('transform', event.transform);
      // }
      // var zoomListener = zoom().scaleExtent([0.5, 3]).on('zoom', zoom);

      // function centerNode(source) {
      //   // var scale = 3; //d3.zoomTransform(); //zoomListener.scale()
      //   var x = -source.x0;
      //   var y = source.y0;
      //   // x = x * scale + width / 2;
      //   // y = y * scale + height / 2;
      //   // d3.select("g")
      //   //   .transition()
      //   //   .duration(750)
      //   //   .attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
      //   // zoomListener.scaleBy(scale);
      //   // zoomListener.translateBy([x, y]);

      //   svg
      //     .transition()
      //     .delay(500)
      //     .duration(3000)
      //     .call(
      //       zoomListener.transform,
      //       d3.zoomIdentity
      //         .translate(width / 2, height / 2)
      //         .scale(1)
      //         .translate(x, y)
      //     );
      // }

      function zoomEvent(event) {
        gNode.attr('transform', event.transform);
        gLink.attr('transform', event.transform);
        slider.property('value', scale.invert(event.transform.k));
      }
      var zoomListener = zoom()
        .scaleExtent([zoomMin, zoomMax])
        .on('zoom', zoomEvent);
      svg.call(zoomListener);

      slider.on('input', function (e) {
        const { value } = e.target;
        zoomListener.scaleTo(svg.transition().duration(0), scale(value));
      });

      // var svg = select('body')
      //   .append('svg')
      //   .attr('id', 'svg-id')
      //   .attr('width', dims.innerWidth)
      //   .attr('height', dims.innerHeight)
      //   .call(zoomListener)
      //   .append('g');
      // .attr(
      //   "transform",
      //   "translate(" + (margin.left + width / 2) + "," + margin.top + ")"
      // );

      // var randomButton = chart
      //   .append("foreignObject")
      //   .attr("width", dims.innerWidth / 3)
      //   .attr("height", 50)
      //   // .attr("transform", `translate(${-width / 2}, -40)`)
      //   .classed("foreign-object", true)
      //   .append("xhtml:button")
      //   .text("Click me!");

      const gLink = chart
        .append('g')
        .attr('fill', 'none')
        //.attr("stroke", "#555")
        //.attr('stroke-opacity', 0.4)
        .attr('stroke-width', 2);

      const gNode = chart
        .append('g')
        .attr('cursor', 'pointer')
        .attr('pointer-events', 'all');

      function update(source) {
        //const duration = d3.event && d3.event.altKey ? 2500 : 250;
        const nodes = root.descendants().reverse();
        const links = root.links();

        // Compute the new tree layout.
        treeLayout(root); //tree(root)

        let left = root;
        let right = root;
        root.eachBefore((node) => {
          if (node.x < left.x) left = node;
          if (node.x > right.x) right = node;
        });

        const transitionHeight =
          right.x - left.x + dims.margin.top + dims.margin.bottom;
        //const width = right.x - left.x + margin.left + margin.right;
        const transition = chart
          .transition()
          .duration(250) //250
          .attr('viewBox', [
            -dims.margin.left,
            left.x - dims.margin.top,
            dims.innerWidth,
            transitionHeight,
          ])
          .tween(
            'resize',
            window.ResizeObserver ? null : () => () => chart.dispatch('toggle')
          );

        // Update the nodes…
        const node = gNode.selectAll('g').data(nodes, (d) => d.id);

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node
          .enter()
          .append('g')
          .attr('transform', (d) => `translate(${source.x0},${source.y0})`)
          .attr('fill-opacity', 0)
          .attr('stroke-opacity', 0)
          .on('click', (event, d) => {
            d.children = d.children ? null : d._children;
            update(d);
          })
          .on('mousemove', mouseOver)
          .on('mouseout', mouseOut);

        nodeEnter
          .append('circle')
          .attr('r', 6)
          //.attr('fill', (d) => (d._children ? '#2D426A' : '#fff'))
          .attr('fill', (d) => {
            return d.class === 'found' ? '#5BD992' : '#2D426A';
          })
          //.attr('stroke-width', '1.5px')
          //.attr('stroke', '#2D426A')
          .style('opacity', (d) => {
            return [0, 1].includes(d.depth) ? 0 : 1; //[0, 1].includes(d.depth) ? 0 : 1;
          });

        nodeEnter
          .append('text')
          .attr('id', 'nodeSymbol')
          .attr('dx', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? -0.17 + 'em'
              : -0.28 + 'em';
          })
          .attr('dy', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? 0.25 + 'em'
              : 0.34 + 'em';
          })
          .style('fill', '#ffffff')
          .text((d) => {
            // no text for root and terminal nodes
            // if children are rendered, show - else show +
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? '-'
              : '+';
          })
          .style('font-size', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? '17px'
              : '12px';
          });
        //.attr("transform", (d) => `translate(${source.x0},${source.y0})`)
        //;

        nodeEnter
          .append('text')
          .classed('tree-node-text', true)
          // .style('font-size', '16px')
          // .style('font-size', (d) => {
          //   return d.depth === 1 ? '14px' : '12px';
          // })
          .style('opacity', (d) => {
            return [0, 1].includes(d.depth) ? 0 : 1;
          })
          .attr('dy', '-2em')
          .attr('y', 20) //-25)
          // .attr('dy', (d) => {
          //   return d.depth === 1 ? '-0.8em' : '0.4em';
          // }) //align text next to nodes
          // .attr('dx', (d) => {
          //   return d.depth === 1 || !d._children ? '0em' : '-0.5em';
          // })
          // .attr('y', (d) => (!d._children ? 10 : null))
          // .attr('text-anchor', (d) => {
          //   return d.depth === 1 || !d._children ? 'middle' : 'end';
          // })
          .attr('text-anchor', 'middle')
          .text((d) => (splitText ? d.data.label.split('/')[0] : d.data.label))
          .call(wrap, 100) //wrap text to make tree more compact
          .clone(true)
          .lower()
          .attr('stroke-linejoin', 'round')
          .attr('stroke-width', 10)
          .attr('stroke', 'white');

        // Transition nodes to their new position.
        const nodeUpdate = node
          .merge(nodeEnter)
          .transition(transition)
          .attr('transform', (d) => `translate(${d.x},${d.y})`)
          .attr('fill-opacity', 1)
          .attr('stroke-opacity', 1);

        nodeUpdate
          .select('#nodeSymbol')
          .attr('dx', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? -4.6 + 'px'
              : -3.9 + 'px';
          })
          .attr('dy', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? 7.5 + 'px'
              : 5 + 'px';
          })
          .text((d) => {
            // no text for root and terminal nodes
            // if children are rendered, show - else show +
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? '-'
              : '+';
          })
          .style('font-size', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? '30px'
              : '15px';
          })
          .style('font-weight', (d) => {
            return [0, 1].includes(d.depth) || !d._children
              ? ''
              : d.children
              ? ''
              : '600';
          });

        nodeUpdate
          .select('circle')
          // .attr('fill', (d) => {
          //   return d.children || !d._children ? '#fff' : '#2D426A';
          // })
          .attr('fill', (d) => {
            return d.class === 'found' ? '#5BD992' : '#2D426A';
          });
        // .attr('stroke', (d) =>
        //   d.class === 'found' ? 'orangered' : '#2D426A'
        // );

        // Transition exiting nodes to the parent's new position.
        const nodeExit = node
          .exit()
          .transition(transition)
          .remove()
          .attr('transform', (d) => `translate(${source.x},${source.y})`)
          .attr('fill-opacity', 0)
          .attr('stroke-opacity', 0);

        // Update the links…
        const link = gLink.selectAll('path').data(links, (d) => d.target.id);
        // Enter any new links at the parent's previous position.
        const linkEnter = link
          .enter()
          .append('path')
          .style('opacity', (d) => {
            return idsToHide.includes(d.source.id) ? 0 : 1;
          })
          .attr('d', (d) => {
            const o = { x: source.x0, y: source.y0 };
            return diagonal({ source: o, target: o });
          });

        // Transition links to their new position.
        const linkUpdate = link
          .merge(linkEnter)
          .transition(transition)
          .attr('d', diagonal);

        linkUpdate.style('stroke', (d) => {
          return d.target.class === 'found' ? '#5BD992' : '#DFE2E8';
        });
        // Transition exiting nodes to the parent's new position.
        link
          .exit()
          .transition(transition)
          .remove()
          .attr('d', (d) => {
            const o = { x: source.x, y: source.y };
            return diagonal({ source: o, target: o });
          });

        // Stash the old positions for transition.
        root.eachBefore((d) => {
          d.x0 = d.x;
          d.y0 = d.y;
        });
      }
      update(root);

      highlightFnRef.current = function test(jobNameOrId) {
        // get the id of the job
        var targetNode;
        if (isString(jobNameOrId)) {
          targetNode = rootDesc.find(
            (d) => d.data.label === jobNameOrId && d.depth === selectionDepth
          );
        } else {
          targetNode = rootDesc.find((d) => d.data.id === jobNameOrId);
        }

        if (targetNode) {
          var nodePath = [];
          function getParents(d) {
            var node = d;
            var id = node.id;
            nodePath.push(id);
            if (node.parent !== null) {
              node = node.parent;
              getParents(node);
            }
          }
          getParents(targetNode);
          nodePath.forEach(() => {
            root.descendants().forEach((d, i) => {
              if (!nodePath.includes(d.id)) {
                d.children = null;
                d.class = 'circle';
              } else if (d.id === nodePath[0]) {
                d.children = null;
                d.class = 'found';
              } else {
                d.children = d._children;
                d.class = 'found'; //for styling found nodes & their parent links
                update(d); // gives effect of nodes expanding from parents but WAY too slow for deep nodes
              }
              // update(d);
            });
          });
          // update(root);
          function centerNode(source) {
            var y = -source.y0;
            var x = -source.x0;

            var currScale = zoomTransform(select('#svg-id').node()).k; //can choose to keep current zoom

            // zoomListener.translateTo(
            //   select('#svg-id').transition().duration(700),
            //   x,
            //   y
            // );
            select('#svg-id')
              .transition()
              .duration(0) //1000
              .call(
                zoomListener.transform,
                zoomIdentity
                  .translate(dims.innerWidth / 2, dims.innerHeight * 0.7)
                  .scale(0.8) //zoom out when panning to selected node
                  //.scale(currScale)
                  .translate(x, y)
              );
          }
          centerNode(targetNode);
        }
      };
      // update(root);
      highlightFnRef.current(defaultTerm);
    }
  }, [dims, targetRef.current, width, height, data]);

  useEffect(() => {
    if (searchTerm) {
      highlightFnRef.current(searchTerm);
    }
  }, [searchTerm]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{ height: '100%' }}
    />
  );
};

TreeDiagram.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string,
  data: PropTypes.object,
  // dataUrl: PropTypes.string.isRequired,
  // chartStyle: PropTypes.string,
  // searchTerm: PropTypes.string,
  // defaultTerm: PropTypes.string,
  // selectionDepth: PropTypes.number,
  // splitText: PropTypes.bool,
};

export default withResizeDetector(TreeDiagram, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
