import {
  As,
  Flex,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Text,
} from '@chakra-ui/react';
import { CircleIcon } from '@revelio/layout';

import { PopoverTrigger } from '@revelio/core';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';

export const CONFIGURATION_SUMMARY_HEADING_PROPS = {
  mt: '5',
  pl: '5',
  as: 'h3' as As,
  fontWeight: '400',
  fontSize: 'sm',
  color: 'text.primary',
};

const CopyButton = ({ value }: { value: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      <IconButton
        icon={copied ? <CheckIcon color="green.500" /> : <CopyIcon />}
        aria-label="copy value"
        variant="unstyled"
        size="s"
        color={copied ? 'green.500' : 'navyBlue.500'}
        className="copy-button"
        visibility="hidden"
      />
    </CopyToClipboard>
  );
};

export const DeliverableConfigurationSummary = ({
  details,
}: {
  details: DeliverableConfigurationSummaryDetail[];
}) => {
  return (
    <List mt="2" pl="10" spacing={3}>
      {details.map((config, i) => (
        <ListItem
          key={`${config.label}-${i}`}
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          _hover={{
            '.copy-button': {
              visibility: 'visible',
            },
          }}
        >
          <Flex alignItems="flex-start">
            <ListIcon as={CircleIcon} color="green.500" boxSize="2" mt="5px" />
            <Text color="text.primary" fontSize="xs">
              {config.label ? (
                <>
                  <Text as="b">{config.label}</Text>: {config.value}
                </>
              ) : (
                config.value
              )}
            </Text>
          </Flex>
          {config.copyValue ? <CopyButton value={config.copyValue} /> : null}
        </ListItem>
      ))}
    </List>
  );
};

export const DetailsSummaryTrigger = ({ text }: { text: string }) => (
  <Text
    as="u"
    textDecoration="none"
    borderBottom="1px dotted #000"
    mr="2px"
    cursor="help"
  >
    {text}
  </Text>
);

export const SummaryPopover = ({
  trigger,
  children,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
}) => (
  <Popover trigger="hover" placement="right">
    <PopoverTrigger>{trigger}</PopoverTrigger>
    <PopoverContent w="max-content">
      <PopoverArrow />
      <PopoverBody>{children}</PopoverBody>
    </PopoverContent>
  </Popover>
);

export type DeliverableConfigurationSummaryDetail = {
  label?: string;
  value: React.ReactNode | string;
  copyValue?: string;
};
