import { Flex } from '@chakra-ui/layout';
import { PrimaryFilterChip } from '../primary-filter-chip';
import { get } from 'lodash';
import { PlotColourLookup, updatePlotColorLookup } from '@revelio/core';
import { useEffect } from 'react';
import { colors } from '@revelio/d3';
import { useDroppable } from '@dnd-kit/core';

export interface FilterChipsToggleColumnProps {
  entities: any;
  showColors?: boolean;
  onChipToggle?: any;
  setIsHovering?: React.Dispatch<boolean>;
}

export const FilterChipsToggleColumn = ({
  entities,
  showColors,
  onChipToggle,
  setIsHovering,
}: FilterChipsToggleColumnProps) => {
  const assignColorsToActiveFilters = (filters: any[], colors: string[]) => {
    const colorLookup: PlotColourLookup = {};
    let index = 0;
    filters?.forEach((f) => {
      colorLookup[`${get(f, 'selectionListId')}__${f.id}`] = {
        id: f.id,
        columnName: f.selectionListId,
        shortName: f.shortName,
        longName: f.longName,
        color: colors[index],
      };

      index += 1;
    });

    return colorLookup;
  };

  useEffect(() => {
    const colorLookup = assignColorsToActiveFilters(entities, colors);

    updatePlotColorLookup(colorLookup);
  }, [entities]);

  const { setNodeRef } = useDroppable({
    id: 'droppable-chips-container',
  });

  return (
    <Flex
      minH="22px"
      ref={setNodeRef}
      onMouseEnter={() => setIsHovering?.(true)}
      onMouseLeave={() => setIsHovering?.(false)}
      data-testid="filter-chips-toggle-column"
    >
      {entities.map((entity: any, index: any) => {
        return (
          <PrimaryFilterChip
            key={`${entity.id}-${index}`}
            filterItem={entity}
            isActive={entity.isActive}
            onClick={(e: Event) => {
              e.stopPropagation();
              onChipToggle(entity.selectionListId, entity.id);
            }}
            showColors={showColors}
            isMin={
              entities.filter((ent: any) => get(ent, 'isActive', true))
                .length == 1
            }
          />
        );
      })}
    </Flex>
  );
};
