// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  selectAll,
  select,
  scaleOrdinal,
  scaleBand,
  scaleLinear,
  min,
  max,
} from 'd3';
import { line, curveBasis } from 'd3-shape';
import { axisLeft, axisBottom } from 'd3-axis';
import { format as d3Format } from 'd3-format';
import { roundedRect } from '../utilities/corner-radius.js';
import { colors } from '../utilities/colors.js';
import { generateHTMLHistogram } from '../utilities/generate-html-histogram.js';
import { removeLoadingStatus } from '@revelio/core';
import { endall } from '../utilities/endall-transition.js';
import '../d3-styles.scss';

/**
 * Generate a Histogram
 *
 * @param plotConfigs - configs for generating the plot
 * @param downloadOptions - oprions for downloading plot as image
 *
 * @returns if getSVGNode is true, then return the svg node of the rendered plot.
 * Otherwise, void.
 */
export const HistogramGenerator = (plotConfigs, downloadOptions) => {
  let { name, height, width } = plotConfigs;
  const {
    data,
    ttMainFormat,
    ttSecondaryFormat,
    yAxisFormat,
    xAxisFormat,
    targetRef,
    showDensity,
    requestHash,
  } = plotConfigs;

  const { getSVGNode, svgHeight, svgWidth, containerId } = downloadOptions;

  const dims = {};

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();
    // setup margins and inner dims
    dims.margin = { top: 36, left: 36, bottom: 20, right: 10 };
    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);
    // setup svg node
    const node = targetRef?.current;
    const svg = node
      ? select(node).append('svg')
      : select(containerId).append('svg');
    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    //=============================================================================

    // PROPS REQUIRED: ttMainFormat (always perc), ttSecondaryFormat (always count), yAxisFormat, xAxisFormat

    // find first and last buckets with non-zero values to determine x axis range
    const minId = [];
    const maxId = [];
    data.forEach((company) => {
      const reversed = [];
      company.value.forEach((element) => {
        reversed.unshift(element);
      });
      minId.push(company.value.find((val) => val.value > 0).id);
      maxId.push(reversed.find((val) => val.value > 0).id);
    });

    // get starting points for buckets, starting with buckets with non-zero values and ending with last bucket with non-zero value
    // note that we are only filtering out zero values at the beginning and end of dataset
    const bucketStartPoints = Array.from(
      new Set(
        data
          .map((company) =>
            company.value
              .filter((val) => val.id >= min(minId) && val.id <= max(maxId))
              .map((val) => val.start)
          )
          .flat()
      )
    ).sort((a, b) => a - b);

    const step =
      bucketStartPoints.slice(-1)[0] - bucketStartPoints.slice(-2)[0];

    const xScale = scaleBand()
      .domain(bucketStartPoints)
      .range([0, dims.innerWidth])
      .padding(0);

    const xScaleDensity = scaleLinear()
      .domain([min(bucketStartPoints), max(bucketStartPoints) + step])
      .range([0, dims.innerWidth]);

    const allValues = data
      .map((company) => company.value.map((val) => val.value))
      .flat();
    const yScale = scaleLinear()
      .domain([min(allValues), max(allValues)])
      .range([dims.innerHeight, 0]);

    const colorScale = scaleOrdinal()
      .domain(data.map((d) => d.metadata.longName))
      .range(colors);

    const tooltipWidth = 240;
    const tooltip = select(node)
      .append('div')
      .style('width', tooltipWidth + 'px')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      // .attr('hidden', true)
      .attr('display', null)
      .classed(`tooltip-${name}`, true)
      .classed('tooltip-stacked-bar-chart', true);

    let tooltipDataLen = 0;
    const mouseOver = (event, d) => {
      // change position of tooltip for last bar on charts positioned on the right of the page
      dims.innerWidth - xScale(d.start) < tooltipWidth / 2
        ? tooltip
            .classed('tooltip-stacked-bar-chart-right', true)
            .classed('tooltip-stacked-bar-chart', false)
        : tooltip
            .classed('tooltip-stacked-bar-chart', true)
            .classed('tooltip-stacked-bar-chart-right', false);

      const tooltipData = [];
      data.forEach((company) => {
        const valueFiltered = company.value
          .filter((row) => row.id === d.id)
          .map((row) => row.value);
        const countFiltered = company.value
          .filter((row) => row.id === d.id)
          .map((row) => row.metadata.count);
        tooltipData.push([
          company.metadata.longName,
          valueFiltered,
          d3Format(ttSecondaryFormat)(Math.round(countFiltered)),
        ]);
      });
      tooltipDataLen = tooltipData.length;

      const tooltipHeight =
        24 + // upper + lower tt padding
        28 + // title height
        tooltipDataLen * 21 + // # rows * height of each row
        7; // tt arrow height

      tooltip
        .style('opacity', 1)
        .attr('hidden', null)
        .style(
          'transform',
          `translate(${
            dims.innerWidth - xScale(d.start) < tooltipWidth / 2
              ? dims.margin.left - tooltipWidth + xScale(d.start) - 7
              : dims.margin.left -
                tooltipWidth / 2 +
                xScale(d.start) +
                xScale.bandwidth() / 2
          }px, ${
            dims.innerWidth - xScale(d.start) < tooltipWidth / 2
              ? dims.margin.top +
                yScale(
                  tooltipDataLen > 1
                    ? max([tooltipData[0][1][0], tooltipData[1][1][0]])
                    : tooltipData[0][1][0]
                ) -
                (tooltipHeight - 7) / 2
              : dims.margin.top +
                yScale(
                  tooltipDataLen > 1
                    ? max([tooltipData[0][1][0], tooltipData[1][1][0]])
                    : tooltipData[0][1][0]
                ) -
                tooltipHeight
          }px)`
        )
        .html(
          generateHTMLHistogram(
            d,
            tooltipData,
            colorScale,
            d3Format,
            ttMainFormat
          )
        );
    };

    const mouseOverLine = (event, d) => {
      tooltip.style('opacity', 1).attr('hidden', null);
    };

    // Remove tooltips
    const mouseOut = () => {
      // tooltip.style('opacity', 0).style('display', 'none');
      tooltip.style('opacity', 0).attr('hidden', true);
    };

    const yAxisGrid = axisLeft()
      .scale(yScale)
      .tickFormat('')
      .tickSize(-dims.innerWidth)
      .ticks(3);

    const xAxisGrid = axisBottom()
      .scale(xScale)
      .tickSize(-dims.innerHeight)
      .tickFormat('');

    // y gridlines
    chart
      .append('g')
      .attr('class', 'y axis-grid')
      .attr('transform', `translate(0,0)`)
      .call(yAxisGrid)
      .call((g) => g.selectAll('.domain').remove());

    // x gridlines
    chart
      .append('g')
      .attr('class', 'x axis-grid')
      .attr('transform', `translate(0,${dims.innerHeight})`)
      .call(xAxisGrid)
      .call((g) => g.selectAll('.domain').remove());

    // TODO: these inline styles are taken from the stylesheet.
    // Look into implementing grabbing stylesheet styles when
    // drawing SVG to canvas
    selectAll('.axis-grid line')
      .attr('stroke', '#e6e8ed')
      .attr('stroke-dasharray', '1,3')
      .attr('stroke-width', 1.3)
      .attr('stroke-linecap', 'round');

    // adjust tick size for x axis based on the tick values we are showing
    chart.selectAll('g.x.axis-grid line').attr('y2', function (d, i) {
      return i % 10 === 0 ? -dims.innerHeight : 0;
    });

    // add bars
    chart
      // enter a group for each company
      .selectAll('.company')
      .data(data)
      .join('g')
      .attr('class', 'company')
      .attr('fill', (d, i) => colorScale(d.metadata.longName)) //set bar color according to company
      .attr('stroke', (d) => colorScale(d.metadata.longName))
      .attr('stroke-width', data.length > 1 ? 0.5 : 0.6)
      .attr('stroke-opacity', data.length > 1 ? 0.25 : 0.35) // half the opacity we're using for the bars
      .on('mouseover', (e, d) => (hoveredCompany = d.metadata.longName)) //get company name for tooltip
      // enter a bar for each value per company
      .selectAll('path')
      .data((d) => d.value)
      .join('path')
      .attr('class', 'bar')
      // for rounded bars
      .attr('d', (d) => {
        return roundedRect(
          xScale(d.start),
          yScale(d.value),
          xScale.bandwidth(),
          dims.innerHeight - yScale(d.value),
          [1, 1, 0, 0] //round top corners of bar
        );
      })
      .attr('opacity', data.length > 1 ? 0.5 : 0.8)
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut);

    //add density plot
    if (showDensity) {
      chart
        .selectAll('.line')
        .data(data)
        .join('path')
        .attr('class', 'density-line-path')
        .attr('fill', 'none')
        .attr('stroke', (d) => colorScale(d.metadata.longName))
        .attr('stroke-width', 2)
        //.attr('cursor', 'pointer')
        .attr('d', (d) =>
          line()
            .curve(curveBasis)
            // .curve(curveNatural)
            .x((d) =>
              d.end !== null
                ? xScaleDensity(d.start + (d.end - d.start) / 2)
                : xScaleDensity(d.start)
            )
            // .x((d) => xScale(d.start + (d.end - d.start) / 2))
            // .x((d) => xScaleDensity(d.start + 2500))
            .y((d) => yScale(d.value))(d.value)
        )
        .attr('cursor', 'pointer')
        .on('mouseover', mouseOverLine)
        .on('mouseout', mouseOut);
    }

    const yAxisLeft = axisLeft()
      .scale(yScale)
      .ticks(5) //only a guideline, not guaranteed exactly 5 ticks will be displayed
      .tickFormat((d) => d3Format(yAxisFormat)(d));

    const xAxisBottom = axisBottom()
      .scale(xScale)
      .ticks(4)
      .tickFormat((d, i) => {
        return i % 10 === 0 ? d3Format(xAxisFormat)(d) : '';
      });

    // add axes
    chart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .classed('axis-label-histogram', true)
      .call(yAxisLeft)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove());

    chart
      .append('g')
      .attr('transform', `translate(0, ${dims.innerHeight})`)
      .classed('axis-label-histogram', true)
      .call(xAxisBottom)
      .call((g) => g.selectAll('.domain').remove())
      .call((g) => g.selectAll('line').remove());

    // add legend, but only show for >1 companies
    const legend = chart
      .append('foreignObject')
      .attr('width', dims.innerWidth)
      .attr('height', dims.margin.bottom - 15) //padding of 15px between top of legend and bottom bar
      .attr('class', 'legend')
      .attr('opacity', data.length > 1 ? 1 : 0) // only show legend for >1 companies
      .attr(
        'transform',
        `translate(${width / 2 - width / 1.95}, ${dims.innerHeight + 25})`
      )
      .append('xhtml:div')
      .style('position', 'relative')
      .style('bottom', '0px')
      .selectAll('g')
      .data(data)
      .join('g');

    legend
      .append('div')
      .append('span')
      .style('border-radius', '20%')
      .style('width', '10px')
      .style('height', '10px')
      .style('position', 'absolute')
      .style('background-color', (d) => colorScale(d.metadata.longName));

    legend
      .append('div')
      .append('text')
      .style('margin', '10px')
      .style('position', 'relative')
      .style('top', '-9px')
      .style('left', '4px')
      .attr('class', 'legend-text')
      .style('font-size', '10px')
      .text(function (d) {
        return d.metadata.shortName;
      });

    // position legend groups next to one another
    legend.style('float', 'left').style('margin-bottom', '-5px'); //margin-bottom -5px will move bottom line closer to top line
    chart
      .transition()
      .duration(0)
      .call(endall, () => {
        removeLoadingStatus([requestHash, 'tabChange']);
      });

    if (getSVGNode) {
      return svg.node();
    }
  }
};
