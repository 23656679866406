import { useObservable } from '@ngneat/react-rxjs';
import { map, pluck } from 'rxjs';

import { CompanySelectionTypes } from '@revelio/core';
import { useIsRevelioAdmin } from '@revelio/auth';
import { SelectionCategories, EntitySelectionsModal } from '@revelio/filtering';

import { CompanyRefTable } from './company-ref-table';
import { CompanyFavourites } from './company-favourites';
import CompanyMapping from './company-mapping/company-mapping';
import SubsidiaryMapping from './subsidiary-mapping/subsidiary-mapping';
import {
  selectSubsidiaryColumnMappings,
  updateSubsidiaryMappingPrimaryCompanies,
  updateSubsidiaryMappingPreviousStep,
  getSubsidiaryColumnMappings,
  resetSubsidiaryColumnMappings,
  resetSubsidiarySelectedColumns,
} from './subsidiary-mapping/subsidiary-mapping.repository';
import {
  deliverablesStore,
  getPipelineType,
  removeColumnsById,
} from '../deliverables.repository';
import { getPipelineCombinedCompanyColumns } from '../columns/company-column';
import { getCompanyMapping } from './company-mapping/company-mapping.respository';
import { getEntity } from '@ngneat/elf-entities';
import { Deliverable } from '../deliverables.model';
import { useState } from 'react';

type CompanySelectionActionProps = {
  entityId?: number;
  companyColumnSelectionModalOpen: CompanySelectionTypes | null;
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
  onCloseAction: () => void;
};

export const CompanySelectionActions = ({
  entityId,
  companyColumnSelectionModalOpen,
  setCompanyColumnSelectionModalOpen,
  onCloseAction,
}: CompanySelectionActionProps) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();
  const stepBackwards = (
    methodToSet: CompanySelectionTypes | null = CompanySelectionTypes.SELECT_OPTION
  ) => {
    setCompanyColumnSelectionModalOpen(methodToSet);
  };
  const [shouldAutoSubmit, setShouldAutoSubmit] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);

  const [primaryCompanies] = useObservable(
    deliverablesStore.pipe(
      selectSubsidiaryColumnMappings(),
      pluck('mappings', 'primary', 'subsidiaries'),
      map((companies) => {
        return companies.reduce((formattedState, currentCompany) => {
          const item = {
            has_subsidiaries: currentCompany.hasSubsidiaries,
            primary_name: currentCompany.title,
            raw_emp_count: currentCompany.headCount,
            rcid: currentCompany.rcid,
          };
          return { ...formattedState, [currentCompany.rcid]: item };
        }, {});
      })
    )
  );

  return (
    <>
      <CompanyFavourites
        id="company-favourites"
        entityId={entityId}
        isOpen={
          companyColumnSelectionModalOpen == CompanySelectionTypes.COMMON_SET
        }
        returnToPrevious={() => stepBackwards()}
        onClose={onCloseAction}
      />
      <CompanyMapping
        id="company"
        companyColumnSelectionModalOpen={companyColumnSelectionModalOpen}
        returnToPrevious={() => stepBackwards()}
        setCompanyColumnSelectionModalOpen={setCompanyColumnSelectionModalOpen}
        onClose={onCloseAction}
        shouldAutoSubmit={shouldAutoSubmit}
        setShouldAutoSubmit={setShouldAutoSubmit} // Pass the setter as well to reset it after submit
        submitIsLoading={submitIsLoading}
        setSubmitIsLoading={setSubmitIsLoading}
      />
      <CompanyRefTable
        id="company-selection"
        entityId={entityId}
        isOpen={
          companyColumnSelectionModalOpen == CompanySelectionTypes.DATABASE_REF
        }
        returnToPrevious={stepBackwards}
        onClose={onCloseAction}
      />
      <EntitySelectionsModal
        header={submitIsLoading ? 'Adding Companies...' : 'Select Companies'}
        isOpen={
          companyColumnSelectionModalOpen ==
          CompanySelectionTypes.COMPANIES_LIST
        }
        onClose={onCloseAction}
        onCancel={() => (isRevelioAdmin ? stepBackwards() : onCloseAction)}
        placeholderSelectionList={[
          SelectionCategories.COMPANY,
          SelectionCategories.INDUSTRY,
        ]}
        submitHandler={(updates) => {
          setShouldAutoSubmit(true);
          updateSubsidiaryMappingPrimaryCompanies(updates);
        }}
        entityMenuHandlers={[
          {
            text: 'Edit Subsidiaries',
            handler: (updates) => {
              setShouldAutoSubmit(false); // just in case
              updateSubsidiaryMappingPrimaryCompanies(updates);
              setCompanyColumnSelectionModalOpen(
                CompanySelectionTypes.SUBSIDIARY_MAPPING
              );
              updateSubsidiaryMappingPreviousStep(
                CompanySelectionTypes.COMPANIES_LIST
              );
            },
          },
        ]}
        submitIsLoading={submitIsLoading}
        setSubmitIsLoading={setSubmitIsLoading}
        submitText="Add Companies"
        closeOnSubmit={false}
        treeHeight={260}
        initialSelections={primaryCompanies}
      />
      <SubsidiaryMapping
        entityId={entityId}
        isOpen={
          companyColumnSelectionModalOpen ==
          CompanySelectionTypes.SUBSIDIARY_MAPPING
        }
        onClose={onCloseAction}
        setCompanyColumnSelectionModalOpen={setCompanyColumnSelectionModalOpen}
        shouldAutoSubmit={shouldAutoSubmit}
        setShouldAutoSubmit={setShouldAutoSubmit} // Pass the setter as well to reset it after submit
        submitIsLoading={submitIsLoading}
        setSubmitIsLoading={setSubmitIsLoading}
      />
    </>
  );
};

export const closeCompanySelection = ({
  entityId,
  setCompanyColumnSelectionModalOpen,
}: {
  entityId: Deliverable['id'];
  setCompanyColumnSelectionModalOpen: CompanySelectionActionProps['setCompanyColumnSelectionModalOpen'];
}) => {
  setCompanyColumnSelectionModalOpen(null);
  const deliverable = deliverablesStore.query(getEntity(entityId));
  if (
    !deliverable?.pipeline_input &&
    !deliverable?.company_reference &&
    !deliverable?.company_sets?.length
  ) {
    clearSelectCompanyState(entityId);
  }
};

export const clearSelectCompanyState = (entityId: Deliverable['id']) => {
  const pipelineType = getPipelineType({ entityId });
  removeColumnsById({
    entityId,
    ids: getPipelineCombinedCompanyColumns(pipelineType),
  });
};

export const onSelectCompanyOption = ({
  companySelectionModalToOpen,
  setCompanyColumnSelectionModalOpen,
}: {
  companySelectionModalToOpen: CompanySelectionTypes;
  setCompanyColumnSelectionModalOpen: CompanySelectionActionProps['setCompanyColumnSelectionModalOpen'];
}) => {
  const subsidiaryMapping = deliverablesStore.query(
    getSubsidiaryColumnMappings
  );
  const primaryCompanies = subsidiaryMapping.mappings.primary.subsidiaries;
  const choseSubsidiaryMappingFromList =
    companySelectionModalToOpen === CompanySelectionTypes.COMPANIES_LIST &&
    primaryCompanies.length;
  if (
    choseSubsidiaryMappingFromList &&
    subsidiaryMapping.previousStep === CompanySelectionTypes.COMPANY_MAPPING
  ) {
    // when choosing company list after partially completing company mapping,
    // reset subsidiary mapping so company mapping doesn't show up in company list selection
    deliverablesStore.update(
      resetSubsidiaryColumnMappings(),
      resetSubsidiarySelectedColumns()
    );
  }

  const isSubsidiaryMappingFromListSelection =
    choseSubsidiaryMappingFromList &&
    subsidiaryMapping.previousStep === CompanySelectionTypes.COMPANIES_LIST;
  const companyMapping = deliverablesStore.query(getCompanyMapping);
  const hasSubsidiaryMappingFromCompanyList =
    companySelectionModalToOpen === CompanySelectionTypes.UPLOAD_COMPANIES &&
    companyMapping;
  if (isSubsidiaryMappingFromListSelection) {
    setCompanyColumnSelectionModalOpen(
      CompanySelectionTypes.SUBSIDIARY_MAPPING
    );
  } else if (hasSubsidiaryMappingFromCompanyList) {
    setCompanyColumnSelectionModalOpen(CompanySelectionTypes.COMPANY_MAPPING);
  } else {
    setCompanyColumnSelectionModalOpen(companySelectionModalToOpen);
  }
};
