import { CompositionDataQuery } from '@revelio/data-access';
import {
  RoleBarChartsData,
  RoleSummaryMetric,
  SummaryEntities,
} from '../../types';

export const getRoleCompositionData = (
  data: CompositionDataQuery | undefined
): RoleBarChartsData => {
  const entities = data?.composition;
  if (!entities) {
    return {
      industries: null,
      skills: null,
      job_categories: null,
      geographies: null,
      // companies: null,
    };
  }

  const getData = (metric: RoleSummaryMetric) =>
    getBarChartMetricData(entities, metric);

  return {
    industries: getData(RoleSummaryMetric.Industries),
    // companies: getData(RoleSummaryMetric.Companies),
    skills: getData(RoleSummaryMetric.Skills),
    job_categories: getData(RoleSummaryMetric.Roles),
    geographies: getData(RoleSummaryMetric.Geographies),
  };
};

const getBarChartMetricData = (
  entities: SummaryEntities,
  metric: RoleSummaryMetric
) => {
  const topLevelMetadata = entities?.[0]?.metadata;
  const category = entities?.[0]?.metrics?.[metric]?.category;

  if (!category || !topLevelMetadata) {
    return null;
  }

  const maxCategoriesLength = category.length > 6 ? 7 : category.length;

  return [
    {
      id: topLevelMetadata.id,
      metadata: {
        longName: topLevelMetadata.longName,
        shortName: topLevelMetadata.shortName,
      },
      value: category
        .slice(0, maxCategoriesLength)
        .map((cat) => {
          const metaData = cat?.metadata;
          const value = cat?.timeseries?.[0]?.share;
          const count = cat?.timeseries?.[0]?.count;

          if (!metaData || value == null) {
            return null;
          }

          return {
            id: metaData.id,
            metadata: {
              longName: metaData.longName,
              shortName: metaData.shortName,
              count,
            },
            value: value,
          };
        })
        .filter(Boolean),
    },
  ];
};
