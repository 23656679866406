import { HttpResponse, graphql } from 'msw';
import { BrowserRouter } from 'react-router-dom';
import { render as renderRTL } from '@testing-library/react';
import { Provider as UrqlProvider, createClient, fetchExchange } from 'urql';

import { Role } from '@revelio/data-access';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@revelio/styles';

import {
  clientUserDetailsQueryData,
  CLIENT_USER_DETAILS_ID,
  userDetailsQueryData,
} from './userDetailsQuery';

const DataProviders = ({ children }: { children: React.ReactNode }) => (
  <UrqlProvider value={createClient({ url: '/', exchanges: [fetchExchange] })}>
    <ChakraProvider theme={theme} portalZIndex={40}>
      <BrowserRouter>{children}</BrowserRouter>
    </ChakraProvider>
  </UrqlProvider>
);

export const renderWithData = (ui: JSX.Element) => {
  return {
    ...renderRTL(ui, { wrapper: DataProviders }),
  };
};

export const GetRevelioAdminUserDetailsHandler = graphql.query(
  'GetUserDetails',
  ({ variables }) => {
    const { ids } = variables;
    if (ids[0] === CLIENT_USER_DETAILS_ID) {
      return HttpResponse.json({ data: clientUserDetailsQueryData() });
    }
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.RevelioAdmin }),
    });
  }
);

export const GetClientAdminUserDetailsHandler = graphql.query(
  'GetUserDetails',
  () => {
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.ClientAdmin }),
    });
  }
);

export const GetClientUserDetailsHandler = graphql.query(
  'GetUserDetails',
  () => {
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.ClientUser }),
    });
  }
);

export const GetTrialUserDetailsHandler = graphql.query(
  'GetUserDetails',
  () => {
    return HttpResponse.json({
      data: userDetailsQueryData({ role: Role.ClientUser, live: false }),
    });
  }
);
