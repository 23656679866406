import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { CertificateEntryRaw } from '../resumeTypes';

interface CertificateRawProps {
  data?: CertificateEntryRaw;
  variant?: string;
}

export default function CertificateRaw({ data, variant }: CertificateRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionDate']}>{data?.issue_date}</Text>
      <Text sx={styles['sectionTitle']}>{data?.certificate_name}</Text>
      <Text sx={styles['sectionDetails']}>{data?.issuing_organization}</Text>
      <Text sx={styles['sectionDetails']} mt="5px">
        {data?.additional_information}
      </Text>
    </Box>
  );
}
