/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  select,
  selectAll,
  scaleOrdinal,
  descending,
  color as d3Color,
} from 'd3';
import { format as d3Format } from 'd3-format';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-ignore */
import { sankey, sankeyRight, sankeyLinkHorizontal } from 'd3-sankey';
import { generateTextWidth } from '../../utilities/text-width';
import { queryLookup } from '../../sankey/lookups';
import '../../d3-styles.scss';
import { generateInlineStyles } from '../../utilities/generate-inline-styles';
import { appendWatermark } from '../../utilities/append-watermark';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import { appendTitle } from '../../utilities/append-title';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { DownloadOptions, PlotConfig } from '../../types/types';
import { SankeyData, SankeyLink, SankeyNode } from './types';
import { colors } from '../../utilities/colors';
import { lighten } from '../../utilities/lighten';
import { get, has } from 'lodash';

interface SankeyPlotConfig extends PlotConfig<SankeyData> {
  ttMainFormat: any;
  ttSecondaryFormat: any;
  inflows: any;
  isUniversity: any;
  colorIndex?: number;
}

/**
 * Generate a Sankey Diagram
 *
 * @param plotConfigs - configs for generating the plot
 * @param downloadOptions - oprions for downloading plot as image
 *
 * @returns if getSVGNode is true, then return the svg node of the rendered plot.
 * Otherwise, void.
 */
export const SankeyGenerator = (
  plotConfigs: SankeyPlotConfig,
  downloadOptions: DownloadOptions
): SVGSVGElement | null | void => {
  const {
    data,
    ttMainFormat,
    ttSecondaryFormat,
    inflows,
    isUniversity,
    targetRef,
    requestHash,
    isRenderingOrLoading,
    customMargins,
    colorIndex = 0,
  } = plotConfigs;

  let { name, height, width } = plotConfigs;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const hasSortByValue = data.nodes.every((node) => has(node, 'sortByValue'));

  const dims: any = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;

  if (data && (targetRef?.current || containerId) && height && width) {
    // remove old svg
    select(`.svg-${name}`).remove();
    select(`.tooltip-${name}`).remove();

    // setup margins and inner dims; larger margin space where we have more nodes:

    dims.margin = {
      top: inflows ? 40 : 30,
      left: inflows ? 120 : 74,
      bottom: inflows ? 20 : 40,
      right: inflows ? 70 : 105,
    };

    if (download) {
      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        padding,
      });
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    // setup svg node
    const node = targetRef?.current;

    const svg: any = node
      ? select(node).append('svg')
      : select(containerId).append('svg');

    svg
      .attr('width', svgWidth || '100%')
      .attr('height', svgHeight || '100%')
      .attr('class', `svg-${name}`);
    const chart = svg.append('g');
    chart.attr(
      'transform',
      `translate(${dims.margin.left}, ${dims.margin.top})`
    );

    //=============================================================================

    // PROPS REQUIRED: ttMainFormat (for link tooltips), ttSecondaryFormat (for node tooltips), inflows (boolean)

    // map links to source/target node ids to find their node ids that should stay FIXED in the event of a
    // circular node (for inflows: target nodes; for outflows: source nodes)
    // create a set to get the unique ids (should never be >2 based on current sankey page, but this logic
    // will account for >2 fixed source/target nodes)
    // iterate through all links to find where source AND id are both one of these values (this will account
    // for both circular links where source node id = target node id AND those with the same combo of source id/target id)
    // change the NON-fixed node (for inflows: source; for outflows: target) to a string ending with '-1' to differentiate
    // it from the fixed node

    const fixedNodes = new Set(
      data.links.map((link) => (inflows ? link.target : link.source))
    );

    // correct for circular nodes where source=target and source-target combo is the same
    data.links.forEach((link: any) => {
      if (fixedNodes.has(link.source) && fixedNodes.has(link.target)) {
        if (inflows) {
          // change source node
          link.source = `${link.source}-1`;
          // add new source node to nodes
          const newNodeData = data.nodes.filter((d) => d.id === link.target)[0];
          const newNode = Object.assign({}, newNodeData);
          newNode.id = `${link.target}-1`;
          data.nodes.unshift(newNode); //adding to beginning of nodes array
        } else {
          // change target node
          link.target = `${link.target}-1`;
          // add new target node to nodes
          const newNodeData = data.nodes.filter((d) => d.id === link.source)[0];
          const newNode = Object.assign({}, newNodeData);
          newNode.id = `${link.source}-1`;
          data.nodes.unshift(newNode); //adding to beginning of nodes array
        }
      }
    });

    // TODO: var cutoff wasn't being used but can be useful to incorporate
    // var cutoff = 15; //past this number of nodes, node padding & text size will decrease and text will fit on 1 line
    // var fontSize = chartSize !== 'large' || data.nodes.length > 15 ? 11 : 12;

    const fontSize = queryLookup(height, data.nodes.length, 'fontSize');
    const nPadding = queryLookup(height, data.nodes.length, 'nodePadding');

    const sankeyVar = sankey()
      .size([dims.innerWidth, dims.innerHeight])
      .nodeWidth(6)
      .nodePadding(nPadding)
      .nodeAlign(sankeyRight)
      .nodeId((d: any) => d.id); //use our id to identify each node

    sankeyVar.nodeSort(function (a: SankeyNode, b: SankeyNode) {
      return descending(
        get(a, 'sortByValue', a.value) || undefined,
        get(b, 'sortByValue', b.value) || undefined
      );
    });

    const graph = sankeyVar(data);

    // link color based on source nodes for outflows and target nodes for inflows
    let nodeIds = [];

    if (inflows) {
      nodeIds = graph.nodes
        .filter((n: any) => n.targetLinks.length > 0)
        .map((n: any) => n.id);
    } else {
      nodeIds = graph.nodes
        .filter((n: any) => n.sourceLinks.length > 0)
        .map((n: any) => n.id);
    }

    const linkColorScale = scaleOrdinal()
      .domain(nodeIds)
      .range([colors[colorIndex]]); // NOTE: replace hardcoded color with var `colors` to have links different colors

    const transparentColors: any = {
      '#5CA9E9': '#AED4F4',
      '#70CA97': '#B8E5CB',
    }; //only have transparent blue & green for now - we don't see sankeys with >2 sources/targets currently

    const tooltipWidth = 200;
    const tooltipWidthNode = 140;
    const tooltip: any = select(node) //select(`.react-node-${name}`) //select(targetRef.current) //
      .append('div')
      .style('opacity', 0)
      .style('pointer-events', 'none')
      .style('display', null)
      .classed('tooltip-sankey-chart', true)
      .classed(`tooltip-${name}`, true);

    // function when hovering over links
    const mouseOverLink = (event: any, d: any) => {
      tooltip.classed('tooltip-sankey-chart-node', false);
      tooltip.classed('tooltip-sankey-chart-top', false);
      tooltip.classed('tooltip-sankey-chart', true);
      // highlight link being hovered over
      selectAll('.link')
        .filter((e: any) => e.id !== d.id)
        .attr('stroke', (d: any) => {
          return inflows
            ? transparentColors[linkColorScale(d.target.id) as any] ||
                (linkColorScale(d.target.id) &&
                  lighten(d3Color(`${linkColorScale(d.target.id)}`), 0.9)) //'#a8d7fa
            : transparentColors[linkColorScale(d.source.id) as any] ||
                (linkColorScale(d.source.id) &&
                  lighten(d3Color(`${linkColorScale(d.source.id)}`), 0.9)); // color link based on its target node (inflows) or source node (outflows)
        });

      // tooltip text depends on source, target, inflows & isUniversity prop
      const txt = `${d3Format(ttMainFormat)(d.value)} ${
        d.source.metadata.longName
      } ${isUniversity ? 'graduates' : 'employees'} have transitioned to ${
        d.target.metadata.longName
      }`;

      let updatedTxt;

      if (inflows) {
        updatedTxt = txt.concat(
          ` (${d3Format('.2%')(d.metadata.target_share)} of all ${
            d.target.metadata.shortName
          } inflows)`
        );
      } else {
        updatedTxt = txt.concat(
          ` (${d3Format('.2%')(d.metadata.source_share)} of all ${
            d.source.metadata.shortName
          } outflows)`
        );
      }

      const approxTxtWidth = Math.ceil(
        generateTextWidth(updatedTxt, '10px Source Sans Pro') / (200 - 24) //tooltip text area width
      );

      let tooltipX = event.offsetX - tooltipWidth / 2;
      let tooltipY = event.offsetY - 24 - 15 * approxTxtWidth - 7;

      const svgNode = svg.node();
      const svgWidth = svgNode.clientWidth;
      // const svgHeight = svgNode.clientHeight;

      const tooltipRect = tooltip.node().getBoundingClientRect();

      if (tooltipX + tooltipRect.width > svgWidth) {
        tooltipX = svgWidth - tooltipRect.width;
      } else if (tooltipX < 0) {
        tooltipX = 0;
      }
      if (event.offsetY - tooltipRect.height - 9 < 0) {
        if (d.index === 0) {
          tooltip.classed('tooltip-sankey-chart', false);
          tooltip.classed('tooltip-sankey-chart-top', true);
        }
        tooltipY = event.offsetY + tooltipRect.height / 2 - 8;
      }
      // show tooltip
      tooltip
        .style('opacity', 1)
        .style('width', tooltipWidth + 'px')
        .style(
          'transform',
          (e: any) => `translate(${tooltipX}px, ${tooltipY}px)`
        )
        .style('display', null)
        .html(updatedTxt);
    };

    // function when hovering over nodes
    const mouseOverNode = (event: any, d: any) => {
      tooltip.classed('tooltip-sankey-chart-node', true);
      tooltip.classed('tooltip-sankey-chart-top', false);
      tooltip.classed('tooltip-sankey-chart', false);
      const txt = `${d.metadata.longName} (${d3Format(ttSecondaryFormat)(
        d.value
      )})`;
      tooltip
        .style('opacity', 1)
        .style('width', tooltipWidthNode + 'px')
        .style('transform', () => {
          return `translate(${
            // eslint-disable-next-line no-nested-ternary
            d.index === 0
              ? inflows
                ? d.x1 + dims.margin.left - tooltipWidthNode
                : d.x1 + dims.margin.left
              : inflows
                ? d.x1 + dims.margin.left
                : d.x1 + dims.margin.left - tooltipWidthNode
          }px, ${
            // fine tune this logic
            d.index == 0
              ? dims.innerHeight / 2 + 8
              : dims.margin.top + d.y0 + (d.y1 - d.y0) / 2 - 18
          }px)`;
        })
        .style('display', null)
        .html(txt);
    };

    const mouseOut = (event: any, d: any) => {
      // selectAll(`.link`).style('opacity', data.length > 1 ? 0.7 : 1);
      selectAll(`.link`).attr(
        'stroke',
        (d: any) =>
          inflows
            ? (linkColorScale(d.target.id) as any)
            : linkColorScale(d.source.id) // color link based on its target node (inflows) or source node (outflows)
      );
      tooltip.style('opacity', 0).style('display', 'none');
    };

    // add the nodes
    const nodes = chart
      .append('g')
      .classed('nodes', true)
      .selectAll('.node')
      .data(graph.nodes)
      .enter()
      .append('g');

    // add rects to represent the nodes
    nodes
      .append('rect')
      .classed('node', true)
      .attr(
        'x',
        (d: any) =>
          // eslint-disable-next-line no-nested-ternary
          d.x0 + (inflows ? (d.index === 0 ? 3 : -3) : d.index === 0 ? -3 : 3)
      )
      .attr('y', (d: any) =>
        d.index == 0
          ? dims.innerHeight / 2 - (d.y1 - d.y0) / 2 - (height > 350 ? 0 : 5)
          : d.y0 - 2.5 / 2
      ) //0.8)
      .attr('width', (d: any) => d.x1 - d.x0) // (d) => (d.y1 - d.y0 > 0 ? 38 : 0)
      .attr('height', (d: any) => (d.y1 - d.y0 > 0 ? d.y1 - d.y0 + 2.5 : 0)) //prev + 1.6
      .attr('fill', '#2d426a')
      .attr(
        'filter',
        'drop-shadow(3.5px 0px 0px #ffffff) drop-shadow(-3.5px 0px 0px #ffffff) drop-shadow(0px -2px 0px #ffffff) drop-shadow(0px 2px 0px #ffffff)'
      )
      .on('mouseover', mouseOverNode)
      .on('mouseout', mouseOut)
      .attr('cursor', 'pointer');

    // add the links
    chart
      .append('g')
      .classed('links', true)
      .selectAll('path')
      .data(
        hasSortByValue
          ? [...graph.links].sort((a: SankeyLink, b: SankeyLink) => {
              return descending(
                get(a, `${inflows ? 'source' : 'target'}.sortByValue`),
                get(b, `${inflows ? 'source' : 'target'}.sortByValue`)
              );
            })
          : graph.links
      )
      .enter()
      .append('path')
      .classed('link', true)
      .attr('data-testid', 'sankey-link')
      .attr('id', (d: any) => `link-${d.id}`)
      .each(function (d: any, outerIndex: any) {
        const nodeSelection = select(`rect.node`);
        const nodeYPosition = parseInt(nodeSelection.attr('y'));
        const targetSource = inflows
          ? d.target.targetLinks
          : d.source.sourceLinks;
        let prevHeight = 0;
        const alignmentFix = 1;

        if (outerIndex) {
          const sourceToReduce = hasSortByValue
            ? [...targetSource].sort((a, b) => {
                return descending(
                  get(a, `${inflows ? 'source' : 'target'}.sortByValue`),
                  get(b, `${inflows ? 'source' : 'target'}.sortByValue`)
                );
              })
            : targetSource;

          prevHeight = sourceToReduce.reduce((a: any, c: any, i: any) => {
            if (i > outerIndex - 1) return a;
            return a + c.width;
          }, 0);
        }
        const yCalc = nodeYPosition + d.width / 2 + prevHeight + alignmentFix;
        if (inflows) {
          d.y1 = yCalc;
        } else {
          d.y0 = yCalc;
        }
      })
      .attr('d', sankeyLinkHorizontal())
      .attr('fill', 'none')
      .attr(
        'stroke',
        (d: any) =>
          inflows ? linkColorScale(d.target.id) : linkColorScale(d.source.id) // color link based on its target node (inflows) or source node (outflows)
      )
      .attr('stroke-width', (d: any) => {
        const baseStrokWidth = 2.5;
        return d.width + baseStrokWidth;
      })
      .attr(
        'stroke-dasharray',
        (d: any) => (d.target.id === 0 || d.source.id === 0 ? '12' : '') // dashed link for 'Other' category
      )
      .attr('opacity', (data as any).length > 1 ? 0.7 : 1)
      .on('mousemove', mouseOverLink)
      .on('mouseout', mouseOut)
      .attr('cursor', 'pointer');

    //text using divs to get text-overflow: ellipsis
    nodes
      .append('foreignObject')
      .attr('width', (d: any) =>
        d.sourceLinks.length > 0
          ? `${dims.margin.left - 5}px`
          : `${dims.margin.right - 5}px`
      )
      .attr('data-testid', (d: any) => {
        return d.sourceLinks.length > 0
          ? 'sankey-target-node'
          : 'sankey-source-node';
      })
      .attr('height', (d: any) => (d.y1 - d.y0 > 0 ? 38 : 0))
      .attr(
        'transform',
        (d: any) =>
          `translate(${
            d.sourceLinks.length > 0 ? -dims.margin.left - 8 : d.x1 + 8
          }, ${
            // eslint-disable-next-line no-nested-ternary
            d.index == 0
              ? dims.innerHeight / 2 - 20 + (height > 350 ? 0 : -3)
              : data.nodes.length < 20 &&
                  generateTextWidth(d.metadata.shortName, `${fontSize}px`) >
                    dims.margin.left - 5
                ? d.y1 - (d.y1 - d.y0) / 2 - 20
                : d.y1 - (d.y1 - d.y0) / 2 - 18
          })`
      )
      .append('xhtml:div')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('height', '100%')
      .append('text')
      .style('font-family', 'Source Sans Pro, sans-serif')
      .style('width', (d: any) =>
        d.sourceLinks.length > 0
          ? `${dims.margin.left - 5}px`
          : `${dims.margin.right - 5}px`
      )
      // allows selection of this node when inlining styles
      .classed(`inline-style-target-${name}`, !!download)
      .classed(
        `node-text-wrap-3`,
        data.nodes.length <= 15 && height > 300 ? true : false
      )
      .classed(
        'node-text-wrap-2',
        data.nodes.length > 15 && data.nodes.length < 20 && height > 300
          ? true
          : false
      )
      .classed(
        'node-text-ellipsis',
        data.nodes.length >= 20 || height <= 300 ? true : false
      )
      .style('line-height', data.nodes.length < 20 && height < 350 ? 1 : 1.1)
      // remove Corp., , Inc., , inc.
      .text((d: any) => {
        d.metadata.shortName = d.metadata.shortName.replace('Corp.', '');
        d.metadata.shortName = d.metadata.shortName.replace(', Inc.', '');
        d.metadata.shortName = d.metadata.shortName.replace(', inc.', '');
        return d.metadata.shortName;
      })
      .style('font-size', `${fontSize}px`)
      .style('text-align', (d: any) =>
        d.sourceLinks.length > 0 ? 'right' : 'left'
      )
      // needed if wrapping text, to float to right:
      // .style('float', (d) => (d.sourceLinks.length > 0 ? 'right' : 'left'));
      .style('overflow', 'visible')
      .style('justify-content', (d: any) =>
        d.sourceLinks.length > 0 ? 'flex-end' : 'flex-start'
      );

    if (!download) {
      notifyChartRenderComplete(chart, requestHash, () =>
        isRenderingOrLoading?.next(false)
      );
    }

    if (download) {
      generateInlineStyles(`.inline-style-target-${name}`);
    }

    if (title) {
      appendTitle(chart, title, dims, padding);
    }

    if (watermark) {
      appendWatermark(
        chart,
        watermark,
        dims.innerWidth + dims.margin.right,
        dims.innerHeight + dims.margin.bottom,
        padding
      );
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
