import { CustomColumnForm, EMPTY_CATEGORY_CASE } from './custom.model';
import { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { isEqual } from 'lodash';
import { deliverablesStore } from '../../deliverables.repository';
import { getEntity } from '@ngneat/elf-entities';
import { Deliverable } from '../../deliverables.model';

export const ADD_CUSTOM_COLUMN_ID = 'add-new-custom-column';
export const DEFAULT_COLUMN_NAME = 'unnamed_column';
export const DEFAULT_FORM_VALUES: CustomColumnForm = {
  customColumnName: DEFAULT_COLUMN_NAME,
  categoryCases: [EMPTY_CATEGORY_CASE],
  elseCategoryName: '',
};

export const useSetDetaultCustomColumnNameOnFirstEdit = ({
  entityId,
  control,
  setValue,
  currentCustomColumnName,
}: UseFormReturn<CustomColumnForm> & {
  entityId: Deliverable['id'];
  currentCustomColumnName: string;
}) => {
  const formValues = useWatch({ control });
  useEffect(() => {
    if (
      currentCustomColumnName !== DEFAULT_COLUMN_NAME ||
      isEqual(formValues, DEFAULT_FORM_VALUES)
    ) {
      return;
    }

    const customColumnsLength = deliverablesStore.query(getEntity(entityId))
      ?.pipeline.custom_columns?.length;
    setValue(
      'customColumnName',
      `custom_column_${(customColumnsLength || 0) + 1}`
    );
  }, [entityId, formValues, currentCustomColumnName, setValue]);
};

export function getDisplayTextWidth(text: string | undefined) {
  if (!text) {
    return 10;
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = '600 23px/27px "Source Sans Pro"';
  const metrics = context.measureText(text);
  return metrics.width;
}
