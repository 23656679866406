import { PipelineColumnType } from '@revelio/data-access';
import { CardListSubMenuProps, ICardListSelectProps } from '@revelio/layout';

export const DEFAULT_GEO_PIPELINE_COLUMNS: CardListSubMenuProps<PipelineColumnType> =
  {
    id: 'geography',
    label: 'Geography',
    gridProps: { columns: 1 },
    menuItems: [
      {
        id: PipelineColumnType.Region,
        columnFileSize: 256,
        rowPossibilities: 17,
      },
      {
        id: PipelineColumnType.Country,
        columnFileSize: 256,
        rowPossibilities: 247,
      },
      {
        id: PipelineColumnType.State,
        columnFileSize: 512,
        rowPossibilities: 3719,
      },
      {
        id: PipelineColumnType.Msa,
        label: 'msa',
        columnFileSize: 512,
        rowPossibilities: 5254,
      }, // this gets filtered out if the location model version is V3 in favour of `metro_area`
      {
        id: PipelineColumnType.MetroArea,
        label: 'Metropolitan Area',
        columnFileSize: 512,
        rowPossibilities: 5254,
      }, // this gets filtered out if the location model version is below V3
    ],
  };

export const DEFAULT_ROLE_PIPELINE_COLUMNS: CardListSubMenuProps<PipelineColumnType> =
  {
    id: 'occupation',
    label: 'Occupation',
    menuItems: [
      {
        id: PipelineColumnType.JobCategory,
        label: 'Role k7 (Job Category)',
        columnFileSize: 512,
        rowPossibilities: 8,
      },
      {
        id: PipelineColumnType.RoleK50,
        label: 'Role k50',
        columnFileSize: 512,
        rowPossibilities: 50,
      },
      {
        id: PipelineColumnType.RoleK150,
        label: 'Role k150',
        columnFileSize: 512,
        rowPossibilities: 150,
      },
      {
        id: PipelineColumnType.RoleK1000,
        label: 'Role k1000',
        columnFileSize: 512,
        rowPossibilities: 1000,
      },
      {
        id: PipelineColumnType.RoleK1250,
        label: 'Role k1250',
        columnFileSize: 512,
        rowPossibilities: 1250,
      },
      {
        id: PipelineColumnType.MappedRole,
        label: 'Role k1500',
        columnFileSize: 512,
        rowPossibilities: 1500,
      },
      {
        id: PipelineColumnType.Soc6d,
        label: 'SOC Code',
        columnFileSize: 6,
        rowPossibilities: 712,
        excludeFromFileRowMax: true,
      },
    ],
  };

export const DEFAULT_SKILL_PIPELINE_COLUMNS: CardListSubMenuProps<PipelineColumnType> =
  {
    id: 'skill',
    label: 'Skill',
    isRequired: true,
    menuItems: [
      {
        id: PipelineColumnType.SkillK25,
        defaultIsSelected: true,
        label: 'skill_k25',
        columnFileSize: 512,
        rowPossibilities: 25,
      },
      {
        id: PipelineColumnType.SkillK75,
        label: 'skill_k75',
        columnFileSize: 512,
        rowPossibilities: 75,
      },
    ],
  };

export const DEFAULT_RATING_PIPELINE_COLUMNS: ICardListSelectProps<PipelineColumnType>[] =
  [
    {
      id: PipelineColumnType.RatingOverall,
      label: 'Overall',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingCareerOpportunities,
      label: 'Career Opportunities',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingCompensationAndBenefits,
      label: 'Compensation & Benefits',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingCultureAndValues,
      label: 'Culture & Values',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingDiversityAndInclusion,
      label: 'Diversity & Inclusion',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingSeniorLeadership,
      label: 'Senior Leadership',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingWorkLifeBalance,
      label: 'Work-Life Balance',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingCeo,
      label: 'CEO',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingRecommendToFriend,
      label: 'Recommend to a Friend',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RatingBusinessOutlook,
      label: 'Business Outlook',
      defaultIsSelected: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
  ];

export const STANDARD_COMPANY_COLUMN: ICardListSelectProps<PipelineColumnType> =
  {
    id: PipelineColumnType.Company,
    combineWith: [PipelineColumnType.Rcid],
    isMandatory: true,
    columnFileSize: 516,
    // rowPossibilities is the number of distinct companies selected
  };

export const ULTIMATE_PARENT_COMPANY_COLUMN: ICardListSelectProps<PipelineColumnType> =
  {
    id: PipelineColumnType.UltimateParentCompanyName,
    label: 'Ultimate Parent Company',
    combineWith: [PipelineColumnType.UltimateParentRcid],
    defaultIsSelected: true,
  };

export const RAW_COUNT_COLUMN: ICardListSelectProps<PipelineColumnType> = {
  id: PipelineColumnType.RawCount,
  label: 'Raw Count',
  columnFileSize: 8,
  rowPossibilities: 1,
};

export const SCALED_COUNT_COLUMN: ICardListSelectProps<PipelineColumnType> = {
  id: PipelineColumnType.ScaledCount,
  label: 'Scaled Count',
  columnFileSize: 8,
  rowPossibilities: 1,
  tooltipInfo: 'Raw counts with only sampling bias adjustments',
};

export const COUNT_SUBMENU: CardListSubMenuProps<PipelineColumnType> = {
  id: 'count',
  label: 'Count',
  menuItems: [
    {
      id: PipelineColumnType.Count,
      columnFileSize: 8,
      rowPossibilities: 1,
      defaultIsSelected: true,
    },
    RAW_COUNT_COLUMN,
    SCALED_COUNT_COLUMN,
  ],
};

export const INFLOW_SUBMENU: CardListSubMenuProps<PipelineColumnType> = {
  id: 'inflow',
  label: 'Inflow',
  menuItems: [
    {
      id: PipelineColumnType.Inflow,
      defaultIsSelected: true,
      label: 'Inflow (internal and external)',
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RawInflow,
      label: 'Raw Inflow',
      internalOnly: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.ScaledInflow,
      label: 'Scaled Inflow',
      internalOnly: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.ExternalInflow,
      label: 'External Inflow',
      columnFileSize: 8,
      rowPossibilities: 1,
      tooltipInfo:
        'Total inflow counts of employees at each granularity level for a given month, excluding internal movements within a company',
    },
  ],
};

export const OUTFLOW_SUBMENU: CardListSubMenuProps<PipelineColumnType> = {
  id: 'outflow',
  label: 'Outflow',
  menuItems: [
    {
      id: PipelineColumnType.Outflow,
      defaultIsSelected: true,
      label: 'Outflow (internal and external)',
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.RawOutflow,
      label: 'Raw Outflow',
      internalOnly: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.ScaledOutflow,
      label: 'Scaled Outflow',
      internalOnly: true,
      columnFileSize: 8,
      rowPossibilities: 1,
    },
    {
      id: PipelineColumnType.ExternalOutflow,
      label: 'External Outflow',
      columnFileSize: 8,
      rowPossibilities: 1,
      tooltipInfo:
        'Total outflow counts of employees at each granularity level for a given month, excluding internal movements within a company',
    },
  ],
};
