import { GroupedBarData, GroupedBarValue } from '@revelio/d3';
import { PostingsRolesDataQuery } from '@revelio/data-access';

export const getGroupedBarData = (
  entities: PostingsRolesDataQuery['posting']
): GroupedBarData[] => {
  return (
    entities?.map((entity): GroupedBarData => {
      const entityMetadata = entity?.metadata;

      return {
        id: entityMetadata?.id,
        metadata: {
          shortName: entityMetadata?.shortName,
          longName: entityMetadata?.shortName,
        },
        value: (entity?.category || []).map((category): GroupedBarValue => {
          const metadata = category?.metadata;
          const metrics = category?.metrics;

          const id = metadata?.id;
          const shortName = metadata?.shortName;

          const count = metrics?.count;
          const share = metrics?.share;

          return {
            id,
            metadata: { shortName, longName: shortName, count },
            value: share,
          };
        }),
      };
    }) ?? []
  );
};
