import { Heading, Text, Flex, Box, Stack } from '@chakra-ui/react';
import { Views } from '@revelio/core';
import { EndpointSegment, ViewTypes } from '@revelio/filtering';
import { inRange } from 'lodash';
import { LegacyRef, useEffect, useState } from 'react';
import { useResizeDetector, withResizeDetector } from 'react-resize-detector';

/* eslint-disable-next-line */
export interface CardProps {
  children?: React.ReactNode;
  header?: React.ReactNode | string;
  headerTakesSpace?: boolean;
  topLeft?: React.ReactNode | string;
  topRight?: React.ReactNode | string;
  footer?: React.ReactNode | string;
  style?: React.CSSProperties;
  width: number;
  height: number;
  targetRef: LegacyRef<HTMLDivElement>;
  slim?: boolean;
  switch?: boolean;
  p?: string; // manually set padding takes precedence over padding set by slim or switch prop
  endpointSegment?: EndpointSegment | string;
  view?: Views;
  viewType?: ViewTypes[];
  includeDiagnosticsModal?: boolean;
  disablePlotLink?: boolean;
  disableResizeDetector?: boolean;
  isSubCard?: boolean;
}

function InternalCard({
  topLeft,
  topRight,
  header,
  headerTakesSpace,
  footer,
  width,
  height,
  targetRef,
  p,
  isSubCard,
  ...props
}: CardProps) {
  const [newHeight, setNewHeight] = useState(500);
  const [newWidth, setNewWidth] = useState(500);
  const { height: headerHeight, ref: headerRef } = useResizeDetector();
  const headerSpace = headerTakesSpace ? `${headerHeight}px` : 0;

  useEffect(() => {
    const skipUpdate = inRange(width, newWidth - 5, newWidth + 5);
    const skipHeightUpdate = inRange(width, newHeight - 5, newHeight + 5);
    if (!skipUpdate) {
      setNewWidth(width);
    }
    if (!skipHeightUpdate) {
      setNewHeight(height);
    }
  }, [height, newHeight, newWidth, width]);

  return (
    <Flex
      data-id="CARD"
      data-testid="card"
      ref={targetRef}
      borderRadius="10px"
      backgroundColor="white"
      height={'100%'}
      width={'100%'}
      padding={
        p ||
        (props.slim ? `${height > 100 ? 6 : 4}px 12px` : `12px 16px 16px 16px`)
      }
      sx={props.style}
      flexDirection={['row', 'column']}
      role="group"
      position="relative"
      border={isSubCard ? '1px' : '0px'}
      borderColor="text.lowContrast"
    >
      {header && typeof header === 'string' ? (
        <Flex
          ref={headerRef}
          justifyContent="space-between"
          zIndex={1}
          position="absolute"
          width="100%"
          marginLeft={
            /* eslint-disable-next-line */
            props.slim ? '-22px' : props.switch ? '-26px' : '-20px'
          }
          paddingLeft="24px"
          alignItems="center"
        >
          <Stack spacing={0} direction="row" align="center">
            <Heading
              fontSize={props.slim ? '12px' : '14px'}
              color="text.primary"
              fontWeight="semibold"
              display={topLeft ? 'inline-block' : 'block'}
              marginRight={topLeft ? '1px' : 0}
            >
              {header}
            </Heading>
            {topLeft || null}
          </Stack>
          {topRight || ''}
        </Flex>
      ) : (
        <>
          {topLeft || ''}
          {header}
        </>
      )}
      <Box
        flex="1"
        maxHeight={newHeight} // this is the height of the Card header, not great but it makes it work
        maxWidth={newWidth}
      >
        <Flex direction={'column'} height={'100%'}>
          {headerTakesSpace && <Box height={headerSpace} marginBottom="4" />}
          {props.children}
        </Flex>
      </Box>
      {footer && typeof footer === 'string' ? (
        <Text marginTop="4" fontSize="24" color="text.primary">
          {footer}
        </Text>
      ) : (
        footer
      )}
    </Flex>
  );
}

const ResizeWrapper = withResizeDetector<CardProps>(InternalCard, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: true,
    trailing: true,
  },
});

const DisabledResizeWrapper = withResizeDetector<CardProps>(InternalCard, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: true,
    trailing: true,
  },
  handleWidth: false,
  handleHeight: false,
  skipOnMount: true,
});

export const Card = (
  props: Omit<CardProps, 'width' | 'height' | 'targetRef'>
) => {
  const { disableResizeDetector = false, children, ...rest } = props;
  if (disableResizeDetector) {
    return <DisabledResizeWrapper {...rest}>{children}</DisabledResizeWrapper>;
  }
  return <ResizeWrapper {...rest}>{children}</ResizeWrapper>;
};

export default Card;
