import { Box, BoxProps, Flex, Icon } from '@chakra-ui/react';
import { isString } from 'lodash';
import { formatListStringToLimit, formatViewList } from './label-format-fns';
import styles from './node-filter-set-tooltip-label.module.css';

/* eslint-disable-next-line */
export interface NodeFilterSetTooltipLabelProps {
  icon?: any;
  list?: string[] | string;
  limit?: number;
  text?: string;
  textStyles?: BoxProps;
  quoteListItems?: boolean;
}

export function NodeFilterSetTooltipLabel({
  icon,
  list = [],
  limit = 7,
  text,
  textStyles,
  quoteListItems = true,
  ...props
}: NodeFilterSetTooltipLabelProps) {
  return (
    <Flex className={styles.tooltipLabel}>
      {icon && (
        <Box as="span" className={styles.iconContainer}>
          <Icon as={icon} className={styles.icon} />
        </Box>
      )}

      <Box as="span" className={styles.tooltipViewLabel}>
        <>
          <Box as="span" {...textStyles}>
            {text}
          </Box>
          {isString(list)
            ? formatListStringToLimit(list, limit)
            : formatViewList(list, limit)}
        </>
      </Box>
    </Flex>
  );
}

export default NodeFilterSetTooltipLabel;
