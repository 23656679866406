import { DragHandleIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/layout';
import { ReactNode, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Subsidiary } from '@revelio/core';

export const ItemTypes = {
  ITEM: 'item',
};

interface DraggableItemProps {
  id: string | number;
  children: ReactNode;
  item: Subsidiary; //or eventually any other item type
  disabled?: boolean;
  longDropProps?: {
    stopLongDropTimer: () => void;
    setHoveredItem: (columnItem: string | null) => void;
  };
}

const DraggableItemWrapper = ({
  children,
  id,
  item,
  disabled,
  longDropProps,
}: DraggableItemProps) => {
  const { stopLongDropTimer = () => null, setHoveredItem = () => null } =
    longDropProps || {};

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.ITEM,
      // item: () => {
      //   startLongDropTimer();
      //   return item;
      // },
      item,
      canDrag: !disabled,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (dropResult, monitor) => {
        stopLongDropTimer();
        setHoveredItem(null);
        // Handle the drop logic
      },
    }),
    []
  );

  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = () => setHovering(true);
  const handleMouseLeave = () => {
    setHovering(false);
    stopLongDropTimer();
  };

  return (
    <Flex
      key={id}
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        width="100%"
        position="absolute"
        left="0"
        top="0"
        height="100%"
        opacity={hovering ? 1 : 0}
        display="flex"
        alignItems="center"
        paddingLeft="6px"
        pointerEvents="none"
      >
        <DragHandleIcon width="12px" />
      </Box>
      <Flex
        ref={drag}
        alignItems="center"
        padding="2px 0 2px 6px"
        borderRadius="6px"
        flex={1}
        backgroundColor="white"
        opacity={isDragging ? 0.5 : 1}
      >
        <Box width="12px" />
        {children}
      </Flex>
    </Flex>
  );
};

export default DraggableItemWrapper;
