import {
  BinaryOperation,
  CompanySet,
  CustomColumnStep,
  Maybe,
  ModelVersions,
  PipelineColumnType,
  PipelineType,
  PostingConfiguration,
  PostingSource,
  S3Delivery,
  SnowflakeDelivery,
  Deliverable as APIDeliverable,
} from '@revelio/data-access';
import { CategoryCase } from './columns/custom/custom.model';
import { Condition } from './columns/dataset-conditions/conditions.model';
import { isNil } from 'lodash';

export const POSTINGS_DATASETS = [
  PipelineType.Posting,
  PipelineType.PostingsIndividual,
];
export const isPostingsPipeline = (pipeline: PipelineType) =>
  POSTINGS_DATASETS.includes(pipeline);

export const isIndividualPostingPipeline = (pipeline: PipelineType) =>
  pipeline === PipelineType.PostingsIndividual;

export const isIndividualPipeline = (pipeline: PipelineType) =>
  pipeline === PipelineType.Individual;

export const isCompanyInfoPipeline = (pipeline: PipelineType) =>
  pipeline === PipelineType.CompanyInfo;

export const isSkillDynamPipeline = (pipeline: PipelineType) =>
  pipeline === PipelineType.SkillDynam;

export type ElseDefaultCategoryCase = { then: string };
export function isCategoryCase(
  pipeline: CategoryCase | ElseDefaultCategoryCase | Maybe<CustomColumnStep>
): pipeline is CategoryCase {
  return !!(pipeline as CategoryCase).case;
}
export type CustomColumn = {
  name: string;
  step: (CategoryCase | ElseDefaultCategoryCase)[];
};
export type DatasetFilter = {
  name: string;
  conditions: Condition[];
  binary: BinaryOperation;
};
export type CompletePostingPipeline = {
  pipeline_type: PipelineType.Posting | PipelineType.PostingsIndividual;
  columns: PipelineColumnType[];
  source: PostingSource;
  custom_columns?: CustomColumn[];
  filters?: DatasetFilter[];
  posting_configuration?: PostingConfiguration;
};
export type CompletePipeline = {
  pipeline_type: Omit<
    PipelineType,
    // covered by CompletePostingPipeline
    | PipelineType.Posting
    | PipelineType.PostingsIndividual
    // covered by CompleteIndividualPipeline
    | PipelineType.Individual
    // covered by CompleteCompanyInfoPipeline
    | PipelineType.CompanyInfo
  >;
  columns: PipelineColumnType[];
  custom_columns?: CustomColumn[];
  filters?: DatasetFilter[];
};

export type CompleteIndividualPipeline = {
  pipeline_type: PipelineType.Individual;
  columns: PipelineColumnType[];
  custom_columns?: CustomColumn[];
  filters?: DatasetFilter[];
  individual_user_configuration: {
    current: boolean;
    full_history: boolean;
  };
};

export type CompleteCompanyInfoPipeline = {
  pipeline_type: PipelineType.CompanyInfo;
  columns: PipelineColumnType[];
  custom_columns?: CustomColumn[];
  filters?: DatasetFilter[];
  company_info_configuration: {
    include_subsidiaries: boolean;
  };
};

export type Pipeline =
  | CompletePipeline
  | CompletePostingPipeline
  | CompleteIndividualPipeline
  | CompleteCompanyInfoPipeline;

export function isCompletePostingPipeline(
  pipeline:
    | CompletePipeline
    | CompletePostingPipeline
    | CompleteIndividualPipeline
    | CompleteCompanyInfoPipeline
    | undefined
): pipeline is CompletePostingPipeline {
  return (
    isPostingsPipeline((pipeline as CompletePostingPipeline).pipeline_type) &&
    !!(pipeline as CompletePostingPipeline).source
  );
}

export function isCompleteIndividualPipeline(
  pipeline:
    | CompletePipeline
    | CompletePostingPipeline
    | CompleteIndividualPipeline
    | CompleteCompanyInfoPipeline
    | undefined
): pipeline is CompleteIndividualPipeline {
  return (
    isIndividualPipeline(
      (pipeline as CompleteIndividualPipeline).pipeline_type
    ) &&
    !!(pipeline as CompleteIndividualPipeline).individual_user_configuration
  );
}

export function isCompleteCompanyInfoPipeline(
  pipeline:
    | CompletePipeline
    | CompletePostingPipeline
    | CompleteIndividualPipeline
    | CompleteCompanyInfoPipeline
    | undefined
): pipeline is CompleteCompanyInfoPipeline {
  return (
    isCompanyInfoPipeline(
      (pipeline as CompleteCompanyInfoPipeline).pipeline_type
    ) && !!(pipeline as CompleteCompanyInfoPipeline).company_info_configuration
  );
}

export type Deliverable<T = CompletePipeline> = {
  // option T = CompletePostingPipeline
  id: number | string;
  model_versions: ModelVersions;
  pipeline: Partial<T>;
  s3_delivery?: S3Delivery;
  snowflake_delivery?: SnowflakeDelivery;
  isExcluded?: boolean;
} & Partial<InputRefs>;

export interface InputRefs {
  company_sets: CompanySet[];
  pipeline_input: string;
  company_reference: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeEmptyValuesAndTypename(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeEmptyValuesAndTypename(item));
  } else if (typeof obj === 'object' && obj) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (
        ((!Array.isArray(value) && !isNil(value)) ||
          (Array.isArray(value) && value.length)) &&
        key !== '__typename'
      ) {
        result[key] = removeEmptyValuesAndTypename(value);
      }
    });
    return result;
  }

  return obj;
}

export function isDataBuilderDeliverable(
  deliverable: Deliverable | APIDeliverable
): deliverable is Deliverable {
  return !!(deliverable as Deliverable).id;
}
