import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import styles from './content-wrapper.module.scss';

type ContentWrapperTheme = 'terms-of-service' | 'default';

interface ContentWrapperProps
  extends Omit<ComponentProps<typeof Box>, 'className'> {
  themeType?: 'terms-of-service' | 'default';
}

const contentWrapperClassName = (themeType: ContentWrapperTheme) => {
  if (themeType === 'terms-of-service') {
    return styles['tosContent'];
  }
  return styles['standardContent'];
};

export const ContentWrapper = forwardRef<HTMLDivElement, ContentWrapperProps>(
  (props, ref) => {
    return (
      <Box
        ref={ref as ForwardedRef<HTMLDivElement>}
        className={contentWrapperClassName(props.themeType ?? 'default')}
        {...props}
      ></Box>
    );
  }
);

export default ContentWrapper;
