import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import { useUserTrialDetails } from '@revelio/auth';
import { ActionMenu } from '@revelio/core';
import { FiImage, FiLink, FiMaximize2, FiMoreVertical } from 'react-icons/fi';
import {
  ActionMenuButton,
  DownloadModal,
  PlotLinkModal,
} from '@revelio/composed';
import { PlotExpandModal } from './plot-expand-modal';
import { PlotData } from '@revelio/layout';
import React from 'react';

export const PlotActionMenu = ({
  title,
  chartType,
  chartProps,
  expandedPlot,
  disableExpand = false,
  disableLink = false,
  disableDownload = false,
  ...boxProps
}: {
  title: string;
  chartType: D3ChartNames;
  chartProps: ID3ChartProps;
  expandedPlot: React.ReactNode;
  disableExpand?: boolean;
  disableLink?: boolean;
  disableDownload?: boolean;
} & BoxProps) => {
  const plotDownloadDisclosure = useDisclosure();
  const plotExpandDisclosure = useDisclosure();
  const plotLinkDisclosure = useDisclosure();
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: true,
  });
  return (
    <Box {...boxProps}>
      <ActionMenu menuButtonIcon={FiMoreVertical} portalMenu={true}>
        {!disableExpand && (
          <ActionMenuButton
            icon={FiMaximize2}
            text="Expand Plot"
            disabled={isTrialUser}
            onClick={plotExpandDisclosure.onOpen}
          />
        )}
        {!disableLink && (
          <ActionMenuButton
            icon={FiLink}
            text="Plot Link"
            disabled={isTrialUser}
            onClick={plotLinkDisclosure.onOpen}
          />
        )}
        {!disableDownload && (
          <ActionMenuButton
            icon={FiImage}
            text="Download PNG"
            disabled={isTrialUser}
            onClick={plotDownloadDisclosure.onOpen}
          />
        )}
      </ActionMenu>
      <PlotExpandModal
        title={title}
        {...plotExpandDisclosure}
        topRight={
          <ActionMenu menuButtonIcon={FiMoreVertical} portalMenu={false}>
            {!disableLink && (
              <ActionMenuButton
                icon={FiLink}
                text="Plot Link"
                disabled={isTrialUser}
                onClick={plotLinkDisclosure.onOpen}
              />
            )}
            {!disableDownload && (
              <ActionMenuButton
                icon={FiImage}
                text="Download PNG"
                disabled={isTrialUser}
                onClick={plotDownloadDisclosure.onOpen}
              />
            )}
          </ActionMenu>
        }
      >
        {expandedPlot}
      </PlotExpandModal>
      <PlotLinkModal
        plotLinkModalState={{
          isPlotLinkOpen: plotLinkDisclosure.isOpen,
          onPlotLinkOpen: plotLinkDisclosure.onOpen,
          onPlotLinkClose: plotLinkDisclosure.onClose,
        }}
        cardConfig={{}}
        typeAndProps={{
          chartType,
          chartProps: chartProps as ID3ChartProps,
        }}
        data={chartProps.data as PlotData}
      />
      <DownloadModal
        header={title}
        {...plotDownloadDisclosure}
        downloadDimension={{ height: 0, width: 0 }} // should make prop optional
        typeAndProps={{
          chartType,
          chartProps: chartProps as ID3ChartProps,
        }}
        isFullscreenMode={true}
        data={chartProps.data as PlotData}
      />
    </Box>
  );
};
