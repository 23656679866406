import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { noop } from 'lodash';
import {
  SelectionCategoryLabelOverrides,
  SelectionCategoryLabelPrefix,
} from '../nested-tree/node-submenu/node-submenu';
import { SelectionListIdNames } from '../../../engine/filters.model';

export interface NestedTreeBreadcrumbHeaderProps {
  lists: { header?: string; selectionList?: SelectionListIdNames }[];
  rebuildParentTree: (selectionList: SelectionListIdNames) => void;
}

export function NestedTreeBreadcrumbHeader({
  lists,
  rebuildParentTree,
}: NestedTreeBreadcrumbHeaderProps) {
  return (
    <Box maxW={500} overflow="auto">
      <Breadcrumb spacing={0.5} separator={<ChevronRightIcon boxSize={3} />}>
        {lists.map((li, i) => {
          const standardSelectionListHeader = `${
            SelectionCategoryLabelPrefix[
              li.selectionList as SelectionListIdNames
            ] || ''
          }${
            SelectionCategoryLabelOverrides[
              li.selectionList as SelectionListIdNames
            ] || ''
          }`;
          const isLastItem = i === lists.length - 1;
          return (
            <BreadcrumbItem
              key={`${li.header}_${i}`}
              // last breadcrumb is always the current page since we remove all children breadcrumbs on selection
              isCurrentPage={isLastItem}
              opacity={isLastItem ? 1 : 0.5}
            >
              <BreadcrumbLink
                onClick={
                  li.selectionList
                    ? () =>
                        rebuildParentTree(
                          li.selectionList as SelectionListIdNames
                        )
                    : noop
                }
              >
                {li.header || standardSelectionListHeader}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Box>
  );
}

export default NestedTreeBreadcrumbHeader;
