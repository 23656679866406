export const mtPercentiles = [
  {
    percentile: 0,
    z_score: -3.560961487,
  },
  {
    percentile: 1,
    z_score: -0.8951949517,
  },
  {
    percentile: 2,
    z_score: -0.7983532068,
  },
  {
    percentile: 3,
    z_score: -0.7390162063,
  },
  {
    percentile: 4,
    z_score: -0.6957948315,
  },
  {
    percentile: 5,
    z_score: -0.6612254054,
  },
  {
    percentile: 6,
    z_score: -0.6323615344,
  },
  {
    percentile: 7,
    z_score: -0.6075247179,
  },
  {
    percentile: 8,
    z_score: -0.5853025243,
  },
  {
    percentile: 9,
    z_score: -0.5652555891,
  },
  {
    percentile: 10,
    z_score: -0.5468927741,
  },
  {
    percentile: 11,
    z_score: -0.5299798221,
  },
  {
    percentile: 12,
    z_score: -0.5140674426,
  },
  {
    percentile: 13,
    z_score: -0.4991832209,
  },
  {
    percentile: 14,
    z_score: -0.4850637979,
  },
  {
    percentile: 15,
    z_score: -0.4717156356,
  },
  {
    percentile: 16,
    z_score: -0.4588311269,
  },
  {
    percentile: 17,
    z_score: -0.4465696216,
  },
  {
    percentile: 18,
    z_score: -0.434623381,
  },
  {
    percentile: 19,
    z_score: -0.42303013,
  },
  {
    percentile: 20,
    z_score: -0.4116541976,
  },
  {
    percentile: 21,
    z_score: -0.4005917101,
  },
  {
    percentile: 22,
    z_score: -0.3897143915,
  },
  {
    percentile: 23,
    z_score: -0.379012258,
  },
  {
    percentile: 24,
    z_score: -0.3684515255,
  },
  {
    percentile: 25,
    z_score: -0.3581155792,
  },
  {
    percentile: 26,
    z_score: -0.3478580358,
  },
  {
    percentile: 27,
    z_score: -0.3376513844,
  },
  {
    percentile: 28,
    z_score: -0.3274030181,
  },
  {
    percentile: 29,
    z_score: -0.3173901798,
  },
  {
    percentile: 30,
    z_score: -0.3073180319,
  },
  {
    percentile: 31,
    z_score: -0.2972457885,
  },
  {
    percentile: 32,
    z_score: -0.2873068512,
  },
  {
    percentile: 33,
    z_score: -0.2772102298,
  },
  {
    percentile: 34,
    z_score: -0.2671573657,
  },
  {
    percentile: 35,
    z_score: -0.2570538992,
  },
  {
    percentile: 36,
    z_score: -0.2468553621,
  },
  {
    percentile: 37,
    z_score: -0.2366965789,
  },
  {
    percentile: 38,
    z_score: -0.2265226956,
  },
  {
    percentile: 39,
    z_score: -0.2160918954,
  },
  {
    percentile: 40,
    z_score: -0.205857357,
  },
  {
    percentile: 41,
    z_score: -0.1953594423,
  },
  {
    percentile: 42,
    z_score: -0.184834494,
  },
  {
    percentile: 43,
    z_score: -0.1743346411,
  },
  {
    percentile: 44,
    z_score: -0.163817938,
  },
  {
    percentile: 45,
    z_score: -0.1530729363,
  },
  {
    percentile: 46,
    z_score: -0.142161668,
  },
  {
    percentile: 47,
    z_score: -0.1312841567,
  },
  {
    percentile: 48,
    z_score: -0.1202370795,
  },
  {
    percentile: 49,
    z_score: -0.1090228878,
  },
  {
    percentile: 50,
    z_score: -0.09774907965,
  },
  {
    percentile: 51,
    z_score: -0.08634656597,
  },
  {
    percentile: 52,
    z_score: -0.07462113381,
  },
  {
    percentile: 53,
    z_score: -0.06277123121,
  },
  {
    percentile: 54,
    z_score: -0.05086094867,
  },
  {
    percentile: 55,
    z_score: -0.03876801592,
  },
  {
    percentile: 56,
    z_score: -0.02650570119,
  },
  {
    percentile: 57,
    z_score: -0.01404300307,
  },
  {
    percentile: 58,
    z_score: -0.001490681648,
  },
  {
    percentile: 59,
    z_score: 0.01122376603,
  },
  {
    percentile: 60,
    z_score: 0.02430384866,
  },
  {
    percentile: 61,
    z_score: 0.03752894974,
  },
  {
    percentile: 62,
    z_score: 0.05099075598,
  },
  {
    percentile: 63,
    z_score: 0.06458317848,
  },
  {
    percentile: 64,
    z_score: 0.07851383942,
  },
  {
    percentile: 65,
    z_score: 0.09287168886,
  },
  {
    percentile: 66,
    z_score: 0.1074744808,
  },
  {
    percentile: 67,
    z_score: 0.1225232973,
  },
  {
    percentile: 68,
    z_score: 0.1378588269,
  },
  {
    percentile: 69,
    z_score: 0.1536800975,
  },
  {
    percentile: 70,
    z_score: 0.1699783562,
  },
  {
    percentile: 71,
    z_score: 0.1867990979,
  },
  {
    percentile: 72,
    z_score: 0.2040277696,
  },
  {
    percentile: 73,
    z_score: 0.2216672561,
  },
  {
    percentile: 74,
    z_score: 0.2401484297,
  },
  {
    percentile: 75,
    z_score: 0.2591261954,
  },
  {
    percentile: 76,
    z_score: 0.2790382108,
  },
  {
    percentile: 77,
    z_score: 0.2993532524,
  },
  {
    percentile: 78,
    z_score: 0.320325931,
  },
  {
    percentile: 79,
    z_score: 0.3425014884,
  },
  {
    percentile: 80,
    z_score: 0.3653581546,
  },
  {
    percentile: 81,
    z_score: 0.3885426248,
  },
  {
    percentile: 82,
    z_score: 0.413148539,
  },
  {
    percentile: 83,
    z_score: 0.4386698959,
  },
  {
    percentile: 84,
    z_score: 0.4650364111,
  },
  {
    percentile: 85,
    z_score: 0.4925188953,
  },
  {
    percentile: 86,
    z_score: 0.5210561786,
  },
  {
    percentile: 87,
    z_score: 0.5513178014,
  },
  {
    percentile: 88,
    z_score: 0.5833651223,
  },
  {
    percentile: 89,
    z_score: 0.6173120981,
  },
  {
    percentile: 90,
    z_score: 0.653822993,
  },
  {
    percentile: 91,
    z_score: 0.6928814164,
  },
  {
    percentile: 92,
    z_score: 0.7350658176,
  },
  {
    percentile: 93,
    z_score: 0.7815040182,
  },
  {
    percentile: 94,
    z_score: 0.8333145031,
  },
  {
    percentile: 95,
    z_score: 0.8934496213,
  },
  {
    percentile: 96,
    z_score: 0.9658946808,
  },
  {
    percentile: 97,
    z_score: 1.06607597,
  },
  {
    percentile: 98,
    z_score: 1.232448281,
  },
  {
    percentile: 99,
    z_score: 1.627355195,
  },
  {
    percentile: 100,
    z_score: 31.384884162,
  },
];
