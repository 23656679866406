import { useEffect, useState } from 'react';
import { forEach, isEqual, omit } from 'lodash';
import { useNavigate } from 'react-router';
import { Button, useToast } from '@chakra-ui/react';
import {
  getAllEntities,
  getEntity,
  updateAllEntities,
} from '@ngneat/elf-entities';

import { CardListSelectController } from '@revelio/layout';
import { DeliverableStepContainer } from '../deliverable-step-container';
import {
  deliverablesStore,
  selectDuplicateDatasetNaming,
  setDuplicateDatasetNaming,
} from '../deliverables.repository';
import { Deliverable } from '../deliverables.model';
import {
  AddingOrEditingConfig,
  DOWNLOAD_DELIVERY_BUCKET,
  DOWNLOAD_DELIVERY_FILE_PREFIX,
  DeliverCheckoxValues,
  DeliverySelection,
  getClientDeliveryOptions,
  adminDeliveryOptions,
  isDeliverableDownloadable,
  getDuplicateDeliverables,
} from './delivery.model';
import ChangeConfigModal from './change-config-modal';
import { DeliverablePaths, isDeliveryStepValid } from '../deliverables-page';
import { PagePaths } from '@revelio/core';
import { S3Format } from '@revelio/data-access';
import { useIsRevelioAdmin } from '@revelio/auth';
import { useForm } from 'react-hook-form';
import DuplicateDatasetLocations from './duplicate-dataset-locations';
import { useObservable } from '@ngneat/react-rxjs';
import { useClientDataBuilderConfig } from '../useClientDataBuilderConfig';

export const Delivery = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isRevelioAdmin } = useIsRevelioAdmin();

  const entities = deliverablesStore.query(getAllEntities());
  const entityId = entities[0].id;
  const [selectedDeliveryOptions, setSelectedDeliveryOptions] =
    useState<DeliverCheckoxValues>([]);
  useEffect(() => {
    const draftDeliverable = deliverablesStore.query(getEntity(entityId));
    let selectedDeliveryOptions: DeliverySelection[] = [];
    if (draftDeliverable?.s3_delivery) {
      selectedDeliveryOptions = [
        isDeliverableDownloadable(draftDeliverable?.s3_delivery)
          ? DeliverySelection.DOWNLOAD
          : DeliverySelection.S3,
      ];
    }
    if (draftDeliverable?.snowflake_delivery) {
      selectedDeliveryOptions = [DeliverySelection.SNOWFLAKE];
    }
    setSelectedDeliveryOptions(selectedDeliveryOptions);
  }, [entityId]);

  const [
    deliveryOptionConfigBeingChanged,
    setDeliveryOptionConfigBeingChanged,
  ] = useState<DeliverCheckoxValues[number] | null>(null);
  const [addingOrEditingConfig, setAddingOrEditingConfig] =
    useState<AddingOrEditingConfig | null>(null);

  const resetConfigBeingChanged = () => {
    setDeliveryOptionConfigBeingChanged(null);
    setAddingOrEditingConfig(null);
  };

  const duplicateDeliverables = getDuplicateDeliverables<Deliverable>(entities);

  const [savedDuplicateDatasetNaming] = useObservable(
    deliverablesStore.pipe(selectDuplicateDatasetNaming())
  );
  const formProps = useForm<{ [delId: string]: string }>({
    defaultValues: savedDuplicateDatasetNaming,
  });
  const { setValue, getValues, handleSubmit } = formProps;

  useEffect(() => {
    if (!isEqual(getValues(), savedDuplicateDatasetNaming)) {
      forEach(savedDuplicateDatasetNaming, (naming, id) => {
        setValue(id, naming);
      });
    }
  }, [getValues, savedDuplicateDatasetNaming, setValue]);

  const clientDataBuilderConfig = useClientDataBuilderConfig();

  return (
    <DeliverableStepContainer
      id="delivery"
      title="Delivery"
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      previousStepPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.COLUMNS}`}
      isSubmitDisabled={selectedDeliveryOptions.length === 0}
      onSubmit={handleSubmit((formData) => {
        const draftDeliverable = deliverablesStore.query(getEntity(entityId));
        if (!isDeliveryStepValid(draftDeliverable as Deliverable)) {
          return toast({
            title: 'Delivery is required!',
            description: 'Please select a delivery option.',
            status: 'warning',
            variant: 'subtle',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
            containerStyle: { width: '360px' },
          });
        }

        deliverablesStore.update(setDuplicateDatasetNaming(formData));

        return navigate(
          `/${PagePaths.DELIVERABLES}/${DeliverablePaths.CONFIRMATION}`
        );
      })}
    >
      <CardListSelectController<DeliverySelection>
        variant="delivery"
        items={
          isRevelioAdmin
            ? [
                {
                  ...adminDeliveryOptions[0],
                  columns: adminDeliveryOptions[0].columns.map((option) => {
                    if (
                      option.isDisabled ||
                      option.id === DeliverySelection.DOWNLOAD
                    ) {
                      return option;
                    }

                    return {
                      ...option,
                      cta: selectedDeliveryOptions.includes(option.id) ? (
                        <Button
                          colorScheme="gray"
                          borderRadius="4px"
                          fontSize="12px"
                          fontWeight="600"
                          size="sm"
                          px="6"
                          mr="5"
                          onClick={() => {
                            setDeliveryOptionConfigBeingChanged(option.id);
                            setAddingOrEditingConfig(
                              AddingOrEditingConfig.Editing
                            );
                          }}
                        >
                          Edit
                        </Button>
                      ) : undefined,
                    };
                  }),
                },
              ]
            : getClientDeliveryOptions({
                hasS3DefaultLocation: !!clientDataBuilderConfig?.s3_location,
                hasSnowflakeDefaultLocation:
                  !!clientDataBuilderConfig?.snowflake_location,
              })
        }
        value={selectedDeliveryOptions}
        gridProps={{
          spacing: 6,
          columns: 1,
          gridAutoFlow: 'row',
        }}
        onChange={(value: DeliverCheckoxValues) => {
          // add if is not admin + each option, then save the details from client config
          const hasCheckedNewDeliveryValue =
            value.length > selectedDeliveryOptions.length;
          if (hasCheckedNewDeliveryValue) {
            const addedDeliveryValue = value[value.length - 1]; // value
            if (addedDeliveryValue === DeliverySelection.DOWNLOAD) {
              setSelectedDeliveryOptions([DeliverySelection.DOWNLOAD]);
              deliverablesStore.update(
                updateAllEntities((deliverable) => {
                  const downloadDelivery = omit(deliverable, [
                    DeliverySelection.SNOWFLAKE,
                  ]) as Deliverable;
                  downloadDelivery.s3_delivery = {
                    s3_bucket: DOWNLOAD_DELIVERY_BUCKET,
                    s3_file_prefix: DOWNLOAD_DELIVERY_FILE_PREFIX,
                    s3_format: S3Format.Csv,
                  };
                  return downloadDelivery;
                })
              );
              return;
            }

            setDeliveryOptionConfigBeingChanged(addedDeliveryValue);
            setAddingOrEditingConfig(AddingOrEditingConfig.Adding);
          }

          const hasRemovedDeliveryValue =
            value.length < selectedDeliveryOptions.length;
          if (hasRemovedDeliveryValue) {
            const removedDeliveryValue =
              selectedDeliveryOptions[selectedDeliveryOptions.length - 1];
            deliverablesStore.update(
              updateAllEntities((deliverable) => {
                const removedDelivery = omit(deliverable, [
                  removedDeliveryValue,
                ]) as Deliverable;
                return removedDelivery;
              })
            );
          }

          setSelectedDeliveryOptions(value);
        }}
      />

      <ChangeConfigModal
        entityId={entityId}
        addingOrEditingConfig={addingOrEditingConfig}
        selectedDeliveryOptions={selectedDeliveryOptions}
        setSelectedDeliveryOptions={setSelectedDeliveryOptions}
        deliveryOptionConfigBeingChanged={deliveryOptionConfigBeingChanged}
        resetConfigBeingChanged={resetConfigBeingChanged}
      />

      {!duplicateDeliverables.length ? null : (
        <DuplicateDatasetLocations
          duplicateDeliverables={duplicateDeliverables}
          isRevelioAdmin={isRevelioAdmin}
          formProps={formProps}
        />
      )}
    </DeliverableStepContainer>
  );
};
