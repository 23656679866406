import pMemoize from 'p-memoize';
import QuickLRU from 'quick-lru';
import { oryClient, ORY_KRATOS_ROOT } from './auth';
import { LoginFlow, UiNodeInputAttributes } from '@ory/kratos-client';
import { AuthStoreRootProps, OryUserResponse } from './auth.model';
import { authStore, useAuthStore } from './auth.repository';
import { useEffect, useRef } from 'react';
import { isNil } from 'lodash';
import { setApiReady } from '@revelio/core';
import { fullLogout } from './auth.flows';

export const oryLoginFlow = async (data: {
  email?: string;
  username?: string;
  password: string;
}): Promise<{
  ory: { session: OryUserResponse };
  email?: string | undefined;
  username?: string | undefined;
}> => {
  const identifier = data.email || data.username;
  try {
    const browserResponse: LoginFlow = await fetch(
      `${ORY_KRATOS_ROOT}/self-service/login/browser`,
      {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      }
    ).then((r) => r.json());
    const flowId = browserResponse.id;
    const { value: csrfValue } = browserResponse.ui?.nodes.find(
      (n) => (n.attributes as UiNodeInputAttributes)?.name == 'csrf_token'
    )?.attributes as UiNodeInputAttributes;

    const loginResponse = await fetch(
      `${ORY_KRATOS_ROOT}/self-service/login?flow=${flowId}`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          csrf_token: csrfValue,
          identifier: identifier,
          password: data.password,
          method: 'password',
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ).then((r) => {
      if (r.status !== 200) {
        throw new Error('Log in failed.');
      } else {
        return r.json();
      }
    });

    const { password, ...dataWithoutPassword } = data;
    return { ...dataWithoutPassword, ory: loginResponse };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Session length of 12 hours
const SESSION_VALIDATED_LENGTH = 1000 * 60 * 60 * 12;
const isSessionValidatedDateStale = (
  session_validated_at: string | undefined
) => {
  return (
    !isNil(session_validated_at) &&
    +new Date() - +new Date(session_validated_at) > SESSION_VALIDATED_LENGTH
  );
};

type AuthStoreOry = AuthStoreRootProps['ory'];
/** useAuthStoreOry
 * Gets latest authStore and returns ory if defined and the last session validation is not stale.
 * Else fetches the ory session and updates the auth store. */
export const useAuthStoreOry = () => {
  const fetching = useRef(false);
  const { authChecked, ory } = useAuthStore();
  /** We can store a session validation timestamp within the auth store and re-validate if the session becomes stale */
  const isSessionStale = isSessionValidatedDateStale(ory?.session_validated_at);

  useEffect(() => {
    /** if no ory object, no user id, or session is stale, re-fetch whoami */
    if (
      (!authChecked || !ory || !ory.id || isSessionStale) &&
      !fetching.current
    ) {
      (async () => {
        try {
          fetching.current = true;
          const whoAmIResponse = await fetch(
            `${ORY_KRATOS_ROOT}/sessions/whoami`,
            { credentials: 'include' }
          );
          fetching.current = false;

          if (!whoAmIResponse.ok) throw await whoAmIResponse.json();

          const orySession: OryUserResponse = await whoAmIResponse.json();

          const authStoreOry: AuthStoreOry = {
            id: orySession.identity?.id,
            active: orySession.active,
            name: orySession.identity?.traits?.name,
            username: orySession.identity?.traits?.username,
            expires_at: orySession.expires_at,
            authenticated_at: orySession.authenticated_at,
            session_validated_at: new Date().toISOString(),
            account_created_at: orySession.identity?.created_at,
          };

          authStore.update((state) => ({
            ...state,
            authChecked: true,
            ory: authStoreOry,
          }));

          setApiReady();
        } catch (err) {
          /** If there's an error with ory whoami auth, force logout */
          fetching.current = false;
          console.error(err);
          fullLogout();
        }
      })();
    }
  }, [authChecked, ory, isSessionStale]);

  return { ory, fetching: fetching.current };
};

// TODO: move this to a worker via https://github.com/GoogleChromeLabs/comlink
export const oryWhoAmI = pMemoize(
  async (): Promise<OryUserResponse> => {
    try {
      const whoamiResponse = await fetch(`${ORY_KRATOS_ROOT}/sessions/whoami`, {
        cache: 'default',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((r) => r.json());
      return whoamiResponse;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  { cache: new QuickLRU({ maxSize: 1, maxAge: 2000 }) }
);

export async function oryLogout() {
  try {
    if (oryClient) {
      const { data: flow } = await oryClient.createBrowserLogoutFlow();
      await oryClient.updateLogoutFlow({ token: flow.logout_token });
    }
    return { success: true, message: 'logged out' };
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
}
