export const generateHTMLHistogram = (
  d,
  tooltipData,
  colorScale,
  d3Format,
  ttMainFormat
) => {
  let myArray = [];

  // sort tooltipData in order of main value
  var sortedTooltipData = tooltipData.sort((a, b) => {
    return b[1] - a[1];
  });

  myArray.push(
    `<div class="tt-container"><div class="title">${d.metadata.longName}</div>`
  );

  sortedTooltipData.forEach((item) => {
    // bold & increase text size & opacity of bar group being hovered on
    // if (d.data.group_longName === item[0]) {
    //   var weight = 'bold';
    //   var size = '11px';
    //   var opacity = 1;
    // }
    myArray.push(
      `<div class="row">
        <div class ="tt-color-container"><span style="height: 7px; width: 7px; border-radius: 30%; background-color: ${colorScale(
          item[0]
        )}; position: absolute; top: 30%"></span></div>
      <div class="tt-title">${item[0]}</div>
      <div class="tt-count">${item[2]}</div>
      <div class="tt-percent-container"><div class="tt-slash">&nbsp; /</div><div class="tt-percent">${d3Format(
        ttMainFormat
      )(item[1])}</div></div></div>`
    );
  });

  myArray.push('</div>');

  const joined = myArray.join('');
  // return `<div>${d[1] - d[0]} and ${d.key} and ${d.data[d.key]}</div>`;
  return joined;
};
