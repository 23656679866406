import { UserOption } from '../../deliverables/status/user-selector';
import { PagePaths } from '@revelio/core';
import {
  getUnifiedReportTypeDisplayText,
  useAllReports,
} from '../hooks/use-all-reports';
import { DeliveryStatus, GetReportConfigQuery } from '@revelio/data-access';
import { GetReportConfig } from '../../deliverables/report-builder/report-builder-operations';
import { useQuery } from 'urql';

export const useReportTableData = (
  selectedUserOption: UserOption | undefined
) => {
  const { reports, fetching, executeQuery } = useAllReports({
    selectedUserId: selectedUserOption?.value,
    isGroupOption: selectedUserOption?.isGroupOption,
  });
  const [{ data: config, fetching: configFetching }] =
    useQuery<GetReportConfigQuery>({
      query: GetReportConfig,
    });

  const data = reports.map((report) => ({
    id: report.id,
    name: report.name,
    client: report.reportBuilderData?.client || 'Revelio Labs',
    created_at:
      report.createdAt || report.reportBuilderData?.created_at || null,
    updated_at:
      report.updatedAt || report.reportBuilderData?.updated_at || null,
    status:
      report.reportType === 'GeneratedByRevelio'
        ? DeliveryStatus.Finished
        : report.reportBuilderData?.status,
    reportType: getUnifiedReportTypeDisplayText(report.reportType, config),
    viewReportLink: `/${PagePaths.REPORTS}/${report.id}`,
  }));

  return {
    data,
    fetching: fetching || configFetching,
    refresh: executeQuery,
  };
};
