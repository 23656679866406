import {
  BarChartHorizontalData,
  LineData,
  LineValue,
  StackedBarChartHorizontalData,
  StackedBarChartHorizontalValue,
} from '@revelio/d3';
import { CompositionDataQuery, CompositionMetrics } from '@revelio/data-access';
import { isNil, isNumber, round } from 'lodash';

export type TopPlotName =
  | 'headcount'
  | 'growth_rate'
  | 'hiring_rate'
  | 'attrition_rate'
  | 'tenure'
  | 'salary';

export type BottomPlotName =
  | 'job_categories'
  | 'geographies'
  // | 'seniorities'
  /** TODO: Skill Plot Enable */
  | 'skills'
  | 'genders'
  | 'ethnicities'
  | 'educations'
  | 'industries';

/** ================================
 * Bar Data
 ================================ */
const getBarValue = (
  metric: CompositionMetrics[TopPlotName]
): BarChartHorizontalData['value'] | null => {
  if (
    isNil(metric) ||
    isNil(metric.timeseries) ||
    metric.timeseries.length === 0 ||
    isNil(metric?.timeseries?.[0]?.value)
  ) {
    return null;
  }

  return metric.timeseries?.[0]?.value;
};

export const getBarData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): BarChartHorizontalData[] => {
  return (
    entities
      ?.map((entity): BarChartHorizontalData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        return {
          id,
          metadata: { shortName, longName, type },
          value: getBarValue(entity.metrics[plotName]),
        };
      })
      .filter((d): d is BarChartHorizontalData => !isNil(d)) ?? []
  );
};

/** ================================
 * Top Line Data
 ================================ */
const getLineValue = (
  metric: CompositionMetrics[TopPlotName]
): LineData['value'] => {
  if (isNil(metric)) return [];

  return (
    metric.timeseries
      ?.map((d): LineValue | null => {
        const id = Number(d?.id);
        const value = d?.value;
        const dateString = d?.date;
        if (!id || isNil(value) || isNil(dateString)) return null;

        const regEx = /(.*)-(.*)/;
        const match = dateString?.match(regEx);

        if (isNil(match)) return null;
        const year = Number(match[1]);
        const month = Number(match[2]);

        return {
          id,
          value,
          metadata: {
            shortName: dateString,
            longName: dateString,
            count: value,
            share: value,
            month,
            year,
          },
        };
      })
      .filter((d): d is LineValue => !isNil(d)) ?? []
  );
};

export const getTopLineData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: TopPlotName;
}): LineData[] => {
  return (
    entities
      ?.map((entity): LineData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        return {
          id,
          metadata: { shortName, longName, type },
          value: getLineValue(entity.metrics[plotName]),
        };
      })
      .filter((d): d is LineData => !isNil(d)) ?? []
  );
};

/** ================================
 * Stacked Bar Data
 ================================ */
const getStackedBarValue = (
  metric: CompositionMetrics[BottomPlotName]
): StackedBarChartHorizontalData['value'] => {
  const category = metric?.category;
  if (isNil(metric) || isNil(category)) return [];

  return category
    .map((categoryValue): StackedBarChartHorizontalValue | null => {
      const metadata = categoryValue?.metadata;
      const timeseries = categoryValue?.timeseries;
      const share = timeseries?.[0]?.share;
      const count = timeseries?.[0]?.count;
      if (
        isNil(categoryValue) ||
        isNil(metadata) ||
        isNil(metadata?.id) ||
        isNil(metadata?.shortName) ||
        isNil(timeseries) ||
        timeseries.length === 0 ||
        !isNumber(share) ||
        !isNumber(count)
      ) {
        return null;
      }

      return {
        id: metadata.id,
        value: share / 100,
        metadata: {
          shortName: metadata.shortName,
          longName: metadata.shortName,
          count,
        },
      };
    })
    .filter((d): d is StackedBarChartHorizontalValue => !isNil(d));
};

export const getStackedBarData = ({
  entities,
  plotName,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
}): StackedBarChartHorizontalData[] => {
  return (
    entities
      ?.map((entity): StackedBarChartHorizontalData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        const value = getStackedBarValue(entity.metrics[plotName]);

        return {
          id,
          metadata: { shortName, longName, type },
          value,
        };
      })
      .filter((d): d is StackedBarChartHorizontalData => !isNil(d)) ?? []
  );
};

/** ================================
 * Bottom Line Data
 ================================ */

const getBottomLineValue = ({
  metric,
  subfilters,
}: {
  metric: CompositionMetrics[BottomPlotName];
  subfilters: (string | number)[];
}): LineData['value'] => {
  const category = metric?.category;
  if (isNil(metric) || isNil(category)) return [];

  const timeSeries = category?.find(
    (c) => c?.timeseries && c.timeseries?.length > 0
  )?.timeseries;

  if (isNil(timeSeries)) return [];

  return timeSeries
    .map((ts, i): LineValue | null => {
      const id = Number(ts?.id);

      if (isNil(ts) || !isNumber(id)) return null;

      const dateString = ts?.date;
      const regEx = /(.*)-(.*)/;
      const match = dateString?.match(regEx);

      if (isNil(match)) return null;
      const year = Number(match[1]);
      const month = Number(match[2]);

      const subfilterTotal = category
        .filter((c) => {
          const categoryMetadata = c?.metadata;
          return (
            isNil(categoryMetadata) ||
            isNil(categoryMetadata.id) ||
            isNil(categoryMetadata.shortName) ||
            subfilters.includes(`${c?.metadata?.id}`)
          );
        })
        .reduce<{ count: number; share: number }>(
          (acc, value) => {
            const timeSeriesCategoryValues = value?.timeseries?.[i];
            if (
              isNil(timeSeriesCategoryValues?.count) ||
              isNil(timeSeriesCategoryValues?.share)
            ) {
              return acc;
            } else {
              return {
                ...acc,
                count: acc.count + timeSeriesCategoryValues.count,
                share: acc.share + timeSeriesCategoryValues.share,
              };
            }
          },
          { count: 0, share: 0 }
        );

      const share = round(subfilterTotal.share / 100, 4);

      return {
        id,
        value: share,
        metadata: {
          shortName: dateString,
          longName: dateString,
          count: subfilterTotal.count,
          share,
          month,
          year,
        },
      };
    })
    .filter((d): d is LineValue => !isNil(d));
};

export const getBottomLineData = ({
  entities,
  plotName,
  subfilters,
}: {
  entities: CompositionDataQuery['composition'];
  plotName: BottomPlotName;
  subfilters: (string | number)[];
}): LineData[] => {
  return (
    entities
      ?.map((entity): LineData | null => {
        if (isNil(entity) || isNil(entity.metadata) || isNil(entity.metrics)) {
          return null;
        }

        const { id, shortName, longName, type } = entity.metadata;
        if (isNil(id) || isNil(shortName) || isNil(longName)) {
          return null;
        }

        return {
          id,
          metadata: { shortName, longName, type },
          value: getBottomLineValue({
            metric: entity.metrics[plotName],
            subfilters,
          }),
        };
      })
      .filter((d): d is LineData => !isNil(d)) ?? []
  );
};
