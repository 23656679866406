export const drawSentimentEffectsArrows = (
  chart,
  dims,
  leftArrowTranslate,
  rightArrowTranslate
) => {
  const minSpaceBetween = 20;

  const breakpoints = {
    sm: 280,
  };

  const textFontSize = dims.innerWidth < 400 ? '14px' : '16px';

  const { translateX1, translateY1 } = leftArrowTranslate;
  const { translateX2, translateY2 } = rightArrowTranslate;

  const loseContainer = chart.append('g');

  loseContainer
    .append('line') //arrow shaft
    .attr('class', 'mirror-bar-chart-horizontal-arrow')
    .attr('x1', -4)
    .attr('y1', -6)
    .attr('x2', dims.innerWidth < breakpoints.sm ? -40 : -68)
    .attr('y2', -6);

  loseContainer
    .append('line') //top line of arrow head
    .attr('class', 'mirror-bar-chart-horizontal-arrow')
    .attr('x1', dims.innerWidth < breakpoints.sm ? -41 : -69)
    .attr('y1', -6)
    .attr('x2', dims.innerWidth < breakpoints.sm ? -34 : -63)
    .attr('y2', -11);

  loseContainer
    .append('line') //bottom line of arrow head
    .attr('class', 'mirror-bar-chart-horizontal-arrow')
    .attr('x1', dims.innerWidth < breakpoints.sm ? -41 : -69)
    .attr('y1', -6)
    .attr('x2', dims.innerWidth < breakpoints.sm ? -34 : -63)
    .attr('y2', -1);

  const loseText = loseContainer
    .append('text')
    .attr('class', 'mirror-bar-chart-horizontal-text')
    .attr('font-size', textFontSize)
    .text('Factors of Attrition');

  const loseTextRect = loseText.node().getBoundingClientRect();

  const gainContainer = chart.append('g');

  gainContainer
    .append('line') //arrow shaft
    .attr('class', 'mirror-bar-chart-horizontal-arrow')
    .attr('x1', 4)
    .attr('y1', -6)
    .attr('x2', dims.innerWidth < breakpoints.sm ? 40 : 68)
    .attr('y2', -6);

  gainContainer
    .append('line') //top line of arrow head
    .attr('class', 'mirror-bar-chart-horizontal-arrow')
    .attr('x1', dims.innerWidth < breakpoints.sm ? 41 : 69)
    .attr('y1', -6)
    .attr('x2', dims.innerWidth < breakpoints.sm ? 34 : 63)
    .attr('y2', -11);

  gainContainer
    .append('line') //bottom line of arrow head
    .attr('class', 'mirror-bar-chart-horizontal-arrow')
    .attr('x1', dims.innerWidth < breakpoints.sm ? 41 : 69)
    .attr('y1', -6)
    .attr('x2', dims.innerWidth < breakpoints.sm ? 34 : 63)
    .attr('y2', -1);

  const gainText = gainContainer
    .append('text')
    .attr('class', 'mirror-bar-chart-horizontal-text')
    .attr('text-anchor', 'end')
    .attr('font-size', textFontSize)
    .text('Factors of Hiring');

  const gainTextRect = gainText.node().getBoundingClientRect();

  let spaceBetween = 0;

  const loseContainerTranslateX =
    dims.innerWidth < breakpoints.sm ? -35 : translateX1;

  const gainContainerTranslateX =
    dims.innerWidth < breakpoints.sm ? translateX2 : translateX2;

  const loseTextOffsetX = loseContainerTranslateX + loseTextRect.width;
  const gainTextOffsetX = gainContainerTranslateX - gainTextRect.width;

  const isOverlap = loseTextOffsetX + minSpaceBetween >= gainTextOffsetX;

  if (isOverlap) {
    spaceBetween = loseTextOffsetX - gainTextOffsetX + minSpaceBetween;
  }

  loseContainer.attr(
    'transform',
    `translate(${loseContainerTranslateX - spaceBetween / 2},${translateY1})`
  );
  gainContainer.attr(
    'transform',
    `translate(${gainContainerTranslateX + spaceBetween / 2},${translateY2})`
  );
};
