// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { BarChartHorizontalMirrorGenerator } from '../plot-generators/BarChartHorizontalMirrorGenerator';

const BarChartHorizontalMirror = (plotConfigs) => {
  const { name, data, height, width, targetRef, requestHash, customMargins } =
    plotConfigs;

  useD3(BarChartHorizontalMirrorGenerator, { ...plotConfigs, data }, [
    targetRef.current,
    data,
    height,
    width,
    requestHash,
    customMargins,
  ]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

BarChartHorizontalMirror.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  chartStyle: PropTypes.string.isRequired,
};

BarChartHorizontalMirror.defaultProps = {
  name: 'default',
  heading: 'default',
  data: [],
  ttMainFormat: '.2f',
  ttSecondaryFormat: ',',
  chartStyle: '',
};

export default withResizeDetector(BarChartHorizontalMirror, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
