import {
  Box,
  CheckboxProps,
  useCheckbox,
  Icon,
  chakra,
  LayoutProps,
  IconProps,
} from '@chakra-ui/react';

interface CircleCheckboxProps extends CheckboxProps {
  checkBoxApi: ReturnType<typeof useCheckbox>;
}

export function CircleCheckbox({ checkBoxApi, ...props }: CircleCheckboxProps) {
  const { state } = checkBoxApi;

  const { height = '24px', width = '24px', opacity } = props;

  const dEmpty =
    'M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.7533 19.5 0.5 15.2467 0.5 10C0.5 4.7533 4.7533 0.5 10 0.5C15.2467 0.5 19.5 4.7533 19.5 10Z';

  return (
    <chakra.label
      display="contents"
      border="1px solid"
      borderRadius="50%"
      borderColor="gray.300"
      cursor="pointer"
    >
      <Box>
        {state.isChecked ? (
          <CircleChecked
            width={width}
            height={height}
            iconProps={{ opacity }}
          />
        ) : (
          <Icon w={width} h={height} viewBox="0 0 20 20">
            <path fill="none" d={dEmpty} stroke="#2D426A" strokeOpacity="0.2" />
          </Icon>
        )}
      </Box>
    </chakra.label>
  );
}

type CircleCheckedProps = {
  height?: LayoutProps['h'];
  width?: LayoutProps['w'];
  iconProps?: IconProps;
};
export function CircleChecked({
  width,
  height,
  iconProps,
}: CircleCheckedProps) {
  return (
    <Icon w={width} h={height} {...iconProps} viewBox="0 0 20  20">
      <path
        fill="#3BCE7B"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
      />
      <path
        d="M16.4705 6.33976L8.30328 16.4706L3.5293 11.8678L5.09021 10.0098L8.094 12.9059L14.7046 4.70593L16.4705 6.33976Z"
        fill="white"
      />
    </Icon>
  );
}
