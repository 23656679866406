import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
  useTheme,
  useToast,
} from '@chakra-ui/react';

import { DeliverableStepContainer } from '../deliverable-step-container';
import {
  deliverablesStore,
  getInputRefs,
  getPipelineType,
  getRecommendCompanyRef,
  setColumnsToDefault,
  setRecommendCompanyRef,
} from '../deliverables.repository';
import { PagePaths } from '@revelio/core';
import { PipelineType } from '@revelio/data-access';
import DataConfiguration from './data-configuration';
import { DeliverablePaths } from '../deliverables-page';
import { areColumnsValid } from './validate-column-submission';
import { CheckIcon, MinusIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { useIsRevelioAdmin } from '@revelio/auth';
import { formatBytes, useFileSizeEstimation } from './file-size-estimate';
import { createNewEntity, getPipelineTypeTitleById } from '../data-set.model';
import {
  addEntities,
  getAllEntities,
  selectEntitiesCountByPredicate,
  selectEntity,
} from '@ngneat/elf-entities';
import { noop } from 'lodash';
import { PipelineConfigurationToolbar } from './saved-pipeline-configurations/pipeline-configuration-toolbar';
import {
  CompleteCompanyInfoPipeline,
  Deliverable,
  isCompanyInfoPipeline,
} from '../deliverables.model';
import { useObservable } from '@ngneat/react-rxjs';
import EditDatasetColumns from './edit-dataset-columns';
import { useMount } from 'react-use';
import {
  hasSelectedCompany,
  isIndustrySupportedDataset,
} from './columns.model';
import { getCompanyInfoCompanyMapping } from './configurations/company-info';

export const DatasetConfiguration = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isRevelioAdmin } = useIsRevelioAdmin();

  const _entityIds = deliverablesStore
    .query(getAllEntities())
    .filter((d) => d)
    .map((d) => d.id);

  const [entityIds, setEntityIds] = useState(_entityIds || []);

  useMount(() => {
    const deliverables = deliverablesStore.query(getAllEntities());
    const inputRefs = deliverablesStore.query(getInputRefs);
    const companyInfoDataset = deliverables.find((entity) =>
      isCompanyInfoPipeline(entity.pipeline.pipeline_type as PipelineType)
    );
    // If the user did not select Company Ref dataset
    if (!companyInfoDataset) {
      const entitiesObject: Record<string | number, Deliverable> = {};
      deliverables.forEach((entity) => {
        entitiesObject[entity.id] = entity;
      });
      const { newEntity, newId } = createNewEntity(
        PipelineType.CompanyInfo,
        entitiesObject
      );
      const datasetsWithSelectedCompanies = deliverables
        .filter(
          (entity) =>
            entity.pipeline_input ||
            entity.company_reference ||
            entity.company_sets?.length
        )
        .map((entity) => entity.pipeline.pipeline_type) as PipelineType[];
      const companyInfoCompanyMapping = getCompanyInfoCompanyMapping({
        deliverables,
        inputRefs,
        datasetsWithSelectedCompanies,
      });
      newEntity.company_reference = companyInfoCompanyMapping.company_reference;
      newEntity.pipeline_input = companyInfoCompanyMapping.pipeline_input;
      newEntity.company_sets = companyInfoCompanyMapping.company_sets;
      (
        newEntity.pipeline as CompleteCompanyInfoPipeline
      ).company_info_configuration = {
        include_subsidiaries: false,
      };

      const hasCompanySelection = hasSelectedCompany();
      const includesNonIndustrySupportedDataset = deliverables.filter(
        (entity) =>
          !isIndustrySupportedDataset(
            entity.pipeline.pipeline_type as PipelineType
          )
      ).length;
      // if the user makes company selection at Company Mapping
      // or the user doesn't make company selection & has selected datasets that aren't industry supported
      if (
        hasCompanySelection ||
        (!hasCompanySelection && includesNonIndustrySupportedDataset)
      ) {
        deliverablesStore.update(
          setRecommendCompanyRef(true),
          addEntities(newEntity)
        );
        setColumnsToDefault({ entityId: newId });
        setEntityIds((prevState) => [...prevState, newId]);
      } else {
        deliverablesStore.update(setRecommendCompanyRef(false));
      }
    }
  });

  const [selectedDatasetId, setSelectedDatasetId] = useState<
    Deliverable['id'] | null
  >(entityIds[0]);

  const [showDatasetErrors, setShowDatasetErrors] = useState(false);
  const onSubmit = () => {
    const validationSucceeded = entityIds.every((entityId) =>
      areColumnsValid({ entityId, toast })
    );
    if (validationSucceeded) {
      navigate(`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DELIVERY}`);
    }

    setShowDatasetErrors(true);
  };

  const [fileSizeError, setFileSizeError] = useState(false);
  const [pipelineCountIncluded] = useObservable(
    deliverablesStore.pipe(
      selectEntitiesCountByPredicate((del) => !del.isExcluded)
    )
  );
  return (
    <DeliverableStepContainer
      id="columns_filters_select"
      titleElement={isRevelioAdmin && <DataConfiguration />}
      title="Select Columns and Filters"
      startOverPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`}
      previousStepPath={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.COMPANY_SELECTION}`}
      onSubmit={onSubmit}
      isSubmitDisabled={fileSizeError || pipelineCountIncluded === 0}
      submitTooltip={
        fileSizeError
          ? 'Exceeds file size limit, please contact sales for assistance.'
          : undefined
      }
      submitButtonContent={
        fileSizeError ? (
          <>
            Continue
            <Icon as={WarningTwoIcon} color="red" ml="2" />
          </>
        ) : (
          'Continue'
        )
      }
      contentContainerProps={{
        px: 0,
      }}
      showTopDivider={false}
    >
      <Grid gridTemplateColumns="385px 1fr" width="100%">
        <GridItem
          colSpan={selectedDatasetId !== null ? 1 : 2} // !== null because it could be 0
          overflowY="scroll"
          paddingBottom="40px"
          css={{
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          position="relative"
        >
          <Box
            position="absolute"
            right="0"
            top="0"
            bottom="0"
            width="1px"
            bg="gray.200"
            zIndex={0}
          />
          {entityIds.map((entityId, datasetIndex) => (
            <DatasetItem
              key={`data-set-configuration-${entityId}`}
              datasetIndex={datasetIndex}
              entityId={entityId}
              isRevelioAdmin={isRevelioAdmin}
              showDatasetErrors={showDatasetErrors}
              setSelectedDatasetId={setSelectedDatasetId}
              selectedDatasetId={selectedDatasetId}
              setFileSizeError={setFileSizeError}
            />
          ))}
        </GridItem>
        <EditDatasetColumns
          isRevelioAdmin={isRevelioAdmin}
          selectedDatasetId={selectedDatasetId}
          setSelectedDatasetId={setSelectedDatasetId}
        />
      </Grid>
    </DeliverableStepContainer>
  );
};

const DatasetItem = ({
  entityId,
  datasetIndex,
  showDatasetErrors,
  isRevelioAdmin,
  setSelectedDatasetId,
  selectedDatasetId,
  setFileSizeError,
}: {
  entityId: Deliverable['id'];
  showDatasetErrors: boolean;
  isRevelioAdmin: boolean;
  datasetIndex: number;
  setSelectedDatasetId: React.Dispatch<
    React.SetStateAction<Deliverable['id'] | null>
  >;
  selectedDatasetId: Deliverable['id'] | null;
  setFileSizeError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const companyRefRecommended = deliverablesStore.query(getRecommendCompanyRef);
  const pipelineType = getPipelineType({ entityId });
  const isRecommended =
    companyRefRecommended && isCompanyInfoPipeline(pipelineType);
  const datasetIsValid = areColumnsValid({
    entityId,
    toast: noop,
  });
  const showDatasetError = !datasetIsValid && showDatasetErrors;
  const [isExcluded] = useObservable(
    deliverablesStore.pipe(selectEntity(entityId, { pluck: 'isExcluded' })),
    { deps: [entityId] }
  );

  const isDatasetSelected = selectedDatasetId === entityId;

  const handleSelectDataset = () => {
    setSelectedDatasetId((prevId) => (prevId === entityId ? null : entityId));
  };

  const lowOpacityGray = theme.colors.gray[100] + '55';
  const lowOpacityRed = theme.colors.red[100] + '55';
  const lowOpacityBlue = theme.colors.lightBlue[100] + '85';

  /* eslint-disable-next-line no-nested-ternary */
  const BACKGROUND_COLOR = !isDatasetSelected
    ? /* eslint-disable-next-line no-nested-ternary */
      showDatasetError
      ? lowOpacityRed
      : !datasetIsValid
        ? lowOpacityGray
        : 'white'
    : `linear-gradient(to right, ${lowOpacityBlue} 70%, white);`;
  const LAST_CHILD_CSS = {
    '&:last-child': {
      borderBottom: `1px solid ${theme.colors.gray[200]}`,
    },
  };

  const fileSizeEstimation = useFileSizeEstimation({ entityId });
  return (
    <Flex
      borderColor="gray.200"
      borderTop="1px"
      borderTopColor="gray.200"
      borderRight="1px"
      borderRightColor={isDatasetSelected ? 'white' : 'gray.200'}
      onClick={handleSelectDataset}
      padding="0.5rem 1rem"
      background={BACKGROUND_COLOR}
      height="60px"
      width={selectedDatasetId ? '385px' : 'auto'}
      position="relative"
      cursor="pointer"
      css={LAST_CHILD_CSS}
    >
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex alignItems="flex-end" opacity={isExcluded ? 0.4 : undefined}>
          <Flex alignItems="center">
            {datasetIsValid && <CheckIcon color="green.500" mr="2" />}

            {!datasetIsValid && showDatasetErrors && (
              <Icon as={WarningTwoIcon} color="red" mr="2" />
            )}

            {!datasetIsValid && !showDatasetErrors && (
              <Icon as={MinusIcon} color="grey" mr="2" />
            )}

            <Heading
              as="h3"
              fontWeight="400"
              size="sm"
              color="text.primary"
              mr="3"
            >
              {getPipelineTypeTitleById(pipelineType)}
              <Box as="span" color="red">
                {isRecommended && ' (Recommended)'}
              </Box>
            </Heading>
          </Flex>

          <EstimatedFileSize
            isRevelioAdmin={isRevelioAdmin}
            pipelineType={pipelineType}
            fileSizeEstimation={fileSizeEstimation}
            shorthand={selectedDatasetId !== null}
            setFileSizeError={setFileSizeError}
          />
        </Flex>
        {selectedDatasetId == null && (
          <PipelineConfigurationToolbar
            entityId={entityId}
            isRevelioAdmin={isRevelioAdmin}
            pipelineType={pipelineType}
            isDatasetSelected={isDatasetSelected}
            onEditDataset={handleSelectDataset}
          />
        )}
      </Flex>
    </Flex>
  );
};

const EstimatedFileSize = ({
  fileSizeEstimation,
  pipelineType,
  isRevelioAdmin,
  shorthand,
  setFileSizeError,
}: {
  fileSizeEstimation: number;
  pipelineType: PipelineType;
  isRevelioAdmin: boolean;
  shorthand?: boolean;
  setFileSizeError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const supportedDatasets = [
    PipelineType.WfDynam,
    PipelineType.SkillDynam,
    PipelineType.Posting,
    PipelineType.ReviewTrends,
  ];
  useEffect(() => {
    if (
      isFileSizeEstimateHigherThanLimit({ fileSizeEstimation, isRevelioAdmin })
    ) {
      setFileSizeError(true);
    } else {
      setFileSizeError(false);
    }
  }, [fileSizeEstimation, setFileSizeError, isRevelioAdmin]);

  if (!supportedDatasets.includes(pipelineType)) {
    return null;
  }

  return (
    <Text
      mr="2"
      fontSize="xs"
      opacity="0.7"
      color={
        isFileSizeEstimateHigherThanLimit({
          isRevelioAdmin,
          fileSizeEstimation,
        })
          ? 'red'
          : 'text.primary'
      }
    >
      {shorthand
        ? `(Est: ${formatBytes(fileSizeEstimation)})`
        : `(Estimated File Size: ${formatBytes(fileSizeEstimation)})`}
    </Text>
  );
};

const isFileSizeEstimateHigherThanLimit = ({
  fileSizeEstimation,
  isRevelioAdmin,
}: {
  fileSizeEstimation: number;
  isRevelioAdmin: boolean;
}) => {
  return !isRevelioAdmin && fileSizeEstimation > 5368709120; // 5GB
};
