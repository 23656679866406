/**
 * positions a watermark in the chart for png download
 *
 * @param chart - d3 selection to append to
 * @param watermark - config object with svg string, width and height
 * @param width - width of chart area
 * @param height - height of chart area
 * @param padding - the width of the padding around the chart
 *
 */
export const appendWatermark = (chart, watermark, width, height, padding) => {
  const { svgString } = watermark;
  chart
    .append('g')
    .html(svgString)
    .attr('transform', function () {
      const watermarkWidth = this.getBBox().width;
      const watermarkHeight = this.getBBox().height;

      const xOffset = width - watermarkWidth - (padding || 0);
      const yOffset = height - watermarkHeight - (padding || 0);

      return `translate(${xOffset}, ${yOffset})`;
    });
};
