import { forwardRef, MutableRefObject, useState } from 'react';
import { get } from 'lodash';
import { filter, map, pipe } from 'rxjs';
import { CompanySearchTree } from '../../tree/search-trees/company-search-tree/company-search-tree';
import { SelectionCategories } from '../../../engine/filters.model';
import { useSelectionLists } from '../../../engine/filters.engine';
import { PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { SelectFooter } from '../select-footer';
import { CompanyResultItem, TreeNode, arrayToTree } from '../../collection';
import { useScreenerFilter } from '../screener-filter-provider';
import {
  ScreenerFilterOption,
  isCompanySearchFilterState,
  CompanySearchFilters,
} from '../types';

export type CompanySearchFilterHandle = {
  handleClearSelections: () => void;
};

export interface CompanySearchFilterProps {
  treeHeight: number;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

export const CompanySearchFilter = forwardRef<
  CompanySearchFilterHandle,
  CompanySearchFilterProps
>(
  (
    {
      treeHeight,
      searchInputRef,
      closeMenu,
      selectedFilter,
    }: CompanySearchFilterProps,
    ref
  ) => {
    const { state, dispatch } = useScreenerFilter();
    const selectedCompanyDetailState =
      state.filters.company_detail_filters?.find(
        (filter) => filter.name === selectedFilter.value
      );

    const defaultSelectedItems = isCompanySearchFilterState(
      selectedCompanyDetailState
    )
      ? (selectedCompanyDetailState?.companyResultItems as Record<
          string,
          CompanyResultItem
        >)
      : {};
    const [placeholderList, setPlaceholderList] = useState<TreeNode[]>([]);

    const [selections, setSelections] =
      useState<Record<string, CompanyResultItem>>(defaultSelectedItems);

    useSelectionLists(
      [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
      undefined,
      pipe(filter((lists) => lists.length > 0)),
      pipe(
        map((res) => {
          if (res.error) {
            return;
          }
          const { rootItems } = arrayToTree(...res);

          setPlaceholderList(rootItems as TreeNode[]);
        })
      )
    );

    const onClearSelections = () => {
      setSelections({});
    };

    const handleAddFilter = () => {
      if (Object.keys(selections).length) {
        dispatch({
          type: 'ADD_COMPANY_DETAIL_SEARCH_FILTER',
          name: selectedFilter.value as CompanySearchFilters,
          companyResultItems: selections,
        });
      }

      closeMenu();
    };

    return (
      <>
        <PopoverBody padding={0}>
          <CompanySearchTree
            placeholderListNodes={placeholderList}
            selections={selections}
            setSelections={setSelections}
            sortFn={(a: TreeNode, b: TreeNode) => {
              const headcountA = get(a, 'item.raw_emp_count');
              const headcountB = get(b, 'item.raw_emp_count');
              return headcountB - headcountA;
            }}
            searchInputRef={searchInputRef}
            treeHeight={treeHeight}
            treeNodeType="checkboxes"
          />
        </PopoverBody>
        <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
          <SelectFooter
            onClearSelections={onClearSelections}
            onClose={closeMenu}
            onAdd={handleAddFilter}
            addLabel={
              !Object.keys(defaultSelectedItems).length ? 'Add' : 'Update'
            }
          />
        </PopoverFooter>
      </>
    );
  }
);
