import { Heading, Stack, StackProps, Text } from '@chakra-ui/react';

interface HeadingGroupProps extends StackProps {
  title: string;
  description: string;
}

export const HeadingGroup = (props: HeadingGroupProps) => {
  const { title, description, ...stackProps } = props;
  return (
    <Stack spacing="1" marginLeft={1} {...stackProps}>
      <Heading size="md" fontWeight="semibold" color="text.primary">
        {title}
      </Heading>
      <Text color="text.primary" opacity={0.7}>
        {description}
      </Text>
    </Stack>
  );
};

export default HeadingGroup;
