export const ADMIN_USER_ID = '1d1ffbd8-81b4-45ba-85d3-af2b1a07b967';
export const userDetailsQueryData = ({ role, live = true }) => ({
  users: [
    {
      id: ADMIN_USER_ID,
      name: 'Storybook User',
      username: 'storybookUser',
      email: 'storybook@reveliolabs.com',
      role: role,
      client_name: 'Revelio Labs',
      live,
      active: true,
      tabs: [
        'talent_discovery',
        'transition',
        'posting',
        'screener',
        'data_dictionary',
        'skill',
        'sentiment',
        'overview',
        'skill_posting',
      ],
      company_lists: ['681049584891297793', '828340263681622036'],
      metadata: [
        {
          key: 'tour_completed',
          value: 'true',
          __typename: 'KeyValueResp',
        },
        {
          key: 'terms_accepted',
          value: 'true',
          __typename: 'KeyValueResp',
        },
      ],
      linkup_postings: true,
      num_seats: '0',
      __typename: 'User',
    },
  ],
});

export const CLIENT_USER_DETAILS_ID = '12345';
export const clientUserDetailsQueryData = (role) => ({
  users: [
    {
      id: CLIENT_USER_DETAILS_ID,
      name: 'add on user',
      username: 'addOnUser',
      email: 'addOnStorybookUser@reveliolabs.com',
      role: role,
      client_name: 'Revelio Labs',
      live: true,
      active: false,
      tabs: [
        'talent_discovery',
        'transition',
        'posting',
        'screener',
        'data_dictionary',
        'skill',
        'sentiment',
        'overview',
        'skill_posting',
      ],
      company_lists: ['828340263681622036'],
      metadata: [
        {
          key: 'tour_completed',
          value: 'true',
          __typename: 'KeyValueResp',
        },
        {
          key: 'terms_accepted',
          value: 'true',
          __typename: 'KeyValueResp',
        },
        {
          key: 'expiration_date',
          value: '2023-04-24T17:03:46.588Z',
          __typename: 'KeyValueResp',
        },
      ],
      linkup_postings: false,
      num_seats: '0',
      __typename: 'User',
    },
  ],
});
