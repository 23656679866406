export const stateData = {
  '1': '(UK)',
  '2': 'NY',
  '11': 'IL',
  '14': 'DC',
  '15': 'CA',
  '17': 'MA',
  '21': 'CA',
  '24': 'PA',
  '26': 'GA',
  '28': 'TX',
  '30': 'TX',
  '31': 'WA',
  '38': 'CO',
  '47': 'CA',
  '48': 'CA',
  '51': 'AZ',
  '55': 'MN',
  '59': 'MI',
  '63': 'CA',
  '67': 'TX',
  '76': 'NY',
  '92': 'FL',
  '95': 'FL',
  '98': 'OR',
  '105': 'FL',
  '106': 'NC',
  '109': 'CT',
  '111': 'IN',
  '115': 'OH',
  '118': 'NC',
  '119': 'UT',
  '126': 'OH',
  '132': 'MD',
  '133': 'PA',
  '135': 'TN',
  '139': 'MO',
  '149': 'OH',
  '155': 'CA',
  '157': 'VA',
  '158': 'CA',
  '159': 'WI',
  '177': 'TX',
  '185': 'RI',
  '186': 'NC',
  '194': 'NV',
  '195': 'CA',
  '202': 'FL',
  '217': 'FL',
  '224': 'VA',
  '232': 'TX',
  '240': 'WI',
  '242': 'MO',
  '249': 'CO',
  '255': 'OK',
  '263': 'NY',
  '264': 'KY',
  '282': 'MI',
  '285': 'NY',
  '286': 'TN',
  '291': 'SC',
  '296': 'SC',
  '299': 'LA',
  '304': 'IA',
  '308': 'NY',
  '311': 'NE',
  '314': 'OH',
  '323': 'KY',
  '324': 'OK',
  '326': 'FL',
  '327': 'AZ',
  '330': 'AL',
  '341': 'TN',
  '345': 'SC',
  '356': 'ID',
  '368': 'CO',
  '369': 'LA',
  '372': 'AR',
  '374': 'OH',
  '384': 'FL',
  '385': 'FL',
  '406': 'AL',
  '409': 'CA',
  '411': 'NV',
  '413': 'NM',
  '415': 'KS',
  '420': 'HI',
  '421': 'OR',
  '423': 'TN',
  '427': 'CA',
  '428': 'IN',
  '452': 'WA',
  '454': 'GA',
  '464': 'NC',
  '465': 'TX',
  '466': 'MS',
  '481': 'NE',
  '493': 'MO',
  '502': 'AL',
  '513': 'CA',
  '514': 'DE',
  '556': 'TX',
  '562': 'AK',
  '567': 'CA',
  '568': 'TX',
  '836': 'WA',
  '845': 'MA',
  '865': 'CA',
  '889': 'CA',
  '910': 'NC',
  '921': 'NC',
  '953': 'AL',
  '974': '(IN)',
};
