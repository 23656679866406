import { createIcon } from '@chakra-ui/react';

export const DownRightArrowIcon = createIcon({
  displayName: 'DownRightArrowIcon',
  viewBox: '0 0 14 16',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <path
      id="Vector 11"
      opacity="0.5"
      d="M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1H1.5ZM1 13H0.5V13.5H1V13ZM14 13L9 10.1132V15.8868L14 13ZM0.5 1V13H1.5V1H0.5ZM1 13.5H9.5V12.5H1V13.5Z"
      fill="#2D426A"
    />,
  ],
});
