export const plotColors = [
  '#5CA9E9',
  '#70CA97',
  '#E8A18A',
  '#A0A9B8',
  '#AB99D2',
  '#D47E93',
  '#95C7F1',
  '#A2DDBB',
  '#F0C2B3',
  '#C1C7D1',
  '#C8BDE2',
  '#E3ABB9',
  '#4A87BA',
  '#5AA179',
  '#BA816E',
  '#808793',
  '#897AA8',
  '#AA6576',
];
