import { forwardRef, MutableRefObject, useState } from 'react';
import { filter, pipe } from 'rxjs';
import { SelectionCategories } from '../../../engine/filters.model';
import { useSelectionLists } from '../../../engine/filters.engine';
import { PopoverBody, PopoverFooter } from '@chakra-ui/react';
import { SelectFooter } from '../select-footer';
import { useScreenerFilter } from '../screener-filter-provider';
import {
  ScreenerFilterOption,
  isSchoolSearchFilterState,
  SchoolSearchFilters,
} from '../types';
import { ControlledSchoolSearchTree } from '../../tree/search-trees/school-search-tree/controlled-school-search-tree';
import { SchoolItem } from '../../collection';

export type SchoolSearchFilterHandle = {
  handleClearSelections: () => void;
};

export interface SchoolSearchFilterProps {
  treeHeight?: number;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
  closeMenu: () => void;
  selectedFilter: ScreenerFilterOption;
}

export const SchoolSearchFilter = forwardRef<
  SchoolSearchFilterHandle,
  SchoolSearchFilterProps
>(
  ({
    treeHeight,
    searchInputRef,
    closeMenu,
    selectedFilter,
  }: SchoolSearchFilterProps) => {
    const { state, dispatch } = useScreenerFilter();

    // Retrieve the selected school detail state from the global state
    const selectedSchoolDetailState =
      state.filters.company_detail_filters?.find(
        (filter) => filter.name === selectedFilter.value
      );

    // Get default selected items if any
    const defaultSelectedItems: Record<string, SchoolItem> =
      isSchoolSearchFilterState(selectedSchoolDetailState)
        ? selectedSchoolDetailState?.schoolResultItems
        : {};

    const [selections, setSelections] =
      useState<Record<string, SchoolItem>>(defaultSelectedItems);

    useSelectionLists(
      [SelectionCategories.RSID],
      undefined,
      pipe(filter((lists) => lists.length > 0))
    );

    const onClearSelections = () => {
      setSelections({});
    };

    const handleAddFilter = () => {
      if (Object.keys(selections)?.length > 0) {
        dispatch({
          type: 'ADD_SCHOOL_DETAIL_SEARCH_FILTER',
          name: selectedFilter.value as SchoolSearchFilters,
          schoolResultItems: selections,
        });
      }

      closeMenu();
    };

    return (
      <>
        <PopoverBody padding={0}>
          <ControlledSchoolSearchTree
            selections={selections}
            setSelections={setSelections}
            searchInputRef={searchInputRef}
            treeHeight={treeHeight}
            treeNodeType="checkboxes"
          />
        </PopoverBody>
        <PopoverFooter padding="8px 0 0" borderTopWidth={0}>
          <SelectFooter
            onClearSelections={onClearSelections}
            onClose={closeMenu}
            onAdd={handleAddFilter}
            addLabel={
              !Object.keys(defaultSelectedItems).length ? 'Add' : 'Update'
            }
          />
        </PopoverFooter>
      </>
    );
  }
);
