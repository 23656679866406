import { TreeItem } from '../../../engine/filters.model';

export const parseSelectedSegmentsForDisplay = (
  segments: Record<string, TreeItem>
) => {
  return Object.entries(
    Object.entries(segments).reduce(
      (acc, [_, value]) => {
        const key = value.selectionListId;

        return {
          ...acc,
          [key]: [...(acc[key] || []), value],
        };
      },
      {} as Record<string, TreeItem[]>
    )
  );
};
