export const getHumanReadableNumbers = (value: number | undefined) => {
  if (value === undefined) {
    return '';
  }

  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(1)}b`;
  }

  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}m`;
  }

  if (value >= 1000) {
    return `${(value / 1000).toFixed(0)}k`;
  }

  return value.toFixed(0);
};
