import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

interface UseTrackPerformanceProps {
  loading: boolean;
  eventName: string;
}
export const useTrackPerformance = ({
  loading,
  eventName,
}: UseTrackPerformanceProps) => {
  const [startTime, setStartTime] = useState<number | null>(null);
  const [apiStartTime, setApiStartTime] = useState<number | null>(null);
  const [firstApiCall, setFirstApiCall] = useState<boolean>(false);
  useEffect(() => {
    // Capture the start time when the component mounts
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (!firstApiCall && loading) {
      setApiStartTime(Date.now());
      setFirstApiCall(true);
    }
  }, [loading, firstApiCall, setFirstApiCall]);

  useEffect(() => {
    if (!loading && startTime && apiStartTime) {
      const now = Date.now();
      const api_response_time = now - apiStartTime;
      const total_render_time = now - startTime;
      const frontend_render_time = total_render_time - api_response_time;

      mixpanel.track(eventName, {
        total_render_time,
        api_response_time,
        frontend_render_time,
      });
    }
  }, [loading, startTime, apiStartTime, eventName]);
};
