import { useEffect$ } from '@ngneat/react-rxjs';
import { select, filterNil } from '@ngneat/elf';
import { of, startWith, Subject, switchMap, tap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { DASH_META_ROOT } from './auth';
import {
  authStore,
  updateAuthDashMetaCsrfToken,
  updateUserReports,
  useAuthStore,
} from './auth.repository';
import { inRange, isArray } from 'lodash';
import { PagePaths } from '@revelio/core';
import { CustomReportMeta } from './auth.model';
import { useEffect, useRef } from 'react';

export const refreshUserReportsTrigger$ = new Subject<void>();

export const triggerReportsFromAuthRefresh = () => {
  refreshUserReportsTrigger$.next();
};

export const useUserReports = () => {
  useEffect$(() => {
    return refreshUserReportsTrigger$.pipe(
      startWith(null),
      switchMap(() =>
        authStore.pipe(
          select((state) => state.user?.id),
          filterNil(),
          switchMap((userId) =>
            fromFetch(`${DASH_META_ROOT}/api/deliverables/userdocs/${userId}`, {
              credentials: 'include',
              headers: {
                'request-id': crypto.randomUUID(),
              },
              selector(response) {
                if (response.ok && inRange(response.status, 200, 299)) {
                  return response.json();
                }
                return of({
                  error: true,
                  message: response.statusText,
                  status: response.status,
                });
              },
            })
          ),
          tap(
            (
              reportData:
                | CustomReportMeta[]
                | { error: boolean; message: string }
            ): void => {
              if (isArray(reportData)) {
                const routes = reportData.map((r) => ({
                  id: r.id,
                  path: `${PagePaths.REPORTS}/${r.id}`,
                  link: r.link,
                  name: r.name,
                  createdAt: r.createdAt,
                  updatedAt: r.updatedAt,
                }));
                updateUserReports(routes);
              } else {
                console.error(
                  'Report data is in an invalid format',
                  reportData.message
                );
                updateUserReports([]);
              }
            }
          )
        )
      )
    );
  });
};

interface CsrfResponse {
  csrfToken: string;
}
/** useDashMetaCsrf
 * Grabs latest auth store and returns dashMetaCsrfToken if it is defined. Else fetches the token */
export const useDashMetaCsrf = () => {
  const { dashMetaCsrfToken } = useAuthStore();
  const fetching = useRef(false);

  useEffect(() => {
    // if auth is checked and csrf token does not exist, fetch token
    if (!dashMetaCsrfToken && !fetching.current) {
      (async () => {
        try {
          fetching.current = true;
          const csrfResponse = await fetch(`${DASH_META_ROOT}/surf`, {
            headers: { 'request-id': crypto.randomUUID() },
            credentials: 'include',
          });
          fetching.current = false;

          if (!csrfResponse.ok) throw await csrfResponse.json();

          const { csrfToken }: CsrfResponse = await csrfResponse.json();
          updateAuthDashMetaCsrfToken(csrfToken);
        } catch (err) {
          fetching.current = false;
          updateAuthDashMetaCsrfToken('missing');
          console.error('USE_DASH_META_CSRF', err);
        }
      })();
    }
  }, [dashMetaCsrfToken]);

  return { dashMetaCsrfToken, fetching: fetching.current };
};

export const dashMetaLogout = async () => {
  try {
    await fetch(`${DASH_META_ROOT}/api/auth/logout?types="storage"`, {
      credentials: 'include',
      headers: { 'request-id': crypto.randomUUID() },
    });
  } catch (err) {
    console.error('DASH_META_LOGOUT', err);
    throw err;
  }
};
