import { Card } from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';

export const SimpleCard: FC<ComponentProps<typeof Card>> = (props) => {
  return (
    <Card
      p={6}
      borderRadius="xl"
      backgroundColor="white"
      shadow="none"
      {...props}
    />
  );
};
