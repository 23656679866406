import React from 'react';

const GeographyIcon = () => {
  return (
    <svg
      id="Layer_1"
      className="animate-icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 112.49 112.42"
      style={{
        position: 'absolute',
        width: '126px',
        height: '130px',
        bottom: -10,
        right: -10,
        transition: 'opacity 0.25s ease-in-out',
      }}
    >
      <defs>
        <mask
          id="mask"
          x="5.81"
          y="6.32"
          width="106.68"
          height="106.1"
          maskUnits="userSpaceOnUse"
        >
          <g id="mask0_20_55">
            <path
              d="m59.35,111.19c-27.81,0-50.35-22.88-50.35-51.1S31.54,9,59.35,9s50.35,22.88,50.35,51.1-22.54,51.1-50.35,51.1Z"
              fill="#fff"
              strokeWidth={0}
            />
          </g>
        </mask>
      </defs>
      <path
        d="m59.11,110.85c-28.4,0-51.43-23.02-51.43-51.43S30.71,8,59.11,8s51.43,23.02,51.43,51.43-23.02,51.43-51.43,51.43Z"
        fill="#e2faff"
        stroke="#2d426a"
        strokeWidth={2}
      />
      <g mask="url(#mask)">
        <path
          d="m37.27,14.71c-.34,3.61-.34,7.74,2.24,10.49,1.47,1.49,3.31,2.55,5.33,3.1,1.52.68,3.15,1.09,4.82,1.2,4.3.52,9.29-.69,12.9,1.89,3.61,2.58,3.96,7.91,7.74,9.98,1.17.66,2.45,1.07,3.78,1.2,7.4.99,14.92.05,21.84-2.75,2.16-.72,4.11-1.96,5.68-3.61,1.86-2.85,2.71-6.24,2.41-9.63-.89-11.32-11.97-17.89-19.78-18.75-7.81-.86-38.92-.71-42.36,1.03-3.44,1.74-4.42,3.78-4.59,5.85Z"
          fill="#fff"
          stroke="#2d426a"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          d="m94.03,57.53c-1.62-.48-3.3-.71-4.99-.69-1.34,0-2.66.38-3.81,1.08-1.15.7-2.09,1.69-2.72,2.88-1.03,2.24-.52,4.82-1.89,6.71-2.58,3.61-9.63,3.1-11.52,6.88-1.2,2.41.17,5.16,1.55,7.57,3.1,5.68,5.85,12.04,3.96,18.23-.69,2.06-1.72,4.13-2.41,6.19-1.2,3.1,2.41,5.85,5.16,4.82,3.27-1.38,20.47-9.37,27.66-22.57,7.19-13.19,8.29-36.39,3.87-34.04-3.27,1.38-11.06,3.46-14.84,2.94Z"
          fill="#fff"
          stroke="#2d426a"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          d="m25.23,29.5c.02,2.75.49,5.48,1.38,8.08,1.72,3.78,5.5,6.19,7.22,9.98.86,1.72,1.03,3.78,2.06,5.5,1.56,2.11,3.56,3.87,5.85,5.16,2.1,1.35,3.91,3.11,5.33,5.16.7,1.05,1.17,2.24,1.38,3.48.21,1.25.15,2.52-.17,3.74-1.2,4.3-6.54,6.54-11.01,5.5-4.47-1.03-8.08-4.47-12.38-5.68-2.32-.61-4.76-.58-7.06.09-2.3.67-4.38,1.94-6.01,3.7-6.02,8.6-4.82-7.36-4.99-18.54-.13-9.4.55-18.79,2.01-28.07.69-4.85,6.42-10.84,10.03-9.46,4.99,2.06,6.02,6.54,6.36,11.35Z"
          fill="#fff"
          stroke="#2d426a"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
      </g>
      <g
        className="animate-pin"
        style={{
          transformOrigin: '20% 50%',
          transform: 'scale(50%)',
          transition: 'transform 0.2s ease-out',
        }}
      >
        <path
          d="m1,19.75c0,9.1,14.58,26.21,18.02,30.13.39.44,1.06.44,1.45,0,3.45-3.92,18.02-21.03,18.02-30.13,0-4.97-1.98-9.74-5.49-13.26-3.52-3.52-8.28-5.49-13.26-5.49s-9.74,1.98-13.26,5.49C2.98,10.01,1,14.78,1,19.75Z"
          fill="#5bd992"
          stroke="#2d426a"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          d="m19.75,25.25c-3.61,0-6.54-2.93-6.54-6.54s2.93-6.54,6.54-6.54,6.54,2.93,6.54,6.54-2.93,6.54-6.54,6.54Z"
          fill="#fff"
          stroke="#2d426a"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
      </g>
    </svg>
  );
};

export default GeographyIcon;
