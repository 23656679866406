import { useEffect } from 'react';
import { D3ChartGenerator, ID3ChartProps } from '../../model';

/**
 * hook to render d3 chart
 *
 * @param generator - generator function for desired plot
 * @param plotConfigs  - configs passed to generator
 * @param dependencies - values that trigger plot re-render on change
 *
 * @return void
 */
export const useD3 = (
  generator: D3ChartGenerator,
  plotConfigs: ID3ChartProps,
  dependencies: React.DependencyList // this is an array of variables that trigger re-render on change
) => {
  useEffect(() => {
    try {
      generator(plotConfigs, {});
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
