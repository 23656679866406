import React from 'react';

const HighlightLine = ({ winnerCompleted }: { winnerCompleted: boolean }) => {
  return (
    <svg
      width={768}
      height={147}
      viewBox="0 0 768 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        width: '94vh',
        height: 'auto',
        bottom: '43.5%',
        zIndex: 10,
      }}
      className={winnerCompleted ? 'neon-gold-line-2' : 'neon-gold-line-2-gray'}
    >
      <rect
        x={3}
        y={3}
        width={762}
        height="140.735"
        rx="8"
        // stroke="#FFE36D"
        strokeWidth={6}
        className={
          winnerCompleted ? 'neon-gold-line-2-rect-stroke' : 'neon-gray'
        }
      />
      <rect
        x={3}
        y={3}
        width={762}
        height="140.735"
        rx="8"
        stroke="#FFFFFF"
        strokeWidth={2}
      />
    </svg>
  );
};

export default HighlightLine;
