export enum PrizeType {
  GRAND = 'GRAND',
  MEGA = 'MEGA',
  ULTIMATE = 'ULTIMATE',
  NONE = 'NONE',
}

export const lightsOn = (status: boolean) => {
  console.log(`Lights turned ${status ? 'on' : 'off'}`);
  // const url = 'http://localhost:3002/light';
  // fetch(url, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({ command: status ? 'A' : 'B' }),
  // })
  //   .then((response) => response.json())
  //   .then((data) => console.log(data))
  //   .catch((error) => console.error('Error:', error));
};
