export const getCornerRadius = (d, single, chartSize) => {
  var radius = 2;
  //if (chartSize === 'medium') radius = 1;
  const finalRadius = single ? radius : 0;
  if (d[0] === 0) {
    return [radius, 0, 0, radius];
  }
  if (d[1] === 100) {
    return [0, radius, radius, 0];
  }
  return [finalRadius, finalRadius, finalRadius, finalRadius];
};

export const roundedRect = (x, y, width, height, r) => {
  r = [
    Math.min(r[0], height, width),
    Math.min(r[1], height, width),
    Math.min(r[2], height, width),
    Math.min(r[3], height, width),
  ];

  const arc = (r, sign) =>
    r
      ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}`
      : '';

  return `M${x + r[0]},${y}h${width - r[0] - r[1]}${arc(r[1], [1, 1, 1, 1])}v${
    height - r[1] - r[2]
  }${arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]}${arc(
    r[3],
    [1, 1, -1, -1]
  )}v${-height + r[3] + r[0]}${arc(r[0], [1, 1, 1, -1])}z`;
};
