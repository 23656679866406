import { Box, Flex, Text } from '@chakra-ui/layout';
import {
  EMPTY_NODE,
  LOADING_NODE,
  NO_RESULTS_NODE,
  PLACEHOLDER_NODE,
} from '../async-tree.constants';
import { Tag } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import nodeStyles from '../../node/node.module.css';
import { Item } from '../../../../engine/filters.model';
import { useEffect$ } from '@ngneat/react-rxjs';
import { BehaviorSubject, map } from 'rxjs';
import { has } from 'lodash';
import { useState } from 'react';
import NodeToggleButton from '../../node-toggle-button/node-toggle-button';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { WithCheckbox } from '../../node/node';
import { NoDataNode } from './no-data-node';

export interface AsyncTreeCheckboxNodeProps {
  data: {
    id: string;
    item: Item;
    nestingLevel: number;
    name: string;
    showHeadcount?: boolean;
    isSelectedByDefault?: boolean;
    disableSelect?: boolean;
    isLeaf?: boolean;
    select: (item: Item, isChecked: boolean) => void;
    selectionLookup: BehaviorSubject<{
      [key: string]: Item;
    }>;
    noDataText?: string;
  };
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  style: React.CSSProperties;
}

export function AsyncTreeCheckboxNode({
  data: {
    id,
    item,
    nestingLevel,
    showHeadcount = true,
    isSelectedByDefault = false,
    disableSelect,
    isLeaf = false,
    select,
    selectionLookup,
  },
  isOpen,
  setOpen,
  style,
}: AsyncTreeCheckboxNodeProps) {
  const [isChecked, setIsChecked] = useState<boolean>(isSelectedByDefault);

  useEffect$(() => {
    return selectionLookup.pipe(
      map((selectionState) => {
        const isCurrentItemSelected = has(selectionState, id);
        setIsChecked(isCurrentItemSelected);
      })
    );
  });

  if (
    [PLACEHOLDER_NODE, LOADING_NODE, NO_RESULTS_NODE, EMPTY_NODE].includes(id)
  ) {
    return <NoDataNode id={id} />;
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!item) return;

    select(item, e.target.checked);
  };

  const toggleExpandNode = () => {
    if (!isLeaf) setOpen(!isOpen);
  };

  return (
    <div
      className={cx(nodeStyles.highlight, nodeStyles.itemContainer)}
      role="group"
      data-testid="async-tree-search-result"
      style={style}
    >
      <Flex alignItems="center" onClick={toggleExpandNode} w="100%">
        <Box width={isLeaf ? '0' : '6'} marginLeft={nestingLevel * 4}>
          {!isLeaf && (
            <NodeToggleButton
              icon={isOpen ? ChevronDownIcon : ChevronRightIcon}
            />
          )}
        </Box>

        <WithCheckbox
          className="filter-tree-checkbox"
          variant="node-checkbox"
          _groupHover={{
            borderColor: 'gray.300',
          }}
          colorScheme="green"
          paddingLeft={1}
          onChange={handleSelect}
          defaultChecked={isSelectedByDefault}
          isChecked={isChecked}
          width="100%"
          wrapCondition={!disableSelect}
        >
          <Flex
            className={cx(nodeStyles.nodeText)}
            justifyContent="space-between"
            w="100%"
            data-testid={`${item?.primary_name || item.label}_tree_item`}
          >
            <Text as="span" userSelect="none" className={nodeStyles.nodeLabel}>
              {item?.primary_name || item.label}
            </Text>
            {showHeadcount && has(item, 'raw_emp_count') && (
              <Flex justifyContent="center">
                <Tag
                  size="xs"
                  background="gray.100"
                  borderRadius="100"
                  padding="0 12px"
                  marginLeft="8px"
                  variant="subtle"
                >
                  <Text
                    fontSize="10"
                    color="navyBlue.500"
                    justifyContent="center"
                  >
                    {Intl.NumberFormat('en').format(item.raw_emp_count)}
                  </Text>
                </Tag>
              </Flex>
            )}
          </Flex>
        </WithCheckbox>
      </Flex>
    </div>
  );
}
