import { Icon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertTitle,
  Text,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { PopoverTrigger } from '@revelio/core';
import ContactSalesButton from '../contact-sales-button/contact-sales-button';

export interface FreeTrialAlertModalProps {
  days: number;
  hours: number;
}

export function FreeTrialAlertModal({ days, hours }: FreeTrialAlertModalProps) {
  const status = days > 3 ? 'warning' : 'error';

  const trialEndsText =
    hours < 25
      ? `Your trial ends in ${hours} hour${hours === 1 ? '' : 's'}!`
      : `Your trial ends in ${days} days!`;

  return (
    <Popover placement="top-start">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Alert
              status={status}
              borderRadius="25px"
              height="60%"
              fontSize="12px"
              cursor="pointer"
              backgroundColor={status === 'error' ? '#ff4b56' : '#fff7c9'}
            >
              <Icon
                as={WarningTwoIcon}
                color="rgb(255, 207, 8)"
                marginRight="3px"
                boxSize="1em"
              />
              <AlertTitle
                color={status === 'error' ? 'white' : 'black'}
                fontWeight="semibold"
                data-testid="free-trial-alert-title"
              >
                {trialEndsText}
              </AlertTitle>
            </Alert>
          </PopoverTrigger>
          <PopoverContent
            border="none"
            px={1}
            boxShadow="2xl"
            shadow="0 25px 50px -12px rgba(0, 0, 0, 0.25) !important"
          >
            <PopoverHeader
              fontWeight="semibold"
              fontSize="16px"
              borderBottom="none"
              display="flex"
              pt={3}
            >
              <Icon
                as={WarningTwoIcon}
                color="#ffcf08"
                marginRight="3px"
                alignSelf="center"
                boxSize="5"
              />
              <Text>{trialEndsText}</Text>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton color="gray" size="md" />
            <PopoverBody fontSize="14px" pt={0}>
              We'd love to keep you as a customer, and there's still time to
              activate your account. Simply reach out to us to get started with
              a customized plan.
            </PopoverBody>
            <PopoverFooter
              display="flex"
              justifyContent="flex-end"
              borderTop="none"
            >
              <ContactSalesButton
                onClick={onClose}
                buttonProps={{ fontSize: '14px', size: 'sm' }}
              />
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default FreeTrialAlertModal;
