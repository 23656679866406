import { PageTitles } from '@revelio/core';
import { Screener } from '../../../screener/screener';
import {
  ScreenerFilterProvider,
  SelectionCategories,
} from '@revelio/filtering';
import { generateFilterOptions } from '../../utils/generateFilterOptions';
import { ScreenerDimension, View } from '@revelio/data-access';

const title = [PageTitles.ROLE, PageTitles.SCREENER];
const filterOptions = generateFilterOptions(
  SelectionCategories.JOB_CATEGORY,
  'Role'
);

export const RoleScreener = () => {
  return (
    <ScreenerFilterProvider>
      <Screener
        title={title}
        filterOptions={filterOptions}
        view={ScreenerDimension.Role}
        sortingDisabledColumns={['Role']}
        savedSetsView={View.RoleScreener}
      />
    </ScreenerFilterProvider>
  );
};

export default RoleScreener;
