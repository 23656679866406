import {
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
  Input,
  VStack,
} from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import NumberFormat, {
  NumberFormatPropsBase,
  NumberFormatValues,
} from 'react-number-format';
import { OperatorOption, SubFilterValues } from '../types';
import { FORMAT_MAP } from '../constants';
import { Dispatch, SetStateAction } from 'react';
import { TreeItem } from '../../../engine/filters.model';
import { SelectFooter } from '../select-footer';
import { SingleScreenerSegment } from '../screener-filter-reducer';
import {
  ScreenerReqSegmentSelector,
  ScreenerReqFilters,
} from '@revelio/data-access';
import { useScreenerFilter } from '../screener-filter-provider';

interface SubFilterInputFormProps {
  selectedFilterValue: SubFilterValues;
  segmentSelection: Record<string, TreeItem>;
  updateId?: string;
  onSubmit: () => void;
  onClose: () => void;
  onClear: () => void;
  valueControls: {
    startValue: number | undefined;
    setStartValue: Dispatch<SetStateAction<number | undefined>>;
    endValue: number | undefined;
    setEndValue: Dispatch<SetStateAction<number | undefined>>;
    operator: SingleValue<OperatorOption> | OperatorOption;
    setOperator: Dispatch<
      SetStateAction<SingleValue<OperatorOption> | OperatorOption>
    >;
    defaults: {
      startValue: number;
      endValue: number;
      operator: SingleValue<OperatorOption> | OperatorOption;
    };
  };
}

export const operatorOptions: OperatorOption[] = [
  { label: 'is between', value: 'between' },
  { label: 'is greater than', value: 'greater_than' },
  { label: 'is less than', value: 'less_than' },
];

export const SubFilterInputForm = ({
  selectedFilterValue,
  segmentSelection,
  updateId,
  onSubmit,
  onClose,
  onClear,
  valueControls: {
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    operator,
    setOperator,
    defaults,
  },
}: SubFilterInputFormProps) => {
  const { dispatch } = useScreenerFilter();

  const format = selectedFilterValue ? FORMAT_MAP[selectedFilterValue] : null;
  const prefix = format?.type === 'prefix' ? format.text : '';
  const suffix = format?.type === 'suffix' ? format.text : '';

  const onChangeStartValue = (values: NumberFormatValues) => {
    const val = values.floatValue;
    if (val !== undefined && !isNaN(val)) {
      setStartValue(val);
    } else {
      setStartValue(undefined);
    }
  };

  const onChangeEndValue = (values: NumberFormatValues) => {
    const val = values.floatValue;
    if (val !== undefined && !isNaN(val)) {
      setEndValue(val);
    } else {
      setEndValue(undefined);
    }
  };

  const onChangeOperator = (value: SingleValue<OperatorOption>) => {
    setOperator(value);
  };

  const handleClearSelections = () => {
    setStartValue(defaults.startValue);
    setEndValue(defaults.endValue);
    setOperator(defaults.operator);
    onClear();
  };

  // Compute error messages without using state
  const computeErrorMessages = () => {
    const errors: string[] = [];

    if (operator?.value === 'between') {
      if (startValue === undefined) {
        errors.push('Start value is required.');
      }

      if (endValue === undefined) {
        errors.push('End value is required.');
      }

      if (
        startValue !== undefined &&
        endValue !== undefined &&
        startValue >= endValue
      ) {
        errors.push('Start value must be less than the end value.');
      }
    } else if (operator?.value === 'greater_than') {
      if (startValue === undefined) {
        errors.push('Value is required.');
      }
    } else if (operator?.value === 'less_than') {
      if (endValue === undefined) {
        errors.push('Value is required.');
      }
    }

    return errors;
  };

  const errorMessages = computeErrorMessages();
  const isInvalid = errorMessages.length > 0;

  const handleSubmit = () => {
    if (isInvalid || !selectedFilterValue) return;

    const filter = Object.entries(segmentSelection).reduce(
      (acc, [_, value]) => {
        const key = value.selectionListId;
        const prev = acc?.[key as keyof ScreenerReqFilters] || [];
        const newItem = value.item?.id;

        if (!newItem) return acc;

        return { ...acc, [key]: [...prev, newItem] };
      },
      {} as ScreenerReqSegmentSelector['filter']
    );

    const start_val = operator?.value === 'less_than' ? undefined : startValue;
    const end_val = operator?.value === 'greater_than' ? undefined : endValue;

    const newFilter: SingleScreenerSegment = {
      name: selectedFilterValue,
      start_val,
      end_val,
      filter,
    };

    dispatch({
      type: updateId ? 'UPDATE_SUB_FILTER' : 'ADD_SUB_FILTER',
      filter: newFilter,
      id: updateId || '',
      treeItems: segmentSelection,
    });

    onSubmit();
  };

  return (
    <Flex direction="column" gap="3" fontSize="14px">
      <Select
        options={operatorOptions}
        value={operator}
        onChange={onChangeOperator}
        size="sm"
        selectedOptionColorScheme="green"
        chakraStyles={{
          control: (provider) => ({
            ...provider,
            height: '36px',
          }),
        }}
      />
      <FormControl isInvalid={isInvalid}>
        {operator?.value === 'between' ? (
          <Flex
            alignItems="center"
            gap="2.5"
            fontSize="14px"
            justifyContent="space-between"
          >
            <NumberInput
              value={startValue}
              prefix={prefix}
              suffix={suffix}
              onValueChange={onChangeStartValue}
              allowNegative
            />
            <Text>to</Text>
            <NumberInput
              value={endValue}
              prefix={prefix}
              suffix={suffix}
              onValueChange={onChangeEndValue}
              allowNegative
            />
          </Flex>
        ) : (
          <NumberInput
            value={operator?.value === 'greater_than' ? startValue : endValue}
            prefix={prefix}
            suffix={suffix}
            onValueChange={
              operator?.value === 'greater_than'
                ? onChangeStartValue
                : onChangeEndValue
            }
            allowNegative
          />
        )}
        <FormErrorMessage>
          <VStack align="start">
            {errorMessages.map((msg, idx) => (
              <Text key={idx} color="red.600">
                {msg}
              </Text>
            ))}
          </VStack>
        </FormErrorMessage>
      </FormControl>
      <SelectFooter
        onClearSelections={handleClearSelections}
        onClose={onClose}
        onAdd={handleSubmit}
        addLabel={updateId ? 'Update' : 'Add'}
        isAddDisabled={isInvalid}
      />
    </Flex>
  );
};

const NumberInput = ({ ...restProps }: NumberFormatPropsBase<typeof Input>) => {
  return (
    <NumberFormat
      {...restProps}
      customInput={Input}
      thousandSeparator
      displayType="input"
      height="36px"
      fontSize="14px"
      padding="10px"
      borderRadius={'2px'}
    />
  );
};
