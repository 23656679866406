import { Flex } from '@chakra-ui/layout';
import { Button, Switch } from '@chakra-ui/react';
import { GO_API_ROOT } from '@revelio/auth';
import { useEffect, useMemo, useState } from 'react';

interface CompensationModelSwitchProps {
  setUrl: (url: string) => void;
}

export const CompensationModelSwitch = ({
  setUrl,
}: CompensationModelSwitchProps) => {
  const storageIsDevCompensation = useMemo(
    () => localStorage.getItem('isDevCompensation') === 'true',
    []
  );

  const [isDevCompensation, setIsDevCompensation] = useState<boolean>(
    storageIsDevCompensation
  );

  const handleChangeCompensationMode = () => {
    setIsDevCompensation((prevState) => {
      const newState = !prevState;
      localStorage.setItem('isDevCompensation', newState.toString());
      return newState;
    });
  };

  useEffect(() => {
    setUrl(
      isDevCompensation
        ? `${GO_API_ROOT}/models/compensation-dev`
        : `${GO_API_ROOT}/models/compensation`
    );
  }, [isDevCompensation, setUrl]);

  return (
    <Flex alignItems="center">
      <Button
        variant="unstyled"
        size="sm"
        height="22px"
        fontWeight="normal"
        pr="2"
        {...(isDevCompensation && { color: 'gray.400' })}
        onClick={() => setIsDevCompensation(false)}
      >
        Prod model
      </Button>
      <Switch
        size="sm"
        colorScheme="green"
        isChecked={isDevCompensation}
        onChange={handleChangeCompensationMode}
      />
      <Button
        variant="unstyled"
        size="sm"
        fontWeight="normal"
        pl="2"
        height="22px"
        {...(!isDevCompensation && { color: 'gray.400' })}
        onClick={() => setIsDevCompensation(true)}
      >
        Dev model
      </Button>
    </Flex>
  );
};
