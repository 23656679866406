export const dsrPercentiles = [
  {
    percentile: 0,
    z_score: -0.1987911669,
  },
  {
    percentile: 1,
    z_score: -0.1976775077,
  },
  {
    percentile: 2,
    z_score: -0.1970475693,
  },
  {
    percentile: 3,
    z_score: -0.1964843628,
  },
  {
    percentile: 4,
    z_score: -0.1959209841,
  },
  {
    percentile: 5,
    z_score: -0.1953541097,
  },
  {
    percentile: 6,
    z_score: -0.1947817642,
  },
  {
    percentile: 7,
    z_score: -0.1942049068,
  },
  {
    percentile: 8,
    z_score: -0.1936157539,
  },
  {
    percentile: 9,
    z_score: -0.1930236453,
  },
  {
    percentile: 10,
    z_score: -0.192423144,
  },
  {
    percentile: 11,
    z_score: -0.191824982,
  },
  {
    percentile: 12,
    z_score: -0.1912183436,
  },
  {
    percentile: 13,
    z_score: -0.1906031574,
  },
  {
    percentile: 14,
    z_score: -0.1899862155,
  },
  {
    percentile: 15,
    z_score: -0.1893637268,
  },
  {
    percentile: 16,
    z_score: -0.1887187911,
  },
  {
    percentile: 17,
    z_score: -0.1880791377,
  },
  {
    percentile: 18,
    z_score: -0.1874240056,
  },
  {
    percentile: 19,
    z_score: -0.1867579233,
  },
  {
    percentile: 20,
    z_score: -0.1860803189,
  },
  {
    percentile: 21,
    z_score: -0.1853981595,
  },
  {
    percentile: 22,
    z_score: -0.184695321,
  },
  {
    percentile: 23,
    z_score: -0.1839735938,
  },
  {
    percentile: 24,
    z_score: -0.1832416097,
  },
  {
    percentile: 25,
    z_score: -0.1824958157,
  },
  {
    percentile: 26,
    z_score: -0.1817339428,
  },
  {
    percentile: 27,
    z_score: -0.1809570891,
  },
  {
    percentile: 28,
    z_score: -0.1801526694,
  },
  {
    percentile: 29,
    z_score: -0.1793265352,
  },
  {
    percentile: 30,
    z_score: -0.1784767892,
  },
  {
    percentile: 31,
    z_score: -0.1776086544,
  },
  {
    percentile: 32,
    z_score: -0.1767019007,
  },
  {
    percentile: 33,
    z_score: -0.1757802036,
  },
  {
    percentile: 34,
    z_score: -0.1748307837,
  },
  {
    percentile: 35,
    z_score: -0.1738602144,
  },
  {
    percentile: 36,
    z_score: -0.1728512854,
  },
  {
    percentile: 37,
    z_score: -0.1718221923,
  },
  {
    percentile: 38,
    z_score: -0.1707699168,
  },
  {
    percentile: 39,
    z_score: -0.1696692338,
  },
  {
    percentile: 40,
    z_score: -0.1685488981,
  },
  {
    percentile: 41,
    z_score: -0.1673849443,
  },
  {
    percentile: 42,
    z_score: -0.1661953147,
  },
  {
    percentile: 43,
    z_score: -0.1649528029,
  },
  {
    percentile: 44,
    z_score: -0.1636794591,
  },
  {
    percentile: 45,
    z_score: -0.1623487885,
  },
  {
    percentile: 46,
    z_score: -0.1609908711,
  },
  {
    percentile: 47,
    z_score: -0.1595771653,
  },
  {
    percentile: 48,
    z_score: -0.1581140476,
  },
  {
    percentile: 49,
    z_score: -0.1566051553,
  },
  {
    percentile: 50,
    z_score: -0.1550419947,
  },
  {
    percentile: 51,
    z_score: -0.1534350216,
  },
  {
    percentile: 52,
    z_score: -0.1517439504,
  },
  {
    percentile: 53,
    z_score: -0.1500115953,
  },
  {
    percentile: 54,
    z_score: -0.1481965963,
  },
  {
    percentile: 55,
    z_score: -0.146292436,
  },
  {
    percentile: 56,
    z_score: -0.144342944,
  },
  {
    percentile: 57,
    z_score: -0.1423071699,
  },
  {
    percentile: 58,
    z_score: -0.1402033994,
  },
  {
    percentile: 59,
    z_score: -0.1380383391,
  },
  {
    percentile: 60,
    z_score: -0.1357568565,
  },
  {
    percentile: 61,
    z_score: -0.1333803079,
  },
  {
    percentile: 62,
    z_score: -0.1308954721,
  },
  {
    percentile: 63,
    z_score: -0.1283078619,
  },
  {
    percentile: 64,
    z_score: -0.1256031082,
  },
  {
    percentile: 65,
    z_score: -0.12278984,
  },
  {
    percentile: 66,
    z_score: -0.1198026226,
  },
  {
    percentile: 67,
    z_score: -0.1167122532,
  },
  {
    percentile: 68,
    z_score: -0.1133959241,
  },
  {
    percentile: 69,
    z_score: -0.1098898204,
  },
  {
    percentile: 70,
    z_score: -0.1061768883,
  },
  {
    percentile: 71,
    z_score: -0.1022019916,
  },
  {
    percentile: 72,
    z_score: -0.09796727109,
  },
  {
    percentile: 73,
    z_score: -0.09344829599,
  },
  {
    percentile: 74,
    z_score: -0.08856511207,
  },
  {
    percentile: 75,
    z_score: -0.08330858483,
  },
  {
    percentile: 76,
    z_score: -0.07758367451,
  },
  {
    percentile: 77,
    z_score: -0.07128881561,
  },
  {
    percentile: 78,
    z_score: -0.06444378694,
  },
  {
    percentile: 79,
    z_score: -0.05682788883,
  },
  {
    percentile: 80,
    z_score: -0.04849969388,
  },
  {
    percentile: 81,
    z_score: -0.03906091483,
  },
  {
    percentile: 82,
    z_score: -0.02857991919,
  },
  {
    percentile: 83,
    z_score: -0.01688222415,
  },
  {
    percentile: 84,
    z_score: -0.003369769087,
  },
  {
    percentile: 85,
    z_score: 0.01221329601,
  },
  {
    percentile: 86,
    z_score: 0.03047390793,
  },
  {
    percentile: 87,
    z_score: 0.05175354023,
  },
  {
    percentile: 88,
    z_score: 0.07760336208,
  },
  {
    percentile: 89,
    z_score: 0.1079708971,
  },
  {
    percentile: 90,
    z_score: 0.1455151332,
  },
  {
    percentile: 91,
    z_score: 0.1914238028,
  },
  {
    percentile: 92,
    z_score: 0.2483147684,
  },
  {
    percentile: 93,
    z_score: 0.3206424946,
  },
  {
    percentile: 94,
    z_score: 0.4139640874,
  },
  {
    percentile: 95,
    z_score: 0.5383027019,
  },
  {
    percentile: 96,
    z_score: 0.7146166163,
  },
  {
    percentile: 97,
    z_score: 0.9845249068,
  },
  {
    percentile: 98,
    z_score: 1.475268803,
  },
  {
    percentile: 99,
    z_score: 2.647669788,
  },
  {
    percentile: 100,
    z_score: 165.008152841,
  },
];
