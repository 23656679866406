import { min, max, range } from 'd3';
import { monthlyDatesSorted } from '../../utilities/date-check-monthly.js';
import { weeklyDatesSorted } from '../../utilities/date-check-weekly.js';
import { generateTextWidth } from '../../utilities/text-width.js';
import { format as d3Format } from 'd3-format';
import { line, curveBasis } from 'd3-shape';
import { yearDiff, dayDiff } from '../../utilities/date-helpers.ts';
import { generateTooltipData } from '../../utilities/generate-tooltip-data-linechart.js';
import { generateHTMLLinechart } from '../../utilities/generate-html-linechart.js';
import { getColorKeyFromLookup } from '@revelio/core';
import { colors } from '../../utilities/colors.js';

export const getYDates = (data) => {
  const allDates = data
    .map((d) => d.value.map((d) => new Date(d.metadata.year, 0)))
    .flat();

  const allDatesSorted = allDates.sort((a, b) => b - a);

  // add an extra point one month after max date so that tooltip line
  // shows for our max date
  const newMaxDate = new Date(max(allDatesSorted).getFullYear(), 1);

  allDatesSorted.push(newMaxDate);

  data.forEach((d) => {
    const originalMaxVal = d.value[d.value.length - 1];

    const newMaxValue = { ...originalMaxVal };

    if (originalMaxVal) {
      newMaxValue.metadata.year = newMaxDate.getFullYear();
      newMaxValue.metadata.month = newMaxDate.getMonth();

      d.value.push(newMaxValue);
    }
  });

  return [allDates, allDatesSorted];
};
/**
 * dates in year month format
 */
export const getYMDates = (data) => {
  const allDates = data
    .map((d) =>
      d.value.map((d) => new Date(d.metadata.year, d.metadata.month - 1))
    )
    .flat();

  const allDatesSorted = monthlyDatesSorted(allDates);
  // add a new max date 27 days after the original max date (to account for shortest month - Feb)
  // so that the final month shown comes up on tooltip
  const newMaxDate = new Date(
    max(allDatesSorted).getFullYear(),
    max(allDatesSorted).getMonth()
  );
  newMaxDate.setDate(newMaxDate.getDate() + 27);
  allDatesSorted.push(newMaxDate);
  // add a new value to the value array for each company, so the line is drawn past the 1st of the max month
  data.forEach((d) => {
    let originalMaxVal = d.value.slice(-1)[0];
    if (originalMaxVal) {
      let maxValue = JSON.parse(JSON.stringify(originalMaxVal));
      maxValue.id = originalMaxVal.id + 1;
      if (maxValue.metadata.month < 12) {
        maxValue.metadata.month += 1;
      } else {
        maxValue.metadata.month = 1;
        maxValue.metadata.year += 1;
      }
      d.value.push(maxValue);
    }
  });

  return [allDates, allDatesSorted];
};

/**
 * dates in year month date fromat
 */
export const getYMDDates = (data) => {
  const allDates = data
    .map((d) =>
      d.value.map(
        (d) => new Date(d.metadata.year, d.metadata.month - 1, d.metadata.day)
      )
    )
    .flat();
  const allDatesSorted = weeklyDatesSorted(allDates);

  return [allDates, allDatesSorted];
};

/**
 * calculates the width of the longest y axis label
 *
 * @param fontSize
 * @param fontFamily
 * @param padding // adds a bit of extra padding to prevent cutoff
 * @param yAxisTickLabels
 * @param yAxisFormat
 *
 * @returns width of longest y axis label with padding
 */
export const calcMaxLeftLabelWidth = (
  fontSize,
  fontFamily,
  padding,
  yAxisTickLabels,
  yAxisFormat
) => {
  let maxTextWidth = 0;
  let textWidth;
  yAxisTickLabels
    .map((d) => d3Format(yAxisFormat)(d))
    .forEach((d) => {
      textWidth = generateTextWidth(d, `${fontSize}px ${fontFamily}`);
      if (textWidth > maxTextWidth) maxTextWidth = textWidth;
    });

  return maxTextWidth + padding;
};

/**
 * get labels for the Y axis
 */
export const getYAxisTickLabels = (chartSize, yScale) => {
  let yAxisTickLabels;
  // for small charts, only show largest & smallest values on y axis
  if (chartSize === 'small') {
    yAxisTickLabels = [
      yScale.ticks().length % 2 === 0 ? yScale.ticks()[1] : yScale.ticks()[0], // show min tick for odd # of tick vals; second tick for even #
      yScale.ticks().slice(-1)[0],
    ];
  } else {
    // for tick labels & gridlines show largest, smallest & middle value
    yAxisTickLabels = [
      yScale.ticks().length % 2 === 0 ? yScale.ticks()[1] : yScale.ticks()[0], // show min tick for odd # of tick vals; second tick for even #
      yScale.ticks()[Math.floor(yScale.ticks().length / 2)], // middle val for odd # of tick vals; higher val for even #
      yScale.ticks().slice(-1)[0],
    ];
  }

  return yAxisTickLabels;
};

/**
 * get chart lines for year month format
 */
export const genYMChartLines = (
  chart,
  data,
  color,
  xScale,
  yScale,
  metaValueCompany,
  colorLookupKeyPath,
  lineColor
) => {
  const chartLines = chart
    .selectAll('.line')
    .data(data)
    .join('path')
    .attr('class', 'line-path')
    .attr('fill', 'none')
    .attr('stroke', (d, i) => {
      const { metadata } = d;

      const colorKey = getColorKeyFromLookup({
        metadata,
        d,
        colorLookupKeyPath,
      });

      return lineColor || color(colorKey) || colors[i];
    })
    .attr('stroke-width', 2)
    .attr('cursor', 'pointer')
    .attr('d', (d) =>
      line()
        .defined((d) => {
          return d.value !== null;
        })
        .curve(curveBasis)
        .x((d) => xScale(new Date(d.metadata.year, d.metadata.month - 1)))
        .y((d) => yScale(d.value))(d.value)
    );

  return chartLines;
};

/**
 * get chart lines for year month date format
 */
export const genYMDChartLines = (
  chart,
  data,
  color,
  xScale,
  yScale,
  metaValueCompany,
  colorLookupKeyPath,
  lineColor
) => {
  const chartLines = chart
    .selectAll('.line')
    .data(data)
    .join('path')
    .attr('class', 'line-path')
    .attr('fill', 'none')
    .attr('stroke', (d, i) => {
      const { metadata } = d;

      const colorKey = getColorKeyFromLookup({
        metadata,
        d,
        colorLookupKeyPath,
      });

      return lineColor || color(colorKey) || colors[i];
    })
    .attr('stroke-width', 2)
    .attr('cursor', 'pointer')
    .attr('d', (d) =>
      line()
        .defined((d) => {
          return d.value !== null;
        })
        .curve(curveBasis)
        .x((d) =>
          xScale(
            new Date(d.metadata.year, d.metadata.month - 1, d.metadata.day)
          )
        )
        .y((d) => yScale(d.value))(d.value)
    );

  return chartLines;
};

export const getYearIndexes = (minDate, allDatesSorted) => {
  // if date range > 2 years, we will show tick labels in year format
  const yearsDiff = yearDiff(min(allDatesSorted), max(allDatesSorted));
  let yearIndexes;
  // if month of minDate is not Jan, don't show that tick label
  if (minDate.getMonth() !== 0) {
    yearIndexes = range(1, yearsDiff + 1); // each year between min & max, excluding min (since min year is not Jan)
  } else {
    yearIndexes = range(0, yearsDiff + 1); // each year between min & max, inclusive
  }

  if (yearIndexes.length > 4) {
    // for >4 years of data, we will show first, last and middle year
    // show even/odd years (by year index not year value) depending on first month of data & # of years of data
    if (yearIndexes.length % 2 === 0) {
      yearIndexes = [
        yearIndexes[0],
        yearIndexes[yearIndexes.length / 2 - 1],
        yearIndexes.slice(-2)[0],
      ];
    } else {
      yearIndexes = [
        yearIndexes[0],
        yearIndexes[(yearIndexes.length + 1) / 2 - 1],
        yearIndexes.slice(-1)[0],
      ];
    }
  }
  return yearIndexes;
};

export const getXAxisTickLabelsOverTwoYears = (
  minDate,
  allDatesSorted,
  chartSize,
  yearIndexes
) => {
  //   // if date range > 2 years, we will show tick labels in year format
  //   const yearIndexes = getYearIndexes(minDate, allDatesSorted);
  let xAxisTickLabels;
  // map each year index to the min date + the # of years each index represents
  xAxisTickLabels = yearIndexes.map(function (i) {
    const newDate = new Date(minDate.getFullYear(), 0); //set month to jan - when showing year on x axis, we always want to show beginning of that year
    newDate.setFullYear(newDate.getFullYear() + i);
    return newDate;
  });

  if (chartSize === 'small') {
    xAxisTickLabels = [xAxisTickLabels[0], xAxisTickLabels.slice(-1)[0]];
  }

  return xAxisTickLabels;
};

export const getXAxisTickLabelsTwoMonthsToTwoYears = (
  minDate,
  monthsDiff,
  chartSize
) => {
  // if date range is between 2 months and 2 years, we will show tick labels in the format 'Feb 2021' etc
  let monthIndexes = range(0, monthsDiff + 1);
  let xAxisTickLabels;
  // map each month index to the min date + the # of months each index represents
  xAxisTickLabels = monthIndexes.map(function (i) {
    const newDate = new Date(minDate.getFullYear(), minDate.getMonth());
    newDate.setMonth(newDate.getMonth() + i);
    return newDate;
  });

  if (chartSize === 'small') {
    xAxisTickLabels = [xAxisTickLabels[0], xAxisTickLabels.slice(-1)[0]];
  } else if (xAxisTickLabels.length % 2 === 0) {
    xAxisTickLabels = [
      xAxisTickLabels[0],
      xAxisTickLabels[xAxisTickLabels.length / 2 - 1],
      xAxisTickLabels.slice(-2)[0],
    ];
  } else {
    xAxisTickLabels = [
      xAxisTickLabels[0],
      xAxisTickLabels[(xAxisTickLabels.length + 1) / 2 - 1],
      xAxisTickLabels.slice(-1)[0],
    ];
  }

  return xAxisTickLabels;
};

export const getXAxisTickLabelsLessThanTwoMonths = (
  minDate,
  allDatesSorted,
  chartSize
) => {
  const daysDiff = dayDiff(min(allDatesSorted), max(allDatesSorted));
  let dayIndexes = range(0, daysDiff + 1);
  let xAxisTickLabels;
  // map each day index to the min date + the # of days each index represents
  xAxisTickLabels = dayIndexes.map(function (i) {
    const newDate = new Date(
      minDate.getFullYear(),
      minDate.getMonth(),
      minDate.getDate()
    );
    newDate.setDate(newDate.getDate() + i);
    return newDate;
  });

  if (chartSize === 'small') {
    xAxisTickLabels = [xAxisTickLabels[0], xAxisTickLabels.slice(-1)[0]];
  } else if (xAxisTickLabels.length % 2 === 0) {
    xAxisTickLabels = [
      xAxisTickLabels[0],
      xAxisTickLabels[xAxisTickLabels.length / 2 - 1],
      xAxisTickLabels.slice(-2)[0],
    ];
  } else {
    xAxisTickLabels = [
      xAxisTickLabels[0],
      xAxisTickLabels[(xAxisTickLabels.length + 1) / 2 - 1],
      xAxisTickLabels.slice(-1)[0],
    ];
  }

  return xAxisTickLabels;
};

export const createMouseMove = (
  dims,
  xScale,
  yScale,
  ttDataConfig,
  ttConfig,
  chartConfig
) => {
  const {
    data,
    ttType,
    dateFormat,
    allDatesSorted,
    metaValueCompany,
    color,
    ttMainFormat,
    ttSecondaryFormat,
    colorLookupKeyPath,
  } = ttDataConfig;

  const {
    tooltip,
    tooltipLine,
    paddingLeft,
    ttArrowLength,
    ttYPadding,
    ttTitleHeight,
    ttRowHeight,
    tooltipWidth,
    ttCustomString,
    chartStyle,
    ttPosition,
  } = ttConfig;

  const { chartSize, chartPosition } = chartConfig;

  return (event) => {
    let hoveredMonth =
      xScale.invert(event.offsetX - dims.margin.left).getMonth() + 1; //our jan = 1
    let hoveredYear = xScale
      .invert(event.offsetX - dims.margin.left)
      .getFullYear();
    let hoveredDay = xScale.invert(event.offsetX - dims.margin.left).getDate();

    let closestDate;

    let calculatedTranslateX;

    let calculatedTTTranslateX = event.offsetX;

    if (dateFormat === 'Y') {
      const hoveredDate = new Date(hoveredYear, hoveredMonth - 1, hoveredDay);

      closestDate = allDatesSorted.find((d) => d <= hoveredDate);

      hoveredMonth = closestDate.getMonth() + 1;
      hoveredYear = closestDate.getFullYear();
      hoveredDay = closestDate.getDate();

      calculatedTranslateX = xScale(closestDate);
      calculatedTTTranslateX = calculatedTranslateX + dims.margin.left;
    }

    // get data for tooltip based on hovered month & year
    const tooltipData = generateTooltipData(
      data,
      ttType,
      dateFormat,
      hoveredDay,
      hoveredMonth,
      hoveredYear,
      allDatesSorted,
      d3Format,
      metaValueCompany,
      color,
      ttMainFormat,
      ttSecondaryFormat,
      colorLookupKeyPath
    );

    tooltip
      .style('opacity', 1)
      .attr('hidden', () => {
        // hide tooltip if all data is null
        const allDataNull = tooltipData
          .slice(1)
          ?.every((line) => line[2]?.every((datum) => datum === null));

        return allDataNull || null;
      })
      .style('transform', () => {
        return `translate(${
          calculatedTTTranslateX + paddingLeft + ttArrowLength
        }px, ${
          dims.margin.top +
          yScale(max(tooltipData.slice(1).map((d) => d[2][0]))) -
          ttYPadding - //upper & lower padding of tt
          ttTitleHeight - //height of tt title
          data.length * ttRowHeight - //each row of tt = 21px tall
          ttArrowLength
        }px)`;
      })
      .html(
        generateHTMLLinechart(
          tooltipData,
          dateFormat,
          ttType,
          ttCustomString,
          chartStyle,
          d3Format,
          ttMainFormat,
          ttSecondaryFormat
        )
      );

    tooltipLine
      .style('opacity', 1)
      .attr('hidden', null)
      .style('transform', () => {
        return `translate(${
          calculatedTranslateX ?? event.offsetX - dims.margin.left
        }px, ${0}px)`;
      });

    // switch orientation of tooltip if past midpoint of chart
    if (chartPosition === 'left') {
      tooltip.classed('tooltip-line-chart-arrow-right', false);
      tooltip.classed('tooltip-line-chart-arrow-left', true);
    } else if (chartSize === 'small' && chartPosition === 'right') {
      tooltip.classed('tooltip-line-chart-arrow-left', false);
      tooltip.classed('tooltip-line-chart-arrow-right', true);
      tooltip.style(
        'transform',
        `translate(${
          event.offsetX + paddingLeft - tooltipWidth - ttArrowLength
        }px, ${
          //event.offsetY - (data.length + (data.length - 2) * 0.5) * 10.6
          dims.margin.top +
          yScale(max(tooltipData.slice(1).map((d) => d[2][0]))) -
          ttYPadding - //upper & lower padding of tt
          ttTitleHeight - //height of tt title
          data.length * ttRowHeight - //each row of tt = 21px tall
          ttArrowLength
        }px)` //-(data.length + (data.length - 2) * 0.5) * 10.6
      );
    } else if (
      event.offsetX - dims.margin.left > dims.innerWidth / 2 ||
      ttPosition === 'left'
    ) {
      tooltip.classed('tooltip-line-chart-arrow-right', true);
      tooltip.classed('tooltip-line-chart-arrow-left', false);
      tooltip.style(
        'transform',
        `translate(${
          calculatedTTTranslateX + paddingLeft - tooltipWidth - ttArrowLength //9=approx arrow length
        }px, ${
          //event.offsetY - (data.length + (data.length - 2) * 0.5) * 10.6
          dims.margin.top +
          yScale(max(tooltipData.slice(1).map((d) => d[2][0]))) -
          ttYPadding - //upper & lower padding of tt
          ttTitleHeight - //height of tt title
          data.length * ttRowHeight - //each row of tt = 21px tall
          ttArrowLength
        }px)` //-(data.length + (data.length - 2) * 0.5) * 10.6
      );
    } else {
      tooltip.classed('tooltip-line-chart-arrow-left', true);
      tooltip.classed('tooltip-line-chart-arrow-right', false);
    }
  };
};
