import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { get } from 'lodash';
import { filter, map, pipe } from 'rxjs';
import {
  CompanySearchTree,
  SelectionCategories,
  CompanyResultItem,
  TreeNode,
  useSelectionLists,
  arrayToTree,
} from '@revelio/filtering';

export type ReportEntityCompanyEditorHandle = {
  handleClearSelections: () => void;
};

export interface ReportEntityCompanyEditorProps {
  limit: number;
  treeHeight: number;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
  onSelectionsValue: (items: Record<string, CompanyResultItem>) => void;
}

export const ReportEntityCompanyEditor = forwardRef<
  ReportEntityCompanyEditorHandle,
  ReportEntityCompanyEditorProps
>(
  (
    {
      limit,
      treeHeight,
      searchInputRef,
      onSelectionsValue,
    }: ReportEntityCompanyEditorProps,
    ref
  ) => {
    const [placeholderList, setPlaceholderList] = useState<TreeNode[]>([]);
    const [selections, setSelections] = useState<
      Record<string, CompanyResultItem>
    >({});

    if (limit > 1) {
      throw new Error(
        "ReportEntityCompanyEditor doesn't currently support multiselect"
      );
    }

    useImperativeHandle(ref, () => ({
      handleClearSelections: () => {
        setSelections({});
      },
    }));

    useEffect(() => {
      onSelectionsValue && onSelectionsValue(selections);
    }, [onSelectionsValue, selections]);

    useSelectionLists(
      [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
      undefined,
      pipe(filter((lists) => lists.length > 0)),
      pipe(
        map((res) => {
          if (res.error) {
            return;
          }
          const { rootItems } = arrayToTree(...res);
          setPlaceholderList(rootItems as TreeNode[]);
        })
      )
    );

    return (
      <CompanySearchTree
        isSingleSelect={true}
        placeholderListNodes={placeholderList}
        selections={selections}
        setSelections={(item) => {
          const id = item.rcid;
          setSelections((current) => {
            if (current[id]) {
              return {};
            }
            return {
              [id]: item,
            };
          });
        }}
        sortFn={(a: TreeNode, b: TreeNode) => {
          const headcountA = get(a, 'item.raw_emp_count');
          const headcountB = get(b, 'item.raw_emp_count');
          return headcountB - headcountA;
        }}
        searchInputRef={searchInputRef}
        treeHeight={treeHeight}
        treeNodeType={'checkboxes'}
      />
    );
  }
);
