import { useQuery } from 'urql';

import {
  serializeTDFilters,
  talentDiscoveryFilterTransformation,
  useActiveFiltersState,
  useTalentDiscoveryFilterContext,
} from '@revelio/filtering';
import { TalentDiscoveryV1_5Filter } from '@revelio/data-access';
import { TALENT_DISCOVERY_DATA } from './graphql-operations';

export const useTalentDiscoveryDataFetch = () => {
  const activeFilters = useActiveFiltersState();
  const activeFiltersWithoutSkills = activeFilters.filter(
    (f) => !f.id.startsWith('skill_')
  );

  const talentDiscoveryFilterApi = useTalentDiscoveryFilterContext();
  const skillFilters = talentDiscoveryFilterApi.skillFilterApi.skillFilters;

  const numOfFilterSelections =
    activeFiltersWithoutSkills.length + skillFilters.length;

  const [{ data, fetching }] = useQuery({
    query: TALENT_DISCOVERY_DATA,
    variables: {
      query: {
        ...(talentDiscoveryFilterTransformation({
          skillFilters: talentDiscoveryFilterApi.skillFilterApi.skillFilters,
          filters: serializeTDFilters(activeFiltersWithoutSkills),
        }) as TalentDiscoveryV1_5Filter),
        page: 1,
        num_results: 5000,
      },
    },
    pause: numOfFilterSelections === 0,
  });

  return {
    data: numOfFilterSelections > 0 ? data : undefined,
    loading: fetching,
  };
};
