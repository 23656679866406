import { get } from 'lodash';
import { useAsync } from 'react-use';
import QuickLRU from 'quick-lru';

const millisInADay = 60000 * 60 * 24;
export const dataCache = new QuickLRU({ maxAge: millisInADay, maxSize: 500 });

export const useFetchTalentDiscoveryMap = () => {
  const countryUrl =
    'https://revelio-dashboard.s3.us-east-2.amazonaws.com/countryBorders.json';
  const stateUrl =
    'https://revelio-dashboard.s3.us-east-2.amazonaws.com/stateBorders.json';

  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  async function isResponseOk(url: string, response: Response) {
    if (response.status >= 200 && response.status <= 299) {
      const data = await response.json();
      dataCache.set(url, data);
      return data;
    } else if (response.status >= 401 && response.status <= 499) {
      // TODO: do logout
    } else if (response.status >= 500 && response.status <= 599) {
      throw new Error('Oops! An Error Occurred.');
    } else {
      throw new Error(response.statusText);
    }
  }

  async function getData(url: string) {
    if (dataCache.has(url)) {
      return dataCache.get(url);
    } else {
      const resp = await fetch(url, defaultOptions);
      return isResponseOk(url, resp);
    }
  }

  const state = useAsync(async () => {
    return Promise.all([getData(countryUrl), getData(stateUrl)]);
  }, []);

  const bordersLoading = state.loading;
  const [countryBorders, stateBorders] = get(state, 'value', []);

  return { countryBorders, stateBorders, bordersLoading };
};
