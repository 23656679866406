import { findKey } from 'lodash';
import {
  ModelVersions,
  PipelineColumnType,
  PipelineType,
} from '@revelio/data-access';
import { write } from '@revelio/core';
import {
  DEMOGRAPHIC_BREAKDOWN_DEFAULTS,
  GEO_BREAKDOWN_DEFAULTS,
  NO_COMPANY_DEFAULTS,
} from '../configurations/workforce-dynamics';
import {
  areSelectedColumnsIdenticalToColumnConfiguration,
  getDatasetColumnDefaults,
  getStandardNoCompanyDatasetColumnDefaults,
  isIndustrySupportedDataset,
} from '../columns.model';
import {
  LATEST_MODEL_VERSIONS,
  deliverablesStore,
  getOrderedCombinedColumns,
  getPipelineType,
  setOrderedColumns,
  updateDraftDeliverable,
  updateModelVersions,
} from '../../deliverables.repository';
import {
  CustomColumn,
  DatasetFilter,
  Deliverable,
} from '../../deliverables.model';
import { areLatestModelVersions } from '../data-configuration';
import { getEntity } from '@ngneat/elf-entities';

export type PipelineConfigurationSelectOption = {
  label: string;
  value: string;
};
export const NEW_PIPELINE_CONFIGURATION_SELECT_OPTION: PipelineConfigurationSelectOption =
  {
    label: 'Create new',
    value: 'create_new',
  };

export const DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION: PipelineConfigurationSelectOption =
  {
    label: 'Standard',
    value: 'standard',
  };

export const DEMOGRAPHIC_BREAKDOWN_OPTION = {
  label: 'Demographic',
  value: 'demo_breakdown',
};
export const GEO_BREAKDOWN_OPTION = {
  label: 'Geography',
  value: 'geo_breakdown',
};
const NO_COMPANY_WF_DYNAMICS_STANDARD_OPTION = {
  label: 'Standard (no company)',
  value: 'wf_dynamics_standard_no_company',
};

const NO_COMPANY_STANDARD_OPTION = {
  label: 'Standard (no company)',
  value: 'standard_no_company',
};

export const STANDARD_PIPELINE_CONFIGURATIONS = [
  DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION,
];
export const WF_DYNAMICS_GLOBAL_PIPELINE_CONFIGURATION: PipelineConfigurationSelectOption[] =
  [
    {
      label: 'Role (Standard)',
      value: DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION.value,
    },
    DEMOGRAPHIC_BREAKDOWN_OPTION,
    GEO_BREAKDOWN_OPTION,
  ];

export const getPipelineConfigurationOptions = ({
  pipelineType,
  hasSelectedCompany,
}: {
  pipelineType: PipelineType;
  hasSelectedCompany: boolean;
}) => {
  if (isIndustrySupportedDataset(pipelineType) && !hasSelectedCompany) {
    if (pipelineType === PipelineType.WfDynam) {
      return [NO_COMPANY_WF_DYNAMICS_STANDARD_OPTION];
    }

    return [NO_COMPANY_STANDARD_OPTION];
  }

  if (pipelineType !== PipelineType.WfDynam) {
    return STANDARD_PIPELINE_CONFIGURATIONS;
  }

  return WF_DYNAMICS_GLOBAL_PIPELINE_CONFIGURATION;
};

export const globalPipelinConfigurationColumnsMap = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}): {
  [selectValue: string]: PipelineColumnType[];
} => ({
  [DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION.value]:
    getDatasetColumnDefaults({ entityId }),
  [DEMOGRAPHIC_BREAKDOWN_OPTION.value]: DEMOGRAPHIC_BREAKDOWN_DEFAULTS,
  [GEO_BREAKDOWN_OPTION.value]: GEO_BREAKDOWN_DEFAULTS,
  [NO_COMPANY_WF_DYNAMICS_STANDARD_OPTION.value]: NO_COMPANY_DEFAULTS,
  [NO_COMPANY_STANDARD_OPTION.value]: getStandardNoCompanyDatasetColumnDefaults(
    { entityId }
  ),
});

export const getPipelineConfigurationValue = ({
  entityId,
  selectedPipelineColumns,
  savedPipelineConfigurationSelection,
}: {
  entityId: Deliverable['id'];
  selectedPipelineColumns: PipelineColumnType[];
  savedPipelineConfigurationSelection?: PipelineConfigurationSelectOption | null;
}) => {
  const matchingGlobalPipelineConfigurationValue = findKey(
    globalPipelinConfigurationColumnsMap({ entityId }),
    (columnSet) => {
      return areSelectedColumnsIdenticalToColumnConfiguration({
        entityId,
        selectedPipelineColumns,
        pipelineColumnsToCompare: getOrderedCombinedColumns({
          entityId,
          columnIds: columnSet,
        }),
      });
    }
  );
  const modelVersions = deliverablesStore.query(
    getEntity(entityId)
  )?.model_versions;
  const pipelineType = getPipelineType({ entityId });
  if (
    matchingGlobalPipelineConfigurationValue &&
    areLatestModelVersions({ modelVersions })
  ) {
    const wFDynamicsPipelineConfiguration = [
      ...WF_DYNAMICS_GLOBAL_PIPELINE_CONFIGURATION,
      NO_COMPANY_WF_DYNAMICS_STANDARD_OPTION,
    ].find(
      (configOption) =>
        configOption.value === matchingGlobalPipelineConfigurationValue
    );
    if (wFDynamicsPipelineConfiguration) {
      if (pipelineType === PipelineType.WfDynam) {
        return wFDynamicsPipelineConfiguration;
      } else {
        return wFDynamicsPipelineConfiguration.value ===
          DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION.value
          ? DEFAULT_PIPELINE_CONFIGURATION_SELECT_OPTION
          : NEW_PIPELINE_CONFIGURATION_SELECT_OPTION;
      }
    }

    return [
      ...STANDARD_PIPELINE_CONFIGURATIONS,
      NO_COMPANY_STANDARD_OPTION,
    ].find(
      (configOption) =>
        configOption.value === matchingGlobalPipelineConfigurationValue
    ) as PipelineConfigurationSelectOption;
  }

  if (savedPipelineConfigurationSelection) {
    return savedPipelineConfigurationSelection;
  }

  return NEW_PIPELINE_CONFIGURATION_SELECT_OPTION;
};

export const applyPipelineConfiguration = ({
  entityId,
  columns,
  modelVersions = LATEST_MODEL_VERSIONS,
  customColumns,
  filters,
}: {
  entityId: Deliverable['id'];
  columns: PipelineColumnType[];
  modelVersions?: ModelVersions;
  customColumns?: CustomColumn[];
  filters?: DatasetFilter[];
}) => {
  updateModelVersions({
    entityId,
    newModelVersions: modelVersions,
  });
  updateDraftDeliverable(
    entityId,
    write<Deliverable>((state) => {
      state.pipeline.custom_columns =
        customColumns === null ? undefined : customColumns;
      state.pipeline.filters = filters === null ? undefined : filters;
    })
  );

  setOrderedColumns({
    entityId,
    columnIds: columns,
  });
};
