import { GqlPlotName } from './gql.models';
import {
  Transition2DResponse,
  TransitionMetrics,
  graphql,
} from '@revelio/data-access';
import {
  SankeyData,
  SankeyLink,
  SankeyNode,
  StackedBarChartHorizontalData,
  StackedBarChartHorizontalValue,
} from '@revelio/d3';
import { SerializedFiltersForQuery } from '../filters.model';
import { isNil } from 'lodash';

const generatePlotStackOthers = (
  others: StackedBarChartHorizontalValue[]
): StackedBarChartHorizontalValue => {
  return others.reduce<StackedBarChartHorizontalValue>(
    (acc, curr): StackedBarChartHorizontalValue => {
      if (
        isNil(acc.value) ||
        isNil(curr.value) ||
        isNil(acc.metadata?.count) ||
        isNil(curr.metadata?.count)
      ) {
        return acc;
      }
      return {
        ...acc,
        value: acc.value + curr.value,
        metadata: {
          ...acc.metadata,
          count: acc.metadata.count + curr.metadata.count,
          share: (acc.metadata?.share || 0) + (curr.metadata?.share || 0),
        },
      };
    },
    {
      id: 0,
      value: 0,
      metadata: {
        count: 0,
        share: 0,
        longName: 'Other',
        shortName: 'Other',
      },
    }
  );
};

const transitionSankeyPlotNames = [GqlPlotName.TRANSITION] as const;
type TransitionSankeyPlotName = (typeof transitionSankeyPlotNames)[number];
const transitionBarPlotNames = [
  GqlPlotName.ROLE,
  GqlPlotName.GEOGRAPHY,
  GqlPlotName.SENIORITY,
  GqlPlotName.GENDER,
  GqlPlotName.ETHNICITY,
  GqlPlotName.EDUCATION,
  GqlPlotName.INDUSTRY,
] as const;
type TransitionBarPlotName = (typeof transitionBarPlotNames)[number];

type TransitionPlotName = TransitionSankeyPlotName | TransitionBarPlotName;

const entityMeticLookup: Record<
  TransitionBarPlotName,
  keyof Pick<
    TransitionMetrics,
    | 'role'
    | 'geography'
    | 'seniority'
    | 'gender'
    | 'ethnicity'
    | 'education'
    | 'industry'
  >
> = {
  role: 'role',
  geo: 'geography',
  seniority: 'seniority',
  gender: 'gender',
  ethnicity: 'ethnicity',
  education: 'education',
  industry: 'industry',
};

interface ConvertTransitionResponseToPlotDataProps {
  entities: Transition2DResponse[];
  plotName: TransitionPlotName;
  filters: SerializedFiltersForQuery;
}
type TransitionPlotData = SankeyData | StackedBarChartHorizontalData[];
export const convertTransitionResponseToPlotData = ({
  entities,
  plotName,
  filters,
}: ConvertTransitionResponseToPlotDataProps): TransitionPlotData => {
  const isInflow = !!filters.inflow;

  if (entities.length === 0) return [];
  const entity = entities[entities.length - 1];

  /** ================ Transition Sankey ================ */
  if (plotName === GqlPlotName.TRANSITION) {
    const { metadata: entityMetadata, metrics: entityMetrics } = entity;
    const entityId = `entity_${entityMetadata?.id}`;

    return (
      entity.category?.reduce<SankeyData>(
        (acc, category, index): SankeyData => {
          const metadata = category?.metadata;
          const metrics = category?.metrics;

          const node: SankeyNode = {
            id: metadata?.id,
            metadata: {
              shortName: metadata?.shortName,
              longName: metadata?.longName,
            },
            value: metrics?.count,
          };

          const link: SankeyLink = {
            id: index,
            source: isInflow ? metadata?.id : entityId,
            target: isInflow ? entityId : metadata?.id,
            metadata: {
              target_share: isInflow ? (metrics?.value || 0) / 100 : 1,
              source_share: isInflow ? 1 : (metrics?.value || 0) / 100,
            },
            value: metrics?.count,
          };

          return {
            ...acc,
            nodes: [...acc.nodes, node],
            links: [...acc.links, link],
          };
        },
        /** Intial State */
        {
          links: [],
          // initialize with entity node
          nodes: [
            {
              id: entityId,
              metadata: {
                shortName: entityMetadata?.shortName,
                longName: entityMetadata?.longName,
              },
              value: entityMetrics?.count,
            },
          ],
        }
      ) || { links: [], nodes: [] }
    );
  } else {
    /** ================ Stacked Bar Charts ================ */
    const metricName = entityMeticLookup[plotName];
    const groups: ('Current' | 'Inflow' | 'Outflow')[] = [
      'Current',
      isInflow ? 'Inflow' : 'Outflow',
    ];
    /** Create 2 bar stacks, 1 for current state, 1 for inflow/outflow dependent on view */
    const plotStack = groups.map(
      (group): StackedBarChartHorizontalData => ({
        id: group,
        metadata: { shortName: group, longName: group },
        value:
          entity.metrics?.[metricName]
            ?.filter(
              (metric) =>
                !(metric?.shortName === 'Empty' || metric?.longName === 'Empty')
            )
            ?.map((metric): StackedBarChartHorizontalValue => {
              const { count, share } =
                // eslint-disable-next-line no-nested-ternary
                group === 'Current'
                  ? { count: metric?.current, share: metric?.currentShare }
                  : group === 'Inflow'
                    ? { count: metric?.inflow, share: metric?.inflowShare }
                    : { count: metric?.outflow, share: metric?.outflowShare };

              return {
                id: metric?.id,
                metadata: {
                  shortName: metric?.shortName,
                  longName: metric?.longName,
                  count,
                  share,
                },
                value: share,
              };
            }) || [],
      })
    );

    /** If Role, Geography, or Industry, group least remaining values after 7 into Other category */
    if (
      [GqlPlotName.ROLE, GqlPlotName.GEOGRAPHY, GqlPlotName.INDUSTRY].includes(
        plotName
      )
    ) {
      // sort current stack desc
      plotStack[0].value.sort((a, b) => (b?.value || 0) - (a?.value || 0));
      // get current stack first 7, and put remaining in Other array
      const currentPlotStackFirstSeven = plotStack[0].value.slice(0, 7);
      const currentPlotStackOthers = plotStack[0].value.slice(7);

      // for each current stack's first 7, find the corresponding flow stack item and push to flow stack first 7
      const flowPlotStackFirstSeven: StackedBarChartHorizontalValue[] = [];
      currentPlotStackFirstSeven.forEach((currentItem) => {
        const flowItem = plotStack[1].value.find(
          (v) => v.id === currentItem.id
        );
        if (flowItem) flowPlotStackFirstSeven.push(flowItem);
      });

      // do the same thing for the other items in current stack, putting corresponding items to flow stack others
      const flowPlotStackOthers: StackedBarChartHorizontalValue[] = [];
      currentPlotStackOthers.forEach((currentItem) => {
        const flowItem = plotStack[1].value.find(
          (v) => v.id === currentItem.id
        );
        if (flowItem) flowPlotStackOthers.push(flowItem);
      });

      // overwrite current and flow stacks with sorted first 7 values
      plotStack[0].value = currentPlotStackFirstSeven;
      plotStack[1].value = flowPlotStackFirstSeven;

      // for the 8th value, generate an "Other" category
      plotStack[0].value[7] = generatePlotStackOthers(currentPlotStackOthers);
      plotStack[1].value[7] = generatePlotStackOthers(flowPlotStackOthers);
    }

    return plotStack;
  }
};

// dim2 is inflow or outflow
// cetegory is a list of companies (either inflow or outflow based on dim2)
export const TRANSITIONS_DATA = graphql(`
  query TransitionData(
    $filters: TransitionFilters
    $dim1: Dimension1
    $dim2: TransitionDimension
  ) {
    transitions2D(filters: $filters, dim1: $dim1, dim2: $dim2) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        count
        role {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
        industry {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
        geography {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
        seniority {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
        gender {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
        ethnicity {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
        education {
          id
          shortName
          longName
          current
          currentShare
          inflow
          inflowShare
          outflow
          outflowShare
        }
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          value
          count
        }
      }
    }
  }
`);
