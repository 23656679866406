import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { FieldError, FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { Input } from '@chakra-ui/react';
import { CustomColumnConditionVariable } from '@revelio/data-access';
import { SelectionCategories } from '@revelio/filtering';

import {
  MultiGranularitySelectionListVariables,
  NumericOperation,
  NumericVariable,
  SelectionListOperation,
  SelectionListVariable,
  SharedConditionValueProps,
  SimpleFormTreeItem,
  StringOperation,
  StringVariable,
  VariableSelectionTypes,
  isMultiGranularitySelectionListVariable,
} from './conditions.model';
import {
  NumericConditionValue,
  NumericConditionValueProps,
} from './numeric-condition-value';
import StringConditionValue from './string-condition-value';
import SelectionListValueSelect from './selection-list-value-select';
import {
  EMPTY_VALUE_MESSAGE,
  getVariableSelectionType,
} from './dataset-conditions-form-helpers';
import { SelectionListValueSelectNested } from './selection-list-value-select-nested';

interface ConditionValueProps<FormInputs extends FieldValues> {
  conditionVariable:
    | NumericVariable
    | SelectionListVariable
    | StringVariable
    | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueError: any;
  numericValueProps: Omit<
    NumericConditionValueProps<FormInputs>,
    'selectedConditionOperation' | 'firstValuePath'
  >;
  stringValueProps: SharedConditionValueProps<string | SimpleFormTreeItem>;
  selectedConditionOperation:
    | NumericOperation
    | SelectionListOperation
    | StringOperation
    | null
    | undefined;
  formProps: UseFormReturn<FormInputs>;
  firstValuePath: Path<FormInputs>;
}
export function ConditionValue<FormInputs extends FieldValues>({
  conditionVariable,
  valueError,
  numericValueProps,
  stringValueProps,
  selectedConditionOperation,
  formProps,
  firstValuePath,
}: ConditionValueProps<FormInputs>) {
  const conditionVariableType = getVariableSelectionType(conditionVariable);

  if (!conditionVariableType) {
    return (
      <Input
        background="white"
        ml="8px"
        fontSize="xs"
        flexGrow="1"
        isDisabled={true}
        placeholder="Value"
      />
    );
  }

  if (conditionVariableType === VariableSelectionTypes.Numeric) {
    return (
      <FormControl isInvalid={!!valueError}>
        <NumericConditionValue
          {...numericValueProps}
          {...formProps}
          firstValuePath={firstValuePath}
          selectedConditionOperation={
            selectedConditionOperation as NumericOperation
          }
        />

        <FormErrorMessage ml="2">
          {(valueError as FieldError[])?.[0]?.message}
        </FormErrorMessage>
        <FormErrorMessage ml="2">
          {
            (valueError as FieldError[])?.[1]
              ?.message /* this is only for numeric IN_BETWEEN operator with min+max inputs */
          }
        </FormErrorMessage>
      </FormControl>
    );
  }

  if (conditionVariableType === VariableSelectionTypes.String) {
    return (
      <FormControl isInvalid={!!valueError} data-testid="string_condition">
        {selectedConditionOperation === StringOperation.EQUAL ||
        selectedConditionOperation === StringOperation.DOES_NOT_EQUAL ? (
          <Input
            background="white"
            ml="8px"
            fontSize="xs"
            flexGrow="1"
            {...formProps.register(firstValuePath, {
              required: EMPTY_VALUE_MESSAGE,
            })}
            placeholder="Value"
          />
        ) : (
          <StringConditionValue
            {...(stringValueProps as SharedConditionValueProps<string>)}
          />
        )}

        <FormErrorMessage ml="2">
          {(valueError as FieldError)?.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <FormControl isInvalid={!!valueError}>
      {conditionVariableType === VariableSelectionTypes.SelectionList &&
        (conditionVariable ===
        MultiGranularitySelectionListVariables.GEOGRAPHY ? (
          <SelectionListValueSelectNested
            selectionList={
              SelectionListVariableToSelectionCategory[
                conditionVariable as SelectionListVariable
              ]
            }
            isMultiGranularitySelectionListVariable
            {...(stringValueProps as SharedConditionValueProps<SimpleFormTreeItem>)}
          />
        ) : (
          <SelectionListValueSelect
            selectionList={
              SelectionListVariableToSelectionCategory[
                conditionVariable as SelectionListVariable
              ]
            }
            isMultiGranularitySelectionListVariable={isMultiGranularitySelectionListVariable(
              conditionVariable
            )}
            {...(stringValueProps as SharedConditionValueProps<SimpleFormTreeItem>)}
          />
        ))}

      <FormErrorMessage ml="2">
        {(valueError as FieldError)?.message}
      </FormErrorMessage>
    </FormControl>
  );
}

export const MultiGranularitySelectionListVariablesToSelectionCategories = {
  [MultiGranularitySelectionListVariables.GEOGRAPHY]: [
    SelectionCategories.REGION,
    SelectionCategories.COUNTRY,
    SelectionCategories.STATE,
    SelectionCategories.METRO_AREA,
  ],
  [MultiGranularitySelectionListVariables.ROLE]: [
    SelectionCategories.JOB_CATEGORY,
    SelectionCategories.ROLE_K50,
    SelectionCategories.ROLE_K150,
    SelectionCategories.ROLE_K300,
    SelectionCategories.ROLE_K500,
    SelectionCategories.ROLE_K1000,
    SelectionCategories.ROLE_K1250,
    SelectionCategories.ROLE_K1500,
  ],
  [MultiGranularitySelectionListVariables.SKILL]: [
    SelectionCategories.SKILL_K25,
    SelectionCategories.SKILL_K50,
    SelectionCategories.SKILL_K75,
    SelectionCategories.SKILL_K700,
    SelectionCategories.SKILL_K2500,
    SelectionCategories.SKILL_MAPPED,
  ],
};

export const SelectionListVariableToSelectionCategory = {
  ...MultiGranularitySelectionListVariablesToSelectionCategories,
  [CustomColumnConditionVariable.HighestDegree]: [
    SelectionCategories.HIGHEST_DEGREE,
  ],
  [CustomColumnConditionVariable.Ethnicity]: [SelectionCategories.ETHNICITY],
  [CustomColumnConditionVariable.SkillMapped]: [
    SelectionCategories.SKILL_MAPPED,
  ],
  [CustomColumnConditionVariable.Gender]: [SelectionCategories.GENDER],
  [CustomColumnConditionVariable.Seniority]: [SelectionCategories.SENIORITY],
};
