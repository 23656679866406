import { Box, Checkbox, CheckboxGroup, Text } from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import { SelectOption } from '../../types';
import styles from './check-list.module.css';

/* eslint-disable-next-line */
export interface CheckListProps {
  value: StringOrNumber[];
  setValue: React.Dispatch<StringOrNumber[]>;
  selectOptions: SelectOption[];
}

export function CheckList({ value, setValue, selectOptions }: CheckListProps) {
  return (
    <CheckboxGroup
      colorScheme="green"
      value={value}
      onChange={(state) => {
        setValue(state);
      }}
    >
      <Box>
        {selectOptions?.map((option: SelectOption, i: number) => {
          let listItem;

          if (option?.id && option?.label) {
            listItem = (
              <Box key={`${option?.id}-${i}`} className={styles['highlight']}>
                <Checkbox
                  className={styles['checkbox']}
                  sx={{
                    [`.${styles['highlight']}:hover &`]: {
                      borderColor: 'gray.300',
                    },
                  }}
                  value={option.id}
                >
                  <Text className={styles['itemText']}>{option.label}</Text>
                </Checkbox>
              </Box>
            );
          }

          return listItem;
        })}
      </Box>
    </CheckboxGroup>
  );
}

export default CheckList;
