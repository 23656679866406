import { BehaviorSubject } from 'rxjs';
import { findKey, get, isUndefined } from 'lodash';

export type PlotColourLookup = Record<
  string,
  {
    color: string;
    columnName: string;
    id: number;
    shortName: string;
    longName: string;
  }
>;

export const CompensationResponseLevelOverrides = {
  msa: 'metro_area',
};

export const plotColorLookup = new BehaviorSubject<PlotColourLookup>({});

export const getPlotColorById = (id: string | number, columnName: string) => {
  const foundItem = Object.values(plotColorLookup.value).find(
    (entry) => entry.id == id && entry.columnName == columnName
  );

  return foundItem?.color;
};

export const getPlotColorByKey = (key: string) => {
  const foundItem = plotColorLookup.value?.[key];

  return foundItem?.color;
};

export const updatePlotColorLookup = (entry: PlotColourLookup) => {
  plotColorLookup.next(entry);
};

export const getColorKeyByIdAndName = (
  id: number | string,
  shortName: string,
  longName: string
) => {
  const [selectionList, numericalId] = id?.toString().split('__') || [];

  const builtKey = `${selectionList}__${numericalId}`;

  const directMatch = get(plotColorLookup.getValue(), builtKey);

  if (directMatch) {
    return builtKey;
  }

  const colorLookup = plotColorLookup.getValue();

  const colorKey = findKey(colorLookup, (item) => {
    const {
      id: itemId,
      columnName,
      shortName: itemShortName,
      longName: itemLongName,
    } = item;

    // TODO: can remove this once python_company workaround is removed
    // using magic strings here because importing the enum/variables causes circular dependency
    const columnNameForId =
      columnName === 'python_company' ? 'company' : columnName;

    const comparisonId = `${columnNameForId}__${itemId}`;

    const idMatch =
      id === comparisonId || id == itemId || numericalId === itemId?.toString();

    //catches `London (United Kingdom)` when searching for `London`
    const labelMatch =
      itemLongName?.includes(longName) || itemShortName?.includes(shortName);

    return idMatch && labelMatch;
  });

  return colorKey;
};

type KeyValuePair = { [key: string]: string | number };

interface getColorKeyFromLookupProps {
  metadata: KeyValuePair & { shortName: string; longName: string };
  d: KeyValuePair;
  colorLookupKeyPath: string;
}

export const getColorKeyFromLookup = ({
  metadata,
  d,
  colorLookupKeyPath,
}: getColorKeyFromLookupProps) => {
  const { type } = metadata;

  if (isUndefined(type)) {
    const { id, shortName, longName } = metadata;

    if (isUndefined(id)) {
      return getColorKeyByIdAndName(get(d, 'id', ''), shortName, longName);
    }

    const colorKey = getColorKeyByIdAndName(id, shortName, longName);

    return colorKey;
  }

  return `${type.toString().toLowerCase()}__${get(d, colorLookupKeyPath)}`;
};
