import { useContext } from 'react';
import { RoleStatsProps } from '../types';
import { Text } from '@chakra-ui/react';
import { formatIntegerToDecimal } from '@revelio/core';
import {
  calculateStatValues,
  PostingsStatsMetric,
  Stat,
} from '../../../../shared/stat';
import { DailyLineChart, DailyLineChartProps } from '@revelio/d3';
import { ChartsRenderedContext } from '../../../../shared/charts-rendered-context';

interface PostingsChartProps {
  postingsActiveData: RoleStatsProps['postingsActiveData'];
  selectedRoleId: RoleStatsProps['selectedRoleId'];
  roleName: string | null | undefined;
}
export const PostingsChart = ({
  postingsActiveData,
  selectedRoleId,
  roleName,
}: PostingsChartProps) => {
  const primaryPostingData = postingsActiveData?.posting?.find(
    (datum) => datum?.metadata?.id === selectedRoleId
  );

  const primaryRoleCategories = primaryPostingData?.category || [];

  const postingsTimeseries: DailyLineChartProps['timeSeries'] =
    primaryRoleCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.active,
      date: datum?.metadata?.shortName,
    })) || [];

  const { lastValue, percentageChange } =
    calculateStatValues(postingsTimeseries);

  const primaryValue = formatIntegerToDecimal(lastValue);

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  return (
    <Stat
      name={PostingsStatsMetric.ActivePostings}
      plotInfo={<Text color="white">The number of active job postings.</Text>}
      header="Active Postings"
      statValues={{
        primaryValue,
        percentChange: percentageChange,
        containerStyles: {
          justifyContent: 'center',
        },
      }}
    >
      <DailyLineChart
        setChartHasRendered={setChartHasRendered}
        name={PostingsStatsMetric.ActivePostings}
        shortName={roleName}
        timeSeries={postingsTimeseries}
        type="Role"
      />
    </Stat>
  );
};
