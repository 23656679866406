import { SearchIcon } from '@chakra-ui/icons';
import { Box, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { debounce } from 'lodash';
import React, { MutableRefObject, useEffect } from 'react';

interface SearchProps<DataSource> {
  gridRef: MutableRefObject<TypeComputedProps | null> | undefined;
  tableData: DataSource[];
  searchText: string;
  setSearchText: React.Dispatch<string>;
  setDataSource: React.Dispatch<React.SetStateAction<DataSource[]>>;
}

export const Search = <T,>({
  gridRef,
  tableData,
  searchText,
  setSearchText,
  setDataSource,
}: SearchProps<T>) => {
  useEffect(() => {
    setDataSource(tableData);
  }, [tableData, setDataSource]);

  const filterSearch = debounce((value) => {
    const visibleColumns = gridRef?.current?.visibleColumns;
    const lowerSearchText = value && value.toLowerCase();
    const newData = tableData.filter((p) => {
      return visibleColumns?.reduce((acc, col) => {
        const v = (p[col.id as keyof typeof p] + '').toLowerCase();
        return acc || v.indexOf(lowerSearchText) != -1;
      }, false);
    });
    setDataSource(newData);
  }, 250);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    filterSearch(value);
    setSearchText(value);
  };

  return (
    <Box>
      <InputGroup size="sm">
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          type="text"
          placeholder="Search..."
          width="192px"
          size="sm"
          value={searchText}
          onChange={onSearchChange}
        />
      </InputGroup>
    </Box>
  );
};

export default Search;
