export async function fetchAccountPortalbackend(
  input: string,
  init: RequestInit = {}
) {
  const baseUrl = new URL(input).origin;

  const surf = await fetch(`${baseUrl}/surf`, { credentials: 'include' }).then(
    (r) => r.json()
  );

  const _init: RequestInit = {
    ...{
      credentials: 'include',
      headers: {
        'x-surf-token': surf?.csrfToken,
        'Content-Type': 'application/json',
      },
    },
    ...init,
  };

  return fetch(input, _init);
}
