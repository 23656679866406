import { Center, CenterProps, Spinner } from '@chakra-ui/react';
import { useMemo } from 'react';

export const Loading = ({
  size = 'md',
  transparentBackground = false,
  reverse = false,
}) => {
  return (
    <img
      data-testid="loading"
      className=""
      style={{
        display: 'block',
        margin: 'auto',
        width: `${
          /* eslint-disable-next-line no-nested-ternary */
          size === 'sm' ? 85 : size === 'lg' ? 140 : 110
        }px`,
        height: 'auto',
        mixBlendMode: 'multiply',
      }}
      src={`images/rl-bar-loading-r${reverse ? '-d' : ''}${
        transparentBackground ? '-t' : ''
      }.gif`}
      alt="logo"
    />
  );
};

interface ICenterLoading extends CenterProps {
  className?: string;
  size?: string;
  shouldShow?: boolean;
  testId?: string;
}

export const CenterLoading = ({
  className,
  size,
  shouldShow = true,
  testId = 'data-loading',
  ...chakraProps
}: ICenterLoading) => {
  const display = useMemo(() => (shouldShow ? 'flex' : 'none'), [shouldShow]);

  return (
    <Center
      data-testid={testId}
      className={className}
      height="100%"
      width="100%"
      display={display}
      {...chakraProps}
    >
      <Loading size={size} />
    </Center>
  );
};

export const CenterSpinner = () => (
  <Center height={'100%'} width={'100%'}>
    <Spinner color="navyBlue.500" />
  </Center>
);

export default Loading;
