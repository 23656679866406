import { gql } from 'urql';

export const GET_RESUME = gql`
  query GetResume($id: String!) {
    getResume(id: $id) {
      resume_id
      name
      download_link
      enriched_data
    }
  }
`;

export const SAVE_RESUME = gql`
  mutation SaveResume($input: ResumeRequest!) {
    saveResume(input: $input) {
      resume_id
      name
      download_link
      excel_location
    }
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteResume($id: String!) {
    deleteResume(id: $id)
  }
`;
