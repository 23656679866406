import { Flex, Grid, Heading } from '@chakra-ui/react';
import { getPipelineType } from '../deliverables.repository';
import { getPipelineTypeTitleById } from '../data-set.model';
import { DataSetColumns } from './data-set-columns';
import { PipelineConfigurationToolbar } from './saved-pipeline-configurations/pipeline-configuration-toolbar';
import { Deliverable } from '../deliverables.model';

const EditDatasetColumns = ({
  isRevelioAdmin,
  selectedDatasetId,
  setSelectedDatasetId,
}: {
  isRevelioAdmin: boolean;
  selectedDatasetId: Deliverable['id'] | null;
  setSelectedDatasetId: React.Dispatch<
    React.SetStateAction<Deliverable['id'] | null>
  >;
}) => {
  const pipelineType =
    selectedDatasetId !== null && // !== null because it could be 0
    getPipelineType({ entityId: selectedDatasetId });
  const pipelineTitle = pipelineType && getPipelineTypeTitleById(pipelineType);
  const handleEditDataset = () => setSelectedDatasetId(null);

  if (selectedDatasetId == null) {
    return null;
  }

  return (
    <Grid
      padding="18px 30px 20px"
      borderTop="1px"
      borderTopColor="gray.200"
      overflowY="scroll"
      display="flex"
      flexDirection="column"
    >
      <Flex
        justifyContent="space-between"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
        mb="20px"
        alignItems="center"
        pb="15px"
      >
        <Heading as="h3" fontWeight="400" size="md" color="navyBlue.500">
          Editing {pipelineTitle}
        </Heading>
        <PipelineConfigurationToolbar
          entityId={selectedDatasetId}
          isRevelioAdmin={isRevelioAdmin}
          pipelineType={getPipelineType({ entityId: selectedDatasetId })}
          isDatasetSelected
          onEditDataset={handleEditDataset}
        />
      </Flex>
      <DataSetColumns entityId={selectedDatasetId} />
    </Grid>
  );
};

export default EditDatasetColumns;
