import {
  LocationVersion,
  PipelineColumnType,
  PipelineType,
} from '@revelio/data-access';
import {
  ColumnSet,
  ICardListSelectProps,
  isColumnCardListSubmMenu,
} from '@revelio/layout';
import { WORKFORCE_DYNAMICS_COLUMNS } from './configurations/workforce-dynamics';
import { SKILL_DYNAMICS_COLUMNS } from './configurations/skill-dynamics';
import { TRANSITION_COLUMNS } from './configurations/transitions';
import { INDIVIDUAL_USER_COLUMNS } from './configurations/individual-users';
import { POSTING_DYNAMICS_COLUMNS } from './configurations/posting-dynamics';
import { POSTING_INDIVIDUAL_COLUMNS } from './configurations/posting-individual';
import { REVIEW_TRENDS_COLUMNS } from './configurations/review-trends';
import { INDIVIDUAL_REVIEWS_COLUMNS } from './configurations/individual-reviews';
import { SENTIMENT_SCORES_COLUMNS } from './configurations/sentiment-scores';
import { LAYOFFS_COLUMNS } from './configurations/layoffs';
import { xor } from 'lodash';
import {
  deliverablesStore,
  getInputRefs,
  getCompatibleColumns,
  getPipelineType,
  hasCompanySelection,
} from '../deliverables.repository';
import { getPipelineCombinedCompanyColumns } from './company-column';
import { getEntity } from '@ngneat/elf-entities';
import { Deliverable, isSkillDynamPipeline } from '../deliverables.model';
import { getAuthStoreUser, isRoleRevelioAdmin } from '@revelio/auth';
import { COMPANY_INFO_COLUMNS } from './configurations/company-info';

export const INDUSTRY_SUPPORTED_DATA_SETS = [
  PipelineType.WfDynam,
  PipelineType.Posting,
  PipelineType.SkillDynam,
] as const;

export function isIndustrySupportedDataset(
  pipelineType: PipelineType
): pipelineType is typeof INDUSTRY_SUPPORTED_DATA_SETS[number] {
  return INDUSTRY_SUPPORTED_DATA_SETS.includes(
    pipelineType as
      | PipelineType.WfDynam
      | PipelineType.Posting
      | PipelineType.SkillDynam
  );
}

export type IDataSetColumnsMap = {
  [key in PipelineType]: ColumnSet<PipelineColumnType>[];
};

export const dataSetColumnsMap: IDataSetColumnsMap = {
  [PipelineType.WfDynam]: WORKFORCE_DYNAMICS_COLUMNS,
  [PipelineType.SkillDynam]: SKILL_DYNAMICS_COLUMNS,
  [PipelineType.Transition]: TRANSITION_COLUMNS,
  [PipelineType.Individual]: INDIVIDUAL_USER_COLUMNS,
  [PipelineType.Posting]: POSTING_DYNAMICS_COLUMNS,
  [PipelineType.PostingsIndividual]: POSTING_INDIVIDUAL_COLUMNS,
  [PipelineType.ReviewTrends]: REVIEW_TRENDS_COLUMNS,
  [PipelineType.IndividualReviews]: INDIVIDUAL_REVIEWS_COLUMNS,
  [PipelineType.SentimentScores]: SENTIMENT_SCORES_COLUMNS,
  [PipelineType.Layoffs]: LAYOFFS_COLUMNS,
  [PipelineType.CompanyInfo]: COMPANY_INFO_COLUMNS,
};

export const getFlattenedOrderedColumnItems = (
  datasetColumns: ColumnSet<PipelineColumnType>[]
) => {
  return datasetColumns.reduce((acc, curr) => {
    const flattenedColumnSubMenus = flattenColumnSet(curr);

    return [...acc, ...flattenedColumnSubMenus];
  }, [] as ICardListSelectProps<PipelineColumnType>[]);
};

export function flattenColumnSet<T>(columnSet: ColumnSet<T>) {
  return columnSet.columns.reduce((flattenedColumns, columnItem) => {
    if (isColumnCardListSubmMenu(columnItem)) {
      return [...flattenedColumns, ...columnItem.menuItems];
    }
    return [...flattenedColumns, columnItem];
  }, [] as ICardListSelectProps<T>[]);
}

export const getDatasetColumnsList = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const pipelineType = getPipelineType({ entityId });
  const columns = dataSetColumnsMap[pipelineType as PipelineType];
  const isRevelioAdmin = isRoleRevelioAdmin(getAuthStoreUser()?.role);
  if (
    !isRevelioAdmin ||
    ![PipelineType.WfDynam, PipelineType.SkillDynam].includes(pipelineType)
  ) {
    return columns;
  }

  return columns.map((columnSet) => ({
    ...columnSet,
    columns: columnSet.columns.map((c) => {
      if (c.id === PipelineColumnType.Count) {
        return {
          ...c,
          isMandatory: false,
        };
      }

      return c;
    }),
  }));
};

export const getDatasetStandardDefault = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const pipelineType = getPipelineType({ entityId });
  const isNoCompanyPostingDeliverable =
    !hasCompanySelection({ entityId }) &&
    [PipelineType.Posting, PipelineType.SkillDynam].includes(pipelineType);
  if (isNoCompanyPostingDeliverable) {
    return getStandardNoCompanyDatasetColumnDefaults({ entityId });
  }

  return getDatasetColumnDefaults({ entityId });
};

export const getDatasetColumnDefaults = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const columnList = getDatasetColumnsList({ entityId });
  const modelVersions = deliverablesStore.query(
    getEntity(entityId)
  )?.model_versions;
  const versionCompatibleColumns = getFlattenedOrderedColumnItems(
    getCompatibleColumns({
      entityId,
      modelVersions,
    })
  );

  return getFlattenedOrderedColumnItems(columnList)
    .filter(
      (column) =>
        (column.defaultIsSelected || column.isMandatory) &&
        versionCompatibleColumns.map((col) => col.id).includes(column.id)
    )
    .reduce(addCombineWithColumns, [] as PipelineColumnType[]);
};

export const getStandardNoCompanyDatasetColumnDefaults = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const pipelineType = getPipelineType({ entityId });
  const NoCompanySkillDynamColumnsToRemoveFromDefaults = [
    PipelineColumnType.JobCategory,
    PipelineColumnType.RoleK50,
    PipelineColumnType.RoleK150,
    PipelineColumnType.Seniority,
  ];
  return [
    PipelineColumnType.Naics2d,
    ...getDatasetColumnDefaults({ entityId }).filter((colId) =>
      isSkillDynamPipeline(pipelineType)
        ? !NoCompanySkillDynamColumnsToRemoveFromDefaults.includes(colId) &&
          !getPipelineCombinedCompanyColumns(pipelineType).includes(colId)
        : !getPipelineCombinedCompanyColumns(pipelineType).includes(colId)
    ),
  ];
};

export const areSelectedColumnsDefault = ({
  entityId,
  selectedPipelineColumns,
}: {
  entityId: Deliverable['id'];
  selectedPipelineColumns: PipelineColumnType[];
}) => {
  return areSelectedColumnsIdenticalToColumnConfiguration({
    entityId,
    selectedPipelineColumns,
    pipelineColumnsToCompare: getDatasetStandardDefault({
      entityId,
    }),
  });
};

export const areSelectedColumnsIdenticalToColumnConfiguration = ({
  entityId,
  selectedPipelineColumns,
  pipelineColumnsToCompare,
}: {
  entityId: Deliverable['id'];
  selectedPipelineColumns: PipelineColumnType[];
  pipelineColumnsToCompare: PipelineColumnType[];
}) => {
  const differenceFromColumnConfiguration = xor(
    selectedPipelineColumns,
    pipelineColumnsToCompare
  );

  const companyDefaultColumns = getPipelineCombinedCompanyColumns(
    getPipelineType({ entityId }) as PipelineType
  ); // exceptional column because it's set with data other than just a selection
  return (
    !differenceFromColumnConfiguration.length ||
    (differenceFromColumnConfiguration.length ===
      companyDefaultColumns.length &&
      !xor(differenceFromColumnConfiguration, companyDefaultColumns).length)
  );
};

export const isMetroAreaColumnSupported = (
  locationVersion: LocationVersion
) => {
  return locationVersion === LocationVersion.V3;
};

export const hasSelectedCompany = () => {
  const inputRefs = deliverablesStore.query(getInputRefs);

  return hasCompanyInformation(inputRefs);
};

export const hasCompanyInformation = ({
  company_reference,
  pipeline_input,
  company_sets,
}: {
  company_reference?: string;
  pipeline_input?: string;
  company_sets?: string[];
}) => {
  return !!(pipeline_input || company_reference || !!company_sets?.length);
};

export const addCombineWithColumns = (
  combinedColumns: PipelineColumnType[],
  column: ICardListSelectProps<PipelineColumnType>
) => [
  ...combinedColumns,
  column.id,
  ...(column?.combineWith || []), // include combination columns in defaults
];
