import { CombinedError } from 'urql';

export const NETWORK_ABORTED = '[Network] The user aborted a request.';
export const NO_MANIFEST =
  '[GraphQL] sending request: status code not valid: 404';
export const FORBIDDEN = '[Network] Forbidden';
export const USER_NOT_AUTHORIZED =
  '[GraphQL] user not authorized for this operation';
export const USER_IS_NOT_AUTHORIZED =
  '[GraphQL] user is not authorized for this operation';
export const DUPLICATE_USER_ADD =
  '[GraphQL] rpc error: code = AlreadyExists desc = ';
export const ENTITY_NOT_FOUND = 'entity not found';
export const DELIVERABLES_NAME_NOT_UNIQUE =
  'error storing deliverable: error storing delivery: deliverable name should be unique';
export const DELIVERABLES_REPORT_EXISTS =
  '[GraphQL] error storing deliverable: error storing delivery: report exists';
export const SEAT_LIMIT_REACHED = 'seat limit has been reached';

export const nonRetriableErrors = [
  NETWORK_ABORTED,
  FORBIDDEN,
  USER_NOT_AUTHORIZED,
  USER_IS_NOT_AUTHORIZED,
  NO_MANIFEST,
  DUPLICATE_USER_ADD,
  ENTITY_NOT_FOUND,
  DELIVERABLES_NAME_NOT_UNIQUE,
  SEAT_LIMIT_REACHED,
  DELIVERABLES_REPORT_EXISTS,
];

export const isNonRetriableError = (error: Error) =>
  nonRetriableErrors.some((nonRetriableError) =>
    error.message.includes(nonRetriableError)
  );

export const warningErrors = [
  NO_MANIFEST,
  DUPLICATE_USER_ADD,
  ENTITY_NOT_FOUND,
  DELIVERABLES_NAME_NOT_UNIQUE,
  SEAT_LIMIT_REACHED,
  DELIVERABLES_REPORT_EXISTS,
];

export const isWarningError = (error: CombinedError) => {
  const errorIsAuthError = [401, 403].includes(error.response?.status);
  const errorMessageIsWarning = warningErrors.some((warningError) =>
    error.message.includes(warningError)
  );

  return errorIsAuthError || errorMessageIsWarning;
};
