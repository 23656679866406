import { useEffect, useState } from 'react';
import {
  fetchResultsStats,
  transformCompensationData,
  transformResultsStatsResponse,
  transformTDResponse,
} from '../../game/utils/fetchResultStats';
import { useClient } from 'urql';
import { format as d3Format } from 'd3-format';
import { Player } from '../context/PlayerContext';
import { getUpdatedLeaderboard } from '../utils/getUpdatedLeaderboard';
import { environment } from '../../../environments/environment';

const DB_ENDPOINT = 'https://rl-slots-prod.up.railway.app/leaderboard';

type SpinItem = {
  id: number;
  shortName: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useResultsState = ({ onOpen }: any) => {
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [isPlotLoading, setIsPlotLoading] = useState(false);
  const [results, setResults] = useState({});
  const [kdeData, setKDEData] = useState([]);

  const [currentLeaderboard, setCurrentLeaderboard] = useState([]);

  const [leaderboard, setLeaderboard] = useState([]);

  const [player, setPlayer] = useState<Player>({ playerid: '', email: '' });

  const gqlClient = useClient();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(DB_ENDPOINT, {
          headers: {
            'api-key': environment.SLOTS_API_KEY || '',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch leaderboard data');
        }
        const leaderboardData = await response.json();

        setCurrentLeaderboard(leaderboardData);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, []);

  const handleOpen = ({
    seniority,
    rolek150,
    msa,
    salary,
  }: {
    seniority: SpinItem;
    rolek150: SpinItem;
    msa: SpinItem;
    salary: number;
  }) => {
    onOpen();

    setIsStatsLoading(true);
    setIsPlotLoading(true);

    const seniorityIDs: number[] = [];

    if (seniority.id === 4) {
      seniorityIDs.push(4);
      seniorityIDs.push(5);
    } else if (seniority.id === 6) {
      seniorityIDs.push(6);
      seniorityIDs.push(7);
    } else {
      seniorityIDs.push(seniority.id);
    }

    fetchResultsStats({
      msa: [msa.id],
      seniority: seniorityIDs,
      role: [rolek150.id],
      prevYearSentimentEndDate: '2023-09',
      postingsEndDate: '2024-09-02',
      gqlClient,
    })
      .then((res) => {
        const { otherStats: otherStatsRes, compensation, td: tdStatsRes } = res;

        if (otherStatsRes.error) {
          setResults({});
        }

        if (!otherStatsRes.error) {
          let tdCount = 0;

          const transformedResults = transformResultsStatsResponse(
            otherStatsRes.data
          );

          if (!tdStatsRes?.error) {
            tdCount = transformTDResponse(tdStatsRes?.data, msa.id);
          }

          setResults({
            headcount: tdCount ? d3Format(',')(tdCount) : '--',
            ...transformedResults,
          });
        }

        const transformedKDEResults = transformCompensationData(compensation);

        const newScore = {
          playerid: player.playerid,
          email: player.email,
          salary,
          seniority: seniority.shortName,
          role: rolek150.shortName,
          msa: msa.shortName,
        };

        const { updatedLeaderboard, truncatedLeaderboard } =
          getUpdatedLeaderboard(currentLeaderboard, newScore);

        const storeNewScore = async () => {
          try {
            const response = await fetch(DB_ENDPOINT, {
              method: 'POST',
              headers: {
                'api-key': environment.SLOTS_API_KEY || '',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(newScore),
            });

            if (!response.ok) {
              throw new Error('Failed to store updated leaderboard');
            }
          } catch (error) {
            console.error('Error storing updated leaderboard:', error);
          }
        };

        storeNewScore();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setLeaderboard(truncatedLeaderboard as any);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setCurrentLeaderboard(updatedLeaderboard as any);

        setKDEData(transformedKDEResults);
        setIsStatsLoading(false);
        setIsPlotLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return {
    handleOpen,
    isStatsLoading,
    isPlotLoading,
    kdeData,
    results,
    player,
    setPlayer,
    currentLeaderboard,
    leaderboard,
  };
};
