import { operatorOptions } from '../../engine/filters.constants';
import { OperatorOptionType, OPSelectFilter } from '../../engine/filters.model';

export type TDStateBase = {
  // "free form fields"
  [key: string]: any; // eslint-disable-line
};

export type TDRangeStateType = {
  opValue?: { [key: string]: OPSelectFilter };
  startValue?: { [key: string]: { filterName?: string; value: number } };
  endValue?: { [key: string]: { filterName?: string; value: number } };
};

export type TDStateConfig = TDStateBase & TDRangeStateType;

export const defaultOption: OperatorOptionType = operatorOptions[0];
