/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LocalSelectionCategories,
  SelectionCategories,
} from '@revelio/filtering';
import { ChakraStylesConfig } from 'chakra-react-select';
import { set, transform } from 'lodash';

export const landingSelectionLists: SelectionCategories[] = [
  SelectionCategories.INDUSTRY,
  SelectionCategories.COMPANY,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
];

export const placeholderMap: { [x: string]: string } = {
  [SelectionCategories.COMPANY]: 'Amazon, Tesla, Walmart, etc.',
  [SelectionCategories.GEOGRAPHY]: 'New York City, London, Paris, etc.',
  [SelectionCategories.ROLES]: 'Accountant, Engineer, Recruiter, etc',
};

export const defaultSelectionMap: any = {
  [SelectionCategories.COMPANY]: SelectionCategories.COMPANY,
  [SelectionCategories.GEOGRAPHY]: SelectionCategories.METRO_AREA,
  [SelectionCategories.ROLES]: SelectionCategories.ROLE_K150,
};

export const simplePluralize = (word: string) =>
  word.charAt(word.length - 1) === 'y' ? word.slice(0, -1) + 'ies' : word + 's';

export const chakraStyles: ChakraStylesConfig = {
  container: (provided, state) => ({
    ...provided,
    w: '100%',
    color: 'text.primary',
  }),
  control: (provided, state) => ({
    ...provided,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    h: '100%',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'rgb(236, 249, 254)',
    border: `1px solid rgb(162, 223, 249)`,
    borderRadius: '4px',
    color: '#ffffff',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: 'text.primary',
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: 'text.primary',
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    pl: 4,
    fontSize: 'md',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxH: '200px',
  }),
};

export const filterAndMergeValuesOptimized = (array: any, ids: string[]) => {
  const idsSet = new Set(ids);
  const mergedValues: any = [];

  for (const obj of array) {
    if (idsSet.has(obj.id)) {
      obj.value.forEach((valueItem: any) => {
        mergedValues.push({ ...valueItem, entityName: obj.id });
      });
    }
  }

  return mergedValues;
};

export const entityMap: any = {
  company: [SelectionCategories.INDUSTRY, SelectionCategories.COMPANY],
  geography: [
    SelectionCategories.REGION,
    SelectionCategories.COUNTRY,
    SelectionCategories.METRO_AREA,
  ],
  role: [
    SelectionCategories.JOB_CATEGORY,
    SelectionCategories.ROLE_K150,
    SelectionCategories.ROLE_K1500,
  ],
};

export const filterEntities = (inputValue: string, entityData: any) => {
  return entityData
    .filter(
      (i: any) =>
        i?.shortName?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        i?.ticker?.toLowerCase()?.includes(inputValue?.toLowerCase())
    )
    .slice(0, 25);
};

export const generateDeepLink = (entity: string, urlParams: string) =>
  `/${entity}/compositions?${urlParams}&snapshot_or_over_time=snapshot`;

export const getUrlParams = (data: any) => {
  const obj = transform(
    data,
    (result, d) => {
      set(
        result,
        LocalSelectionCategories.PRIMARY_ENTITIES,
        d.value.map((id: string) => `${d.selectionListId}__${id}__true`)
      );
      return result;
    },
    {}
  );

  return new URLSearchParams(obj).toString();
};
