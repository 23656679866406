import { get, isUndefined, omitBy } from 'lodash';
import { TreeItem } from '../../../engine/filters.model';

/**
 * This function takes in a collection of selected items and
 * the parent item being selected, removes the items in the
 * collection that is a descendant of the parent item and returns
 * the new collection with the descendants omitted.
 *
 * To determine if item is a descendant of the parent item, we need to
 * follow the parent id chain upwards to see if we end up at the
 * selected parent item.
 *
 * @param tempSelection collection of currently selected items
 * @param parentItem the parent item
 * @param lookup reference lookup in order to get next parentId up the chain
 *
 * @returns tempSelection with the descendants of selectedItem omitted.
 */
export const unselectChildrenOnParentSelect = (
  tempSelection: {
    [itemId: string]: TreeItem;
  },
  parentItem: TreeItem,
  lookup: {
    [id: string | number]: TreeItem;
  }
) => {
  return omitBy(tempSelection, (selection) => {
    let parentId = selection.parentId;
    let shouldUnselect = false;

    while (!isUndefined(parentId)) {
      if (parentId === parentItem.id) {
        shouldUnselect = true;
        break;
      }
      parentId = lookup[parentId]?.parentId;
    }

    return !isUndefined(selection.parentId) && shouldUnselect;
  });
};

/**
 * Takes in an item and reference lookup, follows the item's parent
 * id chain and collects all of the ids for items that are ancestors
 * of the input item.
 *
 * @param item the item to get all parent ids for
 * @param lookup reference lookup in order to get next parentId up the chain
 *
 * @returns an array containing the parent ids collected by following the
 * input item's parent id up the chain.
 */
export const getParentIds = (item: TreeItem, lookup: any) => {
  const parentIds = [];

  let parentIdKey: string | undefined = item.parentId;

  while (!isUndefined(parentIdKey)) {
    const parentItem: TreeItem | undefined = lookup[parentIdKey];

    if (!parentItem) {
      break;
    }

    parentIds.push(parentItem.id);

    parentIdKey = get(parentItem, 'parentId', undefined);
  }

  return parentIds;
};
