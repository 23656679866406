import { EndpointSegment, IChartPropsMap } from '@revelio/filtering';

export const snapshotChartProps: IChartPropsMap = {
  [EndpointSegment.OVERALL]: {
    name: 'sentiment-overall',
    heading: 'Overall',
    chartPosition: '',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.OVERALL}`,
  },
  [EndpointSegment.BUSINESS]: {
    name: 'sentiment-business',
    heading: 'Business Outlook',
    chartPosition: '',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.BUSINESS}`,
  },
  [EndpointSegment.CAREER]: {
    name: 'sentiment-career',
    heading: 'Career Opportunities',
    chartPosition: 'right',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.CAREER}`,
  },
  [EndpointSegment.COMPENSATION]: {
    name: 'sentiment-compensation',
    heading: 'Compensation and Benefits',
    chartPosition: '',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.COMPENSATION}`,
  },
  [EndpointSegment.WORKLIFE]: {
    name: 'sentiment-worklife',
    heading: 'Work-Life Balance',
    chartPosition: '',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.WORKLIFE}`,
  },
  [EndpointSegment.LEADERSHIP]: {
    name: 'sentiment-leadership',
    heading: 'Senior Leadership',
    chartPosition: 'right',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.LEADERSHIP}`,
  },
  [EndpointSegment.CULTURE]: {
    name: 'sentiment-culture',
    heading: 'Culture and Values',
    chartPosition: '',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.CULTURE}`,
  },
  [EndpointSegment.DIVERSITY]: {
    name: 'sentiment-diversity',
    heading: 'Diversity and Inclusion',
    chartPosition: '',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.DIVERSITY}`,
  },
  [EndpointSegment.RECOMMEND]: {
    name: 'sentiment-recommend',
    heading: 'Recommend to Friend',
    chartPosition: 'right',
    chartSize: 'medium',
    format: '.2r',
    tooltipFormat: '.3r',
    ttType: 'sentimentRatings',
    singleColor: false,
    metaValue: 'shortName',
    tooltipMetaValue: 'longName',
    chartStyle: `.sentiment-plot-${EndpointSegment.RECOMMEND}`,
  },
};

export const overTimeChartProps: IChartPropsMap = {
  [EndpointSegment.OVERALL]: {
    name: 'sentiment-trends-overall',
    heading: 'Overall',
    chartSize: 'medium', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'reviews', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.BUSINESS]: {
    name: 'sentiment-trends-business',
    heading: 'Business',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.CAREER]: {
    name: 'sentiment-trends-career',
    heading: 'Career Opportunities',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percen:
  },
  [EndpointSegment.COMPENSATION]: {
    name: 'sentiment-trends-compensation',
    heading: 'Compensation and Benefits',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.WORKLIFE]: {
    name: 'sentiment-trends-worklife',
    heading: 'Work-Life Balance',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.LEADERSHIP]: {
    name: 'sentiment-trends-leadership',
    heading: 'Senior Leadership',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.CULTURE]: {
    name: 'sentiment-trends-culture',
    heading: 'Culture and Values',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.DIVERSITY]: {
    name: 'sentiment-trends-diversity',
    heading: 'Diversity and Inclusion',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
  [EndpointSegment.RECOMMEND]: {
    name: 'sentiment-trends-recommend',
    heading: 'Recommend to Friend',
    chartSize: 'large', //"small", "medium" or "large"
    chartStyle: '.sentiment-ratings-page .plot',
    metaValueCompany: 'shortName', //for coloring by company
    yAxisFormat: '',
    dateFormat: 'YM', //"YM" or "YMD"
    ttType: 'percent', //"percent" (%value & count in tooltip), "custom" (add ttCustomString after single value) or "single" (tooltip with single value)
    ttCustomString: 'postings', //for ttType="custom", string to use in tooltip after value
    ttMainFormat: '.2f',
    ttSecondaryFormat: ',', //for editing the count in ttType='percent'
  },
};
