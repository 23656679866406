import { CSSObjectWithLabel } from 'react-select';
import { StylesProps } from 'react-select/dist/declarations/src/styles';

export const defaultStyles: Partial<{
  [K in keyof StylesProps<any, any, any>]: CSSObjectWithLabel;
}> = {
  container: {
    fontWeight: 400,
    fontSize: '14px',
  },
  control: {
    height: '32px',
    minHeight: '32px',
    borderColor: '#E2E8F0',
    borderRadius: '0.125rem',
    boxShadow: 'none',
    ':hover': { borderColor: '#CBD5E0' },
    ':focus-within': { borderColor: '#3dc679' },
  },
  valueContainer: {
    height: '100%',
    padding: '2px 12px',
  },
  input: {
    marginTop: 0,
    marginBottom: 0,
    cursor: 'text',
  },
  indicatorsContainer: {
    height: '100%',
  },
  indicatorSeparator: {
    display: 'none',
  },
  dropdownIndicator: {
    color: 'hsl(0, 0%, 80%)',
    ':hover': { color: 'hsl(0, 0%, 80%)' },
  },
  menu: {
    position: 'relative',
    margin: '10px 0',
    zIndex: 'unset',
    boxShadow: 'none',
  },
  menuList: {
    padding: 0,
    fontSize: '12px',
    scrollbarColor: '#e3e3e3 #fff',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '3px 4px',
    color: '#2D426A',
    ':active': { backgroundColor: '#EDF2F7' },
  },
};
