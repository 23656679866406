/* eslint-disable @typescript-eslint/no-explicit-any */
import { selectAll, scaleOrdinal } from 'd3';
import { colors } from '../../utilities/colors';
import { appendWatermark } from '../../utilities/append-watermark';
import '../../d3-styles.scss';
import { adjustDownloadMargins } from '../../utilities/adjust-download-margins';
import { notifyChartRenderComplete } from '../../utilities/notify-chart-render-complete';
import { removeSVG } from '../../utilities/remove-svg';
import {
  calcMaxRightLabelWidth,
  calcMaxLeftLabelWidth,
  setupSVGAndChart,
  setupYScale,
  setupXScale,
  drawBars,
  drawBarValueLabels,
  setupYAxis,
  drawYLabels,
  createTooltip,
  createTooltipMouseOver,
} from './helpers';
import { appendTitle } from '../../utilities/append-title';
import { diagonalStripePattern4 } from '../patterns/diagonalStripe4';
import { DownloadOptions, PlotConfig } from '../../types/types';
import { BarChartHorizontalData } from './types';
import { globalLoader } from '@revelio/core';

interface BarChartHorizontalPlotConfig
  extends PlotConfig<BarChartHorizontalData[]> {
  preformatter: any;
  format: any;
  customFormatter: any;
  tooltipFormat: any;
  formatCustomString: any;
  tooltipFormatCustomString: any;
  singleColor: any;
  colorIndex: any;
  metaValue: any;
  tooltipMetaValue: any;
  textAboveBar: any;
  tooltipCustomValue: any;
  setChartHasRendered?: (hasRendered: boolean) => void;
}

export const BarChartHorizontalGenerator = (
  plotConfigs: BarChartHorizontalPlotConfig,
  downloadOptions: DownloadOptions
): SVGGElement | null | void => {
  const {
    chartPosition,
    chartSize,
    preformatter,
    format,
    customFormatter,
    tooltipFormat,
    formatCustomString,
    tooltipFormatCustomString,
    singleColor,
    colorIndex,
    metaValue,
    tooltipMetaValue,
    ttType,
    textAboveBar,
    targetRef,
    requestHash,
    tooltipCustomValue,
    isRenderingOrLoading,
    customMargins,
    setChartHasRendered,
  } = plotConfigs;

  let { name, data, height, width } = plotConfigs;

  const {
    title,
    download,
    getSVGNode,
    svgHeight,
    svgWidth,
    containerId,
    padding,
    watermark,
  } = downloadOptions;

  const dims: any = {};

  const watermarkHeight = watermark?.height || 0;

  name = getSVGNode ? name + '-download' : name;
  height = svgHeight || height;
  width = svgWidth || width;
  data = data.map((element) => {
    return { ...element, value: Number(element.value) };
  });

  if (data && (targetRef?.current || containerId) && height && width) {
    const fontFamily = 'Source Sans Pro';

    let fontSize;

    if (chartSize === 'medium' && !textAboveBar) {
      fontSize = 10;
    } else if (chartSize === 'large') {
      // fontSize = width > 1000 ? 14 : width > 800 ? 14 : 12;
      fontSize = 12;
    } else {
      fontSize = data.length > 5 ? 8 : 9;
    }

    // remove old svg
    removeSVG([`.svg-${name}`, `.tooltip-${name}`]);

    // setup margins and inner dims, increase top margin for medium chart
    if (textAboveBar) {
      dims.margin = {
        top: 40,
        left: 5,
        bottom: 5,
        right: 0,
      };
    } else if (chartSize === 'small') {
      dims.margin = { top: 26, left: 62, bottom: 4, right: 0 }; // prev 62 left & 30 right
    } else if (chartSize === 'medium' || chartSize === 'large') {
      dims.margin = {
        top: 30,
        left: 80,
        bottom: 5,
        right: 50,
      };
    } else {
      dims.margin = { top: 5, left: 70, bottom: 5, right: 40 };
    }

    //Override margins
    if (customMargins) {
      dims.margin = { ...dims.margin, ...customMargins };
    }

    // calculate width with text that is a bit bigger to provide some padding
    const fontSizePadding = 2;
    const padGuard = 8;

    dims.margin.right = calcMaxRightLabelWidth(
      formatCustomString,
      preformatter,
      format,
      customFormatter,
      data,
      fontSize,
      fontFamily,
      fontSizePadding,
      padding,
      padGuard
    );

    let maxTextWidth = 0;
    // + 5 to account for axis and tick lines that we remove
    const axisTickLinesOffset = 5;

    if (!textAboveBar) {
      maxTextWidth = calcMaxLeftLabelWidth(
        data,
        fontSize,
        fontFamily,
        fontSizePadding
      );

      if (
        !customMargins &&
        maxTextWidth + axisTickLinesOffset <= dims.margin.left
      ) {
        dims.margin.left = maxTextWidth + axisTickLinesOffset;
      }
    }

    if (download) {
      adjustDownloadMargins(dims, {
        title,
        watermark,
        watermarkHeight,
        padding,
      });
    }
    dims.innerHeight = height - (dims.margin.top + dims.margin.bottom);
    dims.innerWidth = width - (dims.margin.left + dims.margin.right);

    const node = targetRef?.current || containerId;

    const [svg, chart] = setupSVGAndChart(
      node,
      svgWidth || '100%',
      svgHeight || '100%',
      name,
      dims.margin.left,
      dims.margin.top
    );

    const color = scaleOrdinal()
      .domain(
        data.map(
          (d: any) =>
            d.metadata[metaValue as keyof BarChartHorizontalData['metadata']]
        )
      )
      .range(colors);

    const xScale = setupXScale(ttType, data, dims.innerWidth);

    // keep inner padding between bars constant and clamp space occupied by bar & inner padding to a max
    let innerPad = 0.45; //this is a percentage of step

    let maxStep;

    if (textAboveBar) {
      maxStep = 50;
      innerPad = 0.7;
    } else if (chartSize === 'large') {
      maxStep = 90;
    } else if (chartSize === 'medium') {
      maxStep = 36;
    } else {
      maxStep = 15;
    }

    const yScale = setupYScale(
      data,
      metaValue,
      maxStep,
      innerPad,
      dims.innerHeight
    );

    const tooltipHeight = ttType === 'skillsSnapshot' ? 74 : 50;

    const tooltip = createTooltip(node, name, tooltipHeight, chartPosition);
    const mouseOver = createTooltipMouseOver(
      name,
      dims,
      chartPosition,
      {
        xScale,
        yScale,
        metaValue,
      },
      {
        tooltip,
        tooltipFormat,
        tooltipHeight,
        tooltipMetaValue,
        ttType,
        tooltipFormatCustomString,
        tooltipCustomValue,
      }
    );

    const mouseOut = () => {
      tooltip.style('opacity', 0).style('display', 'none');
      selectAll(`.${name}-bar`).style('opacity', 1);
    };

    const hasZeros = data.some((d) => d.value === 0);
    if (hasZeros) {
      const defs = chart.append('defs');
      defs.html(diagonalStripePattern4);
    }

    drawBars(
      chart,
      data,
      name,
      { ttType, xScale, yScale, metaValue },
      { chartSize, singleColor, colorIndex, color },
      { mouseOver, mouseOut }
    );

    drawBarValueLabels(
      chart,
      data,
      { formatCustomString, preformatter, format, customFormatter, fontSize },
      { xScale, yScale, metaValue }
    )
      .attr('cursor', 'pointer')
      .on('mouseover', mouseOver)
      .on('mouseout', mouseOut);

    // axes
    const yAxisLeft = setupYAxis(
      data,
      dims.margin.left,
      { xScale, yScale, metaValue },
      { fontSize, fontFamily, maxTextWidth, textAboveBar }
    );

    drawYLabels(chart, textAboveBar, yScale, yAxisLeft, chartSize, fontSize);

    if (!download) {
      notifyChartRenderComplete(chart, requestHash, () => {
        globalLoader.next(false);
        isRenderingOrLoading?.next(false);
        setChartHasRendered?.(true);
      });
    }

    if (title) {
      appendTitle(chart, title, dims, padding);
    }

    if (watermark) {
      appendWatermark(
        chart,
        watermark,
        dims.innerWidth + dims.margin.right,
        dims.innerHeight + dims.margin.bottom,
        padding
      );
    }

    if (getSVGNode) {
      return svg.node();
    }
  }
};
