import {
  CardListSelectController,
  ColumnSet,
  DeliverableIcons,
} from '@revelio/layout';
import { useIsRevelioAdmin } from '@revelio/auth';
import { useObservable } from 'react-use';
import { combineLatest, map, pluck } from 'rxjs';
import { CompanySelectionTypes } from '@revelio/core';
import { deliverablesStore, selectInputRefs } from '../deliverables.repository';
import { selectCompanyMapping } from './company-mapping/company-mapping.respository';
import { selectSubsidiaryColumnMappings } from './subsidiary-mapping/subsidiary-mapping.repository';
import { onSelectCompanyOption } from './company-selection-actions';

export const companySelectionOptions: ColumnSet<CompanySelectionTypes>[] = [
  {
    heading: null,
    columns: [
      {
        id: CompanySelectionTypes.COMMON_SET,
        label: 'Choose a common set',
        icon: DeliverableIcons.STAR,
        detail: 'Select companies from favorite lists',
      },
      {
        id: CompanySelectionTypes.UPLOAD_COMPANIES,
        label: 'Map companies',
        icon: DeliverableIcons.DISCOVERY,
        detail: 'Map a list of provided companies to our company universe',
      },
      {
        id: CompanySelectionTypes.DATABASE_REF,
        label: 'Choose from a list',
        icon: DeliverableIcons.DATABASE_REF,
        detail: 'Add the companies using company names & other identifiers',
        internalOnly: true,
      },
      {
        id: CompanySelectionTypes.COMPANIES_LIST,
        label: 'Select companies',
        icon: DeliverableIcons.LIST_SELECT,
        detail: 'Add the companies that you want',
      },
    ],
  },
];

export type CompanySelectionOptionsProps = {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
  allDisabled?: boolean;
};

export const CompanySelectionOptions = ({
  setCompanyColumnSelectionModalOpen,
  allDisabled = false,
}: CompanySelectionOptionsProps) => {
  const { isRevelioAdmin } = useIsRevelioAdmin();
  const openCompanySelection = useObservable(
    combineLatest({
      inputRefs: deliverablesStore.pipe(selectInputRefs()),
      companyMapping: deliverablesStore.pipe(selectCompanyMapping()),
      subsidiaryMapping: deliverablesStore.pipe(
        selectSubsidiaryColumnMappings(),
        pluck('mappings', 'primary', 'subsidiaries')
      ),
    }).pipe(
      map(({ inputRefs, companyMapping, subsidiaryMapping }) => {
        if (inputRefs?.company_sets?.length) {
          return CompanySelectionTypes.COMMON_SET;
        }

        if (inputRefs?.company_reference && companyMapping) {
          return CompanySelectionTypes.UPLOAD_COMPANIES;
        }

        if (
          inputRefs?.company_reference &&
          subsidiaryMapping.length &&
          !companyMapping
        ) {
          return CompanySelectionTypes.COMPANIES_LIST;
        }

        if (inputRefs?.company_reference && !subsidiaryMapping.length) {
          return CompanySelectionTypes.DATABASE_REF;
        }

        return CompanySelectionTypes.SELECT_OPTION;
      })
    )
  );

  const modifiedCompanySelectionOptions = allDisabled
    ? [
        {
          ...companySelectionOptions[0],
          columns: companySelectionOptions[0].columns.map((column) => ({
            ...column,
            isDisabled: true,
          })),
        },
      ]
    : companySelectionOptions;

  return (
    <CardListSelectController<CompanySelectionTypes>
      variant="delivery"
      hideCheckbox={true}
      items={
        isRevelioAdmin
          ? modifiedCompanySelectionOptions
          : [
              {
                ...modifiedCompanySelectionOptions[0],
                columns: modifiedCompanySelectionOptions[0].columns.filter(
                  (column) => !column.internalOnly
                ),
              },
            ]
      }
      value={[openCompanySelection as CompanySelectionTypes]}
      gridProps={{
        spacing: 6,
        columns: 2,
        gridAutoFlow: 'row',
      }}
      onChange={(value: CompanySelectionTypes[]) => {
        // if there's no value, it means that the user clicked on the same option as the value (since it's like a checkbox)
        const editCompanySelection = !value.length && openCompanySelection;
        if (editCompanySelection) {
          onSelectCompanyOption({
            companySelectionModalToOpen: openCompanySelection,
            setCompanyColumnSelectionModalOpen,
          });
        }

        const next = value.pop() as CompanySelectionTypes;
        if (next) {
          onSelectCompanyOption({
            companySelectionModalToOpen: next,
            setCompanyColumnSelectionModalOpen,
          });
          return;
        }
      }}
    />
  );
};
