import { graphql } from '@revelio/data-access';

export const GET_SCHOOL_INFO = graphql(
  `
    query GetSchoolInfo($name: String!, $page: Int!) {
      schoolInfo(in: { name: $name, page: $page }) {
        id
        rsid
        name
      }
    }
  `
);
