import { Button, IconButton } from '@chakra-ui/react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { PaginationComponentProps } from 'react-ultimate-pagination';

const Page = (hidePage?: IsPageHiddenFn) => (e: PaginationComponentProps) => {
  if (hidePage && hidePage(e)) {
    return null;
  }

  return (
    <Button
      onClick={e.onClick}
      disabled={e.isDisabled}
      size="sm"
      variant={e.isActive ? 'solid' : 'ghost'}
      colorScheme={e.isActive ? 'green' : 'gray'}
    >
      {e.value}
    </Button>
  );
};

const Ellipsis = (props: PaginationComponentProps) => {
  return (
    <Button
      aria-label="First Page"
      onClick={props.onClick}
      disabled={props.isDisabled}
      size="sm"
      variant="ghost"
      colorScheme="gray"
    >
      ...
    </Button>
  );
};

const FirstPageLink = (props: PaginationComponentProps) => {
  return (
    <IconButton
      aria-label="First Page"
      icon={<FiChevronsLeft />}
      onClick={props.onClick}
      disabled={props.isDisabled}
      size="sm"
      variant="ghost"
      colorScheme="gray"
    >
      First
    </IconButton>
  );
};

const PreviousPageLink = (props: PaginationComponentProps) => {
  return (
    <IconButton
      aria-label="First Page"
      icon={<FiChevronLeft />}
      onClick={props.onClick}
      disabled={props.isDisabled}
      size="sm"
      variant="ghost"
      colorScheme="gray"
      mr={2}
    >
      Previous
    </IconButton>
  );
};

const NextPageLink = (props: PaginationComponentProps) => {
  return (
    <IconButton
      aria-label="First Page"
      icon={<FiChevronRight />}
      onClick={props.onClick}
      disabled={props.isDisabled}
      size="sm"
      variant="ghost"
      colorScheme="gray"
      ml={2}
    >
      Next
    </IconButton>
  );
};

const LastPageLink = (props: PaginationComponentProps) => {
  return (
    <IconButton
      aria-label="First Page"
      icon={<FiChevronsRight />}
      onClick={props.onClick}
      disabled={props.isDisabled}
      size="sm"
      variant="ghost"
      colorScheme="gray"
    >
      Last
    </IconButton>
  );
};

type IsPageHiddenFn = (pageProps: PaginationComponentProps) => boolean;
export type ItemTypeToComponentProps = {
  hidePage?: (selectedPageNumber?: number) => IsPageHiddenFn;
  selectedPageId?: number;
};
export const itemTypeToComponent = (props: ItemTypeToComponentProps = {}) => ({
  PAGE: Page(props.hidePage ? props.hidePage(props.selectedPageId) : undefined),
  ELLIPSIS: Ellipsis,
  FIRST_PAGE_LINK: FirstPageLink,
  PREVIOUS_PAGE_LINK: PreviousPageLink,
  NEXT_PAGE_LINK: NextPageLink,
  LAST_PAGE_LINK: LastPageLink,
});
