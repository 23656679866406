const SERVICE_WORKER_UPDATE_POLL_INTERVAL = 1000 * 60;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

type Config = {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const register = (config?: Config) => {
  const isServiceWorkerSupported = 'serviceWorker' in navigator && !isLocalhost;

  if (!isServiceWorkerSupported) {
    console.log('Service workers are not supported.');
    return;
  }

  const handleRegister = () => {
    navigator.serviceWorker.register('/service-worker.js').then(
      function (registration) {
        const update = () => {
          registration.update();
        };

        const interval = setInterval(
          update,
          SERVICE_WORKER_UPDATE_POLL_INTERVAL
        );

        registration.onupdatefound = (event) => {
          /** Both initial service worker setup, and new service worker updates, were causing
           * notification to show. If a user loads the site for the first time, or they clear
           * cache and reload the page, the app update will trigger, but we only want to trigger
           * if a new service worker is installed. If we see a new service worker within the first
           * 10 seconds of a user session, it is likely to be caused by the initial install, and
           * not a new update available */
          if (event.timeStamp > 10000) {
            clearInterval(interval);

            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          }
        };

        console.log('Service worker registration succeeded:', registration);
      },
      (error) => {
        console.log('Service worker registration failed:', error);
      }
    );
  };

  // window load will not be called if window is already loaded by the time we get here, so we call
  // document.readyState === 'complete' first
  if (document.readyState === 'complete') handleRegister();
  else window.addEventListener('load', handleRegister);
};

export const disableUserSelect = () => {
  const isPWA = () => {
    return window.matchMedia('(display-mode: standalone)').matches;
  };
  if (!isPWA()) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document.querySelectorAll('html,body,#root').forEach((e: any) => {
    e.style['user-select'] = 'none';
  });
};
