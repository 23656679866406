import { Flex, Text } from '@chakra-ui/layout';
import { Checkbox, Tag } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Subsidiary, SubsidiaryMappingKeys } from '@revelio/core';
import React, { useMemo, useState } from 'react';
import { isNull } from 'lodash';

interface MapperItemProps {
  item: Subsidiary;
  onItemClick: (item: Subsidiary, column: number) => void;
  column: number;
  selectedColumns: SubsidiaryMappingKeys[];
  toggleItemSelected: (itemId: string | number) => void;
}

const MapperItem = ({
  item,
  onItemClick,
  column,
  selectedColumns,
  toggleItemSelected,
}: MapperItemProps) => {
  const selectedItem = useMemo(() => {
    return selectedColumns[column + 1] === item.id;
  }, [selectedColumns, item, column]);

  const handleItemClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;

    const checkboxContainer = target.closest('.rl-mapper-item-checkbox');
    const isClickOutsideCheckbox = isNull(checkboxContainer);

    if (isClickOutsideCheckbox) {
      onItemClick(item, column);
    }
  };

  const handleToggleItem = () => toggleItemSelected(item.id);

  const [cursorType, setCursorType] = useState('grab');

  const handleMouseDown = () => {
    setCursorType('grabbing');
  };

  const handleMouseUp = () => {
    setCursorType('grab');
  };

  return (
    <Flex
      flex={1}
      paddingLeft="6px"
      borderRadius="6px"
      alignItems="center"
      justifyContent="space-between"
      onClick={handleItemClick}
      background={selectedItem ? 'gray.100' : 'white'}
      cursor={cursorType === 'grab' ? 'pointer' : cursorType}
      _hover={{ background: 'green.50' }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      column-item={`${selectedColumns[column]}__${item.id || ''}`}
    >
      <Flex
        alignItems="center"
        _before={{
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '12px',
          height: '26px',
          left: '6px',
          backgroundColor: 'transparent',
          cursor: cursorType,
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        column-item={`${selectedColumns[column]}__${item.id || ''}`}
      >
        <Checkbox
          className="rl-mapper-item-checkbox"
          colorScheme="brightGreen"
          marginRight="8px"
          isChecked={item.isSelected}
          isIndeterminate={item.isIndeterminate}
          onChange={handleToggleItem}
          borderColor={selectedItem ? 'gray.300' : 'gray.200'}
          column-item={`${selectedColumns[column]}__${item.id || ''}`}
        />
        <Text
          fontSize="14"
          column-item={`${selectedColumns[column]}__${item.id || ''}`}
          noOfLines={1}
          title={item.title}
        >
          {item.title}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        marginLeft="20px"
        column-item={`${selectedColumns[column]}__${item.id || ''}`}
      >
        <Flex
          justifyContent="center"
          column-item={`${selectedColumns[column]}__${item.id || ''}`}
        >
          <Tag
            size="sm"
            background={selectedItem ? 'white' : 'gray.100'}
            borderRadius="100"
            padding="0 12px"
            marginRight="0"
            variant="subtle"
            column-item={`${selectedColumns[column]}__${item.id || ''}`}
          >
            <Text
              fontSize="10"
              color="navyBlue.500"
              column-item={`${selectedColumns[column]}__${item.id || ''}`}
            >
              {Intl.NumberFormat('en').format(item.headCount)}
            </Text>
          </Tag>
        </Flex>
        <Flex
          width="32px"
          height="32px"
          alignItems="center"
          justifyContent="center"
          column-item={`${selectedColumns[column]}__${item.id || ''}`}
        >
          {item.hasSubsidiaries && (
            <ChevronRightIcon
              color="navyBlue.500"
              width="20px"
              height="20px"
              column-item={`${selectedColumns[column]}__${item.id || ''}`}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MapperItem;
