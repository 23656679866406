import { removeLoadingStatus } from '@revelio/core';
import {
  AnyFilter,
  FilterList,
  SelectFilter,
  SelectionCategories,
  getActiveFiltersState,
  getSingleFilterState,
  setManualLoadingStatus,
} from '@revelio/filtering';
import {
  defer,
  get,
  has,
  isEqual,
  isNull,
  isString,
  isUndefined,
} from 'lodash';
import {
  Observable,
  UnaryFunction,
  map,
  pipe,
  scan,
  withLatestFrom,
} from 'rxjs';
import { faker } from '@faker-js/faker';

export const generateRandomPlaceholder = (columnName: string): string => {
  switch (columnName) {
    case 'last_name':
      return faker.person.lastName();
    case 'email':
      return faker.internet.email();
    case 'personal_email':
      return faker.internet.email();
    case 'phone_number':
      return faker.phone.number();
    case 'linkedin_url':
      return `linkedin.com/in/${faker.internet.userName()}`;
    default:
      return '';
  }
};

export const TALENT_DISICOVERY_DEBUG_MODE = 'talent_discovery_debug_mode_on';

type TDSourceData = {
  columns: string[];
  table_data: Record<string, string>[];
  map_data: Record<string, string>[];
  metadata: {
    total_pages?: number;
  };
  filters: AnyFilter[];
  uuid: string;
};

const dataInitialState: TDSourceData = {
  columns: [],
  table_data: [],
  map_data: [],
  metadata: {},
  filters: [],
  uuid: '',
};
export const continuouslyMergeData = (
  dataType: 'map_data' | 'table_data'
): UnaryFunction<Observable<unknown>, Observable<TDSourceData>> => {
  return pipe(
    withLatestFrom(
      getSingleFilterState<SelectFilter<FilterList>>(
        SelectionCategories.TALENT_PAGE_ID
      ),
      getActiveFiltersState()
    ),
    scan(
      (
        result,
        [newResp, pageFilter, activeFilters]: [
          unknown,
          SelectFilter<FilterList>,
          AnyFilter[],
        ]
      ) => {
        if (!isEqual(activeFilters, result['filters'])) {
          result = dataInitialState;
          result['uuid'] = crypto.randomUUID();
        }
        result['filters'] = activeFilters;
        result.metadata = get(newResp, 'metadata', {});
        const isLoading = has(newResp, 'loading');
        const data = isLoading ? [] : get(newResp, dataType, []);
        const pageNum = get(pageFilter, 'value.id');
        setManualLoadingStatus(pageNum === 0 && isLoading);
        if (dataType == 'table_data') {
          const formattedCols = get(newResp, 'columns', result.columns).map(
            (d: string | object) => {
              const name = isString(d) ? d : get(d, 'name');
              const header = isString(d) ? d : get(d, 'header');

              return {
                name,
                header,
                minWidth: 30,
              };
            }
          );
          result['columns'] = isLoading ? result.columns : formattedCols;
        }
        result[dataType] = data;
        defer(() => {
          removeLoadingStatus(get(newResp, 'requestHash'));
        });
        return result;
      },
      dataInitialState
    )
  );
};

export const additionalOperatorForMap = pipe(
  continuouslyMergeData('map_data'),
  map((source: TDSourceData) => {
    const companyFilters = source.filters.find(
      (fil) => fil.id === SelectionCategories.COMPANY
    );

    const hasCompanyFilters = !isUndefined(companyFilters);

    const dimension = hasCompanyFilters
      ? SelectionCategories.COMPANY
      : SelectionCategories.GEOGRAPHY;

    // TEMP FIX: fixes location string formatting where there is an
    // extra space after the city name. Can remove this once backend fix is in
    // eslint-disable-next-line
    const formattedMapData = source['map_data']?.map((d: any) => {
      if (d.type === 2 && !isNull(d.location)) {
        return {
          ...d,
          location: d.location.replace(' , ', ', '),
        };
      }

      return d;
    });

    return { data: formattedMapData, filters: source.filters, dimension };
  })
);
