import { Deliverable, graphql } from '@revelio/data-access';

export const AllUsersIdAndNameQuery = graphql(`
  query GetAllUsersNameAndId {
    users {
      id
      name
      email
      client_name
    }
  }
`);

export const GetUserDeliverablesQuery = graphql(`
  query GetUserDeliverables($ids: [String!]!) {
    users(ids: $ids) {
      deliverables {
        job_id
        deliverable {
          name
          client
          lag_data
          company_sets
          company_reference
          pipeline_input
          deliverables {
            company_sets
            company_reference
            pipeline_input
            model_versions {
              location_version
              timescaling_version
              ethnicity_version
              job_taxonomy_version
              company_mapper_version
              salary_version
              seniority_version
              weight_table
              remove_bad_users
            }
            pipeline {
              pipeline_type
              columns
              source
              individual_user_configuration {
                current
                full_history
              }
              posting_configuration {
                frequency
                source
              }
              company_info_configuration {
                include_subsidiaries
              }
              custom_columns {
                name
                step {
                  case {
                    conditions {
                      variable
                      value
                      operation
                    }
                    binary
                  }
                  then
                }
              }
              filters {
                name
                conditions {
                  variable
                  value
                  operation
                }
                binary
              }
            }
            s3_delivery {
              s3_bucket
              s3_file_prefix
              s3_format
            }
            snowflake_delivery {
              snowflake_db
              snowflake_schema
              snowflake_file_prefix
            }
          }
        }
        status
        updated_at
        created_at
      }
    }
  }
`);

export const GetUserReportsQuery = graphql(`
  query GetUserReports($ids: [String!]!) {
    users(ids: $ids) {
      reports {
        uuid
        job_id
        job_name
        name
        client
        status
        report {
          reportType
          mainEntities {
            type
            values
          }
          comparisonEntities {
            type
            values
          }
          filters {
            type
            id
            values
          }
        }
        created_at
        updated_at
      }
    }
  }
`);

export const GetClientDeliverablesQuery = graphql(`
  query GetClientDeliverables($name: String!) {
    clientsDeliverable(client_name: $name) {
      job_id
      deliverable {
        name
        client
        lag_data
        company_sets
        company_reference
        pipeline_input
        deliverables {
          company_sets
          company_reference
          pipeline_input
          model_versions {
            location_version
            timescaling_version
            ethnicity_version
            job_taxonomy_version
            company_mapper_version
            salary_version
            seniority_version
            weight_table
            remove_bad_users
          }
          pipeline {
            pipeline_type
            columns
            source
            posting_configuration {
              frequency
              source
            }
            individual_user_configuration {
              current
              full_history
            }
            company_info_configuration {
              include_subsidiaries
            }
            custom_columns {
              name
              step {
                case {
                  conditions {
                    variable
                    value
                    operation
                  }
                  binary
                }
                then
              }
            }
            filters {
              name
              conditions {
                variable
                value
                operation
              }
              binary
            }
          }
          s3_delivery {
            s3_bucket
            s3_file_prefix
            s3_format
          }
          snowflake_delivery {
            snowflake_db
            snowflake_schema
            snowflake_file_prefix
          }
        }
      }
      user {
        email
        name
      }
      status
      updated_at
      created_at
    }
  }
`);

export const getCommonDeliveryFilePrefix = (deliverables: Deliverable[]) => {
  const deliverableWithCommonFileLocation = deliverables.find(
    (del) =>
      deliverables.filter(
        (savedDeliverable) =>
          savedDeliverable?.pipeline.pipeline_type ===
          del?.pipeline.pipeline_type
      ).length === 1
  );

  return (
    deliverableWithCommonFileLocation?.s3_delivery?.s3_file_prefix ||
    deliverableWithCommonFileLocation?.snowflake_delivery?.snowflake_file_prefix
  );
};
