import { NodeApi } from 'react-arborist';
import { SelectionDescendantMap } from '../use-tree-api';
import { TreeData } from '../tree-selection';

const insertDescendantSelections = ({
  selectionDescendantMap,
  ancestors,
}: {
  selectionDescendantMap: SelectionDescendantMap;
  ancestors: string[];
}): SelectionDescendantMap => {
  if (ancestors.length === 0) return selectionDescendantMap;

  const [id, ...rest] = ancestors;
  if (!selectionDescendantMap.has(id)) {
    selectionDescendantMap.set(id, new Map());
  }

  return selectionDescendantMap.set(
    id,
    insertDescendantSelections({
      selectionDescendantMap: selectionDescendantMap.get(
        id
      ) as SelectionDescendantMap,
      ancestors: rest,
    })
  );
};

const removeDescendantSelections = ({
  selectionDescendantMap,
  ancestors,
  selectedIds,
}: {
  selectionDescendantMap: SelectionDescendantMap;
  ancestors: string[];
  selectedIds: string[];
}): SelectionDescendantMap => {
  if (ancestors.length === 1) {
    const noOfChildren = selectionDescendantMap.get(ancestors[0])?.size || 0;
    if (noOfChildren > 0) return selectionDescendantMap;
    else {
      selectionDescendantMap.delete(ancestors[0]);
      return selectionDescendantMap;
    }
  } else {
    const [id, ...rest] = ancestors;
    const newSelectionMap = removeDescendantSelections({
      selectionDescendantMap: selectionDescendantMap.get(
        id
      ) as SelectionDescendantMap,
      ancestors: rest,
      selectedIds,
    });
    if (newSelectionMap.size === 0 && !selectedIds.includes(id)) {
      selectionDescendantMap.delete(id);
      return selectionDescendantMap;
    } else return selectionDescendantMap.set(id, newSelectionMap);
  }
};

type UpdateDescendantSelectionsBaseProps = {
  // operation: 'INSERT' | 'REMOVE';
  node: NodeApi<TreeData>;
  selectionDescendantMap: SelectionDescendantMap;
  // selectedIds: string[];
};

type InsertDescendantSelectionsProps = UpdateDescendantSelectionsBaseProps & {
  operation: 'INSERT';
};

type RemoveDescendantSelectionsProps = UpdateDescendantSelectionsBaseProps & {
  operation: 'REMOVE';
  selectedIds: string[];
};

type UpdateDescendantSelectionsProps =
  | InsertDescendantSelectionsProps
  | RemoveDescendantSelectionsProps;

export const updateDescendantSelections = (
  props: UpdateDescendantSelectionsProps
): string[] => {
  const { operation, node, selectionDescendantMap } = props;
  const ancestors = getAncestors(node);
  switch (operation) {
    case 'INSERT':
      return getIndeterminateParents(
        insertDescendantSelections({ selectionDescendantMap, ancestors })
      );
    case 'REMOVE':
      return getIndeterminateParents(
        removeDescendantSelections({
          selectionDescendantMap,
          ancestors,
          selectedIds: props.selectedIds,
        })
      );
  }
};

const getAncestors = (node: NodeApi<TreeData>): string[] => {
  if (node.parent) return [...getAncestors(node.parent), node.id];
  return [];
};

const getIndeterminateParents = (
  selectionDescendantMap: SelectionDescendantMap
): string[] => {
  const indeterminateParents: string[] = [];
  selectionDescendantMap.forEach((value, key) => {
    if (value.size === 0) return;
    else {
      indeterminateParents.push(key);
      indeterminateParents.push(...getIndeterminateParents(value));
    }
  });
  return indeterminateParents;
};
