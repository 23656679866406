import { Flex, Text } from '@chakra-ui/layout';
import {
  EMPTY_NODE,
  LOADING_NODE,
  NO_RESULTS_NODE,
  PLACEHOLDER_NODE,
} from '../async-tree.constants';

export interface NoDataNodeProps {
  id: string;
}

export function NoDataNode({ id }: NoDataNodeProps) {
  let text = '';

  if (id === EMPTY_NODE) {
    text = '';
  }

  if (id === PLACEHOLDER_NODE) {
    text = 'Start typing to see results';
  }

  if (id === LOADING_NODE) {
    text = 'Loading...';
  }

  if (id === NO_RESULTS_NODE) {
    text = 'No results';
  }

  return (
    <Flex pl={1}>
      <Text fontSize="sm">{text}</Text>
    </Flex>
  );
}
