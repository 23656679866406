export const postingsTopRoleQueryData = {
  posting: [
    {
      metadata: {
        id: 861,
        shortName: 'Workday',
      },
      category: [
        {
          metadata: {
            id: 3,
            shortName: 'Softw Eng',
            longName: 'Software Engineer',
          },
          metrics: {
            count: 8013,
            share: 0.179414267162241,
          },
        },
        {
          metadata: {
            id: 2,
            shortName: 'Sales Assc',
            longName: 'Sales Associate',
          },
          metrics: {
            count: 5759,
            share: 0.128946307823205,
          },
        },
        {
          metadata: {
            id: 50,
            shortName: 'Solutions',
            longName: 'Solutions Specialist',
          },
          metrics: {
            count: 4925,
            share: 0.110272715059782,
          },
        },
        {
          metadata: {
            id: 31,
            shortName: 'Mgr',
            longName: 'Product Manager',
          },
          metrics: {
            count: 3303,
            share: 0.0739554878867941,
          },
        },
        {
          metadata: {
            id: 38,
            shortName: 'IT Proj Mgr',
            longName: 'IT Project Manager',
          },
          metrics: {
            count: 2907,
            share: 0.0650888898840177,
          },
        },
        {
          metadata: {
            id: 32,
            shortName: 'Delivery',
            longName: 'Delivery Manager',
          },
          metrics: {
            count: 2726,
            share: 0.061036227665577,
          },
        },
        {
          metadata: {
            id: 28,
            shortName: 'Data Anlst',
            longName: 'Data Analyst',
          },
          metrics: {
            count: 2282,
            share: 0.0510948905109489,
          },
        },
        {
          metadata: {
            id: 18,
            shortName: 'Project Mgr',
            longName: 'Project Manager',
          },
          metrics: {
            count: 2274,
            share: 0.0509157673189736,
          },
        },
        {
          metadata: {
            id: 13,
            shortName: 'Accountant',
            longName: 'Accountant',
          },
          metrics: {
            count: 1558,
            share: 0.034884241637186,
          },
        },
        {
          metadata: {
            id: 21,
            shortName: 'HR Spec',
            longName: 'Human Resources Specialist',
          },
          metrics: {
            count: 1331,
            share: 0.0298016210648874,
          },
        },
        {
          metadata: {
            id: 36,
            shortName: 'Designer',
            longName: 'Designer',
          },
          metrics: {
            count: 1314,
            share: 0.0294209842819399,
          },
        },
        {
          metadata: {
            id: 25,
            shortName: 'Marketing',
            longName: 'Marketing',
          },
          metrics: {
            count: 1078,
            share: 0.0241368501186691,
          },
        },
        {
          metadata: {
            id: 34,
            shortName: 'Apps Engr',
            longName: 'Application Engineer',
          },
          metrics: {
            count: 765,
            share: 0.0171286552326362,
          },
        },
        {
          metadata: {
            id: 45,
            shortName: 'QA Tester',
            longName: 'QA Tester',
          },
          metrics: {
            count: 650,
            share: 0.0145537593479916,
          },
        },
        {
          metadata: {
            id: 49,
            shortName: 'Business Ops',
            longName: 'Business Operations',
          },
          metrics: {
            count: 587,
            share: 0.0131431642111862,
          },
        },
      ],
    },
  ],
};
