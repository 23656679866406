// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { KDEPlotGenerator } from '../plot-generators/kde/KDEPlotGenerator';

const KDEPlot = (plotConfigs) => {
  const {
    name,
    data,
    height,
    width,
    targetRef,
    requestHash,
    lineColor,
    lineStrokeWidth,
    gradient,
    setChartHasRendered,
  } = plotConfigs;

  const setKDEChartHasRendered = setChartHasRendered
    ? (hasRendered) => setChartHasRendered(name, hasRendered)
    : undefined;

  useD3(
    KDEPlotGenerator,
    { ...plotConfigs, setChartHasRendered: setKDEChartHasRendered },
    [
      targetRef.current,
      height,
      width,
      data,
      requestHash,
      lineColor,
      lineStrokeWidth,
      gradient,
      setKDEChartHasRendered,
    ]
  );
  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
        width: '100%',
      }}
    />
  );
};

KDEPlot.propTypes = {
  name: PropTypes.string.isRequired,
  chartStyle: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

KDEPlot.defaultProps = {
  name: 'default',
  chartStyle: '',
  data: [],
};

export default withResizeDetector(KDEPlot, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
