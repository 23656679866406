import {
  Button,
  Grid,
  GridItem,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import styles from './tos-modal.module.scss';
import ReactMarkdown from 'react-markdown';
import trialAgreement from './tos-text/trialAgreement.md';
import termsOfUse from './tos-text/termsOfUse.md';
import {
  AgreementTypes,
  markdownComponents,
  remarkPlugins,
} from './tos-modal-markdown-config';
import { TermsOfServiceModalControlPanel } from './tos-modal-control-panel/tos-modal-control-panel';
import { Box } from '@chakra-ui/layout';
import { ContentWrapper } from '@revelio/layout';

export interface TermsOfServiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
  onAccept?: () => void;
  isTrialUser?: boolean;
}

export function TermsOfServiceModal({
  isOpen,
  onClose,
  onCloseComplete,
  onAccept,
  isTrialUser = false,
}: TermsOfServiceModalProps) {
  const [activeSelection, setActiveSelection] = useState(
    AgreementTypes.TERMS_OF_USE
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const termsOfUseRef = useRef<HTMLDivElement | null>(null);
  const trialAgreementRef = useRef<HTMLDivElement | null>(null);

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      const active = entry.isIntersecting
        ? AgreementTypes.TRIAL_AGREEMENT
        : AgreementTypes.TERMS_OF_USE;

      setActiveSelection(active);
    });
  };

  useEffect(() => {
    if (!trialAgreementRef.current) {
      return;
    }

    const observer = new IntersectionObserver(intersectionCallback, {});

    observer.observe(trialAgreementRef.current);

    return () => observer.disconnect();
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      onCloseComplete={onCloseComplete}
      closeOnOverlayClick={false}
      isCentered
      size="3xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.7)" mixBlendMode="hard-light" />
      <ModalContent
        data-testid="tos-modal"
        textColor="#2D426A"
        borderRadius="8px"
        h="80%"
        w="90%"
        minH="500px"
        minW="475px"
      >
        <ModalBody height="100%">
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(5, 1fr)"
            height="100%"
          >
            <GridItem colSpan={1} rowSpan={2} borderRight="1px solid #CBD4DE">
              <VStack alignItems="flex-start" w="150px" pt="20px" spacing={3}>
                <Text as="h2" fontSize="md" fontWeight="semibold">
                  Agreements
                </Text>
                <List spacing={3} pl={4}>
                  <ListItem>
                    <Button
                      variant="link"
                      colorScheme="black"
                      fontWeight={
                        activeSelection === AgreementTypes.TERMS_OF_USE
                          ? 'semibold'
                          : 'normal'
                      }
                      onClick={() => {
                        if (termsOfUseRef.current) {
                          termsOfUseRef.current.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      Terms of Use
                    </Button>
                  </ListItem>
                  {isTrialUser && (
                    <ListItem>
                      <Button
                        variant="link"
                        colorScheme="black"
                        fontWeight={
                          activeSelection === AgreementTypes.TRIAL_AGREEMENT
                            ? 'semibold'
                            : 'normal'
                        }
                        onClick={() => {
                          if (trialAgreementRef.current) {
                            trialAgreementRef.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      >
                        User Trial
                      </Button>
                    </ListItem>
                  )}
                </List>
              </VStack>
            </GridItem>
            <GridItem colSpan={4} rowSpan={2}>
              <VStack h="100%">
                <Box
                  h="100%"
                  mt="20px"
                  pl="20px"
                  pr="10px"
                  className={styles.textScrollBox}
                  ref={containerRef}
                >
                  <ContentWrapper
                    themeType={'terms-of-service'}
                    ref={termsOfUseRef}
                  >
                    <ReactMarkdown
                      components={markdownComponents}
                      remarkPlugins={remarkPlugins}
                      includeElementIndex
                    >
                      {termsOfUse}
                    </ReactMarkdown>
                  </ContentWrapper>
                  <Spacer h={2} />
                  {isTrialUser && (
                    <ContentWrapper
                      themeType={'terms-of-service'}
                      ref={trialAgreementRef}
                    >
                      <ReactMarkdown
                        components={markdownComponents}
                        remarkPlugins={remarkPlugins}
                      >
                        {trialAgreement}
                      </ReactMarkdown>
                    </ContentWrapper>
                  )}
                </Box>
                <TermsOfServiceModalControlPanel onAccept={onAccept} />
              </VStack>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default TermsOfServiceModal;
