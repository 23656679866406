import { authStore, useUserTrialDetails } from '@revelio/auth';
import { useObservable } from '@ngneat/react-rxjs';
import { select } from '@ngneat/elf';

const useIsTrialUserWithNoDatasetAccess = () => {
  const { isTrialUser } = useUserTrialDetails();
  const [dataSetsWithAccess] = useObservable(
    authStore.pipe(select((state) => state.dataSets))
  );

  const isTrialUserWithNoDatasetAccess =
    isTrialUser && !dataSetsWithAccess?.length;

  return isTrialUserWithNoDatasetAccess;
};

export default useIsTrialUserWithNoDatasetAccess;
