import { createElement, useEffect } from 'react';
import { Flex, VStack, Text, Input } from '@chakra-ui/react';
import {
  assign,
  get,
  isPlainObject,
  isUndefined,
  pick,
  reduce,
  set,
} from 'lodash';
import {
  FilterOrSubfilterName,
  OPSelectFilter,
  OPValues,
  TalentDiscoveryValue,
} from '../../../engine/filters.model';
import { Select } from 'chakra-react-select';
import NumberFormat from 'react-number-format';
import {
  TDStateConfig,
  TDRangeStateType,
} from '../talent-discovery-filter-menu.config';

import {
  OperatorOptionType,
  OperatorOptionsType,
} from '../../../engine/filters.model';
import { useSingleOrMoreFilterState } from '../../../engine/filters.engine';

export interface TDRangeInputProps {
  name: string;
  keyName: string;
  operatorOptions: OperatorOptionsType;
  TDState: TDStateConfig;
  setTDState: React.Dispatch<React.SetStateAction<TDStateConfig>>;
  defaultState?: Partial<TDStateConfig>;
  defaultOption: OperatorOptionType;
  selectionCategory: FilterOrSubfilterName | FilterOrSubfilterName[];
}
export const TDRangeInput = ({
  name,
  keyName,
  operatorOptions,
  TDState,
  setTDState,
  defaultState,
  defaultOption,
  selectionCategory,
}: TDRangeInputProps) => {
  const [activeState, activeStateFetchError] =
    useSingleOrMoreFilterState(selectionCategory);

  useEffect(() => {
    if (activeStateFetchError) return;

    const defaults = defaultState || {};

    const updatedState: TDRangeStateType = { ...defaults };

    // TODO: fix typings here
    reduce(
      activeState,
      (acc: any, cur: any) => {
        const value = get(cur, 'value', {} as TalentDiscoveryValue);

        const { keyName, opValue, startValue, endValue } = value;

        if (opValue) {
          const currentOPValue: Partial<OPSelectFilter> = get(
            updatedState,
            'opValue',
            {}
          );

          set(updatedState, 'opValue', {
            ...currentOPValue,
            [keyName]: opValue,
          });
        }

        if (!isUndefined(startValue)) {
          assign(updatedState, {
            startValue: { [keyName]: { value: startValue } },
          });
        }

        if (!isUndefined(endValue)) {
          assign(updatedState, {
            endValue: { [keyName]: { value: endValue } },
          });
        }

        return { ...acc, ...updatedState };
      },
      {}
    );

    setTDState((prevState: TDStateConfig) => {
      const relevantFilters = pick(prevState, [
        'opValue',
        'startValue',
        'endValue',
      ]);

      const mergedState: TDStateConfig = { ...relevantFilters };

      Object.entries(updatedState).forEach(
        ([key, value]: [string, TDRangeStateType]) => {
          const previousVal = get(prevState, key);

          if (isUndefined(previousVal)) {
            mergedState[key] = value;
            return;
          }

          if (isPlainObject(value)) {
            Object.entries(value).forEach(([innerKey, innerValue]) => {
              mergedState[key][innerKey] = innerValue;
            });
          }
        }
      );

      return mergedState;
    });
  }, [activeState, activeStateFetchError, defaultState, setTDState]);

  return (
    <VStack spacing={2} align="stretch">
      {/* TODO: properly type create element */}
      {/* eslint-disable-next-line */}
      {createElement<any>(Select, {
        name: name,
        placeholder: name,
        isMulti: false,
        options: operatorOptions,
        value: get(TDState, `opValue.${keyName}`),
        closeMenuOnScroll: () => true,
        defaultValue: defaultOption,
        onChange: (e: any) => {
          setTDState((prev: any) => {
            const prevOPValue = get(prev, 'opValue', {});

            return {
              ...prev,
              opValue: { ...prevOPValue, [keyName]: e },
            };
          });
        },
        size: 'sm',
        chakraStyles: {
          dropdownIndicator: (
            prev: any,
            { selectProps: { menuIsOpen } }: any
          ) => ({
            ...prev,
            '> svg': {
              transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
            },
          }),
        },
      })}
      <Flex align="center">
        {get(TDState, `opValue.${keyName}.value`) === OPValues.BETWEEN && (
          <>
            {/* TODO: properly type create element */}
            {/* eslint-disable-next-line */}
            {createElement<any>(NumberFormat, {
              customInput: Input,
              placeholder: 'years',
              thousandSeparator: true,
              allowNegative: true,
              suffix: ' years',
              type: 'text',
              size: 'sm',
              width: '100%',
              maxWidth: '100px',
              flex: '1',
              value: get(TDState?.startValue, `${keyName}.value`, ''),
              onValueChange: (e: any) => {
                setTDState((prev: any) => {
                  const prevStartValue = get(prev, 'startValue', {});
                  return {
                    ...prev,
                    startValue: {
                      ...prevStartValue,
                      [keyName]: {
                        filterName: selectionCategory[0],
                        value: e.floatValue,
                      },
                    },
                  };
                });
              },
            })}
            <Text px="2" fontSize="sm">
              to
            </Text>
          </>
        )}
        {/* TODO: properly type create element */}
        {/* eslint-disable-next-line */}
        {createElement<any>(NumberFormat, {
          customInput: Input,
          placeholder: 'years',
          thousandSeparator: true,
          allowNegative: true,
          suffix: ' years',
          type: 'text',
          size: 'sm',
          width: '100%',
          maxWidth: '100px',
          flex: '1',
          value: get(TDState, `endValue.${keyName}.value`, ''),
          onValueChange: (e: any) => {
            setTDState((prev: any) => {
              const prevEndValue = get(prev, 'endValue', {});

              return {
                ...prev,
                endValue: {
                  ...prevEndValue,
                  [keyName]: {
                    filterName: selectionCategory[0],
                    value: e.floatValue,
                  },
                },
              };
            });
          },
        })}
      </Flex>
    </VStack>
  );
};
