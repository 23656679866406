import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { PublicationEntryRaw } from '../resumeTypes';

interface PublicationRawProps {
  data?: PublicationEntryRaw;
  variant?: string;
}

export default function PublicationRaw({ data, variant }: PublicationRawProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      <Text sx={styles['sectionDate']}>{data?.publication_date}</Text>
      <Text sx={styles['sectionTitle']}>{data?.title}</Text>
      <Text sx={styles['sectionDetails']}>{data?.organization}</Text>
      <Text sx={styles['sectionDetails']}>{data?.url}</Text>
      <Text sx={styles['sectionDetails']} mt="5px">
        {data?.description}
      </Text>
    </Box>
  );
}
