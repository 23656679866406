// Find greatest common divisor (GCD)
const gcd = (a: number, b: number): number => {
  return b === 0 ? a : gcd(b, a % b);
};

export const getValueRatio = (
  num1?: number | null,
  num2?: number | null
): [number, number] | null => {
  if (!num1 || !num2) {
    return [0, 0];
  }

  // If num1 is at least 2 times greater than num2, return ratio as x:1
  if (num1 >= 2 * num2) {
    return [Math.round(num1 / num2), 1];
  }

  // If num2 is at least 2 times greater than num1, return ratio as 1:x
  if (num2 >= 2 * num1) {
    return [1, Math.round(num2 / num1)];
  }

  const divisor = gcd(num1, num2);
  const simplifiedNum1 = num1 / divisor;
  const simplifiedNum2 = num2 / divisor;

  // If either number exceeds 9, scale both numbers down proportionally
  if (simplifiedNum1 > 9 || simplifiedNum2 > 9) {
    const maxNum = Math.max(simplifiedNum1, simplifiedNum2);
    const scale = maxNum / 9; // Find the scaling factor

    return [
      Math.round(simplifiedNum1 / scale),
      Math.round(simplifiedNum2 / scale),
    ];
  }

  // Return the simplified numbers if no scaling is needed
  return [Math.round(simplifiedNum1), Math.round(simplifiedNum2)];
};
