import {
  SelectionCategories,
  FilterContainer,
  FilterMenu,
  FilterChips,
  useViewFilters,
  AddEntityButton,
  useViewFilterDefaults,
  useStoredFilterSet,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  LocalSelectionCategories,
  useSelectionLists,
  OtherFilterNames,
  usePrimaryFilter,
  useDefaultLastMonth,
  DefaultDates,
  FilterMenuItemOrConfig,
  createSelectableFiltersMap,
  useSyncFiltersToSearchParams,
  PrimaryDataView,
  getPrimaryDataView,
  TabsFilter,
  RequestMethod,
  requireAtLeastOneFilterValueOf,
  serializeFiltersForQuery,
  PlotAdditionalQueryParams,
  FilterTypes,
  AnyFilter,
  filterStore,
  setManualLoadingStatus,
  useSingleOrMoreFilterState,
  FilterOrSubfilterName,
  FilterList,
  ValidValueTypes,
  FilterParameterKeys,
  SHARED_SET_ENTITY_LIMIT,
  Tab,
  DateRangeFormattedValues,
  getSelectedLastMonth,
  getStartDateConst,
  getYearFromDate,
  FilterChipsContainer,
  FiltersUsedInTabs,
  isNotNillOrEmpty,
  useTabMeta,
  FilterSetSaveMenu,
} from '@revelio/filtering';
import { Grid, GridItem, Flex, Box, Link, Text } from '@chakra-ui/react';
import {
  EndpointSegment,
  useManyPlotConfigProviders,
  provideBasePlotConfigDefaults,
  ViewTypes,
} from '@revelio/filtering';
import { BoxPlot, D3ChartNames, ID3ChartProps } from '@revelio/d3';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  pipe,
  switchMap,
  tap,
} from 'rxjs';
import { flatten, get, startCase, omit, find, set, compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DefaultCard } from '@revelio/composed';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  Views,
  useResponsivePageGridDefs,
  TourClasses,
  useGlobalLoaderMaxWait,
  CompensationTypes,
  getCompDataById,
  salaryOverTimeDataTransformer,
  timeToFillDataTransformer,
  CompensationResponseTimeToFillValues,
  PrimaryView,
} from '@revelio/core';
import DashboardPage from '../DashboardPage';
import { GO_API_ROOT } from '@revelio/auth';
import { useClient } from 'urql';
import { getEntity } from '@ngneat/elf-entities';
import { useMount } from 'react-use';
import { getTopEntityFilters } from './helpers';
import objectHash from 'object-hash';
import { environment } from '../../environments/environment';
import { CompensationModelSwitch } from './compensation-model-switch';
import { Dimension1, View } from '@revelio/data-access';
import { usePayDataFetch } from './data-fetch/use-pay-data-fetch';
import { PlotLayoutCard } from '../../shared/components/plots/plot-layout-card';
import { PlotActionMenu } from '../../shared/components/plots/plot-action-menu';

const isProd = environment.production;

const getCompensationType = () => {
  const state = filterStore.query(
    getEntity(LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP)
  );
  return get(state, 'value.id', CompensationTypes.BASE);
};

const providerFilterId = LocalSelectionCategories.PROVIDER;

const plotInfoBodyLookup = {
  [ViewTypes.COMPANY]: {
    [EndpointSegment.TOP_ROLES]: (
      <Text variant="tooltip">
        The most common roles within this company. More information on the
        occupational taxonomy can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#jobs-taxonomy"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
    [EndpointSegment.TOP_GEOGRAPHIES]: (
      <Text variant="tooltip">
        The most common geographies within this company.
      </Text>
    ),
  },
  [ViewTypes.GEO]: {
    [EndpointSegment.TOP_ROLES]: (
      <Text variant="tooltip">
        The most common roles within this geography. More information on the
        occupational taxonomy can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#jobs-taxonomy"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
    [EndpointSegment.TOP_COMPANIES]: (
      <Text variant="tooltip">
        The most common companies hiring in this geography. More information on
        company mapping can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#company-mapping"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
  },
  [ViewTypes.ROLE]: {
    [EndpointSegment.TOP_GEOGRAPHIES]: (
      <Text variant="tooltip">The most common geographies for this role.</Text>
    ),
    [EndpointSegment.TOP_COMPANIES]: (
      <Text variant="tooltip">
        The most common companies hiring for this role. More information on
        company mapping can be found{' '}
        <Link
          href="https://www.data-dictionary.reveliolabs.com/methodology.html#company-mapping"
          isExternal
          variant="tooltip"
        >
          here
        </Link>
        .
      </Text>
    ),
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

export interface CompensationProps {
  title: PageTitles[];
  primaryView: PrimaryView;
  viewType: ViewTypes | Tab;
  primaryFilter: PrimaryFilters;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryFiltersLimit: PrimaryFilterLimits | number;
  nonActiveSelectableFilters?: (SelectionCategories | SelectionCategories[])[];
  selectableFilters: FilterMenuItemOrConfig[];
  filterMenuLimits: FilterMenuLimits | number;
  otherFilters: (OtherFilterNames | SelectionCategories)[];
  additionalNonActiveFilters?: (OtherFilterNames | SelectionCategories)[];
  disableParentOnPrimaryFilter?: boolean;
  viewFiltersForDefault?: (OtherFilterNames | SelectionCategories)[];
  onlyConsiderTheseFiltersToTriggerDefaults?: (
    | OtherFilterNames
    | SelectionCategories
  )[];
  trialNoResultsMessage?: JSX.Element;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  savedSetView: View;
}

export function Compensation({
  title,
  primaryView,
  viewType,
  primaryFilter,
  primaryFiltersLimit,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  primaryViewFilters,
  selectableFilters,
  filterMenuLimits,
  otherFilters,
  disableParentOnPrimaryFilter = false,
  viewFiltersForDefault,
  onlyConsiderTheseFiltersToTriggerDefaults,
  trialNoResultsMessage,
  isGqlQuery = false,
  isGoRequest = true,
  savedSetView,
}: CompensationProps) {
  // controls whether plot link option is available in card menu
  const disablePlotLink = true;
  const client = useClient();

  const additionalNonActiveFiltersPageGlobal = [
    LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
  ];

  useMount(() => {
    setManualLoadingStatus(true);
  });

  const view = Views.COMPENSATION;

  const {
    templateColumns,
    templateRows,
    bigPlotColSpan,
    bigPlotRowSpan,
    gridItemMinHeight,
    tallGridItemMinHeight,
  } = useResponsivePageGridDefs(Views.COMPENSATION);

  const selectableFiltersMap = createSelectableFiltersMap(selectableFilters);
  const flatSelectableFilters = flatten(
    selectableFiltersMap as SelectionCategories[]
  );

  const primaryFilters = flatten(
    createSelectableFiltersMap(primaryViewFilters)
  );

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: viewType,
    primaryEntitiesSync: true,
    limit: primaryFiltersLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
    defaultLimit: PrimaryFilterLimits.COMPENSATION_DEFAULT,
  };

  useStoredFilterSet(storedFilterSetArgs);

  const brokenOutFilterIds = [
    ...primaryFilters,
    ...flatSelectableFilters,
    SelectionCategories.TOP_GEOGRAPHIES,
    SelectionCategories.TOP_COMPANIES,
    SelectionCategories.TOP_ROLES,
    LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
    SelectionCategories.PRIMARY_FILTER,
  ];

  useViewFilters([...primaryFilters, ...selectableFiltersMap]);

  useSelectionLists([
    ...primaryFilters,
    ...flatSelectableFilters,
    ...FiltersUsedInTabs,
  ]);

  const viewFilterDefaultArgs = {
    view,
    viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults:
      onlyConsiderTheseFiltersToTriggerDefaults ?? [
        LocalSelectionCategories.PRIMARY_ENTITIES,
      ],
    viewFilters: viewFiltersForDefault ?? [
      ...otherFilters,
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    deepLinkLimit: PrimaryFilterLimits.COMPENSATION,
    limit: PrimaryFilterLimits.COMPENSATION_DEFAULT,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);
  useTabMeta({
    savedSetView,
    view,
    viewType,
    deepLinkLimit: PrimaryFilterLimits.COMPENSATION,
    limit: PrimaryFilterLimits.COMPENSATION_DEFAULT,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });
  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.LAST_START_DATE,
  });

  useGlobalLoaderMaxWait(false);

  usePrimaryFilter(primaryFilter);

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(viewType),
    [viewType]
  );

  useSingleOrMoreFilterState(
    [...primaryFilters, ...flatSelectableFilters] as FilterOrSubfilterName[],
    pipe(
      tap((filters) => {
        setManualLoadingStatus(true);
      })
    )
  );

  const [compensationType, setCompensationType] = useState<CompensationTypes>(
    CompensationTypes.TOTAL
  );
  const [currentCompViewLabel] = useSingleOrMoreFilterState(
    LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
    map((state) => {
      const id = get(state, 'value.id') as CompensationTypes;
      const label = {
        [CompensationTypes.HOURLY]: 'hourly pay',
        [CompensationTypes.BASE]: 'base salary',
        [CompensationTypes.TOTAL]: 'total compensation',
      }[id];
      setCompensationType(id);
      return label;
    })
  ) as [string, unknown];

  const requireAtLeastOne = requireAtLeastOneFilterValueOf(primaryFilters);

  const [companyNameMap, setCompanyNameMap] = useState<{
    [key: number]: string;
  }>({});

  const [modelsCompensationUrl, setModelsCompensationUrl] = useState<string>(
    `${GO_API_ROOT}/models/compensation`
  );

  //** DISTRIBUTION *//
  /////////////////////
  // lodash get kde
  // kde values are for the y-axis (500)
  // metadata.salaries is for the x-axis (500)
  // base_salary > kde > role_1 > value and metadata

  const {
    mappers: [
      {
        name: nameMainCompensations,
        endpointMapper: endpointMapperMainCompensations,
        plotConfigMapper: plotConfigMapperMainCompensations,
        downloadEndpointMapper: downloadEndpointMainCompensations,
        additionalNonActiveFilters: additionalNonActiveFiltersMainCompensations,
        brokenOutFilterIds: brokenOutFilterIdsMainCompensations,
        dataProvider: dataProviderMainCompensations,
        metaData: metaDataMainCompensations,
        endpointSegment,
      },
    ],
    allUpdater: allUpdaterMainCompensations,
  } = useManyPlotConfigProviders([
    {
      view: Views.COMPENSATION,
      endpoint: EndpointSegment.DISTRIBUTION,
      viewType,
      url: new URL(modelsCompensationUrl),
      chartType: D3ChartNames.KDE,
      chartProps: {
        name: 'compensation-company-main',
        chartStyle: '.compensation-company-main-plot',
        metaValueCompany: 'shortName', //for coloring by company
        yAxisFormat: '~s',
        dateFormat: 'YMD', //YM or YMD
        ttType: 'single', //percent (%value & count in tooltip), custom (add ttCustomString after single value) or single (tooltip with single value)
        ttCustomString: 'days', //for ttType="custom", string to use in tooltip after value
        ttMainFormat: '$,',
        ttSecondaryFormat: ',', //for editing the count in ttType='percent'
      },
      additionalNonActiveFilters: additionalNonActiveFiltersPageGlobal,
      brokenOutFilterIds: brokenOutFilterIds,
      metaData: {
        isGoRequest,
        pageGroupName: 'compensation',
        primaryDataView,
      },
    },
  ]);

  const additionalOperatorsForDistribution = pipe(
    // eslint-disable-next-line
    map((data: any) => {
      if (data?.loading || data.error) {
        return data;
      }
      const requestHash = get(data, 'requestHash');
      const compType = getCompensationType();
      const idToLabelMap =
        primaryFilter == PrimaryFilters.COMPANY ? companyNameMap : undefined;
      const kdeData = getCompDataById(data, compType, 'kde', idToLabelMap);
      if (kdeData?.value) {
        set(kdeData.value, 'requestHash', requestHash);
      }
      return kdeData?.value || [];
    }),
    tap(() => {
      setManualLoadingStatus(false);
    })
  );

  const additionalOperatorsForSalaryOverTime = pipe(
    // eslint-disable-next-line
    map((data: any) => {
      const compType = getCompensationType();
      const idToLabelMap =
        primaryFilter == PrimaryFilters.COMPANY ? companyNameMap : undefined;
      return salaryOverTimeDataTransformer(data, compType, idToLabelMap);
    })
  );

  //** LINE PLOTS **//
  //* ///////////////
  // timeseries - mean values by year is for overtime plot

  const { mappers: overtimeLinePlotMappers, allUpdater: overtimeAllUpdater } =
    useManyPlotConfigProviders([
      {
        view: Views.COMPENSATION,
        viewType,
        chartType: D3ChartNames.LineChart,
        url: new URL(modelsCompensationUrl),
        endpoint: EndpointSegment.OVERTIME,
        chartProps: {
          name: EndpointSegment.OVERTIME,
          chartStyle: `.compensation-company-page-${EndpointSegment.OVERTIME}`,
          chartSize: 'large',
          dateFormat: 'YM',
          yAxisFormat: '$~s',
          ttType: 'single',
          ttMainFormat: '$.4s',
          ttSecondaryFormat: ',',
          chartPosition: 'left',
          metaValueCompany: 'shortName',
        },
        brokenOutFilterIds: brokenOutFilterIds,
        additionalNonActiveFilters: additionalNonActiveFiltersPageGlobal,
        metaData: {
          isGoRequest,
          pageGroupName: 'compensation',
          primaryDataView,
          requiredParams: [
            LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
            SelectionCategories.PRIMARY_FILTER,
          ],
        },
      },
    ]);

  const viewDefaultsForTimeToFillPlots = provideBasePlotConfigDefaults({
    view: Views.POSTING,
    viewType: ViewTypes.OVERTIME,
    chartType: D3ChartNames.LineChart,
    chartProps: {
      yAxisFormat: '~s',
      metaValueCompany: 'shortName',
      dateFormat: 'YM',
      ttType: 'single',
      ttMainFormat: 'd',
      ttSecondaryFormat: ',',
      ttCustomString: 'days',
    },
  });

  const { mappers: timeToFillLinePlotMappers } = useManyPlotConfigProviders([
    viewDefaultsForTimeToFillPlots({
      endpoint: EndpointSegment.TIMETOFILL,
      metaData: {
        isGqlQuery: true,
        isGoRequest: true,
        primaryDataView,
        pageGroupName: 'postings',
      },
      chartProps: {
        name: EndpointSegment.TIMETOFILL,
        chartStyle: `.postings-company-page-${EndpointSegment.TIMETOFILL}`,
        yAxisFormat: '~s',
        ttType: 'custom',
      },
      brokenOutFilterIds: [SelectionCategories.PRIMARY_FILTER],
    }),
  ]);

  const additionalOperatorsBeforeTimeToFill = pipe(
    requireAtLeastOne,
    map((data: PlotAdditionalQueryParams) => {
      return {
        ...data,
        filters: [
          ...data.filters,
          {
            id: LocalSelectionCategories.PROVIDER,
            value: [
              {
                id: 4,
                label: 'Indeed',
                shortName: 'Indeed',
                parentId: -1,
              },
              {
                id: 2,
                label: 'Website Postings',
                data: { shortName: 'Website Postings' },
              },
              {
                id: 3,
                label: 'LinkedIn',
                shortName: 'LinkedIn',
                parentId: -1,
              },
            ],
            isMulti: true,
            label: LocalSelectionCategories.PROVIDER,
            selectionListId: LocalSelectionCategories.PROVIDER,
            type: FilterTypes.SELECT,
          },
        ],
      } as PlotAdditionalQueryParams;
    }),
    distinctUntilChanged(
      (prev: PlotAdditionalQueryParams, curr: PlotAdditionalQueryParams) =>
        objectHash(prev.filters, { unorderedArrays: true }) ===
        objectHash(curr.filters, { unorderedArrays: true })
    )
  );

  //** BOX PLOTS **//
  //* ///////////////
  const additionalOperatorsBeforeTopPlots = pipe(
    requireAtLeastOne,
    switchMap(
      async (
        data: PlotAdditionalQueryParams
      ): Promise<PlotAdditionalQueryParams> => {
        const serializedFilters = serializeFiltersForQuery(
          data.filters,
          [],
          true
        ).filters;
        const filteredFilters = omit(serializedFilters, [
          FilterParameterKeys.START_DATE,
          FilterParameterKeys.END_DATE,
          FilterParameterKeys.DIM1,
        ]);

        if (primaryFilter == PrimaryFilters.COMPANY) {
          const companyFilter = find(
            data.filters,
            (f) => f.id == SelectionCategories.COMPANY
          );
          const industryyFilter = find(
            data.filters,
            (f) => f.id == SelectionCategories.INDUSTRY
          );
          compact([
            ...(companyFilter?.value as FilterList<ValidValueTypes>),
            ...(industryyFilter?.value as FilterList<ValidValueTypes>),
          ]).forEach((val) => {
            setCompanyNameMap((s) => {
              set(s, val.rcid || val.id, val.label);
              return s;
            });
          });
        }

        // TODO: finish this to skip top entity queries if we are on defaults
        // const defaultTopEntityFilters = isDefaultsSoProvideTopEntitiesOverQuering(primaryFilter, filteredFilters)
        const { topCompanies, topGeos, topRoles } = await getTopEntityFilters({
          client,
          dim1: serializedFilters?.dim1 as Dimension1,
          filteredFilters,
        });

        const dateRangeFilter = data.filters.find(
          (filter) => filter.id === SelectionCategories.DATE_RANGE_FULL
        )?.value as DateRangeFormattedValues;

        const customFormatter = (includeYearRange = false) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (filter: AnyFilter<any>, idx: number): unknown => {
            const defaultStartYear = getYearFromDate(
              getStartDateConst(Views.COMPENSATION)
            );
            const compensationLastMonth = getSelectedLastMonth(
              Views.COMPENSATION
            );
            const defaultEndYear = compensationLastMonth
              ? getYearFromDate(compensationLastMonth)
              : getYearFromDate(new Date().toISOString());
            const startYear =
              dateRangeFilter?.startDate &&
              isNotNillOrEmpty(dateRangeFilter.startDate)
                ? getYearFromDate(dateRangeFilter.startDate)
                : defaultStartYear;
            const endYear =
              dateRangeFilter?.endDate &&
              isNotNillOrEmpty(dateRangeFilter.endDate)
                ? getYearFromDate(dateRangeFilter.endDate)
                : defaultEndYear;

            const yearRange = {
              year_range: [[Number(startYear), Number(endYear)]],
            };

            return {
              [filter.id]: filter.value.map((v: AnyFilter) => ({
                id: v.id,
                label: v.id,
              })),
              ...(includeYearRange ? yearRange : {}),
            };
          };
        };

        const topCompaniesFilter = {
          id: SelectionCategories.TOP_COMPANIES,
          value: topCompanies.map((company) => {
            setCompanyNameMap((s) => {
              const id = company?.metadata?.id;
              if (id) {
                s[id] = company?.metadata?.shortName || `${id}`;
              }
              return s;
            });
            return {
              data: { shortName: company?.metadata?.shortName || '' },
              label: company?.metadata?.shortName || '',
              id: company?.metadata?.id || 0,
            };
          }),
          isMulti: true,
          label: SelectionCategories.COMPANY,
          selectionListId: SelectionCategories.COMPANY,
          type: FilterTypes.SELECT,
          customFormatter: customFormatter(true),
        } as AnyFilter;

        const topGeosFilter = {
          id: SelectionCategories.TOP_GEOGRAPHIES,
          value: topGeos.map((geo) => ({
            data: { shortName: geo?.metadata?.shortName || '' },
            label: geo?.metadata?.shortName || '',
            id: geo?.metadata?.id || 0,
          })),
          isMulti: true,
          label: SelectionCategories.REGION,
          selectionListId: SelectionCategories.REGION,
          type: FilterTypes.SELECT,
          customFormatter: customFormatter(true),
        } as AnyFilter;

        const topRolesFilter = {
          id: SelectionCategories.TOP_ROLES,
          value: topRoles.map((role) => ({
            data: { shortName: role?.metadata?.shortName || '' },
            label: role?.metadata?.shortName || '',
            id: role?.metadata?.id || 0,
          })),
          isMulti: true,
          label: SelectionCategories.ROLES,
          selectionListId: SelectionCategories.ROLES,
          type: FilterTypes.SELECT,
          customFormatter: customFormatter(true),
        } as AnyFilter;

        const remainingFilters = data.filters.filter(
          (filter) =>
            ![SelectionCategories.DATE_RANGE].includes(
              filter.id as SelectionCategories
            )
        );

        const topFiltersMap = omit(
          {
            [PrimaryFilters.COMPANY]: topCompaniesFilter,
            [PrimaryFilters.GEOGRAPHY]: topGeosFilter,
            [PrimaryFilters.ROLE]: topRolesFilter,
          },
          primaryFilter
        );

        return {
          ...data,
          additionalFilters: [],
          filters: [
            ...remainingFilters,
            ...Object.values<AnyFilter>(topFiltersMap),
          ],
        };
      }
    ),
    debounceTime(200)
  );

  useEffect(() => {
    if (!isProd) {
      allUpdaterMainCompensations.next({ url: new URL(modelsCompensationUrl) });
      overtimeAllUpdater.next({ url: new URL(modelsCompensationUrl) });
    }
  }, [modelsCompensationUrl, allUpdaterMainCompensations, overtimeAllUpdater]);

  const [overtimeRendered, setOvertimeRendered] = useState<boolean>(false);
  const [timeToFillRendered, setTimeToFillRendered] = useState<boolean>(false);

  const allChartsRendered = overtimeRendered && timeToFillRendered;

  const setHasRendered = (name: string, rendered: boolean) => {
    if (name === 'overtime') setOvertimeRendered(rendered);
    if (name === 'timetofill') setTimeToFillRendered(rendered);
  };
  useEffect(() => {
    setTimeout(() => {
      setOvertimeRendered(true);
      setTimeToFillRendered(true);
    }, 6000);
  }, []);

  const {
    overtimeData,
    timeToFillData,
    kdeData,
    topCompanyData,
    topRoleData,
    topGeographyData,
    loading,
    colorMap,
  } = usePayDataFetch({
    view: primaryView,
    primaryFilters,
    compensationType,
    modelsCompensationUrl,
  });

  const isRole = viewType === ViewTypes.ROLE;
  const isCompany = viewType === ViewTypes.COMPANY;

  return (
    <DashboardPage
      title={title}
      loading={loading || !allChartsRendered}
      hideSelectionsMargins
      selections={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          width="100%"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={true}
            isPrimaryChip={true}
            min={1}
            limit={primaryFiltersLimit}
            addButton={
              <AddEntityButton
                entities={primaryViewFilters}
                entityName={AddEntityButtonText[primaryFilter]}
                buttonText={AddEntityButtonText[primaryFilter]}
                disableParentSelect={disableParentOnPrimaryFilter}
                isParentCheckboxHidden={disableParentOnPrimaryFilter}
                activeLimit={primaryFiltersLimit}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
          {!isProd && (
            <CompensationModelSwitch setUrl={setModelsCompensationUrl} />
          )}
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={selectableFilters}
            view={Views.COMPENSATION}
            variant="filterChip"
            limit={filterMenuLimits}
            offsetParent={[providerFilterId]}
            endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
            propsView={Views.COMPENSATION}
            viewType={viewType}
            showGranularity
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  view={Views.COMPENSATION}
                  filters={selectableFilters}
                  endDateDefaultFilterName={DefaultDates.LAST_START_DATE}
                  selectMenuOpenDefault
                  limit={filterMenuLimits}
                  offsetParent={[providerFilterId]}
                  viewIdForDefault={`${view}_${viewType}`}
                />
                <FilterSetSaveMenu view={savedSetView} />
              </>
            }
          />
        </Flex>
        <Box className={TourClasses.TOUR_VIEW_CLASS}>
          <TabsFilter
            initialValue={{
              id: CompensationTypes.TOTAL,
              label: startCase(ViewTypes.TOTAL_COMP),
              index: 2,
            }}
            includeInFilters
            includeTabChangeLoaderEvent={false}
            filterName={LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP}
          ></TabsFilter>
        </Box>
      </FilterContainer>
      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
        data-testid="plots-grid"
      >
        {overtimeLinePlotMappers.map(
          (
            {
              name,
              endpointMapper,
              plotConfigMapper,
              endpointSegment,
              downloadEndpointMapper,
              additionalNonActiveFilters,
              metaData,
              brokenOutFilterIds: brokenOutFilterIdsConfig,
              dataProvider,
            },
            i
          ) => (
            <GridItem
              className={`compensation-company-page-${endpointSegment}`}
              key={'overtime-pay-plot'}
              rowSpan={1}
              colSpan={1}
              minH={gridItemMinHeight}
            >
              <DefaultCard
                cardConfig={{
                  header: name,
                  endpointSegment,
                  view: Views.COMPENSATION,
                  disablePlotLink,
                }}
                customPlotInfo={{
                  plotInfoConfig: {
                    iconBoxSize: 3,
                    popoverPlacement: 'top-start',
                  },
                  plotInfoBody: (
                    <Text variant="tooltip">
                      The average predicted {currentCompViewLabel} over time.
                      More information on the compensation model can be found{' '}
                      <Link
                        href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
                        isExternal
                        variant="tooltip"
                      >
                        here
                      </Link>
                      .
                    </Text>
                  ),
                }}
                plotConfig={{
                  endpoint: endpointMapper,
                  chartTypeAndProps: plotConfigMapper,
                  additionalNonActiveFilters,
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeTopPlots,
                  brokenOutFilterIds: brokenOutFilterIdsConfig,
                  dataProvider,
                  isGqlQuery,
                  requestMethod: RequestMethod.POST,
                  isGoRequest,
                  additionalOperators: additionalOperatorsForSalaryOverTime,
                  includeInGlobalLoader: false,
                }}
                downloadConfig={{
                  endpoint: downloadEndpointMapper,
                  isGoRequest: metaData?.isGoRequest,
                  disabled: true,
                }}
                data={overtimeData}
                loading={loading}
                setHasRendered={setHasRendered}
              />
            </GridItem>
          )
        )}
        {timeToFillLinePlotMappers.map(
          (
            {
              name,
              endpointMapper,
              plotConfigMapper,
              endpointSegment,
              downloadEndpointMapper,
              metaData,
              additionalNonActiveFilters,
              brokenOutFilterIds: brokenOutFilterIdsConfig,
              dataProvider,
            },
            i
          ) => (
            <GridItem
              className={`postings-company-page-${endpointSegment}`}
              key={'pay-time-to-fill'}
              rowSpan={1}
              colSpan={1}
              minH={gridItemMinHeight}
            >
              <DefaultCard
                cardConfig={{
                  header: name,
                  endpointSegment,
                  view: Views.POSTING,
                  disablePlotLink,
                }}
                plotConfig={{
                  endpoint: endpointMapper,
                  chartTypeAndProps: plotConfigMapper,
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeTimeToFill,
                  additionalOperators: pipe(
                    map((d: CompensationResponseTimeToFillValues[]) => {
                      return timeToFillDataTransformer(d);
                    })
                  ),
                  additionalNonActiveFilters,
                  brokenOutFilterIds: brokenOutFilterIdsConfig,
                  dataProvider,
                  isGqlQuery: metaData?.isGqlQuery,
                  isGoRequest: metaData?.isGoRequest,
                  includeInGlobalLoader: false,
                }}
                downloadConfig={{
                  endpoint: downloadEndpointMapper,
                  isGoRequest: metaData?.isGoRequest,
                }}
                data={timeToFillData}
                loading={loading}
                setHasRendered={setHasRendered}
              />
            </GridItem>
          )
        )}

        <GridItem rowSpan={2} colSpan={1} minH={tallGridItemMinHeight}>
          <PlotLayoutCard
            title={`${isRole ? 'Top Geographies' : 'Top Roles'}`}
            plotInfo={{
              plotInfoBody: isRole
                ? plotInfoBodyLookup[viewType][EndpointSegment.TOP_GEOGRAPHIES]
                : plotInfoBodyLookup[viewType][EndpointSegment.TOP_ROLES],
              plotInfoConfig: {
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              },
            }}
            menu={
              <PlotActionMenu
                title={`${isRole ? 'Top Geographies' : 'Top Roles'}`}
                chartType={undefined as unknown as D3ChartNames}
                chartProps={{} as ID3ChartProps}
                disableLink
                disableDownload
                expandedPlot={
                  <BoxPlot
                    data={isRole ? topGeographyData : topRoleData}
                    boxColors={colorMap}
                  />
                }
                pr={2}
              />
            }
          >
            <BoxPlot
              data={isRole ? topGeographyData : topRoleData}
              boxColors={colorMap}
            />
          </PlotLayoutCard>
        </GridItem>

        <GridItem
          className="compensation-company-main-plot"
          rowSpan={bigPlotRowSpan}
          colSpan={bigPlotColSpan}
          minH={tallGridItemMinHeight}
        >
          <DefaultCard
            cardConfig={{
              header: nameMainCompensations,
              endpointSegment,
              view: Views.POSTING,
              disablePlotLink,
            }}
            customPlotInfo={{
              plotInfoConfig: {
                iconBoxSize: 3,
                popoverPlacement: 'top-start',
              },
              plotInfoBody: (
                <Text variant="tooltip">
                  The distribution of {currentCompViewLabel}. More information
                  on the compensation model can be found{' '}
                  <Link
                    href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
                    isExternal
                    variant="tooltip"
                  >
                    here
                  </Link>
                  .
                </Text>
              ),
            }}
            plotConfig={{
              additionalNonActiveFilters:
                additionalNonActiveFiltersMainCompensations,
              additionalOperatorsBeforeQuery: additionalOperatorsBeforeTopPlots,
              brokenOutFilterIds: brokenOutFilterIdsMainCompensations,
              endpoint: endpointMapperMainCompensations,
              chartTypeAndProps: plotConfigMapperMainCompensations,
              dataProvider: dataProviderMainCompensations,
              isGqlQuery,
              requestMethod: RequestMethod.POST,
              isGoRequest,
              additionalOperators: additionalOperatorsForDistribution,
              includeInGlobalLoader: true,
            }}
            downloadConfig={{
              endpoint: downloadEndpointMainCompensations,
              isGoRequest: metaDataMainCompensations?.isGoRequest,
              disabled: true,
            }}
            data={kdeData}
            loading={loading}
          />
        </GridItem>

        <GridItem rowSpan={2} colSpan={1} minH={tallGridItemMinHeight}>
          <PlotLayoutCard
            title={`${isCompany ? 'Top Geographies' : 'Top Companies'}`}
            plotInfo={{
              plotInfoBody: isCompany
                ? plotInfoBodyLookup[viewType][EndpointSegment.TOP_GEOGRAPHIES]
                : plotInfoBodyLookup[viewType][EndpointSegment.TOP_COMPANIES],
              plotInfoConfig: {
                popoverPlacement: 'right',
                iconBoxSize: 2.5,
              },
            }}
            menu={
              <PlotActionMenu
                title={`${isCompany ? 'Top Geographies' : 'Top Companies'}`}
                chartType={undefined as unknown as D3ChartNames}
                chartProps={{} as ID3ChartProps}
                disableLink
                disableDownload
                expandedPlot={
                  <BoxPlot
                    data={isCompany ? topGeographyData : topCompanyData}
                    boxColors={colorMap}
                  />
                }
                pr={2}
              />
            }
          >
            <BoxPlot
              data={isCompany ? topGeographyData : topCompanyData}
              boxColors={colorMap}
            />
          </PlotLayoutCard>
        </GridItem>
      </Grid>
    </DashboardPage>
  );
}

export default Compensation;
