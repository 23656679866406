import React, { useState } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  LayoutProps,
  Link,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Loading } from '@revelio/core';
import UploadIcon from '../deliverables/data-enrichment/icons/UploadIcon';
import { environment } from '../../environments/environment';

const SAMPLE_FILE_URL =
  'https://rl-images-2.s3.us-east-2.amazonaws.com/dashboard/sample_resume.pdf';

export interface FileUploadResponse {
  environment: string;
  key: string;
  message: string;
  s3_loc: string;
  name: string;
}

const UploadResume = ({
  fileUploadHeight,
  onUpload,
}: {
  fileUploadHeight?: LayoutProps['h'];
  onUpload: (response: FileUploadResponse) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptedDropError, setAcceptedDropError] = useState<string>();

  const dropzoneState = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    // maxSize: maxFileSize,
    accept: {
      'application/pdf': ['.pdf'],
      // 'application/msword': ['.doc'],
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((fileRejection) => {
        setAcceptedDropError(
          `"${fileRejection.file.name}" must be a .pdf`
          // `"${fileRejection.file.name}" must be either a .pdf, .doc`
        );
      });
    },
    onDropAccepted: async ([file]) => {
      setLoading(true);
      setAcceptedDropError(undefined);
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`${environment.GO_API_ROOT}/file/upload`, {
          method: 'POST',
          body: formData,
          credentials: 'include',
          headers: {
            'X-File-Name': file.name,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        await onUpload({ ...data, name: file.name } as FileUploadResponse);
      } catch (e) {
        setAcceptedDropError((e as Error).message);
      }
      setLoading(false);
    },
  });

  return (
    <FileUpload
      fileUploadHeight={fileUploadHeight}
      dropzoneState={dropzoneState}
      loading={loading}
      uploadErrorMessage={acceptedDropError}
    />
  );
};

const FileUpload = ({
  fileUploadHeight,
  dropzoneState: {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  },
  loading,
  uploadErrorMessage,
}: {
  fileUploadHeight?: LayoutProps['h'];
  dropzoneState: DropzoneState;
  loading: boolean;
  uploadErrorMessage: string | undefined;
}) => {
  let borderColor = 'gray.200';
  let bgColor = 'white';
  let message: React.ReactNode = (
    <Flex flexDirection="column" alignItems="center">
      <UploadIcon />
      <Text textAlign="center" color="text.primary">
        Drag & drop your .pdf file here,
        {/* Drag & drop your .pdf or .doc file here, */}
      </Text>
      <Text textAlign="center" color="text.primary">
        or{' '}
        <Link
          onClick={open}
          color="lightBlue.600"
          textDecoration="underline"
          fontWeight={600}
          _hover={{ textDecoration: 'none' }}
        >
          choose file
        </Link>
      </Text>
    </Flex>
  );

  if (isDragActive) {
    borderColor = 'lightBlue.500';
    bgColor = 'lightBlue.100';
    message = 'Release to drop the files here';
  }

  if (isDragReject || uploadErrorMessage) {
    borderColor = 'red.500';
    bgColor = 'red.100';
  }

  if (isDragReject) {
    message = 'File type not accepted, try again!';
  }

  if (isDragAccept) {
    borderColor = 'green.500';
    bgColor = 'green.100';
    message = 'File accepted, release to drop!';
  }
  return (
    <VStack w="full" h={fileUploadHeight ?? 'full'} gap={4}>
      <Alert status="info" style={{ background: 'rgba(94, 201, 238,0.2)' }}>
        <Spacer />
        <Box>
          <Text color="text.primary" fontSize="sm">
            Download{' '}
            <Link
              onClick={() => window.open(SAMPLE_FILE_URL)}
              color="lightBlue.600"
              textDecoration="underline"
              fontWeight={600}
              _hover={{ textDecoration: 'none' }}
            >
              sample resume
            </Link>{' '}
            file
          </Text>
        </Box>
      </Alert>
      <VStack
        {...getRootProps()}
        w="full"
        h="full"
        border="2px"
        borderStyle="dashed"
        borderColor={borderColor}
        borderRadius="md"
        bg={bgColor}
        alignItems="center"
        justifyContent="center"
        transition="border .24s ease-in-out, background .24s ease-in-out"
        _hover={{
          borderColor: 'gray.300',
        }}
        _focusWithin={{
          borderColor: 'lightBlue.500',
        }}
      >
        {loading ? (
          <VStack>
            <Loading size="sm" />
            <Text color="text.primary" fontSize="sm" opacity={0.8}>
              Processing file, please wait...
            </Text>
          </VStack>
        ) : (
          <>
            <input {...getInputProps()} />
            <Box>{message}</Box>
          </>
        )}
      </VStack>

      {uploadErrorMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Upload Error</AlertTitle>
          <AlertDescription>{uploadErrorMessage}</AlertDescription>
        </Alert>
      )}
    </VStack>
  );
};

export default UploadResume;
