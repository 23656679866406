import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import {
  DiagnosticsModalStateType,
  PlotLinkModalStateType,
  DownloadModalStateType,
  FullScreenStateType,
  PlotDimensionsStateType,
  PlotModalStateType,
} from '../../lib/types';

type DefaultCardHelperReturnState = {
  plotModalState: PlotModalStateType;
  downloadModalState: DownloadModalStateType;
  fullScreenState: FullScreenStateType;
  plotDimensionsState: PlotDimensionsStateType;
  diagnosticsModalState: DiagnosticsModalStateType;
  plotLinkModalState: PlotLinkModalStateType;
};

export const useDefaultCardHelperState = (): DefaultCardHelperReturnState => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isDownloadOpen,
    onOpen: onDownloadOpen,
    onClose: onDownloadClose,
  } = useDisclosure();

  const {
    isOpen: isDiagnosticsOpen,
    onOpen: onDiagnosticsOpen,
    onClose: onDiagnosticsClose,
  } = useDisclosure();

  const {
    isOpen: isPlotLinkOpen,
    onOpen: onPlotLinkOpen,
    onClose: onPlotLinkClose,
  } = useDisclosure();

  const [plotDimensions, setPlotDimensions] = useState({ height: 0, width: 0 });

  // TODO: Maybe keep track of size/aspect ratio of small plots
  // const [smallPlotDimensions, setSmallPlotDimensions] = useState({});

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  return {
    plotModalState: {
      isOpen,
      onOpen,
      onClose,
    },

    diagnosticsModalState: {
      isDiagnosticsOpen,
      onDiagnosticsOpen,
      onDiagnosticsClose,
    },

    downloadModalState: {
      isDownloadOpen,
      onDownloadOpen,
      onDownloadClose,
    },

    plotLinkModalState: {
      isPlotLinkOpen,
      onPlotLinkOpen,
      onPlotLinkClose,
    },

    fullScreenState: {
      isFullScreen,
      setIsFullScreen,
    },

    plotDimensionsState: {
      plotDimensions,
      setPlotDimensions,
    },
  };
};
