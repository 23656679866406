import {
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Text,
} from '@chakra-ui/react';
import { AuthEventIds, emitAuthEvent } from '@revelio/auth';
import { InitiationTrackingEvents, PopoverTrigger } from '@revelio/core';
import { ActionModalControlPanel } from '@revelio/core';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';

export interface TOSPopoverProps {
  trigger: React.ReactNode;
}

export function TOSPopover({ trigger }: TOSPopoverProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Popover placement="top-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <PopoverContent
            data-testid="tos-decline-popover"
            boxShadow="2xl"
            // workaround to fix focus shadow bug
            // ensures popover has box shadow when open
            sx={{
              ':focus:not(:focus-visible)': {
                shadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
              },
            }}
          >
            <PopoverCloseButton />
            <PopoverHeader fontWeight="semibold">Are you sure?</PopoverHeader>
            <PopoverBody>
              <Flex rowGap={2} direction="column">
                <Text fontSize="sm">
                  You must accept our terms to continue using the dashboard.
                </Text>
                <Text fontSize="sm">
                  Clicking "Don't Accept" below will log you out.
                </Text>
              </Flex>
              <Flex pt={4}>
                <ActionModalControlPanel
                  includeReset={false}
                  cancelText="Nevermind"
                  submitText="Don't Accept"
                  customProps={{
                    containerProps: {
                      justifyContent: 'flex-end',
                    },
                    submitButtonProps: {
                      colorScheme: 'red',
                    },
                  }}
                  submitIsLoading={isLoading}
                  onClose={function (): void {
                    onClose();
                  }}
                  onSubmit={function (): void {
                    setIsLoading(true);

                    try {
                      mixpanel.track(InitiationTrackingEvents.DECLINE_TERMS);
                      emitAuthEvent({ id: AuthEventIds.USER_LOGOUT });
                    } catch (err) {
                      //TODO: some err handling
                      console.log(err);
                    } finally {
                      // add a small timeout here so that
                      // the button shows the loading wheel on click
                      // to provide some user feedback.
                      setTimeout(() => {
                        setIsLoading(false);
                      }, 1000);
                    }
                  }}
                />
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default TOSPopover;
