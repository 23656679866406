import {
  StackedBarChartHorizontalData,
  StackedBarChartHorizontalValue,
} from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import { deviation, max, mean } from 'd3-array';
import { isNil } from 'lodash';

const NUM_OF_TOP_SKILLS = 7;

export const getSkillsTopData = ({
  entities,
}: {
  entities: CompositionDataQuery['composition'];
}): StackedBarChartHorizontalData[] => {
  const skillShareLookup: Record<string, number[]> = {};

  /** For each primary entity, calculate the normalized share of each individual skill count over the sum of counts
   * across all skills in the entity.
   * Add each skill share to the lookup table for further analysis */
  const entitiesNormalizedSkills: StackedBarChartHorizontalData[] = (
    entities ?? []
  )
    .map((entity): StackedBarChartHorizontalData | null => {
      const id = entity?.metadata?.id;
      const shortName = entity?.metadata?.shortName;
      const type = entity?.metadata?.type;
      /** TODO: remove filter when middleware removes Other option in response */
      const skills = entity?.metrics?.skills?.category?.filter(
        (skill) => skill?.metadata?.id !== 0
      );

      if (isNil(id) || isNil(shortName) || isNil(type) || isNil(skills)) {
        return null;
      }

      /** Get the sum of count across all skills in this entity */
      const sumOfSkills = skills.reduce(
        (acc, skill) => acc + (skill?.timeseries?.[0]?.count ?? 0),
        0
      );

      /** map each skill's count to its normalized share in the entity */
      const valueWithNormalizedShare: StackedBarChartHorizontalData['value'] =
        skills
          .map((skill): StackedBarChartHorizontalValue | null => {
            const id = skill?.metadata?.id;
            const shortName = skill?.metadata?.shortName;
            const count = skill?.timeseries?.[0]?.count;

            if (isNil(id) || isNil(shortName) || isNil(count)) {
              return null;
            }

            const normalizedShare = count / sumOfSkills;

            /** add this skill's share to the lookup table */
            skillShareLookup[`${id}`] = [
              ...(skillShareLookup[`${id}`] ?? []),
              normalizedShare,
            ];

            return {
              id,
              value: (skill?.timeseries?.[0]?.share ?? 0) / 100,
              width: normalizedShare * 100,
              metadata: { shortName, longName: shortName, count },
            };
          })
          .filter(
            (value): value is StackedBarChartHorizontalValue => value !== null
          );

      return {
        id,
        metadata: { shortName, longName: shortName, type },
        value: valueWithNormalizedShare,
      };
    })
    .filter(
      (entity): entity is StackedBarChartHorizontalData => entity !== null
    );

  /** Calculate the top N skills across all entities as a measurement of each skill's:
   * standard deviation of share
   * plus the max share
   * minus mean share */
  const normalizedSkillsSorted = (() => {
    /** if there is only one company, just return sorted normalized shares */
    if (entitiesNormalizedSkills.length < 2) {
      return Object.entries(skillShareLookup)
        .map(([id, shares]): { id: string; normalizedMax: number } => {
          return { id, normalizedMax: shares[0] };
        })
        .sort((a, b) => b.normalizedMax - a.normalizedMax);
    }

    return Object.entries(skillShareLookup)
      .map(([id, shares]): { id: string; normalizedMax: number } => {
        const shareStd = deviation(shares);
        const shareMax = max(shares);
        const shareMean = mean(shares);

        if (isNil(shareStd) || isNil(shareMax) || isNil(shareMean)) {
          return { id, normalizedMax: 0 };
        }

        return { id, normalizedMax: shareStd + shareMax - shareMean };
      })
      .sort((a, b) => b.normalizedMax - a.normalizedMax);
  })();

  const topSkills = normalizedSkillsSorted
    .slice(0, NUM_OF_TOP_SKILLS)
    .map((skill) => skill.id);

  /** For each entity, return only the top N skills and sort based on topSkills sorting.
   * Also, re-normalize the skill shares based on the remaining top skills */
  const entitiesTopSkills: StackedBarChartHorizontalData[] =
    entitiesNormalizedSkills.map((entity): StackedBarChartHorizontalData => {
      const filteredSortedValues = entity.value
        .filter((value) => topSkills.includes(`${value.id}`))
        .sort(
          (a, b) => topSkills.indexOf(`${a.id}`) - topSkills.indexOf(`${b.id}`)
        );

      const sumOfCounts = filteredSortedValues.reduce(
        (acc, v) => acc + (v.metadata.count ?? 0),
        0
      );

      const normalizedValues = filteredSortedValues.map((v) => ({
        ...v,
        width: ((v.metadata?.count ?? 0) / sumOfCounts) * 100,
      }));

      return {
        ...entity,
        value: normalizedValues,
      };
    });

  return entitiesTopSkills;
};
