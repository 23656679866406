import {
  CardListSelectController,
  ICardListSelectProps,
} from '@revelio/layout';
import { useObservable } from '@ngneat/react-rxjs';
import {
  deliverablesStore,
  updateDraftDeliverable,
} from '../deliverables.repository';
import { selectEntity } from '@ngneat/elf-entities';
import { map } from 'rxjs';
import { difference } from 'lodash';
import { write } from '@revelio/core';
import { PostingFrequency } from '@revelio/data-access';
import { CompletePostingPipeline, Deliverable } from '../deliverables.model';

export const POSTINGS_FREQUENCY_OPTIONS = [
  { id: PostingFrequency.Day, label: 'Day' },
  {
    id: PostingFrequency.Week,
    label: 'Week',
  },
  { id: PostingFrequency.Month, label: 'Month' },
];

export const PostingFrequencySelect = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const [postingFrequency] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          (deliverable?.pipeline as CompletePostingPipeline)
            ?.posting_configuration?.frequency
      )
    ),
    { deps: [entityId] }
  );

  return (
    <CardListSelectController<PostingFrequency>
      variant="columns"
      items={[
        {
          heading: null,
          columns: [
            {
              id: 'posting_frequency',
              label: 'Posting Frequency',
              defaultValue: [PostingFrequency.Month],
              menuItems: POSTINGS_FREQUENCY_OPTIONS.map((option) => ({
                ...option,
              })),
            },
          ],
        },
      ]}
      gridProps={{
        spacing: 6,
        columns: 1,
        gridAutoFlow: 'row',
      }}
      value={postingFrequency ? [postingFrequency] : []}
      onChange={(value: string[]) => {
        const newFrequencySelected = difference(value, [postingFrequency]) as [
          PostingFrequency,
        ];
        updateDraftDeliverable(
          entityId,
          write<Deliverable>((state) => {
            (state.pipeline as CompletePostingPipeline).posting_configuration =
              {
                ...(state.pipeline as CompletePostingPipeline)
                  .posting_configuration,
                frequency: value.length ? newFrequencySelected[0] : undefined,
              };
          })
        );
      }}
      onCloseSubMenu={(
        previouslyCheckedPostingFrequency: ICardListSelectProps<PostingFrequency>[]
      ) => {
        updateDraftDeliverable(
          entityId,
          write<Deliverable>((state) => {
            (state.pipeline as CompletePostingPipeline).posting_configuration =
              {
                ...(state.pipeline as CompletePostingPipeline)
                  .posting_configuration,
                frequency: previouslyCheckedPostingFrequency.length
                  ? (previouslyCheckedPostingFrequency[0]
                      .value as PostingFrequency)
                  : undefined,
              };
          })
        );
      }}
    />
  );
};
