import { createContext, PropsWithChildren, useContext } from 'react';
import { KibanaLogger } from '@revelio/iso-utility';
import type { AuthStore } from '@revelio/auth';

const KibanaLoggerContext = createContext<{
  kibanaLogger: KibanaLogger<AuthStore> | undefined;
}>({ kibanaLogger: undefined });

export const KibanaLoggerProvider = ({
  children,
  kibanaLogger,
}: PropsWithChildren<{ kibanaLogger: KibanaLogger<AuthStore> }>) => {
  return (
    <KibanaLoggerContext.Provider value={{ kibanaLogger }}>
      {children}
    </KibanaLoggerContext.Provider>
  );
};

export const useKibanaLogger = () => useContext(KibanaLoggerContext);
