import { Box } from '@chakra-ui/layout';
import { Menu } from '@chakra-ui/menu';
import { CSSProperties, ReactNode, useState } from 'react';
import RightClickMenu, { MenuOption } from './right-click-menu';

interface RightClickWrapperProps {
  children: ReactNode;
  menuOptions: MenuOption[];
  style: CSSProperties;
}

const RightClickWrapper = ({
  children,
  menuOptions,
  style,
}: RightClickWrapperProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpen(true);
  };
  const handleCloseMenu = () => setMenuOpen(false);

  return (
    <Box onContextMenu={handleRightClick} position="relative" style={style}>
      <Menu
        isOpen={menuOpen}
        closeOnBlur
        closeOnSelect
        onClose={handleCloseMenu}
      >
        {children}
        <RightClickMenu menuOptions={menuOptions} />
      </Menu>
    </Box>
  );
};

export default RightClickWrapper;
