import { ComponentWithAs, Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

/* eslint-disable-next-line */
export interface NodeToggleButtonProps {
  icon: ComponentWithAs<'svg', IconProps>;
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
}

export function NodeToggleButton({
  icon,
  onClick,
  ...props
}: NodeToggleButtonProps) {
  return (
    <Icon
      id="popout-menu"
      data-testid="popout-menu"
      className="revelio-node-submenu-toggle-button-identifier"
      tabIndex={0}
      as={icon}
      aria-label="node toggle button"
      boxSize={6}
      color="text.primary"
      onClick={onClick}
      marginTop="-2px"
      outline="none"
    />
  );
}

export default NodeToggleButton;
