import { PrimaryFilters, Views } from '@revelio/core';
import {
  FilterMenuItemOrConfig,
  FilterSets,
  ROLE_GRANULARITY_FILTERS,
  GEOGRAPHY_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewsForDefaultsOnly,
} from '@revelio/filtering';

export interface IViewFiltersDef {
  primaryViewFilter: (SelectionCategories | FilterMenuItemOrConfig)[];
  selectableFilters: FilterMenuItemOrConfig[];
  filterSet: FilterSets;
  viewForDefaultsOnly?: ViewsForDefaultsOnly;
  isGqlQuery?: boolean;
}

export type IViewFlow = {
  [key in Views]: {
    [key in PrimaryFilters]?: IViewFiltersDef;
  };
};

export const viewflow: IViewFlow = {
  [Views.SENTIMENT_RATING]: {
    [PrimaryFilters.COMPANY]: {
      primaryViewFilter: [
        {
          filters: [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
        // SelectionCategories.SENIORITY,
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      filterSet: FilterSets.COMPANY_SENTIMENT_RATINGS,
      isGqlQuery: true,
    },
    [PrimaryFilters.METRO_AREA]: {
      primaryViewFilter: [
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
        // SelectionCategories.SENIORITY,
        SelectionCategories.INDUSTRY,
      ],
      filterSet: FilterSets.GEOGRAPHY_SENTIMENT_RATINGS,
      isGqlQuery: true,
    },
    [PrimaryFilters.ROLE]: {
      primaryViewFilter: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        SelectionCategories.INDUSTRY,
        // SelectionCategories.SENIORITY,
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      filterSet: FilterSets.ROLE_SENTIMENT_RATINGS,
      isGqlQuery: true,
    },
  },
  // get [Views.SENTIMENT_EFFECT]() {
  //   return this[Views.SENTIMENT_RATING];
  // },
  [Views.SENTIMENT_EFFECT]: {
    [PrimaryFilters.COMPANY]: {
      primaryViewFilter: [
        SelectionCategories.COMPANY,
        SelectionCategories.INDUSTRY,
      ],
      selectableFilters: [
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
        // SelectionCategories.SENIORITY,
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      filterSet: FilterSets.COMPANY_SENTIMENT_EFFECTS,
      viewForDefaultsOnly: ViewsForDefaultsOnly.SENTIMENT_EFFECTS,
    },
    [PrimaryFilters.METRO_AREA]: {
      primaryViewFilter: [
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
        // SelectionCategories.SENIORITY,
        SelectionCategories.INDUSTRY,
      ],
      filterSet: FilterSets.GEOGRAPHY_SENTIMENT_EFFECTS,
      viewForDefaultsOnly: ViewsForDefaultsOnly.SENTIMENT_EFFECTS,
    },
    [PrimaryFilters.ROLE]: {
      primaryViewFilter: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        SelectionCategories.INDUSTRY,
        // SelectionCategories.SENIORITY,
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      filterSet: FilterSets.ROLE_SENTIMENT_EFFECTS,
      viewForDefaultsOnly: ViewsForDefaultsOnly.SENTIMENT_EFFECTS,
    },
  },
  // get [Views.SENTIMENT_REVIEW]() {
  //   return this[Views.SENTIMENT_RATING];
  // },
  [Views.SENTIMENT_REVIEW]: {
    [PrimaryFilters.COMPANY]: {
      primaryViewFilter: [
        SelectionCategories.COMPANY,
        SelectionCategories.INDUSTRY,
      ],
      selectableFilters: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
        SelectionCategories.SENIORITY,
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],

      filterSet: FilterSets.COMPANY_SENTIMENT_REVIEWS,
      viewForDefaultsOnly: ViewsForDefaultsOnly.SENTIMENT_REVIEWS,
    },
    [PrimaryFilters.METRO_AREA]: {
      primaryViewFilter: [
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
        SelectionCategories.SENIORITY,
        SelectionCategories.INDUSTRY,
      ],
      filterSet: FilterSets.GEOGRAPHY_SENTIMENT_REVIEWS,
      viewForDefaultsOnly: ViewsForDefaultsOnly.SENTIMENT_REVIEWS,
    },
    [PrimaryFilters.ROLE]: {
      primaryViewFilter: [
        {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      selectableFilters: [
        SelectionCategories.INDUSTRY,
        SelectionCategories.SENIORITY,
        {
          filters: GEOGRAPHY_GRANULARITY_FILTERS,
          isNested: true,
          limit: 5,
        },
      ],
      filterSet: FilterSets.ROLE_SENTIMENT_REVIEWS,
      viewForDefaultsOnly: ViewsForDefaultsOnly.SENTIMENT_REVIEWS,
    },
  },
  get [Views.JOB_ANALYSIS]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.JOB_ANALYSIS_OVERVIEW]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.LAYOFF]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.NONE]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.ENTITY_SUMMARY]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.OVERVIEW]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.POSTING]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.COMPENSATION]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.SUMMARY]() {
    return this[Views.NONE];
  },
  get [Views.POSTING_COMPANY]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.POSTING_KEYWORD]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.SCREENER]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.SENTIMENT]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.TALENT_DISCOVERY]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.TAXONOMY_VISUALIZER]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.TRANSITION]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.SKILL]() {
    return this[Views.SENTIMENT_RATING];
  },
  get [Views.ACTIVITY_ANALYSIS]() {
    return this[Views.SENTIMENT_RATING];
  },
};
