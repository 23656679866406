import {
  SelectionCategories,
  SelectionListParentMap,
} from '@revelio/filtering';

export const REPORT_BUILDER_SELECTION_LIST_PARENT_MAP: SelectionListParentMap =
  {
    [SelectionCategories.COUNTRY]: SelectionCategories.REGION,
    [SelectionCategories.STATE]: SelectionCategories.COUNTRY,
    [SelectionCategories.METRO_AREA]: SelectionCategories.STATE,
    [SelectionCategories.ROLE_K50]: SelectionCategories.JOB_CATEGORY,
    [SelectionCategories.ROLE_K150]: SelectionCategories.ROLE_K50,
    [SelectionCategories.ROLE_K300]: SelectionCategories.ROLE_K150,
    [SelectionCategories.ROLE_K500]: SelectionCategories.ROLE_K300,
    [SelectionCategories.ROLE_K1000]: SelectionCategories.ROLE_K500,
    [SelectionCategories.ROLE_K1250]: SelectionCategories.ROLE_K1000,
    [SelectionCategories.ROLE_K1500]: SelectionCategories.ROLE_K1250,
    [SelectionCategories.SKILL_K50]: SelectionCategories.SKILL_K25,
    [SelectionCategories.SKILL_K75]: SelectionCategories.SKILL_K50,
    [SelectionCategories.SKILL_K700]: SelectionCategories.SKILL_K75,
    [SelectionCategories.SKILL_K2500]: SelectionCategories.SKILL_K700,
    [SelectionCategories.SKILL_MAPPED]: SelectionCategories.SKILL_K2500,
  };
