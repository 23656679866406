import { CompanySelectionTypes } from '@revelio/core';
import { BaseStepsInterface } from '../data-enrichment/types/dataEnrichmentTypes';
import { SnowflakeInputsWithContext } from './snowflake-inputs';
import MappingStepWithState from '../company-selection/company-mapping/upload-companies/mapping-step-with-state';
import { SnowflakeMapperProvider } from './snowflake-mapper-provider';
import { useContext } from 'react';
import { SnowflakeMapperContext } from './snowflake-mapper-context';
import SnowflakeMapperModal from './snowflake-mapper-modal';
import SnowflakeMapperConfirmation from './snowflake-mapper-confirmation';

interface SnowflakeCompaniesMapperStepProps {
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
}

export const SnowflakeCompanyMapperSteps = ({
  setCompanyColumnSelectionModalOpen,
}: SnowflakeCompaniesMapperStepProps) => {
  const baseSteps: BaseStepsInterface[] = [
    {
      title: 'Snowflake Table',
      description: '',
      content: () => <SnowflakeInputsWithContext />,
    },
    {
      title: 'Map Columns',
      description: '',
      content: () => (
        <MappingStepWithState
          UploadContext={SnowflakeMapperContext}
          Modal={SnowflakeMapperModal}
        />
      ),
    },
    {
      title: 'Confirmation',
      description: '',
      content: () => (
        <SnowflakeMapperConfirmation
          setCompanyColumnSelectionModalOpen={
            setCompanyColumnSelectionModalOpen
          }
        />
      ),
    },
  ];

  return (
    <SnowflakeMapperProvider baseSteps={baseSteps}>
      <SnowflakeMapperActiveStep />
    </SnowflakeMapperProvider>
  );
};

const SnowflakeMapperActiveStep = () => {
  const { activeStep, steps } = useContext(SnowflakeMapperContext);

  return steps[activeStep].content();
};
