import {
  SimpleGrid,
  SimpleGridProps,
  Stack,
  UseCheckboxGroupProps,
} from '@chakra-ui/react';
import {
  CardListColumn,
  CardListColumnProps,
  ColumnItem,
} from './card-list-column';
import { ReactNode, useMemo } from 'react';

export interface ColumnListItemGridProps<CardType extends string>
  extends Pick<
    CardListColumnProps<CardType>,
    'variant' | 'hideCtaIfNotSelected' | 'hideCheckbox' | 'onCloseSubMenu'
  > {
  gridProps?: SimpleGridProps;
  columnItems: ColumnItem<CardType>[];
  checkboxGroupProps: UseCheckboxGroupProps;
  seperator?: ReactNode;
}

export const ColumnListItemGrid = <CardType extends string>({
  gridProps,
  columnItems,
  checkboxGroupProps,
  variant,
  hideCtaIfNotSelected,
  hideCheckbox,
  onCloseSubMenu,
  seperator,
}: ColumnListItemGridProps<CardType>) => {
  const gridConfig: SimpleGridProps = useMemo(() => {
    return { spacing: 6, columns: 2, gridAutoFlow: 'row', ...gridProps };
  }, [gridProps]);

  return (
    <SimpleGrid {...gridConfig}>
      {columnItems.map((column, i) => (
        <Stack key={`${column.id}-${i}`} direction="row" gap="4">
          <CardListColumn<CardType>
            columnItem={column}
            variant={variant}
            checkboxGroupProps={checkboxGroupProps}
            hideCtaIfNotSelected={hideCtaIfNotSelected}
            hideCheckbox={hideCheckbox}
            onCloseSubMenu={onCloseSubMenu}
          />
          {seperator && i !== columnItems.length - 1 ? seperator : null}
        </Stack>
      ))}
    </SimpleGrid>
  );
};
