import { BinaryOperation } from '@revelio/data-access';
import { Condition } from '../dataset-conditions/conditions.model';
import { EMPTY_CASE_CONDITION } from '../dataset-conditions/dataset-conditions-form-helpers';

export type CategoryCase = {
  case: {
    conditions: Condition[];
    binary?: BinaryOperation;
  };
  then: string;
};

export type CustomColumnForm = {
  categoryCases: (CategoryCase | typeof EMPTY_CATEGORY_CASE)[];
  elseCategoryName: string;
  customColumnName: string;
};

export const EMPTY_CATEGORY_CASE = {
  case: {
    conditions: [EMPTY_CASE_CONDITION],
    binary: undefined,
  },
  then: '',
};
