import { createContext, PropsWithChildren, useContext } from 'react';
import { SkillFilterApi, useSkillFiltersApi } from './use-skill-filter-api';
import {
  KeywordFilterApi,
  useKeywordFilterApi,
} from './use-keyword-filter-api';
import { Filter } from '../../engine/filters.model';

export type TalentDiscoveryFilterApi = {
  skillFilterApi: SkillFilterApi;
  keywordFilterApi: KeywordFilterApi;
  breakoutFilterChips: (filters: Filter[]) => Filter[];
};

const TalentDiscoveryFilterContext = createContext<TalentDiscoveryFilterApi>({
  skillFilterApi: {
    skillFilters: [],
    addFilterChip: ({ selections }) => selections,
    updateFilterChip: ({ selections }) => selections,
    removeFilterChip: () => undefined,
    breakoutFilterChips: (filters) => filters,
  },
  keywordFilterApi: {
    keywordFilters: [],
    addKeywordFilterChip: () => undefined,
    breakoutFilterChips: (filters) => filters,
    removeKeywordFilterChip: () => undefined,
  },
  breakoutFilterChips: (filters) => filters,
});

export const TalentDiscoveryFilterProvider = ({
  children,
}: PropsWithChildren) => {
  const skillFilterApi = useSkillFiltersApi();
  const keywordFilterApi = useKeywordFilterApi();

  const { breakoutFilterChips: breakoutSkillFilterChips } = skillFilterApi;

  return (
    <TalentDiscoveryFilterContext.Provider
      value={{
        skillFilterApi,
        keywordFilterApi,
        breakoutFilterChips: (filterItems) =>
          breakoutSkillFilterChips(filterItems as any) as any,
      }}
    >
      {children}
    </TalentDiscoveryFilterContext.Provider>
  );
};

export const useTalentDiscoveryFilterContext = () =>
  useContext(TalentDiscoveryFilterContext);
