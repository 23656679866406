// Function for end of transition for each D3 plot
export const endall = (transition, callback) => {
  if (typeof callback !== 'function')
    throw new Error('Wrong callback in endall');
  if (transition.size() === 0) {
    callback();
  }
  let n = 0;
  transition
    .each(function () {
      ++n;
    })
    .on('end', function () {
      if (!--n) callback.apply(this, arguments);
    });
};
