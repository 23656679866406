import { chakra, HTMLChakraProps } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      {...props}
    >
      <path
        d="M0.198034 4.17549H2.68557V12.1894H0.198034V4.17549ZM1.4418 0.189423C1.72697 0.189423 2.00572 0.274229 2.24282 0.433117C2.47993 0.592004 2.66473 0.817846 2.77386 1.08207C2.88299 1.34629 2.91154 1.63703 2.8559 1.91753C2.80027 2.19802 2.66295 2.45565 2.46131 2.65788C2.25967 2.86011 2.00277 2.99784 1.72309 3.05363C1.44341 3.10942 1.1535 3.08079 0.890049 2.97135C0.626594 2.86191 0.401416 2.67656 0.242988 2.43877C0.0845607 2.20098 0 1.92141 0 1.63542C0 1.25192 0.151907 0.884123 0.422298 0.612947C0.692689 0.341771 1.05941 0.189423 1.4418 0.189423ZM4.24551 12.1894H6.73306V8.22078C6.73306 7.17548 6.92761 6.16155 8.2235 6.16155C9.51939 6.16155 9.51592 7.35667 9.51592 8.28698V12.1894H12V7.78872C12 5.62845 11.5379 3.95597 9.0191 3.95597C8.5463 3.93821 8.07754 4.04936 7.66274 4.27761C7.24795 4.50586 6.90264 4.84267 6.66357 5.25215H6.63231V4.17549H4.24551V12.1894Z"
        fill="currentColor"
      />
    </chakra.svg>
  );
};
