import { SelectedOptionsInterface } from '../data-enrichment/types/dataEnrichmentTypes';

const compareTwoStrings = (first: string | null, second: string) => {
  first = first ? first?.replace(/\s+/g, '') : '';
  second = second?.replace(/\s+/g, '');

  if (first === second) {
    return 1;
  }
  if (first?.length < 2 || second?.length < 2) {
    return 0;
  }

  const firstBigrams = new Map();
  for (let i = 0; i < first?.length - 1; i++) {
    const bigram = first?.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second?.length - 1; i++) {
    const bigram = second?.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (
    (2.0 * intersectionSize) /
    ((first ? first?.length : 0) + second?.length - 2)
  );
};

const findBestMatch = (
  mainString: string | null,
  targetStrings: SelectedOptionsInterface[]
) => {
  const ratings = [];
  let bestMatchIndex = 0;
  const strings = targetStrings.map((option) =>
    option?.label ? option.label : ''
  );

  for (let i = 0; i < strings.length; i++) {
    const currentTargetString = strings[i];
    const currentRating = compareTwoStrings(mainString, currentTargetString);
    ratings.push({ target: currentTargetString, rating: currentRating });
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i;
    }
  }

  const bestMatch = ratings[bestMatchIndex];
  return {
    ratings: ratings,
    bestMatch: bestMatch,
    bestMatchIndex: bestMatchIndex,
  };
};

export const findUniqueBestMatches = (
  defaultStrings: string[],
  availableOptions: SelectedOptionsInterface[]
) => {
  const selected: ({ label: string; value: string } | null)[] = [];
  const remainingOptions = [...availableOptions];
  const filledArr: (string | null)[] = Array.from(defaultStrings, (item) =>
    item === undefined ? null : item
  );
  filledArr.forEach((defaultStr: string | null, index: number) => {
    const { bestMatch, bestMatchIndex } = findBestMatch(
      defaultStr,
      remainingOptions
    );

    if (bestMatch && bestMatch.rating >= 0.5) {
      selected.push(remainingOptions[bestMatchIndex]);
      remainingOptions.splice(bestMatchIndex, 1);
    } else {
      selected.push(null);
    }
  });

  return selected;
};
