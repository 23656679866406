import { Icon } from '@chakra-ui/react';

export const EmailIcon = () => (
  <Icon viewBox="0 0 64 64" boxSize={4}>
    <circle
      cx="32"
      cy="32"
      r="31"
      fill="white"
      style={{ fill: 'white' }}
      opacity={0.8}
    ></circle>
    <path
      d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z"
      fill="#243656"
    ></path>
  </Icon>
);
