import { ContactSalesButton } from '@revelio/composed';
import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import Overview from '../../../overview/overview';
import { useFlag } from '@unleash/proxy-client-react';
import { View } from '@revelio/data-access';

const title = [PageTitles.COMPANY, PageTitles.COMPOSITIONS];

const primaryViewFilter = [
  {
    filters: [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
    isNested: true,
  },
];

export function CompanyCompositions() {
  const keywordFiltersFeatureFlag = useFlag(
    FeatureFlag.CompanyCompositionsKeyword
  );

  const savedFilterSetFeatureFlag = useFlag(FeatureFlag.SavedSets);

  const SHARED_SELECTABLE_FILTERS = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },

    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },

    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    ...(keywordFiltersFeatureFlag
      ? [SelectionCategories.KEYWORD, SelectionCategories.RAW_TITLE]
      : []),
    LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
    {
      ...(savedFilterSetFeatureFlag
        ? [SelectionCategories.SAVED_FILTER_SET]
        : []),
    },
  ];

  const selectableFilters = {
    snapshot: [SelectionCategories.SNAPSHOT_DATE, ...SHARED_SELECTABLE_FILTERS],
    overtime: [SelectionCategories.DATE_RANGE, ...SHARED_SELECTABLE_FILTERS],
  };

  return (
    <Overview
      title={title}
      primaryView={PrimaryView.COMPANY}
      savedSetView={View.Company}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      primaryViewFilterLimit={PrimaryFilterLimits.COMPANY_OVERVIEW}
      filterMenuLimit={FilterMenuLimits.COMPANY_OVERVIEW}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_OVERVIEW}
      disableParentOnPrimaryFilter={false}
      trialNoResultsMessage={
        <>
          Can't find a company?
          <ContactSalesButton
            buttonProps={{
              size: 'sm',
              variant: 'link',
              fontWeight: 400,
              paddingTop: 2,
              paddingLeft: 1,
            }}
            page={PageTitles.COMPOSITIONS}
          />
        </>
      }
    />
  );
}

export default CompanyCompositions;
