import { Center, Icon, IconButton, Portal } from '@chakra-ui/react';
import { LoaderType, PageTitles, UserTrackingEvents } from '@revelio/core';
import mixpanel from 'mixpanel-browser';
import { useLifecycles } from 'react-use';
import DashboardPage from '../DashboardPage';
import { useParams, useSearchParams } from 'react-router-dom';
import { Menu, MenuButton } from '@chakra-ui/menu';
import { FiMoreVertical } from 'react-icons/fi';
import { ReportMenuList } from './components/report-menu-list';
import { defaultAlertDialogDisclosureControl } from '@revelio/layout';
import { useAllReports } from './hooks/use-all-reports';
import { ReportIframe } from './components/report-iframe';

const ReportPage = () => {
  useLifecycles(
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
        page: PageTitles.REPORTS,
      });
      mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    },
    () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: PageTitles.REPORTS,
      });
    }
  );

  const params = useParams();
  const [queryParams] = useSearchParams();

  const reportUserId = queryParams.get('userId') || '';
  const { reports, fetching } = useAllReports({
    selectedUserId: reportUserId,
    isGroupOption: false,
  });
  const report = reports.find((r) => r.id === params.id);

  if (fetching || !report || !report.link) {
    console.log(report);
    const message = !fetching
      ? `Sorry, we couldn't find the requested report.`
      : 'Loading...';
    return (
      <Center h="100%" w="100%">
        {message}
      </Center>
    );
  }

  return (
    <DashboardPage
      title={[PageTitles.REPORTS, report?.name || '']}
      loader={LoaderType.DUAL}
      loaderActiveOnInit={false}
      topRight={
        report &&
        report.reportType !== 'GeneratedByRevelio' && (
          <Menu placement="left-start">
            <MenuButton
              as={IconButton}
              icon={
                <Icon
                  as={FiMoreVertical}
                  data-testid="reports_status_menu_button"
                />
              }
              size="xs"
              colorScheme="gray"
            >
              Actions
            </MenuButton>
            <Portal>
              <ReportMenuList
                reportId={report.id}
                reportType={report.reportType}
                onActionTriggered={(action) => {
                  if (action === 'refresh') {
                    defaultAlertDialogDisclosureControl.next({
                      alertType: 'actionless',
                      isOpen: true,
                      headerText: 'Report Refreshing',
                      bodyContent: (
                        <span>
                          This report is being regenerated and will take a few
                          minutes. You may leave this page.
                        </span>
                      ),
                    });
                  } else if (action === 'delete') {
                    defaultAlertDialogDisclosureControl.next({
                      alertType: 'actionless',
                      isOpen: true,
                      headerText: 'Report Deleted Successfully',
                      bodyContent: (
                        <span>
                          This report has been deleted. You may now leave the
                          page.
                        </span>
                      ),
                    });
                  }
                }}
              />
            </Portal>
          </Menu>
        )
      }
    >
      <ReportIframe link={report.link} />
    </DashboardPage>
  );
};

export default ReportPage;
