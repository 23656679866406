import { BoxProps, Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const GRADIENT_PROPS: BoxProps = {
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.8))',
    pointerEvents: 'none',
  },
};

const SKELETON_WIDTHS = ['30%', '70%', '50%', '90%', '40%', '80%'];
const SKELETON_HEIGHT = '16px';
type VariableLengthSkeletonProps = {
  count: number;
  fade?: boolean;
  delay?: number;
  skeletonWidths?: string[];
  skeletonHeight?: string;
  checkboxes?: boolean;
} & BoxProps;

export const VariableLengthSkeleton = ({
  count = 1,
  fade,
  delay = 0,
  skeletonWidths = SKELETON_WIDTHS,
  skeletonHeight = SKELETON_HEIGHT,
  checkboxes,
  ...restProps
}: VariableLengthSkeletonProps) => {
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  const gradient = fade ? GRADIENT_PROPS : {};
  return showSkeleton ? (
    <Box {...gradient} {...restProps}>
      {Array.from({ length: count }).map((_, index) => (
        <Flex key={index} alignItems="center" alignContent="center" mb={1}>
          {checkboxes && (
            <Skeleton height={SKELETON_HEIGHT} width={SKELETON_HEIGHT} />
          )}
          <Box flex="1" {...(checkboxes && { ml: 2, flex: 1 })}>
            <Skeleton
              width={skeletonWidths[index % skeletonWidths.length]}
              height={SKELETON_HEIGHT}
            />
          </Box>
        </Flex>
      ))}
    </Box>
  ) : null;
};
