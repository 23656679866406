import { Box, Heading, UseCheckboxGroupProps } from '@chakra-ui/react';
import styles from './card-list-select-controller.module.css';
import {
  ColumnListItemGrid,
  ColumnListItemGridProps,
} from './column-list-item-grid';
import { ColumnItem } from './card-list-column';

export type ColumnSet<CardType> = {
  heading: string | null;
  columns: ColumnItem<CardType>[];
  maxColumnsAllowed?: number;
  minColumnsRequired?: number;
  supportsCustomColumns?: boolean;
};

export type CardListSelectControllerProps<CardType extends string> =
  UseCheckboxGroupProps &
    Pick<
      ColumnListItemGridProps<CardType>,
      | 'hideCtaIfNotSelected'
      | 'hideCheckbox'
      | 'variant'
      | 'seperator'
      | 'onCloseSubMenu'
      | 'gridProps'
    > & {
      items: ColumnSet<CardType>[];
      ['data-testid']?: string;
    };

export function CardListSelectController<CardType extends string>(
  props: CardListSelectControllerProps<CardType>
) {
  const {
    items,
    hideCtaIfNotSelected = false,
    hideCheckbox = false,
    gridProps,
    variant,
    seperator,
    onCloseSubMenu,
    'data-testid': data_TestId,
    ...checkboxGroupProps
  } = props;

  return (
    <Box data-testid={data_TestId}>
      {items.map((ds, i) => {
        if (variant === 'columns' && ds.heading) {
          return (
            <fieldset key={i} className={styles['columnFieldSet']}>
              <legend className={styles['columnFieldSetHeading']}>
                {ds.heading ? (
                  <Heading as="h2" fontWeight="600" size="xs" color="gray.500">
                    {ds.heading}
                  </Heading>
                ) : null}
              </legend>
              <Box>
                <ColumnListItemGrid
                  gridProps={gridProps}
                  columnItems={ds.columns}
                  checkboxGroupProps={checkboxGroupProps}
                  variant={variant}
                  hideCtaIfNotSelected={hideCtaIfNotSelected}
                  hideCheckbox={hideCheckbox}
                  onCloseSubMenu={onCloseSubMenu}
                  seperator={seperator}
                />
              </Box>
            </fieldset>
          );
        }

        return (
          <Box key={i} mb="8">
            {ds.heading ? (
              <Heading
                as="h3"
                fontWeight="600"
                size="sm"
                color="text.primary"
                pb="3"
              >
                {ds.heading}
              </Heading>
            ) : null}
            <ColumnListItemGrid
              gridProps={gridProps}
              columnItems={ds.columns}
              checkboxGroupProps={checkboxGroupProps}
              variant={variant}
              hideCtaIfNotSelected={hideCtaIfNotSelected}
              hideCheckbox={hideCheckbox}
              onCloseSubMenu={onCloseSubMenu}
              seperator={seperator}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default CardListSelectController;
