import { FC } from 'react';
import { OptionProps, components } from 'react-select';
import styles from './option.module.css';
import { Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import { Option } from '../selection-list';

export const CustomOption: FC<OptionProps<Option, true>> = (props) => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center">
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <span
          className={classNames(styles.checkmark, {
            [styles.checked]: props.isSelected,
          })}
        ></span>
        <label className={styles.label}>{props.label}</label>
      </Flex>
    </components.Option>
  );
};
