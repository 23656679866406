import { UseCheckboxGroupProps, useCheckboxGroup } from '@chakra-ui/react';
import CardListSubMenu, {
  CardListSubMenuProps,
  OnCloseSubMenuProp,
  isColumnCardListSubmMenu,
} from './card-list-sub-menu';
import CardListSelect, { ICardListSelectProps } from './card-list-select';

export type ColumnItem<CardType> =
  | ICardListSelectProps<CardType>
  | CardListSubMenuProps<CardType>;

export interface CardListColumnProps<CardType> {
  variant: 'dataset' | 'columns' | 'delivery';
  columnItem: ColumnItem<CardType>;
  hideCtaIfNotSelected?: boolean;
  hideCheckbox?: boolean;
  onCloseSubMenu?: OnCloseSubMenuProp<CardType>;
  checkboxGroupProps: UseCheckboxGroupProps;
}

export const CardListColumn = <CardType extends string>({
  columnItem,
  variant,
  hideCtaIfNotSelected,
  hideCheckbox,
  checkboxGroupProps,
  onCloseSubMenu,
}: CardListColumnProps<CardType>) => {
  const { getCheckboxProps } = useCheckboxGroup(checkboxGroupProps);

  if (isColumnCardListSubmMenu(columnItem)) {
    return (
      <CardListSubMenu<CardType>
        variant={variant}
        hideCtaIfNotSelected={hideCtaIfNotSelected}
        hideCheckbox={hideCheckbox}
        onCloseSubMenu={onCloseSubMenu}
        {...columnItem}
        menuItems={columnItem.menuItems.map((col) => ({
          variant,
          hideCtaIfNotSelected,
          hideCheckbox,
          borderColor: 'none',
          ...col,
          ...getCheckboxProps({ value: col.id, isDisabled: col.isDisabled }),
        }))}
      />
    );
  }

  return (
    <CardListSelect<CardType>
      key={columnItem.id}
      hideCtaIfNotSelected={hideCtaIfNotSelected}
      hideCheckbox={hideCheckbox}
      variant={variant}
      {...columnItem}
      {...getCheckboxProps({
        value: columnItem.id,
        isDisabled: columnItem.isDisabled,
      })}
      {...(columnItem.isChecked // this is so you can fix if the card is checked
        ? { isChecked: columnItem.isChecked }
        : {})}
    />
  );
};
