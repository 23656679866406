import { selectEntity, upsertEntities } from '@ngneat/elf-entities';
import { CacheState, createRequestDataSource } from '@ngneat/elf-requests';
import { shareReplay } from 'rxjs/operators';
import { ViewFilterDefaultIds, ViewFilterDefaults } from './filters.model';
import { filterStore, viewFilterDefaultEntitiesRef } from './filters.core';
import { SelectionCategories, OtherFilterNames } from './filters.model';
import { get, isArray, isEmpty, reduce } from 'lodash';
import { DateMonthFilterNames, notMultiFilters } from './filters.constants';

export const viewFiltersDefaultsDataSource = createRequestDataSource({
  data$: (viewViewTypeIds: ViewFilterDefaultIds) =>
    filterStore.pipe(
      selectEntity(viewViewTypeIds, { ref: viewFilterDefaultEntitiesRef }),
      shareReplay({ refCount: true })
    ),
  dataKey: 'viewFilterDefault',
  store: filterStore,
});

export function addViewViewTypeDefault(
  viewViewTypeDefault: ViewFilterDefaults
) {
  const hasAllDefaults = viewViewTypeDefault.full;

  filterStore.update(
    upsertEntities(viewViewTypeDefault, { ref: viewFilterDefaultEntitiesRef }),
    viewFiltersDefaultsDataSource.setSuccess({ key: viewViewTypeDefault.id }),
    viewFiltersDefaultsDataSource.setCached({
      key: viewViewTypeDefault.id,
      value: hasAllDefaults ? 'full' : 'partial',
    })
  );
}

export function clearViewViewTypeDefault(
  viewIdForDefault: ViewFilterDefaultIds | undefined,
  cacheStatus: CacheState['value'] = 'none'
) {
  filterStore.update(
    upsertEntities(
      { id: viewIdForDefault, value: {}, full: false },
      { ref: viewFilterDefaultEntitiesRef }
    ),

    // unset cached status to trigger refetching on reload
    viewFiltersDefaultsDataSource.setCached({
      key: viewIdForDefault,
      value: cacheStatus,
    })
  );
}

export function createCompactDefaults(
  defaultEntry: ViewFilterDefaults & { value: { error?: any } }
) {
  const compactDefaults = reduce(
    defaultEntry.value,
    (result, fD, filterName) => {
      const error = get(fD, 'error');
      if (!isEmpty(fD) && !error) {
        result.filters.push(filterName);
        if (
          DateMonthFilterNames.includes(
            filterName as SelectionCategories | OtherFilterNames
          )
        ) {
          result.value[filterName] = (fD as any).label;
        } else {
          // eslint-disable-next-line no-nested-ternary
          result.value[filterName] = isArray(fD)
            ? fD
            : notMultiFilters.includes(filterName)
              ? fD
              : [fD];
        }
      }
      if (error) {
        result.full = false;
      }
      return result;
    },
    { id: defaultEntry.id, filters: [], value: {}, full: true } as any
  );

  return compactDefaults;
}
