import {
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import { Dispatch } from 'react';
import { ScreenerFilterOption } from '../types';
import {
  ScreenerFilterAction,
  ScreenerFilterState,
} from '../screener-filter-reducer';
import { ScreenerFilterPopover } from '../screener-filter-popover';

interface PrimaryFilterTagProps {
  filterOptions: ScreenerFilterOption[];
  dispatch: Dispatch<ScreenerFilterAction>;
  primaryFilter: ScreenerFilterState['filters']['primary_filter'];
}

export const PrimaryFilterTag = ({
  filterOptions,
  dispatch,
  primaryFilter,
}: PrimaryFilterTagProps) => {
  const primaryFilterNames = Object.values(primaryFilter || {})?.map(
    (filter) => filter.item?.label
  );

  const filterNamesString = primaryFilterNames.join(', ');

  const handlePrimaryFilterClear = () => {
    dispatch({ type: 'REMOVE_PRIMARY_FILTER' });
  };

  const primaryFilterKey = primaryFilter && Object.keys(primaryFilter)[0];
  const selectedFilterOption = filterOptions.find(
    (option) =>
      primaryFilterKey?.includes(option.value) ||
      option.filterGranularities?.some((granularity) =>
        primaryFilterKey?.includes(granularity)
      )
  );

  return (
    <ScreenerFilterPopover
      trigger={
        <Tag
          size="sm"
          variant="solid"
          cursor="pointer"
          height={6}
          borderRadius="4px"
          _hover={{ background: 'white' }}
          as={Button}
          data-testid="primary-filter-tag"
        >
          <TagLabel _hover={{ textDecoration: 'underline' }}>
            <Flex>
              <Text>{selectedFilterOption?.label}</Text>
              <Text mx={0.5}>
                •{primaryFilterNames.length > 1 ? ' is one of ' : ' is '}•
              </Text>
              <Text color="lightBlue.600" fontWeight={600}>
                {filterNamesString}
              </Text>
            </Flex>
          </TagLabel>
          <TagCloseButton
            onClick={handlePrimaryFilterClear}
            data-testid={`primary-filter-tag-remove-${selectedFilterOption?.label}`}
          />
        </Tag>
      }
      filterOptions={filterOptions}
      selectedFilterOverride={selectedFilterOption}
    />
  );
};
