import { chakra, HTMLChakraProps } from '@chakra-ui/react';

export default (props: HTMLChakraProps<'svg'>) => {
  return (
    <chakra.svg
      width="185"
      height="27"
      viewBox="0 0 185 27"
      fill="none"
      {...props}
    >
      <path
        d="M111.385 16.8131L100.935 6.68457C94.8642 6.68457 90.3691 11.238 90.3691 16.8423V16.9007C90.3691 22.5341 94.8934 26.9708 100.877 26.9708C105.868 26.9708 109.809 23.8768 111.064 19.6736C111.327 18.7979 111.473 17.8347 111.473 16.8715L111.385 16.8131ZM100.935 22.213C97.8707 22.213 95.7983 19.7028 95.7983 16.8423V16.7839C95.7983 13.865 97.6955 11.4715 100.848 11.4715C103.504 11.4715 105.401 13.3688 105.868 15.7331C105.927 16.0834 105.985 16.4628 105.985 16.8423V16.9007C105.985 17.3677 105.927 17.8055 105.839 18.2433C105.343 20.5201 103.592 22.213 100.935 22.213Z"
        fill="#2D426A"
      />
      <path
        d="M142.255 9.01924C140.824 7.58899 138.723 6.83008 135.687 6.83008C132.817 6.83008 130.854 7.31615 128.969 8.05763C128.489 8.24672 128.25 8.78173 128.41 9.27267L129.17 11.6007C129.348 12.1479 129.95 12.4273 130.496 12.2449C131.864 11.7874 133.148 11.5587 134.87 11.5587C137.643 11.5587 139.044 12.7846 139.044 15.1197V15.4408C137.614 14.9738 136.271 14.6527 134.286 14.6527C129.674 14.6527 126.434 16.6375 126.434 20.8699V20.9283C126.434 24.8104 129.441 26.8828 133.118 26.8828C135.833 26.8828 137.672 25.8904 139.015 24.4017V25.5033C139.015 26.0556 139.462 26.5033 140.015 26.5033H143.356C143.908 26.5033 144.356 26.0556 144.356 25.5033V15.2365C144.356 12.6095 143.714 10.4787 142.255 9.01924ZM139.131 19.6732C139.131 21.7164 137.409 23.0882 134.811 23.0882C133.031 23.0882 131.805 22.2126 131.805 20.724V20.6656C131.805 18.9143 133.264 17.9802 135.629 17.9802C136.971 17.9802 138.168 18.2721 139.161 18.7099V19.6732H139.131Z"
        fill="#2D426A"
      />
      <path
        d="M158.921 6.68424C156.002 6.68424 154.192 7.99774 152.849 9.83663V0.999999C152.849 0.447714 152.402 0 151.849 0H148.333C147.78 0 147.333 0.447715 147.333 1V25.5326C147.333 26.0849 147.78 26.5326 148.333 26.5326H151.849C152.402 26.5326 152.849 26.0849 152.849 25.5326V23.9932C154.163 25.5694 155.973 26.9121 158.921 26.9121C163.533 26.9121 167.794 23.3511 167.794 16.8419V16.7836C167.765 10.2453 163.445 6.68424 158.921 6.68424ZM162.248 16.8419C162.248 20.1111 160.059 22.2127 157.49 22.2127C154.922 22.2127 152.762 20.0819 152.762 16.8419V16.7836C152.762 13.5436 154.893 11.4128 157.49 11.4128C160.059 11.4128 162.248 13.5728 162.248 16.7836V16.8419Z"
        fill="#2D426A"
      />
      <path
        d="M178.418 14.7687C176.317 14.1266 174.449 13.4552 174.449 12.4336V12.3753C174.449 11.6163 175.149 11.0326 176.492 11.0326C177.675 11.0326 179.233 11.5322 180.85 12.3557C181.349 12.61 181.968 12.4391 182.237 11.9483L183.407 9.82049C183.656 9.36654 183.52 8.79355 183.07 8.53668C181.09 7.40623 178.767 6.771 176.58 6.771C172.639 6.771 169.458 8.98935 169.458 12.959V13.0174C169.458 16.7828 172.552 18.0963 175.266 18.8552C177.397 19.5557 179.294 19.906 179.294 21.1027V21.1611C179.294 22.0367 178.564 22.6205 176.959 22.6205C175.347 22.6205 173.54 22.0556 171.706 20.8884C171.22 20.5788 170.564 20.6944 170.251 21.1783L168.964 23.1641C168.682 23.5996 168.778 24.182 169.206 24.4745C171.644 26.1376 174.324 26.8821 176.813 26.8821C181.045 26.8821 184.256 24.9264 184.256 20.6065V20.5481C184.315 16.9871 181.162 15.6444 178.418 14.7687Z"
        fill="#2D426A"
      />
      <path
        d="M31.2897 17.2797C31.2897 11.763 28.3124 6.71338 21.8033 6.71338C16.1407 6.71338 12.171 11.296 12.171 16.8711V16.9295C12.171 22.884 16.491 26.9996 22.3579 26.9996C25.5021 26.9996 27.8828 25.9355 29.6856 24.0957C30.0612 23.7123 30.0097 23.0983 29.6088 22.7415L27.8162 21.1463C27.4344 20.8065 26.8617 20.8151 26.4588 21.1294C25.1742 22.1317 24.0068 22.5337 22.4163 22.5337C19.9352 22.5337 18.2131 21.2202 17.6585 18.71H30.2853C30.8161 18.71 31.26 18.2956 31.2797 17.7652C31.2859 17.5987 31.2897 17.4373 31.2897 17.2797ZM18.7897 15.2657C18.1687 15.2657 17.6903 14.7007 17.8941 14.1141C18.5357 12.2672 19.8771 11.1209 21.8325 11.1209C23.7947 11.1209 25.118 12.2957 25.6984 14.1431C25.8807 14.7231 25.4049 15.2657 24.7971 15.2657H18.7897Z"
        fill="#2D426A"
      />
      <path
        d="M46.2517 7.06396C45.8227 7.06396 45.4416 7.33753 45.3043 7.74392L41.1265 20.1114L37.0053 7.74774C36.8691 7.33939 36.487 7.06396 36.0566 7.06396H32.4048C31.7005 7.06396 31.2169 7.77258 31.4736 8.42842L38.3675 26.0433C38.5175 26.4266 38.8871 26.6788 39.2987 26.6788H42.9542C43.3658 26.6788 43.7354 26.4266 43.8854 26.0433L50.7794 8.42842C51.036 7.77258 50.5524 7.06396 49.8481 7.06396H46.2517Z"
        fill="#2D426A"
      />
      <path
        d="M69.7326 17.2797C69.7326 11.763 66.7553 6.71338 60.2462 6.71338C54.5836 6.71338 50.6139 11.296 50.6139 16.8711V16.9295C50.6139 22.884 54.9338 26.9996 60.8008 26.9996C63.9447 26.9996 66.3254 25.9357 68.1281 24.0961C68.5039 23.7126 68.4522 23.0983 68.0509 22.7416L66.2882 21.1747C65.9064 20.8353 65.334 20.8441 64.9313 21.1583C63.6465 22.1608 62.4791 22.5629 60.8884 22.5629C58.4073 22.5629 56.656 21.2494 56.1306 18.7392H68.7034C69.2557 18.7392 69.7091 18.2915 69.7247 17.7394C69.7292 17.5813 69.7326 17.4288 69.7326 17.2797ZM57.2325 15.2657C56.6115 15.2657 56.1332 14.7007 56.3369 14.1141C56.9785 12.2672 58.3199 11.1209 60.2754 11.1209C62.2415 11.1209 63.5868 12.3003 64.1545 14.154C64.3312 14.7308 63.8565 15.2657 63.2533 15.2657H57.2325Z"
        fill="#2D426A"
      />
      <path
        d="M78.0505 23.03V1C78.0505 0.447716 77.6028 0 77.0505 0H73.5651C73.0177 0 72.572 0.440151 72.5652 0.98753L72.5338 3.50266V25.5326C72.5338 26.0849 72.9815 26.5326 73.5338 26.5326H76.6786H77.0505C77.6028 26.5326 78.0505 26.0849 78.0505 25.5326V23.03Z"
        fill="#2D426A"
      />
      <path
        d="M87.5491 24.0374L87.4124 8.37553C87.4076 7.82327 86.956 7.37948 86.4037 7.3843L82.7894 7.41584C82.244 7.4206 81.803 7.86151 81.7981 8.4069L81.7843 9.95892L81.921 25.6208C81.9258 26.1731 82.3774 26.6168 82.9297 26.612L86.1683 26.5838L86.5713 26.5802C87.1235 26.5754 87.5673 26.1238 87.5625 25.5716L87.5491 24.0374Z"
        fill="#2D426A"
      />
      <path
        d="M123.632 23.03V1C123.632 0.447716 123.184 0 122.632 0H119.146C118.599 0 118.153 0.440151 118.146 0.98753L118.115 3.50266V25.5326C118.115 26.0849 118.563 26.5326 119.115 26.5326H122.26H122.632C123.184 26.5326 123.632 26.0849 123.632 25.5326V23.03Z"
        fill="#2D426A"
      />
      <path
        d="M5.51669 10.0992V8.0636C5.51669 7.51132 5.06897 7.0636 4.51669 7.0636H1C0.447717 7.0636 0 7.51132 0 8.0636V10.5663V25.5617C0 26.114 0.447715 26.5617 1 26.5617H4.51668C5.06897 26.5617 5.51669 26.114 5.51669 25.5617V19.3521C5.51669 13.4851 7.76422 11.6171 11.442 11.6171C11.7699 11.6171 12.041 11.3616 12.0605 11.0343L12.2579 7.71799C12.2908 7.16418 11.8657 6.68412 11.311 6.69465C8.53687 6.74728 6.53565 7.72165 5.51669 10.0992Z"
        fill="#2D426A"
      />
      <path
        d="M111.442 16.8715H102.861C101.81 16.8715 100.934 16.025 100.934 14.945V6.68457L111.442 16.8715Z"
        fill="#48EB8F"
      />
      <rect
        x="81.8457"
        width="5.64172"
        height="4.83576"
        rx="1"
        fill="#2D426A"
      />
    </chakra.svg>
  );
};
