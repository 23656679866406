import { Link, Placement, Text } from '@chakra-ui/react';
import { Views } from '@revelio/core';
import {
  EndpointSegment,
  EndpointToNameLookup,
  ViewTypes,
} from '@revelio/filtering';
import { startCase } from 'lodash';

export type plotConfigObject = {
  iconBoxSize: number;
  popoverPlacement: Placement;
};

export interface IPlotInfoDef {
  plotInfoBody: React.ReactElement | null;
  plotInfoConfig: plotConfigObject | null;
}

export type IPlotInfo = {
  [key in Views as string]: {
    [key in EndpointSegment as string]: (props?: ViewTypes[]) => IPlotInfoDef;
  };
};

const getShareComp = (vt?: ViewTypes[]) =>
  vt?.includes(ViewTypes.OVERTIME) ? 'share' : 'composition';

const jobPostingsVtMap: { [key in ViewTypes]?: string } = {
  [ViewTypes.COMPANY]: 'roles',
  [ViewTypes.GEO]: 'companies',
  [ViewTypes.SKILLS]: 'companies',
  [ViewTypes.ROLE]: 'skills',
  [ViewTypes.NONE]: '-',
};

export const plotInfo: IPlotInfo = {
  [Views.OVERVIEW]: {
    [EndpointSegment.HEADCOUNT](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            Our estimate of the total workforce. These counts include both
            formal employees and contingent workers. These counts also include
            subsidiaries. More information on the methodologies used for this
            metric can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#sampling-weights"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
            {/* To download a diagnostic document outlining the underlying counts,{' '}
            <DownloadButton
              endpoint="/diagnostics"
              brokenOutFilterIds={[SelectionCategories.PRIMARY_FILTER]}
              isText
            />
            . */}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.GROWTH](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The growth rate is the percent change in the total workforce. It is
            the difference between the hiring rate and attrition rate.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.HIRING](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The annual rate at which workers join, relative to the total
            headcount. It is the 12-month moving sum of inflows divided by the
            12-month moving average of headcount.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.ATTRITION](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The annual rate at which workers leave, relative to the total
            headcount. It is the 12-month moving sum of outflows divided by the
            12-month moving average of headcount.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.TENURE](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average amount of time that workers have spent in their
            position.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.SALARY](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            Our estimate of average salary. More information on the salary model
            can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top-end',
        },
      };
    },
    [EndpointSegment.ROLE](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by occupation. More information on the
            job representation and taxonomy can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#jobs-taxonomy"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.GEO](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by geography.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.SKILL](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by skill. More information on the skill
            model can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#combining-skill-clusters"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.SENIORITY](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by seniority. More information on the
            seniority model can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#seniority"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-end',
        },
      };
    },
    [EndpointSegment.GENDER](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by gender, predicted using first name.
            More information on the gender model can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#gender-and-ethnicity"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.ETHNICITY](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by ethnicity, predicted using first name,
            last name, and geography. More information on the ethnicity model
            can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#gender-and-ethnicity"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.EDUCATION](vt) {
      const shareComp = getShareComp(vt);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The {shareComp} of workers by the highest educational degree.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-end',
        },
      };
    },
    [EndpointSegment.INDUSTRY](vt) {
      const shareComp = getShareComp(vt);
      const isRole = vt?.includes(ViewTypes.ROLE);
      return {
        plotInfoBody: (
          <Text variant="tooltip">The {shareComp} of workers by industry.</Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: isRole ? 'top-start' : 'top',
        },
      };
    },
  },
  [Views.TRANSITION]: {
    [EndpointSegment.TRANSITION](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The most common sources of incoming workers and the most common
            destinations of outgoing workers.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.ROLE](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.ROLE](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.GEO](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.GEO](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.SENIORITY](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.SENIORITY](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.GENDER](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.GENDER](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.ETHNICITY](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.ETHNICITY](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.EDUCATION](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.EDUCATION](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.INDUSTRY](vt) {
      return {
        plotInfoBody:
          plotInfo[Views.OVERVIEW][EndpointSegment.INDUSTRY](vt).plotInfoBody,
        plotInfoConfig: {
          iconBoxSize: 2.5,
          popoverPlacement: 'top',
        },
      };
    },
  },
  [Views.POSTING]: {
    [EndpointSegment.POSTING](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The number of active job postings, new job postings, and removed job
            postings.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.SALARY](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average salary listed on job postings.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.TIMETOFILL](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average number of days that job postings are active before being
            removed.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.TOP](vt) {
      const singleVt = vt?.[0] || ViewTypes.NONE;
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The top 15 {jobPostingsVtMap[singleVt]} in terms of number of added
            postings over the selected time range.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-end',
        },
      };
    },
  },
  [Views.SKILL]: {
    [EndpointSegment.GROWTH_GROUPED](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The year-over-year growth rate of skills in the workforce. This
            chart displays the top fastest growing and top fastest shrinking
            skills. More information on the skill representation and taxonomy
            can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#skill-clustering"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.SHARES_SNAPSHOT](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The share of workers with the most common skills. More information
            on the skill representation and taxonomy can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#skill-clustering"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.SHARES_OVERTIME](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The share of workers by skill. More information on the skill
            representation and taxonomy can be found{' '}
            <Link
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#skill-clustering"
              isExternal
              variant="tooltip"
            >
              here
            </Link>
            .
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.KEYWORD_OVERTIME](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The number of active job postings containing a given skill.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
  },
  [Views.SENTIMENT_RATING]: {
    [EndpointSegment.OVERALL](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by workers on the topic of{' '}
            {EndpointToNameLookup[
              EndpointSegment.OVERALL || 'thisKeyDoesntExist'
            ] || startCase(EndpointSegment.OVERALL)}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.EFFECT](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            Using the raw text of reviews, we identify topics and themes from
            the text and compare those to hiring and attrition trends to see
            reasons why people are joining or leaving.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.BUSINESS](vt) {
      const heading =
        EndpointToNameLookup[
          EndpointSegment.BUSINESS || 'thisKeyDoesntExist'
        ] || startCase(EndpointSegment.BUSINESS);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.CAREER](vt) {
      const heading =
        EndpointToNameLookup[EndpointSegment.CAREER || 'thisKeyDoesntExist'] ||
        startCase(EndpointSegment.CAREER);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-end',
        },
      };
    },
    [EndpointSegment.COMPENSATION](vt) {
      const heading =
        EndpointToNameLookup[
          EndpointSegment.COMPENSATION || 'thisKeyDoesntExist'
        ] || startCase(EndpointSegment.COMPENSATION);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.WORKLIFE](vt) {
      const heading =
        EndpointToNameLookup[
          EndpointSegment.WORKLIFE || 'thisKeyDoesntExist'
        ] || startCase(EndpointSegment.WORKLIFE);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.CULTURE](vt) {
      const heading =
        EndpointToNameLookup[EndpointSegment.CULTURE || 'thisKeyDoesntExist'] ||
        startCase(EndpointSegment.CULTURE);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-end',
        },
      };
    },
    [EndpointSegment.LEADERSHIP](vt) {
      const heading =
        EndpointToNameLookup[
          EndpointSegment.LEADERSHIP || 'thisKeyDoesntExist'
        ] || startCase(EndpointSegment.LEADERSHIP);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
    [EndpointSegment.DIVERSITY](vt) {
      const heading =
        EndpointToNameLookup[
          EndpointSegment.DIVERSITY || 'thisKeyDoesntExist'
        ] || startCase(EndpointSegment.DIVERSITY);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.RECOMMEND](vt) {
      const heading =
        EndpointToNameLookup[
          EndpointSegment.RECOMMEND || 'thisKeyDoesntExist'
        ] || startCase(EndpointSegment.RECOMMEND);
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            The average score given by employees on the topic of {heading}
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-end',
        },
      };
    },
  },
  [Views.SENTIMENT_EFFECT]: {
    [EndpointSegment.EFFECT](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            Using the raw text of reviews, we extract topics from the text.
            These topics are then compared to source and destination companies
            to identify why workers join or leave.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top-start',
        },
      };
    },
  },
  [Views.SENTIMENT_REVIEW]: {
    [EndpointSegment.POSITIVE](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            Words used in positive reviews, weighted by prevalence.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
    [EndpointSegment.NEGATIVE](vt) {
      return {
        plotInfoBody: (
          <Text variant="tooltip">
            Words used in negative reviews, weighted by prevalence.
          </Text>
        ),
        plotInfoConfig: {
          iconBoxSize: 3,
          popoverPlacement: 'top',
        },
      };
    },
  },
};
