//import 'stop-runaway-react-effects/hijack';

import { createElement, useEffect } from 'react';
import { get, isPlainObject, isUndefined } from 'lodash';
import { useSingleOrMoreFilterState } from '../../../engine/filters.engine';
import {
  AnyFilter,
  FilterOrSubfilterName,
  TalentDiscoveryCompanyValueType,
} from '../../../engine/filters.model';
import { TDStateConfig } from '../talent-discovery-filter-menu.config';
import FreeText from '../../free-text/free-text';

export interface TDCreatableSelectProps {
  name: string;
  keyName: string;
  placeholderText: string;
  TDState: TDStateConfig;
  setTDState: React.Dispatch<React.SetStateAction<TDStateConfig>>;
  selectionCategory: FilterOrSubfilterName | FilterOrSubfilterName[];

  // TODO: Will need to come back and properly type this.
  /* eslint-disable-next-line */
  props: any;
  defaultState?: Partial<TDStateConfig>;
}
export const TDCreatableSelect = ({
  name,
  keyName,
  placeholderText,
  TDState,
  setTDState,
  selectionCategory,
  props,
  defaultState,
}: TDCreatableSelectProps) => {
  const [activeState, error] =
    useSingleOrMoreFilterState<AnyFilter[]>(selectionCategory);

  useEffect(() => {
    if (error) return;

    const defaults = defaultState || {};

    const updatedState = { ...defaults };
    // TODO: fix typings here
    activeState?.reduce((acc: any, cur: any) => {
      const value = get(cur, 'value', {} as TalentDiscoveryCompanyValueType);

      const { keyName, companyValue = [] } = value;

      const currentCompanyValue = get(updatedState, 'companyValue', {});

      updatedState['companyValue'] = {
        ...currentCompanyValue,
        [keyName]: { value: companyValue },
      };

      return { ...acc, ...updatedState };
    }, {});

    setTDState((prevState: TDStateConfig) => {
      const mergedState: TDStateConfig = { ...prevState };
      Object.entries(updatedState).forEach(([key, value]: any) => {
        const previousVal = get(prevState, key);

        if (isUndefined(previousVal)) {
          mergedState[key] = value;
          return;
        }

        if (isPlainObject(value)) {
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            mergedState[key][innerKey] = innerValue;
          });
        }
      });

      return mergedState;
    });
  }, [activeState, defaultState, error, setTDState]);

  return (
    <>
      {/* TODO: properly type create element */}
      {/* eslint-disable-next-line */}
      {createElement<any>(FreeText, {
        key: name,
        placeholder: placeholderText || name,
        value: get(TDState, `companyValue.${keyName}.value`, ''),
        onChange: (e: Event) => {
          setTDState((prevState: TDStateConfig) => {
            const previousCompanyValue = get(prevState, 'companyValue', {});

            return {
              ...prevState,
              companyValue: {
                ...previousCompanyValue,
                [keyName]: {
                  filterName: selectionCategory[0],
                  value: e,
                },
              },
            };
          });
        },
        noOptionsMessage: () => 'Start typing to add a company',
        formatCreateLabel: (inputText: string) => `"Add ${inputText}"`,
        ...props[0],
      })}
    </>
  );
};
