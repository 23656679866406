export const RevelioWatermark = `<svg width="85" height="13" viewBox="0 0 85 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M51.3844 7.7564L46.5638 3.08398C43.7631 3.08398 41.6895 5.18455 41.6895 7.76987V7.7968C41.6895 10.3956 43.7766 12.4423 46.5369 12.4423C48.8395 12.4423 50.6573 11.015 51.2363 9.07599C51.3575 8.67203 51.4248 8.22768 51.4248 7.78333L51.3844 7.7564ZM46.5638 10.2475C45.15 10.2475 44.194 9.08945 44.194 7.76987V7.74294C44.194 6.39642 45.0692 5.29227 46.5235 5.29227C47.7488 5.29227 48.624 6.16751 48.8395 7.25819C48.8664 7.41977 48.8933 7.59482 48.8933 7.76987V7.7968C48.8933 8.01224 48.8664 8.21422 48.826 8.4162C48.5971 9.46648 47.7892 10.2475 46.5638 10.2475Z" fill="#C8CBD0"/>
<path d="M65.6243 4.16126C64.9645 3.50146 63.995 3.15137 62.5946 3.15137C61.3038 3.15137 60.4104 3.36456 59.5607 3.69229C59.3015 3.79227 59.1731 4.08047 59.2593 4.34459L59.5629 5.27476C59.659 5.56914 59.9823 5.71882 60.277 5.62392C60.88 5.42979 61.4558 5.33273 62.2176 5.33273C63.4968 5.33273 64.1431 5.89826 64.1431 6.97548V7.1236C63.4833 6.90815 62.8639 6.76004 61.9483 6.76004C59.8208 6.76004 58.3262 7.67567 58.3262 9.62812V9.65505C58.3262 11.4459 59.7131 12.4019 61.4097 12.4019C62.662 12.4019 63.5103 11.9441 64.1297 11.2574V11.6889C64.1297 11.986 64.3705 12.2269 64.6676 12.2269H66.0558C66.3529 12.2269 66.5938 11.986 66.5938 11.6889V7.02934C66.5938 5.81747 66.2976 4.83451 65.6243 4.16126ZM64.1835 9.07605C64.1835 10.0186 63.3891 10.6515 62.1907 10.6515C61.3693 10.6515 60.8038 10.2475 60.8038 9.56079V9.53386C60.8038 8.72595 61.477 8.29507 62.5677 8.29507C63.1871 8.29507 63.7392 8.42972 64.197 8.6317V9.07605H64.1835Z" fill="#C8CBD0"/>
<path d="M73.3115 3.08353C71.965 3.08353 71.1301 3.68946 70.5107 4.53777V0.537976C70.5107 0.24086 70.2699 0 69.9728 0H68.5038C68.2067 0 67.9658 0.24086 67.9658 0.537976V11.7019C67.9658 11.999 68.2067 12.2398 68.5038 12.2398H69.9728C70.2699 12.2398 70.5107 11.999 70.5107 11.7019V11.0684C71.1167 11.7955 71.9515 12.4149 73.3115 12.4149C75.439 12.4149 77.4049 10.7721 77.4049 7.76941V7.74248C77.3914 4.72628 75.3986 3.08353 73.3115 3.08353ZM74.8465 7.76941C74.8465 9.27751 73.8366 10.247 72.6517 10.247C71.4668 10.247 70.4703 9.26404 70.4703 7.76941V7.74248C70.4703 6.24784 71.4533 5.26489 72.6517 5.26489C73.8366 5.26489 74.8465 6.26131 74.8465 7.74248V7.76941Z" fill="#C8CBD0"/>
<path d="M82.3075 6.81348C81.338 6.51725 80.4762 6.20755 80.4762 5.73627V5.70934C80.4762 5.35924 80.7993 5.08994 81.4187 5.08994C81.9475 5.08994 82.6385 5.30625 83.3591 5.66512C83.6288 5.79945 83.9617 5.70791 84.1068 5.44387L84.5748 4.59227C84.7091 4.34806 84.6357 4.03948 84.3921 3.90412C83.4924 3.40418 82.4458 3.12402 81.4591 3.12402C79.6413 3.12402 78.1736 4.14738 78.1736 5.97864V6.00557C78.1736 7.74258 79.6009 8.34851 80.8532 8.69861C81.8362 9.02177 82.7114 9.18336 82.7114 9.73543V9.76236C82.7114 10.1663 82.3748 10.4356 81.6342 10.4356C80.9109 10.4356 80.1023 10.189 79.28 9.67989C79.0162 9.5166 78.6639 9.5784 78.4952 9.83874L77.9842 10.6274C77.8324 10.8617 77.8839 11.1753 78.1161 11.3303C79.2248 12.0699 80.4386 12.4015 81.5669 12.4015C83.5193 12.4015 85.0005 11.4994 85.0005 9.50652V9.47959C85.0274 7.83684 83.5732 7.21744 82.3075 6.81348Z" fill="#C8CBD0"/>
<path d="M14.4349 7.97156C14.4349 5.42664 13.0615 3.09717 10.0587 3.09717C7.4465 3.09717 5.61523 5.2112 5.61523 7.78305V7.80998C5.61523 10.5569 7.60808 12.4555 10.3146 12.4555C11.735 12.4555 12.8177 11.9847 13.643 11.168C13.8482 10.9649 13.8202 10.6341 13.6045 10.4422L12.884 9.80105C12.6786 9.61827 12.3711 9.62374 12.1517 9.78947C11.5804 10.2211 11.0526 10.3953 10.3415 10.3953C9.19697 10.3953 8.40253 9.78936 8.14669 8.63136H13.8991C14.1846 8.63136 14.4248 8.40846 14.4327 8.12305C14.4341 8.07161 14.4349 8.02118 14.4349 7.97156ZM8.75969 7.04247C8.42561 7.04247 8.1671 6.73756 8.2866 6.42558C8.59366 5.62392 9.20074 5.13041 10.0722 5.13041C10.9474 5.13041 11.5471 5.63705 11.8279 6.44091C11.9357 6.74965 11.6786 7.04247 11.3515 7.04247H8.75969Z" fill="#C8CBD0"/>
<path d="M21.3921 3.25928C21.1614 3.25928 20.9563 3.40645 20.8825 3.62508L18.9728 9.27821L17.0891 3.62713C17.0159 3.40745 16.8103 3.25928 16.5787 3.25928H15.0617C14.6828 3.25928 14.4226 3.64049 14.5607 3.99332L17.681 11.966C17.7617 12.1722 17.9605 12.3079 18.1819 12.3079H19.7636C19.9851 12.3079 20.1839 12.1722 20.2646 11.966L23.3849 3.99332C23.5229 3.64049 23.2628 3.25928 22.8839 3.25928H21.3921Z" fill="#C8CBD0"/>
<path d="M32.1683 7.97156C32.1683 5.42664 30.7949 3.09717 27.7921 3.09717C25.1799 3.09717 23.3486 5.2112 23.3486 7.78305V7.80998C23.3486 10.5569 25.3415 12.4555 28.048 12.4555C29.4683 12.4555 30.551 11.9848 31.3762 11.1682C31.5815 10.9651 31.5534 10.6341 31.3375 10.4422L30.6309 9.81412C30.4254 9.63154 30.1181 9.63709 29.8988 9.80277C29.3274 10.2345 28.7996 10.4088 28.0884 10.4088C26.9438 10.4088 26.1359 9.80283 25.8936 8.64482H31.6169C31.914 8.64482 32.1601 8.40404 32.1667 8.10699C32.1677 8.06101 32.1683 8.01596 32.1683 7.97156ZM26.4931 7.04247C26.159 7.04247 25.9005 6.73756 26.02 6.42558C26.3271 5.62392 26.9341 5.13041 27.8056 5.13041C28.6829 5.13041 29.2923 5.63947 29.5682 6.44665C29.6732 6.75373 29.4166 7.04247 29.0921 7.04247H26.4931Z" fill="#C8CBD0"/>
<path d="M36.0049 10.624V0.537977C36.0049 0.240861 35.764 0 35.4669 0H34.0114C33.7169 0 33.4772 0.236791 33.4735 0.531267L33.46 1.61582V11.7019C33.46 11.999 33.7008 12.2398 33.9979 12.2398H35.372H35.4669C35.764 12.2398 36.0049 11.999 36.0049 11.7019V10.624Z" fill="#C8CBD0"/>
<path d="M40.3874 11.0889L40.325 3.94054C40.3224 3.64343 40.0794 3.40468 39.7823 3.40728L38.2669 3.4205C37.9735 3.42306 37.7363 3.66026 37.7337 3.95367L37.728 4.59431L37.7904 11.7427C37.793 12.0398 38.0359 12.2785 38.333 12.2759L39.7504 12.2636L39.8596 12.2626C40.1567 12.26 40.3955 12.0171 40.3929 11.72L40.3874 11.0889Z" fill="#C8CBD0"/>
<path d="M57.0322 10.624V0.537977C57.0322 0.240861 56.7914 0 56.4942 0H55.0388C54.7443 0 54.5045 0.236791 54.5008 0.531267L54.4873 1.61582V11.7019C54.4873 11.999 54.7282 12.2398 55.0253 12.2398H56.3994H56.4942C56.7914 12.2398 57.0322 11.999 57.0322 11.7019V10.624Z" fill="#C8CBD0"/>
<path d="M2.54492 4.65903V3.79663C2.54492 3.49951 2.30406 3.25865 2.00694 3.25865H0.537975C0.240859 3.25865 0 3.49951 0 3.79663V4.87447V11.7154C0 12.0125 0.24086 12.2534 0.537976 12.2534H2.00694C2.30406 12.2534 2.54492 12.0125 2.54492 11.7154V8.92749C2.54492 6.22099 3.58174 5.35922 5.27835 5.35922V5.35922C5.4296 5.35922 5.55467 5.24138 5.56366 5.09039L5.65013 3.63755C5.66787 3.33961 5.43929 3.08065 5.14099 3.09043C3.90115 3.1311 3.00551 3.58432 2.54492 4.65903Z" fill="#C8CBD0"/>
<path d="M51.41 7.78333H47.4512C46.9665 7.78333 46.5625 7.39284 46.5625 6.89463V3.08398L51.41 7.78333Z" fill="#DFE2E7"/>
<rect x="37.7578" width="2.6026" height="2.2308" rx="0.537976" fill="#C8CBD0"/>
</svg>`;
