import { PipelineColumnType } from '@revelio/data-access';
import { CardListSubMenuProps } from '@revelio/layout';

export const addDefaultIsSelectedToSubMenu = ({
  columnSubMenu,
  includeColumns,
}: {
  columnSubMenu: CardListSubMenuProps<PipelineColumnType>;
  includeColumns?: PipelineColumnType[];
}) => {
  return {
    ...columnSubMenu,
    menuItems: columnSubMenu.menuItems.map((item) => ({
      ...item,
      defaultIsSelected: !includeColumns || includeColumns.includes(item.id),
    })),
  };
};
