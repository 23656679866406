import { mapValues, pick } from 'lodash';
import {
  Button,
  Flex,
  FormControl,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
import {
  FilterPopover,
  SelectionCategories,
  SelectionCategoryLabelOverrides,
  SelectionListParentMap,
  TreeRef,
  TreeType,
} from '@revelio/filtering';
import { PlusMinusIcon } from '@revelio/layout';
import { useState } from 'react';
import { FormInputMimicBox } from './FormInputMimicBox';
import {
  SharedConditionValueProps,
  SimpleFormTreeItem,
} from './conditions.model';

export function SelectionListValueSelect({
  selectionList,
  isMultiGranularitySelectionListVariable,
  conditionId,
  selectedValues,
  setSelections,
  clearError,
  onNoValues,
  isDisabled,
  hasError,
}: {
  selectionList: SelectionCategories[];
  isMultiGranularitySelectionListVariable: boolean;
} & SharedConditionValueProps<SimpleFormTreeItem>) {
  const [tempSelections, setTempSelections] = useState<
    Record<string, SimpleFormTreeItem>
  >({});
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <FilterPopover
      openHandler={() => {
        setIsEditing(true);
      }}
      triggerOnClose={() => {
        setIsEditing(false);
      }}
      resetBtn={
        <Button
          variant="ghost"
          size="sm"
          fontSize="12px"
          colorScheme="red"
          flexShrink={0}
          onClick={() => {
            setTempSelections({});
          }}
        >
          Clear Selections
        </Button>
      }
      handleSubmit={() => {
        if (Object.keys(tempSelections).length) {
          clearError();
        } else {
          onNoValues();
        }
        setSelections(
          Object.keys(tempSelections)
            .map((key) => tempSelections[key])
            .filter((x) => x) as SimpleFormTreeItem[]
        );
      }}
      menuWidth={360}
      lazy={true}
      isDisabled={isDisabled}
      triggerElement={
        <FormInputMimicBox
          isEditing={isEditing}
          isDisabled={isDisabled}
          hasError={hasError}
        >
          <Flex
            columnGap="3px"
            alignItems="center"
            height="100%"
            wrap="wrap"
            rowGap="0.5rem"
          >
            {selectedValues.map((conditionValue) => {
              return (
                <Tag
                  key={`${conditionId}-${conditionValue}`}
                  variant="filterChip"
                  data-testid={`condition-${conditionId}-${conditionValue}`}
                  size="sm"
                >
                  <TagLabel>
                    <Flex alignItems="center">
                      {conditionValue.item?.label}{' '}
                      {isMultiGranularitySelectionListVariable
                        ? `(${
                            SelectionCategoryLabelOverrides[
                              conditionValue.selectionListId
                            ]
                          })`
                        : ''}
                    </Flex>
                  </TagLabel>
                  <TagCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      const selectedValuesWithDeletion = selectedValues.filter(
                        (value) => value !== conditionValue
                      );
                      setSelections(selectedValuesWithDeletion);
                      if (!selectedValuesWithDeletion.length) {
                        onNoValues();
                      }
                    }}
                  />
                </Tag>
              );
            })}
            <Button
              variant={'addcompany'}
              leftIcon={<PlusMinusIcon />}
              display="flex"
              isDisabled={isDisabled}
            >
              <span style={{ marginLeft: '-2px' }}>Value</span>
            </Button>
          </Flex>
        </FormInputMimicBox>
      }
      closeOnBlur={false}
      closeOnOutsideClick={true}
    >
      <FormControl isInvalid={false}>
        {/* this is necessary to opt out of the parent field form control making the tree show as erroneous */}
        <TreeRef
          showHeader={true}
          selectionLists={selectionList}
          selectionListParentMap={DATA_BUILDER_SELECTION_LIST_PARENT_MAP}
          nestingTreeType={TreeType.BREADCRUMB_NESTED}
          setTempSelections={(treeSelections) => {
            setTempSelections(
              mapValues(treeSelections, (tItem) =>
                pick(tItem, ['id', 'item', 'selectionListId', 'parentId'])
              )
            );
          }}
          defaultSelectedItemIds={selectedValues.map((i) => i.id) as string[]}
        />
      </FormControl>
    </FilterPopover>
  );
}
const DATA_BUILDER_SELECTION_LIST_PARENT_MAP: SelectionListParentMap = {
  [SelectionCategories.COUNTRY]: SelectionCategories.REGION,
  [SelectionCategories.STATE]: SelectionCategories.COUNTRY,
  [SelectionCategories.METRO_AREA]: SelectionCategories.STATE,
  [SelectionCategories.ROLE_K50]: SelectionCategories.JOB_CATEGORY,
  [SelectionCategories.ROLE_K150]: SelectionCategories.ROLE_K50,
  [SelectionCategories.ROLE_K300]: SelectionCategories.ROLE_K150,
  [SelectionCategories.ROLE_K500]: SelectionCategories.ROLE_K300,
  [SelectionCategories.ROLE_K1000]: SelectionCategories.ROLE_K500,
  [SelectionCategories.ROLE_K1250]: SelectionCategories.ROLE_K1000,
  [SelectionCategories.ROLE_K1500]: SelectionCategories.ROLE_K1250,
  [SelectionCategories.SKILL_K50]: SelectionCategories.SKILL_K25,
  [SelectionCategories.SKILL_K75]: SelectionCategories.SKILL_K50,
  [SelectionCategories.SKILL_K700]: SelectionCategories.SKILL_K75,
  [SelectionCategories.SKILL_K2500]: SelectionCategories.SKILL_K700,
  [SelectionCategories.SKILL_MAPPED]: SelectionCategories.SKILL_K2500,
};

export default SelectionListValueSelect;
