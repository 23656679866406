import { Box, Text } from '@chakra-ui/layout';
import { useMultiStyleConfig } from '@chakra-ui/system';
import { capitalize } from 'lodash';
import { ParsedResume } from '../resumeTypes';

interface SkillsEnrichedProps {
  variant?: string;
  data?: ParsedResume['skills']['en'];
}

export default function SkillsEnriched({ data, variant }: SkillsEnrichedProps) {
  const styles = useMultiStyleConfig('Resume', {
    variant: variant || 'resume',
  });
  return (
    <Box sx={styles['gridBox']}>
      {!!data?.length && (
        <Box sx={styles['enrichedData']}>
          <Text sx={styles['enrichedDataLabel']}>Skills</Text>
          <Text sx={styles['enrichedDataValue']}>
            {data?.map((skill) => capitalize(skill)).join(' | ')}
          </Text>
        </Box>
      )}
    </Box>
  );
}
