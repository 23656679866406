import { PageTitles, PrimaryFilters, Views } from '@revelio/core';
import {
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
  SelectionCategories,
} from '@revelio/filtering';
import { Sentiment } from '../../../sentiment/Sentiment';

/* eslint-disable-next-line */
export interface GeographySentimentProps {}

export function GeographySentiment(props: GeographySentimentProps) {
  const title = [PageTitles.GEOGRAPHY, PageTitles.SENTIMENT];

  const primaryViewFilters = [
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 1,
    },
  ];
  const selectableFilters = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: INDUSTRY_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
  ];

  return (
    <Sentiment
      title={title}
      view={Views.SENTIMENT}
      viewType={ViewTypes.GEO}
      primaryFilter={PrimaryFilters.GEOGRAPHY}
      sharedFilterSetId={FilterSets.GEOGRAPHY}
      filterSet={FilterSets.GEOGRAPHY_SENTIMENT}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.SENTIMENT}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.SENTIMENT}
      enableGptSummaryReviews={true}
    />
  );
}

export default GeographySentiment;
