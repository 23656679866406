export enum CompanySelectionTypes {
  SELECT_OPTION = 'select_option',
  COMMON_SET = 'common_set',
  DATABASE_REF = 'database_ref',
  COMPANIES_LIST = 'companies_list',
  SUBSIDIARY_MAPPING = 'subsidiary_mapping',
  UPLOAD_COMPANIES = 'upload_companies',
  COMPANY_MAPPING = 'company_mapping',
}

export type SubsidiaryMappingWorkflow = {
  previousStep: CompanySelectionTypes | null;
  mappings: ColumnMappings;
};

export type ColumnMappings = {
  // the key will be either 'primary' for the first column or a companyID of the company that was selected
  [key: string]: ColumnMappingValues;
};

export type ColumnMappingValues = {
  title: string;
  name: string;
  selectAll: boolean;
  subsidiaries: Subsidiary[];
  isLoading: boolean;
};

export type Subsidiary = {
  title: string;
  id: SubsidiaryMappingKeys;
  hasSubsidiaries: boolean;
  rcid: number;
  headCount: number;
  isSelected?: boolean;
  isIndeterminate?: boolean;
  company_id?: string;
};

export type SubsidiaryMappingKeys = keyof ColumnMappings;

export type FinalMappedData = {
  name: string | number;
  rcid: number;
  child_rcids: number[];
  expand_rcids: number[];
  company_id?: string;
};
