import {
  Button,
  Flex,
  FormControl,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react';
import {
  convertListIdBreadcrumb,
  FilterPopover,
  getSelectionListItems,
  nestSelectionHierarchy,
  SelectionCategories,
  SelectionCategoryLabelOverrides,
  SelectionListItems,
  useSelectionLists,
} from '@revelio/filtering';
import { PlusMinusIcon } from '@revelio/layout';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormInputMimicBox } from './FormInputMimicBox';
import {
  SharedConditionValueProps,
  SimpleFormTreeItem,
} from './conditions.model';
import { BreadcrumbTree } from '@revelio/filtering';

export const SelectionListValueSelectNested = ({
  selectionList,
  isMultiGranularitySelectionListVariable,
  conditionId,
  selectedValues,
  setSelections,
  clearError,
  onNoValues,
  isDisabled,
  hasError,
}: {
  selectionList: SelectionCategories[];
  isMultiGranularitySelectionListVariable: boolean;
} & SharedConditionValueProps<SimpleFormTreeItem>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const selectionLists = useSelectionLists(
    selectionList
  ) as SelectionListItems[];
  const nestedSelectionTree = useMemo(
    () => nestSelectionHierarchy(selectionLists),
    [selectionLists]
  );

  const tempSelectedIds = useRef<string[]>([]);
  const handleChange = useCallback((selections: string[]) => {
    tempSelectedIds.current = selections;
  }, []);

  return (
    <FilterPopover
      openHandler={() => {
        setIsEditing(true);
      }}
      triggerOnClose={() => {
        setIsEditing(false);
      }}
      resetBtn={
        <Button
          variant="ghost"
          size="sm"
          fontSize="12px"
          colorScheme="red"
          flexShrink={0}
          onClick={() => {
            tempSelectedIds.current = [];
          }}
        >
          Clear Selections
        </Button>
      }
      handleSubmit={() => {
        if (tempSelectedIds.current.length) {
          clearError();
        } else {
          onNoValues();
        }
        const selectedTreeItems = getSelectionListItems({
          selectionLists,
          selectedIds: tempSelectedIds.current,
        });
        setSelections(selectedTreeItems);
      }}
      menuWidth={360}
      lazy={true}
      isDisabled={isDisabled}
      triggerElement={
        <FormInputMimicBox
          isEditing={isEditing}
          isDisabled={isDisabled}
          hasError={hasError}
        >
          <Flex
            columnGap="3px"
            alignItems="center"
            height="100%"
            wrap="wrap"
            rowGap="0.5rem"
          >
            {selectedValues.map((conditionValue) => {
              return (
                <Tag
                  key={`${conditionId}-${conditionValue}`}
                  variant="filterChip"
                  data-testid={`condition-${conditionId}-${conditionValue}`}
                  size="sm"
                >
                  <TagLabel>
                    <Flex alignItems="center">
                      {conditionValue.item?.label}{' '}
                      {isMultiGranularitySelectionListVariable
                        ? `(${
                            SelectionCategoryLabelOverrides[
                              conditionValue.selectionListId
                            ]
                          })`
                        : ''}
                    </Flex>
                  </TagLabel>
                  <TagCloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      const selectedValuesWithDeletion = selectedValues.filter(
                        (value) => value !== conditionValue
                      );
                      setSelections(selectedValuesWithDeletion);
                      if (!selectedValuesWithDeletion.length) {
                        onNoValues();
                      }
                    }}
                  />
                </Tag>
              );
            })}
            <Button
              variant={'addcompany'}
              leftIcon={<PlusMinusIcon />}
              display="flex"
              isDisabled={isDisabled}
            >
              <span style={{ marginLeft: '-2px' }}>Value</span>
            </Button>
          </Flex>
        </FormInputMimicBox>
      }
      closeOnBlur={false}
      closeOnOutsideClick={true}
    >
      <FormControl isInvalid={false}>
        <BreadcrumbTree
          data={nestedSelectionTree}
          branches={convertListIdBreadcrumb(selectionList)}
          defaultSelections={tempSelectedIds.current}
          onChange={handleChange}
        />
      </FormControl>
    </FilterPopover>
  );
};
