import { Button } from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface TabsListProps {
  tabs: string[];
  setTab: (tab: string) => void;
  tab: string;
}

export function TabsList(props: TabsListProps) {
  return (
    <>
      {props.tabs.map((item, i) => {
        return (
          <Button
            key={i}
            variant={props.tab === item ? 'tab_active' : 'tab'}
            onClick={() => props.setTab(item)}
            marginX="2"
          >
            {item}
          </Button>
        );
      })}
    </>
  );
}

export default TabsList;
