export enum TrialType {
  NO_DATA_LAG = 'NO_DATA_LAG',
  NO_DATA_LAG_WITH_TD_REVEAL = 'NO_DATA_LAG_WITH_TD_REVEAL',
  ONE_YEAR_DATA_LAG = 'ONE_YEAR_LAG',
}

export const TRIAL_TYPES_WITHOUT_LAG = [
  TrialType.NO_DATA_LAG,
  TrialType.NO_DATA_LAG_WITH_TD_REVEAL,
];
