import { Card, Grid, GridItem } from '@chakra-ui/react';
import { CompanyStatsProps } from './company-stat/company-stat-line-charts/types';
import { SentimentChart } from './company-stat/company-stat-line-charts/charts/sentiment-chart';
import { HeadcountChart } from './company-stat/company-stat-line-charts/charts/headcount-chart';
import { GrowthChart } from './company-stat/company-stat-line-charts/charts/growth-chart';
import { PostingsChart } from './company-stat/company-stat-line-charts/charts/postings-chart';
import { BusinessOutlookChart } from './company-stat/company-stat-line-charts/charts/business-outlook-chart';
import { BenefitsChart } from './company-stat/company-stat-line-charts/charts/benefits-chart';
import styles from './company-stats.module.css';

export const CompanyStats = ({
  competitors,
  compositionData,
  postingsActiveData,
  selectedCompanyRCID,
  sentimentData,
}: CompanyStatsProps) => {
  const primaryCompanyCompositionData = compositionData?.composition?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );

  const companyName = primaryCompanyCompositionData?.metadata?.longName;

  return (
    <Card height="100%" p="20px 30px" boxShadow="none">
      <Grid
        height="100%"
        templateRows="repeat(3, minmax(0, 1fr))"
        templateColumns="repeat(2, minmax(0, 1fr))"
        gap={8}
      >
        <GridItem className={styles.gridItem}>
          <HeadcountChart
            compositionData={compositionData}
            selectedCompanyRCID={selectedCompanyRCID}
            competitors={competitors}
            companyName={companyName}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <GrowthChart
            compositionData={compositionData}
            selectedCompanyRCID={selectedCompanyRCID}
            competitors={competitors}
            companyName={companyName}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <PostingsChart
            postingsActiveData={postingsActiveData}
            selectedCompanyRCID={selectedCompanyRCID}
            competitors={competitors}
            companyName={companyName}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <SentimentChart
            sentimentData={sentimentData}
            selectedCompanyRCID={selectedCompanyRCID}
            companyName={companyName}
            competitors={competitors}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <BusinessOutlookChart
            sentimentData={sentimentData}
            selectedCompanyRCID={selectedCompanyRCID}
            companyName={companyName}
            competitors={competitors}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <BenefitsChart
            sentimentData={sentimentData}
            selectedCompanyRCID={selectedCompanyRCID}
            companyName={companyName}
            competitors={competitors}
          />
        </GridItem>
      </Grid>
    </Card>
  );
};
