// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { useD3 } from '../hooks/useD3.ts';
import { BoxPlotGenerator } from '../plot-generators/box-plot/BoxPlotGenerator.js';

import '../d3-styles.scss';

const BoxPlot = (plotConfigs) => {
  const { name, data, height, width, targetRef, requestHash } = plotConfigs;

  const [plotData, otherTabData] = data;

  useD3(BoxPlotGenerator, { ...plotConfigs, data: plotData, otherTabData }, [
    targetRef.current,
    height,
    width,
    data,
    requestHash,
  ]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        display: 'grid',
        position: 'relative',
        height: '100%',
      }}
    />
  );
};

BoxPlot.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
  xAxisFormat: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

BoxPlot.defaultProps = {
  name: 'default',
  heading: 'default',
  ttMainFormat: '$s',
  ttSecondaryFormat: ',.0f',
  yAxisFormat: '.0%',
  xAxisFormat: '$~s',
  data: [],
};

export default withResizeDetector(BoxPlot, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
