import { FC } from 'react';
import {
  Button,
  Stack,
  StackDivider,
  Flex,
  Center,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
} from '@chakra-ui/react';
import { Control, UseFormWatch, useForm } from 'react-hook-form';
import { get, noop } from 'lodash';
import { useQuery } from 'urql';

import { isRoleAdmin, useGetLoggedInUser } from '@revelio/auth';
import { Client, InputMaybe, Role } from '@revelio/data-access';
import { Loading } from '@revelio/core';
import { FieldGroup } from '@revelio/layout';

import { useSubmitUser } from '../utils/helpers';
import { ClientDetailsQuery } from '../userOperations';
import UserForm, { UserFormValues } from '../user-form';
import ClientForm, { ClientFormValues } from '../client-form';
import { useSearchParams } from 'react-router-dom';

interface AdminUserAddProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const AdminUserAdd: FC<AdminUserAddProps> = ({ isOpen, onClose }) => {
  const {
    loggedInUser,
    query: [{ fetching: loggedInUserLoading }],
  } = useGetLoggedInUser();
  const isAdmin = isRoleAdmin(loggedInUser.role);
  const isClientAdmin = loggedInUser.role === Role.ClientAdmin;

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<UserFormValues>();

  const [searchParams] = useSearchParams();
  const clientNameParam = searchParams.get('client');
  const newUserClient = watch('client_group');

  const clientQueryId = newUserClient?.value
    ? newUserClient?.value
    : clientNameParam || undefined;

  const [{ data: clientData }] = useQuery({
    query: ClientDetailsQuery,
    variables: { ids: [clientQueryId as InputMaybe<string>] },
    pause: !clientQueryId,
  });

  const clientDetails: Client = get(clientData, 'clients[0]');

  const { addUser, addUserLoading } = useSubmitUser({
    loggedInUser,
    isClientAdmin,
    onClose,
  });

  const submitAddUser = handleSubmit((formData) => {
    addUser({ formData, specifiedClient: clientDetails });
  });

  return (
    <Modal
      isOpen={isOpen || false}
      onClose={onClose || noop}
      size="5xl"
      isCentered
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a new user</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!loggedInUserLoading ? (
            <form onSubmit={submitAddUser}>
              <Stack divider={<StackDivider />} spacing="6">
                <Stack direction="row" divider={<StackDivider />} spacing="6">
                  <div style={{ width: '930px' }}>
                    <FieldGroup
                      title="Add User"
                      description="Create a new account user"
                    >
                      <UserForm
                        userToEdit={null}
                        defaultClient={clientDetails}
                        register={register}
                        errors={errors}
                        control={control}
                        setPassword={(newPassword: string) =>
                          setValue('password', newPassword)
                        }
                      />
                    </FieldGroup>
                  </div>

                  {!isClientAdmin && (
                    <ClientForm
                      clientToEdit={clientDetails}
                      readonly={true}
                      title={
                        clientDetails
                          ? `${clientDetails?.client_name} settings`
                          : 'User client group'
                      }
                      description={
                        clientDetails
                          ? "To edit client group, go to edit one of client's user"
                          : 'Choose client group to see settings'
                      }
                      register={() => ({})}
                      control={
                        control as unknown as Control<ClientFormValues, object>
                      }
                      watch={watch as unknown as UseFormWatch<ClientFormValues>}
                    />
                  )}
                </Stack>

                <FieldGroup>
                  <Stack direction="row" spacing={4} align="center">
                    <Button
                      colorScheme="navyBlue"
                      variant="solid"
                      size="md"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    {isAdmin && (
                      <Button
                        colorScheme="green"
                        variant="solid"
                        size="md"
                        isLoading={addUserLoading}
                        type="submit"
                      >
                        Add User
                      </Button>
                    )}
                    <Spacer />
                  </Stack>
                </FieldGroup>
              </Stack>
            </form>
          ) : (
            <Flex justify="center" minHeight="200px">
              <Center>
                <Loading />
              </Center>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdminUserAdd;
