import { Badge, Flex, Text } from '@chakra-ui/react';
import { ChakraStylesConfig, GroupBase, Select } from 'chakra-react-select';
import { selectEntity } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';

import {
  DeliverableOutput,
  ModelVersions,
  PipelineColumnType,
  PipelineFilter,
  PipelineType,
  CustomColumn as CustomColumnOutput,
  GetPipelineConfigsQuery,
} from '@revelio/data-access';

import {
  deliverablesStore,
  hasCompanyInColumns,
} from '../../deliverables.repository';
import { getDefaultChakraStyles } from '../dataset-conditions/condition-select';
import {
  NEW_PIPELINE_CONFIGURATION_SELECT_OPTION,
  PipelineConfigurationSelectOption,
  applyPipelineConfiguration,
  getPipelineConfigurationOptions,
  getPipelineConfigurationValue,
  globalPipelinConfigurationColumnsMap,
} from './saved-pipeline-configuration-select-helpers';
import {
  CustomColumn,
  DatasetFilter,
  Deliverable,
} from '../../deliverables.model';
import { getRequiredSelectionListNamesForCustomStepDeserialisation } from '../dataset-conditions/serialize-conditions.api';
import { useSelectionLists } from '@revelio/filtering';
import { deserializeCustomColumnMultiGranularitySelectionListVariables } from '../custom/serialize-custom-columns.api';
import { deserializeFilterMultiGranularitySelectionListVariables } from '../filters/serialize-filters.api';

export const PipelineConfigurationSelect = ({
  entityId,
  pipelineType,
  pipelineConfigurations,
  selectedPipelineConfiguration,
  setSelectedPipelineConfiguration,
}: {
  entityId: Deliverable['id'];
  pipelineType: PipelineType;
  pipelineConfigurations: GetPipelineConfigsQuery;
  selectedPipelineConfiguration:
    | PipelineConfigurationSelectOption
    | null
    | undefined;
  setSelectedPipelineConfiguration: React.Dispatch<
    React.SetStateAction<PipelineConfigurationSelectOption | null | undefined>
  >;
}) => {
  const [deliverable] = useObservable(
    deliverablesStore.pipe(selectEntity(entityId)),
    { deps: [entityId] }
  );
  const selectedPipelineColumns = deliverable?.pipeline
    .columns as PipelineColumnType[];
  const pipelineConfigurationSelectValue = getPipelineConfigurationValue({
    entityId,
    selectedPipelineColumns,
    savedPipelineConfigurationSelection: selectedPipelineConfiguration,
  });

  const requiredSelectionListsFromSavedPipelineConfigs =
    getRequiredSelectionListNamesForCustomStepDeserialisation({
      deliverables: pipelineConfigurations?.pipelineConfiguration?.map((x) => ({
        pipeline: x?.pipeline,
      })) as DeliverableOutput[],
    });
  const selectionLists = useSelectionLists(
    requiredSelectionListsFromSavedPipelineConfigs
  );

  return (
    <Select<
      PipelineConfigurationSelectOption,
      false,
      GroupBase<PipelineConfigurationSelectOption>
    >
      options={[
        NEW_PIPELINE_CONFIGURATION_SELECT_OPTION,
        {
          label: 'Defaults',
          options: getPipelineConfigurationOptions({
            pipelineType,
            hasSelectedCompany: hasCompanyInColumns({
              pipelineType,
              columns: selectedPipelineColumns,
            }),
          }),
        },
        {
          label: 'Saved',
          options: (pipelineConfigurations?.pipelineConfiguration || [])
            .filter((x) => x?.pipeline?.pipeline_type === pipelineType)
            .map((config) => ({
              label: config?.name as string,
              value: config?.name as string,
            })),
        },
      ]}
      formatGroupLabel={SavedPipelineConfigurationGroupLabel}
      value={pipelineConfigurationSelectValue}
      onChange={(selectOption) => {
        const selectedGlobalPipelineConfigurationColumns =
          globalPipelinConfigurationColumnsMap({ entityId })[
            selectOption?.value as string
          ];
        if (selectedGlobalPipelineConfigurationColumns) {
          setSelectedPipelineConfiguration(
            NEW_PIPELINE_CONFIGURATION_SELECT_OPTION
          );
          applyPipelineConfiguration({
            entityId,
            columns: selectedGlobalPipelineConfigurationColumns,
          });
          return;
        }

        const selectedSavedPipelineConfiguration =
          pipelineConfigurations?.pipelineConfiguration?.find(
            (config) => config?.name === selectOption?.value
          );
        if (selectedSavedPipelineConfiguration) {
          setSelectedPipelineConfiguration(selectOption);
          applyPipelineConfiguration({
            entityId,
            columns: selectedSavedPipelineConfiguration.pipeline
              ?.columns as PipelineColumnType[],
            modelVersions:
              selectedSavedPipelineConfiguration.model_versions as ModelVersions,
            customColumns:
              deserializeCustomColumnMultiGranularitySelectionListVariables({
                customColumns: selectedSavedPipelineConfiguration.pipeline
                  ?.custom_columns as CustomColumnOutput[],
                selectionLists,
              }) as CustomColumn[],
            filters: deserializeFilterMultiGranularitySelectionListVariables({
              filters: selectedSavedPipelineConfiguration.pipeline
                ?.filters as PipelineFilter[],
              selectionLists,
            }) as unknown as DatasetFilter[],
          });
          return;
        }

        setSelectedPipelineConfiguration(
          NEW_PIPELINE_CONFIGURATION_SELECT_OPTION
        );
        applyPipelineConfiguration({
          entityId,
          columns: [],
        });
      }}
      variant="outline"
      name="dataset-column-set"
      chakraStyles={
        {
          ...getDefaultChakraStyles({
            width: '250px',
            fontSize: '14px',
          }),
          control: (provided) => ({
            ...provided,
            background: 'white',
            width: '175px',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
            textAlign: 'left',
            color: 'navyBlue.500',
            backgroundColor: 'transparent',
          }),
          groupHeading: (provided) => ({
            ...provided,
            borderTop: '1px solid #E2E8F0',
          }),
          option: (provided, props) => {
            return {
              ...provided,
              borderBottom:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props as any).value ===
                NEW_PIPELINE_CONFIGURATION_SELECT_OPTION.value
                  ? '1px solid #E2E8F0'
                  : 'none',
              paddingLeft: [NEW_PIPELINE_CONFIGURATION_SELECT_OPTION]
                .map((o) => o.value)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .includes((props as any).value)
                ? undefined
                : '30px',
            };
          },
        } as ChakraStylesConfig<
          PipelineConfigurationSelectOption,
          false,
          GroupBase<PipelineConfigurationSelectOption>
        >
      }
    />
  );
};

const SavedPipelineConfigurationGroupLabel = (data: {
  readonly label?: string;
  readonly options: readonly PipelineConfigurationSelectOption[];
}) => (
  <Flex justifyContent="space-between">
    <Text>{data.label}</Text>
    <Badge>{data.options.length}</Badge>
  </Flex>
);
