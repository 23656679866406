import { Flex, Heading } from '@chakra-ui/layout';
import { FullLogo, SankeyError } from '@revelio/assets';

export const MobileUnsupportedPage = () => {
  return (
    <Flex
      direction="row"
      align="center"
      justify="center"
      minH="100vh"
      textAlign="center"
      bg="#F1F5F9"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Flex maxW="md" mx="auto" flexDirection="column">
        <FullLogo mx="auto" h="39" mb={{ base: '4', md: '6' }} />
        <Heading
          textAlign="center"
          size="xl"
          fontWeight="extrabold"
          color="text.primary"
          mb="6"
        >
          Please use a larger screen for the best experience to meet your
          workforce analytics needs.
        </Heading>
        <SankeyError height="192px" />
      </Flex>
    </Flex>
  );
};
