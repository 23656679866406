import { Flex } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const CentrializedLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Flex justifyContent="center" alignItems="center" minHeight="100%">
      <Box maxWidth={760}>{children}</Box>
    </Flex>
  );
};
