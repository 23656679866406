import { useEffect, useState } from 'react';
import { useSingleOrMoreFilterState } from '../../../engine/filters.engine';
import { SelectionCategories } from '../../../engine/filters.model';
import { get, has } from 'lodash';

export const useAsyncTreeState = (filterName: SelectionCategories) => {
  const [selections, setSelections] = useState({});

  const [initialSelections] = useSingleOrMoreFilterState(filterName);

  useEffect(() => {
    if (initialSelections) {
      const hasUnformattedValues = has(initialSelections, 'unformatted');

      let initialValues = hasUnformattedValues
        ? get(initialSelections, 'unformatted', {})
        : get(initialSelections, 'value', []);

      if (!hasUnformattedValues && Array.isArray(initialValues)) {
        initialValues = initialValues.reduce((acc, cur) => {
          return { ...acc, [cur.id]: cur };
        }, {});
      }

      setSelections(initialValues);
    }
  }, [initialSelections]);

  return [selections, setSelections];
};
