import {
  calculateStatValues,
  CompositionStatsMetric,
  Stat,
} from '../../../../../shared/stat';
import { MonthlyLineChart } from '../../../../../shared/monthly-line-chart-with-has-rendered-context';

import { getAggregatedCompetitorCompositionMetrics } from '../utils/get-aggregated-competitor-metrics';
import { CompanyStatsProps } from '../types';
import { formatIntegerToDecimal } from '@revelio/core';
import { Text } from '@chakra-ui/react';

interface SentimentChartProps {
  compositionData: CompanyStatsProps['compositionData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const HeadcountChart = ({
  compositionData,
  selectedCompanyRCID,
  companyName,
  competitors,
}: SentimentChartProps) => {
  const primaryCompanyCompositionData = compositionData?.composition?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );

  const { lastValue, percentageChange } = calculateStatValues(
    primaryCompanyCompositionData?.metrics?.[CompositionStatsMetric.Headcount]
      ?.timeseries
  );

  const weightedCompetorHeadcount = getAggregatedCompetitorCompositionMetrics(
    competitors,
    compositionData,
    CompositionStatsMetric.Headcount
  );

  return (
    <Stat
      name={CompositionStatsMetric.Headcount}
      plotInfo={
        <Text color="white">
          Our estimate of the total workforce. These counts include both formal
          employees and contingent workers. These counts also include
          subsidiaries. More information on the methodologies used for this
          metric can be found{' '}
          <Text
            as="span"
            color="green.300"
            _hover={{ textDecoration: 'underline' }}
          >
            <a
              href="https://www.data-dictionary.reveliolabs.com/methodology.html#sampling-weights"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </Text>
          .
        </Text>
      }
      header="Headcount"
      statValues={{
        primaryValue: formatIntegerToDecimal(lastValue),
        percentChange: percentageChange,
        vsCompetitors: lastValue - weightedCompetorHeadcount,
      }}
      competitorFormatting={formatIntegerToDecimal}
      competitorStyles={{
        minWidth: '110px',
      }}
    >
      <MonthlyLineChart
        name={CompositionStatsMetric.Headcount}
        shortName={companyName}
        timeSeries={
          primaryCompanyCompositionData?.metrics?.headcount?.timeseries
        }
        chartProps={{
          ttMainFormat: ',.0f',
        }}
        type="Company"
      />
    </Stat>
  );
};
