import { DownloadIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef, useState } from 'react';
import { useClient } from 'urql';
import { environment } from '../../environments/environment';
import EnrichedResume from './EnrichedResume';
import { GET_RESUME } from './resume-operations.gql';

// Function to check if a horizontal strip of the canvas is empty (white space)
const isLineEmpty = ({
  canvas,
  y,
  width,
  height = 1,
}: {
  canvas: HTMLCanvasElement;
  y: number;
  width: number;
  height: number;
}): boolean => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return false;

  const imageData = ctx.getImageData(0, y, width, height).data;
  for (let i = 0; i < imageData.length; i += 4) {
    const [r, g, b] = [imageData[i], imageData[i + 1], imageData[i + 2]];
    if (r < 250 || g < 250 || b < 250) return false;
  }
  return true;
};

// Find the optimal cut-off point based on original canvas dimensions
const findCutOffPoint = ({
  canvas,
  startY,
  maxEndY,
  pageHeight,
  scaleX,
  canvasWidth,
}: {
  canvas: HTMLCanvasElement;
  startY: number;
  maxEndY: number;
  canvasWidth: number;
  scaleX: number;
  pageHeight: number;
}): number => {
  let cutOffY: number = startY + pageHeight / scaleX;
  if (cutOffY > maxEndY) cutOffY = maxEndY;

  while (
    cutOffY > startY &&
    !isLineEmpty({ canvas, y: cutOffY - 3, width: canvasWidth, height: 3 })
  ) {
    cutOffY--;
    if (cutOffY <= startY + 6) break;
  }

  return cutOffY;
};

// Function to create a canvas for 1 page of the PDF
const createPageCanvas = ({
  fullCanvas,
  offsetY,
  pageHeight,
  canvasWidth,
}: {
  fullCanvas: HTMLCanvasElement;
  offsetY: number;
  pageHeight: number;
  canvasWidth: number;
}): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    const canvasPage = document.createElement('canvas');
    const ctx = canvasPage.getContext('2d');
    if (!ctx) {
      return reject(new Error('Failed to get canvas context'));
    }

    canvasPage.width = canvasWidth;
    canvasPage.height = pageHeight;

    const img = new Image();
    img.src = fullCanvas.toDataURL('image/png');

    img.onload = () => {
      ctx.drawImage(
        img,
        0,
        offsetY,
        canvasWidth,
        pageHeight,
        0,
        0,
        canvasWidth,
        pageHeight
      );
      resolve(canvasPage);
    };

    img.onerror = (e) => {
      reject(new Error('Image failed to load: ' + e));
    };
  });
};

interface DownloadResumeButtonProps {
  resumeId: string;
  resumeName: string;
  iconButton: boolean;
}

function DownloadResumeButton({
  resumeId,
  resumeName,
  iconButton,
}: DownloadResumeButtonProps) {
  const [resumeData, setResumeData] = useState(null);
  const [resumeDownloadLoading, setResumeDownloadLoading] = useState(false);
  const hiddenContainerRef = useRef(null);
  const client = useClient();
  const toast = useToast();

  const fetchResumeData = async () => {
    const { data } = await client.query(GET_RESUME, { id: resumeId });
    if (data?.getResume?.enriched_data) {
      return {
        id: data.getResume.resume_id,
        ...JSON.parse(data.getResume.enriched_data),
      };
    }
    return null;
  };

  const generatePdf = async (): Promise<void> => {
    try {
      setResumeDownloadLoading(true);
      const data = await fetchResumeData();
      if (data) {
        setResumeData(data);

        // Wait for the EnrichedResume component to render with the fetched data
        setTimeout(async () => {
          if (hiddenContainerRef.current) {
            const fullCanvas = await html2canvas(hiddenContainerRef.current, {
              scale: 2,
              useCORS: true,
              allowTaint: true,
            });

            const canvasWidth = fullCanvas.width;
            const canvasHeight = fullCanvas.height;

            const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'pt',
              format: 'a4',
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 10;
            const imgWidth = pdfWidth - 2 * margin;
            const pageHeight = pdfHeight - 2 * margin; // Maximum page height in PDF

            // Calculate the scaling factor based on the canvas width and pdf width
            const scaleX = imgWidth / canvasWidth;

            let offsetY = 0;

            const addPagesToPDF = async (): Promise<void> => {
              while (offsetY < canvasHeight) {
                try {
                  const startY: number = offsetY;
                  const endY: number = Math.min(
                    offsetY + pageHeight / scaleX,
                    canvasHeight
                  );

                  // Find the correct cut-off point for the page
                  const cutOffY: number = findCutOffPoint({
                    canvas: fullCanvas,
                    startY,
                    maxEndY: endY,
                    canvasWidth,
                    scaleX,
                    pageHeight,
                  });

                  const pageCanvas = await createPageCanvas({
                    fullCanvas,
                    offsetY: startY,
                    pageHeight: cutOffY - startY,
                    canvasWidth,
                  });
                  const pageImgData: string = pageCanvas.toDataURL('image/png');

                  // Calculate the height for the PDF page based on the scaling factor
                  const imgHeight: number = (cutOffY - startY) * scaleX;

                  if (offsetY === 0) {
                    pdf.addImage(
                      pageImgData,
                      'PNG',
                      margin,
                      margin,
                      imgWidth,
                      imgHeight
                    );
                  } else {
                    pdf.addPage();
                    pdf.addImage(
                      pageImgData,
                      'PNG',
                      margin,
                      margin,
                      imgWidth,
                      imgHeight
                    );
                  }

                  offsetY = cutOffY; // Move to the next page
                } catch (error) {
                  console.error(error);
                }
              }

              pdf.save(`${resumeName}.pdf`);
              setResumeDownloadLoading(false);
            };

            await addPagesToPDF();
            setResumeData(null);
          }
        }, 0);
      } else {
        setResumeDownloadLoading(false);
        console.error('Failed to fetch resume data');
      }
    } catch (error) {
      console.error(error);
      setResumeDownloadLoading(false);
    }
  };

  const generateCSV = async () => {
    try {
      setResumeDownloadLoading(true);
      const response = await fetch(
        `${environment.GO_API_ROOT}/api/resume-parsing/downloads/${resumeId}`,
        {
          credentials: 'include',
        }
      );

      if (!response.ok) {
        toast({
          title: 'Error',
          description: 'Error downloading csv.',
          status: 'error',
          duration: 4000,
          position: 'top-right',
        });
      } else {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = `${resumeName}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    setResumeDownloadLoading(false);
  };

  return (
    <>
      <Menu>
        {iconButton ? (
          <MenuButton
            as={IconButton}
            icon={
              resumeDownloadLoading ? (
                <Spinner height="13px" width="13px" />
              ) : (
                <DownloadIcon height="13px" width="13px" />
              )
            }
            variant="unstyled"
            aria-label="download-icon"
            size="s"
          />
        ) : (
          <MenuButton
            as={Button}
            size="sm"
            variant="outline"
            backgroundColor="white"
            color="navyBlue.500"
            border="none"
            leftIcon={resumeDownloadLoading ? <Spinner /> : <DownloadIcon />}
            isDisabled={resumeDownloadLoading}
          >
            Download
          </MenuButton>
        )}
        <MenuList>
          <MenuItem onClick={generatePdf} isDisabled={resumeDownloadLoading}>
            Download as PDF
          </MenuItem>
          <MenuItem onClick={generateCSV} isDisabled={resumeDownloadLoading}>
            Download as CSV
          </MenuItem>
        </MenuList>
      </Menu>
      {/* Hidden container for rendering the resume */}
      <Box
        style={{
          position: 'absolute',
          top: '-9999px',
          left: '-9999px',
          width: '595px', // Width of A4 in pixels
          height: '842px', // Height of A4 in pixels
        }}
      >
        <Box ref={hiddenContainerRef}>
          {resumeData && <EnrichedResume resume={resumeData} variant="pdf" />}
        </Box>
      </Box>
    </>
  );
}

export default DownloadResumeButton;
