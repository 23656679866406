import { Box } from '@chakra-ui/react';
import {
  get,
  has,
  isEmpty,
  isUndefined,
  kebabCase,
  padStart,
  startCase,
  truncate,
  uniqueId,
} from 'lodash';
import { TalentDiscoveryChipLabelOverrides } from './aggregate-chip-lookups';
import { pluralize } from '../../utils/pluralize';
import { Fragment } from 'react';
import {
  AnyFilter,
  OPSelectFilter,
  OPValues,
  OperatorOptionType,
  SelectionCategories,
  TalentDiscoveryCompanyValueType,
  TalentDiscoveryValue,
  ValueItem,
} from '../../../engine/filters.model';
import { format } from 'd3-format';

interface FormatSelectValuesArgs {
  key: string;
  value: string[];
  isLastItem?: boolean;
  maxChars?: number;
  formatChipLabel?: boolean;
}

const formatSelectValues = ({
  key,
  value,
  isLastItem = false,
  maxChars = 20, // TODO: Can experiment with this number to see what length works best
  formatChipLabel = true,
}: FormatSelectValuesArgs) => {
  // eslint-disable-next-line no-nested-ternary
  const delimiter = value.length > 0 ? (isLastItem ? '' : ', ') : '';

  const capitalizedValues = value.map((val) =>
    formatChipLabel ? startCase(val) : val
  );

  const fullString = capitalizedValues.join(', ');

  const formatMultiLabel = (length: number, key: string) => {
    let str = get(TalentDiscoveryChipLabelOverrides, key, key);
    if (str.includes('role_k') || str.includes('skill_k')) {
      const splitString = str.split('_');
      str = `${startCase(splitString[0])} ${splitString[1]}`;
    } else {
      str = startCase(str);
    }

    return `${length} ${pluralize(str)}`;
  };
  const content =
    value.length < 2
      ? truncate(fullString, { length: maxChars })
      : formatMultiLabel(value.length, key);

  return (
    <Box
      as="span"
      color="lightBlue.600"
      fontWeight="semibold"
      key={`${kebabCase(key)}-${uniqueId()}`}
      aria-labelledby={fullString}
      title={fullString}
    >
      {content}
      {delimiter}
    </Box>
  );
};

interface FormatRangeLabelProps {
  entityLabel?: string;
  index: number;
  operator: string;
  hasStartValue: boolean;
  formattedStartValue: string;
  formattedEndValue: string;
}

const formatRangeLabel = ({
  entityLabel,
  index,
  operator,
  hasStartValue,
  formattedStartValue,
  formattedEndValue,
}: FormatRangeLabelProps) => {
  const hasEntityLabel = !isEmpty(entityLabel);

  const key = hasEntityLabel
    ? uniqueId()
    : `${kebabCase(entityLabel)}-${uniqueId()}`;

  return (
    <Fragment key={key}>
      {index > 0 && ` \u2022 for `}

      {entityLabel && (
        <Box as="span" color="lightBlue.600" fontWeight="semibold">
          {`${entityLabel}  \u2022 `}
        </Box>
      )}

      {`${operator} `}

      <Box as="span" color="lightBlue.600" fontWeight="semibold">
        {formattedStartValue}
      </Box>
      {/* {!isUndefined(startValue) && ' to '} */}
      {hasStartValue && !isEmpty(formattedEndValue) && ' to '}
      <Box as="span" color="lightBlue.600" fontWeight="semibold">
        {formattedEndValue}
      </Box>
    </Fragment>
  );
};
interface FormatSalaryLabelProps {
  operator: OperatorOptionType;
  startValue: string;
  endValue: string;
}

const formatSalaryLabel = ({
  operator,
  startValue,
  endValue,
}: FormatSalaryLabelProps) => {
  const isSalaryRange = operator.value === OPValues.BETWEEN;
  return (
    <Fragment key={uniqueId()}>
      {`${operator.value} ${isSalaryRange ? '' : ' than'} \u2022 `}
      <Box as="span" color="lightBlue.600" fontWeight="semibold">
        {startValue}
      </Box>

      {isSalaryRange && (
        <>
          {` and `}
          <Box as="span" color="lightBlue.600" fontWeight="semibold">
            {endValue}
          </Box>
        </>
      )}
    </Fragment>
  );
};

interface GetRangeLabelProps {
  opValue: OPSelectFilter;
  startValue: number;
  endValue: number;
  key: string;
  index: number;
  unit?: string;
}
const getRangeLabel = ({
  opValue,
  startValue,
  endValue,
  key,
  index,
  unit = ' years',
}: GetRangeLabelProps) => {
  const entityLabel = startCase(
    get(TalentDiscoveryChipLabelOverrides, key, key)
  );

  const operator =
    opValue.value === 'between' ? 'from' : opValue.value + ' than ';

  const formattedStartValue = !isUndefined(startValue)
    ? `${startValue} ${unit}`
    : '';

  const formattedEndValue = `${endValue} ${unit}`;

  const newElement = formatRangeLabel({
    entityLabel,
    index,
    operator,
    hasStartValue: !isUndefined(startValue),
    formattedStartValue,
    formattedEndValue,
  });

  return newElement;
};

export const formatSelections = (
  filterItem: AnyFilter,
  formatChipLabel: boolean
) => {
  let count = 0;

  const isName = filterItem.id == SelectionCategories.NAME;

  if (isName) {
    const name = get(filterItem, 'value.name[0]', '');

    const nameLabel = (
      <Fragment key={uniqueId()}>
        <Box as="span" color="lightBlue.600" fontWeight="semibold">
          {name}
        </Box>
      </Fragment>
    );

    return [1, nameLabel];
  }

  const isDateRange = filterItem.id == SelectionCategories.DATE_RANGE;

  const isSalaryRange = [
    SelectionCategories.BASE_SALARY,
    SelectionCategories.TOTAL_COMPENSATION,
  ].includes(filterItem.id as SelectionCategories);

  const elements = Object.entries(filterItem.value).reduce(
    (acc: (string | JSX.Element)[], cur, index) => {
      const [key, value] = cur;

      const rangeValues: JSX.Element[] = [];
      const selectValues: string[] = [];

      if (isSalaryRange) {
        const { operator: opValue, value: salaryValues } = value[0] || {};

        const [startValue, endValue] = salaryValues;

        const newElement = formatSalaryLabel({
          operator: opValue,
          startValue: format('$,')(startValue),
          endValue: format('$,')(endValue),
        });

        rangeValues.push(newElement);
      }

      if (isDateRange) {
        const datesCollected = [
          get(value[0], 'startDate'),
          get(value[0], 'endDate'),
        ];

        const formattedDates = datesCollected.map((date) => {
          if (!date) return date;

          return `${date.getFullYear()}-${padStart(
            date.getMonth() + 1,
            2,
            '0'
          )}-${padStart(date.getDate(), 2, '0')}`;
        });

        const [formattedStartDate, formattedEndDate] = formattedDates;

        const numDates = formattedDates.filter(
          (dateString) => !isUndefined(dateString)
        ).length;

        const newElement = formatRangeLabel({
          index: 0,
          operator: numDates > 1 ? 'from' : '',
          hasStartValue: !isUndefined(formattedStartDate),
          formattedStartValue:
            formattedStartDate && `Start Date: ${formattedStartDate}`,
          formattedEndValue:
            formattedEndDate && `End Date: ${formattedEndDate}`,
        });

        rangeValues.push(newElement);
      }

      value.forEach((val: TalentDiscoveryValue & ValueItem) => {
        const isRangeValue = has(val, 'opValue');
        const isCompanyValue = has(val, 'companyValue');

        if (!isRangeValue) {
          const { companyValue } = val;

          if (isCompanyValue) {
            companyValue?.forEach(
              (company: TalentDiscoveryCompanyValueType) => {
                selectValues.push(company.label);
              }
            );

            return;
          }

          if (!isUndefined(val?.label)) {
            selectValues.push(val.label);
          }
        } else {
          const { opValue, startValue, endValue } = val;

          const newElement = getRangeLabel({
            opValue,
            startValue,
            endValue,
            key,
            index,
          });

          rangeValues.push(newElement);
        }
      });

      count += selectValues.length;

      return [
        ...acc,
        ...rangeValues,
        formatSelectValues({
          key,
          value: selectValues,
          isLastItem: Object.entries(filterItem.value).length === index + 1,
          formatChipLabel,
        }),
      ];
    },
    []
  );

  return [count, elements];
};
