import { startCase } from 'lodash';

export const getRiskColor = (num: number) => {
  if (num < 0.4) return 'green';
  if (num < 0.7) return 'yellow';
  return 'red';
};

export const getRiskLabel = (num: number) => {
  if (num < 0.4) return 'Low';
  if (num < 0.7) return 'Moderate';
  return 'High';
};

export const formatCurrency = (num: number, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};

export const formatWithCommas = (fields: (string | undefined | null)[]) => {
  const formattedFields = fields
    .filter(Boolean)
    .map((field) => startCase(field as string));
  return formattedFields.join(', ');
};
