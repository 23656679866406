import { Box } from '@chakra-ui/react';
import { Views } from '@revelio/core';
import { debounce } from 'lodash';
import { ViewTypes } from '../../data-api/data-api.model';
import { SelectionCategories } from '../../engine/filters.model';
import {
  deleteFilters,
  upsertFiltersWithProvidedValue,
} from '../../engine/filters.repository';
import { MaxDateRangeToDefault } from '../date-range/helpers';

export const DateRangeChip = ({
  formattedStartDate,
  formattedEndDate,
}: {
  formattedStartDate: string;
  formattedEndDate: string;
}) => (
  <>
    {`Range \u2022 is from \u2022 `}
    <Box as="span" color="lightBlue.600" fontWeight="semibold">
      {formattedStartDate} to {formattedEndDate}
    </Box>
  </>
);

export const handleRemoveDateRangeFilter = debounce(
  (
    e,
    {
      propsView,
      viewType,
      dateKey,
    }: {
      propsView?: Views;
      viewType?: ViewTypes;
      dateKey?:
        | SelectionCategories.DATE_RANGE
        | SelectionCategories.DATE_RANGE_FULL;
    }
  ) => {
    e.stopPropagation();

    // reset the snapshot date range filter
    deleteFilters([
      SelectionCategories.SNAPSHOT_DATE,
      SelectionCategories.DATE_RANGE_FULL,
      SelectionCategories.DATE_RANGE,
    ]);

    upsertFiltersWithProvidedValue(
      MaxDateRangeToDefault(propsView, viewType, undefined, dateKey)
    );
  },
  400,
  { leading: true }
);
