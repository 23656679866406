import { FormControl } from '@chakra-ui/react';
import { ChakraStylesConfig, GroupBase, Select } from 'chakra-react-select';
import { noop, some } from 'lodash';
import { useLifecycles } from 'react-use';
import {
  FilterItem,
  FilterName,
  SelectFilter,
  SelectionListIdNames,
  ValidValueTypes,
} from '../../engine/filters.model';
import { useState } from 'react';
import { setGlobalLoaderEnableState } from '@revelio/core';
import {
  upsertFilter,
  useSelectionList,
  useSingleOrMoreFilterState,
} from '../../engine/filters.engine';
/**
 * @param filter - Filter category from the enum
 * @param selectionList - Optional if you need a list different than the filter category
 * @interface SelectProps
 */
export interface SelectProps {
  filterName: FilterName;
  selectionList?: FilterName;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  onClose?: () => void;
  chakraStyles?: ChakraStylesConfig<
    FilterItem<ValidValueTypes>,
    boolean,
    GroupBase<FilterItem<ValidValueTypes>>
  >;
}

export function FilterSelect({
  selectionList,
  filterName,
  isClearable = true,
  isSearchable = false,
  isMulti = true,
  onClose = noop,
  chakraStyles,
}: SelectProps) {
  const selectionListToGet = (selectionList ||
    filterName) as SelectionListIdNames;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  useLifecycles(() => {
    setMenuIsOpen(true);
  });

  const pickedSelectionList = useSelectionList(selectionListToGet);
  const [filterState] =
    useSingleOrMoreFilterState<SelectFilter<FilterItem<ValidValueTypes>>>(
      filterName
    );

  return (
    <FormControl>
      <Select
        id={`filter-select-${filterName}`}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable={isSearchable}
        menuPlacement="top"
        menuIsOpen={menuIsOpen}
        closeMenuOnSelect
        name={filterName}
        options={pickedSelectionList?.value}
        isOptionSelected={(opt, selected) => {
          return some(selected, (check) => check.id == opt.id);
        }}
        value={filterState?.value}
        onChange={(e) => {
          setGlobalLoaderEnableState('DISABLE', 2000);
          upsertFilter<SelectFilter>(filterName, {
            isMulti,
            value: e as SelectFilter['value'],
          });
          setMenuIsOpen(false);
          onClose();
        }}
        chakraStyles={chakraStyles}
      />
    </FormControl>
  );
}

export default FilterSelect;
