import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import { PrimaryFilters } from '@revelio/core';
import { Select, CreatableSelect } from 'chakra-react-select';
import { get } from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { pipe, tap } from 'rxjs';
import {
  deleteFilter,
  useActiveFiltersV2,
  useSingleOrMoreFilterState,
} from '../../engine/filters.engine';
import {
  DateValSelectFilter,
  ISelectFilter,
  OPSelectFilter,
  FilterItem,
  OPValues,
  SelectionCategories,
  LocalSelectionCategories,
} from '../../engine/filters.model';
import { TreeRef } from '../tree/tree/tree';
import { screenerFiltersWithTimeComponent } from '../filter-menu/lookups.config';
import { operatorOptions } from '../../engine/filters.constants';

/* eslint-disable-next-line */
export interface ScreenerMenuProps {
  selectValue: ISelectFilter;
  defaultOPVal: OPSelectFilter;
  opValue: OPSelectFilter;
  setOpValue: React.Dispatch<any>;
  startValue?: number;
  setStartValue: React.Dispatch<number>;
  endValue?: number;
  setEndValue: React.Dispatch<number>;
  defaultDateVal: DateValSelectFilter;
  dateValue: DateValSelectFilter;
  setDateValue: React.Dispatch<any>;
  companyValue: any;
  setCompanyValue: React.Dispatch<any>;
  tempEmpTypes: any;
  setTempEmpTypes: React.Dispatch<any>;
  dateToggle: boolean;
  setDateToggle: React.Dispatch<React.SetStateAction<boolean>>;
  employeeToggle: boolean;
  setEmployeeToggle: React.Dispatch<React.SetStateAction<boolean>>;
  screenerDefaults: {
    [val: string]: { start: number; end: number };
  };
  limit?: number;
  forwardedRef?: any;
}

export function ScreenerMenu({
  selectValue,
  defaultOPVal,
  opValue,
  setOpValue,
  startValue,
  setStartValue,
  endValue,
  setEndValue,
  defaultDateVal,
  dateValue,
  setDateValue,
  companyValue,
  setCompanyValue,
  tempEmpTypes,
  setTempEmpTypes,
  screenerDefaults,
  dateToggle,
  setDateToggle,
  employeeToggle,
  setEmployeeToggle,
  limit,
  forwardedRef,
}: ScreenerMenuProps) {
  const [activeFilters] = useActiveFiltersV2();

  const [primaryFilter, setPrimaryFilter] = useState<PrimaryFilters>(
    PrimaryFilters.COMPANY
  );

  useSingleOrMoreFilterState(
    SelectionCategories.PRIMARY_FILTER,
    pipe(
      tap((filter) => {
        const primaryFilterObject = get(filter, 'value.id');
        setPrimaryFilter(primaryFilterObject);
      })
    )
  );

  const dateOptions = [
    { label: 'Last 6 Months', value: 6, filterName: 'Last 6 Months' },
    { label: 'Last Year', value: 12, filterName: 'Last Year' },
    { label: 'Last 2 Years', value: 24, filterName: 'Last 2 Years' },
    { label: 'Last 5 Years', value: 60, filterName: 'Last 5 Years' },
    { label: 'Last 10 Years', value: 120, filterName: 'Last 10 Years' },
    { label: 'Max', value: -1, filterName: 'Max' },
  ];

  const prefixMap: any = {
    salary: '$',
  };

  const suffixMap: any = {
    hiring: '%',
    attrition: '%',
    growth: '%',
    tenure: ' years',
  };

  const filtersToAllowNegative = [
    SelectionCategories.HIRING_RATE,
    SelectionCategories.ATTRITION_RATE,
    SelectionCategories.GROWTH_RATE,
  ] as (SelectionCategories | LocalSelectionCategories)[];

  const currentFilter = activeFilters?.find(
    (f) => f.id === selectValue.filterName
  ) as FilterItem<any> | undefined;

  /**
   * updates the startValue, endValue and OPValue states
   *
   * @param currentFilter
   * @param op
   *
   * @returns void
   */

  const setStartEndVals = (
    currentFilter: FilterItem<any>,
    op: OPSelectFilter
  ) => {
    const currentStartVal = [OPValues.BETWEEN].includes(
      op.filterName as OPValues
    )
      ? currentFilter.value.startValue
      : undefined;

    // eslint-disable-next-line no-nested-ternary
    const currentEndVal = [OPValues.BETWEEN, OPValues.LESS].includes(
      op.filterName as OPValues
    )
      ? currentFilter.value.endValue
      : op.filterName === OPValues.GREATER
        ? currentFilter.value.startValue
        : undefined;

    // use current filter data
    setOpValue(op);
    setStartValue(currentStartVal);
    setEndValue(currentEndVal);
  };

  useEffect(() => {
    if (
      ![
        SelectionCategories.COMPANY_CLEANED,
        SelectionCategories.INDUSTRY,
      ].includes(selectValue?.filterName as SelectionCategories)
    ) {
      if (currentFilter) {
        setStartEndVals(currentFilter, currentFilter.value.opValue);
        setDateValue(currentFilter.value.date);

        if (dateValue !== defaultDateVal) {
          setDateToggle(true);
        }

        if (
          currentFilter?.value?.employeeTypes &&
          (Object.values(currentFilter.value.employeeTypes) as any).some(
            (t: any) => t.length > 0
          )
        ) {
          setEmployeeToggle(true);
        }
      } else {
        // no active filter, use defaults

        setOpValue(defaultOPVal);
        setDateValue(defaultDateVal);
        setStartValue(screenerDefaults[selectValue.filterName].start);
        setEndValue(screenerDefaults[selectValue.filterName].end);
      }
    } else if (
      selectValue?.filterName === SelectionCategories.COMPANY_CLEANED
    ) {
      const companyFilter = activeFilters?.find(
        (f) => f.id === SelectionCategories.COMPANY_CLEANED
      ) as FilterItem<any> | undefined;

      if (companyFilter?.value.length === 0) {
        deleteFilter(SelectionCategories.COMPANY_CLEANED);
      }

      setCompanyValue(companyFilter ? companyFilter.value : []);
    }
    // eslint-disable-next-line
  }, [selectValue, activeFilters]);

  /**
   * updates the tempEmpTypes state with current selections
   *
   * @param vals - selected values
   * @param category - selection category that the values are for
   *
   * @returns void
   */
  const handleSetTempEmps = (vals: any, category: SelectionCategories) => {
    setTempEmpTypes((prev: any) => ({
      ...prev,
      [category]: Object.values(vals).map((v: any) => v.item),
    }));
  };

  return (
    <>
      {/* Operator Selection & Employee Type */}
      {![
        SelectionCategories.INDUSTRY,
        SelectionCategories.COMPANY_CLEANED,
        SelectionCategories.JOB_CATEGORY,
        SelectionCategories.SENIORITY,
        SelectionCategories.REGION,
        SelectionCategories.DATE_RANGE,
        undefined,
      ].includes(selectValue?.filterName as any) && (
        <>
          <Select
            name={'headcount-operator-select'}
            placeholder="Select a filter"
            isMulti={false}
            options={operatorOptions}
            value={opValue}
            defaultValue={{
              filterName: OPValues.BETWEEN,
              value: OPValues.BETWEEN,
              label: 'is between',
            }}
            onChange={(e) => setOpValue(e)}
            size="sm"
            chakraStyles={{
              dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
                ...prev,
                '> svg': {
                  transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
                },
              }),
            }}
          />
          <FormControl
            isInvalid={
              startValue !== undefined &&
              endValue !== undefined &&
              opValue.filterName === OPValues.BETWEEN &&
              startValue > endValue
            }
          >
            <Flex align="center">
              {opValue?.value === OPValues.BETWEEN && (
                <>
                  <NumberFormat
                    customInput={Input}
                    value={startValue}
                    thousandSeparator={true}
                    allowNegative={filtersToAllowNegative.includes(
                      selectValue?.filterName
                    )}
                    onValueChange={(val: any) => setStartValue(val.floatValue)}
                    prefix={
                      prefixMap[selectValue?.value as keyof typeof prefixMap]
                    }
                    suffix={
                      suffixMap[selectValue?.value as keyof typeof suffixMap]
                    }
                    type="text"
                    size="sm"
                    width="100%"
                    flex="1"
                    data-testid="range-num-input-start"
                  />
                  <Text px="2" fontSize="sm">
                    to
                  </Text>
                </>
              )}
              <NumberFormat
                customInput={Input}
                value={endValue}
                thousandSeparator={true}
                allowNegative={filtersToAllowNegative.includes(
                  selectValue?.filterName
                )}
                onValueChange={(val: any) => setEndValue(val.floatValue)}
                prefix={prefixMap[selectValue?.value as keyof typeof prefixMap]}
                suffix={suffixMap[selectValue?.value as keyof typeof suffixMap]}
                type="text"
                size="sm"
                width="100%"
                flex="1"
                data-testid="range-num-input-end"
              />
            </Flex>
            <FormErrorMessage>
              Start value cannot be greater than the end value!
            </FormErrorMessage>
          </FormControl>
          <Divider pt={2} />
          {/* Date Range */}
          {screenerFiltersWithTimeComponent.includes(
            selectValue?.filterName as any
          ) &&
            dateToggle && (
              <>
                <Text fontSize="13px" fontWeight="semibold" pt={2}>
                  Date Range
                </Text>
                <Select
                  name={'date-select'}
                  placeholder="Select a date"
                  isMulti={false}
                  options={dateOptions}
                  value={dateValue}
                  onChange={(e) => setDateValue(e)}
                  size="sm"
                  chakraStyles={{
                    dropdownIndicator: (
                      prev,
                      { selectProps: { menuIsOpen } }
                    ) => ({
                      ...prev,
                      '> svg': {
                        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
                      },
                    }),
                  }}
                />
              </>
            )}
          {employeeToggle &&
            ![SelectionCategories.COUNT].includes(
              selectValue?.filterName as any
            ) && (
              <>
                <Text fontSize="13px" fontWeight="semibold" pt={2}>
                  Employee Type
                </Text>
                {selectValue && (
                  <>
                    {/* TODO: refactor/abstract this section of code */}
                    {[
                      PrimaryFilters.COMPANY,
                      PrimaryFilters.GEOGRAPHY,
                    ].includes(primaryFilter as PrimaryFilters) && (
                      <TreeRef
                        key="job-category"
                        placeholder={SelectionCategories.JOB_CATEGORY}
                        filterName={SelectionCategories.JOB_CATEGORY}
                        selectionLists={[SelectionCategories.JOB_CATEGORY]}
                        submitOnBlur={false}
                        setTempSelections={(val: any) =>
                          handleSetTempEmps(
                            val,
                            SelectionCategories.JOB_CATEGORY
                          )
                        }
                        limit={limit}
                        ref={(element: any) =>
                          (forwardedRef.current['screener-job-category'] = {
                            filterName: 'screener-job-category',
                            value: element,
                          })
                        }
                        screenerFilter={selectValue.filterName}
                        isDropdown
                      />
                    )}
                    {[
                      PrimaryFilters.COMPANY,
                      PrimaryFilters.GEOGRAPHY,
                      PrimaryFilters.ROLE,
                    ].includes(primaryFilter as PrimaryFilters) && (
                      <TreeRef
                        key="seniority"
                        placeholder={SelectionCategories.SENIORITY}
                        filterName={SelectionCategories.SENIORITY}
                        selectionLists={[SelectionCategories.SENIORITY]}
                        submitOnBlur={false}
                        setTempSelections={(val: any) =>
                          handleSetTempEmps(val, SelectionCategories.SENIORITY)
                        }
                        limit={limit}
                        ref={(element: any) =>
                          (forwardedRef.current['screener-seniority'] = {
                            filterName: 'screener-seniority',
                            value: element,
                          })
                        }
                        screenerFilter={selectValue.filterName}
                        isDropdown
                      />
                    )}
                    {[PrimaryFilters.COMPANY, PrimaryFilters.ROLE].includes(
                      primaryFilter as PrimaryFilters
                    ) && (
                      <TreeRef
                        key="regions"
                        placeholder={SelectionCategories.REGION}
                        filterName={SelectionCategories.REGION}
                        selectionLists={[SelectionCategories.REGION]}
                        submitOnBlur={false}
                        setTempSelections={(val: any) =>
                          handleSetTempEmps(val, SelectionCategories.REGION)
                        }
                        limit={limit}
                        ref={(element: any) =>
                          (forwardedRef.current['screener-regions'] = {
                            filterName: 'screener-regions',
                            value: element,
                          })
                        }
                        screenerFilter={selectValue.filterName}
                        isDropdown
                      />
                    )}
                    {[PrimaryFilters.GEOGRAPHY, PrimaryFilters.ROLE].includes(
                      primaryFilter as PrimaryFilters
                    ) && (
                      <TreeRef
                        key="industry"
                        placeholder={SelectionCategories.INDUSTRY}
                        filterName={SelectionCategories.INDUSTRY}
                        selectionLists={[SelectionCategories.INDUSTRY]}
                        submitOnBlur={false}
                        setTempSelections={(val: any) =>
                          handleSetTempEmps(val, SelectionCategories.INDUSTRY)
                        }
                        limit={limit}
                        ref={(element: any) =>
                          (forwardedRef.current['screener-industry'] = {
                            filterName: 'screener-industry',
                            value: element,
                          })
                        }
                        screenerFilter={selectValue.filterName}
                        isDropdown
                      />
                    )}
                  </>
                )}
              </>
            )}
          {![SelectionCategories.COUNT].includes(
            selectValue?.filterName as any
          ) && (
            <Button
              variant={employeeToggle ? 'removecompany' : 'addcompany'}
              leftIcon={
                employeeToggle ? (
                  <MinusIcon boxSize={3} />
                ) : (
                  <AddIcon boxSize={3} />
                )
              }
              padding={0}
              onClick={() => setEmployeeToggle(!employeeToggle)}
              justifyContent="left"
              width="fit-content"
            >
              {employeeToggle
                ? 'Remove employee type segment'
                : 'Segment by employee type'}
            </Button>
          )}
        </>
      )}

      {/* Company Names */}
      {selectValue?.filterName == SelectionCategories.COMPANY_CLEANED && (
        <CreatableSelect
          name={'headcount-operator-select'}
          placeholder="Add Company"
          isMulti
          options={[]}
          value={companyValue}
          onChange={(e) => setCompanyValue(e)}
          size="sm"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          noOptionsMessage={() => 'Start typing to add a company'}
          formatCreateLabel={(inputText) => `"Add ${inputText}"`}
        />
      )}
    </>
  );
}

const withScreenerRef = (
  ScreenerComponent: React.FunctionComponent<ScreenerMenuProps>
) => {
  return forwardRef(({ ...rest }: ScreenerMenuProps, ref) => (
    <ScreenerComponent {...rest} forwardedRef={ref} />
  ));
};
export const ScreenerRef = withScreenerRef(ScreenerMenu);

export default ScreenerMenu;
