import {
  CompensationDimension,
  CompensationFilters,
  Dimension1,
} from '@revelio/data-access';
import { PrimaryFilters } from '@revelio/core';
import {
  EnumeratedFilters,
  GET_COMPENSATION_HELPER_DATA,
  ValidValueTypes,
} from '@revelio/filtering';
import { get, isEqual } from 'lodash';
import { Client } from 'urql';

const defaultValueLookup: { [key in PrimaryFilters]?: unknown } = {
  [PrimaryFilters.COMPANY]: { company: [861] },
  [PrimaryFilters.GEOGRAPHY]: { msa: [2] },
  [PrimaryFilters.ROLE]: { role_k150: [107] },
};

const defaultTopEntitiesLookup: { [key in PrimaryFilters]?: unknown } = {
  [PrimaryFilters.COMPANY]: {
    top_geographies: [1, 5, 3, 12, 2, 6].map((id) => ({ id, label: id })),
    top_roles: [2, 1, 3, 6, 4, 7].map((id) => ({ id, label: id })),
  },
  [PrimaryFilters.GEOGRAPHY]: {
    top_companies: [18334, 4983, 3904, 5559, 3295, 6399].map((id) => ({
      id,
      label: id,
    })),
    top_roles: [1, 7, 2, 6, 3, 4].map((id) => ({ id, label: id })),
  },
  [PrimaryFilters.ROLE]: {
    top_companies: [3094, 2312, 7914, 18338, 4822, 4957].map((id) => ({
      id,
      label: id,
    })),
    top_geographies: [1, 2, 3, 5, 4, 6].map((id) => ({ id, label: id })),
  },
};

export function isDefaultsSoProvideTopEntitiesOverQuering(
  primaryFilter: PrimaryFilters,
  serializedPayFilters: Partial<
    EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>
  >
) {
  const defaultValue = defaultValueLookup[primaryFilter];
  const filterStateMatches = isEqual(defaultValue, serializedPayFilters);

  if (filterStateMatches) {
    return defaultTopEntitiesLookup[primaryFilter];
  }

  return false;
}

interface IGetTopEntityFilters {
  client: Client;
  dim1: Dimension1;
  filteredFilters: Partial<
    EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>
  >;
}

export async function getTopEntityFilters({
  client,
  dim1,
  filteredFilters,
}: IGetTopEntityFilters) {
  const queryVariablesGeography = {
    filters: filteredFilters as CompensationFilters,
    dim1: dim1 as Dimension1,
    dim2: CompensationDimension.Geography,
  };

  const queryVariablesCompany = {
    filters: filteredFilters as CompensationFilters,
    dim1: dim1 as Dimension1,
    dim2: CompensationDimension.Company,
  };

  const queryVariablesRole = {
    filters: filteredFilters as CompensationFilters,
    dim1: dim1 as Dimension1,
    dim2: CompensationDimension.Role,
  };

  const { data: topGeographyData } = await client
    .query(GET_COMPENSATION_HELPER_DATA, queryVariablesGeography)
    .toPromise();

  const { data: topCompanyData } = await client
    .query(GET_COMPENSATION_HELPER_DATA, queryVariablesCompany)
    .toPromise();

  const { data: topRoleData } = await client
    .query(GET_COMPENSATION_HELPER_DATA, queryVariablesRole)
    .toPromise();

  type CompensationData = {
    __typename?: 'CompensationResponse';
    metadata?: {
      shortName: string;
      id: number;
      // Other properties...
    } | null;
  };

  let topGeos: (CompensationData | null | undefined)[] = get(
    topGeographyData,
    'compensation[0].category',
    []
  ) as (CompensationData | null | undefined)[];

  let topCompanies: (CompensationData | null | undefined)[] = get(
    topCompanyData,
    'compensation[0].category',
    []
  ) as (CompensationData | null | undefined)[];

  let topRoles: (CompensationData | null | undefined)[] = get(
    topRoleData,
    'compensation[0].category',
    []
  ) as (CompensationData | null | undefined)[];

  topGeos = topGeos
    ? topGeos
        .map((data) => (data?.metadata ? { metadata: data.metadata } : null))
        .slice(0, 6)
    : [];

  topCompanies = topCompanies
    ? topCompanies
        .map((data) => (data?.metadata ? { metadata: data.metadata } : null))
        .slice(0, 6)
    : [];

  topRoles = topRoles
    ? topRoles
        .map((data) => (data?.metadata ? { metadata: data.metadata } : null))
        .slice(0, 6)
    : [];

  return { topGeos, topCompanies, topRoles };
}
