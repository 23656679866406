import { select } from 'd3';

/**
 * takes an array of selectors and removes the elements
 * with those matching selectors from the DOM.
 *
 * @param {string[]} selectors - array of selectors
 */
export const removeSVG = (selectors) => {
  selectors.forEach((selector) => {
    select(selector).remove();
  });
};
