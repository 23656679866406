import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'categoryHeader',
  'sectionTitle',
  'sectionDetails',
  'sectionDate',
  'enrichedData',
  'enrichedDataLabel',
  'enrichedDataValue',
  'enrichedDataLink',
  'gridBox',
  'chip',
  'percentageContainer',
  'percentageBox',
  'percentageBoxFilled',
]);

export const Resume = helpers.defineMultiStyleConfig({
  baseStyle: {
    categoryHeader: {
      width: '100%',
      borderBottom: '1px solid',
      borderBottomColor: 'navyBlue.500',
      backgroundColor: 'white',
      fontWeight: '600',
      fontSize: '16px',
      textTransform: 'uppercase',
      height: '24px',
    },
    sectionTitle: {
      color: 'navyBlue.500',
      fontSize: '14px',
      fontWeight: '600',
    },
    sectionDetails: {
      color: 'navyBlue.500',
      fontSize: '12px',
    },
    sectionDate: {
      opacity: '70%',
      color: 'navyBlue.500',
      fontSize: '12px',
    },
    enrichedDataLabel: {
      color: 'navyBlue.500',
      fontSize: '10px',
      fontWeight: '600',
      width: '100px',
      minWidth: '100px',
    },
    enrichedDataValue: {
      color: 'navyBlue.500',
      opacity: '70%',
      fontSize: '10px',
      flex: 1,
    },
    enrichedDataLink: {
      color: '#35ABDD',
      fontSize: '10px',
      fontWeight: '600',
      textDecoration: 'underline',
    },
    enrichedData: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '4px',
    },
    percentageContainer: {
      display: 'flex',
      gap: '2px',
    },
    percentageBox: {
      height: '8px',
      width: '8px',
      borderRadius: '1px',
      backgroundColor: 'gray.300',
    },
    percentageBoxFilled: {
      height: '8px',
      width: '8px',
      borderRadius: '1px',
      backgroundColor: '#35ABDD',
    },
    gridBox: {
      backgroundColor: 'white',
      p: '10px 20px',
    },
    viewMoreButton: {
      color: 'ligtBlue.500',
    },
    divider: {
      height: '1px',
      borderBottom: '1px solid',
      borderBottomColor: 'gray.300',
      borderBottomStyle: 'dashed',
    },
    dividerBox: {
      p: '10px 20px',
      backgroundColor: 'white',
    },
    chip: {
      borderRadius: 'full',
      fontSize: '10px',
      textTransform: 'uppercase',
      fontWeight: '600',
      padding: '0px 8px',
      width: 'fit-content',
    },
    placeholderBox: {
      bg: 'white',
    },
  },
  sizes: {
    sm: {},
    md: {},
  },
  variants: {
    resume: {
      categoryHeader: {},
    },
    pdf: {
      enrichedDataLabel: {},
      chip: {},
      categoryHeader: {
        backgroundColor: 'transparent',
      },
      sectionTitle: {},
      enrichedDataLink: {},
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'resume',
  },
});
