import { fromFetch } from 'rxjs/fetch';
import { of } from 'rxjs';

interface FetchResultsProps {
  body: any;
  apiEndpoint: string;
}

export const fetchResults = (props: FetchResultsProps) => {
  const { body, apiEndpoint } = props;

  return fromFetch(apiEndpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body,
    selector: async (resp) => {
      if (resp.status === 200) {
        const json = await resp.json();

        return json;
      }

      return of([]);
    },
  });
};
