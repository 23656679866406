const settingsFlowMock = {
  id: 'e294ec7c-9285-4b62-9d28-a90ca7be03eb',
  type: 'browser',
  expires_at: '2023-03-13T12:44:48.906151Z',
  issued_at: '2023-03-13T11:44:48.906151Z',
  request_url:
    'https://ory-kratos.us-east-2.cloud.reveliolabs.com/self-service/settings/browser',
  ui: {
    action:
      'https://kratos.reveliolabs.com/self-service/settings?flow=e294ec7c-9285-4b62-9d28-a90ca7be03eb',
    method: 'POST',
    nodes: [
      {
        type: 'input',
        group: 'default',
        attributes: {
          name: 'csrf_token',
          type: 'hidden',
          value:
            'WD3QdduT/+WdlT6v8qQWgpnrDgyORdEAUYG0UHUw6xYiYxMYj15Z7mkZyV4TavOoOAmVc1t9tbe8mE87Gz1xIA==',
          required: true,
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: {},
      },
      {
        type: 'input',
        group: 'profile',
        attributes: {
          name: 'traits.email',
          type: 'email',
          value: 'vasily@reveliolabs.com',
          autocomplete: 'email',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070002, text: 'E-Mail', type: 'info' } },
      },
      {
        type: 'input',
        group: 'profile',
        attributes: {
          name: 'traits.username',
          type: 'text',
          value: 'vasily@reveliolabs.com',
          required: true,
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: {},
      },
      {
        type: 'input',
        group: 'profile',
        attributes: {
          name: 'traits.name',
          type: 'text',
          value: 'Vasily Shelkov',
          required: true,
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: {},
      },
      {
        type: 'input',
        group: 'profile',
        attributes: {
          name: 'traits.legacyUserID',
          type: 'text',
          value: '813930831910273049',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: {},
      },
      {
        type: 'input',
        group: 'profile',
        attributes: {
          name: 'method',
          type: 'submit',
          value: 'profile',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070003, text: 'Save', type: 'info' } },
      },
      {
        type: 'input',
        group: 'password',
        attributes: {
          name: 'password',
          type: 'password',
          required: true,
          autocomplete: 'new-password',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070001, text: 'Password', type: 'info' } },
      },
      {
        type: 'input',
        group: 'password',
        attributes: {
          name: 'method',
          type: 'submit',
          value: 'password',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070003, text: 'Save', type: 'info' } },
      },
    ],
  },
  identity: {
    id: '4a064d83-2e39-4f16-a547-9b235a001aa0',
    schema_id: 'revelio-v3',
    schema_url: 'https://kratos.reveliolabs.com/schemas/cmV2ZWxpby12Mw',
    state: 'active',
    state_changed_at: '2023-03-10T03:19:41.148652Z',
    traits: {
      name: 'Vasily Shelkov',
      email: 'vasily@reveliolabs.com',
      username: 'vasily@reveliolabs.com',
      legacyUserID: '813930831910273049',
    },
    recovery_addresses: [
      {
        id: 'd8564e15-56cf-4448-bb3c-5fd2b2a93bf7',
        value: 'vasily@reveliolabs.com',
        via: 'email',
        created_at: '2023-03-10T03:19:41.151426Z',
        updated_at: '2023-03-10T03:19:41.151426Z',
      },
    ],
    metadata_public: { terms_accepted: 'true', tour_completed: 'true' },
    created_at: '2023-03-10T03:19:41.150028Z',
    updated_at: '2023-03-10T03:19:41.150028Z',
  },
  state: 'show_form',
};

export default settingsFlowMock;
