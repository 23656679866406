export const Logo = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_358_2)">
        <path
          d="M23.26 0H22.5C10.109 0 0 10.109 0 22.5C0 34.891 10.109 45 22.5 45C34.891 45 45 34.891 45 22.5V21.74L23.26 0Z"
          fill="url(#paint0_linear_358_2)"
        />
        <path
          d="M30.9868 13.922C30.9868 13.4128 30.574 13 30.0648 13H27.5703C27.0656 13 26.6547 13.4058 26.6484 13.9105L26.6252 15.7692V33.0549C26.6252 33.5641 27.038 33.9769 27.5472 33.9769H30.0648C30.574 33.9769 30.9868 33.5641 30.9868 33.0549V13.922Z"
          fill="#EBEBEB"
        />
        <path
          d="M18.3615 20.9845V19.5065C18.3615 18.9973 17.9487 18.5845 17.4395 18.5845H14.922C14.4128 18.5845 14 18.9973 14 19.5065V33.0779C14 33.5871 14.4128 33.9999 14.922 33.9999H17.4395C17.9487 33.9999 18.3615 33.5871 18.3615 33.0779V28.2999C18.3615 23.6614 20.1385 22.1845 23.0462 22.1845C23.3054 22.1845 23.5197 21.9825 23.5351 21.7238L23.6833 19.2339C23.7137 18.7233 23.322 18.2794 22.8107 18.2962C20.6859 18.3659 19.1509 19.1426 18.3615 20.9845Z"
          fill="#EBEBEB"
        />
        <path
          d="M30.9868 12.922C30.9868 12.4128 30.574 12 30.0648 12H27.5703C27.0656 12 26.6547 12.4058 26.6484 12.9105L26.6252 14.7692V32.0549C26.6252 32.5641 27.038 32.9769 27.5472 32.9769H30.0648C30.574 32.9769 30.9868 32.5641 30.9868 32.0549V12.922Z"
          fill="#2D426A"
        />
        <path
          d="M18.3615 19.9845V18.5065C18.3615 17.9973 17.9487 17.5845 17.4395 17.5845H14.922C14.4128 17.5845 14 17.9973 14 18.5065V32.0779C14 32.5871 14.4128 32.9999 14.922 32.9999H17.4395C17.9487 32.9999 18.3615 32.5871 18.3615 32.0779V27.2999C18.3615 22.6614 20.1385 21.1845 23.0462 21.1845C23.3054 21.1845 23.5197 20.9825 23.5351 20.7238L23.6833 18.2339C23.7137 17.7233 23.322 17.2794 22.8107 17.2962C20.6859 17.3659 19.1509 18.1426 18.3615 19.9845Z"
          fill="#2D426A"
        />
        <path
          opacity="0.08"
          d="M23.26 0L45 21.74C31.304 21.74 23.26 13.913 23.26 0Z"
          fill="black"
        />
        <path
          d="M45.0001 21.7399C44.4561 21.7399 43.9131 21.7399 43.4781 21.6299C32.9351 20.5439 24.5651 12.1739 23.4781 1.62989C23.4781 1.08689 23.3701 0.651887 23.3701 0.108887L45.0001 21.7399Z"
          fill="#48EB8F"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_358_2"
          x1="22.5"
          y1="0"
          x2="22.5"
          y2="45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_358_2">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
