import { RouteNavConfig } from '@revelio/auth';
import { isObservable } from 'rxjs';
import { navRouteItems } from './routes';
import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { RequireAuth } from './ProtectedRoute';

const flattenNavRouteItems = (items: RouteNavConfig[]): RouteNavConfig[] => {
  return items.reduce((acc, item) => {
    if (item.children && !isObservable(item.children)) {
      return [
        ...acc,
        ...flattenNavRouteItems(item.children),
      ] as RouteNavConfig[];
    }
    return [...acc, item] as RouteNavConfig[];
  }, [] as RouteNavConfig[]);
};

export const reducedRoutes = flattenNavRouteItems(navRouteItems).reduce<
  ReactElement[]
>((routes, r, i) => {
  if (!r.element) {
    return routes;
  }
  const hasNestedRoutes = !!r.nestedRoutes?.length;
  routes.push(
    <Route
      key={i}
      path={r.path}
      element={
        <RequireAuth
          tab={r.serverTab}
          adminRequired={r.admin}
          clientAdminRequired={r.clientAdmin}
        >
          {r.element}
        </RequireAuth>
      }
    >
      {hasNestedRoutes &&
        r.nestedRoutes?.map((nr) => (
          <Route key={i} path={nr.path} element={nr.element} />
        ))}
    </Route>
  );
  return routes;
}, []);
