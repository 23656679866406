import { useEffect, useRef, useState } from 'react';

export const useResizeObserver = (): {
  containerRef: React.RefObject<HTMLDivElement>;
  width: number;
  height: number;
} => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  /** ======== Resize Observer ======== */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = container.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    });
    resizeObserver.observe(container);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { containerRef, width, height };
};
