import {
  downloadFile,
  filterNil,
  toggleStatusOnGlobalLoaderOrSkipOne,
} from '@revelio/core';
import {
  isString,
  isUndefined,
  startCase,
  mergeWith,
  has,
  get,
  noop,
} from 'lodash';
import { useRef } from 'react';
import { BehaviorSubject, Subject, merge, Observable } from 'rxjs';
import {
  distinct,
  map,
  scan,
  skipWhile,
  take,
  skip,
  filter,
  first,
} from 'rxjs/operators';
import { getPlotEndpointConfig } from '../data-api/data-api';
import {
  DownloadEndpointMapper,
  EndpointMapper,
  EndpointToNameLookup,
  PlotConfigParams,
  PlotConfigMapper,
  PlotConfigProvider,
  PlotConfig,
  SubfilterConfigs,
} from '../data-api/data-api.model';
import { getDataBasedOnActiveFilters } from '../engine/filters.engine';
import {
  DownloadBuildRequest,
  FilterBase,
  SubFilterNames,
} from '../engine/filters.model';

const requiredChartPropsForPlot = ['chartType', 'chartProps'];
const requiredPlotConfigParams = [
  'endpoint',
  'view',
  'viewType',
  ...requiredChartPropsForPlot,
];

export function plotDataDownloader({
  endpoint,
  brokenOutFilterIds,
  additionalNonActiveFilters = [],
  additionalOperatorsBeforeQuery = (o) => o,
  requestMethod,
  customGetRequest,
  callback = noop,
  isGoRequest = false,
  view,
  kibanaLogger,
}: DownloadBuildRequest) {
  toggleStatusOnGlobalLoaderOrSkipOne();
  getDataBasedOnActiveFilters({
    endpoint,
    brokenOutFilterIds,
    additionalNonActiveFilters,
    additionalOperatorsBeforeQuery,
    requestMethod,
    customGetRequest,
    includeInGlobalLoader: false,
    isGoRequest,
    view,
    kibanaLogger,
  })
    .pipe(
      skip(1),
      filter((data: any) => !data.loading),
      take(1)
    )
    .subscribe({
      next: ([fileBlob, responseContentDisposition]) =>
        downloadFile({ fileBlob, responseContentDisposition }),
      error: (e) => console.log(e),
      complete: () => callback(),
    });
}

export const useManyPlotConfigProviders = (configs: PlotConfigParams[]) => {
  const allUpdater = useRef(new Subject<PlotConfigParams>());
  const updaters = useRef(
    configs.map((c) => new BehaviorSubject<PlotConfigParams>(c))
  );

  const breakoutSubfiltersAndMetaData = (inputConfig: PlotConfigParams) => {
    const { subfilters, metaData, ...config } = inputConfig;
    return {
      subfilters,
      metaData,
      config,
    };
  };

  const providers = updaters.current.map((u, i) => {
    const { subfilters, metaData, config } = breakoutSubfiltersAndMetaData(
      configs[i]
    );

    return merge(u.asObservable(), allUpdater.current.asObservable()).pipe(
      distinct(),
      scan<PlotConfigParams, PlotConfigParams>((accParts, part) => {
        const arrayCustomizer = (prev: any, cur: any) => {
          return cur;
        };

        return mergeWith(accParts, part, arrayCustomizer);
      }, config),
      skipWhile((plotConfigParts) => {
        const requiredBrokenOutParams = metaData?.requiredParams || [];

        const brokenOutParams = plotConfigParts?.brokenOutFilterIds || [];

        const requiredPropsProvided = Object.keys(plotConfigParts).filter(
          (key) => requiredPlotConfigParams.includes(key)
        );

        const isAllRequiredBrokenOutParamsProvided =
          requiredBrokenOutParams.every((param) => {
            return brokenOutParams.includes(param);
          });

        return (
          requiredPropsProvided.length !== requiredPlotConfigParams.length &&
          !isAllRequiredBrokenOutParamsProvided
        );
      }),
      map((plotConfig: PlotConfigParams) => {
        return {
          ...(plotConfig.dataFetcher
            ? {}
            : getPlotEndpointConfig({
                ...plotConfig,
                primaryDataView: metaData?.primaryDataView,
                pageGroupName: metaData?.pageGroupName,
              })),
          chartType: plotConfig.chartType,
          chartProps: plotConfig.chartProps,
          additionalNonActiveFilters: plotConfig.additionalNonActiveFilters,
          brokenOutFilterIds: plotConfig.brokenOutFilterIds,
          subfilters: plotConfig.subfilters || subfilters,
          metaData: metaData,
        } as PlotConfigProvider;
      })
    );
  });

  const mappers = useRef(
    configs.map((c, i) => {
      const name = c.endpoint;
      const subfilters =
        c.subfilters ||
        ({
          filterName: '' as unknown as SubFilterNames,
          selectionLists: [],
        } as SubfilterConfigs);
      const metaData = c.metaData;
      const finalName =
        EndpointToNameLookup[name || 'thisKeyDoesntExist'] || startCase(name);

      return {
        name: finalName,
        dataFetcher: c.dataFetcher,
        subfilters: subfilters,
        subfilters$: merge(
          providers[i].pipe(first()),
          updaters.current[i]
        ).pipe(
          filter((updates) => {
            return has(updates, 'subfilters');
          }),
          map((updates) => get(updates, 'subfilters'))
        ) as Observable<PlotConfigParams['subfilters']>,
        metaData: metaData,
        metaData$: merge(providers[i].pipe(first()), updaters.current[i]).pipe(
          filter((updates) => has(updates, 'metaData')),
          map((updates) => get(updates, 'metaData'))
        ),
        brokenOutFilterIds: providers[i].pipe(
          filter((updates) => has(updates, 'brokenOutFilterIds')),
          map<any, FilterBase['id'][]>((updates) =>
            get(updates, 'brokenOutFilterIds')
          )
        ),
        additionalNonActiveFilters: providers[i].pipe(
          filter((updates) => has(updates, 'additionalNonActiveFilters')),
          map<any, FilterBase['id'][]>((updates) =>
            get(updates, 'additionalNonActiveFilters')
          )
        ),
        // eslint-disable-next-line no-nested-ternary
        endpointSegment: isString(c)
          ? undefined
          : c.url instanceof URL
            ? c.url.pathname
            : c.endpoint,
        updater: updaters.current[i],
        fullPlotMapper: providers[i],
        urlMapper: providers[i].pipe(
          map((item) => {
            const { url, endpointPath, error } = item;
            return {
              url,
              endpointPath,
              name: finalName,
              error,
            };
          }),
          filterNil()
        ) as EndpointMapper,
        endpointMapper: providers[i].pipe(
          map((item) => {
            const { endpointPath, name, error, url } = item;
            return { url, endpointPath, name, error };
          })
        ) as EndpointMapper,
        // alias for prefetching since it's set to that name on plotConfig and preFetch doesn't do that mapping for us
        get endpoint() {
          return this.endpointMapper;
        },
        downloadEndpointMapper: providers[i].pipe(
          map((item) => {
            const {
              downloadEndpointPath,
              name,
              error,
              metaData,
              goDownloadEndpointPath,
            } = item;
            const isGoRequest = get(metaData, 'isGoRequest', false);
            return {
              endpointPath: isGoRequest
                ? goDownloadEndpointPath
                : downloadEndpointPath,
              name,
              error,
            };
          })
        ) as DownloadEndpointMapper,
        // downloadTrigger: null,
        plotConfigMapper: providers[i].pipe(
          map((item) => {
            const { chartProps, chartType } = item;
            return { chartProps, chartType };
          })
        ) as PlotConfigMapper,
        // NOTE: this is to provide data the Plot produces back up to a higher level component, not to provide data to the plot
        dataProvider: new BehaviorSubject<any[] | { [key: string]: any }>([]),
      };
    })
  );

  const dataProviders = useRef(
    mappers.current.reduce(
      (dp, m) => {
        dp[m.name] = m.dataProvider.asObservable();
        return dp;
      },
      {} as { [key: string]: Observable<any[] | { [key: string]: any }> }
    )
  );

  return {
    allUpdater: allUpdater.current,
    mappers: mappers.current,
    dataProviders: dataProviders.current,
  };
};

export const useManyPreFetchPlotConfigProviders = (
  plotConfigsMap: PlotConfigParams[]
) => {
  const [firstConfig, nextConfig] = plotConfigsMap.reduce(
    (result, config) => {
      const [curConfig, prefetchConfig] = result;
      const { preFetchConfig, ...plotConfigs } = config;
      curConfig.push(plotConfigs);
      if (preFetchConfig) {
        prefetchConfig.push(preFetchConfig);
      }
      return [curConfig, prefetchConfig];
    },
    [[], []] as [PlotConfig[], PlotConfig[]]
  );

  const { allUpdater, mappers: currentConfigMappers } =
    useManyPlotConfigProviders(firstConfig);

  const { allUpdater: preFetchAllUpdater, mappers: preFetchConfigMappers } =
    useManyPlotConfigProviders(nextConfig);

  return {
    allUpdater: { allUpdater, preFetchAllUpdater },
    mappers: { currentConfigMappers, preFetchConfigMappers },
  };
};

export const provideBasePlotConfigDefaults = (
  configDefaults: PlotConfigParams
) => {
  return (config: PlotConfigParams) => {
    const { preFetchConfig: _preFetchConfigDefaults, ..._configDefaults } =
      configDefaults;
    const { preFetchConfig: _preFetchConfig, ..._config } = config;
    const defaultMetadata = isUndefined(configDefaults?.metaData)
      ? {}
      : _configDefaults.metaData;
    const metadata = isUndefined(_config?.metaData) ? {} : _config.metaData;
    const defaultChartProps = isUndefined(configDefaults?.chartProps)
      ? {}
      : _configDefaults.chartProps;
    const chartProps = isUndefined(config?.chartProps) ? {} : config.chartProps;
    const preFetchConfigDefaults = isUndefined(_preFetchConfigDefaults)
      ? {}
      : _preFetchConfigDefaults;
    const preFetchConfig = isUndefined(_preFetchConfig) ? {} : _preFetchConfig;

    return {
      ..._configDefaults,
      ..._config,
      metaData: {
        ...defaultMetadata,
        ...metadata,
      },
      chartProps: {
        ...defaultChartProps,
        ...chartProps,
      },
      preFetchConfig: {
        ..._configDefaults,
        ..._config,
        ...preFetchConfigDefaults,
        ...preFetchConfig,
      },
    };
  };
};
