import { hsl } from 'd3';
import { set } from 'lodash';

export const lighten = (color, lightness) => {
  const hslConfig = hsl(color);

  set(hslConfig, 'l', lightness);

  return hslConfig?.formatHsl();
};
