import { NodeApi, NodeRendererProps } from 'react-arborist';
import { Flex } from '@chakra-ui/layout';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/layout';
import { Checkbox, Icon, IconProps } from '@chakra-ui/react';
import styles from './node.module.css';
import { MouseEventHandler } from 'react';
import { NodeData } from './tree-selection';
import classNames from 'classnames';

type NodeProps = {
  className?: string;
  selected: boolean;
  halfCheck: boolean;
  onToggle: (node: NodeApi<NodeData>) => void;
  isDrillModeEnabled?: boolean;
  drillIntoNode?: (node: NodeApi<NodeData>) => void;
};

const iconProps: IconProps = {
  'aria-label': 'node toggle button',
  boxSize: 6,
  color: 'text.primary',
  marginTop: '-2px',
  outline: 'none',
};

export const Node = ({
  node,
  className,
  selected,
  style,
  halfCheck,
  onToggle,
  isDrillModeEnabled = false,
  drillIntoNode,
}: NodeRendererProps<NodeData> & NodeProps) => {
  const handleClickExpandNode: MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    node.toggle();
  };

  const handleClickNodeDrill: MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    drillIntoNode?.(node);
  };

  return (
    <Flex
      {...(!isDrillModeEnabled && { style })}
      className={classNames(styles.nodeContainer, className)}
      onClick={(event) => {
        event.stopPropagation();
        onToggle(node);
      }}
      tabIndex={0}
    >
      <Flex alignItems="center" justify="space-between" w="100%">
        <Flex w="100%" overflow="hidden">
          {!isDrillModeEnabled && (
            <Flex
              visibility={
                node.children && node.children.length > 0 ? 'visible' : 'hidden'
              }
            >
              <Icon
                {...iconProps}
                data-testid="popout-menu"
                as={node.isOpen ? ChevronDownIcon : ChevronRightIcon}
                onClick={handleClickExpandNode}
              />
            </Flex>
          )}

          <Checkbox
            isChecked={selected}
            isIndeterminate={halfCheck && !selected}
            colorScheme="green"
            variant="node-checkbox"
            w="90%"
          >
            <Text className={styles.label}>{node.data.name}</Text>
          </Checkbox>
        </Flex>
        {isDrillModeEnabled && node.children && node.children.length > 0 && (
          <Icon
            {...iconProps}
            data-testid="popout-menu"
            as={ChevronRightIcon}
            onClick={handleClickNodeDrill}
          />
        )}
      </Flex>
    </Flex>
  );
};
