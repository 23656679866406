import {
  Icon,
  IconButton,
  IconProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
  Portal,
  PortalProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { WithTooltip } from '../with-tooltip/with-tooltip';
import { UserTrackingEvents } from '../logger/analytics';
import { emitMixpanelUserEvent } from '../../utils/mixpanel-user-events';
import { IconType } from 'react-icons/lib';

interface WithPortalProps extends PortalProps {
  // boolean condition to determine when to wrap
  cond: boolean;

  // child elements to wrap
  children: ReactNode;
}

/**
 * WithPortal HOC
 * ---------------
 * wraps child elements in a chakra ui Portal if condition is met.
 *
 */
const WithPortal = ({ cond, children, ...portalProps }: WithPortalProps) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return cond ? <Portal {...portalProps}>{children}</Portal> : <>{children}</>;
};

export interface ActionMenuProps {
  children: ReactNode;
  menuButtonIcon: IconType;
  menuButtonVariant?: string;
  preventOverflow?: boolean;
  showOnHover?: boolean;
  portalMenu?: boolean;
  chakraMenuProps?: Partial<MenuProps>;
  menuIconProps?: IconProps;
  menuButtonRef?: React.LegacyRef<HTMLButtonElement>;
  portalProps?: PortalProps;
  menuListClassName?: string;
  testId?: string;
}

export function ActionMenu({
  children,
  menuButtonIcon,
  portalMenu = true,
  showOnHover = true,
  preventOverflow = true,
  menuButtonVariant = 'moreSlim',
  menuIconProps = {},
  chakraMenuProps = {},
  menuButtonRef,
  portalProps,
  menuListClassName,
  testId = 'action-menu-button',
}: ActionMenuProps) {
  return (
    <Menu
      // defaultIsOpen // for testing
      placement="bottom-end"
      autoSelect={false}
      isLazy
      onOpen={() => {
        emitMixpanelUserEvent(UserTrackingEvents.OPEN_MENU);
      }}
      preventOverflow={preventOverflow}
      {...chakraMenuProps}
    >
      <MenuButton
        data-testid={testId}
        as={IconButton}
        aria-label="open options menu"
        icon={<Icon as={menuButtonIcon} boxSize={4} {...menuIconProps} />}
        variant={menuButtonVariant}
        size="xs"
        colorScheme="gray"
        opacity={showOnHover ? 0 : 1}
        _groupHover={{ opacity: 1 }}
        ref={menuButtonRef}
      />
      <WithPortal cond={portalMenu} {...portalProps}>
        <MenuList
          className={menuListClassName}
          pointerEvents="auto"
          minWidth="120px"
          boxShadow="xl"
          data-testid="action-menu-list"
          // workaround to fix focus shadow bug
          // ensures popover has box shadow when open
          sx={{
            ':focus:not(:focus-visible)': {
              shadow:
                '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important',
            },
          }}
        >
          {React.Children.map(children, (child) => {
            const { disabled = false } = (child as JSX.Element)?.props || {};

            return (
              <WithTooltip
                showCondition={disabled}
                variant="label"
                padding="10px 15px"
                label="This feature is not available for trial users"
                aria-label="This feature is not available for trial users"
              >
                <MenuItem
                  isDisabled={disabled}
                  as="span"
                  p="0"
                  _hover={{
                    bg: 'initial',
                  }}
                >
                  {child}
                </MenuItem>
              </WithTooltip>
            );
          })}
        </MenuList>
      </WithPortal>
    </Menu>
  );
}

export default ActionMenu;
