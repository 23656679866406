export const usersQueryData = {
  users: [
    {
      id: '',
      name: 'Harry Potter',
      username: 'TheChosenOne',
      live: true,
      __typename: 'User',
    },
    {
      id: '',
      name: 'Ron Weasley',
      username: 'Ginger',
      live: false,
      __typename: 'User',
    },
  ],
};
