const recoveryFlowSentEmailMock = {
  id: 'fcfeadad-cb7c-46a2-ab20-0b5ac32fcb8d',
  type: 'browser',
  expires_at: '2023-03-14T15:23:24.572481Z',
  issued_at: '2023-03-14T14:23:24.572481Z',
  request_url:
    'https://ory-kratos-testing.us-east-2.cloud.reveliolabs.com/self-service/recovery/browser',
  active: 'code',
  ui: {
    action:
      'https://kratos-stage.reveliolabs.com/self-service/recovery?flow=fcfeadad-cb7c-46a2-ab20-0b5ac32fcb8d',
    method: 'POST',
    nodes: [
      {
        type: 'input',
        group: 'default',
        attributes: {
          name: 'csrf_token',
          type: 'hidden',
          value:
            'izPhspsIB3Q5EQmVHrzNuYLTrfsB4CBT2CfW9rasrnyRkSsetKChkaYEhq0/Q5X193I4Un6U0zvAsIk6ZTKN0Q==',
          required: true,
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: {},
      },
      {
        type: 'input',
        group: 'code',
        attributes: {
          name: 'code',
          type: 'text',
          required: true,
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070006, text: 'Verify code', type: 'info' } },
      },
      {
        type: 'input',
        group: 'code',
        attributes: {
          name: 'method',
          type: 'hidden',
          value: 'code',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: {},
      },
      {
        type: 'input',
        group: 'code',
        attributes: {
          name: 'method',
          type: 'submit',
          value: 'code',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070005, text: 'Submit', type: 'info' } },
      },
      {
        type: 'input',
        group: 'code',
        attributes: {
          name: 'email',
          type: 'submit',
          value: 'vasilydshelkov@gmail.com',
          disabled: false,
          node_type: 'input',
        },
        messages: [],
        meta: { label: { id: 1070008, text: 'Resend code', type: 'info' } },
      },
    ],
    messages: [
      {
        id: 1060003,
        text: 'An email containing a recovery code has been sent to the email address you provided.',
        type: 'info',
        context: {},
      },
    ],
  },
  state: 'sent_email',
};

export default recoveryFlowSentEmailMock;
