import { selectAll } from 'd3';

export const generateInlineStyles = (selectors) => {
  const selectorString = Array.isArray(selectors)
    ? selectors.join(', ')
    : selectors;

  selectAll(selectorString).each(function () {
    const styles = getComputedStyle(this);

    for (let i = 0; i < styles.length; i++) {
      const prop = styles[i];
      const val = styles.getPropertyValue(prop);
      this.style[prop] = val;
    }
  });
};
