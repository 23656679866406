import {
  SelectionCategories,
  FilterContainer,
  useHiddenFiltersWithProvidedValues,
  FilterMenu,
  FilterChips,
  AddEntityButton,
  useViewFilters,
  useSingleOrMoreFilterState,
  useViewFilterDefaults,
  requireAtLeastOneFilterValueOf,
  EndpointSegment,
  provideBasePlotConfigDefaults,
  useManyPlotConfigProviders,
  ViewTypes,
  useStoredFilterSet,
  FilterSets,
  useFilterLimits,
  toggleStatusOnGlobalLoaderOrSkipOne,
  PrimaryFilterLimits,
  FilterMenuLimits,
  LocalSelectionCategories,
  upsertFiltersWithProvidedValue,
  OtherFilterNames,
  SelectFilter,
  FilterList,
  FilterItem,
} from '@revelio/filtering';
import { Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import { pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { D3ChartNames } from '@revelio/d3';
import { useState } from 'react';
import SwitchSelector from 'react-switch-selector';
import {
  PageTitles,
  setGlobalLoaderEnableState,
  Views,
  useResponsivePageGridDefs,
} from '@revelio/core';
import { DefaultCard } from '@revelio/composed';
import DashboardPage from '../DashboardPage';

/* eslint-disable-next-line */
export interface GeographyActivityAnalysisProps {}

export function GeographyActivityAnalysis(
  props: GeographyActivityAnalysisProps
) {
  const {
    templateColumns,
    templateRows,
    bigPlotColSpan,
    bigPlotRowSpan,
    gridItemMinHeight,
    tallGridItemMinHeight,
  } = useResponsivePageGridDefs(Views.ACTIVITY_ANALYSIS);

  const primaryViewFilter = SelectionCategories.JA_MSA;
  const selectableFilters = [
    SelectionCategories.JA_INDUSTRY,
    SelectionCategories.JA_SENIORITY,
    SelectionCategories.JA_ROLE,
  ];

  const additionalOperatorsBeforeQuery = requireAtLeastOneFilterValueOf([
    primaryViewFilter,
  ]);

  useStoredFilterSet({ sharedSetId: FilterSets.GEOGRAPHY });
  useViewFilters([primaryViewFilter, ...selectableFilters]);
  useFilterLimits([
    {
      filterNames: [primaryViewFilter],
      limit: PrimaryFilterLimits.GEO_ACTIVITY_ANALYSIS,
    },
  ]);
  useViewFilterDefaults({
    view: Views.JOB_ANALYSIS,
    viewType: ViewTypes.GEO,
    onlyConsiderTheseFiltersToTriggerDefaults: [primaryViewFilter],
    viewFilters: [primaryViewFilter],
  });
  useHiddenFiltersWithProvidedValues(
    {
      [LocalSelectionCategories.PRIMARY_FILTER]: primaryViewFilter,
    },
    true
  );

  useHiddenFiltersWithProvidedValues(
    {
      [LocalSelectionCategories.INFLOW_OR_OUTFLOW]: OtherFilterNames.INFLOW,
    },
    false
  );

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view: Views.JOB_ANALYSIS,
    viewType: ViewTypes.SHARES,
    brokenOutFilterIds: [LocalSelectionCategories.PRIMARY_FILTER],
  });

  const { allUpdater: updateTaskSkillQual, mappers: taskSkillQualMappers } =
    useManyPlotConfigProviders([
      viewDefaultsForPlots({
        endpoint: EndpointSegment.BROAD_ACTIVITY,
        chartType: D3ChartNames.StackedBarChartHorizontal,
        chartProps: {
          name: 'ja-broad-activity',
          ttMainFormat: '.0%',
          ttSecondaryFormat: ',',
          chartPosition: '',
        },
      }),
      viewDefaultsForPlots({
        endpoint: EndpointSegment.TASK,
        chartType: D3ChartNames.BarChartVertical,
        chartProps: {
          name: 'ja-task',
          yAxisFormat: '.0%',
          ttMainFormat: '.1%',
          ttSecondaryFormat: ',',
          mirror: false,
          colorIndex: 2,
        },
      }),
      viewDefaultsForPlots({
        viewType: ViewTypes.WEIGHT,
        endpoint: EndpointSegment.SKILL,
        chartType: D3ChartNames.BarChartVertical,
        chartProps: {
          name: 'ja-skill',
          yAxisFormat: '.0%',
          ttMainFormat: '.1%',
          ttSecondaryFormat: ',',
          mirror: false,
          colorIndex: 3,
        },
      }),
      viewDefaultsForPlots({
        viewType: ViewTypes.WEIGHT,
        endpoint: EndpointSegment.QUALIFICATION,
        chartType: D3ChartNames.BarChartVertical,
        chartProps: {
          name: 'ja-qualification',
          yAxisFormat: '.0%',
          ttMainFormat: '.1%',
          ttSecondaryFormat: ',',
          mirror: false,
          colorIndex: 4,
        },
      }),
    ]);

  useSingleOrMoreFilterState<SelectFilter<FilterItem | FilterList>>(
    SelectionCategories.JA_MSA,
    pipe(
      tap((filter) => {
        const moreThanOne =
          (filter as SelectFilter<FilterList>)?.value?.length > 1;
        updateTaskSkillQual.next({
          chartProps: {
            mirror: moreThanOne,
          },
        });
      })
    )
  );

  const viewDefaultsForBottomPlots = provideBasePlotConfigDefaults({
    view: Views.JOB_ANALYSIS,
    viewType: ViewTypes.SHARES,
    additionalNonActiveFilters: [],
    brokenOutFilterIds: [],
  });

  const { mappers: bottomPlotsMapper } = useManyPlotConfigProviders([
    viewDefaultsForBottomPlots({
      viewType: ViewTypes.SHARES_SANKEY,
      endpoint: EndpointSegment.TRANSITION,
      chartType: D3ChartNames.SankeyDiagram,
      chartProps: {
        name: 'ja-geo-transition',
        ttMainFormat: ',.0f',
        ttSecondaryFormat: ',.0f',
        inflows: true,
      },
      additionalNonActiveFilters: [LocalSelectionCategories.INFLOW_OR_OUTFLOW],
      brokenOutFilterIds: [
        LocalSelectionCategories.PRIMARY_FILTER,
        LocalSelectionCategories.INFLOW_OR_OUTFLOW,
      ],
    }),
    viewDefaultsForBottomPlots({
      endpoint: EndpointSegment.SALARY,
      chartType: D3ChartNames.Histogram,
      chartProps: {
        name: 'ja-geo-salary',
        ttMainFormat: '.1%',
        ttSecondaryFormat: ',',
        yAxisFormat: '.0%',
        xAxisFormat: '$~s',
      },
      brokenOutFilterIds: [LocalSelectionCategories.PRIMARY_FILTER],
    }),
  ]);

  const [isInflowState, setisInflowState] = useState<boolean>(true);

  const handleInflowsToggle = () => {
    toggleStatusOnGlobalLoaderOrSkipOne();
    setGlobalLoaderEnableState('DISABLE', 1000);

    bottomPlotsMapper[0].updater.next({
      chartProps: {
        inflows: !isInflowState,
      },
    });

    setisInflowState(!isInflowState);

    upsertFiltersWithProvidedValue(
      {
        [LocalSelectionCategories.INFLOW_OR_OUTFLOW]: !isInflowState
          ? OtherFilterNames.INFLOW
          : OtherFilterNames.OUTFLOW,
      },
      false
    );
  };

  return (
    <DashboardPage
      title={[PageTitles.GEOGRAPHY, PageTitles.ACTIVITY_ANALYSIS]}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChips
            filterNames={[primaryViewFilter]}
            variant="companyChip"
            limit={PrimaryFilterLimits.GEO_ACTIVITY_ANALYSIS}
            isPrimaryChip={true}
            min={1}
            addButton={
              <AddEntityButton
                entities={[primaryViewFilter]}
                entityName={'Metro Area'}
                buttonText={'Add Metro Area'}
                limit={PrimaryFilterLimits.GEO_ACTIVITY_ANALYSIS}
                required={1}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={[...selectableFilters]}
            variant="filterChip"
            limit={FilterMenuLimits.GEO_ACTIVITY_ANALYSIS}
            addButton={
              <FilterMenu
                title="Filter"
                filters={selectableFilters}
                selectMenuOpenDefault
                limit={FilterMenuLimits.GEO_ACTIVITY_ANALYSIS}
              />
            }
          />
        </Flex>
      </FilterContainer>

      <Grid
        height="100%"
        templateRows={templateRows}
        templateColumns={templateColumns}
        gap={4}
      >
        {taskSkillQualMappers.map(
          (
            {
              name,
              endpointMapper,
              plotConfigMapper,
              downloadEndpointMapper,
              brokenOutFilterIds,
              dataProvider,
            },
            i
          ) => (
            <GridItem
              key={i}
              className={`plot${i + 1}`}
              minH={gridItemMinHeight}
            >
              <DefaultCard
                cardConfig={{ header: name }}
                downloadConfig={{ endpoint: downloadEndpointMapper }}
                plotConfig={{
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeQuery,
                  brokenOutFilterIds: brokenOutFilterIds,
                  endpoint: endpointMapper,
                  chartTypeAndProps: plotConfigMapper,
                  dataProvider,
                }}
              />
            </GridItem>
          )
        )}
        {bottomPlotsMapper.map(
          (
            {
              name,
              endpointMapper,
              plotConfigMapper,
              downloadEndpointMapper,
              additionalNonActiveFilters,
              brokenOutFilterIds,
              dataProvider,
            },
            i
          ) => (
            <GridItem
              key={i}
              rowSpan={bigPlotRowSpan}
              colSpan={bigPlotColSpan}
              minH={tallGridItemMinHeight}
              className={`plot${i + 5}`}
            >
              <DefaultCard
                cardConfig={{ header: name }}
                topRight={
                  i == 0 && (
                    <Box w={110} mr={2}>
                      <SwitchSelector
                        onChange={(e) => handleInflowsToggle()}
                        initialSelectedIndex={isInflowState ? 0 : 1}
                        options={[
                          { label: 'Inflows', value: true },
                          { label: 'Outflows', value: false },
                        ]}
                        backgroundColor={'#f8f9fa'}
                        fontColor={'#2d426a'}
                        selectedFontColor={'#ffffff'}
                        selectedBackgroundColor={'#3dc679'}
                        wrapperBorderRadius={6}
                        optionBorderRadius={6}
                        selectionIndicatorMargin={-1}
                        fontSize={13}
                      />
                    </Box>
                  )
                }
                downloadConfig={{ endpoint: downloadEndpointMapper }}
                plotConfig={{
                  additionalOperatorsBeforeQuery:
                    additionalOperatorsBeforeQuery,
                  additionalNonActiveFilters: additionalNonActiveFilters,
                  brokenOutFilterIds: brokenOutFilterIds,
                  endpoint: endpointMapper,
                  chartTypeAndProps: plotConfigMapper,
                  dataProvider,
                }}
              />
            </GridItem>
          )
        )}
      </Grid>
    </DashboardPage>
  );
}

export default GeographyActivityAnalysis;
