/* eslint-disable no-restricted-globals */
import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import hexRgb from 'hex-rgb';
import { Box, HTMLChakraProps } from '@chakra-ui/react';
import { CenterLoading } from '../loading/loading';
import { useRef } from 'react';

const spinnerClassName = 'icon-loading';

const Innerwrap = styled('div')<{
  timeIn: number;
  timeOut: number;
  backgroundColor: string;
  backgroundColorOpacity: number;
}>(({ timeIn, timeOut, backgroundColor, backgroundColorOpacity }) => ({
  overflow: 'hidden',
  position: 'absolute',
  height: '100%',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '10px',
  [`& .${spinnerClassName}`]: {
    // position: 'absolute',
    zIndex: 101,
    opacity: 1,
    height: '100%',
    transition: `opacity ${timeIn}ms ease-in-out`,
    willChange: 'opacity',
  },
  '& .rl-loader-overlay': {
    // backdropFilter: 'blur(16px)',
    zIndex: 100,
    transition: `background-color ${timeIn}ms ease-in-out`,
    willChange: 'background-color',
    backgroundColor: `rgba(
      ${hexRgb(backgroundColor).red},
      ${hexRgb(backgroundColor).blue},
      ${hexRgb(backgroundColor).green},
      ${backgroundColorOpacity})`,
  },
  '&.fade-enter': {
    '& .rl-loader-overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        0)`,
      transitionDuration: `${timeIn}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 0,
      transitionDuration: `${timeIn}ms`,
      transitionDelay: `${timeIn / 2}ms`,
    },
  },
  '&.fade-enter-active': {
    '& .rl-loader-overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        ${backgroundColorOpacity})`,
      transitionDuration: `${timeIn}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 1,
      transitionDuration: `${timeIn}ms`,
      transitionDelay: `${timeIn / 2}ms`,
    },
  },
  '&.fade-exit': {
    '& .rl-loader-overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        ${backgroundColorOpacity})`,
      transitionDuration: `${timeOut}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 1,
      transitionDuration: `${timeOut}ms`,
    },
  },
  '&.fade-exit-active': {
    '& .rl-loader-overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        0)`,
      transitionDuration: `${timeOut}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 0,
      transitionDuration: `${timeOut}ms`,
    },
  },
}));

const Overlay = styled('div')({
  position: 'absolute',
  // top: 0,
  // right: 0,
  // bottom: 0,
  // left: 0,
  overflow: 'hidden',
  height: '100%',
  width: '100%',
});

export const LoadingBox: React.FC<LoadingBoxProps> = ({
  children,
  backgroundColor = '#ffffff',
  backgroundColorOpacity = 0.85,
  title,
  loading,
  timeIn = 0,
  timeOut = 0,
  testId,
  ...props
}: LoadingBoxProps) => {
  const loadingBoxStyles = {
    scrollbarWidth: 'thin',
    scrollbarColor: '#e3e3e3 rgba(0,0,0,0)',
    '&::-webkit-scrollbar': {
      width: '14px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgrey',
      borderRadius: '10px',
      backgroundClip: 'padding-box',
      border: '5px solid rgba(0,0,0,0)',
    },
  };

  const nodeRef = useRef(null);

  return (
    <Box
      className={'LOADER'}
      flex="1"
      position="relative"
      marginBottom="4"
      marginTop="1"
      {...props}
      zIndex={0}
      minHeight="400px"
      sx={loadingBoxStyles}
    >
      <CSSTransition
        timeout={timeOut}
        classNames="fade"
        in={loading}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <Innerwrap
          className="INNER-WRAP"
          backgroundColor={backgroundColor}
          backgroundColorOpacity={backgroundColorOpacity}
          timeIn={timeIn}
          timeOut={timeOut}
          ref={nodeRef}
        >
          <CenterLoading
            testId={testId}
            className={spinnerClassName}
            size="md"
          />
          <Overlay className="rl-loader-overlay" />
        </Innerwrap>
      </CSSTransition>
      {children}
    </Box>
  );
};

LoadingBox.displayName = 'LoadingBox';

export interface LoadingBoxProps extends HTMLChakraProps<'div'> {
  /**
   * One or more children nodes that loading box will overlay
   */
  children: React.ReactNode;
  /**
   * Background color (3 or 6 Hex char) of loading spinner overlay when loading is true.
   */
  backgroundColor?: string;
  /**
   * Opacity of loading spinner backgroud colour when loading is true
   */
  backgroundColorOpacity?: number;
  /**
   * Loading spinner title text
   */
  title?: string;
  /**
   * Whether loading is currently set to true or false
   */
  loading?: boolean;
  /**
   * Length of fade-in animation in milliseconds
   */
  timeIn?: number;
  /**
   * Length of fade-out animation in milliseconds
   */
  timeOut?: number;
  testId?: string;
}
export default LoadingBox;
