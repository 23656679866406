import {
  calculateStatValues,
  PostingsStatsMetric,
  Stat,
} from '../../../../../shared/stat';
import { CompanyStatsProps } from '../types';
import { DailyLineChart, DailyLineChartProps } from '@revelio/d3';
import { formatIntegerToDecimal } from '@revelio/core';
import { getAggregatedCompetitorPostings } from '../utils/get-aggregated-competitor-metrics';
import { Text } from '@chakra-ui/react';
import { ChartsRenderedContext } from '../../../../../shared/charts-rendered-context';
import { useContext } from 'react';

interface PostingsChartProps {
  postingsActiveData: CompanyStatsProps['postingsActiveData'];
  selectedCompanyRCID: CompanyStatsProps['selectedCompanyRCID'];
  competitors: CompanyStatsProps['competitors'];
  companyName: string | null | undefined;
}
export const PostingsChart = ({
  postingsActiveData,
  selectedCompanyRCID,
  companyName,
  competitors,
}: PostingsChartProps) => {
  const primaryPostingData = postingsActiveData?.posting?.find(
    (datum) => datum?.metadata?.id === selectedCompanyRCID
  );

  const primaryCompanyCategories = primaryPostingData?.category || [];

  const postingsTimeseries: DailyLineChartProps['timeSeries'] =
    primaryCompanyCategories?.map((datum) => ({
      id: datum?.metadata?.id,
      value: datum?.metrics?.active,
      date: datum?.metadata?.shortName,
    })) || [];

  const { lastValue, percentageChange } =
    calculateStatValues(postingsTimeseries);

  const primaryValue = formatIntegerToDecimal(lastValue);

  const vsCompetitors =
    lastValue -
    getAggregatedCompetitorPostings(
      competitors,
      postingsActiveData?.posting,
      PostingsStatsMetric.ActivePostings
    );

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  return (
    <Stat
      name={PostingsStatsMetric.ActivePostings}
      plotInfo={<Text color="white">The number of active job postings.</Text>}
      header="Active Postings"
      statValues={{
        primaryValue,
        percentChange: percentageChange,
        vsCompetitors,
      }}
      competitorFormatting={formatIntegerToDecimal}
      competitorStyles={{
        minWidth: '110px',
      }}
    >
      <DailyLineChart
        name={PostingsStatsMetric.ActivePostings}
        shortName={companyName}
        timeSeries={postingsTimeseries}
        type="Company"
        setChartHasRendered={setChartHasRendered}
      />
    </Stat>
  );
};
