import { Box } from '@chakra-ui/react';

export const getDelimiter = (index: number, length: number, limit: number) => {
  let delimiter;

  const limiter = Math.min(limit, length);

  switch (index) {
    case limiter - 2:
      delimiter = ' and';
      break;
    case limiter - 1:
      delimiter = '.';
      break;
    default:
      delimiter = ',';
  }

  return delimiter;
};

export const formatViewList = (
  labels: string[],
  limit: number,
  quoteListItems = false
) => {
  const slicedArray = labels.slice(0, limit);

  return slicedArray.map((label, i) => {
    return (
      <span key={`${label}-${i}`}>
        <Box as="span">{`${quoteListItems ? "'" : ''}${label}${
          quoteListItems ? "'" : ''
        }`}</Box>
        {getDelimiter(i, slicedArray.length, limit)}
      </span>
    );
  });
};

export const formatListStringToLimit = (list: string, limit: number) => {
  const arr = list.split(',').slice(0, limit);

  let index = 0;

  return arr.reduce((formattedString: string, subString: string) => {
    return (
      formattedString +
      `${subString}${getDelimiter(index++, arr.length, limit)}`
    );
  }, '');
};
