import { SelectionListItems, TreeItem } from '../engine/filters.model';
import { TreeData, TreeSelectionProps } from '../filter-components/tree';

const nestedIdMap = (
  selectionList: SelectionListItems[],
  sourceIdMap: Map<string, string[]> = new Map<string, string[]>(),
  parentFilterId?: string,
  nestedParentId?: string
): Map<string, string[]> => {
  if (selectionList.length === 0) return sourceIdMap;
  const parent = selectionList[0].id;
  const parentSelectionList = selectionList.find((list) => list.id === parent);
  if (!parentSelectionList) return sourceIdMap;

  parentSelectionList.value
    .filter(
      (item) =>
        !parentFilterId ||
        item[parentSelectionList.parent]?.includes(parentFilterId)
    )
    .forEach((item) => {
      const nestedId = nestedParentId
        ? `${nestedParentId}_${item.id}`
        : item.id;

      const sourceId = `${parent}.${item.id}`;
      if (sourceIdMap.has(sourceId)) {
        sourceIdMap.set(sourceId, [
          ...(sourceIdMap.get(sourceId) || []),
          nestedId,
        ]);
      } else sourceIdMap.set(sourceId, [nestedId]);

      if (selectionList.length > 1) {
        nestedIdMap(selectionList.slice(1), sourceIdMap, item.id, nestedId);
      }
    });

  return sourceIdMap;
};

export const nestSelections = (
  selectionList: SelectionListItems[],
  sourceIdMap: Map<string, string[]>,
  parentFilterId?: string,
  nestedParentId?: string
): TreeSelectionProps['data'] => {
  if (selectionList.length === 0) return [];

  const parent = selectionList[0].id;

  const parentSelectionList = selectionList.find((list) => list.id === parent);

  if (!parentSelectionList) return [];

  return parentSelectionList.value
    .filter(
      (item) =>
        !parentFilterId ||
        item[parentSelectionList.parent]?.includes(parentFilterId)
    )
    .map((item): TreeData => {
      const nestedId = nestedParentId
        ? `${nestedParentId}_${item.id}`
        : item.id;

      const sourceId = `${parent}.${item.id}`;
      const sourceIds = sourceIdMap.get(sourceId) || [nestedId];
      return {
        id: nestedId,
        ...(sourceIds.length > 1 && {
          linkedIds: sourceIds.filter((id) => id !== nestedId),
        }),
        name: item.longName || item.id,
        ...(selectionList.length > 1 && {
          children: nestSelections(
            selectionList.slice(1),
            sourceIdMap,
            item.id,
            nestedId
          ),
        }),
      };
    });
};

export const nestSelectionHierarchy = (selectionList: SelectionListItems[]) => {
  const sourceIdMap = nestedIdMap(selectionList);

  return nestSelections(selectionList, sourceIdMap);
};

type SimpleFormTreeItem = Pick<
  TreeItem,
  'id' | 'item' | 'parentId' | 'selectionListId'
>;
export const getSelectionListItems = ({
  selectionLists,
  selectedIds,
}: {
  selectionLists: SelectionListItems[];
  selectedIds: string[];
}): SimpleFormTreeItem[] => {
  const deduplicatedMap = selectedIds
    .map((id): SimpleFormTreeItem | null => {
      const path = id.split('_');
      const depth = path.length - 1;

      const selectionList = selectionLists[depth];

      if (!selectionList) return null;

      const selectionListId = selectionList.id;

      const item = selectionList.value.find((item) => item.id === path[depth]);
      if (!item) return null;

      return {
        id: `${selectionListId}.${item.id}`,
        selectionListId,
        parentId: selectionList.parent
          ? `${selectionList.parent}.${item?.[selectionList.parent]?.[0]}`
          : undefined,
        item: {
          id: item.id,
          shortName: item.shortName,
          longName: item.longName,
          label: item.label,
        },
      };
    })
    .filter((x): x is SimpleFormTreeItem => !!x)
    .reduce<{ [key: string]: SimpleFormTreeItem }>((acc, item) => {
      return {
        ...acc,
        [item.id]: item,
      };
    }, {});

  return Object.values(deduplicatedMap).map((item) => item);
};
