import { CompositionDataQuery, Dimension1 } from '@revelio/data-access';
import { CompanySummaryMetric, SummaryEntities } from '../../../types';
import { isNil } from 'lodash';

export const getCompetitorCompositionData = (
  data: CompositionDataQuery | undefined,
  primaryRCID: number
) => {
  const entities = data?.composition;

  if (!entities) {
    return {
      headcount: null,
      attrition_rate: null,
      growth_rate: null,
      hiring_rate: null,
    };
  }

  const getData = (metric: CompanySummaryMetric) =>
    getCompetitorBarChartMetricData(entities, metric, primaryRCID);

  return {
    headcount: getData(CompanySummaryMetric.Headcount),
    attrition_rate: getData(CompanySummaryMetric.AttritionRate),
    growth_rate: getData(CompanySummaryMetric.GrowthRate),
    hiring_rate: getData(CompanySummaryMetric.HiringRate),
  };
};

const getCompetitorBarChartMetricData = (
  entities: SummaryEntities,
  metric: CompanySummaryMetric,
  primaryRCID: number
) => {
  return entities
    .map((entity) => {
      const metaData = entity?.metadata;
      const timeSeries = entity?.metrics?.[metric]?.timeseries;
      const value = timeSeries?.[timeSeries?.length - 1]?.value;

      if (!metaData || isNil(value)) {
        return null;
      }

      return {
        id: metaData.id,
        value: value,
        metadata: {
          longName: metaData.longName,
          shortName: metaData.shortName,
          type: Dimension1.Company,
          isPrimary: metaData.id === primaryRCID,
        },
      };
    })
    .filter((data) => data !== null)
    .sort((a, b) => (b?.value || 0) - (a?.value || 0));
};
