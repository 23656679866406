import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  Stack,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  PopoverTrigger,
  UserTrackingEvents,
  emitMixpanelUserEvent,
} from '@revelio/core';
import { plotConfigObject } from '../../../hooks/plot-info/plotInfo';
import './plot-info-comp.module.css';

export interface PlotInfoCompProps {
  plotInfoBody: React.ReactElement | null;
  plotInfoConfig: plotConfigObject | null;
  topLeft?: React.ReactNode;
  disableInfoPopup?: boolean;
}

export const PlotInfoComp = ({
  plotInfoBody,
  plotInfoConfig,
  topLeft,
  disableInfoPopup = false,
}: PlotInfoCompProps) => {
  return (
    <Stack spacing={0} direction="row" align="center" display="inline-flex">
      {plotInfoBody && !disableInfoPopup && (
        <Popover
          trigger="hover"
          placement={plotInfoConfig?.popoverPlacement}
          openDelay={500}
          onOpen={() => {
            emitMixpanelUserEvent(UserTrackingEvents.TOOLTIP_OPEN);
          }}
          variant="tooltip"
          isLazy
        >
          <PopoverTrigger>
            <IconButton
              opacity={0}
              _groupHover={{ opacity: 1 }}
              aria-label="plot info"
              data-testid="plot-info-icon"
              colorScheme="gray"
              variant="plotInfo"
              size="1.2"
              icon={<InfoOutlineIcon boxSize={plotInfoConfig?.iconBoxSize} />}
              minWidth={4}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody data-testid="plot-info-body">
                {plotInfoBody}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
      {topLeft}
    </Stack>
  );
};

export default PlotInfoComp;
