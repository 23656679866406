import { Views } from '@revelio/core';
import {
  FilterTypes,
  LocalSelectionCategories,
  SelectionCategories,
  SubFilterNames,
} from '../../engine/filters.model';
import { getStartEndValues } from './helpers.config';
import { HandlerConfig, WorkFlows } from './types';

export const filtersWithCurrentOption: string[] = [
  SelectionCategories.COMPANY,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.MSA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
  SelectionCategories.SENIORITY,
  SelectionCategories.BASE_SALARY,
  SelectionCategories.TOTAL_COMPENSATION,
  LocalSelectionCategories.PRESTIGE,
  LocalSelectionCategories.FLIGHT_RISK,
  LocalSelectionCategories.REMOTE_SUITABILITY,
];

export const screenerFiltersWithTimeComponent = [
  SelectionCategories.HIRING_RATE,
  SelectionCategories.ATTRITION_RATE,
  SelectionCategories.GROWTH_RATE,
];

export const SelectionListsToNotSortSelectedToTop: string[] = [
  SelectionCategories.SENIORITY,
  SubFilterNames.SUB_SENIORITY,
  LocalSelectionCategories.DATA_METRIC,
];

export const formatOverrideLookup: { [key: string]: SelectionCategories[] } = {
  [Views.SCREENER]: [
    SelectionCategories.INDUSTRY,
    SelectionCategories.INDUSTRY_FULL,
    SelectionCategories.COMPANY_FULL,
    SelectionCategories.REGION,
    SelectionCategories.JOB_CATEGORY,
  ],
  [Views.LAYOFF]: [
    SelectionCategories.INDUSTRY_FULL,
    SelectionCategories.COMPANY_FULL,
  ],
};

export const filterWorkflowLookup: { [key: string]: WorkFlows } = {
  [SelectionCategories.HEADCOUNT]: WorkFlows.SCREENER,
  [SelectionCategories.HIRING_RATE]: WorkFlows.SCREENER,
  [SelectionCategories.ATTRITION_RATE]: WorkFlows.SCREENER,
  [SelectionCategories.GROWTH_RATE]: WorkFlows.SCREENER,
  [SelectionCategories.AVERAGE_TENURE]: WorkFlows.SCREENER,
  [SelectionCategories.AVERAGE_SALARY]: WorkFlows.SCREENER,
  [SelectionCategories.COMPANY_CLEANED]: WorkFlows.COMPANY_CLEANED,
  [SelectionCategories.COUNT]: WorkFlows.LAYOFF,
  [SelectionCategories.FULL_MSA]: WorkFlows.LAYOFF,
  [SelectionCategories.STATE]: WorkFlows.LAYOFF,
  [SelectionCategories.DATE_RANGE]: WorkFlows.DATE_RANGE,
  [SelectionCategories.DATE_RANGE_FULL]: WorkFlows.DATE_RANGE_FULL,
  [SelectionCategories.SNAPSHOT_DATE]: WorkFlows.DATE,
  [SelectionCategories.SAVED_FILTER_SET]: WorkFlows.SAVED_FILTER_SET,
  [SelectionCategories.OTHER]: WorkFlows.DEFAULT,
  [Views.TALENT_DISCOVERY]: WorkFlows.TALENT_DISCOVERY,
  [SelectionCategories.KEYWORD]: WorkFlows.KEYWORD,
  [SelectionCategories.RAW_TITLE]: WorkFlows.KEYWORD,
};

export const filterTypeLookup: { [key: string]: FilterTypes } = {
  [SelectionCategories.HEADCOUNT]: FilterTypes.BOARD,
  [SelectionCategories.HIRING_RATE]: FilterTypes.BOARD,
  [SelectionCategories.ATTRITION_RATE]: FilterTypes.BOARD,
  [SelectionCategories.GROWTH_RATE]: FilterTypes.BOARD,
  [SelectionCategories.AVERAGE_TENURE]: FilterTypes.BOARD,
  [SelectionCategories.AVERAGE_SALARY]: FilterTypes.BOARD,
  [SelectionCategories.COMPANY_CLEANED]: FilterTypes.STRING_LIST,
  [SelectionCategories.COUNT]: FilterTypes.BOARD_LAYOFF,
  [SelectionCategories.FULL_MSA]: FilterTypes.LAYOFF_MSA_STATE,
  [SelectionCategories.STATE]: FilterTypes.LAYOFF_MSA_STATE,
};

export const configLookup: { [key: string]: any } = {
  [FilterTypes.BOARD_LAYOFF]: (config: HandlerConfig) => ({
    isMulti: false,
    type: FilterTypes.BOARD_LAYOFF,
    value: {
      opValue: config.opValue,
      ...getStartEndValues(
        config.selectValue,
        config.startValue,
        config.endValue,
        config.opValue
      ),
    },
  }),
  [FilterTypes.LAYOFF_MSA_STATE]: (config: HandlerConfig) => ({
    isMulti: true,
    type: FilterTypes.LAYOFF_MSA_STATE,
    value: Object.values({ ...config.tempSelections }).map((v: any) => v.item),
  }),
};
