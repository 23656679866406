import {
  CardListSelectController,
  ICardListSelectProps,
} from '@revelio/layout';
import { useObservable } from '@ngneat/react-rxjs';
import {
  deliverablesStore,
  removeColumnsById,
  updateDraftDeliverable,
} from '../deliverables.repository';
import { selectEntity } from '@ngneat/elf-entities';
import { map } from 'rxjs';
import { difference } from 'lodash';
import { write } from '@revelio/core';
import { PipelineColumnType, PostingSource } from '@revelio/data-access';
import { CompletePostingPipeline, Deliverable } from '../deliverables.model';
import { useClientDataBuilderConfig } from '../useClientDataBuilderConfig';

export const POSTINGS_SOURCE_OPTIONS = [
  { id: PostingSource.Ghr, label: 'Aggregator Postings', deprecated: true }, // needs to be kept for deliverable summaries on status page
  { id: PostingSource.Indeed, label: 'Indeed Postings' },
  {
    id: PostingSource.Mixrank,
    label: 'LinkedIn Postings',
  },
  { id: PostingSource.Unified, label: 'Unified Postings' },
  { id: PostingSource.Linkup, label: 'Linkup Postings' },
];

export const PostingsSourceSelect = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const [postingsSource] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          (deliverable?.pipeline as CompletePostingPipeline)?.source
      )
    ),
    { deps: [entityId] }
  );
  const clientDataBuilderConfig = useClientDataBuilderConfig();

  return (
    <CardListSelectController<PostingSource | 'posting_source'>
      variant="columns"
      items={[
        {
          heading: null,
          columns: [
            {
              id: 'posting_source',
              label: 'Posting Source',
              isRequired: true,
              menuItems: POSTINGS_SOURCE_OPTIONS.map((option) => ({
                ...option,
                isDisabled: !clientDataBuilderConfig?.posting_source?.includes(
                  option.id
                ),
              })).filter((option) => !option.deprecated || !option.isDisabled),
            },
          ],
        },
      ]}
      gridProps={{
        spacing: 6,
        columns: 1,
        gridAutoFlow: 'row',
      }}
      value={postingsSource ? [postingsSource] : []}
      onChange={(value: string[]) => {
        const newSourceSelected = difference(value, [postingsSource]);
        updateDraftDeliverable(
          entityId,
          write<Deliverable>((state) => {
            state.pipeline = {
              ...state.pipeline,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore because this is only when completing a postings pipeline
              source: value.length ? newSourceSelected[0] : undefined,
            };
          })
        );

        if (newSourceSelected[0] !== PostingSource.Unified) {
          // in the case where the column was selected while unified posting source was selected, need to clear it for the other posting sources
          removeColumnsById({
            entityId,
            ids: [PipelineColumnType.ExpectedHires],
          });
        }
      }}
      onCloseSubMenu={(
        previouslyCheckedPostingSource: ICardListSelectProps<
          PostingSource | 'posting_source'
        >[]
      ) => {
        updateDraftDeliverable(
          entityId,
          write<Deliverable>((state) => {
            state.pipeline = {
              ...state.pipeline,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore because this is only when completing a postings pipeline
              source: previouslyCheckedPostingSource.length
                ? previouslyCheckedPostingSource[0].value
                : undefined,
            };
          })
        );
      }}
    />
  );
};
