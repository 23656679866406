import { get, isBoolean, isEmpty, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';
import { Role, TrialType, USER_DETAILS_QUERY } from '@revelio/data-access';
import { authStore, useAuthStore } from './auth.repository';
import { getUserTrialType, overwriteTDV2Tab } from './helpers';

export const useGetLoggedInUser = ({
  onTrialToLive,
}: {
  onTrialToLive?: () => void;
} = {}) => {
  const { ory, user } = useAuthStore();
  const loggedInUserId = ory?.id;

  const query = useQuery({
    query: USER_DETAILS_QUERY,
    variables: { ids: [loggedInUserId as string] },
    pause: !loggedInUserId, // maybe should be paused if already has user + they are live ???
  });

  const [result, reexecuteQuery] = query;

  const userWithTDTabOverwrite = useMemo(() => {
    const loggedInUser = result.data?.users?.[0] ?? {};
    const userWithTDTabOverwrite = overwriteTDV2Tab(loggedInUser);

    authStore.update((state) => ({
      ...state,
      user: userWithTDTabOverwrite,
    }));

    return userWithTDTabOverwrite;
  }, [result]);

  if (user?.live === false && userWithTDTabOverwrite.live === true) {
    onTrialToLive?.();
  }

  const refetch = () => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  return { query, loggedInUser: userWithTDTabOverwrite, refetch };
};

export const PREVIEW_TRIAL_COOKIE_NAME = 'previewTrialUser';
export const useUserTrialDetails = <T = null>({
  initialRenderValue,
}: {
  initialRenderValue?: boolean;
} = {}) => {
  const {
    loggedInUser,
    query: [{ fetching }],
  } = useGetLoggedInUser();

  const [isTrialUser, setIsTrialUser] = useState<boolean | T>(
    isBoolean(initialRenderValue) ? initialRenderValue : (null as T)
  );
  const [trialType, setTrialType] = useState<TrialType>();

  useEffect(() => {
    const isFetchingOrEmpty = fetching || isEmpty(loggedInUser);

    if (isFetchingOrEmpty) {
      return;
    }

    const isLiveUser = get(loggedInUser, 'live', false);
    const trialType = getUserTrialType(loggedInUser);
    const isTrialUserDevCookie = document.cookie.includes(
      PREVIEW_TRIAL_COOKIE_NAME
    );

    setIsTrialUser(isTrialUserDevCookie || !isLiveUser);
    trialType && setTrialType(trialType);
  }, [fetching, loggedInUser]);

  return { isTrialUser, trialType };
};

export const isRoleAdmin = (userRole: Role | undefined | null) =>
  userRole
    ? [Role.RevelioAdmin, Role.ClientAdmin, Role.SuperAdmin].includes(userRole)
    : false;

export const isRoleRevelioAdmin = (userRole: Role | undefined | null) =>
  userRole ? [Role.RevelioAdmin, Role.SuperAdmin].includes(userRole) : false;
export const useIsRevelioAdmin = () => {
  const {
    loggedInUser,
    query: [{ fetching }],
  } = useGetLoggedInUser();
  return {
    isRevelioAdmin: isRoleRevelioAdmin(loggedInUser.role),
    // set to this when no data fetched yet, useful when you don't want to show anything until you know the user role
    // avoiding things like flash renders
    dataRecieved: !isEqual(loggedInUser, {}),
    fetching,
  };
};
