import {
  deleteFilter,
  FilterContainerName,
  OtherFilterNames,
  ScreenerFilterOption,
  ScreenerFilters,
  useScreenerFilter,
} from '@revelio/filtering';
import { Grid, GridItem, Flex } from '@chakra-ui/react';
import { Card } from '@revelio/layout';
import { FeatureFlag, PageTitles } from '@revelio/core';
import DashboardPage from '../DashboardPage';
import { useUserTrialDetails } from '@revelio/auth';
import { PlotDataLagWarning } from '@revelio/composed';
import { ScreenerDimension, TrialType, View } from '@revelio/data-access';
import { DownloadScreenerButton } from './download-screener-button';
import { ScreenerTable } from './screener-table/screener-table';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useUnleashFlag } from '../../hooks/unleash/useUnleashFlag';
import { useSyncFiltersWithUrl } from './useSyncFiltersWithUrl';
import { ScreenerSorting } from '@revelio/filtering';

export interface ScreenerProps {
  title: PageTitles[];
  filterOptions: ScreenerFilterOption[];
  view: ScreenerDimension;
  sortingDisabledColumns?: string[];
  savedSetsView: View;
}

export const Screener = ({
  title,
  filterOptions,
  view,
  sortingDisabledColumns,
  savedSetsView,
}: ScreenerProps) => {
  const { isTrialUser, trialType } = useUserTrialDetails();
  const isDataLagged = isTrialUser && trialType === TrialType.ONE_YEAR_DATA_LAG;
  const additionalFiltersEnabled = useUnleashFlag(
    FeatureFlag.CompanyScreenerAdditionalFilters
  );

  const { state, dispatch } = useScreenerFilter();

  const sorting = state.sorting;
  const setSorting = useCallback(
    (sortingToUpdate: ScreenerSorting | undefined) => {
      dispatch({ type: 'UPDATE_SORTING', sorting: sortingToUpdate });
    },
    [dispatch]
  );

  const defaultColumns = useMemo(() => {
    const primaryEntity = (() => {
      if (view === ScreenerDimension.Company) {
        return 'Company Name';
      }

      if (view === ScreenerDimension.Geography) {
        return 'Metro Area';
      }

      return 'Role';
    })();

    return [
      primaryEntity,
      'Headcount',
      'Growth Rate',
      'Salary',
      'Attrition Rate',
      'Hiring Rate',
      'Tenure',
    ];
  }, [view]);

  // Selected columns state and setter
  const [savedCheckedColumns, setSavedCheckedColumns] =
    useState<string[]>(defaultColumns);

  // Pass sorting and selected columns to the hook
  useSyncFiltersWithUrl(
    state,
    dispatch,
    view,
    sorting,
    setSorting,
    savedCheckedColumns,
    setSavedCheckedColumns
  );

  /** Adding this as a hotfix to disable adaptive taxonomy roles from being shown in the role dropdown list */
  useEffect(() => {
    deleteFilter(OtherFilterNames.ROLE_TAXONOMY);
  }, []);

  return (
    <DashboardPage title={title} loaderActiveOnInit={false}>
      {/* Need to pass this custom property so DashboardPage renders the filters in the right grid position.
            Should remove this constraint from DashboardPage */}
      <Flex
        {...{ [FilterContainerName]: [FilterContainerName] }}
        width="full"
        justifyContent="space-between"
      >
        <ScreenerFilters filterOptions={filterOptions} />
        {isDataLagged && <PlotDataLagWarning persistent />}
        <DownloadScreenerButton view={view} />
      </Flex>

      <Grid
        height="100%"
        templateRows="1fr"
        templateColumns="1fr"
        data-testid="plots-grid"
      >
        <GridItem rowSpan={1} colSpan={1}>
          <Card p="16px">
            <ScreenerTable
              view={view}
              sortingDisabledColumns={sortingDisabledColumns}
              additionalFiltersEnabled={additionalFiltersEnabled}
              sorting={sorting}
              setSorting={setSorting}
              savedCheckedColumns={savedCheckedColumns}
              setSavedCheckedColumns={setSavedCheckedColumns}
            />
          </Card>
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};
