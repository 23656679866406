import { PipelineColumnType, PipelineType } from '@revelio/data-access';
import { ColumnSet } from '@revelio/layout';
import { difference } from 'lodash';
import { Deliverable, InputRefs } from '../../deliverables.model';
import { isIndustrySupportedDataset } from '../columns.model';
import { STANDARD_COMPANY_COLUMN } from './shared';

const HEADQUARTERS_MENU_ITEMS = [
  {
    id: PipelineColumnType.City,
    label: 'City',
    defaultIsSelected: true,
  },
  {
    id: PipelineColumnType.State,
    label: 'State',
    defaultIsSelected: true,
  },
  {
    id: PipelineColumnType.Country,
    label: 'Country',
    defaultIsSelected: true,
  },
];

const INDUSTRY_MENU_ITEMS = [
  {
    id: PipelineColumnType.Naics,
    label: 'NAICS',
    defaultIsSelected: true,
  },
  {
    id: PipelineColumnType.Sic,
    label: 'SIC',
  },
];

const SECURITY_IDENTIFIERS_MENU_ITEMS = [
  {
    id: PipelineColumnType.Cusip,
    label: 'CUSIP',
  },
  {
    id: PipelineColumnType.Isin,
    label: 'ISIN',
  },
  {
    id: PipelineColumnType.Lei,
    label: 'LEI',
  },
];

export const COMPANY_INFO_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: null,
    columns: [
      {
        id: PipelineColumnType.CompanyId,
        label: 'Company ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        ...STANDARD_COMPANY_COLUMN,
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: 'headquarters',
        label: 'Headquarters',
        defaultIsSelected: true,
        menuItems: HEADQUARTERS_MENU_ITEMS,
      },
      {
        id: PipelineColumnType.YearFounded,
        label: 'Year Founded',
      },
      {
        id: PipelineColumnType.Ticker,
        label: 'Ticker',
        defaultIsSelected: true,
        combineWith: [PipelineColumnType.Exchange],
      },
      {
        id: PipelineColumnType.Website,
        label: 'Website',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.LinkedinUrl,
        label: 'Company LinkedIn URL',
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.FactsetEntityId,
        label: 'FactSet Entity ID',
      },
      {
        id: 'industry',
        label: 'Industry',
        defaultIsSelected: true,
        menuItems: INDUSTRY_MENU_ITEMS,
      },
      {
        id: 'security_identifiers',
        label: 'Security Identifiers',
        menuItems: SECURITY_IDENTIFIERS_MENU_ITEMS,
      },
      {
        id: PipelineColumnType.Cik,
        label: 'CIK',
      },
      {
        id: PipelineColumnType.Gvkey,
        label: 'GVKey',
      },
      {
        id: PipelineColumnType.Sedol,
        label: 'SEDOL',
      },
      {
        id: PipelineColumnType.UltimateParentRcid,
        label: 'Ultimate Parent Company',
        defaultIsSelected: true,
        combineWith: [PipelineColumnType.UltimateParentCompanyName],
      },
    ],
  },
];

interface CompanyInfoMappingProps {
  deliverables: Deliverable[];
  inputRefs: InputRefs;
  datasetsWithSelectedCompanies: PipelineType[];
}

export const getCompanyInfoCompanyMapping = ({
  deliverables,
  inputRefs,
  datasetsWithSelectedCompanies,
}: CompanyInfoMappingProps): InputRefs => {
  const selectedPipelineTypes = deliverables.map(
    (entity) => entity.pipeline.pipeline_type
  );

  const allPipelinesHaveAppliedMapping =
    datasetsWithSelectedCompanies.length === selectedPipelineTypes.length;

  const datasetsWithoutSelectedCompanies = difference(
    selectedPipelineTypes,
    datasetsWithSelectedCompanies
  );

  const datasetsWithoutSelectedCompaniesAreIndustrySupported =
    datasetsWithoutSelectedCompanies.find((id) =>
      isIndustrySupportedDataset(id as PipelineType)
    );

  if (
    allPipelinesHaveAppliedMapping ||
    (!allPipelinesHaveAppliedMapping &&
      datasetsWithoutSelectedCompaniesAreIndustrySupported)
  ) {
    return {
      company_reference: inputRefs.company_reference,
      pipeline_input: inputRefs.pipeline_input,
      company_sets: inputRefs.company_sets,
    };
  } else {
    return {
      company_reference: '',
      pipeline_input: '',
      company_sets: [],
    };
  }
};
