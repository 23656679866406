import { Card, Grid, GridItem } from '@chakra-ui/react';
import { RoleStatsProps } from './role-stat-line-charts/types';
import { SentimentChart } from './role-stat-line-charts/charts/sentiment-chart';
import { HeadcountChart } from './role-stat-line-charts/charts/headcount-chart';
import { GrowthChart } from './role-stat-line-charts/charts/growth-chart';
import { PostingsChart } from './role-stat-line-charts/charts/postings-chart';
import styles from '../../company-summary/company-stats/company-stats.module.css';

export const RoleStats = ({
  compositionData,
  postingsActiveData,
  selectedRoleId,
  sentimentData,
}: RoleStatsProps) => {
  const primaryRoleCompositionData = compositionData?.composition?.find(
    (datum) => datum?.metadata?.id === selectedRoleId
  );

  const roleName = primaryRoleCompositionData?.metadata?.longName;

  return (
    <Card height="100%" p="20px 30px" boxShadow="none">
      <Grid
        height="100%"
        templateRows="repeat(2, minmax(0, 1fr))"
        templateColumns="repeat(2, minmax(0, 1fr))"
        gap={8}
      >
        <GridItem className={styles.gridItem}>
          <HeadcountChart
            compositionData={compositionData}
            selectedRoleId={selectedRoleId}
            roleName={roleName}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <GrowthChart
            compositionData={compositionData}
            selectedRoleId={selectedRoleId}
            roleName={roleName}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <PostingsChart
            postingsActiveData={postingsActiveData}
            selectedRoleId={selectedRoleId}
            roleName={roleName}
          />
        </GridItem>
        <GridItem className={styles.gridItem}>
          <SentimentChart
            sentimentData={sentimentData}
            selectedRoleId={selectedRoleId}
            roleName={roleName}
          />
        </GridItem>
      </Grid>
    </Card>
  );
};
