import { useQuery } from 'urql';
import { ClientDetailsQuery } from '../adminRewrite/userOperations';
import { useGetLoggedInUser } from '@revelio/auth';
import { get } from 'lodash';
import { PipelineClientConfig } from '@revelio/data-access';

export const useClientDataBuilderConfig = (): PipelineClientConfig | null => {
  const { loggedInUser } = useGetLoggedInUser();
  const [{ data: clientData }] = useQuery({
    query: ClientDetailsQuery,
    variables: { ids: [loggedInUser?.client_name as string] },
    pause: !loggedInUser?.client_name,
  });
  return get(clientData, 'clients[0].data_builder_configuration', null);
};
