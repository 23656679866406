import { PrimaryView } from '@revelio/core';
import {
  Filter,
  FilterName,
  OtherFilterNames,
  RangeFullFilter,
  SelectionCategories,
  useRoleTaxonomySetting,
} from '@revelio/filtering';
import { useMemo } from 'react';

type UseIsQueryReadyProps = {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
  view: PrimaryView;
};
export const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
  view,
}: UseIsQueryReadyProps) => {
  /** check that activeFilters has primaryFilter */
  const filtersHasAtLeastOnePrimaryFilter = useMemo(
    () => activeFilters.some((filter) => primaryFilters.includes(filter.id)),
    [activeFilters, primaryFilters]
  );

  const filtersHasCustomRoleTaxonomy = useMemo(() => {
    return !!activeFilters.find(
      (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
    );
  }, [activeFilters]);
  const { isEnabled: isRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  /** check that filter has full date range set */
  const filtersHasDateSet = useMemo(
    () =>
      activeFilters.some((filter) => {
        /** TODO: Typecasting this as Filters aren't typed by id */
        const dateRangeFullFilter = filter as unknown as RangeFullFilter;
        return (
          dateRangeFullFilter.id === SelectionCategories.DATE_RANGE_FULL &&
          dateRangeFullFilter.value.startDate &&
          dateRangeFullFilter.value.endDate
        );
      }),
    [activeFilters]
  );

  return (
    filtersHasAtLeastOnePrimaryFilter &&
    filtersHasDateSet &&
    (!isRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};
