import {
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import { Dispatch, ReactNode } from 'react';
import {
  ScreenerFilterOption,
  isCheckboxFilterState,
  isCompanySearchFilterState,
  isFreeTextFilterState,
  isSchoolSearchFilterState,
  isTreeFilterState,
  isYearRangeFilterState,
} from '../types';
import {
  ScreenerFilterAction,
  ScreenerFilterState,
} from '../screener-filter-reducer';
import { ScreenerFilterPopover } from '../screener-filter-popover';
import { TreeItem } from '../../../engine/filters.model';

const findOptionInFilterOptions = (
  options: ScreenerFilterOption[],
  predicate: (option: ScreenerFilterOption) => boolean
): ScreenerFilterOption | undefined => {
  for (const option of options) {
    if (predicate(option)) {
      return option;
    }

    if (option.children) {
      const found = findOptionInFilterOptions(option.children, predicate);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

interface ComapnyDetailFilterTagProps {
  filterOptions: ScreenerFilterOption[];
  dispatch: Dispatch<ScreenerFilterAction>;
  companyDetailFilter: NonNullable<
    ScreenerFilterState['filters']['company_detail_filters']
  >[number];
}

export const CompanyDetailFilterTag = ({
  filterOptions,
  dispatch,
  companyDetailFilter,
}: ComapnyDetailFilterTagProps) => {
  const isTreeFilter = isTreeFilterState(companyDetailFilter);
  const isCheckboxFilter = isCheckboxFilterState(companyDetailFilter);
  const isYearRangeFilter = isYearRangeFilterState(companyDetailFilter);
  const isCompanySearchFilter = isCompanySearchFilterState(companyDetailFilter);
  const isSchoolFilter = isSchoolSearchFilterState(companyDetailFilter);
  const isFreeText = isFreeTextFilterState(companyDetailFilter);

  const filterNames: ReactNode = (() => {
    if (isCompanySearchFilter) {
      const companyResultItems = companyDetailFilter.companyResultItems;
      const names = Object.values(companyResultItems || {})
        .map((item) => item.primary_name)
        .join(', ');
      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {names}
        </Text>
      );
    }

    if (isFreeText) {
      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {companyDetailFilter?.text?.join(', ')}
        </Text>
      );
    }

    if (isTreeFilter) {
      const treeItems =
        companyDetailFilter && isTreeFilter
          ? companyDetailFilter.treeItems
          : {};
      const treeNames = Object.values(treeItems || {}).map(
        (filter: TreeItem) => filter.item?.label
      );

      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {treeNames.join(', ')}
        </Text>
      );
    }

    if (isCheckboxFilter) {
      const names = companyDetailFilter.options
        .map((option) => option.label)
        .join(', ');
      return (
        <Text as="span" color="lightBlue.600" fontWeight={600}>
          {names}
        </Text>
      );
    }

    if (isYearRangeFilter) {
      if (companyDetailFilter?.start_year && companyDetailFilter?.end_year) {
        return (
          <>
            <Text as="span" mr="2px">
              between
            </Text>
            <Text as="span" color="lightBlue.600" fontWeight={600} mr="2px">
              {companyDetailFilter.start_year}
            </Text>
            <Text as="span" mr="2px">
              and
            </Text>
            <Text as="span" color="lightBlue.600" fontWeight={600}>
              {companyDetailFilter.end_year}
            </Text>
          </>
        );
      }

      if (companyDetailFilter.start_year) {
        return (
          <>
            <Text as="span" mr="2px">
              is greater than
            </Text>
            <Text as="span" color="lightBlue.600" fontWeight={600}>
              {companyDetailFilter.start_year}
            </Text>
          </>
        );
      }

      if (companyDetailFilter.end_year) {
        return (
          <>
            <Text as="span" mr="2px">
              is less than
            </Text>
            <Text as="span" color="lightBlue.600" fontWeight={600}>
              {companyDetailFilter.end_year}
            </Text>
          </>
        );
      }
    }

    if (isSchoolFilter) {
      const schoolNames = Object.values(companyDetailFilter.schoolResultItems)
        .map((school) => school?.primary_name)
        .join(', ');

      return (
        <Text color="lightBlue.600" fontWeight={600}>
          {schoolNames}
        </Text>
      );
    }
    return null;
  })();

  const itemCount = (() => {
    if (isTreeFilter) {
      const treeItems =
        companyDetailFilter && isTreeFilter
          ? companyDetailFilter.treeItems
          : {};
      return Object.values(treeItems || {}).length;
    }

    if (isCheckboxFilter) {
      return companyDetailFilter.options.length;
    }

    return 1;
  })();

  const handlePrimaryFilterClear = () => {
    if (isTreeFilter) {
      dispatch({
        type: 'REMOVE_COMPANY_DETAIL_TREE_FILTER',
        name: companyDetailFilter.name,
      });
    }

    if (isCheckboxFilter) {
      dispatch({
        type: 'REMOVE_COMPANY_DETAIL_CHECKBOX_FILTER',
        name: companyDetailFilter.name,
      });
    }

    if (isYearRangeFilter) {
      dispatch({
        type: 'REMOVE_COMPANY_DETAIL_YEAR_RANGE_FILTER',
        name: companyDetailFilter.name,
      });
    }

    if (isCompanySearchFilter) {
      dispatch({
        type: 'REMOVE_COMPANY_DETAIL_SEARCH_FILTER',
        name: companyDetailFilter.name,
      });
    }

    if (isSchoolFilter) {
      dispatch({
        type: 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER',
        name: companyDetailFilter.name,
      });
    }

    if (isFreeText) {
      dispatch({
        type: 'REMOVE_FREE_TEXT_FILTER',
        name: companyDetailFilter.name,
      });
    }
  };

  const primaryFilterKey = companyDetailFilter.name;

  const checkboxedFilterOption = findOptionInFilterOptions(
    filterOptions,
    (option) => primaryFilterKey.includes(option.value)
  );

  return (
    <ScreenerFilterPopover
      trigger={
        <Tag
          size="sm"
          variant="solid"
          cursor="pointer"
          height={6}
          borderRadius="4px"
          _hover={{ background: 'white' }}
          as={Button}
          data-testid="primary-filter-tag"
        >
          <TagLabel _hover={{ textDecoration: 'underline' }}>
            <Flex>
              <Text>{checkboxedFilterOption?.label}</Text>
              <Text mx={0.5}>•{itemCount > 1 ? ' is one of ' : ' is '}•</Text>
              {filterNames}
            </Flex>
          </TagLabel>
          <TagCloseButton
            onClick={handlePrimaryFilterClear}
            data-testid={`primary-filter-tag-remove-${checkboxedFilterOption?.label}`}
          />
        </Tag>
      }
      filterOptions={filterOptions}
      selectedFilterOverride={checkboxedFilterOption}
    />
  );
};
