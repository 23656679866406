import { FreeTrialAlertModal } from '@revelio/composed';
import { Page, PageProps, useFreeTrialAlertModalState } from '@revelio/layout';

export function DashboardPage({ topRight, ...props }: PageProps) {
  const { showTrialChip, days, hours } = useFreeTrialAlertModalState();

  return (
    <Page
      {...props}
      topRight={
        topRight
          ? topRight
          : showTrialChip && <FreeTrialAlertModal days={days} hours={hours} />
      }
    />
  );
}

export default DashboardPage;
