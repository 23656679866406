import { mtPercentiles } from './mt_percentiles';
import { dsrPercentiles } from './dsr_percentiles';
import { ttfPercentiles } from './ttf_percentiles';
import { wagePercentiles } from './wage_percentiles';

export const granularityLookup = {
  demandSupply: {
    mean: 0.0732,
    std: 0.3681,
    lookup: dsrPercentiles,
  },
  timeToFill: {
    mean: 38.0,
    std: 12.9,
    lookup: ttfPercentiles,
  },
  wageGrowth: {
    mean: 0.0298,
    std: 0.1048,
    lookup: wagePercentiles,
  },
  marketTightness: {
    lookup: mtPercentiles,
  },
};
