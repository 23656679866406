import React, { useContext, useState } from 'react';
import { SelectedOptionsInterface } from '../../../data-enrichment/types/dataEnrichmentTypes';
import { FileUploadContextType } from '../../../file-upload/file-upload-context';
import { DataInterface } from '../../../file-upload/file-upload.types';
import MappingStep from '../../../file-upload/mapping-step';
import { SnowflakeMapperContextType } from '../../../snowflake-mapper/snowflake-mapper-context';
import SnowflakeMapperModal from '../../../snowflake-mapper/snowflake-mapper-modal';
import UploadCompaniesModal from './upload-companies-modal';

type ModalType = typeof UploadCompaniesModal | typeof SnowflakeMapperModal;

const MappingStepWithState = ({
  UploadContext,
  Modal,
}: {
  UploadContext: React.Context<
    FileUploadContextType | SnowflakeMapperContextType
  >;
  Modal: ModalType;
}) => {
  const { headers, setData, goToNext } = useContext(UploadContext);
  // this is here so it doesn't cause re-rending of the whole upload companies list every time columns are mapped
  const [selectedOptions, setSelectedOptions] = useState<
    SelectedOptionsInterface[]
  >([]);

  const handleGetSelectedValues = () => {
    const mappedValues = selectedOptions
      .map(
        (option: SelectedOptionsInterface, index: number) =>
          option && {
            [headers[index]]: option?.value,
          }
      )
      .filter((item) => {
        return item !== null;
      });
    setData((prevData: DataInterface) => ({
      ...prevData,
      mappedColumns: mappedValues as { [arg0: string]: string }[],
    }));
    goToNext();
  };

  return (
    <MappingStep
      onSubmit={handleGetSelectedValues}
      columnOptions={COMPANY_MAPPING_OPTIONS}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      ContainerElement={({ onSubmit, children }) => {
        return <Modal onContinue={onSubmit}>{children}</Modal>;
      }}
      maxSampleDataColumnWidth="270px"
      UploadContext={UploadContext}
    />
  );
};

export const COMPANY_MAPPING_OPTIONS: SelectedOptionsInterface[] = [
  { label: 'Company ID', value: 'COMPANY_ID' },
  { label: 'Company Name', value: 'COMPANY_NAME' },
  { label: 'Ticker', value: 'TICKER' },
  { label: 'Website', value: 'WEBSITE' },
  { label: 'Country', value: 'COUNTRY' },
  { label: 'State', value: 'STATE' },
  { label: 'City', value: 'CITY' },
  { label: 'Year Founded', value: 'YEAR_FOUNDED' },
  { label: 'Linkedin URL', value: 'LINKEDIN_URL' },
  { label: 'Factset Entity ID', value: 'FACTSET_ENTITY_ID' },
  { label: 'LEI', value: 'LEI' },
  { label: 'CIK', value: 'CIK' },
  { label: 'SIC', value: 'SIC' },
  { label: 'NAICS', value: 'NAICS' },
  { label: 'ISIN', value: 'ISIN' },
];

export default MappingStepWithState;
