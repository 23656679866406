import {
  CompensationResponseLevelOverrides,
  getPlotColorByKey,
} from '@revelio/core';
import { isUndefined } from 'lodash';

export const getBoxPlotColor = (d) => {
  const id = d?.value?.id;

  const colorKey = getColorCode(id);

  const assignedColor = getPlotColorByKey(colorKey);

  return assignedColor;
};

export const getColorCode = (id) => {
  if (isUndefined(id)) return id;

  const [level, numId] = id.split('__');

  if (level) {
    const levelStringOverride = CompensationResponseLevelOverrides[level];

    const formattedId =
      levelStringOverride && !isUndefined(numId)
        ? `${levelStringOverride}__${numId}`
        : id;

    return formattedId;
  }

  return id;
};
