import { DeliveryStatus } from '@revelio/data-access';

type TagStatusColorMap = {
  [key in string]: {
    bgColor: string;
    color: string;
  };
};

export const tagStatusColorMap: TagStatusColorMap = {
  [DeliveryStatus.Running]: {
    bgColor: 'blue.100',
    color: 'blue.600',
  },
  [DeliveryStatus.Failed]: {
    bgColor: 'red.100',
    color: 'red.600',
  },
  [DeliveryStatus.Scheduled]: {
    bgColor: 'purple.100',
    color: 'purple.600',
  },
  [DeliveryStatus.Finished]: {
    bgColor: 'green.100',
    color: 'green.600',
  },
};
