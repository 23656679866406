import { Step } from 'react-joyride';
import { TourClasses } from '../../constants';

export const steps: Step[] = [
  {
    title: 'Welcome to Revelio Labs',
    content:
      'Harness the world of publicly available data to understand and track the workforce.',
    placement: 'center',
    target: 'body',
    disableBeacon: true,
    showProgress: false,
  },
  {
    title: 'Control panel',
    content:
      'Here is your control panel where you can select your preferred unit of analysis.',
    placement: 'right',
    spotlightPadding: 4,
    target: `.${TourClasses.TOUR_CONTROL_PANEL_CLASS}`,
    disableBeacon: true,
  },
  {
    title: 'Data Views',
    content: 'Each subject has multiple views to choose from.',
    placement: 'auto',
    target: `.${TourClasses.TOUR_DATA_SOURCES_CLASS}`,
    styles: {
      options: {
        arrowColor: 'white',
      },
    },
    disableBeacon: true,
  },
  {
    title: 'Tracking',
    content: 'You can select the specific entities to track.',
    placement: 'right-start',
    target: `.${TourClasses.TOUR_TRACKING_CLASS}`,
    disableBeacon: true,
  },
  {
    title: 'Filters',
    content:
      'And you can filter those entities by role, skill, seniority, geography, and many other filter types.',
    placement: 'right-start',
    target: `.${TourClasses.TOUR_FILTER_MENU_CLASS}`,
    disableBeacon: true,
  },
  {
    title: 'History',
    content: 'You can also see this data over time.',
    placement: 'left-start',
    target: `.${TourClasses.TOUR_VIEW_CLASS}`,
    disableBeacon: true,
    showProgress: false,
  },
];
