import { useState } from 'react';
import { globalPageLoadingStatus } from '../../../engine/filters.engine';
import { useEffect$ } from '@ngneat/react-rxjs';
import { tap, filter } from 'rxjs';
import { isUndefined } from 'lodash';

export const useChipsLoadingState = (initialState = true) => {
  const [isLoading, setIsLoading] = useState<boolean>(initialState);

  useEffect$(
    () =>
      globalPageLoadingStatus.pipe(
        filter(({ areWeLoading }) => {
          return initialState && !isUndefined(areWeLoading);
        }),
        tap(({ areWeLoading }) => {
          if (!areWeLoading) {
            setIsLoading(false);
          }
        })
      ),
    []
  );

  return isLoading;
};
