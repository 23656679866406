import { SentimentGetSummaryDataQuery } from '@revelio/data-access';
import {
  CompositionStatsMetric,
  PostingsStatsMetric,
  SentimentMetric,
} from '../../../../../shared/stat';
import { CompanyStatsProps } from '../types';

type DataWithMetadata = {
  metadata?: {
    id?: number | null;
  } | null;
};

type MetricExtractor<T extends DataWithMetadata, U> = (
  data: T,
  metric: U
) => number;

const calculateWeightedAverage = <T extends DataWithMetadata, U>(
  competitors: CompanyStatsProps['competitors'],
  data: T[],
  extractMetric: MetricExtractor<T, U>,
  metric: U
) => {
  const { totalMetric, totalClosenessScore } = competitors?.reduce(
    (acc, competitor) => {
      const datum = data?.find(
        (item) => item?.metadata?.id === competitor?.metadata?.id
      );

      if (!datum) {
        return acc;
      }

      const metricValue = extractMetric(datum, metric);
      const closenessScore = competitor?.closeness_score || 1;

      return {
        totalMetric: acc.totalMetric + metricValue * closenessScore,
        totalClosenessScore: acc.totalClosenessScore + closenessScore,
      };
    },
    { totalMetric: 0, totalClosenessScore: 0 }
  ) || { totalMetric: 0, totalClosenessScore: 0 };

  return totalClosenessScore > 0 ? totalMetric / totalClosenessScore : 0;
};

export const getAggregatedCompetitorCompositionMetrics = (
  competitors: CompanyStatsProps['competitors'],
  compositionData: CompanyStatsProps['compositionData'],
  metric: CompositionStatsMetric
) => {
  const validCompositionData = (compositionData?.composition || []).filter(
    (datum): datum is NonNullable<typeof datum> & DataWithMetadata =>
      datum !== null && datum.metadata !== null
  );

  return calculateWeightedAverage(
    competitors,
    validCompositionData,
    (datum, metric) => {
      const timeseries = datum?.metrics?.[metric]?.timeseries;

      return timeseries?.[timeseries?.length - 1]?.value || 0;
    },
    metric
  );
};

export const getAggregatedCompetitorPostings = (
  competitors: CompanyStatsProps['competitors'],
  postingsActiveData: NonNullable<
    CompanyStatsProps['postingsActiveData']
  >['posting'],
  metric: PostingsStatsMetric
) => {
  if (!postingsActiveData) {
    return 0;
  }

  const validPostingsData = postingsActiveData.filter(
    (datum): datum is NonNullable<typeof datum> & DataWithMetadata =>
      datum !== null && datum.metadata !== null
  );

  return calculateWeightedAverage(
    competitors,
    validPostingsData,
    (datum, metric) => {
      const category = datum?.category;
      const activePostings =
        category?.[category.length - 1]?.metrics?.[metric] || 0;

      return activePostings;
    },
    metric
  );
};

export const getAggregatedCompetitorSentiment = (
  competitors: CompanyStatsProps['competitors'],
  sentimentData: NonNullable<SentimentGetSummaryDataQuery['sentiment2d']>,
  metric: SentimentMetric
) => {
  const validSentimentData = sentimentData.filter(
    (datum): datum is NonNullable<typeof datum> & DataWithMetadata =>
      datum !== null && datum.metadata !== null
  );

  return calculateWeightedAverage(
    competitors,
    validSentimentData,
    (datum, metric) => {
      const category = datum?.category;
      const totalSentiment =
        category?.[category.length - 1]?.metrics?.[metric] || 0;

      return totalSentiment;
    },
    metric
  );
};
