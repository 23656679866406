import React, { useState, isValidElement } from 'react';
import type { ReactNode } from 'react';
import { Center, Flex, Spacer, Divider, Heading } from '@chakra-ui/react';

import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  FilterContainerName,
  FilterContainerProps,
  useGlobalLoader,
  useGlobalLoaderV2,
  useManualLoader,
} from '@revelio/filtering';
import { LoaderType, LoadingBox } from '@revelio/core';
import { isUndefined } from 'lodash';
import { TabProps, TabsList, TabsPanel } from '../tabs';
import AlertDialog from '../alert-dialog/alert-dialog';
import { RlBox } from '../box/box';
import { PAGE_CONTAINER_CLASS_NAME } from '@revelio/filtering';

export type TitleType = string | string[];

export interface PageProps {
  title: TitleType;
  topRight?: React.ReactNode;
  selections?: JSX.Element; //this will be the little chips that show what youve selected
  hideSelectionsMargins?: boolean;
  hideTop?: boolean;
  display?: 'flex' | 'block';
  loaderActiveOnInit?: boolean;
  children:
    | React.ReactElement<TabProps | FilterContainerProps>[]
    | React.ReactNode;
  loader?: LoaderType;
  loading?: boolean;
  scrollY?: boolean;
}

function headerTemplate(title: TitleType) {
  let arrayCopy;

  if (!Array.isArray(title)) {
    arrayCopy = [title];
  } else {
    arrayCopy = [...title];
  }

  if (arrayCopy.length === 0) {
    throw new ReferenceError(
      'Title must be a string or a non-empty array of strings'
    );
  }

  return (
    <Flex direction="row" data-testid="page-header-label">
      <Heading color="text.primary" fontWeight="600" fontSize="xl">
        {arrayCopy.shift()}
      </Heading>
      {arrayCopy.map((item, i) => {
        return (
          <Center key={i}>
            <ChevronRightIcon
              boxSize="5"
              color="text.primary"
              marginX="3px"
              transform="translateY(1px)"
            />
            <Heading color="text.primary" fontWeight="600" fontSize="xl">
              {item}
            </Heading>
          </Center>
        );
      })}
    </Flex>
  );
}

export function Page({
  topRight,
  selections,
  hideSelectionsMargins,
  hideTop,
  display = 'flex',
  loaderActiveOnInit,
  loader = LoaderType.GLOBAL_V2,
  loading: loadingProp,
  scrollY,
  ...props
}: PageProps) {
  const originalChildren = Array.isArray(props.children)
    ? props.children
    : [props.children];
  const children: {
    filtersContainer: React.ReactElement[];
    tabs: React.ReactElement[];
    other: ReactNode[];
  } = {
    filtersContainer: [],
    tabs: [],
    other: [],
  };
  const tabNames: string[] = [];
  let tabsEnabled = false;

  originalChildren.forEach((tItem) => {
    if (isValidElement(tItem)) {
      if ('name' in tItem.props) {
        tabNames.push(tItem.props.name);
        children.tabs.push(tItem);
      } else if (FilterContainerName in tItem.props) {
        children.filtersContainer.push(tItem);
      } else {
        children.other.push(tItem);
      }
    }
  });

  tabsEnabled = children.tabs.length > 0;

  const [tab, setTab] = useState<string>(tabNames[0]);

  const globalLoader = useGlobalLoader(
    isUndefined(loaderActiveOnInit) ? true : loaderActiveOnInit
  );
  const globalLoaderV2 = useGlobalLoaderV2(
    isUndefined(loaderActiveOnInit) ? true : loaderActiveOnInit
  );
  const manualLoader = useManualLoader(
    isUndefined(loaderActiveOnInit) ? false : loaderActiveOnInit
  );

  const pickedLoaderInternal = {
    [LoaderType.GLOBAL]: globalLoader,
    [LoaderType.GLOBAL_V2]: globalLoaderV2,
    [LoaderType.DUAL]: globalLoaderV2 || manualLoader,
    [LoaderType.MANUAL]: manualLoader,
  }[loader];

  const pickedLoader = isUndefined(loadingProp)
    ? pickedLoaderInternal
    : loadingProp;

  return (
    <Flex
      backgroundColor="background.primary"
      height="100vh"
      direction="column"
      paddingX="6"
      paddingTop="2"
      paddingBottom="1"
      overflowX="hidden"
      overflowY={hideTop || scrollY ? 'auto' : 'hidden'}
      display={display}
      className={PAGE_CONTAINER_CLASS_NAME}
    >
      <AlertDialog
        alertType={'actionless'} // the alertType can be changed by defaultAlertDialogDisclosureControl observable
        headerText={''} // the headerText and bodyContent are set by defaultAlertDialogDisclosureControl observable
        headerProps={{ pb: 0 }}
        bodyContent={<span></span>}
        buttonLoading={false}
        showCloseBtn={false}
        chakraAlertProps={{
          closeOnOverlayClick: true,
          motionPreset: 'slideInBottom',
          isCentered: true,
        }}
      />
      {!hideTop && (
        <>
          <Flex direction="row" flexWrap="wrap">
            <Center justifyContent="flex-start">
              {headerTemplate(props.title)}
            </Center>
            <Spacer />
            <Center justifyContent="flex-end">{topRight}</Center>
          </Flex>
          <Divider
            color="text.lowContrast"
            marginTop="1"
            marginBottom={hideSelectionsMargins ? '0' : '3'}
            opacity="1"
          />
        </>
      )}

      {selections && (
        <Flex direction="row" marginBottom={hideSelectionsMargins ? '0' : '3'}>
          {selections}
        </Flex>
      )}

      <Flex /*height="12"*/ direction="row" marginBottom="2">
        {!!children.filtersContainer.length && (
          <Center flexGrow={2} height="100%" justifyContent="flex-start">
            {children.filtersContainer}
          </Center>
        )}

        {tabNames.length > 0 && (
          <Center height="100%" justifyContent="flex-end">
            <TabsList tabs={tabNames} tab={tab} setTab={setTab} />
          </Center>
        )}
      </Flex>

      <LoadingBox
        testId="page-data-loading"
        loading={pickedLoader}
        backgroundColorOpacity={1}
        height={{ base: 'unset', sm: 'fit-content' }}
        overflowY={{ base: 'scroll', md: 'unset' }}
      >
        {tabsEnabled ? (
          <TabsPanel tab={tab}>{children.tabs}</TabsPanel>
        ) : (
          <RlBox variant="large-container">{children.other}</RlBox>
        )}
      </LoadingBox>
    </Flex>
  );
}

export default Page;
