import { capitalize, isString } from 'lodash';

/**
 * In its current form, lodash's startCase function removes special characters.
 * This function works like startCase, but allows user to select the special
 * characters they want to keep in the output.
 *
 * @param str - string to format
 * @param charsToPreserve - array of strings to preserve
 *
 * @returns formatted string
 */
export const startCasePreserveChars = (
  str: string | unknown,
  charsToPreserve: string[],
  delimiter = ' '
) => {
  const _str = isString(str) ? str : `${str}`;
  const separated = _str.split(delimiter);

  const processed = separated.map((word: string) => {
    if (charsToPreserve?.includes(word)) {
      return word;
    }

    return capitalize(word);
  });

  return processed.join(' ');
};
