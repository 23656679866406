import { graphql, http, HttpResponse } from 'msw';
import { whoAmIHandler } from '@revelio/auth/mocks';
import { usersQueryData } from './usersQuery';
import { postingsTopRoleQueryData } from './postingsTopRoleQuery';
import companySelectionListMock from './companySelectionList';
// import { MOCK_API_ROOT } from '@revelio/filtering';
import { GetRevelioAdminUserDetailsHandler } from '@revelio/core';
import { reportConfigHandler } from '../next/deliverables/report-builder/mocks/handlers';

export const GetAllUsersHandler = graphql.query('GetAllUsers', () => {
  return HttpResponse.json({
    data: usersQueryData,
  });
});

export const GetAllClientUsersHandler = graphql.query(
  'GetAllClientUsers',
  () => {
    return HttpResponse.json({
      data: { clients: [usersQueryData] },
    });
  }
);

export const GetAllClientsHandler = graphql.query('AllClients', () => {
  return HttpResponse.json({
    data: {
      clients: [
        {
          client_name: 'Hogwarts',
          __typename: 'Client',
        },
        {
          client_name: 'Ministry of Magic',
          __typename: 'Client',
        },
        {
          client_name: 'Durmstrang',
          __typename: 'Client',
        },
        {
          client_name: 'Revelio Labs',
          __typename: 'Client',
        },
      ],
    },
  });
});

export const GetAllCompanyListsHandler = graphql.query(
  'AllCompanyLists',
  () => {
    return HttpResponse.json({
      data: {
        company_lists: [
          {
            id: '828340263681622036',
            name: 'MASTER',
            __typename: 'CompanyList',
          },
          {
            id: '681049582353711114',
            name: 'Public Companies',
            __typename: 'CompanyList',
          },
          {
            id: '681049584891297793',
            name: 'Unicorns',
            __typename: 'CompanyList',
          },
        ],
      },
    });
  }
);

export const GetClientDetailsHandler = graphql.query(
  'GetClientDetails',
  ({ variables }) => {
    const { ids } = variables;
    return HttpResponse.json({
      data: {
        clients: [
          {
            client_name: ids[0],
            company_lists: ['828340263681622036'],
            active: true,
            tabs: [
              'screener',
              'job_analysis_overview',
              'posting',
              'overview',
              'transition',
              'sentiment',
              'skill',
              'data_dictionary',
              'job_analysis',
              'talent_discovery',
            ],
            live: true,
            linkup_postings: false,
            unified_postings: false,
            num_seats: '0',
            __typename: 'Client',
          },
        ],
      },
    });
  }
);

export const GetPostingsTopRolesDataHandler = graphql.query(
  'GetPostingsRolesData',
  () => {
    return HttpResponse.json({
      data: postingsTopRoleQueryData,
    });
  }
);

export const GetPostingsDataHandler = graphql.query(
  'GetPostingsData',
  ({ variables }) => {
    const { filters } = variables;
    return HttpResponse.json({
      data: {
        posting: [
          {
            metadata: {
              id: filters.company[0],
              shortName: 'Workday',
            },
            category: [
              {
                metadata: {
                  id: 1,
                  shortName: '2019-03-04',
                  longName: '2019-03-04',
                },
                metrics: {
                  salary: 102368,
                  time_to_fill: 71,
                  active: 539,
                  new: 58,
                  removed: 47,
                },
              },
              {
                metadata: {
                  id: 2,
                  shortName: '2019-03-11',
                  longName: '2019-03-11',
                },
                metrics: {
                  salary: 102515,
                  time_to_fill: 81,
                  active: 547,
                  new: 55,
                  removed: 52,
                },
              },
            ],
          },
        ],
      },
    });
  }
);

// export const companySelectionListErrorHandler = rest.get(
//   `/api/filter/company`,
//   (_req, res, ctx) => {
//     return res(ctx.status(500));
//   }
// );

export const companySelectionListHandler = http.get(
  `${'https://msw-mock-filtering.api'}/api/filter/company`,
  () => {
    return HttpResponse.json(companySelectionListMock);
  }
);

export const handlers = [
  // Handles a "GetUserInfo" query
  GetRevelioAdminUserDetailsHandler,
  GetAllUsersHandler,
  whoAmIHandler,
  GetClientDetailsHandler,
  GetPostingsDataHandler,
  GetPostingsTopRolesDataHandler,
  companySelectionListHandler,
  reportConfigHandler,
];
