import {
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  RangeSlider,
  Tooltip,
  Input,
  Flex,
  Text,
  VStack,
  useControllableState,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { format } from 'd3';
import { Select, SingleValue } from 'chakra-react-select';
import { OPValues } from '../../engine/filters.model';
import { isNull } from 'lodash';

type RangeSliderSelectType = {
  label: string;
  value: OPValues;
};

const rangeSliderOptions = [
  {
    label: 'is between',
    value: OPValues.BETWEEN,
  },
  {
    label: 'is less than',
    value: OPValues.LESS,
  },
  {
    label: 'is greater than',
    value: OPValues.GREATER,
  },
];
export interface FilterRangeSliderProps {
  minValue: number;
  maxValue: number;
  value?: number[];
  defaultValue?: number[];
  onChange?: (e: any[]) => void;
  thumbSize?: number;
  prefix?: string;
  suffix?: string;
  inputReadOnly?: boolean;
  step?: number;
  minStepsBetweenThumbs?: number;
  width?: number | string;
  showSlider?: boolean;
}

export interface FilterRangeSliderThumbProps {
  isTooltipOpen: boolean;
  tooltipLabel: string;
  thumbSize: number;
}

export function FilterRangeSlider({
  minValue,
  maxValue,
  value,
  defaultValue = [0, 0],
  step = 1,
  minStepsBetweenThumbs = 1,
  onChange,
  thumbSize = 4,
  prefix,
  suffix,
  width,
  inputReadOnly = false,
  showSlider = true,
}: FilterRangeSliderProps) {
  const [mouseOverSlider, setMouseOverSlider] = useState(false);

  const [isDragging, setIsDragging] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const [internalValue, setInternalValue] = useControllableState({
    value,
    defaultValue,
    onChange,
  });

  useEffect(() => {
    const isTooltipOpen = mouseOverSlider || isDragging;

    setShowTooltip(isTooltipOpen);
  }, [mouseOverSlider, isDragging]);

  const [minInputValue, setMinInputValue] = useState<string>('0');
  const [maxInputValue, setMaxInputValue] = useState<string>('0');

  const [selectValue, setSelectValue] = useState<RangeSliderSelectType>(
    rangeSliderOptions[0]
  );

  useEffect(() => {
    ///
    setMinInputValue(String(defaultValue[0]));
    setMaxInputValue(String(defaultValue[1]));
  }, [defaultValue]);

  return (
    <Flex direction="column" py={2} w={width}>
      <VStack spacing={5} w="334px">
        <Box w="100%">
          <Select
            size="sm"
            value={selectValue}
            onChange={(e: SingleValue<RangeSliderSelectType>) => {
              if (isNull(e)) return;
              setSelectValue(e);
            }}
            options={rangeSliderOptions}
          />
        </Box>

        <Flex align="center" justifyContent="space-between" w="100%">
          <NumberFormat
            customInput={Input}
            value={minInputValue}
            thousandSeparator={true}
            onValueChange={(val: any) => {
              setMinInputValue(val.value);
            }}
            allowNegative={false}
            onBlur={() => {
              setInternalValue((prevState: any) => {
                let newMin = Number(minInputValue);

                const maxNum = Number(maxInputValue);

                if (newMin >= maxNum) {
                  newMin = maxNum - step < minValue ? minValue : maxNum - step;
                }

                const [, prevMax] = prevState;

                setMinInputValue(String(newMin));

                return [newMin, prevMax];
              });
            }}
            prefix={prefix}
            suffix={suffix}
            type="text"
            size="sm"
            width="100%"
            flex="1"
          />

          {selectValue.value === OPValues.BETWEEN && (
            <>
              <Text px="2" fontSize="sm">
                to
              </Text>
              <NumberFormat
                customInput={Input}
                value={maxInputValue}
                thousandSeparator={true}
                readOnly={inputReadOnly}
                onValueChange={(val: any) => {
                  setMaxInputValue(val.value);
                }}
                allowNegative={false}
                onBlur={() => {
                  setInternalValue((prevState: any) => {
                    let newMax = Number(maxInputValue);

                    const minNum = Number(minInputValue);

                    if (newMax <= minNum) {
                      newMax =
                        minNum + step > maxValue ? maxValue : minNum + step;
                    }

                    const [prevMin] = prevState;

                    setMaxInputValue(String(newMax));

                    return [prevMin, newMax];
                  });
                }}
                prefix={prefix}
                suffix={suffix}
                type="text"
                size="sm"
                width="100%"
                flex="1"
              />
            </>
          )}
        </Flex>
        {showSlider && (
          <Flex px={2} w="100%">
            <RangeSlider
              // eslint-disable-next-line
              aria-label={['min', 'max']}
              min={minValue}
              max={maxValue}
              value={internalValue}
              step={step}
              minStepsBetweenThumbs={minStepsBetweenThumbs}
              defaultValue={defaultValue}
              colorScheme="green"
              zIndex={0}
              onChange={(e) => {
                const [min, max] = e;

                setMinInputValue(String(min));
                setMaxInputValue(String(max));

                setInternalValue((prevState: any) => {
                  const [prevMin, prevMax] = prevState;

                  if (prevMin === min && prevMax === max) {
                    return prevState;
                  }

                  return [min, max];
                });
              }}
              onChangeStart={() => {
                setIsDragging(true);
              }}
              onChangeEnd={() => {
                setIsDragging(false);
              }}
              onMouseEnter={(e) => {
                setMouseOverSlider(true);
              }}
              onMouseLeave={() => {
                setMouseOverSlider(false);
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <Tooltip
                hasArrow
                placement="top"
                variant="label"
                isOpen={showTooltip}
                label={format('$,')(internalValue[0])}
                zIndex="tooltip"
                modifiers={[
                  { name: 'computeStyles', options: { adaptive: false } },
                ]}
              >
                <RangeSliderThumb
                  index={0}
                  boxSize={thumbSize}
                  bg="green.500"
                />
              </Tooltip>
              <Tooltip
                hasArrow
                placement="top"
                variant="label"
                isOpen={showTooltip}
                label={format('$,')(internalValue[1])}
                modifiers={[
                  { name: 'computeStyles', options: { adaptive: false } },
                ]}
              >
                <RangeSliderThumb
                  index={1}
                  boxSize={thumbSize}
                  bg="green.500"
                />
              </Tooltip>
            </RangeSlider>
          </Flex>
        )}
      </VStack>
    </Flex>
  );
}

export default FilterRangeSlider;
