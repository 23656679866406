import { Flex } from '@chakra-ui/layout';
import { ReactNode } from 'react';

type FormInputMimicBoxProps = {
  isEditing: boolean;
  isDisabled: boolean;
  hasError: boolean;
  children: ReactNode;
};
export const FormInputMimicBox = ({
  isEditing,
  isDisabled,
  hasError,
  children,
}: FormInputMimicBoxProps) => {
  const borderColor = getNonInputBorderColor({
    hasError,
    isEditing,
  });
  return (
    <Flex
      background="white"
      p={isEditing ? '3px' : '4px'}
      width="98%"
      ml="2"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      opacity={isDisabled ? 0.4 : 1}
      borderRadius="md"
      minH="38px"
      alignItems="center"
      _hover={{
        borderColor: borderColor || 'gray.300',
        borderWidth: '1px',
        p: '3px',
      }}
      borderColor={borderColor}
      borderWidth={isEditing || hasError ? '1px' : undefined}
      boxShadow={hasError && !isEditing ? '0 0 0 1px #E53E3E' : undefined}
    >
      {children}
    </Flex>
  );
};

const getNonInputBorderColor = ({
  hasError,
  isEditing,
}: {
  hasError: boolean;
  isEditing: boolean;
}) => {
  if (isEditing) {
    return 'green.500';
  }

  if (hasError) {
    return 'red.500';
  }

  return undefined;
};
