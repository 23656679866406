import { useEffect, useState } from 'react';
import { getEntitiesIds, selectEntity } from '@ngneat/elf-entities';
import { useObservable } from 'react-use';
import { map } from 'rxjs';
import { isEqual, map as _map, lowerFirst, startCase, omit } from 'lodash';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/menu';
import { Box, Button, Checkbox, Collapse, Flex, Text } from '@chakra-ui/react';

import {
  LATEST_MODEL_VERSIONS,
  deliverablesStore,
  resetModelVersionsToLatest,
  selectLag_data,
  updateDraftDeliverable,
  updateLag_data,
  updateModelVersions,
} from '../deliverables.repository';
import {
  CompanyMapperVersion,
  EthnicityVersion,
  JobTaxonomyVersion,
  LocationVersion,
  ModelVersions,
  SalaryVersion,
  SeniorityVersion,
  TimescalingVersion,
  WeightTable,
} from '@revelio/data-access';
import { Deliverable } from '../deliverables.model';
import { write } from '@revelio/core';

const MODEL_VERSION_OPTIONS = {
  location_version: LocationVersion,
  timescaling_version: TimescalingVersion,
  ethnicity_version: EthnicityVersion,
  job_taxonomy_version: JobTaxonomyVersion,
  company_mapper_version: CompanyMapperVersion,
  salary_version: SalaryVersion,
  seniority_version: SeniorityVersion,
  weight_table: WeightTable,
};

const DataConfiguration = () => {
  const entityIds = deliverablesStore.query(getEntitiesIds());
  const versions = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityIds[0]),
      map((deliverable) => deliverable?.model_versions)
    )
  );
  const isDataLagged = useObservable(deliverablesStore.pipe(selectLag_data()));

  const [isOverrideOpen, setIsOverrideOpen] = useState(false);
  const isLatest = areLatestModelVersions({
    modelVersions: versions,
  });

  useEffect(() => {
    // when versions are set to "custom", and the user navigates back to the columns page
    // we want to initially have the override menu open
    if (versions && !isLatest && !isOverrideOpen) {
      // check versions because on initial render, versions from the store is undefined and isLatest is false
      // causing the override menu to open even when it is "Latest".
      setIsOverrideOpen(true);
    }
  }, [isLatest, isOverrideOpen, versions]);

  return (
    <Flex alignItems="center">
      <Checkbox
        id="lag_data"
        name="lag_data"
        colorScheme="green"
        isChecked={isDataLagged}
        onChange={(e) => {
          deliverablesStore.update(updateLag_data(e.target.checked));
        }}
        mr="2"
      >
        <Text fontSize="sm">Lag data 1 year</Text>
      </Checkbox>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          colorScheme="gray"
          variant="outline"
          fontWeight="500"
        >
          <Box minWidth="90px">
            {isLatest ? 'Latest Models' : 'Custom Models'}
          </Box>
        </MenuButton>
        <MenuList>
          <MenuGroup title="Model versions">
            <MenuItem _focus={{ bg: 'white' }}>
              <Checkbox
                id="latest_models"
                name="latest_models"
                colorScheme="green"
                isChecked={isLatest}
                onChange={() => {
                  if (!isLatest) {
                    entityIds.forEach((entityId) => {
                      resetModelVersionsToLatest({ entityId });
                    });
                    setIsOverrideOpen(false);
                  }
                }}
              >
                <Text fontSize="sm">Latest</Text>
              </Checkbox>
            </MenuItem>

            <Collapse in={isOverrideOpen} animateOpacity>
              {_map<ModelVersions>(
                omit(LATEST_MODEL_VERSIONS, 'remove_bad_users'),
                (_: ModelVersions, versionKey: keyof ModelVersions) => (
                  <MenuItem
                    as="div"
                    key={`menu-${versionKey}`}
                    _focus={{ bg: 'white' }}
                  >
                    <SingleVersionMenuSelection
                      modelName={versionKey}
                      value={versions?.[versionKey] as string}
                      options={
                        MODEL_VERSION_OPTIONS[
                          versionKey as keyof typeof MODEL_VERSION_OPTIONS
                        ] as {
                          [optionLabel: string]: string;
                        }
                      }
                      onChange={(newValue) => {
                        entityIds.forEach((entityId) => {
                          const compatibleNewVersionValue = newValue;
                          updateModelVersions({
                            entityId,
                            newModelVersions: {
                              [versionKey]: compatibleNewVersionValue,
                            },
                          });
                        });
                      }}
                    />
                  </MenuItem>
                )
              )}

              <MenuItem _focus={{ bg: 'white' }}>
                <Checkbox
                  id="remove_bad_users"
                  name="remove_bad_users"
                  colorScheme="green"
                  isChecked={!!versions?.remove_bad_users}
                  onChange={(e) => {
                    entityIds.forEach((entityId) => {
                      updateDraftDeliverable(
                        entityId,
                        write<Deliverable>((state) => {
                          (
                            state.model_versions as ModelVersions
                          ).remove_bad_users = e.target.checked;
                        })
                      );
                    });
                  }}
                >
                  <Text fontSize="sm">Remove bad users</Text>
                </Checkbox>
              </MenuItem>
            </Collapse>

            <MenuItem as="div" _focus={{ bg: 'white' }}>
              <Button
                colorScheme="blue"
                fontSize="12px"
                fontWeight="600"
                size="sm"
                variant="link"
                px="6"
                onClick={() => {
                  if (isOverrideOpen) {
                    entityIds.forEach((entityId) => {
                      resetModelVersionsToLatest({ entityId });
                    });
                  }

                  setIsOverrideOpen(!isOverrideOpen);
                }}
              >
                {isOverrideOpen ? 'Reset to Latest' : 'Override'}
              </Button>
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export const formatVersionValue = (versionValue: string | undefined) =>
  lowerFirst(versionValue).replace('_', '.');
export const formatVersionName = (modelName: keyof ModelVersions) =>
  startCase(modelName.replace('_version', ' '));
interface SingleVersionMenuSelectionProps {
  modelName: keyof ModelVersions;
  options: { [optionLabel: string]: string };
  value: string | undefined;
  onChange: (value: string | string[]) => void;
}
const SingleVersionMenuSelection = ({
  modelName,
  options,
  value,
  onChange,
}: SingleVersionMenuSelectionProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        size="xs"
        colorScheme="gray"
        variant="outline"
        width="100%"
        textAlign="left"
        fill="white"
        fontWeight="500"
      >
        {formatVersionName(modelName)}: {formatVersionValue(value)}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup value={value} onChange={onChange} type="radio">
          {_map(options, (versionValue, optionLabel) => (
            <MenuItemOption
              key={`menu-item-${optionLabel}-${versionValue}}`}
              value={versionValue}
            >
              {formatVersionValue(optionLabel)}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export const areLatestModelVersions = ({
  modelVersions,
}: {
  modelVersions: ModelVersions | undefined;
}) => {
  if (!modelVersions) {
    return false;
  }

  return isEqual(omit(modelVersions, '__typename'), LATEST_MODEL_VERSIONS);
};

export default DataConfiguration;
