import { Box, Flex, Text } from '@chakra-ui/layout';
import { Icon, Button } from '@chakra-ui/react';
import { FiPlus, FiX } from 'react-icons/fi';
import {
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
} from '../../../engine/filters.constants';
import { SelectionCategories, TreeItem } from '../../../engine/filters.model';
import { parseSelectedSegmentsForDisplay } from '../utils';

interface SegmentFormProps {
  segmentSelection: Record<string, TreeItem<string>>;
  onEditSegment: (key: string) => () => void;
  onRemoveSegment: (
    key: string
  ) => (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
  onNewSegment: () => void;
}
export const SegmentForm = ({
  segmentSelection,
  onEditSegment,
  onRemoveSegment,
  onNewSegment,
}: SegmentFormProps) => {
  const parsedSegments = parseSelectedSegmentsForDisplay(segmentSelection);

  const hasSegments = parsedSegments.length > 0;

  const getSegmentGroup = (key: string) => {
    if (ROLE_GRANULARITY_FILTERS.includes(key as SelectionCategories)) {
      return 'Role_Granularity';
    }

    if (GEOGRAPHY_GRANULARITY_FILTERS.includes(key as SelectionCategories)) {
      return 'Geography_Granularity';
    }

    return key;
  };

  const groupedSegments = parsedSegments.reduce<
    Record<string, [string, TreeItem<string>[]][]>
  >((groups, segment) => {
    const group = getSegmentGroup(segment[0]);

    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(segment);
    return groups;
  }, {});

  const getDisplayNames = (segments: [string, TreeItem<string>[]][]) => {
    const names = segments.flatMap(([_, value]) =>
      value.map((item) => item.item?.shortName)
    );

    return names.length > 2
      ? [
          ...names.slice(0, 2),
          `and ${names.length - 2} other${names.length - 2 > 1 ? 's' : ''}`,
        ].join(', ')
      : names.join(', ');
  };

  return (
    <>
      {hasSegments && (
        <Box marginTop={'18px'} marginLeft={2} paddingX={1.5}>
          {Object.entries(groupedSegments).map(([key, segments]) => (
            <Flex key={key} marginBottom={1.5} alignItems={'center'}>
              <Text fontSize={14}>
                {GEOGRAPHY_GRANULARITY_FILTERS.includes(
                  key as SelectionCategories
                )
                  ? 'in'
                  : 'for'}
              </Text>
              <Flex
                onClick={onEditSegment(segments[0][0])}
                alignItems="center"
                cursor="pointer"
                backgroundColor="lightBlue.100"
                borderRadius={'4px'}
                px={1}
                ml={1}
              >
                <Text fontSize={14} fontWeight={400} color="#079CCF">
                  {getDisplayNames(segments)}
                </Text>
                <Icon
                  as={FiX}
                  boxSize={4}
                  cursor="pointer"
                  onClick={onRemoveSegment(segments[0][0])}
                  color="lightBlue.700"
                  ml={1}
                  data-testid={`remove-segment-${segments[0][0]}`}
                />
              </Flex>
            </Flex>
          ))}
        </Box>
      )}
      <Button
        onClick={onNewSegment}
        variant="link"
        size="sm"
        color="lightBlue.350"
        fontWeight="400"
        marginTop={2.5}
        marginBottom={2.5}
        ml={hasSegments ? 3 : 0}
      >
        <Icon as={FiPlus} boxSize={3} marginTop={'2px'} marginRight={1} />
        <Text as="span" color="lightBlue.350">
          segment by employee type
        </Text>
      </Button>
    </>
  );
};
