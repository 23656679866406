import { Store } from '@ngneat/elf';
import {
  KIBANA_LOG_LEVEL,
  LogBody,
  isWarningError,
} from '@revelio/data-access';
import { CombinedError, Operation } from 'urql';

export type LogOptions = Omit<LogBody, 'message' | 'level' | 'source'>;

interface IKibanaLogger<
  A extends Store<
    {
      name: string;
      state: A;
      config: undefined;
    },
    A
  > = Store,
> {
  source: string;
  authStore: A;
  dashMetaRoot: string;
  sendLogs?: boolean;
}

export class KibanaLogger<A extends Store = Store> {
  private source;
  private authStore;
  private dashMetaRoot;
  private sendLogs;

  constructor({
    source = 'REVELIO_DASHBOARD',
    authStore,
    dashMetaRoot,
    sendLogs = true,
  }: IKibanaLogger<A>) {
    this.source = source;
    this.authStore = authStore;
    this.dashMetaRoot = dashMetaRoot;
    this.sendLogs = sendLogs;
  }

  private getAuthDashMetaCsrfToken() {
    return this.authStore.getValue().dashMetaCsrfToken;
  }

  public setSendLogs(status: boolean) {
    this.sendLogs = status;
  }

  public log = async (message: string, logOptions?: LogOptions) => {
    const logBody: LogBody = {
      source: this.source,
      level: KIBANA_LOG_LEVEL.INFO,
      message,
      ...logOptions,
    };
    return this.sendLog(logBody);
  };

  public warn = async (message: string, logOptions?: LogOptions) => {
    const logBody: LogBody = {
      source: this.source,
      level: KIBANA_LOG_LEVEL.WARN,
      message,
      ...logOptions,
    };

    return this.sendLog(logBody);
  };

  public error = async (message: string, logOptions?: LogOptions) => {
    const logBody: LogBody = {
      source: this.source,
      level: KIBANA_LOG_LEVEL.ERROR,
      message,
      ...logOptions,
    };
    return this.sendLog(logBody);
  };

  private sendLog = async (logBody: LogBody) => {
    const csrfToken = this.getAuthDashMetaCsrfToken();
    if (!csrfToken) throw new Error('Missing CSRF token');

    if (!this.sendLogs) {
      console.warn('Sending logs is turned off');
      return { message: 'Sending logs is turned off' };
    }

    const sendLogResponse = await fetch(`${this.dashMetaRoot}/api/log`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-surf-token-dash-meta': csrfToken,
      },
      body: JSON.stringify(logBody),
    });
    return await sendLogResponse.json();
  };

  public getUrqlErrorLog = (error: CombinedError, operation: Operation) => {
    const fetchOptions = operation?.context?.fetchOptions;
    const headers = ((): { [key: string]: string } | undefined => {
      if (fetchOptions instanceof Function) {
        return fetchOptions()?.headers as { [key: string]: string } | undefined;
      } else {
        return fetchOptions?.headers as { [key: string]: string } | undefined;
      }
    })();

    const requestId = headers?.['x-request-id'];

    const oryUser = this.authStore.getValue().ory;
    const username = oryUser?.username || '';

    const query = operation.query?.loc?.source?.body
      ?.replace(/\n/g, '')
      ?.replace(/\s\s+/g, ' ');
    const variables = JSON.stringify(operation.variables);

    const message = `${error.message}\n\nquery: ${query}\n\nvariables:${variables}`;

    const logOptions: LogOptions = {
      'request-id': requestId,
      url: operation?.context?.url,
      username,
    };

    return { message, logOptions, isWarningLevel: isWarningError(error) };
  };
}
