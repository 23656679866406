import { Box, Text } from '@chakra-ui/layout';
import { NodeApi, Tree } from 'react-arborist';
import { Node } from './node';
import styles from './tree-selection.module.css';
import { TreeApi } from './use-tree-api';
import { handleSearch } from './utils';
import classNames from 'classnames';
import { TreeRow } from './tree-row';
import { ReactNode, useEffect, useState } from 'react';

export type NodeData = {
  id: string;
  name: string;
  linkedIds?: string[];
  secondaryLabel?: string;
  secondaryLabelTitle?: string;
  searchTerms?: string[];
};

export type TreeData = NodeData & {
  children?: TreeData[];
};

export type TreeSelectionDrillProps = {
  isEnabled: boolean;
  drilledIds: string[];
  drillIntoNode: (node: NodeApi<NodeData>) => void;
};

export type TreeSelectionProps = {
  data: TreeData[];
  treeApi: TreeApi;
  openByDefault?: boolean;
  drillMode?: TreeSelectionDrillProps;
  noResultsText?: ReactNode;
};

export const TreeSelection = ({
  data,
  treeApi,
  openByDefault = false,
  drillMode,
  noResultsText = 'No Results',
}: TreeSelectionProps) => {
  const isDrillModeEnabled = drillMode?.isEnabled;

  const {
    treeRef,
    selectedIds,
    indeterminateSelectedIds,
    toggleNodeSelection,
    search,
  } = treeApi;

  const [noResults, setNoResults] = useState(false);
  useEffect(() => {
    const tree = treeRef.current;

    const numVisibleNodes = tree?.visibleNodes.length || 0;
    setNoResults(search.length > 0 && numVisibleNodes === 0);
  }, [search, treeRef]);

  return (
    <Box backgroundColor="white" marginTop="4px">
      {noResults && (
        <Text fontSize="xs" fontWeight="600" letterSpacing={0.8}>
          {noResultsText}
        </Text>
      )}

      <Tree
        data={data}
        className={styles.tree}
        rowClassName={styles.row}
        ref={treeRef}
        searchTerm={search}
        searchMatch={handleSearch}
        openByDefault={openByDefault}
        overscanCount={20}
        width="100%"
        height={300}
        renderRow={(props) => <TreeRow {...props} drillMode={drillMode} />}
      >
        {(props) => (
          <Node
            {...props}
            selected={selectedIds.includes(props.node.id)}
            halfCheck={indeterminateSelectedIds.includes(props.node.id)}
            onToggle={toggleNodeSelection}
            isDrillModeEnabled={isDrillModeEnabled}
            drillIntoNode={drillMode?.drillIntoNode}
            className={classNames({
              [styles.invisible]:
                isDrillModeEnabled &&
                !drillMode.drilledIds.includes(props.node.id),
            })}
          />
        )}
      </Tree>
    </Box>
  );
};
