import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  Icon,
  useToast,
} from '@chakra-ui/react';
import {
  FiShield,
  FiLogOut,
  FiUsers,
  FiSettings,
  FiTrash2,
} from 'react-icons/fi';
import {
  AuthEventIds,
  DASH_META_ROOT,
  clearStorage,
  emitAuthEvent,
  isRoleRevelioAdmin,
  useGetLoggedInUser,
} from '@revelio/auth';
import { useNavigate } from 'react-router';
import { Role } from '@revelio/data-access';

interface AccountMenuProps {
  version?: string;
}

export function AccountMenu({ version }: AccountMenuProps) {
  const navigate = useNavigate();
  const toast = useToast();

  const { loggedInUser } = useGetLoggedInUser();
  const isRevelioAdmin = isRoleRevelioAdmin(loggedInUser.role);
  const userId = loggedInUser.id;
  const username = loggedInUser.name;

  return (
    <Menu placement="right-start" autoSelect={false}>
      <MenuButton _hover={{ opacity: 0.7 }} data-testid="user-controls">
        <Avatar size="sm" bg="lightBlue.500" />
      </MenuButton>
      <MenuList
        boxShadow="xl"
        // workaround to fix focus shadow bugadow
        // ensures popover has box shadow when open
        sx={{
          ':focus:not(:focus-visible)': {
            shadow:
              '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important',
          },
        }}
      >
        <MenuGroup title={username as string}>
          <MenuItem
            onClick={() => navigate(`/manage/user/${userId}/edit`)}
            icon={
              <Icon
                as={FiSettings}
                w={4}
                h={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              />
            }
          >
            Account
          </MenuItem>
          <MenuDivider />
          {isRevelioAdmin && (
            <MenuItem
              onClick={() => navigate('/admin')}
              icon={
                <Icon
                  as={FiShield}
                  w={4}
                  h={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                />
              }
            >
              Admin
            </MenuItem>
          )}
          {isRevelioAdmin && (
            <MenuItem
              onClick={async () => {
                const clearSiteData = fetch(
                  `${DASH_META_ROOT}/api/auth/clear-site-data?types="cache", "storage"`,
                  {
                    credentials: 'include',
                    headers: { 'request-id': crypto.randomUUID() },
                  }
                );

                const manualClear = clearStorage();

                const clearAll = Promise.all([clearSiteData, manualClear]);

                toast.promise(clearAll, {
                  loading: {
                    title: 'Clearing Cache',
                    description: 'Please wait a moment...',
                    position: 'top-right',
                  },
                  success: {
                    title: 'Cache Cleared',
                    position: 'top-right',
                    duration: 1000,
                    onCloseComplete: () => window.location.reload(),
                  },
                  error: (error) => ({
                    title: 'Error Clearing Cache',
                    description: 'Please try again.',
                    position: 'top-right',
                    onCloseComplete: () => console.error(error),
                  }),
                });
              }}
              icon={
                <Icon
                  as={FiTrash2}
                  w={4}
                  h={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                />
              }
            >
              Clear Cache
            </MenuItem>
          )}
          {loggedInUser.role === Role.ClientAdmin && (
            <MenuItem
              onClick={() => navigate('/manage')}
              icon={
                <Icon
                  as={FiUsers}
                  w={4}
                  h={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                />
              }
            >
              User Management
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              emitAuthEvent({
                id: AuthEventIds.USER_LOGOUT,
                data: { explicit: true },
              });
            }}
            icon={
              <Icon
                as={FiLogOut}
                w={4}
                h={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              />
            }
          >
            Logout
          </MenuItem>
          {version && (
            <>
              <MenuDivider />
              <MenuGroup title={version} opacity={0.4} mb={1} />
            </>
          )}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

export default AccountMenu;
