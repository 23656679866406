import {
  FilterChipsToggleList,
  FilterChipsToggleListProps,
} from './filter-chips-toggle-list';
import Skeleton from 'react-loading-skeleton';
import { useChipsLoadingState } from '../hooks/useChipsLoadingState';
import { Box } from '@chakra-ui/layout';
import { isBoolean } from 'lodash';

export interface FilterChipsContainerProps {
  header?: string;
}

export const FilterChipsContainer = ({
  filterNames,
  ...props
}: Omit<FilterChipsToggleListProps, 'entities'> & { limit?: number }) => {
  const isLoading = useChipsLoadingState(
    isBoolean(props.useChipsSkeleton) ? props.useChipsSkeleton : true
  );

  if (isLoading) {
    return (
      <Box data-testid="filter-chip-skeleton">
        <Skeleton
          height="38px"
          width="390px"
          style={{ marginTop: '12px', marginBottom: '12px', color: '#DBE3EB' }}
        />
      </Box>
    );
  }

  return (
    <FilterChipsToggleList
      {...props}
      header="selected"
      filterNames={filterNames}
    />
  );
};
