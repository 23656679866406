import { useMemo } from 'react';
import { Views } from '@revelio/core';
import { plotConfigObject, plotInfo } from './plotInfo';
import { ViewTypes } from '@revelio/filtering';

export interface viewflowProps {
  view: string;
  endpointSegment: string;
  viewType?: ViewTypes[];
}

export interface viewflowReturns {
  plotInfoBody: React.ReactElement | null;
  plotInfoConfig: plotConfigObject | null;
}

export const usePlotInfo = ({
  view = Views.NONE,
  endpointSegment,
  viewType,
}: viewflowProps): viewflowReturns => {
  const lookupFunc = () => {
    const plotExp = plotInfo?.[view]?.[endpointSegment];
    return plotExp
      ? plotExp(viewType)
      : {
          plotInfoBody: null,
          plotInfoConfig: null,
        };
  };
  return useMemo(lookupFunc, [view, endpointSegment, viewType]);
};

export default usePlotInfo;
