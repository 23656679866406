import { Outlet } from 'react-router';
import { PropsWithChildren, useEffect } from 'react';
import {
  ACCOUNT_PORTAL_ROOT,
  AuthEvent,
  AuthEventIds,
  AuthEvents$,
  AuthStoreRootProps,
  authStore,
  fullLogout,
  oryLogout,
  response429,
  updateLoggingOut,
  useGetLoggedInUser,
  useUserReports,
} from '@revelio/auth';
import { filterStore } from '@revelio/filtering';
import { getRegistry } from '@ngneat/elf';
import { assign, get, isEmpty, pick } from 'lodash';
import {
  Loading,
  UserEvents$,
  UserTrackingEvents,
  calcTrialDay,
  userTracking,
} from '@revelio/core';
import { initEffects } from '@ngneat/effects';
import { useEffect$ } from '@ngneat/react-rxjs';
import { distinctUntilKeyChanged, tap } from 'rxjs';
import mixpanel from 'mixpanel-browser';
import { useAuthCheck } from './use-auth-check';
import { Flex } from '@chakra-ui/layout';
import Tour from '../tour/tour';

const AuthInitializer = ({ children }: PropsWithChildren) => {
  const { ory }: AuthStoreRootProps = authStore.getValue();

  useUserReports();

  // save logged in user data to gql cache as soon as possible
  const {
    loggedInUser,
    query: [{ fetching }],
  } = useGetLoggedInUser({ onTrialToLive: () => filterStore.reset() }); // reset filter store to get ensure latest data for new live user instead of trial data

  initEffects();

  useEffect(() => {
    if (!fetching && !isEmpty(loggedInUser) && ory) {
      const userToSend = pick(loggedInUser, [
        'username',
        'client_name',
        'id',
      ]) as { username: string; client_name: string; id: string };
      const creationDate = get(ory, 'account_created_at');

      const trial = !get(loggedInUser, 'live', false);

      if (creationDate && trial) {
        const trialDay = calcTrialDay(creationDate);
        assign(userToSend, { trial_day: trialDay });
      }

      userTracking.setUser({ ...userToSend, trial });
      userTracking.track(UserTrackingEvents.SESSION);
    }
  }, [fetching, loggedInUser, ory]);

  useEffect$(() =>
    UserEvents$.pipe(
      tap((event: string) => {
        mixpanel.track(event);
      })
    )
  );

  useEffect$(() =>
    AuthEvents$.pipe(
      distinctUntilKeyChanged('id'),
      tap((event: AuthEvent) => {
        if ([AuthEventIds.RESP_429].includes(event.id)) {
          response429();
        }

        if ([AuthEventIds.USER_LOGOUT].includes(event.id)) {
          const explicitLogout = get(event, 'data.explicit', false);
          fullLogout(explicitLogout);
        }

        if ([AuthEventIds.FREE_TRIAL_EXPIRED].includes(event.id)) {
          getRegistry().forEach((store) => store.reset());
          updateLoggingOut(true);
          userTracking.reset();
          const loggedInUserName = get(event, 'data.name');
          const loggedInUserEmail = get(event, 'data.email');
          oryLogout().then(() => {
            window.location.assign(
              `${ACCOUNT_PORTAL_ROOT}/trial-expired?name=${loggedInUserName}&email=${loggedInUserEmail}`
            );
          });
        }
      })
    )
  );

  return children;
};

export const PrivateRoute = () => {
  const { isAuthenticating, isAuthorized, hasUserCompletedTourAndToS } =
    useAuthCheck();

  if (isAuthenticating || !isAuthorized) {
    return (
      <Flex height="100vh">
        <Loading />
      </Flex>
    );
  }

  return (
    <AuthInitializer>
      {!hasUserCompletedTourAndToS && <Tour />}
      <Outlet />
    </AuthInitializer>
  );
};
