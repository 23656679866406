import { createStore } from '@ngneat/elf';
import { getEntity, withEntities } from '@ngneat/elf-entities';
import { ReportBuilderDeliverable } from './report-builder.model';

export const getReportType = ({
  entityId,
}: {
  entityId: ReportBuilderDeliverable['id'];
}): string | undefined => {
  return reportBuilderStore.query(getEntity(entityId))?.reportType;
};

export const reportBuilderStore = createStore(
  {
    name: 'reportBuilder',
  },
  withEntities<ReportBuilderDeliverable>({
    initialValue: [],
  })
);
