import React from 'react';

interface CompanyIconProps {
  height?: number;
  width?: number;
  bottom?: number;
}

export const CompanyIcon = ({
  height = 130,
  width = 126,
  bottom = -6,
}: CompanyIconProps) => {
  return (
    <svg
      id="Layer_1"
      className="animate-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120.33 123.63"
      style={{
        position: 'absolute',
        width: `${width}px`,
        height: `${height}px`,
        bottom,
        right: 0,
        transition: 'opacity 0.25s ease-in-out',
      }}
    >
      <g
        className="animate-sun"
        style={{
          transform: 'translate(30px,30px)',
          transition: 'transform 0.2s ease-out',
        }}
      >
        <path
          d="m39.12,35.61l-4.23-3.25"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth="3.3"
        />
        <path
          d="m44.38,18.6l-5.31.69"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m23.84,45.34l-.69-5.31"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m35.95,6.71l-3.25,4.23"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m6.12,10.25l4.23,3.25"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m21.47,1l.69,5.31"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m13.49,35.65l-3.25,4.23"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m6.31,26.66l-5.31.69"
          fill="none"
          stroke="#2d426a"
          strokeLinecap="round"
          strokeWidth={2}
        />
        <path
          d="m14.78,16.47c3.41-4.44,9.79-5.28,14.23-1.86,4.44,3.41,5.28,9.79,1.86,14.23-3.41,4.44-9.79,5.28-14.23,1.86-4.44-3.41-5.28-9.79-1.86-14.23Z"
          fill="#5bd992"
          stroke="#2d426a"
          strokeWidth={2}
        />
      </g>
      <path
        d="m22.42,122.54V27.36c-.02-.71.13-1.41.42-2.05.29-.64.72-1.21,1.26-1.66s1.17-.76,1.85-.93c.68-.16,1.39-.17,2.07-.01l50.5,11.74c1.04.24,1.96.83,2.61,1.67s1,1.89.97,2.97v83.53"
        fill="#fff"
        strokeWidth={0}
      />
      <path
        d="m22.42,122.54V27.36c-.02-.71.13-1.41.42-2.05.29-.64.72-1.21,1.26-1.66s1.17-.76,1.85-.93c.68-.16,1.39-.17,2.07-.01l50.5,11.74c1.04.24,1.96.83,2.61,1.67s1,1.89.97,2.97v83.53"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m82.1,122.54V54l29.01,9.88c.91.31,1.7.9,2.26,1.7.56.79.86,1.74.86,2.71v54.25"
        fill="#5bd992"
        strokeWidth={0}
      />
      <path
        d="m82.1,122.54V54l29.01,9.88c.91.31,1.7.9,2.26,1.7.56.79.86,1.74.86,2.71v54.25"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m47.67,94.7h9.18c1.22,0,2.39.49,3.25,1.36.86.87,1.34,2.05,1.34,3.28v23.2h-18.36v-23.2c0-1.23.48-2.41,1.34-3.28.86-.87,2.03-1.36,3.25-1.36Z"
        fill="none"
        stroke="#2d426a"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="m18.33,122.54h101"
        fill="none"
        stroke="#2d426a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m101.61,81.7h-6.89"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m101.61,103.05h-6.89"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m44.23,47.36h-6.89"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m67.18,47.36h-6.89"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m44.23,64.53h-6.89"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m67.18,64.53h-6.89"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m44.23,81.7h-6.89"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="m67.18,81.7h-6.89"
        fill="none"
        stroke="#5bd992"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};
