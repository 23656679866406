import { startCase } from 'lodash';

export const dimensionLimits = {
  min: 400,
  max: 1200,
};

export enum DownloadSizeOptions {
  AUTO = 'auto',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  CUSTOM = 'custom',
}

export const DownloadSizeMap: { [key: string]: number } = {
  [DownloadSizeOptions.SMALL]: 400,
  [DownloadSizeOptions.MEDIUM]: 600,
  [DownloadSizeOptions.LARGE]: 800,
};

export const sizeOptions = Object.values(DownloadSizeOptions).map((opt) => ({
  label: startCase(opt),
  value: opt,
}));

export const defaultOption = {
  label: startCase(DownloadSizeOptions.AUTO),
  value: DownloadSizeOptions.AUTO,
};
