import { Flex } from '@chakra-ui/layout';
import { useEffect, useRef, useState } from 'react';
import useSpacebarPress from './hooks/useSpacebarPress';
import useSound from 'use-sound';

import './styles/styles.scss';
import SpinButton from './SpinButton';
import Machine from './Machine';
import logo from './assets/revelio-slots-logo.png';
import arrow from './assets/arrow.png';
import reelShadow from './assets/reel-shadow.png';
import Reel from './Reel';
import { useDisclosure } from '@chakra-ui/react';
import ResultsModal from './ResultsModal';
import {
  fetchResultsStats,
  transformCompensationData,
  transformResultsStatsResponse,
  transformTDResponse,
} from './utils/fetchResultStats';
import { useClient } from 'urql';
import { SelectionCategories, useSelectionLists } from '@revelio/filtering';
import useMutePress from './hooks/useMutePress';
import HighlightLine from './HighlightLine';
import HighlightFill from './HighlightFill';
import { format as d3Format } from 'd3-format';

export interface WinnerInterface {
  id: string;
  shortName: string;
  longName: string;
  label: string;
  class: string;
}

const Game = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [muteAll, setMuteAll] = useState<boolean>(true);
  const [muteMusic, setMuteMusic] = useState<boolean>(true);
  const autoPlayRef = useRef(false);
  const autoplayTimeout = useRef<NodeJS.Timer | number | null>(null);
  const [animationKey, setAnimationKey] = useState(0);
  const [lastSpinResult, setLastSpinResult] = useState('No Winner!');
  const [highestScore, setHighestScore] = useState<number>(0);
  const { isPressed } = useSpacebarPress();
  const { isAPressed, isMPressed } = useMutePress();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSpinning, setIsSpinning] = useState(false);
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [isPlotLoading, setIsPlotLoading] = useState(false);
  const [winnerCompleted, setWinnerCompleted] = useState(false);

  const [play, { duration }] = useSound('../../assets/smw.mp3', {
    loop: true,
    volume: muteAll && muteMusic ? 0.5 : 0,
    interrupt: true,
    soundEnabled: muteAll && muteMusic,
  });
  const [play2, { stop: stop2, sound: reelSound }] = useSound(
    '../../assets/reelspin.wav',
    {
      volume: muteAll ? 0.5 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );
  const [playWinner, { sound, stop: stopWinner }] = useSound(
    '../../assets/winner.mp3',
    {
      volume: muteAll ? 0.5 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );

  const [playSelection, { stop: stopSelection }] = useSound(
    '../../assets/reelselection.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );
  const [playSelection2, { stop: stopSelection2 }] = useSound(
    '../../assets/reelselection2.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );
  const [playSelection3, { stop: stopSelection3 }] = useSound(
    '../../assets/reelselection3.wav',
    {
      volume: muteAll ? 1 : 0,
      interrupt: true,
      soundEnabled: muteAll,
    }
  );

  const [playSpinButton, { sound: buttonSound }] = useSound(
    '../../assets/spinbutton.wav',
    {
      volume: muteAll ? 0.75 : 0,
      interrupt: true,
      soundEnabled: muteAll,
      playbackRate: 0.7,
    }
  );

  const [results, setResults] = useState({});
  const [kdeData, setKDEData] = useState({});
  const [winner, setWinner] = useState<WinnerInterface[]>([]);

  const cursorHideTimeoutRef = useRef<NodeJS.Timer | null>(null);

  const gqlClient = useClient();

  useEffect(() => {
    if (duration) {
      play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    if (isAPressed) {
      setMuteAll((prevState) => !prevState);
    }
  }, [isAPressed]);

  useEffect(() => {
    if (isMPressed) {
      setMuteMusic((prevState) => !prevState);
    }
  }, [isMPressed]);

  useEffect(() => {
    if (isPressed && !isSpinning) {
      setAnimationKey((prevKey) => prevKey + 1);
      // Autoplay
      setAutoPlay(false);
      autoPlayRef.current = false;
      clearTimeout(autoplayTimeout.current as number);
      autoplayTimeout.current = setTimeout(() => {
        setAutoPlay(true);
        autoPlayRef.current = true;
        onClose();
      }, 600000); // 10 Minutes
    }
  }, [isPressed, isSpinning, onClose]);

  useEffect(() => {
    const hideCursor = () => {
      document.body.style.cursor = 'none';
    };

    const showCursor = () => {
      document.body.style.cursor = 'default';
    };

    const resetCursorTimeout = () => {
      if (cursorHideTimeoutRef.current) {
        clearTimeout(cursorHideTimeoutRef.current);
      }
      showCursor();
      cursorHideTimeoutRef.current = setTimeout(hideCursor, 3000);
    };

    window.addEventListener('mousemove', resetCursorTimeout);

    return () => {
      window.removeEventListener('mousemove', resetCursorTimeout);
      if (cursorHideTimeoutRef.current) {
        clearTimeout(cursorHideTimeoutRef.current);
      }
    };
  }, []);

  const data = useSelectionLists([
    SelectionCategories.SENIORITY,
    SelectionCategories.ROLE_K150,
    SelectionCategories.METRO_AREA,
  ]);

  const handleOpen = ({
    seniority,
    rolek150,
    msa,
  }: {
    seniority: WinnerInterface;
    rolek150: WinnerInterface;
    msa: WinnerInterface;
  }) => {
    setIsStatsLoading(true);
    setIsPlotLoading(true);

    const seniorityIDs = [];

    if (seniority.id === '4') {
      seniorityIDs.push(4);
      seniorityIDs.push(5);
    } else if (seniority.id === '6') {
      seniorityIDs.push(6);
      seniorityIDs.push(7);
    } else {
      seniorityIDs.push(parseInt(seniority.id));
    }

    fetchResultsStats({
      msa: [parseInt(msa.id)],
      seniority: seniorityIDs,
      role: [parseInt(rolek150.id)],
      prevYearSentimentEndDate: '2023-09',
      postingsEndDate: '2024-09-02',
      gqlClient,
    }).then((res) => {
      const { otherStats: otherStatsRes, compensation, td: tdStatsRes } = res;

      if (otherStatsRes.error) {
        setResults({});
      }

      if (!otherStatsRes.error) {
        let tdCount = 0;

        const transformedResults = transformResultsStatsResponse(
          otherStatsRes.data
        );

        if (!tdStatsRes?.error) {
          tdCount = transformTDResponse(tdStatsRes?.data, parseInt(msa.id));
        }

        setResults({
          headcount: tdCount ? d3Format(',')(tdCount) : '--',
          ...transformedResults,
        });
      }

      const transformedKDEResults = transformCompensationData(compensation);

      setKDEData(transformedKDEResults);

      setIsStatsLoading(false);
    });

    setTimeout(() => {
      onOpen();
      setIsSpinning(false);
    }, 2000);
  };

  const handleWinner = (winner: WinnerInterface[], cancel: boolean) => {
    setWinnerCompleted(true);
    if (!autoPlayRef.current && !cancel) {
      const [seniority, rolek150, msa] = winner;
      setWinner(winner);
      stop2();
      setTimeout(() => {
        playWinner();
        sound.fade(0, 0.5, 500);
      }, 150);
      handleOpen({ seniority, rolek150, msa });
      setLastSpinResult(
        `${seniority.shortName} \u00A0|\u00A0  ${rolek150.shortName} \u00A0|\u00A0 ${msa.shortName}`
      );
    }
  };

  return (
    <div className="v1Wrapper">
      <Flex
        w="full"
        h="100vh"
        bgColor="#2f1e51"
        direction="column"
        align="center"
        justify="flex-end"
        pointerEvents="none"
        userSelect="none"
        position="relative"
      >
        <video
          src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/lights-bg-blur-1080p.mov"
          autoPlay
          loop
          playsInline
          muted
          style={{
            zIndex: 0,
            objectFit: 'cover',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
          }}
        />
        <video
          src="https://rl-images-2.s3.us-east-2.amazonaws.com/misc/game/wave-gray.mov"
          autoPlay
          loop
          playsInline
          muted
          style={{
            zIndex: 20,
            objectFit: 'cover',
            width: '94.2vh',
            position: 'absolute',
            top: '38vh',
            mixBlendMode: 'overlay',
            borderRadius: '10px',
            opacity: winnerCompleted ? 1 : 0,
            maxHeight: '19%',
            transition: 'opacity 0.5s ease',
          }}
        />
        <Machine />
        <SpinButton animationKey={animationKey} isSpinning={isSpinning} />
        <img
          src={logo}
          style={{
            position: 'absolute',
            zIndex: 12,
            top: '6%',
            marginLeft: '30px',
            maxHeight: '20%',
          }}
          alt="revelio slots logo"
        />
        <Reel
          isPressed={isPressed}
          isOpen={isOpen}
          onClose={onClose}
          data={data}
          handleWinner={handleWinner}
          playSelection={[playSelection, playSelection2, playSelection3]}
          isSpinning={isSpinning}
          setIsSpinning={setIsSpinning}
          play2={play2}
          autoPlay={autoPlay}
          autoPlayRef={autoPlayRef}
          autoplayTimeout={autoplayTimeout}
          reelSound={reelSound}
          setWinnerCompleted={setWinnerCompleted}
          playSpinButton={playSpinButton}
          buttonSound={buttonSound}
          stopSelection={[stopSelection, stopSelection2, stopSelection3]}
          stopWinner={stopWinner}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 12,
            bottom: '12.25%',
            height: '4vh',
            width: '101vh',
            color: 'white',
            transform: 'skew(-34deg, 0deg)',
            lineHeight: '2.5vh',
          }}
        >
          <div style={{ width: '28%' }}>
            <div
              className="neon-pink-text"
              style={{
                fontSize: '2.5vh',
                // fontWeight: 'bold',
                fontFamily: 'ds-digitalnormal',
              }}
            >
              <span>{`<<<< Last Spin Winner >>>>`}</span>
            </div>
            <div
              className="neon-blue-text"
              style={{
                fontSize: '2vh',
                // fontWeight: 'bold',
                fontFamily: 'ds-digitalnormal',
              }}
            >
              <span>{lastSpinResult}</span>
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 12,
            bottom: '12%',
            height: '4vh',
            width: '102vh',
            color: 'white',
            transform: 'skew(34deg, 0deg)',
            textAlign: 'right',
            lineHeight: '2.5vh',
          }}
        >
          <div style={{ width: '28%', float: 'right' }}>
            <div
              className="neon-green-text"
              style={{
                fontSize: '2.5vh',
                paddingBottom: '1vh',
                // fontWeight: 'bold',
                fontFamily: 'ds-digitalnormal',
              }}
            >
              <span>$ $ $ Highest Score $ $ $</span>
            </div>
            <div
              className="neon-blue-text"
              style={{
                fontSize: '3.2vh',
                // fontWeight: 'bold',
                fontFamily: 'ds-digitalnormal',
              }}
            >
              <span>
                {/* {`Director \u00A0|\u00A0 Sales \u00A0|\u00A0 New York, NY`} */}
                {d3Format('$,')(highestScore)}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100vh',
            zIndex: 11,
            position: 'absolute',
            top: '23.1%',
          }}
        >
          <img
            src={reelShadow}
            style={{
              height: '13vh',
              width: '100%',
              padding: '0 26px',
            }}
            alt="shadow"
          />
        </div>
        <div
          style={{
            width: '100vh',
            zIndex: 11,
            position: 'absolute',
            bottom: '28.5%',
          }}
        >
          <img
            src={reelShadow}
            style={{
              height: '13vh',
              width: '100%',
              padding: '0 26px',
              transform: 'scaleY(-1)',
            }}
            alt="shadow"
          />
        </div>
        <HighlightLine winnerCompleted={winnerCompleted} />
        <HighlightFill />
        <div
          style={{
            width: '100vh',
            zIndex: 12,
            position: 'absolute',
            bottom: '48.5%',
          }}
        >
          <img
            src={arrow}
            style={{
              height: '8vh',
              width: 'auto',
            }}
            alt="arrow"
          />
        </div>
        <div
          style={{
            width: '100vh',
            transform: 'scaleX(-1)',
            zIndex: 12,
            position: 'absolute',
            bottom: '48.5%',
          }}
        >
          <img
            src={arrow}
            style={{
              height: '8vh',
              width: 'auto',
            }}
            alt="arrow"
          />
        </div>
      </Flex>
      <ResultsModal
        isOpen={isOpen}
        onClose={onClose}
        data={results}
        kdeData={kdeData}
        isLoading={isStatsLoading || isPlotLoading}
        setIsPlotLoading={setIsPlotLoading}
        winner={winner}
        setHighestScore={setHighestScore}
      />
    </div>
  );
};

export default Game;
