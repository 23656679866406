import { Box, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import {
  clearRoleCacheFilters,
  RoleTaxonomySettingOption,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

export const AdminRoleTaxonomyOverrideSelect = () => {
  const {
    canOverrideValue,
    personalOverrideOptionValue,
    personalOverrideOptions,
    setPersonalOverrideOption,
  } = useRoleTaxonomySetting();
  const toast = useToast();

  const handleOption = (option: SingleValue<RoleTaxonomySettingOption>) => {
    setPersonalOverrideOption(option as RoleTaxonomySettingOption);
    clearRoleCacheFilters();
    toast({
      title: 'Taxonomy set on this browser',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    canOverrideValue && (
      <FormControl id="custom_role_taxonomy">
        <FormLabel fontSize="sm" fontWeight="semibold">
          Role Taxonomy Admin Override
        </FormLabel>

        <Box>
          <Select
            value={personalOverrideOptionValue}
            options={personalOverrideOptions}
            placeholder="Select role taxonomy setting"
            onChange={handleOption}
          />
        </Box>
        <Text fontSize="sm" color="gray.600">
          This setting overrides the taxonomy set in the client group and only
          affects this browser
        </Text>
      </FormControl>
    )
  );
};
