import { ADMIN_USER_ID } from '@revelio/core';

export const sessionIdentity = {
  id: 'e2c7d358-043a-4c0d-be89-46d4f6bac039',
  active: true,
  expires_at: '2023-03-01T13:48:18.118134Z',
  authenticated_at: '2023-02-28T13:48:18.118134Z',
  authenticator_assurance_level: 'aal1',
  authentication_methods: [
    {
      method: 'password',
      aal: 'aal1',
      completed_at: '2023-02-28T13:48:18.118132761Z',
    },
  ],
  issued_at: '2023-02-28T13:48:18.118134Z',
  identity: {
    id: ADMIN_USER_ID,
    schema_id: 'revelio-v3',
    schema_url: 'https://kratos-stage.reveliolabs.com/schemas/cmV2ZWxpby12Mw',
    state: 'active',
    state_changed_at: '2023-01-26T21:16:35.7777Z',
    traits: {
      name: 'Storybook User',
      email: 'storybook@reveliolabs.com',
      username: 'storybookUser',
      legacyUserID: '813930831910273049',
    },
    recovery_addresses: [
      {
        id: 'ef8865b1-665b-41b7-9842-08ffdc778999',
        value: 'storybook@reveliolabs.com',
        via: 'email',
        created_at: '2023-01-26T21:16:35.782303Z',
        updated_at: '2023-01-26T21:16:35.782303Z',
      },
    ],
    metadata_public: { terms_accepted: 'true', tour_completed: 'true' },
    created_at: '2023-01-26T21:16:35.77959Z',
    updated_at: '2023-01-26T21:16:35.77959Z',
  },
  devices: [
    {
      id: '0c04fc2f-cca2-497e-be55-ccc942e77cc5',
      ip_address: '172.30.75.19',
      user_agent:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36',
      location: '',
    },
  ],
};
