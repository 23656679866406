/**
 * function to get difference between two dates in months (exclusive of last month)
 *
 * @param d1 - first date
 * @param d2 - second date
 *
 * @returns difference between two dates in months (exclusive of last month)
 */
export function monthDiff(d1: Date, d2: Date) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

/**
 * function to get difference between two dates in years (exclusive of last year)
 *
 * @param d1 - first date
 * @param d2 - second date
 *
 * @returns difference between two dates in years (exclusive of last year)
 */
export function yearDiff(d1: Date, d2: Date) {
  const years = d2.getFullYear() - d1.getFullYear();
  return years <= 0 ? 0 : years;
}

/**
 * function to get difference between two dates in days
 *
 * @param d1 - first date
 * @param d2 - second date
 *
 * @returns  difference between two dates in days
 */
export function dayDiff(d1: number, d2: number) {
  const days = Math.abs(d2 - d1) / (1000 * 60 * 60 * 24);
  return days <= 0 ? 0 : days;
}
