import {
  SelectionListIdNames,
  TreeItem,
} from '../../../../engine/filters.model';
import { omit, uniq } from 'lodash';
import { getParentIds, unselectChildrenOnParentSelect } from '../treeSelect';
import formatFilterLabel from '../../../utils/formatFilterLabel';
import { useToast } from '@chakra-ui/react';
import { toastId } from '../tree.constants';
import { Subject } from 'rxjs';

interface SelectTreeItemParams {
  rootItemsAndLookup: React.MutableRefObject<{
    rootItems: TreeItem<string>[];
    lookup: {
      [id: string]: TreeItem<string>;
      [id: number]: TreeItem<string>;
    };
  }>;
  selectionRef: React.MutableRefObject<{
    [key: string]: TreeItem<string>;
  }>;
  limit: number | undefined;
  unselectParentOnChildSelect: boolean;
  isNestedTree: boolean;
  toast: ReturnType<typeof useToast>;
  selectionLists: SelectionListIdNames[];
  setTempSelections:
    | React.Dispatch<React.SetStateAction<Record<string, TreeItem<string>>>>
    | undefined;
  lookupHandle: React.MutableRefObject<
    Subject<{
      [key: string]: TreeItem<string>;
    }>
  >;
  updateIntermediateState: (selections: {
    [key: string]: TreeItem<string>;
  }) => void;
}

export const selectTreeItem =
  ({
    rootItemsAndLookup,
    selectionRef,
    limit,
    unselectParentOnChildSelect,
    isNestedTree,
    toast,
    selectionLists,
    setTempSelections,
    lookupHandle,
    updateIntermediateState,
  }: SelectTreeItemParams) =>
  (id: string) => {
    const item = rootItemsAndLookup.current.lookup[id];
    const itemHasList = item.item && 'list' in item.item;
    const isParentOfNestedList = itemHasList && 'children' in item;

    const sharedParams: HandleNestedListSelectionParams = {
      item,
      rootItemsAndLookup,
      selectionRef,
      setTempSelections,
      lookupHandle,
      updateIntermediateState,
    };

    if (isParentOfNestedList) {
      handleNestedListSelection(sharedParams);
    } else {
      handleSingleItemSelection({
        ...sharedParams,
        limit,
        unselectParentOnChildSelect,
        isNestedTree,
        selectionLists,
        toast,
      });
    }
  };

interface HandleNestedListSelectionParams {
  item: TreeItem<string>;
  rootItemsAndLookup: SelectTreeItemParams['rootItemsAndLookup'];
  selectionRef: SelectTreeItemParams['selectionRef'];
  setTempSelections: SelectTreeItemParams['setTempSelections'];
  lookupHandle: SelectTreeItemParams['lookupHandle'];
  updateIntermediateState: SelectTreeItemParams['updateIntermediateState'];
}
const handleNestedListSelection = ({
  item,
  rootItemsAndLookup,
  selectionRef,
  setTempSelections,
  lookupHandle,
  updateIntermediateState,
}: HandleNestedListSelectionParams) => {
  let tempSelection = { ...selectionRef.current };

  const selectedItems: TreeItem[] = [];

  item.children.forEach((subitem) => {
    selectedItems.push(rootItemsAndLookup.current.lookup[subitem.id]);
  });

  const isSelected: Record<string, boolean> = {};
  selectedItems.forEach((selectedItem: TreeItem) => {
    isSelected[selectedItem.id] = Object.prototype.hasOwnProperty.call(
      selectionRef.current,
      selectedItem.id
    );
  });

  const unSelected = Object.keys(isSelected).filter(
    (itemId) => !isSelected[itemId]
  );

  if (unSelected.length === 0) {
    // if all child nodes selected, unselect them all
    tempSelection = omit(selectionRef.current, [...Object.keys(isSelected)]);
  } else {
    // if some unselected child nodes, select the nodes that are unselected
    unSelected.forEach((itemId) => {
      const currentItem = selectedItems.find(
        (selected) => selected.id === itemId
      );

      if (currentItem) {
        tempSelection[currentItem.id] = currentItem;
      }
    });
  }

  selectionRef.current = tempSelection;
  if (typeof setTempSelections === 'function') {
    setTempSelections(tempSelection);
  }

  lookupHandle.current.next(tempSelection);
  updateIntermediateState(tempSelection);
};

interface HandleSingleItemSelectionParams
  extends HandleNestedListSelectionParams {
  limit: SelectTreeItemParams['limit'];
  unselectParentOnChildSelect: SelectTreeItemParams['unselectParentOnChildSelect'];
  isNestedTree: SelectTreeItemParams['isNestedTree'];
  selectionLists: SelectTreeItemParams['selectionLists'];
  toast: SelectTreeItemParams['toast'];
}
const handleSingleItemSelection = ({
  item,
  limit,
  selectionRef,
  unselectParentOnChildSelect,
  isNestedTree,
  selectionLists,
  setTempSelections,
  lookupHandle,
  updateIntermediateState,
  rootItemsAndLookup,
  toast,
}: HandleSingleItemSelectionParams) => {
  let tempSelection = { ...selectionRef.current };

  if (item.id in selectionRef.current) {
    delete tempSelection[item.id];
  } else {
    if (!limit) {
      tempSelection[item.id] = item;
    } else if (limit === 1) {
      tempSelection = {};
      tempSelection[item.id] = item;
    } else {
      const parentId = item.parentId;
      const isParentNode = item.children?.length > 0;

      const parentIds = getParentIds(item, rootItemsAndLookup.current.lookup);

      if (unselectParentOnChildSelect && isNestedTree) {
        const currentSelectionList = item.selectionListId;

        Object.keys(tempSelection).forEach((itemId) => {
          if (tempSelection[itemId].selectionListId !== currentSelectionList) {
            delete tempSelection[itemId];
          }
        });
      } else if (unselectParentOnChildSelect && isParentNode) {
        tempSelection = unselectChildrenOnParentSelect(
          tempSelection,
          item,
          rootItemsAndLookup.current.lookup
        );
      } else if (unselectParentOnChildSelect) {
        const currentSelectionList = item.selectionListId;

        Object.keys(tempSelection).forEach((itemId) => {
          if (tempSelection[itemId].selectionListId !== currentSelectionList) {
            delete tempSelection[itemId];
          }
        });
      }

      const numSelected = Object.keys(tempSelection).length;

      if (numSelected < limit) {
        tempSelection[item.id] = item;

        if (parentId && unselectParentOnChildSelect && !isNestedTree) {
          parentIds.forEach((parentId) => {
            delete tempSelection[parentId];
          });
        }
      } else {
        const isTempSelection = item.id in selectionRef.current;

        if (!isTempSelection && !toast.isActive(toastId)) {
          const uniqueTitles = uniq(
            selectionLists.map((sl) => formatFilterLabel(sl, limit))
          ).join('/');

          toast({
            id: toastId,
            position: 'top-right',
            status: 'warning',
            title: `${uniqueTitles} Limit`,
            description: `You can only choose up to ${limit} ${uniqueTitles} at a time!`,
            isClosable: true,
            variant: 'subtle',
          });
        }
      }
    }
  }

  selectionRef.current = tempSelection;
  if (typeof setTempSelections === 'function') {
    setTempSelections(tempSelection);
  }

  lookupHandle.current.next(tempSelection);
  updateIntermediateState(tempSelection);
};
