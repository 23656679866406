import { Flex, Text } from '@chakra-ui/react';

export const NoResultsPlaceholder = () => {
  return (
    <Flex direction="column" justify="center" h="100%">
      <Text color="white" fontSize="3vh" align="center">
        No data at this time.
      </Text>
      <Text pb={12} color="white" fontSize="3vh" align="center">
        Please spin again.
      </Text>
    </Flex>
  );
};
