import { useState } from 'react';
import { map } from 'rxjs';
import { difference, get } from 'lodash';
import { getEntity, selectEntity } from '@ngneat/elf-entities';
import {
  AbsoluteCenter,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/layout';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useObservable } from '@ngneat/react-rxjs';

import { CardListSelectController } from '@revelio/layout';

import { FilterDefinitionModal } from './filter-definition-modal';
import {
  deleteDatasetFilterByName,
  deliverablesStore,
} from '../../deliverables.repository';
import {
  DatasetFilter,
  Deliverable,
  isCompanyInfoPipeline,
} from '../../deliverables.model';
import { ADD_FILTER_ID } from './filter.model';
import { PipelineType } from '@revelio/data-access';

export const DatasetFiltersList = ({
  entityId,
}: {
  entityId: Deliverable['id'];
}) => {
  const [datasetFilters] = useObservable(
    deliverablesStore.pipe(
      selectEntity(entityId),
      map(
        (deliverable) =>
          get(deliverable, 'pipeline.filters', []) as DatasetFilter[]
      )
    ),
    { deps: [entityId] }
  );

  const pipelineType = deliverablesStore.query(getEntity(entityId))?.pipeline
    .pipeline_type;

  const [isAddDatasetFilterOpen, setIsAddDatasetFilterOpen] = useState<
    boolean | string
  >(false);

  const filterNames = datasetFilters.map((c) => c.name);

  const filterColumns = [
    ...datasetFilters.map((dFilter) => ({
      id: dFilter.name,
      label: dFilter.name,
      isChecked: true,
      cta: (
        <IconButton
          bg="none"
          aria-label="delete dataset filter"
          icon={<CloseIcon />}
          size="xs"
          variant="link"
          color="red"
          onClick={() =>
            deleteDatasetFilterByName({
              entityId,
              name: dFilter.name,
            })
          }
        />
      ),
      labelHeadingOverrideProps: {},
    })),
  ];

  if (!isCompanyInfoPipeline(pipelineType as PipelineType)) {
    filterColumns.push({
      id: ADD_FILTER_ID,
      label: '+ Add Custom',
      isChecked: false,
      // This is here just to get rid of a typescript error
      // eslint-disable-next-line react/jsx-no-useless-fragment
      cta: <></>,
      labelHeadingOverrideProps: {
        color: 'blue.300',
      },
    });
  }

  if (!filterColumns.length) {
    return null;
  }

  return (
    <>
      <FilterDefinitionModal
        isOpen={isAddDatasetFilterOpen}
        setIsOpen={setIsAddDatasetFilterOpen}
        existingFilters={datasetFilters}
        entityId={entityId}
      />

      <Heading as="h3" fontWeight="600" size="sm" color="text.primary" pb="3">
        <Flex alignItems="center" gap={1}>
          Filters
          <Tooltip
            label='If more than one filter is defined, the output will include union of filter conditions.("OR" is used.) If you want the output to satisfy all the filter conditions, you can do so in a single filter using "AND".'
            textAlign="center"
            hasArrow
            placement="top"
          >
            <InfoOutlineIcon
              boxSize={3}
              color="text.primary"
              cursor="pointer"
            />
          </Tooltip>
        </Flex>
      </Heading>

      <CardListSelectController<string>
        data-testid="dataset-filters-list"
        variant="columns"
        seperator={
          <Stack position="relative" direction="row">
            <Divider orientation="vertical" />
            <AbsoluteCenter bg="white">
              <Text>OR</Text>
            </AbsoluteCenter>
          </Stack>
        }
        items={[
          {
            heading: null,
            columns: filterColumns,
          },
        ]}
        value={filterNames}
        hideCtaIfNotSelected={true}
        gridProps={{
          spacing: 4,
          columns: 3,
          gridAutoFlow: 'row',
        }}
        onChange={(value: (string | typeof ADD_FILTER_ID)[]) => {
          const clickedAddFilter = value.includes(ADD_FILTER_ID);
          if (clickedAddFilter) {
            setIsAddDatasetFilterOpen(true);
            return; // we don't want it to be added to the pipeline columns
          }

          // removing the value
          const newValuesRemoved = difference(filterNames, value);
          if (newValuesRemoved.length) {
            const [lastUnSelectedValue] = newValuesRemoved;
            setIsAddDatasetFilterOpen(lastUnSelectedValue);
            return;
          }
        }}
      />
    </>
  );
};
