import {
  Box,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { ColumnItem, isColumnCardListSubmMenu } from '@revelio/layout';

import {
  ModelVersions,
  PipelineColumnType,
  Deliverable as APIDeliverable,
  PipelineType,
  Pipeline,
} from '@revelio/data-access';
import { PagePaths } from '@revelio/core';
import { capitalize, compact, isBoolean, map, uniq } from 'lodash';
import { NavLink } from 'react-router-dom';
import { getPipelineTypeTitleById } from '../data-set.model';
import { DeliverablePaths } from '../deliverables-page';
import { POSTINGS_SOURCE_OPTIONS } from '../columns/postings-source-select';
import {
  formatVersionName,
  formatVersionValue,
  areLatestModelVersions,
} from '../columns/data-configuration';
import {
  CompleteCompanyInfoPipeline,
  CompleteIndividualPipeline,
  CompletePostingPipeline,
  DatasetFilter,
  Deliverable as DataBuilderDeliverable,
  isCompanyInfoPipeline,
  isCompleteCompanyInfoPipeline,
  isCompleteIndividualPipeline,
  isCompletePostingPipeline,
} from '../deliverables.model';
import { dataSetColumnsMap } from '../columns/columns.model';
import {
  CONFIGURATION_SUMMARY_HEADING_PROPS,
  DeliverableConfigurationSummary,
  DeliverableConfigurationSummaryDetail,
  DetailsSummaryTrigger,
  SummaryPopover,
} from './confirmation.shared';
import { CustomColumnSummary, FiltersSummary } from './custom-step';
import {
  getDuplicateDeliverables,
  getUniquePipelineLocation,
} from '../delivery/delivery.model';
import { POSTINGS_FREQUENCY_OPTIONS } from '../columns/postings-frequency-select';

const COMBINED_COLUMN_LABEL_OVERRIDES: {
  [key in PipelineColumnType]?: string;
} = {
  [PipelineColumnType.Rcid]: 'RCID',
  [PipelineColumnType.UltimateParentRcid]: 'Ultimate Parent RCID',
  [PipelineColumnType.UltimateParentCompanyName]:
    'Ultimate Parent Company Name',
  [PipelineColumnType.UltParPrimaryName]: 'Ultimate Parent Primary Name',
  [PipelineColumnType.UltParRcid]: 'Ultimate Parent RCID',
  [PipelineColumnType.FinalParentFactsetId]: 'Final Parent Factset ID',
  [PipelineColumnType.FinalParentFactsetName]: 'Final Parent Factset Name',
  [PipelineColumnType.OnetCode]: 'ONET Code',
  [PipelineColumnType.OnetTitle]: 'ONET Title',
  [PipelineColumnType.Naics]: 'Naics',
  [PipelineColumnType.NaicsDesc]: 'Naics_desc',
};
const DatasetConfigurationSummary = ({
  deliverables,
  isRevelioAdmin,
  lagData,
  showHeading = true,
  commonDeliveryFilePrefix,
}: {
  deliverables: DataBuilderDeliverable[] | APIDeliverable[];
  isRevelioAdmin: boolean;
  lagData: boolean | null | undefined;
  showHeading?: boolean;
  commonDeliveryFilePrefix?: string;
}) => {
  const duplicatedDeliverableIds = uniq(
    getDuplicateDeliverables<DataBuilderDeliverable | APIDeliverable>(
      deliverables
    ).map((d) => d.pipeline.pipeline_type)
  );
  return (
    <>
      {showHeading && (
        <Heading pl="2.5" fontWeight="600" fontSize="16px" color="text.primary">
          Datasets
        </Heading>
      )}

      {isBoolean(lagData) && (
        <Box mb="5">
          <DeliverableConfigurationSummary
            details={[{ label: 'Lag data', value: lagData ? 'Yes' : 'No' }]}
          />
        </Box>
      )}

      {deliverables.map((deliverable) => {
        const pipelineType = deliverable.pipeline.pipeline_type as PipelineType;
        const pipelineTypeTitle = getPipelineTypeTitleById(pipelineType);
        const downloadLocation = duplicatedDeliverableIds.includes(
          deliverable.pipeline.pipeline_type
        )
          ? getUniquePipelineLocation({
              deliverable: deliverable as unknown as DataBuilderDeliverable,
              commonFilePrefix: commonDeliveryFilePrefix,
            })
          : undefined;

        let modelVersionSummaryTrigger = 'Latest';
        const modelVersions = deliverable.model_versions;
        if (modelVersions && !areLatestModelVersions({ modelVersions })) {
          modelVersionSummaryTrigger = 'Custom';
        }

        const getCompanySelectionSummary =
          (): DeliverableConfigurationSummaryDetail[] => {
            const inputRefsSummary: DeliverableConfigurationSummaryDetail[] =
              [];
            if (deliverable.company_sets?.length) {
              inputRefsSummary.push({
                label: 'Company Sets',
                value: deliverable.company_sets.join(', '),
              });
            }

            if (deliverable.company_reference && isRevelioAdmin) {
              inputRefsSummary.push({
                label: 'Company Reference Table',
                value: deliverable.company_reference,
              });
            }

            if (deliverable.pipeline_input && isRevelioAdmin) {
              inputRefsSummary.push({
                label: 'Company Pipeline Input Table',
                value: deliverable.pipeline_input,
              });
            }

            return inputRefsSummary;
          };
        const pipelineColumnsList = dataSetColumnsMap[pipelineType];
        const columnLabelMap = pipelineColumnsList.reduce<
          Record<string, string>
        >((labelMap, columnSet) => {
          const addColumnItemsToMap = (
            columnItem: ColumnItem<PipelineColumnType>
          ) => {
            if (isColumnCardListSubmMenu(columnItem)) {
              columnItem.menuItems.forEach((column) => {
                labelMap[column.id] = column.label || capitalize(column.id);
              });
            } else {
              labelMap[columnItem.id] =
                columnItem.label || capitalize(columnItem.id);
            }
          };

          columnSet.columns.forEach(addColumnItemsToMap);

          return labelMap;
        }, {});

        let standardDatasetSummaryDetails: DeliverableConfigurationSummaryDetail[] =
          compact([
            ...getCompanySelectionSummary(),
            {
              label: 'Columns',
              value: (deliverable.pipeline.columns || [])
                .map((col) => {
                  return (
                    COMBINED_COLUMN_LABEL_OVERRIDES[
                      col as PipelineColumnType
                    ] ||
                    columnLabelMap[col as PipelineColumnType] ||
                    col
                  );
                })
                .join(', '),
            },
            !isCompanyInfoPipeline(
              deliverable.pipeline.pipeline_type as PipelineType
            )
              ? {
                  label: 'Custom Columns',
                  value: (
                    <CustomColumnSummary
                      customColumns={deliverable.pipeline.custom_columns}
                    />
                  ),
                }
              : null,
            !isCompanyInfoPipeline(
              deliverable.pipeline.pipeline_type as PipelineType
            )
              ? {
                  label: 'Filters',
                  value: (
                    <FiltersSummary
                      filters={
                        deliverable.pipeline
                          .filters as unknown as DatasetFilter[]
                      }
                    />
                  ), // to get to this stage, it has to be a valid filter
                }
              : null,
          ]);
        if (isRevelioAdmin) {
          standardDatasetSummaryDetails = [
            ...standardDatasetSummaryDetails,
            {
              label: 'Model',
              value: (
                <ModelVersionSummary
                  trigger={
                    <Box as="span">
                      <DetailsSummaryTrigger
                        text={modelVersionSummaryTrigger}
                      />
                    </Box>
                  }
                  modelVersions={modelVersions}
                />
              ),
            },
          ];
        }

        if (
          isCompletePostingPipeline(
            deliverable.pipeline as CompletePostingPipeline
          )
        ) {
          const freq = POSTINGS_FREQUENCY_OPTIONS.find((freq) => {
            return (
              (deliverable.pipeline as Pipeline).posting_configuration
                ?.frequency === freq.id
            );
          })?.label;
          standardDatasetSummaryDetails = compact([
            {
              label: 'Source',
              value: POSTINGS_SOURCE_OPTIONS.find((source) => {
                return (deliverable.pipeline as Pipeline).source === source.id;
              })?.label as string,
            },
            freq
              ? {
                  label: 'Frequency',
                  value: freq,
                }
              : null,
            ...standardDatasetSummaryDetails,
          ]);
        }

        if (
          isCompleteIndividualPipeline(
            deliverable.pipeline as CompleteIndividualPipeline
          )
        ) {
          const individualPipeline =
            deliverable.pipeline as CompleteIndividualPipeline;
          standardDatasetSummaryDetails = [
            {
              label: 'Current positions only',
              value: JSON.stringify(
                individualPipeline.individual_user_configuration?.current
              ),
            },
            {
              label: 'Include full user history',
              value: JSON.stringify(
                individualPipeline.individual_user_configuration?.full_history
              ),
            },
            ...standardDatasetSummaryDetails,
          ];
        }

        if (
          isCompleteCompanyInfoPipeline(
            deliverable.pipeline as CompleteCompanyInfoPipeline
          )
        ) {
          const companyInfoPipeline =
            deliverable.pipeline as CompleteCompanyInfoPipeline;
          standardDatasetSummaryDetails = [
            {
              label: 'Include subsidiaries',
              value: JSON.stringify(
                companyInfoPipeline.company_info_configuration
                  ?.include_subsidiaries
              ),
            },
            ...standardDatasetSummaryDetails,
          ];
        }

        return (
          <Box mb="5">
            <Heading {...CONFIGURATION_SUMMARY_HEADING_PROPS} mt="0">
              {pipelineTypeTitle}{' '}
              {downloadLocation ? `(${downloadLocation})` : undefined}
            </Heading>

            <DeliverableConfigurationSummary
              details={standardDatasetSummaryDetails}
            />
          </Box>
        );
      })}
    </>
  );
};

const ModelVersionSummary = ({
  trigger,
  modelVersions,
}: {
  trigger: React.ReactNode;
  modelVersions?: ModelVersions;
}) => {
  return (
    <SummaryPopover trigger={trigger}>
      <TableContainer>
        <Table size="sm" variant="simple" colorScheme="gray">
          <Tbody>
            {map<ModelVersions>(
              modelVersions,
              (value: string, key: keyof ModelVersions) =>
                (key as unknown) !== '__typename' && (
                  <Tr key={`modelVersionSummary-${key}`}>
                    <Td>{formatVersionName(key)}</Td>
                    <Td>{formatVersionValue(value)}</Td>
                  </Tr>
                )
            )}
          </Tbody>
        </Table>
      </TableContainer>
      * you can edit on the{' '}
      <Link
        as={NavLink}
        to={`/${PagePaths.DELIVERABLES}/${DeliverablePaths.COLUMNS}`}
        fontSize="12px"
        fontWeight="600"
        color="brightGreen.500"
      >
        columns step
      </Link>
    </SummaryPopover>
  );
};

export default DatasetConfigurationSummary;
