import { createIcon } from '@chakra-ui/icons';
import { ComponentWithAs, IconProps } from '@chakra-ui/react';

export enum DeliverableIcons {
  DOWNLOAD = 'download',
  SNOWFLAKE = 'snowflake',
  API = 'api',
  AWS_S3_BUCKET = 'aws-s3-bucket',
  STAR = 'star',
  DATABASE_REF = 'database-ref',
  LIST_SELECT = 'list-select',
  DISCOVERY = 'discovery',
}

export const DiscoveryIcon = createIcon({
  displayName: 'DiscoveryIcon',
  viewBox: '0 0 27 24',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <line
      x1="4.55798"
      y1="1.86051"
      x2="18.9916"
      y2="1.86051"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="4.55798"
      y1="7.17844"
      x2="18.9916"
      y2="7.17844"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="4.55798"
      y1="12.4958"
      x2="18.9916"
      y2="12.4958"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="4.55798"
      y1="17.8137"
      x2="18.9916"
      y2="17.8137"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      y1="1.86051"
      x2="2.27899"
      y2="1.86051"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      y1="7.17844"
      x2="2.27899"
      y2="7.17844"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      y1="12.4958"
      x2="2.27899"
      y2="12.4958"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      y1="17.8137"
      x2="2.27899"
      y2="17.8137"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <circle
      cx="16"
      cy="13"
      r="6.25"
      fill="#E5EBF1"
      stroke="#2D426A"
      strokeWidth="1.5"
    />,
    <path
      d="M21 18L26 23"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
    />,
  ],
});

export const DatabaseRefIcon = createIcon({
  displayName: 'DatabaseRefIcon',
  viewBox: '0 0 26 25',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <path
      d="M20.3 4.27778C20.3 4.61875 20.1333 5.01098 19.6892 5.43872C19.2432 5.86835 18.5609 6.28751 17.6605 6.65436C15.8632 7.38662 13.3319 7.85556 10.5 7.85556C7.66812 7.85556 5.13684 7.38662 3.33949 6.65436C2.43905 6.28751 1.75679 5.86835 1.31076 5.43872C0.866686 5.01098 0.7 4.61875 0.7 4.27778C0.7 3.9368 0.866686 3.54457 1.31076 3.11683C1.75679 2.6872 2.43905 2.26804 3.33949 1.9012C5.13684 1.16894 7.66812 0.7 10.5 0.7C13.3319 0.7 15.8632 1.16894 17.6605 1.9012C18.5609 2.26804 19.2432 2.6872 19.6892 3.11683C20.1333 3.54457 20.3 3.9368 20.3 4.27778Z"
      stroke="#2D426A"
      strokeWidth="1.4"
    />,
    <path
      d="M20.2223 17.8889C20.2223 20.4662 15.8695 22.5555 10.5001 22.5555C5.13062 22.5555 0.777832 20.4662 0.777832 17.8889"
      stroke="#2D426A"
      strokeWidth="1.4"
    />,
    <path
      d="M20.2223 8.55554C20.2223 10.7033 15.8695 12.4444 10.5001 12.4444C5.13062 12.4444 0.777832 10.7033 0.777832 8.55554"
      stroke="#2D426A"
      strokeWidth="1.4"
    />,
    <path
      d="M20.2223 13.2222C20.2223 15.7996 15.8695 17.8889 10.5001 17.8889C5.13062 17.8889 0.777832 15.7996 0.777832 13.2222"
      stroke="#2D426A"
      strokeWidth="1.4"
    />,
    <path
      d="M0.777832 4.2778V18.6667M20.2223 3.88892V18.2778"
      stroke="#2D426A"
      strokeWidth="1.4"
    />,
    <rect x="11" y="10" width="14.1176" height="15" fill="#E5EBF1" />,
    <rect
      x="12.3235"
      y="11.3235"
      width="11.4706"
      height="12.3529"
      rx="0.441176"
      stroke="#2D426A"
      strokeWidth="0.882353"
    />,
    <path
      d="M18.0588 11.7647L18.0588 23.2353"
      stroke="#2D426A"
      strokeWidth="0.882353"
    />,
    <path
      d="M12.7648 13.5294H24.2354"
      stroke="#2D426A"
      strokeWidth="0.882353"
    />,
    <path
      d="M12.7648 17.0588H24.2354"
      stroke="#2D426A"
      strokeWidth="0.882353"
    />,
    <path
      d="M12.7648 20.5883H24.2354"
      stroke="#2D426A"
      strokeWidth="0.882353"
    />,
    <rect
      x="12.7648"
      y="11.7647"
      width="11.4706"
      height="1.76471"
      fill="#2D426A"
    />,
  ],
});

export const ListSelectIcon = createIcon({
  displayName: 'ListSelectIcon',
  viewBox: '0 0 20 19',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <line
      x1="5.25354"
      y1="1.29056"
      x2="19.6871"
      y2="1.29056"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="5.25354"
      y1="6.60849"
      x2="19.6871"
      y2="6.60849"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="5.25354"
      y1="11.9259"
      x2="19.6871"
      y2="11.9259"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="5.25354"
      y1="17.2437"
      x2="19.6871"
      y2="17.2437"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="0.695557"
      y1="1.29056"
      x2="2.97454"
      y2="1.29056"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="0.695557"
      y1="6.60849"
      x2="2.97454"
      y2="6.60849"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="0.695557"
      y1="11.9259"
      x2="2.97454"
      y2="11.9259"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
    <line
      x1="0.695557"
      y1="17.2437"
      x2="2.97454"
      y2="17.2437"
      stroke="#2D426A"
      strokeWidth="2.27899"
    />,
  ],
});

export const StarIcon = createIcon({
  displayName: 'StarIcon',
  viewBox: '0 0 22 20',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M11 1L14.09 6.92429L21 7.88013L16 12.489L17.18 19L11 15.9243L4.82 19L6 12.489L1 7.88013L7.91 6.92429L11 1Z"
      stroke="#2D426A"
      strokeWidth="1.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export const DeliveryDownloadIcon = createIcon({
  displayName: 'DeliveryDownloadIcon',
  viewBox: '0 0 26 26',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <path
      d="M25 17V22.3333C25 23.0406 24.719 23.7189 24.219 24.219C23.7189 24.719 23.0406 25 22.3333 25H3.66667C2.95942 25 2.28115 24.719 1.78105 24.219C1.28095 23.7189 1 23.0406 1 22.3333V17"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6.33337 10.3333L13 17L19.6667 10.3333"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M13 17V1"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export const ApiIcon = createIcon({
  displayName: 'ApiIcon',
  viewBox: '0 0 40 31',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <path
      d="M9.85417 8.08331L1 16.9375L9.85417 25.7916"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M29.3333 8.08331L38.1875 16.9375L29.3333 25.7916"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M20.4792 1L16.9375 29.3333"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

export const AwsS3BucketIcon = createIcon({
  displayName: 'AwsS3BucketIcon',
  viewBox: '0 0 35 36',
  defaultProps: {
    fill: 'none',
  },
  path: [
    <path
      d="M17.0435 14.4706C24.9079 18.2455 40.3222 24.2225 31.1995 16.0435"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.1676 30.6613L1.62915 5.34778H32.4578L28.9193 30.6613C28.6034 32.9217 26.67 34.6035 24.3877 34.6035H9.69922C7.4169 34.6035 5.48356 32.9217 5.1676 30.6613Z"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.087 5.34783C32.087 5.64848 31.9051 6.09405 31.2072 6.64403C30.5206 7.18513 29.4651 7.71616 28.0717 8.18063C25.2953 9.10609 21.3962 9.69565 17.0435 9.69565C12.6908 9.69565 8.79163 9.10609 6.01525 8.18063C4.62184 7.71616 3.56635 7.18513 2.87975 6.64403C2.18188 6.09405 2 5.64848 2 5.34783C2 5.04718 2.18188 4.60161 2.87975 4.05162C3.56635 3.51052 4.62184 2.9795 6.01525 2.51503C8.79163 1.58957 12.6908 1 17.0435 1C21.3962 1 25.2953 1.58957 28.0717 2.51503C29.4651 2.9795 30.5206 3.51052 31.2072 4.05162C31.9051 4.60161 32.087 5.04718 32.087 5.34783Z"
      fill="white"
      stroke="#2D426A"
      strokeWidth="2"
    />,
    <circle cx="17" cy="15" r="2" fill="#2D426A" />,
  ],
});

export const SnowflakeIcon = createIcon({
  displayName: 'SnowflakeIcon',
  viewBox: '0 0 35 37',
  path: [
    <path
      d="M12.9053 3.5L17.4053 8L21.9053 3.5"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.9053 34L17.4053 29.5L21.9053 34"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M17.4053 1V36"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M28.1458 7.10291L26.4986 13.25L32.6458 14.8971"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M1.73193 22.3529L7.87905 24L6.23193 30.1471"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.5608 9.75L2.2499 27.25"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.6458 22.1029L26.4986 23.75L28.1458 29.8971"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6.23193 6.85291L7.87905 13L1.73193 14.6471"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.5608 27.25L2.2499 9.75"
      stroke="#2D426A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

type IDeliverableIcons = ComponentWithAs<'svg', IconProps>;
export const DeliverablesIconLookup: {
  [value in DeliverableIcons]?: IDeliverableIcons;
} & { [key: string]: IDeliverableIcons } = {
  [DeliverableIcons.STAR]: StarIcon,
  [DeliverableIcons.LIST_SELECT]: ListSelectIcon,
  [DeliverableIcons.DATABASE_REF]: DatabaseRefIcon,
  [DeliverableIcons.DISCOVERY]: DiscoveryIcon,

  [DeliverableIcons.DOWNLOAD]: DeliveryDownloadIcon,
  [DeliverableIcons.SNOWFLAKE]: SnowflakeIcon,
  [DeliverableIcons.API]: ApiIcon,
  [DeliverableIcons.AWS_S3_BUCKET]: AwsS3BucketIcon,
};
