import { DataView, PrimaryFilters, PrimaryView, Views } from '@revelio/core';

export const getPrimaryViewFromPrimaryFilter = (
  primaryFilter: PrimaryFilters
): PrimaryView | null => {
  switch (primaryFilter) {
    case PrimaryFilters.COMPANY:
      return PrimaryView.COMPANY;
    case PrimaryFilters.ROLE:
      return PrimaryView.ROLE;
    case PrimaryFilters.GEOGRAPHY:
    case PrimaryFilters.METRO_AREA:
      return PrimaryView.GEOGRAPHY;
    default:
      return null;
  }
};

interface GetDataViewProps {
  endpointPath: string;
  plotName: string;
}
export const getDataView = ({
  endpointPath,
  plotName,
}: GetDataViewProps): DataView | null => {
  const isTDQuery = endpointPath.includes(Views.TALENT_DISCOVERY);

  const view = isTDQuery
    ? Views.TALENT_DISCOVERY
    : endpointPath.match(/\/plots\/([^/]+)\//)?.[1];

  switch (view) {
    case 'overview':
      return DataView.OVERVIEW;
    case 'talent_discovery':
      return DataView.TALENT_DISCOVERY;
    case 'transition':
      return DataView.TRANSITION;

    case 'sentiment_rating': {
      switch (plotName) {
        case 'positive':
          return DataView.SENTIMENT_REVIEW_POSITIVE;
        case 'negative':
          return DataView.SENTIMENT_REVIEW_NEGATIVE;
        default:
          return DataView.SENTIMENT_RATING;
      }
    }

    case 'posting': {
      switch (plotName) {
        case 'top':
          return DataView.POSTING_TOP_ROLES;
        default:
          return DataView.POSTING;
      }
    }

    default:
      return null;
  }
};
