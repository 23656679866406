import { Link } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';

export enum CompanyMappingNameChoice {
  CLIENT_COMPANY_NAME = 'client_company_name',
  REVELIO_COMPANY_NAME = 'revelio_company_name',
}
export type RevelioMappedCompany = {
  primary_name: string;
  company_id: string;
  rcid: number;
  raw_emp_count: number;
  has_subsidiaries: boolean;
  primary_identifiers: {
    primary_name: string;
    primary_website: string;
    primary_ticker: string;
    primary_linkedin_url: string;
    country: string;
    state: string;
    city: string;
  };
};
export type CompanyMapping = {
  request: {
    company_id: string;
    company_name: string;
    website: string;
    year_founded: number;
    city: string;
    state: string;
    country: string;
    linkedin_url: string;
    factset_entity_id: string;
    lei: string;
    cik: string;
    sic: string;
    naics: string;
    isin: string;
    ticker: string;
  };
  response: RevelioMappedCompany;
};

export interface Primary_identifier {
  primary_name: string;
  primary_website: string;
  primary_ticker: string;
  primary_linkedin_url: string;
  country: string;
  state: string;
  city: string;
}

export interface RawMapping {
  primary_name: string;
  company_id: string;
  rcid: number;
  raw_emp_count: number;
  has_subsidiaries: boolean;
  primary_identifiers: Primary_identifier;
}

export type SharedTableCompanyColumnData = {
  client_company_name: string;
  revelio_company_name: string;
  ticker: string;
  linkedin_url: string;
  website: string;
  country: string;
  state: string;
  city: string;
};

export type TableCompanyMapping = SharedTableCompanyColumnData & {
  client_company_id: string;
  actions: string;
  rawMapping: RawMapping;
  isExcluded?: boolean;
};

export type TableMissingCompanyMapping = SharedTableCompanyColumnData & {
  company_id: string;
  company_name: string;
  year_founded: number | null;
  factset_entity_id: string;
  lei: string;
  cik: string;
  sic: string;
  naics: string;
  isin: string;
  ticker: string;
  actions: string;
};

const EMPTY_URL = 'http://';
export const SHARED_COMPANY_MAPPING_COLUMNS: TypeColumn[] = [
  {
    name: CompanyMappingNameChoice.CLIENT_COMPANY_NAME,
    header: 'Your Company Name',
    minWidth: 200,
  },
  {
    name: CompanyMappingNameChoice.REVELIO_COMPANY_NAME,
    header: 'Revelio Company Name',
    render: ({ value }: { value: string }) =>
      value || <WarningTwoIcon color="red.600" />,
    minWidth: 210,
  },
  {
    name: 'ticker',
    header: 'Ticker',
    width: 90,
  },
  {
    name: 'linkedin_url',
    header: 'LinkedIn URL',
    width: 300,
    render: ({
      data: { linkedin_url },
    }: {
      data: TableMissingCompanyMapping | TableCompanyMapping;
    }) =>
      linkedin_url && linkedin_url !== EMPTY_URL ? (
        <Link
          isExternal={true}
          href={linkedin_url}
          color="lightBlue.600"
          textDecoration="underline"
          fontWeight={600}
          _hover={{ textDecoration: 'none' }}
        >
          {linkedin_url}
        </Link>
      ) : (
        ''
      ),
  },
  {
    name: 'website',
    header: 'Website',
    width: 200,
    render: ({
      data: { website },
    }: {
      data: TableMissingCompanyMapping | TableCompanyMapping;
    }) => {
      if (!website || website === EMPTY_URL) {
        return '';
      }

      let url = website;
      if (!/^(?:f|ht)tps?:\/\//.test(website)) {
        url = 'http://' + website;
      }

      return (
        <Link
          isExternal={true}
          href={url}
          color="lightBlue.600"
          textDecoration="underline"
          fontWeight={600}
          _hover={{ textDecoration: 'none' }}
        >
          {url}
        </Link>
      );
    },
  },
  {
    name: 'country',
    header: 'Country',
  },
  {
    name: 'state',
    header: 'State',
  },
  {
    name: 'city',
    header: 'City',
  },
];

export const MISSING_COMPANY_MAPPING_ADDITIONAL_COLUMNS: TypeColumn[] = [
  {
    name: 'year_founded',
    header: 'Year Founded',
  },
  {
    name: 'factset_entity_id',
    header: 'Factset Entity ID',
  },
  {
    name: 'lei',
    header: 'LEI',
  },
  {
    name: 'cik',
    header: 'CIK',
  },
  {
    name: 'sic',
    header: 'SIC',
  },
  {
    name: 'naics',
    header: 'NAICS',
  },
  {
    name: 'isin',
    header: 'ISIN',
  },
];
