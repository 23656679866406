import { Box, Flex, Text } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import { GroupedBarChartHorizontal } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import { useContext, useMemo } from 'react';
import { getRoleBarChartProps } from './utils/get-role-bar-chart-props';
import { getRoleCompositionData } from './utils/get-role-composition-data';
import { ChartsRenderedContext } from '../shared/charts-rendered-context';

interface RoleSummaryTopCompaniesProps {
  data: CompositionDataQuery | undefined;
}

export const RoleSummaryTopCompanies = ({
  data,
}: RoleSummaryTopCompaniesProps) => {
  const chartProps = useMemo(
    () =>
      getRoleBarChartProps(
        getRoleCompositionData(data),
        {
          value: 'industries',
          label: 'Industries',
        },
        'role-summary-top-companies'
      ),
    [data]
  );

  const context = useContext(ChartsRenderedContext);
  if (!context) {
    throw new Error(
      'ChartComponent must be used within a ChartsRenderedProvider'
    );
  }
  const { setChartHasRendered } = context;

  return (
    <Card height="100%" variant="unstyled" borderRadius={'8px'}>
      <Box height="100%" p={4}>
        <Flex justifyContent="space-between">
          <Text fontSize="sm" fontWeight={600}>
            Top Industries
          </Text>
        </Flex>

        <Box height="calc(100% - 21px)">
          <GroupedBarChartHorizontal
            {...chartProps}
            setChartHasRendered={setChartHasRendered}
          />
        </Box>
      </Box>
    </Card>
  );
};
