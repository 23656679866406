export const millisecondsToDays = (
  ms: number,
  roundFn: (input: number) => number
) => {
  if (ms <= 0) {
    return 0;
  }

  return roundFn(ms / (1000 * 60 * 60 * 24));
};

export const calcDaysLeft = (expirationDateString: string) => {
  const expirationDate = new Date(expirationDateString).getTime();
  const today = new Date().getTime();

  const differenceInMilliseconds = expirationDate - today;

  const differenceInDays = millisecondsToDays(
    differenceInMilliseconds,
    Math.ceil
  );

  return differenceInDays;
};

export const calcTrialDay = (creationDate: string) => {
  const creation = new Date(creationDate).getTime();

  const today = new Date().getTime();

  const timeElapsed = today - creation;

  const trialDay = millisecondsToDays(timeElapsed, Math.ceil);

  return trialDay;
};
