import DashboardPage from '../../DashboardPage';
import { LoaderType, PageTitles } from '@revelio/core';
import { ContentWrapper } from '@revelio/layout';
import ReactMarkdown from 'react-markdown';
import trialPageContent from './markdown/data-builder-trial-content.md';
import { TrailPageConent } from './components/trial-page-content';

export const DeliverablesTrialPage = () => {
  return (
    <DashboardPage
      title={[PageTitles.DELIVERABLES, PageTitles.DATA_BUILDER]}
      loader={LoaderType.MANUAL}
      scrollY={true}
    >
      <TrailPageConent>
        <ContentWrapper>
          <ReactMarkdown>{trialPageContent}</ReactMarkdown>
        </ContentWrapper>
      </TrailPageConent>
    </DashboardPage>
  );
};
