import { Box, Button, Icon, useToast } from '@chakra-ui/react';
import { useUserTrialDetails } from '@revelio/auth';
import { downloadFile, WithTooltip } from '@revelio/core';
import {
  GO_API_ROOT,
  RequestMethod,
  serializeTDFilters,
  talentDiscoveryFilterTransformation,
  useActiveFiltersV2,
  useTalentDiscoveryFilterContext,
} from '@revelio/filtering';
import { useCallback, useState } from 'react';
import { FiDownload } from 'react-icons/fi';

export const TalentDiscoveryDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters] = useActiveFiltersV2();
  const talentDiscoveryFilterApi = useTalentDiscoveryFilterContext();
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  const toast = useToast();
  const triggerDownload = useCallback(async () => {
    if (isTrialUser) {
      return;
    }

    setIsLoading(true);
    try {
      const fileDownloadResponse = await fetch(
        `${GO_API_ROOT}/api/talent-discovery/downloads`,
        {
          method: RequestMethod.POST,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'x-request-id': crypto.randomUUID(),
          },
          body: JSON.stringify(
            talentDiscoveryFilterTransformation({
              skillFilters:
                talentDiscoveryFilterApi.skillFilterApi.skillFilters,
              filters: serializeTDFilters(activeFilters),
            })
          ),
        }
      );
      if (!fileDownloadResponse.ok) {
        throw new Error(
          `Error: ${fileDownloadResponse.status} ${fileDownloadResponse.statusText}`
        );
      }

      const fileBlob = await fileDownloadResponse.blob();
      downloadFile({
        fileBlob,
        responseContentDisposition: fileDownloadResponse.headers.get(
          'Content-Disposition'
        ) as string,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: 'Failed to download data',
        description:
          "We've run into an error and our team has been alerted. Please try again later.",
        status: 'error',
        duration: 4000,
        position: 'top-right',
      });
    }

    setIsLoading(false);
  }, [
    activeFilters,
    isTrialUser,
    talentDiscoveryFilterApi.skillFilterApi.skillFilters,
    toast,
  ]);
  return (
    <WithTooltip
      showCondition={isTrialUser}
      placement="top"
      variant="label"
      padding="10px 15px"
      label="This feature is not available for trial users"
      aria-label={`This feature is not available for trial users`}
    >
      <Box>
        {activeFilters?.length > 0 ? (
          <Button
            isLoading={isLoading}
            loadingText="Preparing Download..."
            leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
            size="sm"
            variant="white"
            onClick={triggerDownload}
            justifyContent="center"
            data-testid="download-data-button"
            isDisabled={isTrialUser}
          >
            Download Data
          </Button>
        ) : null}
      </Box>
    </WithTooltip>
  );
};
