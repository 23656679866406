// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import '../d3-styles.scss';
import { useD3 } from '../hooks/useD3';
import { SankeyGenerator } from '../plot-generators';

const SankeyDiagram = (plotConfigs) => {
  const { name, data, height, width, targetRef } = plotConfigs;

  useD3(SankeyGenerator, plotConfigs, [targetRef.current, data, height, width]);

  return (
    <div
      ref={targetRef}
      className={`react-node-${name}`}
      style={{
        position: 'relative',
        display: 'grid',
        height: '100%',
      }}
    />
  );
};

SankeyDiagram.propTypes = {
  name: PropTypes.string.isRequired,
  ttMainFormat: PropTypes.string.isRequired,
  ttSecondaryFormat: PropTypes.string.isRequired,
  chartSize: PropTypes.string,
  inflows: PropTypes.bool.isRequired,
  isUniversity: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

SankeyDiagram.defaultProps = {
  name: 'default',
  heading: 'default',
  ttMainFormat: ',.0f',
  ttSecondaryFormat: ',.0f',
  chartSize: 'large',
  inflows: false,
  isUniversity: false,
  data: [],
};

// export default SankeyDiagram;
export default withResizeDetector(SankeyDiagram, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});
