import { PipelineColumnType } from '@revelio/data-access';
import {
  DEFAULT_RATING_PIPELINE_COLUMNS,
  STANDARD_COMPANY_COLUMN,
} from './shared';
import { ColumnSet } from '@revelio/layout';

export const REVIEW_TRENDS_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: 'Levels of Aggregation',
    columns: [
      { ...STANDARD_COMPANY_COLUMN, isRequired: true },
      {
        id: PipelineColumnType.Region,
        defaultIsSelected: true,
        columnFileSize: 256,
        rowPossibilities: 17,
      },
      {
        id: PipelineColumnType.JobCategory,
        label: 'Role k7 (Job Category)',
        defaultIsSelected: true,
        columnFileSize: 512,
        rowPossibilities: 8,
      },
    ],
  },
  {
    heading: 'Ratings',
    columns: [
      ...DEFAULT_RATING_PIPELINE_COLUMNS,
      {
        id: PipelineColumnType.NumReviews,
        label: 'Number of Reviews',
        defaultIsSelected: true,
        columnFileSize: 8,
        rowPossibilities: 1,
      },
    ],
  },
];
