import {
  createStore,
  distinctUntilArrayItemChanged,
  select,
  withProps,
} from '@ngneat/elf';
import { persistState, excludeKeys } from '@ngneat/elf-persist-state';
import localForage from 'localforage';
import { every, get, isEqual, isUndefined } from 'lodash';
import { AuthStoreRootProps, CustomReportMeta } from './auth.model';
import { shareReplay, filter, debounceTime } from 'rxjs';
import { MetadataKey } from '@revelio/data-access';
import { useEffect, useState } from 'react';

export const authStore = createStore(
  {
    name: 'auth',
  },
  withProps<AuthStoreRootProps>({ authChecked: false })
);

export const useAuthStore = () => {
  const [authStoreValue, setAuthStoreValue] = useState(authStore.getValue());

  useEffect(() => {
    const authStore$ = authStore.subscribe(setAuthStoreValue);

    return () => {
      authStore$.unsubscribe();
    };
  }, []);

  return authStoreValue;
};

export type AuthStore = typeof authStore;

const rlUserAuthDB = localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: 'rl-dashboard',
  version: 1.0,
  storeName: 'rl_user_auth',
});

export const persist = persistState(authStore, {
  key: 'rl_user_auth',
  storage: rlUserAuthDB,
  source: () =>
    authStore.pipe(
      debounceTime(1000),
      excludeKeys([
        'authChecked',
        'dashMetaCsrfToken',
        'isLoggingOut',
        'ory',
        'user',
      ])
    ),
});

export { MetadataKey };
export const getUserMetadataValue = (
  key: MetadataKey,
  user = authStore.query((state) => state.user)
) => {
  return get(user, 'metadata', [])?.find((item) => item?.key == key)?.value;
};

export const UserReports = authStore.pipe(
  select((state) => get(state, 'reports', []) as CustomReportMeta[]),
  distinctUntilArrayItemChanged(),
  shareReplay(1)
);

export function updateOryLoginDetails(ory: AuthStoreRootProps['ory']) {
  if (!isEqual(authStore.getValue().ory, ory)) {
    const isAllUndefined = every(ory, (v) => isUndefined(v));
    authStore.update((state) => ({
      ...state,
      ory: isAllUndefined ? {} : { ...state.ory, ...ory },
    }));
  }
}

export function updateUserReports(reports: CustomReportMeta[]) {
  authStore.update((state) => ({
    ...state,
    reports,
  }));
}

export function updateLoggingOut(
  isLoggingOut: AuthStoreRootProps['isLoggingOut']
) {
  authStore.update((state) => ({
    ...state,
    isLoggingOut,
  }));
}

export const AUTH_CHECKED = authStore.pipe(
  select((state) => state.authChecked),
  filter((checked) => checked)
);

export function clearAuthStore() {
  authStore.reset();
}

export function getAuthStoreUser() {
  return authStore.getValue().user;
}

export function getAuthDashMetaCsrfToken() {
  return authStore.getValue().dashMetaCsrfToken;
}

export const updateAuthDashMetaCsrfToken = (token: string) => {
  authStore.update((state) => ({
    ...state,
    dashMetaCsrfToken: token,
  }));
};
