import { useForm } from 'react-hook-form';
import { getEntity, updateAllEntities } from '@ngneat/elf-entities';
import { Button, ButtonGroup, ModalBody, ModalFooter } from '@chakra-ui/react';
import { omit } from 'lodash';

import { deliverablesStore } from '../deliverables.repository';
import { DeliverySelection } from './delivery.model';
import { Deliverable } from '../deliverables.model';
import { SnowflakeDbInputs, SnowflakeDbValues } from '../snowflake-db-inputs';
import { useClientDataBuilderConfig } from '../useClientDataBuilderConfig';

const SnowflakeDBConfigurationForm = ({
  entityId,
  onClose,
}: {
  entityId: Deliverable['id'];
  onClose: (uncheckAdding?: boolean) => () => void;
}) => {
  const existingSnowflakeConfig = deliverablesStore.query(
    getEntity(entityId)
  )?.snowflake_delivery;
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<SnowflakeDbValues>({
    defaultValues: {
      database: existingSnowflakeConfig?.snowflake_db,
      schema: existingSnowflakeConfig?.snowflake_schema,
      table: existingSnowflakeConfig?.snowflake_file_prefix,
    },
  });

  const clientDataBuilderConfig = useClientDataBuilderConfig();

  const onSubmit = handleSubmit((formData) => {
    deliverablesStore.update(
      updateAllEntities((deliverable) => {
        const snowflakeDelivery = omit(deliverable, [
          DeliverySelection.S3,
        ]) as Deliverable;
        snowflakeDelivery.snowflake_delivery = {
          snowflake_db:
            formData.database ||
            (clientDataBuilderConfig?.snowflake_location
              ?.snowflake_db as string),
          snowflake_schema:
            formData.schema ||
            (clientDataBuilderConfig?.snowflake_location
              ?.snowflake_schema as string),
          snowflake_file_prefix: formData.table,
        };
        return snowflakeDelivery;
      })
    );
    onClose(false)();
  });

  return (
    <form>
      <ModalBody mt="14px">
        <SnowflakeDbInputs
          register={register}
          errors={errors}
          allowUserDatabase={false}
        />
      </ModalBody>

      <ModalFooter>
        <ButtonGroup spacing={4}>
          <Button
            variant="link"
            fontSize="12px"
            colorScheme="gray"
            onClick={onClose(true)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            borderRadius="4px"
            fontSize="12px"
            fontWeight="600"
            colorScheme="green"
            variant="solid"
            size="sm"
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            Save
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

export default SnowflakeDBConfigurationForm;
